import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { useDepartments } from '../../../hooks/useDepartments';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { optionsAllow } from '../../../data/dataOutbound';
// import { arraysAreEqual } from '../../../utils/AppUtils';
import useSettings from '../../../hooks/useSettings';
import { formCreateNumberingExtensions, modalNumberingExtensions, optionsTypeExtension } from '../../../data/dataNumbering';
import { getArrayFormatted } from '../../../utils/AppUtils';
import { useDispatch } from 'react-redux';
import { isModalOpenActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { useAppSelector } from '../../../redux/hooks/hooks';
// import { getArrayFormatted } from '../../../utils/AppUtils';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const ExtensionsFormScreen = ({ crudType }) => {
  const {
    createExtension,
    arrayMarcasHook,
    arrayModelosHook,
    arrayPlantillasHook,
    getMarcas,
    getModelos,
    getPlantillas,
    arrayNumberingExtensionsHook,
    formExtensionsReducer,
    arrayNumberingHook,
    updateExtensions,
    listNumberingForm,
    getExtensions,
  } = useNumbering();

  // getNumberingList;
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { departmentsHook, getDepartments } = useDepartments();
  const { getCategoriesGroupHook, categoriesGroupHook } = useOutbound();
  const { typeSwal } = useSwal();

  const { selectedDoc, isModalOpen } = useAppSelector((state) => state.system);

  // const [checkboxesActives, setCheckboxesActives] = useState([]);
  // const [, setRefresh] = useState(false);
  useEffect(() => {
    getHeadquarter();
    getDepartments();
    getCategoriesGroupHook();
    getUsers();
    getExtensions();
    getMarcas();
    getModelos();
    getPlantillas();

    listNumberingForm({ action: 'clearForm' }, { form: 'extension' });
    // getNumberingList();
  }, []);

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { getUsers, users } = useSettings();

  const location = useLocation();
  const dispatch = useDispatch();

  const data = crudType === 'assignUserDispo' ? modalNumberingExtensions : formCreateNumberingExtensions;
  // crudType === 'assignUserDispo' || crudType === 'createUserDispo' ? modalNumberingExtensions : formCreateNumberingExtensions;

  // const handleRecord = (record) => {
  //   if (record === '' || record === undefined || record === null) return { empty: true, record: [] };
  //   else {
  //     const allRecords = record.split('');

  //     const auxRecords = { empty: false, record: [] };

  //     // allRecords.forEach((rec) => {
  //     if (allRecords[0] === '1')
  //       auxRecords.record.push({
  //         label: 'Internas',
  //         value: 'internas',
  //         id: 1,
  //       });
  //     else if (allRecords[1] === '1')
  //       auxRecords.record.push({
  //         label: 'Salientes',
  //         value: 'salientes',
  //         id: 2,
  //       });
  //     else if (allRecords[2] === '1')
  //       auxRecords.record.push({
  //         label: 'Externas',
  //         value: 'externas',
  //         id: 3,
  //       });
  //     // else if (import.meta.env.VITE_DEBUG === 'true') console.log('error');
  //     // });

  //     return auxRecords;
  //   }
  // };

  // const optionsAllow = [
  //   { label: 'Allaw', value: 'alaw', id: 1 },
  //   { label: 'Ulaw', value: 'ulaw', id: 2 },
  //   { label: 'g729', value: 'g729', id: 3 },
  // ];

  useEffect(() => {
    if (crudType === 'edit' || crudType === 'assignUserDispo' || crudType === 'create') {
      // crudType === 'createUserDispo' || || crudType === 'create'
      // const finalRecord = handleRecord(location.state?.record);
      // const namedpickupgroup = location.state?.pickupgroup === 'null' || location.state?.pickupgroup === undefined ? '' : location.state?.pickupgroup;
      // const depArr = namedpickupgroup?.split(', ');
      // const newNamePickupGroup = [];
      // const removeAccents = (str) => {
      //   return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      // };
      // if (depArr[0] !== '') {
      //   for (let dep of depArr) {
      //     const match = departmentsHook.find((obj) => removeAccents(obj.name.toLowerCase()) === removeAccents(dep.toLowerCase()));
      //     if (match) {
      //       newNamePickupGroup.push({ label: match.name, value: match.id });
      //     } else {
      //       newNamePickupGroup.push({ label: 'not found', value: 'not found' });
      //     }
      //   }
      // }

      listNumberingForm(
        {
          action: 'input',
          value: selectedDoc?.id ? selectedDoc?.id : formExtensionsReducer?.id || location.state?.id,
        },
        {
          form: 'extension',
          element: 'id',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'userId',
        },

        {
          value: selectedDoc?.User?.id
            ? selectedDoc.User.id
            : formExtensionsReducer?.userId.value
              ? formExtensionsReducer?.userId.value
              : location.state?.id || selectedDoc?.userId || location.state?.id,
          label: selectedDoc?.User?.firstname
            ? selectedDoc.User.firstname + ' ' + selectedDoc.User.lastname
            : formExtensionsReducer?.userId.label
              ? formExtensionsReducer?.userId.label
              : location.state?.Usuario || location.state?.User?.fullName || selectedDoc?.User?.fullName,
        }
      );

      let newAllow = [];

      if (Array.isArray(selectedDoc?.allow)) {
        newAllow = selectedDoc?.allow;
      } else {
        newAllow = getArrayFormatted(selectedDoc?.allow, optionsAllow, 'allow');
      }

      // newAllow.forEach((itemAllow) => {
      newAllow
        ? listNumberingForm(
            {
              action: 'multiselect',
              // value: newAllow.value,
              // label: newAllow.label,
            },
            {
              form: 'extension',
              element: 'allow',
            },
            Array.isArray(newAllow) ? newAllow[0] : { value: newAllow, label: newAllow.toUpperCase() + newAllow.slice(1) }
            // location.state?.allow || ''
          )
        : null;
      // });

      listNumberingForm(
        {
          action: 'input',
          value: selectedDoc?.password || location.state?.password,
        },
        {
          form: 'extension',
          element: 'password',
        }
      );

      let newIdentification = {};
      const newAction = 'input';

      if (selectedDoc?.id) {
        newIdentification = {
          value: selectedDoc?.id || location.state?.ddiId,
          label: selectedDoc?.identification || location.state?.identification,
          type: selectedDoc?.typeExtension || location.state?.typeExtension,
        };
        // newAction = 'input';
      } else {
        newIdentification = arrayNumberingExtensionsHook[0]?.name;
        //  {
        //   value: arrayNumberingExtensionsHook[0].id,
        //   label:

        // + '_' + arrayNumberingExtensionsHook[0]?.typeExtension),
        // type: arrayNumberingExtensionsHook[0].typeExtension,
        // };
        // newAction = 'input';
      }

      // if (crudType !== 'create' && crudType !== 'createUserDispo') {
      listNumberingForm(
        {
          action: newAction,
          value: location.state?.identification || selectedDoc?.identification || newIdentification?.label || newIdentification,
        },
        {
          form: 'extension',
          element: 'identification',
        }
      );
      // }
      const newTypeExtension = location.state?.typeExtension
        ? {
            value: location.state?.typeExtension,
            label: location.state?.typeExtension[0]?.toUpperCase() + location.state?.typeExtension.slice(1),
          }
        : !Array.isArray(selectedDoc?.typeExtension)
          ? getArrayFormatted(selectedDoc?.typeExtension, optionsTypeExtension, 'typeExtension')
          : selectedDoc?.typeExtension;

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'typeExtension',
        },
        newTypeExtension || selectedDoc?.typeExtension
      );

      listNumberingForm(
        {
          action: 'select-option',
          value: selectedDoc?.Marca?.id || location.state?.Marca?.value,
          label: selectedDoc?.Marca?.marca || location.state?.Marca?.label,
        },
        {
          form: 'extension',
          element: 'marca',
        }

        // location.state?.Marca
      );

      listNumberingForm(
        {
          action: 'select-option',
          value: selectedDoc?.Modelo?.id || location.state?.Modelo?.value,
          label: selectedDoc?.Modelo?.modelo || location.state?.Modelo?.label,
        },
        {
          form: 'extension',
          element: 'modelo',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
          value: selectedDoc?.Plantilla?.id || location.state?.Plantilla?.value,
          label: selectedDoc?.Plantilla?.nombre || location.state?.Plantilla?.label,
        },
        {
          form: 'extension',
          element: 'plantilla',
        }
      );

      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'pikedGroup',
      //   },
      //   newNamePickupGroup
      // );

      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //     value: newAllow,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'allow',
      //   }
      // );

      // listNumberingForm(
      //   {
      //     action: 'input',
      //     value: location.state?.sipPeerId,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'sipPeerId',
      //   }
      // );
      // listNumberingForm(
      //   {
      //     action: 'input',
      //     value: location.state?.extension,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'extension',
      //   }
      // );
      // listNumberingForm(
      //   {
      //     action: 'input',
      //     value: location.state?.name,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'name',
      //   }
      // );
      // listNumberingForm(
      //   {
      //     action: 'input',
      //     value: location.state?.ringTimeout,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'ringTimeout',
      //   }
      // );
      // listNumberingForm(
      //   {
      //     action: 'input',
      //     value: location.state?.callForward,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'callForward',
      //   }
      // );
      // listNumberingForm(
      //   {
      //     action: 'input',
      //     value: location.state?.callForwardNA,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'callForwardNA',
      //   }
      // );
      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'companyId',
      //   },
      //   {
      //     label: location.state?.company,
      //     value: String(location.state?.companyId),
      //   }
      // );

      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'department',
      //   },
      //   {
      //     label: location.state?.department,
      //     value: String(location.state?.departmentId),
      //   }
      // );

      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'categories',
      //   },
      //   {
      //     label: location.state?.categoryGroup,
      //     value: String(location.state?.categoryGroupId),
      //   }
      // );

      // listNumberingForm(
      //   {
      //     action: 'toggle',
      //     value: location.state?.voicemailActive,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'voicemailActive',
      //   }
      // );
      // if (finalRecord.empty === false)
      //   finalRecord.record.forEach((rec) => {
      //     listNumberingForm({ action: 'select-option', value: rec }, { form: 'extension', element: 'record' });
      //   });

      // listNumberingForm(
      //   {
      //     action: 'toggle',
      //     // value: { label: 'Activa', value: 1, id: 6 },
      //     value: location.state?.callWaiting,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'callWaiting',
      //   }
      // );

      // listNumberingForm(
      //   {
      //     action: 'button',
      //     // value: { label: 'Activa', value: 1, id: 6 },
      //     value: location.state?.password,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'password',
      //   }
      // );
      // } else {
    } else {
      // const newTypeExtension = location.state?.typeExtension
      //   ? { value: location.state?.typeExtension, label: location.state?.typeExtension[0].toUpperCase() + location.state?.typeExtension.slice(1) }
      //   : null;

      // if (crudType === 'create' || crudType === 'createUserDispo') {
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'userId',
        },
        {
          value: location.state?.id,
          label: location.state?.firstname + ' ' + location.state?.lastname,
        }
      );

      const newTypeExtension = location.state?.typeExtension
        ? {
            value: location.state?.typeExtension,
            label: location.state?.typeExtension[0]?.toUpperCase() + location.state?.typeExtension.slice(1),
          }
        : Array.isArray(selectedDoc?.typeExtension)
          ? selectedDoc?.typeExtension
          : getArrayFormatted(selectedDoc?.typeExtension, optionsTypeExtension, 'typeExtension');

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'typeExtension',
        },
        newTypeExtension || optionsTypeExtension[2]
      );
      // }

      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'userId',
      //   },
      //   {
      //     value: location.state?.id,
      //     label: location.state?.Usuario,
      //   }
      // );

      // listNumberingForm(
      //   {
      //     action: 'clear',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'identification',
      //   }
      // );

      const newId = location.state?.firstname + '_' + location.state?.lastname + '__' + selectedDoc?.typeExtension + '__' + location.state?.SIPExtensionId;

      listNumberingForm(
        {
          action: 'input',
          value: newId,
        },
        {
          form: 'extension',
          element: 'identification',
        }
      );

      let newAllow = [];

      if (Array.isArray(selectedDoc?.allow)) {
        newAllow = selectedDoc?.allow;
      } else {
        newAllow = getArrayFormatted(selectedDoc?.allow, optionsAllow, 'allow');
      }

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'allow',
        },
        Array.isArray(newAllow) && newAllow[0] !== undefined ? newAllow[0] : { value: optionsAllow[0].value, label: optionsAllow[0].label }
        // {
        //   value: formExtensionsReducer?.allow.length > 0 && formExtensionsReducer?.allow.value ? formExtensionsReducer?.allow.value : selectedDoc?.allow ? selectedDoc.allow:location.state?.AstSipPeer.allow,
        //   label: formExtensionsReducer?.allow.length > 0 && formExtensionsReducer?.allow.label ? formExtensionsReducer?.allow.label : location.state?.AstSipPeer.allow,
        // }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'marca',
        },
        {
          value: formExtensionsReducer?.marca?.value || arrayMarcasHook[0]?.id,
          label: formExtensionsReducer.marca?.label || arrayMarcasHook[0]?.marca,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'modelo',
        },
        {
          value: formExtensionsReducer?.modelo?.value || arrayModelosHook[0]?.id,
          label: formExtensionsReducer?.modelo?.label || arrayModelosHook[0]?.modelo,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'plantilla',
        },
        {
          value: formExtensionsReducer?.plantilla?.value || arrayPlantillasHook[0]?.id,
          label: formExtensionsReducer?.plantilla?.label || arrayPlantillasHook[0]?.nombre,
        }
      );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof formExtensionsReducer === 'object' && arrayNumberingHook.length > 0 && arrayHeadquarterHook.length > 0 && departmentsHook.length > 0 && categoriesGroupHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'userId') {
              item2.options = [];
              users?.forEach((item3) => {
                if (item3?.firstname === null) {
                  item3.firstname = 'not defined';
                }
                const company = {
                  value: item3?.id,
                  label: item3?.firstname + ' ' + item3?.lastname,
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            if (item2.ref.element === 'marca') {
              item2.options = [];
              arrayMarcasHook?.forEach((item3) => {
                if (item3?.marca === null) {
                  item3.marca = 'not defined';
                }
                const marca = {
                  value: item3?.id,
                  label: item3?.marca,
                };
                const found = item2.options.some((e) => e.value === marca.value);
                if (!found && marca.value !== undefined) {
                  item2.options.push(marca);
                }
              });
            }
            if (item2.ref.element === 'modelo') {
              item2.options = [];

              const found = arrayModelosHook.find((model) => formExtensionsReducer?.marca?.value === model.id);
              // arrayModelosHook?.forEach((item3) => {
              //   if (item3?.modelo === null) {
              //     item3.modelo = 'not defined';
              //   }
              //   const modelo = {
              //     value: item3?.id,
              //     label: item3?.modelo,
              //   };
              //   const found = item2.options.some((e) => e.value === modelo.value);
              if (found) {
                item2.options.push({ value: found.id, label: found.modelo });
              }
              // });
            }
            if (item2.ref.element === 'plantilla') {
              item2.options = [];
              // arrayPlantillasHook?.forEach((item3) => {
              // if (item3?.nombre === null) {
              //   item3.nombre = 'not defined';
              // }
              // const plantilla = {
              //   value: formExtensionsReducer?.marca?.value,
              //   label: formExtensionsReducer?.marca?.label,
              // };

              const found = arrayPlantillasHook.find((model) => formExtensionsReducer?.marca?.value === model.id && formExtensionsReducer?.modelo?.value === model.id);
              // const found = item2.options.some((e) => e.value === plantilla.value);
              if (found) {
                item2.options.push({ value: found.id, label: found.nombre });
              }
              // });
            }

            if (item2.ref.element === 'allow') {
              item2.options = [];
              optionsAllow.forEach((item6) => {
                const newAllowItem = {
                  value: item6.id,
                  label: item6.label,
                };

                const found = item2.options.some((e) => e.value === newAllowItem.value);
                if (!found && newAllowItem.value !== undefined) {
                  item2.options.push(newAllowItem);
                }
              });
            }
            // if (item2.ref.element === 'companyId') {
            //   arrayHeadquarterHook.forEach((item3) => {
            //     if (item3.name === null) {
            //       item3.name = 'not defined';
            //     }
            //     const company = {
            //       label: item3.name,
            //       value: item3.id?.toString(),
            //     };
            //     const found = item2.options.some((e) => e.value === company.value);
            //     if (!found && company.value !== undefined) {
            //       item2.options.push(company);
            //     }
            //   });
            // }
            // if (item2.ref.element === 'department') {
            //   item2.options = [];
            // }

            //  &&
            //   (location.state?.Extension || formExtensionsReducer.typeExtension) &&
            //   (location.state?.Extension?.typeExtension === 'SIP' ||
            //     formExtensionsReducer.typeExtension.value === 'SIP' ||
            //     item2.defaultValues?.label === 'Todos' ||
            //     formExtensionsReducer.typeExtension.label === 'Todos')

            // if (item2.ref.element === 'categories') {
            //   categoriesGroupHook.forEach((item3) => {
            //     if (item3.description === null) {
            //       item3.description = 'not defined';
            //     }
            //     const category = {
            //       label: item3?.description,
            //       value: item3.id?.toString(),
            //     };
            //     const found = item2.options.some((e) => e.value === category.value);
            //     if (!found && category.value !== undefined) {
            //       item2.options.push(category);
            //     }
            //   });
            // }

            // return item2;
          });
          // return item;
        });
      }
      // if ((crudType === 'edit' && formExtensionsReducer.id === '') || formExtensionsReducer.id !== location.state?.id) {
      // formExtensionsReducer.number === '' ||
      // formExtensionsReducer.number !== location.state?.extension ||
      // formExtensionsReducer.callForward !== location.state?.callForward ||
      // formExtensionsReducer.department.value === '' ||
      // +formExtensionsReducer.category.value !== +location.state?.categoryGroupId)
      // (formExtensionsReducer.pikedGroup.length === 0 && formExtensionsReducer.typeExtension.label === 'sip')
      //   setLoadingCounter(loadingCounter + 1);
      // } else
      if (typeof formExtensionsReducer === 'object') {
        if (crudType === 'edit' || crudType === 'assignUserDispo' || crudType === 'createUserDispo') {
          let newValue;
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'userId':
                  newValue = formExtensionsReducer?.userId
                    ? formExtensionsReducer?.userId
                    : location.state?.Usuario
                      ? { value: location.state?.id, label: location.state?.Usuario }
                      : location.state?.Usuario;
                  item2.defaultValues = newValue;
                  // location.state?.User
                  //   ? { value: location.state?.User.id, label: location.state?.User.fullName }
                  //   : location.state?.Usuario
                  //     ? { value: location.state?.id, label: location.state?.Usuario }
                  //     : formExtensionsReducer.userId;
                  break;
                case 'extension':
                  item2.defaultValues = location.state?.number || formExtensionsReducer.number;
                  break;
                case 'marca':
                  newValue = location.state?.Marca
                    ? location.state?.Marca
                    : formExtensionsReducer?.marca
                      ? formExtensionsReducer?.marca
                      : selectedDoc?.length
                        ? { value: selectedDoc?.Marca?.id, label: selectedDoc?.Marca?.marca }
                        : { value: arrayMarcasHook[0]?.id, label: arrayMarcasHook[0]?.marca };
                  item2.defaultValues = newValue;
                  break;
                case 'modelo':
                  newValue = location.state?.Modelo
                    ? location.state?.Modelo
                    : formExtensionsReducer?.modelo
                      ? formExtensionsReducer?.modelo
                      : selectedDoc?.length
                        ? { value: selectedDoc?.id, label: selectedDoc?.modelo }
                        : { value: arrayModelosHook[0]?.id, label: arrayModelosHook[0]?.modelo };
                  item2.defaultValues = newValue;
                  break;
                case 'plantilla':
                  newValue = location.state?.Plantilla
                    ? location.state?.Plantilla
                    : formExtensionsReducer?.plantilla
                      ? formExtensionsReducer?.plantilla
                      : selectedDoc?.length
                        ? { value: selectedDoc?.Plantilla?.id, label: selectedDoc?.Plantilla?.nombre }
                        : { value: arrayPlantillasHook[0]?.id, label: arrayPlantillasHook[0]?.nombre };
                  item2.defaultValues = newValue;
                  break;
                case 'password':
                  item2.defaultValues = location.state?.password || formExtensionsReducer.password;
                  break;
                case 'identification':
                  item2.defaultValues = formExtensionsReducer?.identification || location.state?.identification || selectedDoc?.name;
                  break;
                case 'typeExtension':
                  newValue = formExtensionsReducer?.typeExtension
                    ? formExtensionsReducer?.typeExtension
                    : location.state?.typeExtension
                      ? location.state?.typeExtension
                      : selectedDoc?.length
                        ? selectedDoc?.typeExtension
                        : { value: 'SIP', label: 'SIP' };
                  newValue = !newValue?.label ? getArrayFormatted(newValue, optionsTypeExtension, 'typeExtension') : newValue;
                  item2.defaultValues = newValue;
                  break;
                case 'allow':
                  newValue = formExtensionsReducer?.allow
                    ? formExtensionsReducer?.allow
                    : location.state?.AstSipPeer?.allow
                      ? location.state?.AstSipPeer.allow
                      : selectedDoc.length > 0
                        ? selectedDoc?.allow
                        : { value: 'allow', label: 'Allow' };
                  item2.defaultValues = newValue;
                  break;

                default:
                  // item2.defaultValues = formExtensionsReducer[item2.ref.element];
                  break;
              }
              setDataSave(true);
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'userId':
                  item2.defaultValues = location.state?.Usuario ? { value: location.state?.id, label: location.state?.Usuario } : formExtensionsReducer?.userId;
                  break;
                case 'allow':
                  item2.defaultValues = formExtensionsReducer?.allow;
                  break;
                case 'typeExtension':
                  item2.defaultValues = formExtensionsReducer?.typeExtension || location.state?.typeExtension || selectedDoc?.typeExtension;
                  //  location.state?.typeExtension ||
                  break;
                case 'identification':
                  item2.defaultValues = formExtensionsReducer?.identification;
                  //  location.state?.typeExtension ||
                  break;
                case 'marca':
                  item2.defaultValues = formExtensionsReducer?.marca;
                  //  location.state?.typeExtension ||
                  break;
                case 'modelo':
                  item2.defaultValues = formExtensionsReducer?.modelo;
                  //  location.state?.typeExtension ||
                  break;
                case 'plantilla':
                  item2.defaultValues = formExtensionsReducer?.plantilla;
                  //  location.state?.typeExtension ||
                  break;
                case 'password':
                  item2.defaultValues = formExtensionsReducer?.password;
                  //  location.state?.typeExtension ||
                  break;
                default:
                  // item2.defaultValues = formExtensionsReducer[item2.ref.element];
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create' || crudType === 'createUserDispo') {
      typeSwal(
        'createLoader',
        '',
        listNumberingForm,
        getExtensions,
        crudType === 'createUserDispo' ? dispatch(isModalOpenActionCreator(false)) : `/${routesWide.numbering}/${routesNumbering.extensions}`,
        'extension',
        createExtension
      );
    } else if (crudType === 'edit') {
      if (location.route) typeSwal('updateLoader', '', listNumberingForm, getExtensions, `${location.route}`, 'extension', updateExtensions);
      else typeSwal('updateLoader', '', listNumberingForm, getExtensions, `/${routesWide.numbering}/${routesNumbering.extensions}`, 'extension', updateExtensions);
    }
  };
  // const [inputValue, setInputValue] = useState({ value: '', label: '' });

  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          arrScreen={['Dispositivo']}
          data={data}
          onSubmit={onSubmit}
          onCancel={handleOnCancel}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={crudType}
          reducerForm={formCreateNumberingExtensions}
          // checkboxesActives={checkboxesActives}
          // setCheckboxesActives={setCheckboxesActives}
        />
        //<PageScreen
        //data={data}
        //arrScreen={['Cola']}
        //onSubmit={onSubmit}
        //form={ExtensionsFormScreen}
        //dataSave={dataSave}
        //crudType={crudType}
        //maxwidth={'1000px'}
        //setRender={setRefresh}
        //render={refresh}
        //reducerForm={formCreateNumberingExtensions}
        ///>
      )}
    </div>
  );
};

ExtensionsFormScreen.propTypes = { crudType: string };
