import React from 'react';
import PropTypes from 'prop-types';

import './Header.css';

export const Header = ({ text }) => {
  return (
    <div className="header">
      <div className="header__title">
        <h1>{text}</h1>
      </div>
    </div>
  );
};

Header.propTypes = {
  text: PropTypes.string.isRequired,
};
