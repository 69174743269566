import styled from 'styled-components';

export const PreferencesNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // position: fixed;
  // top: 120px;

  // left: 180px;
`;
