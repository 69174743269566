import React from 'react';
import { InputWrapper } from './Input.style';
import { array, bool, func, object, string } from 'prop-types';
// import FallbackRender from '../FallbackRenderer/FallbackRenderer';
// import { ErrorBoundary } from 'react-error-boundary';

export const Input = ({
  item,
  setUnSavedChanges,
  form,
  inputValue,
  setErrorInput,
  setInputValue,
  setValidationNormalInput,
  validationNormalInput,
  arrScreen,
  setInputValueUnique,
  type,
  isDisabled,
}) => {
  const handleInputChange = (e, item, requiredRegex) => {
    setUnSavedChanges(true);
    setInputValue((prevState) => ({
      ...prevState,
      [item.ref.element]: e.target.value === null ? '' : e.target.value,
    }));

    if (e.target.value === '' || !requiredRegex.test(e.target.value)) {
      setErrorInput(true);
      setValidationNormalInput((prevState) => [...prevState, item.ref.element]);
      setInputValueUnique((prevState) => prevState.filter((obj) => obj.element !== item.ref.element));
    } else {
      if (item.ref.unique) {
        let refItemName = item.ref.element;
        let namesInBd = [];
        arrScreen.map((item) => namesInBd.push(item[refItemName]));

        if (namesInBd.includes(e.target.value.trim())) {
          setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.ref.element));
          if (validationNormalInput.length === 0) setErrorInput(false);
          setInputValueUnique((prevState) => [...prevState, item.ref]);
        } else {
          setInputValueUnique((prevState) => prevState.filter((obj) => obj.element !== item.ref.element));
          setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.ref.element));
          if (validationNormalInput.length === 0) setErrorInput(false);
        }
      } else {
        setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.ref.element));
        if (validationNormalInput.length === 0) setErrorInput(false);
      }
    }

    let action = { action: 'input', value: e.target.value };
    form(action, item.ref);
  };

  return (
    // <ErrorBoundary fallbackRender={FallbackRender}>
    <InputWrapper>
      {type === 'number' ? (
        <input
          className={isDisabled ? 'input-disabled' : 'input'}
          disabled={isDisabled}
          // id={item.ref.element}
          type="number"
          placeholder={item.placeholder}
          value={inputValue && inputValue[item.ref.element] ? inputValue[item.ref.element] : item.defaultValues}
          onChange={(e) => handleInputChange(e, item, item.required.regex)}
        />
      ) : (
        <input
          className={isDisabled ? 'input-disabled' : 'input'}
          disabled={isDisabled}
          // id={item.ref.element}
          type={item.ref.element === 'password' ? 'password' : 'text'}
          placeholder={item.placeholder}
          value={inputValue && inputValue[item.ref.element] && inputValue[item.ref.element] !== '' ? inputValue[item.ref.element] : item.defaultValues}
          onChange={(e) => handleInputChange(e, item, item.required.regex)}
        />
      )}
    </InputWrapper>
    // </ErrorBoundary>
  );
};

Input.propTypes = {
  item: object,
  form: func,
  inputValue: object,
  setErrorInput: func,
  setInputValue: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  arrScreen: array,
  setInputValueUnique: func,
  type: string,
  isDisabled: bool,
  setUnSavedChanges: func,
};
