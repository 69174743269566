import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize } from '../../theme/fonts';

export const NavMenuWrapper = styled.div`
  height: 100%;
  width: ${(p) => (!p.isMenuOpen ? '70px' : '100%')};
  ${'' /* transition: all 1s linear; */}
  ${'' /* transition: width 0.5s linear; */}
  ${'' /* overflow: scroll; */}



  .item__image__user {
    height: 25px;
    width: 25px;
  }

  .openMenu__container {
    height: 40%;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: flex-end;
    justify-content: ${(p) => (!p.isMenuOpen ? 'center' : 'flex-end')};
    color: ${colors.backgroundColor};
    ${'' /* position: relative; */}

    .icon_openMenu {
      &:hover {
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid ${colors.backgroundColor};
      }
    }
  }

  .submenu__title {
    color: ${colors.backgroundColor};
    font-weight: bold;
    padding-left: 10px;
  }

  .item__image__logout {
    height: 35px;
    width: 35px;
    padding: 5px;
  }
  .contain__navLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .login__buttons {
    width: 100%;
    ${'' /* ${(p) => (!p.isMenuOpen ? '65px' : '228px;')}; */}
    display: flex;
    position: absolute;
    bottom: 5px;
    border-top: 1px solid ${colors.grey};
    margin: auto;
    ${'' /* transition: all 1s ease; */}
  }

  .icon__moreOptions {
    padding-right: 8px;
    position: ${(p) => (!p.isMenuOpen ? 'absolute' : '')};
    left: 55px;
    width: ${(p) => (!p.isMenuOpen ? '20px' : '20px')};
  }

  .navleft-link__settings {
    position: fixed;
    bottom: 60px;
    padding-top: 10px;
    border-top: 1px solid ${colors.grey};
    width: ${(p) => (!p.isMenuOpen ? '65px' : '220px')};
    display: flex;
    flex-direction: column-reverse;
  }

  .item__options__container {
    color: rgb(225, 224, 224);
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    left: ${(p) => (!p.isMenuOpen ? '72px' : '235px')};
    top: 250px;
    z-index: 9999;
    ${'' /* background-color: #334155; */}
    width: max-content;
    border-radius: 10px;
    padding: 5px;
    border: ${colors.secondaryColor} 1px solid;
    background-color: ${colors.primaryColorLight};

    .link-active {
      width: 100%;
      ${'' /* width: 180px;/ */}
      min-height: 35px;
      ${'' /* padding-left: 25px; */}
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-size: 14px;
      background-color: ${colors.primaryColor};
      border-radius: 10px;
      gap: 5px;
      padding-right: 5px;

      &:hover {
        cursor: pointer;
        ${'' /* background-color: ${colors.primaryColor}; */}
      }
    }

    margin: auto;

    &:hover {
      cursor: pointer;
    }
    .menuTop__icon {
      height: 35px;
      width: 35px;
      border-radius: 10px;
      padding: 5px;
    }
  }

  .item-link {
    min-height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 14px;
    gap: 5px;
    padding-right: 5px;

    &:hover {
      background-color: ${colors.primaryColor};
      border-radius: 10px;
      margin: auto;
      ${
        '' /* color: white;
        background-color: ${colors.primaryColorLight}; */
      }
    }
  }
  .hamburguer__container {
    display: flex;
    justify-content: ${(p) => (p.isMenuOpen ? 'space-between' : 'center')};
    border: 0;
    ${'' /* justify-content: ${(p) => (!p.isMenuOpen ? '' : 'flex-start')}; */}
    width: 100%;
    height: 68px;
    align-items: center;
    padding-left: ${(p) => (!p.isMenuOpen ? '' : '18px')};
    padding-right: ${(p) => (!p.isMenuOpen ? '' : '15px')};
    margin-bottom: 50px;

    &:hover {
      cursor: pointer;

      ${
        '' /* .item__text {
         color: ${colors.primaryColor};
      } */
      }

      .item__image {
        height: 25px;
        width: 25px;
        color: ${colors.primaryColor};
      }
    }
  }

  .iconActionMenu__Pin__close {
    width: 40px;
    height: 40px;
  }

  .iconActionMenu__Pin__open {
    width: 40px;
    height: 40px;
  }

  .navleft__bottom {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.greyLine};
  }

  .icon__menu {
    width: 40px;
    height: 40px;
    margin-top: 2px;
    color: ${colors.greySpanLabel};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    &:hover {
      ${'' /* opacity: 1; */}
      color: ${colors.white};
    }
  }

  .icon__pin {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    color: ${colors.greySpanLabel};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    &:hover {
      ${'' /* opacity: 1; */}
      color: ${colors.white};
    }
  }

  .icon__pin__active {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    &:hover {
      ${'' /* opacity: 1; */}
      color: ${colors.white};
    }
  }

  .hamburguer__text {
    width: 100%;
    text-align: left;
    padding-left: 10px;
    font-size: ${newFontSize.body};
    color: ${colors.greyLine};

    &:hover {
      opacity: 1;
      color: ${colors.white};
    }
  }

  .iconActionMenu {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    &__contain {
      height: 30px;
      transform: rotate(90deg);
    }
  }

  .container__top {
    width: 100%;
    height: 50%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }

  .navleft-link {
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container__bottom {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primaryColor};
    border: 1px solid ${colors.greyLine};
    margin-top: 25px;

    .item__image {
      height: 30px;
      color: ${colors.primaryColor};
    }

    .item__image__bottom {
      height: 30px;
      width: 100%;

      &:hover {
        color: ${colors.black};
      }
    }
  }

  .item__contain__bottom2 {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px;

    &:hover {
      heigth: 100%;
      width: 100%;
      color: ${colors.black};
      background-color: ${colors.white};

      .item__text {
        width: 100%;
      }

      .item__image {
        height: 35px;
        width: 35px;
        color: ${colors.greySpanLabel};
      }
    }
  }

  .item__contain {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.greySpanLabel};
    margin: auto;
    ${
      '' /* padding-left: ${(p) => (p.isMenuOpen ? '12px' : '5px')};
    padding-right: ${(p) => (p.isMenuOpen ? '15px' : '')}; */
    }

    &:hover {
      cursor: pointer;
      color: white;
      width: ${(p) => (!p.isMenuOpen ? '60px' : '100%')};
      background-color: ${colors.primaryColorLight};
      border-radius: 10px;
    }

    .item__text {
      width: 100%;
      color: ${colors.greySpanLabel};
      z-index: ${(p) => (!p.isMenuOpen ? -1 : 10)};
      flex: 2;
    }

    .item__image {
      padding: 5px;
      width: 55px;
      color: ${colors.greySpanLabel};
      margin: auto;
    }
  }

  ${
    '' /* .item__text {
    width: 100%;
    z-index: ${(p) => (!p.isMenuOpen ? -1 : 10)};
  } */
  }

  .item__divider {
    height: 1px;
    width: ${(p) => (p.isMenuOpen ? '70%' : '30%')};
    background: ${colors.primaryColorLight};
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .item__active {
    height: 40px;
    width: 100%;
    ${
      '' /* padding-left: ${(p) => (p.isMenuOpen ? '12px' : '12px')};
    padding-right: ${(p) => (p.isMenuOpen ? '5px' : '6px')}; */
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: ${colors.white};

    .icon__moreOptions {
      height: 40px;
      border-radius: 0 10px 10px 0px;
      background-color: ${colors.primaryColorLight};
    }

    .item__image {
      width: 55px;
      padding: 5px;
      color: ${colors.white};
      margin: auto;
      border-radius: ${(p) => (p.isMenuOpen ? '10px 0 0 10px' : '10px 10px')};
      background-color: ${colors.primaryColorLight};

      ${
        '' /* &:hover {
        color: ${colors.white};
      } */
      }
    }

    .item__text {
      height: 100%;
      width: 100%;
      color: ${colors.white};
      background-color: ${colors.primaryColorLight};
      z-index: ${(p) => (!p.isMenuOpen ? -1 : 10)};
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0 10px 10px 0;
    }
  }
`;
