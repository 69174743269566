import React, { useEffect } from 'react';
import { ToggleButtonWrapper } from './ToggleButton.style';
import { bool, string } from 'prop-types';
import { IconBrandKakoTalk } from '@tabler/icons-react';
import { IconUpload } from '@tabler/icons-react';

export const ToggleButton = ({ isActive, setIsActive, isShowCard, textRight, textLeft, twoButtonActive = 'false', defaultValue }) => {
  useEffect(() => {
    if (defaultValue !== undefined) {
      setIsActive(defaultValue);
    }
  }, [defaultValue, setIsActive]);
  return (
    <ToggleButtonWrapper isActive={isActive} isShowCard={isShowCard} twoButtonActive={twoButtonActive}>
      <div className={'toggleButton'}>
        <div className="toggleButton__left">
          <span>{textLeft}</span>
          <IconBrandKakoTalk />
        </div>
        <div className="toggleButton__right">
          <span>{textRight}</span>
          <IconUpload />
        </div>
      </div>
    </ToggleButtonWrapper>
  );
};

ToggleButton.propTypes = { isActive: bool, setIsActive: bool, isShowCard: bool, textRight: string, textLeft: string, twoButtonActive: string, defaultValue: string };
