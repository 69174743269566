// import React, { useRef, useState } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import useApplications from '../../hooks/useApplications';
// import useNumbering from '../../hooks/useNumbering';
// import { Select } from '../Select/Select';
import useEM from '../../hooks/useEM';
import { func, number, object, string } from 'prop-types';
// import W3Select from 'react-select';
import { ModalPrompt } from '../ModalPrompt/ModalPrompt';
import { DestinationSelectWrapper } from './DestinationSelect.style';
import Select from 'react-select';
import useSettings from '../../hooks/useSettings';
// import useUsers from '../../hooks/useUsers';
// export const DestinationSelect = ({ onChange, name, placeholder, idPage, destinationOpen, item, defaultValue }) => {
export const DestinationSelect = ({ setInputValue, onChange, name, placeholder, setValidateReducer, idPage, item, defaultValue, form }) => {
  const { getConferences, getIvr, conferences, getMailboxes, queues, mailboxes, ivr, getApplicationsLocutions, arrayLocutionsHook } = useApplications();
  // const { getExtensions, arrayNumberingExtensionsHook } = useNumbering();
  const { getEMAlerts, arrayEMAlerts } = useEM();
  const { getUsers, users } = useSettings();

  // const { usersFormReducer } = useUsers();
  useEffect(() => {
    getConferences();
    getMailboxes();
    getIvr();
    getApplicationsLocutions();
    // getExtensions();
    getEMAlerts();
    getUsers();
  }, []);

  const options = [
    { value: 'conference', id: 1, label: 'Conferencia', ref: 'conference', options: [] },
    { id: 2, value: 'voicemail', label: 'Búzon', ref: 'voicemail', options: [] },
    { id: 3, value: 'ivr', label: 'IVR', ref: 'ivr', options: [] },
    { id: 4, value: 'soundPrompt', label: 'Locución', options: [], ref: 'soundPrompt' },
    { id: 5, value: 'queue', label: 'Cola', options: [], ref: 'queue' },
    { id: 6, value: 'extension', label: 'Usuarios', options: [], ref: 'extension' },
    { id: 7, value: 'emergencyManager', label: 'Emergency Manager', options: [], ref: 'emergencyManager' },
    { id: 8, value: 'external', label: 'Numero externo', type: 'input', ref: 'external' },
    { id: 9, value: 'hangup', label: 'Colgar llamada', ref: 'hangup' },
  ];

  options.forEach((item3) => {
    if (item3.ref === 'conference') {
      conferences.forEach((item4) => {
        if (item4.name === null) {
          item4.name = 'not defined';
        }
        const conference = {
          label: item4.name,
          value: item4.id?.toString(),
          ref: 'conference',
          name: item3.label,
        };
        const found = item3.options.some((e) => e.value === conference.value);
        if (!found) {
          item3.options.push(conference);
        }
      });
    }

    if (item3.ref === 'voicemail') {
      mailboxes.forEach((item4) => {
        if (item4.name === null) {
          item4.name = 'not defined';
        }
        const mailbox = {
          label: item4.extension,
          value: item4.id?.toString(),
          ref: 'voicemail',
          name: item3.label,
        };
        const found = item3.options.some((e) => e.value === mailbox.value);
        if (!found) {
          item3.options.push(mailbox);
        }
      });
    }

    if (item3.ref === 'ivr') {
      if (ivr !== '') {
        ivr.forEach((item4) => {
          if (item4.name === null) {
            item4.name = 'not defined';
          }
          const ivr = {
            label: item4.name,
            value: item4.id?.toString(),
            ref: 'ivr',
            name: item3.label,
          };
          const found = item3.options.some((e) => e.value === ivr.value);
          if (!found) {
            item3.options.push(ivr);
          }
        });
      }
    }

    if (item3.value === 'soundPrompt') {
      arrayLocutionsHook.forEach((item4) => {
        if (item4.name === null) {
          item4.name = 'not defined';
        }
        const locution = {
          label: item4.description,
          value: item4.id?.toString(),
          ref: 'soundPrompt',
          name: item3.label,
        };
        const found = item3.options.some((e) => e.value === locution.value);
        if (!found) {
          item3.options.push(locution);
        }
      });
    }

    if (item3.value === 'extension') {
      users.forEach((item4) => {
        if (item4.firstname === null) {
          item4.name = 'not defined';
        }
        const extension = {
          label: item4.firstname,
          value: item4.id?.toString(),
          ref: 'extension',
          name: item3.label,
        };
        const found = item3.options.some((e) => e.value === extension.value);
        if (!found) {
          item3.options.push(extension);
        }
      });
    }

    if (item3.value === 'emergencyManager') {
      // if (import.meta.env.VITE_DEBUG_ARRAYS) console.log(arrayEMAlerts);
      arrayEMAlerts.forEach((item4) => {
        if (item4.name === null) {
          item4.name = 'not defined';
        }
        const destination = {
          label: item4.name,
          value: item4.id?.toString(),
          ref: 'emergencyManager',
          name: item3.label,
        };
        const found = item3.options.some((e) => e.value === destination.value);
        if (!found) {
          item3.options.push(destination);
        }
      });
    }

    if (item3.value === 'queue') {
      // if (import.meta.env.VITE_DEBUG_ARRAYS) console.log(arrayEMAlerts);
      queues.forEach((item4) => {
        if (item4.name === null) {
          item4.name = 'not defined';
        }
        const destination = {
          value: item4.id?.toString(),
          label: item4.name,
          ref: 'queue',
          name: item3.label,
        };
        const found = item3.options.some((e) => e.value === destination.value);
        if (!found) {
          item3.options.push(destination);
        }
      });
    }
  });

  if (defaultValue && Object.keys(defaultValue)?.length > 0) {
    options.forEach((option) => {
      if (option.ref === defaultValue.label) {
        if (option.options) {
          option.options.forEach((option2) => {
            if (option2.value === defaultValue.value) {
              defaultValue = option2;
            }
          });
        }
        if (option.ref === 'hangup') {
          defaultValue = {
            ...defaultValue,
            label: 'Colgar llamada',
            ref: 'hangup',
          };
        }
      }
    });
  }
  // const [isLoading, setIsLoading] = useState(false);
  // const [value, setValue] = useState(null);
  const select1 = useRef(null);
  const select2 = useRef(null);

  const [valuesSelect1, setValuesSelect1] = useState([]);
  const [valuesSelect2, setValuesSelect2] = useState([]);
  const [valueSelect1, setValueSelect1] = useState(defaultValue);
  const [, setValueSelect2] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const createOption = (option) => ({
    label: option.label,
    value: option.value,
    ref: option.ref,
    name: object.name || object.label,
  });

  const calcValuesSelect1 = () => {
    const newData = options.map((object) => ({
      value: object.id,
      ref: object.ref,
      label: object.label,
      name: object.name || object.label,
    }));
    setValuesSelect1(newData);
  };

  // const calcValuesSelect2 = () => {
  //   const currentLevel1 = valueSelect1?.ref;

  //   const newValues = [];

  //   currentLevel1
  //     ? valuesSelect1.map((item, index) => {
  //         if (item.ref === currentLevel1) {
  //           options[index].options?.forEach((option) => {
  //             if (option.label !== undefined) {
  //               const newOption = createOption(option);

  //               newValues.push(newOption);

  //               setValueSelect2([
  //                 ...newValues.filter(function (el) {
  //                   return el != null;
  //                 }),
  //                 newValues,
  //               ]);
  //             }
  //           });
  //         }
  //       })
  //     : null;

  //   setValueSelect2(newValues);
  // };

  const handleOnChange = (valueSelect1) => {
    if (valueSelect1?.label !== undefined && valueSelect1?.label !== null && valuesSelect1.length > 1) {
      setValuesSelect2([]);
      select2?.current?.setValue('');

      setValueSelect1(valueSelect1?.label);
      console.log('Destino selected: ' + valueSelect1?.label);
      const currentLevel1 = valueSelect1?.ref;

      const newValues = [];

      switch (valueSelect1.label) {
        case 'Colgar llamada':
          setIsModalOpen(false);
          break;
        case 'Numero externo':
          setIsModalOpen(true);
          break;
        default:
          setIsModalOpen(true);
          // setLevel2(newValues);
          valuesSelect1.map((object, index) => {
            if (object.ref === currentLevel1) {
              options[index].options?.forEach((option) => {
                if (option.label !== undefined) {
                  const newOption = createOption(option);

                  newValues.push(newOption);

                  setValuesSelect2([
                    ...newValues.filter(function (el) {
                      return el != null;
                    }),
                    newValues,
                  ]);

                  // form({ action: 'select-option', value: { value: newOption.value, label: newOption.label } }, { form: 'numberingList', element: 'destination' });
                  // setValueSelect2(newValues);
                  // setValueSelect2(newValues);
                  // if (object.ref === 'destinations')
                }
              });
            }

            // else {
            //   setValuesSelect2([]);
            // }
          });
          break;
      }
      // if (valueSelect1?.ref === 'numberingList') {

      // form({ action: 'select-option', value: { value: newValues.value, label: newValues.label } }, { form: 'numberingList', element: 'destination' });
      // form({ action: 'select-option', value: { value: newValues[0].value, label: newValues[0].name } }, { form: 'numberingList', element: 'destination' });
      // }

      switch (valueSelect1?.ref) {
        case 'numberingList':
          form({ action: 'select-option', value: { value: valueSelect1.value, label: valueSelect1.label } }, { form: 'numberingList', element: 'destinationType' });
          break;

        // case 'queue':
        //   break;
        default:
        // form({ action: 'select-option', value: { value: valueSelect1.value, label: valueSelect1.label } }, { form: valueSelect1.ref, element: 'destinationTypeId' });
      }
      // setValueSelect2((prevState) => ({
      //   ...prevState,
      //   [item.element]: newValues,
      // }));
    }
  };

  const setStatus = (status) => {
    if (status !== undefined) {
      setIsModalOpen(status);

      console.log('isModalOpen updated: ' + status);
    }
  };

  useEffect(() => {
    calcValuesSelect1();
    // calcValuesSelect2();
  }, []);

  return (
    <DestinationSelectWrapper>
      <div className="destination-select">
        {/* <Select data={options} onChange={onChange} name={name} placeholder={placeholder} idPage={idPage} destinationOpen={destinationOpen} itemForm={item} defaultValue={defaultValue} /> */}
        {/* <div className="newSelect"> */}
        {/* <span className="bodyMultiForm__span">* {item.label}</span>w */}
        <Select
          className="basic-single"
          onChange={handleOnChange}
          classNamePrefix="select"
          defaultValue={defaultValue !== undefined && defaultValue !== null ? defaultValue : null}
          //  && defaultValue.length > 0
          // isLoading={isLoading}
          isClearable
          isSearchable
          options={valuesSelect1}
          // required
          placeholder={placeholder}
          ref={select1}
          id="select1"
          itemForm={item}
          name={name}
          setValidateReducer={setValidateReducer}
        />
        {/* <span className="basic__destination">{valueSelect2}</span> */}
        {/* {valueSelect2 !== undefined && valueSelect2 !== null && valueSelect2 !== '' ? (
          <Select
            className="basic"
            onChange={(event) => {
              setValueSelect2(event.target.value);
            }}
            value={valueSelect2}
            disabled={true}
            name={name}
            idPage={idPage}
            destinationOpen={destinationOpen}
            itemForm={item}
            defaultValue={defaultValue}
          />
        ) : (
          ''
        )}*/}
        {isModalOpen ? (
          <div>
            <ModalPrompt
              selectedValue={(valueSelect2) => {
                if (valueSelect2 !== undefined && valueSelect2.label !== undefined) {
                  const newValue = valueSelect2.label;
                  setValueSelect2(newValue);
                  // setInputValue((prevState) => ({
                  //   ...prevState,
                  //   Destination: valueSelect2,
                  // }));
                  // console.log('SelectedValue updated: ' + newValue);
                  form({ action: 'select-option', value: { value: Number(valueSelect2.value), label: valueSelect2.label } }, { form: item.ref.form, element: 'destinationId' });
                  setInputValue((prevState) => ({
                    ...prevState,
                    ['Destination']: { value: Number(valueSelect2.value), label: valueSelect2.label },
                    ['destinationId']: valueSelect2.label,
                  }));
                  onChange(valueSelect2, valueSelect2.name, idPage, item);
                }
              }}
              isOpen={setStatus}
              content={valuesSelect2.length > 0 ? valuesSelect2 : []}
              valueSelect1={valueSelect1 !== undefined ? valueSelect1 : ''}
            />
          </div>
        ) : null}
      </div>
    </DestinationSelectWrapper>
  );
};

DestinationSelect.propTypes = {
  onChange: func,
  setInputValue: func,
  valueSelect2: number,
  setValueSelect2: func,
  setValidateReducer: func,
  name: string,
  placeholder: string,
  idPage: number,
  destinationOpen: number,
  item: object,
  defaultValue: object,
  form: func,
};
