export const actionsTypesHeadquarter = {
  getHeadquarter: 'GET_HEADQUARTER',
  getCompanyLink: 'GET_COMPANY_LINK',
  changeCompanyFormName: 'CHANGE_COMPANY_FORM_NAME',
  changeCompanyFormId: 'CHANGE_COMPANY_FORM_ID',
  addCompanyFormDepartment: 'ADD_COMPANY_FORM_DEPARTMENT',
  removeCompanyFormDepartment: 'REMOVE_COMPANY_FORM_DEPARTMENT',
  clearCompanyForm: 'CLEAR_COMPANY_FORM',
  clearMultiselectCompanyForm: 'CLEAR_MULTISELECT_COMPANY_FORM',
  changeCompanyFormIp: 'CHANGE_COMPANY_FORM_IP',
  changeCompanyFormPort: 'CHANGE_COMPANY_FORM_PORT',
  changeCompanyFormIpBackup: 'CHANGE_COMPANY_FORM_IP_BACKUP',
  changeCompanyFormPortBackup: 'CHANGE_COMPANY_FORM_PORT_BACKUP',
};
