import React, { useEffect, useState } from 'react';
// import { Header } from '../../components/Header/Header';
// import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';
// import { menuSedes } from '../../data/dataSettings';
import SettingsNavigation from '../../navigation/SettingsNavigation/SettingsNavigation';
import { SettingsScreenWrapper } from './SettingsScreen.style';

// import { menuPreferences } from '../../data/dataPreferences';
// import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSettingsThunk } from '../../redux/thunks/settingsThunks';
import useSettings from '../../hooks/useSettings';
// import '../../styles/Screens/SettingsScreen/SettingsScreen.css';

const SettingsScreen = () => {
  // const location = useLocation();
  const { getSettingsData } = useSettings();
  // const isPrefHorarias = location.pathname.includes('settings/calendar') || location.pathname.includes('settings/schedule') ? true : false;
  const dispatch = useDispatch();
  // const isSedes = location.pathname.includes('settings/company') || location.pathname.includes('settings/department') ? true : false;
  const [, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getSettingsThunk(setLoading));
    getSettingsData();
  }, []);

  return (
    <SettingsScreenWrapper className="settings-screen">
      {/* <Header image={menuNavLeft.menuBottom[0]?.image} text={menuNavLeft.menuBottom[0]?.text} /> */}
      {/* <MenuTop menu={menuSettings} /> */}
      {/* {isPrefHorarias || isSedes ? <MenuTop menuType={'Preferencias'} menu={isSedes ? menuSedes : menuPreferences} /> : null} */}
      <SettingsNavigation />
    </SettingsScreenWrapper>
  );
};

export default SettingsScreen;
