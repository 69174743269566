import click from '../../assets/images/notFound/click.svg';
// Forms Icons
import textInput from '../../assets/images/forms/textInput.svg';
import extensionIcon from '../../assets/images/forms/affiliate.svg';
// import speakerphone from '../../assets/images/forms/speakerphone.svg';
import phone from '../../assets/images/forms/phone.svg';
import stethoscope from '../../assets/images/forms/stethoscope.svg';
// import alarm from '../../assets/images/forms/alarm.svg';
// import phoneOutgoing from '../../assets/images/forms/phone-outgoing.svg';
// import volume from '../../assets/images/forms/volume.svg';
//Card Icons
import phoneCard from '../../assets/images/numbering/phoneCard.svg';
import home from '../../assets/images/numbering/home.svg';
import hierarchy from '../../assets/images/numbering/hierarchy.svg';

import { IconListCheck, IconPhone } from '@tabler/icons-react';

import { routesNumbering } from '../../navigation/routes';
import { typeRegex } from '../dataRegex';
import { IconBrandMedium } from '@tabler/icons-react';
import { IconCoinMonero } from '@tabler/icons-react';
import { IconListNumbers } from '@tabler/icons-react';

export const menuNumbering = [
  {
    id: 1,
    text: 'Dispositivos',
    path: routesNumbering.extensions,
    icon: IconPhone,
    restricted: 'extensions',
    content: 'Dispositivos',
  },
  {
    // text: 'Listado de numeración',
    id: 2,
    text: 'Numeraciónes externas',
    path: routesNumbering.numberingList,
    icon: IconListCheck,
    restricted: 'numberingList',
    content: 'Numeración externa',
  },
  {
    // text: 'Listado de numeración',
    id: 3,
    text: 'Marcas',
    path: routesNumbering.marcasList,
    icon: IconBrandMedium,
    restricted: 'numberingList',
    content: 'Marcas',
  },
  {
    // text: 'Listado de numeración',
    id: 4,
    text: 'Modelos',
    path: routesNumbering.modelosList,
    icon: IconCoinMonero,
    restricted: 'numberingList',
    content: 'Modelos',
  },
  {
    // text: 'Listado de numeración',
    id: 5,
    text: 'Plantillas',
    path: routesNumbering.plantillasList,
    icon: IconListNumbers,
    restricted: 'numberingList',
    content: 'Plantillas',
  },
];

export const optionsTypeExtension = [
  { label: 'Rainbow', value: 'rainbow' },
  { label: 'Teams', value: 'teams' },
  { label: 'SIP', value: 'SIP' },
  { label: 'Virtual', value: 'virtual' },
  { label: 'Terminal', value: 'terminal' },
];

export const modalNumberingList = {
  title: 'Numeración externa',
  titleEdit: 'Numeración externa',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 4,
      input: [
        // {
        //   layoutId: 2,
        //   cardType: 'input',
        //   label: 'id',
        //   placeholder: 'Calendario',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'id' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'id',
        //     validation: false,
        //     errorMessage: '* Calendario obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Calendario',
          placeholder: 'Calendario',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'calendar' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Calendario',
            validation: false,
            errorMessage: '* Calendario obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          idRef: 1,
          cardType: 'select-option',
          label: 'Horario',
          placeholder: 'Horario',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'schedule' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Horario',
            validation: false,
            errorMessage: '* Horario obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          idRef: 1,
          cardType: 'select-option',
          label: 'Locución abierto',
          placeholder: 'Locución abierto',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'soundPrompt' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Locución',
            validation: false,
          },
        },
        {
          layoutId: 2,
          idRef: 1,
          cardType: 'destinations',
          label: 'Destino abierto',
          placeholder: 'Destino',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'destinationType' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Tipo Destino',
            validation: false,
            errorMessage: '* Destino obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          idRef: 1,
          cardType: 'select-option',
          label: 'Destino abierto',
          placeholder: 'Destino',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'destination' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Destino',
            validation: false,
            errorMessage: '* Destino obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          idRef: 1,
          cardType: 'toggle',
          label: 'Por defecto',
          placeholder: 'Por defecto',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'isDefault' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Predeterminado',
            validation: false,
            errorMessage: '* Destino obligatorio',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formPlantilla = {
  title: 'Plantilla',
  titleEdit: 'Plantilla',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 4,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Nombre',
          placeholder: 'Nombre',
          ref: { form: 'plantilla', element: 'nombre' },
          defaultValues: '',
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Marca',
          placeholder: 'Marca',
          icon: stethoscope,
          ref: { form: 'plantilla', element: 'marca' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Marca',
            validation: false,
            errorMessage: '* Marca obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Modelo',
          placeholder: 'Modelo',
          icon: stethoscope,
          ref: { form: 'plantilla', element: 'modelo', type: 'associatedModelo' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Modelo',
            validation: false,
            errorMessage: '* Modelo obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'Observaciones',
          placeholder: 'Observaciones',
          icon: stethoscope,
          ref: { form: 'plantilla', element: 'observaciones' },
          options: [],
          defaultValues: '',
          required: {
            text: 'Observaciones',
            validation: false,
            errorMessage: '* Observaciones obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'blfs',
          placeholder: 'blfs',
          icon: stethoscope,
          ref: { form: 'plantilla', element: 'blfs' },
          options: [],
          defaultValues: '',
          required: {
            text: 'blfs',
            validation: false,
            errorMessage: '* blfs obligatorio',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formMarca = {
  title: 'Marca',
  titleEdit: 'Marca',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 4,
      input: [
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   icon: textInput,
        //   label: 'id',
        //   placeholder: 'id',
        //   ref: { form: 'marca', element: 'id' },
        //   defaultValues: null,
        //   required: {
        //     text: '* id',
        //     validation: true,
        //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
        //     regex: typeRegex.inputText,
        //   },
        // },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'Marca',
          placeholder: 'Marca',
          icon: stethoscope,
          ref: { form: 'marca', element: 'marca' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Marca',
            validation: false,
            errorMessage: '* Marca obligatorio',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formModelo = {
  title: 'Modelo',
  titleEdit: 'Modelo',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 4,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Modelo',
          placeholder: 'Modelo',
          ref: { form: 'modelo', element: 'modelo' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Marca',
          placeholder: 'Marca',
          icon: stethoscope,
          ref: { form: 'modelo', element: 'marca' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Marca',
            validation: false,
            errorMessage: '* Marca obligatorio',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formCreateNumberingList = {
  title: 'Numeración externa',
  titleEdit: 'Numeración externa',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 4,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Nombre',
          placeholder: 'Nombre',
          ref: { form: 'numberingList', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: phone,
          label: 'Número',
          placeholder: 'Número',
          ref: { form: 'numberingList', element: 'ddi' },
          // , unique: true },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Solo números min 3 max 10',
            regex: typeRegex.inputNumber,
            errorMessageUnique: '* Esa numeración ya existe',
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Sede',
          icon: stethoscope,
          placeholder: 'Sede',

          ref: { form: 'numberingList', element: 'companyId', type: 'dynamic' },
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Sede obligatoria',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select',
          label: 'Departamento',
          placeholder: 'Departamento',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'departmentId', type: 'associatedData' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Departamento',
            validation: true,
            errorMessage: '* Departamento obligatorio',
            regex: /^/,
          },
        },
        // {
        //   layoutId: 2,
        //   cardType: 'multiselect',
        //   label: 'Dispositivos',
        //   placeholder: 'Dispositivos',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'dispositivos' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'Dispositivos',
        //     validation: false,
        //   },
        // },
        // {
        //   layoutId: 2,
        //   cardType: 'destinations',
        //   label: 'Destino en cerrado',
        //   placeholder: 'Destino en cerrado',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'destinationCloseType' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'Destino',
        //     validation: false,
        //     errorMessage: '* Destino obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
        // {
        //   layoutId: 2,
        //   cardType: 'select',
        //   label: 'Calendario',
        //   placeholder: 'Calendario',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'calendar' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'Calendario',
        //     validation: false,
        //     errorMessage: '* Calendario obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
        // {
        //   layoutId: 2,
        //   idRef: 1,
        //   cardType: 'select',
        //   label: 'Horario',
        //   placeholder: 'Horario',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'schedule' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'Horario',
        //     validation: false,
        //     errorMessage: '* Horario obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
        // {
        //   layoutId: 2,
        //   idRef: 1,
        //   cardType: 'select',
        //   label: 'Locución abierto',
        //   placeholder: 'Locución abierto',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'soundPrompt' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'Locución',
        //     validation: false,
        //   },
        // },
        // {
        //   layoutId: 2,
        //   idRef: 1,
        //   cardType: 'destinations',
        //   label: 'Destino abierto',
        //   placeholder: 'Destino',
        //   icon: stethoscope,
        //   ref: { form: 'numberingList', element: 'destinationType' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: 'Destino',
        //     validation: false,
        //     errorMessage: '* Destino obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
      ],
    },
  ],

  // {
  //   number: '2',
  //   title: 'Cerrado',
  //   id: 2,
  //   lastStep: true,
  //   // nextStepText: 'Siguiente Paso',
  //   // columns: 2,
  //   input: [
  //     {
  //       cardType: 'select',
  //       label: 'Locución en cerrado',
  //       placeholder: 'Locución',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'soundPromptCloseId' },
  //       options: [],
  //       defaultValues: {},
  //       required: {
  //         text: 'Locución',
  //         validation: false,
  //       },
  //     },
  //     {
  //       cardType: 'destinations',
  //       label: 'Destino en cerrado',
  //       placeholder: 'Destino en cerrado',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'destinationCloseType' },
  //       options: [],
  //       defaultValues: {},
  //       required: {
  //         text: 'Destino',
  //         validation: false,
  //         errorMessage: '* Destino obligatorio',
  //         regex: typeRegex.inputText,
  //       },
  //     },
  //   ],
  // },
  // {
  //   number: '3',
  //   title: 'Añadir',
  //   id: 3,
  //   lastStep: true,
  //   // nextStepText: 'Siguiente Paso',
  //   // columns: 2,
  //   input: [
  //     {
  //       layoutId: 1,
  //       cardType: 'select',
  //       label: 'Calendario',
  //       placeholder: 'Calendario',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'calendar' },
  //       options: [],
  //       defaultValues: {},
  //       required: {
  //         text: 'Calendario',
  //         validation: false,
  //         errorMessage: '* Calendario obligatorio',
  //         regex: typeRegex.inputText,
  //       },
  //     },
  //     {
  //       idRef: 1,
  //       cardType: 'select',
  //       label: 'Horario',
  //       placeholder: 'Horario',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'schedule' },
  //       options: [],
  //       defaultValues: {},
  //       required: {
  //         text: 'Horario',
  //         validation: false,
  //         errorMessage: '* Horario obligatorio',
  //         regex: typeRegex.inputText,
  //       },
  //     },
  //     {
  //       idRef: 1,
  //       cardType: 'select',
  //       label: 'Locución abierto',
  //       placeholder: 'Locución abierto',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'soundPrompt' },
  //       options: [],
  //       defaultValues: {},
  //       required: {
  //         text: 'Locución',
  //         validation: false,
  //       },
  //     },
  //     {
  //       idRef: 1,
  //       cardType: 'destinations',
  //       label: 'Destino abierto',
  //       placeholder: 'Destino',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'destinationType' },
  //       options: [],
  //       defaultValues: {},
  //       required: {
  //         text: 'Destino',
  //         validation: false,
  //         errorMessage: '* Destino obligatorio',
  //         regex: typeRegex.inputText,
  //       },
  //     },
  //     // {
  //     //   idRef: 1,
  //     //   cardType: 'select-option',
  //     //   label: 'Destino abierto',
  //     //   placeholder: 'Destino',
  //     //   icon: stethoscope,
  //     //   ref: { form: 'numberingList', element: 'destination' },
  //     //   options: [],
  //     //   defaultValues: {},
  //     //   required: {
  //     //     text: 'Destino',
  //     //     validation: false,
  //     //     errorMessage: '* Destino obligatorio',
  //     //     regex: typeRegex.inputText,
  //     //   },
  //     // },
  //     {
  //       idRef: 1,
  //       cardType: 'toggle',
  //       label: 'Predeterminado',
  //       placeholder: 'Destino',
  //       icon: stethoscope,
  //       ref: { form: 'numberingList', element: 'isDefault' },
  //       options: [],
  //       defaultValues: true,
  //       required: {
  //         text: 'Predet.',
  //         validation: false,
  //         errorMessage: '* Predeterminado obligatorio',
  //         regex: typeRegex.inputText,
  //       },
  //     },
  // {
  //   layoutId: 1,
  //   cardType: 'input',
  //   icon: phone,
  //   label: 'Número',
  //  s placeholder: 'Número',
  //   ref: { form: 'numberingList', element: 'destination', unique: true },
  //   defaultValues: null,
  //   required: {
  //     text: '* Número',
  //     validation: false,
  //     errorMessage: '* Solo números min 3 max 10',
  //     regex: typeRegex.inputNumber,
  //     errorMessageUnique: '* Esa numeración ya existe',
  //   },
  // },
  //   ],
  // },
  // {
  //   number: '4',
  //   title: '',
  //   id: 4,
  //   // lastStep: true,
  //   nextStepText: 'Siguiente Paso',
  //   // columns: 2,
  //   input: [
  //     // {
  //     //   layoutId: 1,
  //     //   cardType: 'multiselect',
  //     //   label: 'Calendarios',
  //     //   placeholder: 'Calendario',
  //     //   icon: stethoscope,
  //     //   ref: { form: 'numberingList', element: 'calendars' },
  //     //   options: [],
  //     //   defaultValues: {},
  //     //   required: {
  //     //     text: 'Calendarios',
  //     //     validation: false,
  //     //     errorMessage: '* Calendario obligatorio',
  //     //     regex: typeRegex.inputText,
  //     //   },
  //     // },
  //     // {
  //     //   idRef: 1,
  //     //   cardType: 'multiselect',
  //     //   label: 'Horarios',
  //     //   placeholder: 'Horario',
  //     //   icon: stethoscope,
  //     //   ref: { form: 'numberingList', element: 'schedules' },
  //     //   options: [],
  //     //   defaultValues: {},
  //     //   required: {
  //     //     text: 'Horarios',
  //     //     validation: false,
  //     //     errorMessage: '* Horario obligatorio',
  //     //     regex: typeRegex.inputText,
  //     //   },
  //     // },
  //     // {
  //     //   idRef: 1,
  //     //   cardType: 'multiselect',
  //     //   label: 'Locuciones',
  //     //   placeholder: 'Locución abierto',
  //     //   icon: stethoscope,
  //     //   ref: { form: 'numberingList', element: 'soundPrompts' },
  //     //   options: [],
  //     //   defaultValues: {},
  //     //   required: {
  //     //     text: 'Locuciones',
  //     //     validation: false,
  //     //   },
  //     // },
  //     // {
  //     //   idRef: 1,
  //     //   cardType: 'multiselect',
  //     //   label: 'Destino abierto',
  //     //   placeholder: 'Destino',
  //     //   icon: stethoscope,
  //     //   ref: { form: 'numberingList', element: 'destinationsType' },
  //     //   options: [],
  //     //   defaultValues: {},
  //     //   required: {
  //     //     text: 'Tipo Destinos',
  //     //     validation: false,
  //     //     errorMessage: '* Destino obligatorio',
  //     //     regex: typeRegex.inputText,
  //     //   },
  //     // },
  //     // {
  //     //   idRef: 1,
  //     //   cardType: 'multiselect',
  //     //   label: 'Destino id',
  //     //   placeholder: 'Destino',
  //     //   icon: stethoscope,
  //     //   ref: { form: 'numberingList', element: 'destinations' },
  //     //   options: [],
  //     //   defaultValues: {},
  //     //   required: {
  //     //     text: 'Destinos',
  //     //     validation: false,
  //     //     errorMessage: '* Destino obligatorio',
  //     //     regex: typeRegex.inputText,
  //     //   },
  //     // },
  //   ],
  // },
  // ],
};

// export const formCreateNumberingList = {
//   title: 'Crear un nuevo número',
//   titleEdit: 'Edita el número',
//   textButton: 'Añadir numeración',
//   steps: [
//     {
//       number: '1',
//       title: 'Nombre y Número',
//       id: 1,
//       nextStepText: 'Siguiente Paso',
//       input: [
//         {
//           cardType: 'id',
//           ref: { form: 'numberingList', element: 'ddiId' },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           icon: textInput,
//           label: 'Nombre',
//           placeholder: 'Nombre',
//           ref: { form: 'numberingList', element: 'name' },
//           defaultValues: null,
//           required: {
//             text: '* Nombre',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           icon: phone,
//           label: 'Número',
//           placeholder: 'Número',
//           ref: { form: 'numberingList', element: 'ddi', unique: true },
//           defaultValues: null,
//           required: {
//             text: '* Número',
//             validation: true,
//             errorMessage: '* Solo números min 3 max 10',
//             regex: typeRegex.inputNumber,
//             errorMessageUnique: '* Esa numeración ya existe',
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'select',
//           label: 'Sede',
//           icon: stethoscope,
//           placeholder: 'Sede',

//           ref: { form: 'numberingList', element: 'companyId', type: 'dynamic' },
//           options: [],
//           defaultValues: [],
//           required: {
//             text: '* Sede',
//             validation: true,
//             errorMessage: '* Sede obligatoria',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'select',
//           label: 'Departamento',
//           placeholder: 'Departamento',
//           icon: stethoscope,
//           ref: { form: 'numberingList', element: 'department', type: 'associatedData' },
//           options: [],
//           defaultValues: {},
//           required: {
//             text: '* Departamento',
//             validation: true,
//             errorMessage: '* Departamento obligatorio',
//             regex: typeRegex.inputText,
//           },
//         },
//       ],
//     },
//     {
//       number: '2',
//       title: 'Configuración de la numeración',
//       id: 2,
//       lastStep: true,
//       input: [
//         {
//           cardType: 'multiFormPages',
//           ref: { element: 'multiFormPages' },
//           pages: [
//             {
//               id: 1,
//               text: 'Calendario 1',
//               dataPage: [],
//             },
//             { text: '+' },
//           ],
//           dataRef: [
//             {
//               title: 'Calendario',
//               subtitle: 'Selecciona el calendario que desees configurar.',
//               ref: ['calendar'],
//               id: 1,
//               input: [
//                 {
//                   label: 'Calendario',
//                   placeholder: 'Calendario',
//                   icon: stethoscope,
//                   ref: { form: 'numberingList', element: 'calendar' },
//                   options: [],
//                   defaultValues: {},
//                   required: {
//                     text: '* Calendario',
//                     validation: true,
//                     errorMessage: '* Calendario obligatorio',
//                     regex: typeRegex.inputText,
//                   },
//                 },
//               ],
//             },
//             {
//               title: 'Horarios',
//               subtitle: 'Asigna uno o varios horarios al calendario previamente seleccionado y  selecciona una locución y un destino en abierto a cada horario.',
//               ref: ['schedule', 'soundPrompt', 'destination'],
//               id: 2,
//               destinationOpen: [
//                 {
//                   id: 1,
//                   input: [
//                     {
//                       idRef: 1,
//                       cardType: 'select',
//                       label: 'Horario',
//                       placeholder: 'Horario',
//                       icon: stethoscope,
//                       ref: { form: 'numberingList', element: 'schedule' },
//                       options: [],
//                       defaultValues: {},
//                       required: {
//                         text: '* Horario',
//                         validation: true,
//                         errorMessage: '* Horario obligatorio',
//                         regex: typeRegex.inputText,
//                       },
//                     },
//                     {
//                       idRef: 1,
//                       cardType: 'select',
//                       label: 'Locución abierto',
//                       placeholder: 'Locución abierto',
//                       icon: stethoscope,
//                       ref: { form: 'numberingList', element: 'soundPrompt' },
//                       options: [],
//                       defaultValues: {},
//                       required: {
//                         text: 'Locución abierto',
//                         validation: false,
//                       },
//                     },
//                     {
//                       idRef: 1,
//                       cardType: 'select',
//                       label: 'Destino abierto',
//                       placeholder: 'Destino abierto',
//                       icon: stethoscope,
//                       ref: { form: 'numberingList', element: 'w' },
//                       options: [],
//                       defaultValues: {},
//                       required: {
//                         text: '* Destino abierto',
//                         validation: true,
//                         errorMessage: '* Destino obligatorio',
//                         regex: typeRegex.inputText,
//                       },
//                     },
//                   ],
//                 },
//               ],
//             },
//             {
//               title: 'Locución y destino en cerrado',
//               subtitle: 'Configura la locución y el destino en cerrado',
//               ref: ['soundPromptClose', 'destinationClose'],
//               id: 3,
//               input: [
//                 {
//                   cardType: 'select',
//                   label: 'Locución en cerrado',
//                   placeholder: 'Locución en cerrado',
//                   icon: stethoscope,
//                   ref: { form: 'numberingList', element: 'soundPromptClose' },
//                   options: [],
//                   defaultValues: {},
//                   required: {
//                     text: 'Locución en cerrado',
//                     validation: false,
//                   },
//                 },
//                 {
//                   cardType: 'select',
//                   label: 'Destino en cerrado',
//                   placeholder: 'Destino en cerrado',
//                   icon: stethoscope,
//                   ref: { form: 'numberingList', element: 'destinationClose' },
//                   options: [],
//                   defaultValues: {},
//                   required: {
//                     text: '* Destino en cerrado',
//                     validation: true,
//                     errorMessage: '* Destino obligatorio',
//                     regex: typeRegex.inputText,
//                   },
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };

export const modalCreateNumberingExtensions = {
  title: 'Crear nueva extensión',
  titleEdit: 'Edita la extensión',
  textButton: 'Guardar',

  steps: [
    {
      number: '1',
      title: 'Datos generales',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        // {
        //   layoutId: 1,
        //   cardType: 'toggle',
        //   label: 'Mostrar sólo dispositivos sin asignar',
        //   placeholder: 'Filtrar',
        //   ref: { form: 'extension', element: 'filterType' },
        //   defaultValues: false,
        //   // icon: speakerphone,
        //   required: {
        //     text: 'Mostrar sólo dispositivos sin asignar',

        //     validation: false,
        //     errorMessage: '',
        //     regex: /^/,
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'select-option',
        //   label: 'Propietario',
        //   placeholder: 'Propietario',
        //   ref: { form: 'extension', element: 'userId' },
        //   defaultValues: null,
        //   icon: phone,
        //   required: {
        //     text: '* Propietario',
        //     validation: true,
        //     errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
        //     regex: typeRegex.inputNumber,
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   label: 'Nombre',
        //   placeholder: 'Nombre',
        //   icon: textInput,

        //   ref: { form: 'extension', element: 'name', unique: true },
        //   defaultValues: null,
        //   required: {
        //     validation: true,
        //     text: '* Nombre',
        //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
        //     regex: typeRegex.inputText,
        //     errorMessageUnique: '* Ya hay una extension con este nombre',
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   label: 'Número',
        //   placeholder: 'Extensión',
        //   icon: phone,
        //   ref: { form: 'extension', element: 'extension', unique: true },
        //   defaultValues: null,
        //   required: {
        //     text: '* Número',
        //     validation: true,
        //     errorMessage: '* Solo números min 3 max 10',
        //     regex: typeRegex.inputNumber,
        //     errorMessageUnique: '* Ya hay una extension con este número',
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   label: 'Identificación de salida',
        //   // icon: phoneOutgoing,
        //   placeholder: 'Identificación de salida',
        //   ref: { form: 'extension', element: 'identification' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: '* Identificación',
        //     validation: true,
        //     errorMessage: '* Selecciona una opción',
        //     regex: typeRegex.inputNumber,
        //   },
        // },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Propietario',
          placeholder: 'Propietario',
          ref: { form: 'extension', element: 'userId' },
          defaultValues: null,
          icon: phone,
          required: {
            text: '* Propietario',
            validation: true,
            errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'typeExtension',
          placeholder: 'typeExtension',
          ref: { form: 'extension', element: 'typeExtension' },
          defaultValues: null,
          icon: phone,
          required: {
            text: '* typeExtension',
            validation: true,
            errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
            regex: typeRegex.inputNumber,
          },
        },
        // {
        //   layoutId: 1,
        //   cardType: 'toggle',
        //   label: 'Mostrar sólo dispositivos sin asignar',
        //   placeholder: 'Filtrar',
        //   ref: { form: 'extension', element: 'filterType' },
        //   defaultValues: false,
        //   // icon: speakerphone,
        //   required: {
        //     text: 'Mostrar sólo dispositivos sin asignar',

        //     validation: false,
        //     errorMessage: '',
        //     regex: /^/,
        //   },
        // },
        // {
        //   idRef: 1,
        //   cardType: 'toggle',
        //   label: 'dispositivo',
        //   placeholder: 'Tipo dispositivo',
        //   // icon: stethoscope,
        //   ref: { form: 'extension', element: 'filterType' },
        //   options: [
        //     { value: 1, label: 'Sin asignar' },
        //     { value: 2, label: 'Todos' },
        //   ],
        //   defaultValues: { value: 1, label: 'Sin asignar' },
        //   required: {
        //     text: 'Mostrar todos',
        //     validation: true,
        //     errorMessage: '* dispositivo obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'select-option',
        //   label: 'Tipo de dispositivo',
        //   icon: extensionIcon,
        //   placeholder: 'Tipo de dispositivo',
        //   ref: { form: 'extension', element: 'typeExtension' },
        //   options: [
        //     { label: 'Rainbow', value: 'rainbow' },
        //     { label: 'Teams', value: 'teams' },
        //     { label: 'SIP', value: 'SIP' },
        //     { label: 'Virtual', value: 'virtual' },
        //   ],
        //   defaultValues: {},
        //   required: {
        //     text: '* Tipo',
        //     validation: true,
        //     regex: typeRegex.inputText,
        //     errorMessage: '* Agregar un tipo de dispositivo',
        //   },
        // },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Dispositivo',
          placeholder: 'Identificador de dispositivo',
          icon: phone,
          ref: { form: 'extension', element: 'identification' },
          defaultValues: null,
          required: {
            text: '* Dispositivo',
            validation: true,
            errorMessage: '* Identificador obligatorio',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Ya hay un usuario con ese dispositivo',
          },
        },

        // {
        //   layoutId: 1,
        //   cardType: 'inputRandom',
        //   label: 'Contraseña SIP',
        //   placeholder: 'Contraseña SIP',
        //   ref: { form: 'extension', element: 'password' },
        //   defaultValues: null,
        //   icon: phone,
        //   required: {
        //     text: '* Contraseña SIP',
        //     validation: true,
        //     errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
        //     regex: typeRegex.inputPassword,
        //   },
        // },

        {
          layoutId: 2,
          cardType: 'multiselect',
          icon: textInput,
          label: 'Allow',
          placeholder: 'Allow',
          ref: { form: 'extension', element: 'allow' },
          defaultValues: [],
          // options: [
          //   { label: 'Alaw', value: 'alaw', id: 1 },
          //   { label: 'Ulaw', value: 'ulaw', id: 2 },
          //   { label: 'g729', value: 'g729', id: 3 },
          // ],
          required: {
            text: '* Allow',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
    // {
    //   number: '2',
    //   title: 'Tipo de extensión, Número de desvío, No contestar, Buzón',
    //   id: 2,
    //   nextStepText: 'Siguiente Paso',
    //   input: [
    // {
    //   layoutId: 2,
    //   cardType: 'input',
    //   label: 'Número  de desvío inmediato',
    //   icon: phone,
    //   placeholder: 'Número inmediato',
    //   ref: { form: 'extension', element: 'callForward' },
    //   defaultValues: null,
    //   required: {
    //     text: 'Número  de desvío inmediato',
    //     validation: true,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    // {
    //   layoutId: 2,
    //   cardType: 'input',
    //   label: 'Número  de desvío por no contestar',
    //   icon: phone,
    //   placeholder: 'Numero no contestar',
    //   ref: { form: 'extension', element: 'callForwardNA' },
    //   defaultValues: null,
    //   required: {
    //     text: 'Número  de desvío por no contestar',
    //     validation: true,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    // {
    //   layoutId: 2,
    //   cardType: 'toggle',
    //   label: 'Buzón de voz',
    //   placeholder: 'Buzón de voz',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Buzón de voz',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    //   ],
    // },
    // {
    //   number: '2',
    //   title: 'Opciones',
    //   id: 2,
    //   nextStepText: 'Siguiente Paso',
    //   input: [
    //     {
    //       layoutId: 2,
    //       cardType: 'multiselect',
    //       icon: textInput,
    //       label: 'Allow',
    //       placeholder: 'Allow',
    //       ref: { form: 'extension', element: 'allow' },
    //       defaultValues: [],

    //       required: {
    //         text: '* Allow',
    //         validation: true,
    //         errorMessage: '',
    //         regex: /^/,
    //       },
    //     },

    //   ],
    // },
    // {
    //   number: '4',
    //   title: 'Grabaciones, Llamada en espera',
    //   id: 4,
    //   nextStepText: 'Siguiente Paso',
    //   input: [
    //     {
    //       layoutId: 4,
    //       cardType: 'radio',
    //       label: 'Grabaciones',
    //       placeholder: 'Grabaciones',
    //       // icon: volume,
    //       ref: { form: 'extension', element: 'record' },
    //       defaultValues: '',
    //       radioType: 'multi',
    //       options: [
    //         { label: 'Internas', value: 'internas', id: 1 },
    //         { label: 'Salientes', value: 'salientes', id: 2 },
    //         { label: 'Externas', value: 'externas', id: 3 },
    //       ],
    //       required: {
    //         text: 'Grabaciones',
    //         validation: false,
    //         errorMessage: 'Selecciona una opción',
    //         regex: /^/,
    //       },
    //     },
    // {
    //   layoutId: 4,
    //   cardType: 'toggle',
    //   label: 'Internas',
    //   placeholder: 'Grabaciones',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Grabaciones Internas',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },

    // {
    //   layoutId: 4,
    //   cardType: 'toggle',
    //   label: 'Salientes',
    //   placeholder: 'Grabaciones',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Grabaciones Salientes',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    // {
    //   layoutId: 4,
    //   cardType: 'toggle',
    //   label: 'Externas',
    //   placeholder: 'Grabaciones',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Grabaciones Externas',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    //     {
    //       layoutId: 4,
    //       cardType: 'toggle',
    //       label: 'Llamada en espera',
    //       placeholder: 'Llamada en espera',
    //       ref: { form: 'extension', element: 'callWaiting' },
    //       defaultValues: false,
    //       icon: speakerphone,

    //       required: {
    //         text: 'LLamada en espera',
    //         validation: false,
    //         errorMessage: '',
    //         regex: /^/,
    //       },
    //     },
    //   ],
    // },
    // {
    //   number: '5',
    //   title: 'Grupos de captura',
    //   id: 5,
    //   nextStepText: 'Siguiente Paso',
    //   lastStep: true,
    //   input: [
    //     {
    //       layoutId: 5,
    //       cardType: 'multiselect',
    //       label: 'Grupos de captura',
    //       icon: textInput,
    //       placeholder: 'Grupos de captura',
    //       ref: { form: 'extension', element: 'pikedGroup', type: 'associatedData' },
    //       defaultValues: '',

    //       options: [],
    //       required: {
    //         text: 'Grupos de captura',
    //         validation: false,
    //         errorMessage: '',
    //         regex: /^/,
    //       },
    //     },
    // ],
    // },
  ],
};

export const modalNumberingExtensions = {
  title: 'Crear nueva extensión',
  titleEdit: 'Edita la extensión',
  textButton: 'Guardar',

  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          idRef: 1,
          cardType: 'select-option',
          label: 'Propietario',
          placeholder: 'Propietario',
          // icon: stethoscope,
          ref: { form: 'extension', element: 'userId' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Propietario',
            validation: true,
            errorMessage: '* Propietario obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Tipo de dispositivo',
          icon: extensionIcon,
          placeholder: 'Tipo de dispositivo',
          ref: { form: 'extension', element: 'typeExtension' },
          options: [
            // { label: 'Todos', value: 'todos' },
            { label: 'Rainbow', value: 'rainbow' },
            { label: 'Teams', value: 'teams' },
            { label: 'SIP', value: 'SIP' },
            { label: 'Virtual', value: 'virtual' },
            { label: 'Terminal', value: 'terminal' },
          ],
          defaultValues: { label: 'SIP', value: 'SIP' },
          required: {
            text: '* Tipo',
            validation: true,
            regex: /^/,
            errorMessage: '* Agregar un tipo de dispositivo',
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Dispositivo ',
          placeholder: 'identification',
          icon: phone,
          ref: { form: 'extension', element: 'identification' },
          defaultValues: null,
          required: {
            text: '* identification',
            validation: true,
            errorMessage: '* Campo obligatorio',
            regex: typeRegex.inputNumber,
            errorMessageUnique: '* Identification obligatorio',
          },
        },

        {
          layoutId: 1,
          cardType: 'multiselect',
          icon: textInput,
          label: 'Allow',
          placeholder: 'Allow',
          ref: { form: 'extension', element: 'allow' },
          defaultValues: [],
          required: {
            text: '* Allow',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          icon: textInput,
          label: 'Marca',
          placeholder: 'Marca',
          ref: { form: 'extension', element: 'marca' },
          defaultValues: [],
          required: {
            text: '* Marca',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          icon: textInput,
          label: 'Modelo',
          placeholder: 'Modelo',
          ref: { form: 'extension', element: 'modelo' },
          defaultValues: [],
          required: {
            text: '* Modelo',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          icon: textInput,
          label: 'Plantilla',
          placeholder: 'Plantilla',
          ref: { form: 'extension', element: 'plantilla' },
          defaultValues: [],
          required: {
            text: '* Plantilla',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
  ],
};

export const formCreateNumberingExtensions = {
  title: 'Crear nueva extensión',
  titleEdit: 'Edita la extensión',
  textButton: 'Guardar',

  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Propietario',
          placeholder: 'Propietario',
          ref: { form: 'extension', element: 'userId' },
          defaultValues: null,
          icon: phone,
          required: {
            text: '* Propietario',
            validation: false,
            errorMessage: '* Campo obligatorio',
            regex: /^(?:\s*[a-zA-Z]+){1,10}/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Extensión',
          icon: extensionIcon,
          placeholder: 'Tipo de dispositivo',
          ref: { form: 'extension', element: 'typeExtension' },
          options: [
            // { label: 'Todos', value: 'todos' },
            { label: 'Rainbow', value: 'rainbow' },
            { label: 'Teams', value: 'teams' },
            { label: 'SIP', value: 'SIP' },
            { label: 'Virtual', value: 'virtual' },
            { label: 'Terminal', value: 'terminal' },
          ],
          defaultValues: {},
          // defaultValues: {},
          required: {
            text: '* Tipo',
            validation: false,
            regex: typeRegex.inputText,
            errorMessage: '* Agregar un tipo de dispositivo',
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Identificador ',
          placeholder: 'Identificador',
          icon: phone,
          ref: { form: 'extension', element: 'identification' },
          defaultValues: null,
          required: {
            text: '',
            validation: true,
            errorMessage: '* Campo obligatorio',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Ya hay un usuario con ese dispositivo',
          },
        },

        // {
        //   layoutId: 1,
        //   cardType: 'inputRandom',
        //   label: 'Contraseña SIP',
        //   placeholder: 'Contraseña SIP',
        //   ref: { form: 'extension', element: 'password' },
        //   defaultValues: null,
        //   icon: phone,
        //   required: {
        //     text: '* Contraseña SIP',
        //     validation: true,
        //     errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
        //     regex: typeRegex.inputPassword,
        //   },
        // },

        {
          layoutId: 2,
          cardType: 'select-option',
          icon: textInput,
          label: 'Allow',
          placeholder: 'Allow',
          ref: { form: 'extension', element: 'allow' },
          defaultValues: [],
          // options: [
          //   { label: 'Alaw', value: 'alaw', id: 1 },
          //   { label: 'Ulaw', value: 'ulaw', id: 2 },
          //   { label: 'g729', value: 'g729', id: 3 },
          // ],
          required: {
            text: '* Allow',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          icon: textInput,
          label: 'Marca',
          placeholder: 'Marca',
          ref: { form: 'extension', element: 'marca' },
          defaultValues: {},

          required: {
            text: '* Marca',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          icon: textInput,
          label: 'Modelo',
          placeholder: 'Modelo',
          ref: { form: 'extension', element: 'modelo' },
          defaultValues: {},

          required: {
            text: '* Modelo',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          icon: textInput,
          label: 'Plantilla',
          placeholder: 'Plantilla',
          ref: { form: 'extension', element: 'plantilla' },
          defaultValues: [],
          required: {
            text: '* Plantilla',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   label: 'Nombre',
        //   placeholder: 'Nombre',
        //   icon: textInput,

        //   ref: { form: 'extension', element: 'name', unique: true },
        //   defaultValues: null,
        //   required: {
        //     validation: true,
        //     text: '* Nombre',
        //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
        //     regex: typeRegex.inputText,
        //     errorMessageUnique: '* Ya hay una extension con este nombre',
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   label: 'Número',
        //   placeholder: 'Extensión',
        //   icon: phone,
        //   ref: { form: 'extension', element: 'extension', unique: true },
        //   defaultValues: null,
        //   required: {
        //     text: '* Número',
        //     validation: true,
        //     errorMessage: '* Solo números min 3 max 10',
        //     regex: typeRegex.inputNumber,
        //     errorMessageUnique: '* Ya hay una extension con este número',
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'input',
        //   label: 'Identificación de salida',
        //   // icon: phoneOutgoing,
        //   placeholder: 'Identificación de salida',
        //   ref: { form: 'extension', element: 'identification' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: '* Identificación',
        //     validation: true,
        //     errorMessage: '* Selecciona una opción',
        //     regex: typeRegex.inputNumber,
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'select-option',
        //   label: 'Propietario',
        //   placeholder: 'Propietario',
        //   ref: { form: 'extension', element: 'userId' },
        //   defaultValues: null,
        //   icon: phone,
        //   required: {
        //     text: '* Propietario',
        //     validation: true,
        //     errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
        //     regex: typeRegex.inputNumber,
        //   },
        // },
        // {
        //   idRef: 1,
        //   cardType: 'select-option',
        //   label: 'Propietario',
        //   placeholder: 'Propietario',
        //   // icon: stethoscope,
        //   ref: { form: 'extension', element: 'userId' },
        //   options: [],
        //   defaultValues: {},
        //   required: {
        //     text: '* Propietario',
        //     validation: true,
        //     errorMessage: '* Propietario obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
        // {
        //   layoutId: 1,
        //   cardType: 'toggle',
        //   label: 'Mostrar sólo dispositivos sin asignar',
        //   placeholder: 'Filtrar',
        //   ref: { form: 'extension', element: 'filterType' },
        //   defaultValues: false,
        //   // icon: speakerphone,
        //   required: {
        //     text: 'Mostrar sólo dispositivos sin asignar',

        //     validation: false,
        //     errorMessage: '',
        //     regex: /^/,
        //   },
        // },
        // {
        //   idRef: 1,
        //   cardType: 'toggle',
        //   label: 'dispositivo',
        //   placeholder: 'Tipo dispositivo',
        //   // icon: stethoscope,
        //   ref: { form: 'extension', element: 'filterType' },
        //   options: [
        //     { value: 1, label: 'Sin asignar' },
        //     { value: 2, label: 'Todos' },
        //   ],
        //   defaultValues: { value: 1, label: 'Sin asignar' },
        //   required: {
        //     text: 'Mostrar todos',
        //     validation: true,
        //     errorMessage: '* dispositivo obligatorio',
        //     regex: typeRegex.inputText,
        //   },
        // },
      ],
    },
    // {
    //   number: '2',
    //   title: 'Tipo de extensión, Número de desvío, No contestar, Buzón',
    //   id: 2,
    //   nextStepText: 'Siguiente Paso',
    //   input: [
    // {
    //   layoutId: 2,
    //   cardType: 'input',
    //   label: 'Número  de desvío inmediato',
    //   icon: phone,
    //   placeholder: 'Número inmediato',
    //   ref: { form: 'extension', element: 'callForward' },
    //   defaultValues: null,
    //   required: {
    //     text: 'Número  de desvío inmediato',
    //     validation: true,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    // {
    //   layoutId: 2,
    //   cardType: 'input',
    //   label: 'Número  de desvío por no contestar',
    //   icon: phone,
    //   placeholder: 'Numero no contestar',
    //   ref: { form: 'extension', element: 'callForwardNA' },
    //   defaultValues: null,
    //   required: {
    //     text: 'Número  de desvío por no contestar',
    //     validation: true,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    // {
    //   layoutId: 2,
    //   cardType: 'toggle',
    //   label: 'Buzón de voz',
    //   placeholder: 'Buzón de voz',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Buzón de voz',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    //   ],
    // },
    // {
    //   number: '2',
    //   title: 'Opciones',
    //   id: 2,
    //   nextStepText: 'Siguiente Paso',
    //   input: [
    //     {
    //       layoutId: 2,
    //       cardType: 'multiselect',
    //       icon: textInput,
    //       label: 'Allow',
    //       placeholder: 'Allow',
    //       ref: { form: 'extension', element: 'allow' },
    //       defaultValues: [],

    //       required: {
    //         text: '* Allow',
    //         validation: true,
    //         errorMessage: '',
    //         regex: /^/,
    //       },
    //     },

    //   ],
    // },
    // {
    //   number: '4',
    //   title: 'Grabaciones, Llamada en espera',
    //   id: 4,
    //   nextStepText: 'Siguiente Paso',
    //   input: [
    //     {
    //       layoutId: 4,
    //       cardType: 'radio',
    //       label: 'Grabaciones',
    //       placeholder: 'Grabaciones',
    //       // icon: volume,
    //       ref: { form: 'extension', element: 'record' },
    //       defaultValues: '',
    //       radioType: 'multi',
    //       options: [
    //         { label: 'Internas', value: 'internas', id: 1 },
    //         { label: 'Salientes', value: 'salientes', id: 2 },
    //         { label: 'Externas', value: 'externas', id: 3 },
    //       ],
    //       required: {
    //         text: 'Grabaciones',
    //         validation: false,
    //         errorMessage: 'Selecciona una opción',
    //         regex: /^/,
    //       },
    //     },
    // {
    //   layoutId: 4,
    //   cardType: 'toggle',
    //   label: 'Internas',
    //   placeholder: 'Grabaciones',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Grabaciones Internas',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },

    // {
    //   layoutId: 4,
    //   cardType: 'toggle',
    //   label: 'Salientes',
    //   placeholder: 'Grabaciones',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Grabaciones Salientes',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    // {
    //   layoutId: 4,
    //   cardType: 'toggle',
    //   label: 'Externas',
    //   placeholder: 'Grabaciones',
    //   ref: { form: 'extension', element: 'voicemailActive' },
    //   defaultValues: false,
    //   icon: speakerphone,
    //   required: {
    //     text: 'Grabaciones Externas',
    //     validation: false,
    //     errorMessage: '',
    //     regex: /^/,
    //   },
    // },
    //     {
    //       layoutId: 4,
    //       cardType: 'toggle',
    //       label: 'Llamada en espera',
    //       placeholder: 'Llamada en espera',
    //       ref: { form: 'extension', element: 'callWaiting' },
    //       defaultValues: false,
    //       icon: speakerphone,

    //       required: {
    //         text: 'LLamada en espera',
    //         validation: false,
    //         errorMessage: '',
    //         regex: /^/,
    //       },
    //     },
    //   ],
    // },
    // {
    //   number: '5',
    //   title: 'Grupos de captura',
    //   id: 5,
    //   nextStepText: 'Siguiente Paso',
    //   lastStep: true,
    //   input: [
    //     {
    //       layoutId: 5,
    //       cardType: 'multiselect',
    //       label: 'Grupos de captura',
    //       icon: textInput,
    //       placeholder: 'Grupos de captura',
    //       ref: { form: 'extension', element: 'pikedGroup', type: 'associatedData' },
    //       defaultValues: '',

    //       options: [],
    //       required: {
    //         text: 'Grupos de captura',
    //         validation: false,
    //         errorMessage: '',
    //         regex: /^/,
    //       },
    //     },
    // ],
    // },
  ],
};

// export const modalCreateNumberingExtensions = {
//   title: 'Crear nueva extensión',
//   titleEdit: 'Edita la extensión',
//   textButton: 'Guardar',

//   steps: [
//     {
//       number: '1',
//       title: 'Datos generales',
//       id: 1,
//       nextStepText: 'Siguiente Paso',
//       input: [

//         {
//           layoutId: 1,
//           cardType: 'toggle',
//           label: 'Mostrar sólo dispositivos sin asignar',
//           placeholder: 'Filtrar',
//           ref: { form: 'extension', element: 'filterType' },
//           defaultValues: false,
//           // icon: speakerphone,
//           required: {
//             text: 'Mostrar sólo dispositivos sin asignar',

//             validation: false,
//             errorMessage: '',
//             regex: /^/,
//           },
//         },

//         {
//           layoutId: 1,
//           cardType: 'select',
//           label: 'Extensión',
//           icon: extensionIcon,
//           placeholder: 'Seleccionar tipo de dispositivo',
//           ref: { form: 'extension', element: 'typeExtension' },
//           options: [
//             { label: 'Rainbow', value: 'rainbow' },
//             { label: 'Teams', value: 'teams' },
//             { label: 'SIP', value: 'SIP' },
//             { label: 'Virtual', value: 'virtual' },
//           ],
//           defaultValues: {},
//           required: {
//             text: '* Tipo',
//             validation: true,
//             regex: typeRegex.inputText,
//             errorMessage: '* Agregar un tipo de dispositivo',
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'multiselect',
//           label: 'Dispositivo',
//           placeholder: 'Seleccionar dispositivo',
//           icon: phone,
//           ref: { form: 'extension', element: 'identification' },
//           defaultValues: null,
//           required: {
//             text: '* Dispositivo',
//             validation: true,
//             errorMessage: '* Solo números min 3 max 10',
//             regex: typeRegex.inputNumber,
//             errorMessageUnique: '* Ya hay un usuario con ese dispositivo',
//           },
//         },

//         {
//           layoutId: 1,
//           cardType: 'inputRandom',
//           label: 'Contraseña SIP',
//           placeholder: 'Contraseña SIP',
//           ref: { form: 'extension', element: 'password' },
//           defaultValues: null,
//           icon: phone,
//           required: {
//             text: '* Contraseña SIP',
//             validation: true,
//             errorMessage: '* 8 carácteres mínimo 1 letra 1 número',
//             regex: typeRegex.inputPassword,
//           },
//         },

//         {
//           layoutId: 2,
//           cardType: 'multiselect',
//           icon: textInput,
//           label: 'Allow',
//           placeholder: 'Allow',
//           ref: { form: 'extension', element: 'allow' },
//           defaultValues: [],

//           required: {
//             text: '* Allow',
//             validation: true,
//             errorMessage: '',
//             regex: /^/,
//           },
//         },
//       ],
//     },

//   ],
// };

export const dataScreenExtensions = {
  icon: {
    phoneCard,
    home,
    hierarchy,
  },
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna extensión creada',
    subtitle: 'Empieza por configurar una nueva extensión para visualizar en tu escritorio',
    textButton: 'Crear extensión',
  },
};

export const dataScreenListNumbering = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna lista de numeración creada',
    subtitle: 'Empieza por configurar una nueva lista de numeración para visualizar en tu escritorio',
    textButton: 'Crear lista de numeración',
  },
  icon: {
    phoneCard,
    home,
    hierarchy,
  },
};

export const dataFilterExtension = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Departamento',
      type: 'select',
      ref: 'department',
      options: [],
    },
    {
      label: 'Identificación',
      type: 'select',
      ref: 'identification',
      options: [],
    },
    {
      label: 'Tipo de extensión',
      type: 'select',
      ref: 'typeExtension',
      options: [],
    },
  ],
};
