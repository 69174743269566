import { actionsTypesSettings } from './actionsTypes';

export const getPrivilegesAction = (privileges) => {
  return {
    type: actionsTypesSettings.getPrivileges,
    privileges,
  };
};

export const getMenuSubmenuAction = (arrayMenuSubmenu) => {
  return {
    type: actionsTypesSettings.getMenuSubmenu,
    arrayMenuSubmenu,
  };
};

export const postPrivilegesAction = (privileges) => {
  return {
    type: actionsTypesSettings.postPrivileges,
    privileges,
  };
};

export const getRolesAction = (roles) => {
  return {
    type: actionsTypesSettings.getRoles,
    roles,
  };
};

export const getApiAction = (apiKeys) => {
  return {
    type: actionsTypesSettings.getApi,
    apiKeys,
  };
};

export const changeApiFormAction = (apiKey) => {
  return {
    type: actionsTypesSettings.changeApiForm,
    apiKey,
  };
};

export const changeNameApiFormAction = (name) => {
  return {
    type: actionsTypesSettings.changeNameApiForm,
    name,
  };
};

export const clearApiFormAction = () => {
  return {
    type: actionsTypesSettings.clearApiForm,
  };
};

export const clearConfigFormAction = () => {
  return {
    type: actionsTypesSettings.clearFormConfig,
  };
};

export const getDomainsAction = (domains) => {
  return {
    type: actionsTypesSettings.getDomains,
    domains,
  };
};

export const changeDomainsFormDomainAction = (domain) => {
  return {
    type: actionsTypesSettings.changeDomainsFormDomain,
    domain,
  };
};

export const changeDomainsFormIdAction = (id) => {
  return {
    type: actionsTypesSettings.changeDomainsFormId,
    id,
  };
};

export const clearFormDomainsAction = () => {
  return {
    type: actionsTypesSettings.clearFormDomains,
  };
};

export const getWhitelistAction = (whitelist) => {
  return {
    type: actionsTypesSettings.getWhitelist,
    whitelist,
  };
};

export const changeWhitelistFormIpAddrAction = (ipAddr) => {
  return {
    type: actionsTypesSettings.changeWhitelistFormIpAddr,
    ipAddr,
  };
};

export const changeWhitelistFormIdAction = (id) => {
  return {
    type: actionsTypesSettings.changeWhitelistFormId,
    id,
  };
};
export const changeSettingsFormIdAction = (id) => {
  return {
    type: actionsTypesSettings.changeSettingsFormIdAction,
    id,
  };
};
export const clearFormWhitelistAction = () => {
  return {
    type: actionsTypesSettings.clearFormWhitelist,
  };
};

//form config

export const changeConfigFormIpLocalAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormIpLocal,
    payload,
  };
};

export const changeConfigFormDomainAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormDomain,
    payload,
  };
};

export const changeConfigFormisTeams = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormisTeams,
    payload,
  };
};

export const changeConfigFormisNewUserNotifyAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormisNewUserNotify,
    payload,
  };
};
export const changeConfigFormisRainbow = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormisRainbow,
    payload,
  };
};
export const changeConfigFormisSoftphoneAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormisSoftphone,
    payload,
  };
};
export const changeConfigFormRainbowURL = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormRainbowURL,
    payload,
  };
};
export const changeConfigFormTeamsURL = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormTeamsURL,
    payload,
  };
};

export const changeConfigFormPortWssAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormPortWss,
    payload,
  };
};

export const changeConfigFormPathWssAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormPathWss,
    payload,
  };
};

export const changeConfigFormPasswordAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormPassword,
    payload,
  };
};

export const changeConfigFormAzureSubscripcionKeyAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormAzureSubscripcionKey,
    payload,
  };
};

export const changeConfigFormRutaIndicacionDeSonidoAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormRutaIndicacionDeSonido,
    payload,
  };
};

export const changeConfigFormNumeroMaximoDeExtensionesAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormNumeroMaximoDeExtensiones,
    payload,
  };
};

export const changeConfigFormDigitoDeMarcacionAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormDigitoDeMarcacion,
    payload,
  };
};

export const changeConfigFormAdministradorSecretoAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormAdministradorSecreto,
    payload,
  };
};

export const changeConfigFormUsuarioAdministradorAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormUsuarioAdministrador,
    payload,
  };
};

export const changeConfigFormPuertoAdministradorAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormPuertoAdministrador,
    payload,
  };
};

export const changeConfigFormIpAdministradorAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormIpAdministrador,
    payload,
  };
};

export const changeConfigFormExtensionStartAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormExtensionStart,
    payload,
  };
};

export const changeConfigFormExtensionEndAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormExtensionEnd,
    payload,
  };
};

export const changeConfigFormGroupStartAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormGroupStart,
    payload,
  };
};

export const changeConfigFormGroupEndAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormGroupEnd,
    payload,
  };
};

export const changeConfigFormMailBoxesStartAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormMailBoxesStart,
    payload,
  };
};

export const changeConfigFormMailBoxesEndAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormMailBoxesEnd,
    payload,
  };
};

export const changeConfigFormConferenceStartAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormConferenceStart,
    payload,
  };
};

export const changeConfigFormConferenceEndAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormConferenceEnd,
    payload,
  };
};

export const changeConfigFormShortMarkingStartAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormShortMarkingStart,
    payload,
  };
};

export const changeConfigFormShortMarkingEndAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormShortMarkingEnd,
    payload,
  };
};

export const changeConfigFormColaStartAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormColaStart,
    payload,
  };
};

export const changeConfigFormColaEndAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormColaEnd,
    payload,
  };
};

export const changeConfigFormOptAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormOpt,
    payload,
  };
};
export const changeConfigFormTimeoutAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormTimeout,
    payload,
  };
};
export const changeConfigFormTimeoutCfAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormTimeoutCf,
    payload,
  };
};
export const changeConfigFormOutTimeoutAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormOutTimeout,
    payload,
  };
};
export const changeConfigFormOutOptionsAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormOutOptions,
    payload,
  };
};

export const changeConfigFormConfrecpathAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormConfrecpath,
    payload,
  };
};

export const changeConfigFormVmgreetpathAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormVmgreetpath,
    payload,
  };
};
export const changeConfigFormCallrecpathAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormCallrecpath,
    payload,
  };
};
export const changeConfigFormMydynamicFearuresAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormMydynamicFearures,
    payload,
  };
};
export const changeConfigFormDfRingtimeoutAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormDfRingtimeout,
    payload,
  };
};
export const changeConfigFormFormatoDefaultVmAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormFormatoDefaultVm,
    payload,
  };
};
export const changeConfigFormBxferCountAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormBxferCount,
    payload,
  };
};
export const changeConfigFormSeltimeoutAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormSeltimeout,
    payload,
  };
};
export const changeConfigFormPrefixMovCorpAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormPrefixMovCorp,
    payload,
  };
};
export const changeConfigFormPriMovAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormPriMov,
    payload,
  };
};
export const changeConfigFormNumcortaOriginAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormNumcortaOrigin,
    payload,
  };
};
export const changeConfigFormCodelenAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormCodelen,
    payload,
  };
};
export const changeConfigFormOpcColaAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormOpcCola,
    payload,
  };
};
export const changeConfigFormMaxInboundChanAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormMaxInboundChan,
    payload,
  };
};

export const changeConfigFormMaquinaAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormMaquina,
    payload,
  };
};

export const addConfigFormPreferencesAction = (payload) => {
  return {
    type: actionsTypesSettings.addConfigFormPreferences,
    payload,
  };
};

export const changeConfigFormSubNotifyApiAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormSubNotifyApi,
    payload,
  };
};

export const addConfigFormCallbackAudioIdAction = (payload) => {
  return {
    type: actionsTypesSettings.addConfigFormCallbackAudioId,
    payload,
  };
};
export const addConfigFormisRainbow = (payload) => {
  return {
    type: actionsTypesSettings.addConfigFormisRainbow,
    payload,
  };
};

export const changeConfigFormRainbow_TroncalId = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormRainbow_TroncalId,
    payload,
  };
};
export const changeConfigFormTeams_TroncalId = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormTeams_TroncalId,
    payload,
  };
};
export const addConfigFormRainbow_TroncalId = (payload) => {
  return {
    type: actionsTypesSettings.addConfigFormRainbow_TroncalId,
    payload,
  };
};
export const addSettingsFormIdAction = (payload) => {
  return {
    type: actionsTypesSettings.addSettingsFormIdAction,
    payload,
  };
};
export const addConfigFormTeams_TroncalId = (payload) => {
  return {
    type: actionsTypesSettings.addConfigFormTeams_TroncalId,
    payload,
  };
};
export const changeConfigFormCapturarLlamadaAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormCapturarLlamada,
    payload,
  };
};
export const changeConfigFormTransferenciaCiegaAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormTransferenciaCiega,
    payload,
  };
};
export const changeConfigFormColgarLlamadaAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormColgarLlamada,
    payload,
  };
};
export const changeConfigFormTransferenciaAtendidaAction = (payload) => {
  return {
    type: actionsTypesSettings.changeConfigFormTransferenciaAtendida,
    payload,
  };
};

export const getConfigAction = (config) => {
  return {
    type: actionsTypesSettings.getConfig,
    config,
  };
};
export const getSettingsAction = (payload) => {
  return {
    type: actionsTypesSettings.getSettings,
    payload,
    // isRainbow: payload.isRainbow,
  };
};
