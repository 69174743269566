import styled from 'styled-components';

export const DepartmentScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  ${'' /* padding: 10px; */}
  width: auto;
  ${'' /* margin-left: 70px; */}

  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    padding: 20px 36px 0;
    //transition: all 1s easy-out;
  }
  .settings__contain__table {
    padding-left: 20px;
  }
`;
