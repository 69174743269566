import React, { useState } from 'react';
import { IconX } from '@tabler/icons-react';

import { ModalWrapper } from './Modal.style';
import { Search } from '../Search/Search';
import { TableInfo } from '../TableInfo/TableInfo';
import { array, bool, func, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { isDataModifiedActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { useNavigate } from 'react-router-dom';

export const Modal = ({
  handleCancel,
  handleConfirm,
  nextLocation,
  setUnSavedChanges,
  unSavedChanges,
  show,
  setShow,
  handleClose,
  children,
  dataModal,
  columnsModal,
  setDataCard,
  isWarning,
  isModalOpen,
}) => {
  const [search, setSearch] = useState([]);
  const classModal = isWarning ? 'modal-warning' : unSavedChanges ? 'modal__isDirty' : 'modal';
  const dispatch = useDispatch();

  const message = !isWarning && !unSavedChanges ? 'Selecciona los agentes a los que les quieras asignar una estrategia' : 'Al salir se perderan los cambios realizados.';

  const handleOnClickInModal = (event) => {
    event.stopPropagation();
  };

  const navigate = useNavigate();

  const handleOk = (event) => {
    event.stopPropagation();
    !unSavedChanges && isWarning(false);
    !unSavedChanges && isModalOpen(false);
    !unSavedChanges && show(false);
    setShow(false);
    setUnSavedChanges(false);
    // show = false;
    // setIsFormDirty(false);
    // setIsWarned(true);
    dispatch(isDataModifiedActionCreator(false));
    // setShow(false);
    navigate(nextLocation.pathname);
  };

  const handleCloseOk = (event) => {
    event.stopPropagation();
    !unSavedChanges && isModalOpen(false);
    !unSavedChanges && isWarning(false);
    // type !== 'isDirty' && show(false);
    setShow(false);
    // show = false;
    // setUnSavedChanges(false);
    // setIsWarned(false);
    // dispatch(isDataModifiedActionCreator(false));
  };

  return (
    show && (
      <ModalWrapper>
        <div className={classModal} onClick={handleOnClickInModal}>
          <div className="modal__title">
            <p className="modal__title__item"> Aviso</p>
            <div onClick={!isWarning && !unSavedChanges ? handleClose : handleCloseOk} className="modal__title__icon">
              <IconX height={'20px'} width={'20px'} className="modal__title__icon__item" />
            </div>
          </div>

          {!isWarning && !unSavedChanges ? (
            <div className="modal__search">
              <Search setSearch={setSearch} data={dataModal} columns={columnsModal} />
            </div>
          ) : (
            <div className="modal__message"> {message}</div>
          )}
          {search[0]?.notFound ? (
            <div className="modal__notFound">
              <h4>{search[0].notFound}</h4>
            </div>
          ) : (
            children === 'table' && (
              <div className="modal__children">
                <TableInfo format={'list'} COLUMNS={columnsModal} DATA={search.length === 0 ? dataModal : search} isOptions={false} isNavTable={false} setDataCard={setDataCard} isCheckbox={true} />
              </div>
            )
          )}
          <div className={classModal + '__container__buttons'}>
            {/* <Button
              backgroundColor={colors.primaryColor}
              border={'0px'}
              colorText={colors.white}
              width={'45%'}
              
              // Icon={IconPlus}
              onClick={!isWarning ? handleClose : handleOk}
              hoverBackgroundColor={colors.secondaryColor}
              hoverIcon={colors.primaryColor}
              text={!isWarning && !isDirty ? 'Añadir selección' : 'Aceptar'}
              hoverColorText={colors.primaryColor}
            />  {/* <Button
                backgroundColor={colors.primaryColor}
                border={'0px'}
                colorText={colors.white}
                width={'45%'}
                // Icon={IconPlus}
                onClick={handleCloseOk}
                hoverBackgroundColor={colors.secondaryColor}
                hoverIcon={colors.primaryColor}
                text={'Cancelar'}
                hoverColorText={colors.primaryColor}
              /> */}

            <button className="modal__button__item-ok" onClick={!isWarning && !unSavedChanges ? handleOk : handleConfirm}>
              {!isWarning && !unSavedChanges ? 'Añadir selección' : 'Salir sin guardar'}
            </button>
            {isWarning || unSavedChanges ? (
              <button className="modal__button__item-cancel" onClick={!isWarning && !unSavedChanges ? handleCloseOk : handleCancel}>
                {!isWarning && !unSavedChanges ? 'Cancelar' : 'Volver'}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </ModalWrapper>
    )
  );
};

Modal.propTypes = {
  setIsFormDirty: func,
  type: string,
  show: bool,
  unSavedChanges: bool,
  handleClose: func,
  children: string,
  dataModal: array,
  columnsModal: array,
  setDataCard: func,
  isWarning: func,
  isModalOpen: func,
  setIsWarned: func,
  setShow: func,
  setUnSavedChanges: func,
  nextLocation: string,
  handleCancel: func,
  handleConfirm: func,
};
