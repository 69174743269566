import React from 'react';
// import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuEM } from '../../data/dataEM';
import { EMNavigation } from '../../navigation/EMNavigation/EMNavigation';

export const EMScreen = () => {
  return <EMNavigation />;
  // <EmergencyWrapper>
  // <>

  // </>
  // </EmergencyWrapper>
};
