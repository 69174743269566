import styled from 'styled-components';
import { colors } from './theme/colors.js';

export const AppScreenWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  audio::-webkit-media-controls-panel {
    background-color: ${colors.white};
  }

  audio::-webkit-media-controls-play-button {
    background-color: ${colors.greenGYGAS};
    border-radius: 50%;
  }

  .group {
    min-width: 200px;
  }
  .audioplayer__icon {
    background-color: ${colors.greenGYGAS};
    border-radius: 50%;
    ${'' /* border: 1px solid ${colors.black}; */}
    padding: 8px;
    width: 32px;
    height: 32px;
    ${'' /* margin-left: 11px; */}
  }

  .listnumber__container {
    table {
      width: auto;
    }
    .form__container {
      .form__body {
        .container__cards {
          padding-bottom: 40px;
          .stepForm__contain-typeInput {
            .contain__select {
              display: flex;
              flex-direction: column;
              gap: 5px;
              font-size: 16px;

              div {
                border-radius: 10px;
              }
            }
          }
          .numb__contain-typeInput {
            ${'' /* padding: 0 20px 0 20px; */}
            height: 100%;
            width: 100%;
            margin: auto;
            ${'' /* max-width: 1000px; */}
            border-radius: 10px;

            .stepForm__contain-typeInput {
              border-radius: 15px;
              ${'' /* background-color: ${colors.primaryColorLight}; */}
              padding: 60px 40px 40px 40px;
              ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}

              ${
                '' /* &:hover {
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
          } */
              }
          span {
                color: ${colors.white};
              }
              .contain__select {
                display: flex;
                flex-direction: column;
                gap: 5px;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
  .send__user__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    ${'' /* gap: 5px; */}
    background-color: ${colors.primaryColor};
    border-radius: 15px;
    color: ${colors.white};
    padding: 10px;
    height: 35px;
    margin-right: 20px;

    &:hover {
      background-color: ${colors.primaryColorLight};
    }

    .send__user__data {
      background-color: ${colors.primaryColor};
      color: white;
      height: 20px;
      width: 20px;
      border-radius: 15px 0 0 15px;
      font-size: 15px;
    }

    span {
      padding: 10px;
    }
  }

  .modal__isDirty {
    height: 180px;
    width: 400px;
    color: ${colors.primaryColor};
    background-color: ${colors.white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .modal__message {
      padding: 20px;
      height: 100%;
      text-align: center;
      font-size: 15px;
    }
    .modal__title {
      display: flex;

      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 5px;

      .modal__title__item {
        background-color: unset;
        ${'' /* margin-top: unset; */}
        font-size: 20px;
      }
      .modal__title__icon {
        position: unset;
      }
    }
    .modal__isDirty__container__buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
      padding: 15px;

      .modal__button__item-ok {
        border-radius: 10px;
        width: 120px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
      }
      .modal__button__item-cancel {
        border-radius: 10px;
        width: 120px;
        height: 35px;
        color: ${colors.white};
        background-color: ${colors.primaryColor};
      }
    }
  }

  .contain__select div {
    font-size: 16px;
    ${'' /* color: ${colors.black}; */}

    &:selected {
      opacity: 0.5;
      border-radius: 0px;
      &:hover {
        border-radius: 0px;
      }
    }
  }

  [aria-disabled='true'] {
    opacity: 0.5;
  }

  .trunksoutboundscreen {
    box-sizing: border-box;
    height: 100%;

    .contain__table {
      box-sizing: border-box;
      height: 100%;
      position: relative;
      display: flex;
      width: 100%;
      padding-top: 70px;
      padding-left: 20px;
      overflow: scroll;
    }

    .contain__inputCard-component {
      width: 100%;
      min-height: 130px;
      margin: auto;
      background-color: ${colors.primaryColorLight};
      border-radius: 0 15px 15px 15px;
      ${'' /* min-height: 250px; */}
      margin-top: 25px;

      .stepForm__contain-typeInput {
        display: grid;
        margin: auto;
        column-gap: 20px;
        padding-top: 20px;
        grid-template-columns: 1fr 1fr 1fr;

        .color {
          justify-content: center;
          margin: auto;
        }

        .contain__input
          ${
            '' /* button {
          max-width: 200px;
        } */
          }
          .textRequired {
          margin-top: 20px;
        }
      }
    }
  }

  .diffusion-list {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin-right: 70px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: ${colors.backgroundColor};

    .contain__table {
      box-sizing: border-box;
      height: 100%;
      position: relative;
      display: flex;
      width: 100%;
      padding-top: 70px;
      padding-left: 20px;
      ${'' /* overflow: scroll; */}
    }
  }

  .customConfirmButtonSwal {
    border: none;
    color: white;
    stroke: white;
    background-color: ${colors.backgroundColor};
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dispositivos {
    min-width: 120px;
  }

  .dispositivo-col-container {
    max-width: 40px;
    min-width: unset;
  }

  .icon-usersView {
    ${'' /* background-color: ${colors.greenGYGAS}; */}
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    stroke: ${colors.primaryColor};
  }

  .icon__view {
    opacity: 0.7;
    margin-left: 25px;
    color: ${colors.primaryColor};

    &:hover {
      opacity: 1;
    }
    &:focus {
      border: none;
    }
  }

  audio::-webkit-controls {
    display: none;
  }

  .form__user__logo {
    width: 80px;
    height: 80px;
    border: 4px solid ${colors.primaryColor};
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
    border-radius: 50%;
    z-index: 9999;
    margin-top: 20px;

    svg {
      width: 50px;
      height: 50px;
    }
  }

  ${
    '' /* .form__body__modal {
    width: 100%;
    .container__cards {
      border-radius: 0px 0px 10px 10px;
      .form__title__modal {
        padding-left: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        gap: 10px;
        font-weight: bold;
        font-size: 24px;
        stroke: ${colors.white};
        color: ${colors.white};
        background-color: ${colors.primaryColorLight};
        height: 50px;
        margin-top: -50px;
        width: 100%;

        .icon__Form_title {
          width: 55px;
          height: 55px;
          padding: 5px;
          color: rgb(51, 65, 85);
          background-color: rgb(148, 206, 13);
          border-radius: 50%;
          border: 2px solid rgb(51, 65, 85);
        }

        .form__user__logo {
          padding: 10px;
        }

        .title__Form_title {
          display: flex;
          align-items: flex-end;
          color: ${colors.white};
          font-size: 16px;
          border-radius: 15px;
          justify-content: center;
          height: 100%;
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: ${colors.primaryColorLight};
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    } */
  }
  ${'' /* } */}

  .modal__container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form__body {
      height: 100%;
      .container__cards {
        height: 100%;
      }
    }
  }

  .input-disabled {
    border: 1px solid grey;
    border-radius: 5px;
    width: 100%;
    max-width: 275px;
    height: 38px;
    padding: 0 0 0 10px;
    margin: 0;
    box-sizing: border-box;
    background-color: lightgrey;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &:focus {
      padding-left: 10px;
    }
  }

  audio::-webkit-slider-thumb {
    width: 100px;
    height: 10px;
    background-color: red;
  }

  audio::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  audio::-webkit-slider-thumb:hover {
    background-color: #fff;
  }

  .header__container {
    box-sizing: border-box;
    background-color: ${colors.primaryColor};
    width: -webkit-fill-available;
    height: 50px;
    position: fixed;
    top: 0;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;

    img {
      height: 20px;
      opacity: 0.5;
      padding-top: 5px;
    }
  }

  .form__container {
    width: 85%;
    max-width: 1024px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .row {
      border-bottom: rgb(215, 215, 215) 1px solid;
      color: ${colors.primaryColor};
      cursor: pointer;
      background-color: ${colors.backgroundColor};

      .dispositivo-col-container {
        svg {
          border-radius: 50%;
          margin: auto;
          color: ${colors.primaryColor};
        }
      }

      &:hover {
        background-color: ${colors.white};
        cursor: pointer;
        ${'' /* box-shadow: rgb(110, 110, 110) 0px 0px 6px 0px; */}
      }

      .icon__password,
      .passwordWatcher__copy,
      .hideColumn__copy {
        border: 1px solid ${colors.primaryColor};

        border-radius: 10px;
      }

      .icon__dispositivo svg,
      .icon-cloud svg {
        ${'' /* background-color: ${colors.greenGYGAS}; */}
        border-radius: 50%;
        color: ${colors.primaryColor};
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }

    td {
      cursor: pointer;
      ${'' /* min-width: 140px; */}
      ${'' /* color: ${colors.primaryColor}; */}
    }

    .tooltip {
      background: ${colors.primaryColor};
      border-radius: 10px;
      border: none;
      height: auto;
    }
  }

  .numb_contain__table {
    height: 270px;

    min-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 70px;
    ${'' /* z-index: 900; */}
    border-radius: 10px;
    ${'' /* overflow: auto; */}
    padding: 20px 40px 30px;
    background-color: ${colors.primaryColorLight};
    margin: auto;

    .navTable__selectedItems {
      color: white;
    }

    .search__container {
      height: 35px;
      position: absolute;
      right: 80px;
      color: ${colors.backgroundColor};

      .search__delete__icon {
        width: 35px;
        height: 100%;
        padding-right: 5px;
        color: ${colors.backgroundColor};
      }

      input {
        color: ${colors.backgroundColor};
      }
    }

    .icon-dropDown {
      color: ${colors.white};
    }

    .icon_add_container {
      background-color: ${colors.primaryColorLight};
      color: ${colors.white};
      border-radius: 15px;
      border: 1px solid ${colors.secondaryColor};

      &:hover {
        color: ${colors.primaryColor};
        border: 1px solid ${colors.greenGYGAS};

        span {
          color: ${colors.primaryColor};
        }
      }
    }

    span {
      color: ${colors.white};
    }

    .nav__buttons__container {
      height: 50px;
    }

    .container__cards {
      top: 120px;
    }

    .left {
      width: 100%;
      padding-left: 0;
      display: flex;
      align-items: center;
      margin: auto;
      gap: 20px;
      color: white;
      border-radius: 10px 10px 0px 0;

      .icon-plus {
        width: 35px;
        height: 35px;
        padding: 5px;
        margin-left: 5px;
        background-color: #0c3fc4;
        color: white;

        &:hover {
          cursor: pointer;
          border-radius: 10px;
        }
      }
    }

    table {
      ${'' /* width: 100%; */}
      border-collapse: collapse;

      thead {
        border-radius: 10px 10px 0 0;
        color: ${colors.primaryColor};
        position: relative;

        &:first-child {
          position: absolute;
          width: 635px;
          z-index: 9;
        }

        h3 {
          color: ${colors.backgroundColor};
        }

        tr {
          top: -5px;
          width: 630px;

          &:first-child {
            background-color: ${colors.primaryColorLight};
          }
        }

        td {
          min-width: 40px;
          color: ${colors.primaryColor};
        }

        tr th:nth-child(2) {
          border-left: none;
          font-weight: 700;
          cursor: pointer;
          min-width: 40px;
        }
      }

      tbody {
        tr {
          position: relative;
          top: 45px;
        }

        .checkbox {
          margin: auto;
          min-width: 40px;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    ${
      '' /* table tbody table td:last-child {
      height: 42px;
    } */
    }

    .navtable__container {
      position: relative;
      top: 0;
      left: 0;
      justify-content: flex-start;
      margin: auto;
      color: white;
      display: flex;
      border-radius: 10px 10px 0px 0;

      table {
        position: relative;
        top: -40px;
        left: 40px;
      }
    }

    .tooltip-column {
      color: ${colors.primaryColor};
    }
    .numb__table {
      width: 60%;
    }

    .container .left {
      left: 285px;
      top: 60px;
    }

    .row {
      color: ${colors.primaryColor};
      background-color: ${colors.backgroundColor};

      ${
        '' /* .checkbox {
      
      } */
      }
      &:hover {
        ${'' /* background-color: ${colors.white}; */}
      }
    }

    .section__name {
      display: flex;
      align-items: center;
      height: 30px;
      color: ${colors.white};
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      width: max-content;
    }
  }

  .numb__table {
    width: 60%;
    position: relative;
    top: -50px;
  }

  .tooltip-column {
    ${'' /* min-width: 120px; */}
    color: black;
    width: max-content;
  }

  .tooltip-column_devices {
    width: 100%;
    color: black;
    display: flex;
    justify-content: center;
  }

  .extensions__format {
    width: 50px;
    height: 20px;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    color: white;
    background-color: ${colors.primaryColor};
    border-radius: 25px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .settings-screen {
    ${'' /* padding-top: 40px; */}
    border-radius: 10px;
    background-color: ${colors.backgroundColor};

    .form__body {
      width: 80%;
    }
    .contain__table {
      padding-top: 65px;
      padding-left: 20px;
      overflow: scroll;
    }
    .container__cards {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 60px;

      .contain__button-save {
        height: 35px;
        width: 100%;
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.backgroundColor}; */}
        ${'' /* margin-right: 50px; */}

        .cancel_button {
          background-color: ${colors.primaryColor};
          color: white;
          height: 35px;
          border-radius: 10px;
          width: 100px;
          font-size: 15px;
          margin-right: 20px;
          -webkit-border-radius: 15px;

          &:hover {
            color: white;
            background-color: ${colors.primaryColor};
          }
        }

        .save_edit_button {
          color: black;
          width: 100px;
          height: 35px;
          background-color: ${colors.greenGYGAS};
          font-size: 15px;
          border-radius: 15px;
          -webkit-border-radius: 15px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }

        .save_button {
          height: 35px;
          color: black;
          border-radius: 15px;
          width: 100px;
          background-color: ${colors.greenGYGAS};
          font-size: 15px;
          margin-right: 20px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }

        .save_button,
        .next_step {
          color: black;
          background-color: ${colors.greenGYGAS};
          border-radius: 15px;
          width: 100px;
          font-size: 15px;
          margin-right: 20px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }
      }
    }
    .form__body {
      .form__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        stroke: ${colors.white};

        .title__Form_title {
          display: flex;
          align-items: center;
          color: ${colors.white};
          font-size: 15px;
          border-radius: 15px;
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 10px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 10px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .form__body__queue {
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: scroll;
    align-items: center;
    border-top: 0;
    border-radius: 0px 10px 10px 10px;
    overflow: visible;

    .container__cards {
      margin: auto;
      border-radius: 0 10px 10px 10px;
      width: 100%;
      display: flex;
      ${'' /* gap: 45px; */}
      flex-direction: column;

      .basic__destination {
        top: -40px;
        left: 150px;
        color: ${colors.primaryColor};
      }

      .destination-select {
        width: 100%;
        min-width: 270px;
        max-width: unset;
      }

      .form__title {
        height: 25px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.primaryColorLight};
        font-size: 24px;
        font-weight: bold;
        border-radius: 10px 10px 0 0px;
        padding-left: 45px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.white};
          padding-left: 140px;
        }
      }

      .contain__inputCard-component {
        width: 100%;
        min-height: 130px;
        margin: auto;
        background-color: ${colors.primaryColorLight};
        border-radius: 0 15px 15px 15px;
        margin-top: 70px;
        ${'' /* min-height: 250px; */}
        ${'' /* margin-top: 25px; */}

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          column-gap: 20px;
          padding: 40px 20px 40px 40px;
          grid-template-columns: 1fr 1fr 1fr;

          .color {
            justify-content: center;
            margin: auto;
          }

          .contain__input,
          button {
            ${'' /* max-width: 200px; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;

        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.primaryColorLight}; */}
      }

      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .icon__Form_title {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${colors.greenGYGAS};
    padding: 5px;
    color: ${colors.primaryColor};
    border: 2px solid ${colors.primaryColor};
  }

  .icon__Form_title1 {
    width: 50px;
    height: 50px;
  }
  .form__body__calendar,
  .form__body__whitelist {
    border-radius: 10px;

    .calendar_container {
      width: 100%;
      grid-column: 1 / 4;

      .calendar_wrapper {
        margin-top: 15px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .container__cards {
      margin: auto;

      .form__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: #334155;
        font-size: 24px;
        font-weight: bold;
        width: max-content;
        border-radius: 10px 10px 0 0px;
        padding-left: 100px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.primaryColor};
        }
      }
      .contain__inputCard-component {
        padding-top: 100px;
        width: 80%;
        margin: auto;

        .stepForm__contain-typeInput {
          margin: auto;

          .color {
            justify-content: center;
            margin: auto;
          }
          .contain__input {
            button {
              ${'' /* max-width: 200px; */}
            }
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .form__body__difusion {
    border-radius: 10px;
    width: 80%;
    margin: auto;

    .contain__inputCard-component {
      margin: auto;
      width: 80%;

      .contain__select {
        font-size: 16px;
      }
    }
  }

  ${
    '' /* ,
  .form__body__whitelist,
  .form__body__difusion,
  .form__body__mailbox,
  .form__body__queue  */
  }

  .form__body__difusion {
    margin-top: 30px;
    background-color: ${colors.primaryColorLight};
    max-width: 1024px;

    .container__cards {
      margin: auto;
      border-radius: 10px;
      width: 80%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .form__title {
        height: 25px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.white};
        font-size: 24px;
        font-weight: bold;
        border-radius: 10px 10px 0 0px;
        ${'' /* padding-left: 45px; */}

        .title__Form_title {
          font-size: 18px;
          color: ${colors.white};
          ${'' /* padding-left: 140px; */}
        }
      }

      .contain__inputCard-component {
        width: 100%;
        min-height: 130px;
        margin: auto;
        background-color: ${colors.primaryColorLight};
        border-radius: 15px;
        padding-bottom: 40px;

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          column-gap: 20px;
          ${'' /* padding-top: 20px; */}
          grid-template-columns: 1fr 1fr;

          .color {
            justify-content: center;
            margin: auto;
          }

          .contain__input,
          button {
            ${'' /* max-width: 200px; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;

        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.primaryColorLight}; */}
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .form__body__ivr {
    padding-top: 50px;

    #name__0 {
      grid-column-start: 1;
      grid-row-start: 1;
      background-color: ${colors.primaryColorLight};
      padding: 30px;
      border-radius: 0px 0px 0 10px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      height: 166px;
      span {
        color: white;
      }
    }

    #company__2 {
      grid-column-start: 3;
      grid-row-start: 1;
      background-color: ${colors.primaryColorLight};
      padding: 30px;
      border-radius: 0 0px 10px 0px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      height: 166px;
      span {
        color: white;
      }
    }
    .cards__header {
      background-color: ${colors.primaryColorLight};
      height: 50px;
      border-radius: 10px 10px 0 0;
      width: 100%;
      margin: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      ${'' /* margin-bottom: 30px; */}
    }

    .form__title {
      height: 25px;
      width: 80%;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      text-align: left;
      color: ${colors.white};
      font-size: 24px;
      font-weight: bold;
      border-radius: 10px 10px 0 0px;
      ${'' /* padding-left: 45px; */}

      .title__Form_title {
        font-size: 18px;
        color: ${colors.white};
        ${'' /* padding-left: 140px; */}
      }

      .icon__Form_title {
        width: 40px;
        height: 40px;
      }
    }
    .contain__button-save {
      ${'' /* height: 35px; */}
      width: 100%;
      opacity: 1;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;

      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .form__body__mailbox,
  .form__body__conference {
    background-color: ${colors.primaryColorLight};
    border-radius: 15px;
    width: 90%;
    margin: auto;
    margin-top: 30px;

    #soundPrompt__5 {
      display: flex;
      min-width: 210px;
      width: 50%;
      grid-column-start: 1;
      grid-column-end: 4;

      audio {
        justify-content: center;
        align-items: center;
        height: 45px;
      }
    }

    .container__cards {
      margin: auto;
      border-radius: 10px;
      width: 80%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .form__title {
        height: 25px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.white};
        font-size: 24px;
        font-weight: bold;
        border-radius: 10px 10px 0 0px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.white};
        }
      }

      .contain__inputCard-component {
        width: 100%;
        ${'' /* min-height: 130px; */}
        margin: auto;
        background-color: ${colors.primaryColorLight};
        border-radius: 15px;
        ${'' /* min-height: 280px; */}

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          column-gap: 20px;
          ${'' /* padding-top: 20px; */}
          grid-template-columns: 1fr 1fr;
          padding-bottom: 40px;

          .color {
            justify-content: center;
            margin: auto;
          }

          .contain__input,
          button {
            ${'' /* max-width: 200px; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;

        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.primaryColorLight}; */}
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }
  .form__body,
  .form__body__applications {
    border-radius: 10px;
    background-color: ${colors.primaryColorLight};
    border-radius: 15px;
    width: 90%;
    margin: auto;
    margin-top: 30px;

    audio {
      height: 45px;
    }
    .container__cards {
      margin: auto;
      border-radius: 10px;
      width: 80%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .form__title {
        height: 25px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.white};
        font-size: 24px;
        font-weight: bold;
        border-radius: 10px 10px 0 0px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.white};
        }
      }

      .contain__inputCard-component {
        width: 100%;
        margin: auto;
        background-color: ${colors.primaryColorLight};
        border-radius: 15px;

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          column-gap: 20px;
          ${'' /* grid-template-columns: 1fr 1fr 1fr; */}
          padding-bottom: 40px;

          .color {
            justify-content: center;
            margin: auto;
          }

          .contain__input,
          button {
            ${'' /* max-width: 200px; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;

        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.primaryColorLight}; */}
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .form__body__plantilla {
    border-radius: 10px;
    background-color: ${colors.primaryColorLight};
    border-radius: 15px;
    width: 90%;
    margin: auto;
    margin-top: 30px;

    #marca__1 {
      grid-column-start: 2;
      ${'' /* margin: auto; */}
    }

    #observaciones__3 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    audio {
      height: 45px;
    }

    .container__cards {
      margin: auto;
      border-radius: 10px;
      width: 80%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .form__title {
        height: 25px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.white};
        font-size: 24px;
        font-weight: bold;
        border-radius: 10px 10px 0 0px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.white};
        }
      }

      .contain__inputCard-component {
        width: 100%;
        margin: auto;
        background-color: ${colors.primaryColorLight};
        border-radius: 15px;

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          column-gap: 20px;
          grid-template-columns: 50%;
          padding-bottom: 40px;

          ${
            '' /* div {
            width: 100%;
            max-width: 300px;
          } */
          }
          .color {
            justify-content: center;
            margin: auto;
          }

          .contain__input,
          button {
            ${'' /* max-width: 200px; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;

        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.primaryColorLight}; */}
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 15px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .form__body__applications {
    width: 100%;
    min-width: 920px;

    .container__cards {
      width: 80%;
      border-radius: 10px;
      min-width: 1000px;

      .form__title {
        padding: 10px;
        width: 90%;
        font-size: 24px;
        color: ${colors.backgroundColor};
      }

      .contain__inputCard-component {
        ${'' /* min-height: 400px; */}
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        /* max-width: 900px; */
        background-color: #526581;
        border-radius: 15px;
        width: 100%;
        ${'' /* margin-top: 60px; */}
      }

      .form__title {
        padding: 10px;
        width: 90%;
        font-size: 24px;
        color: ${colors.white};
      }

      .title__Form_title {
        font-size: 18px;
        margin-left: 150px;
      }
      .contain__select {
        width: 100%;
      }
    }
  }

  .form__body__applications {
    #headquarters__1 {
      grid-column-start: 2;
      grid-row-start: 1;
    }

    .container__cards {
      min-width: 900px;
      padding-bottom: 40px;

      .contain__inputCard-component {
        background-color: ${colors.primaryColorLight};

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          grid-template-columns: repeat(2, 1fr);
          justify-self: start;

          .contain__input {
            border-radius: 10px;
            padding: 5px;
            height: 100px;
            width: 100%;
          }

          .contain__select {
            ${'' /* width: 200px; */}
            align-self: center;
            grid-row-start: 2;
            height: 100px;
            padding: 5px;
            font-size: 16px;

            .select {
              font-size: 16px;
              width: 200px;
              max-width: 200px;
            }
          }
        }
      }
    }
  }

  .contain__toggleButton {
    width: auto;
    min-width: 440px;
    grid-row-start: 2;
    grid-column-start: 2;
    display: flex;
    justify-content: flex-end;

    .contain__toggleButton__item {
      width: 100%;
      color: ${colors.white};
      display: flex;
      justify-content: center;

      .toggle__options__left,
      .toggle__options__right {
        max-width: 220px;
      }
    }
  }

  .form__body__locution {
    border-radius: 10px;
    background-color: ${colors.primaryColorLight};
    border-radius: 15px;
    width: 90%;
    margin: auto;
    margin-top: 30px;

    .container__cards {
      margin: auto;
      border-radius: 10px;
      width: 80%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .form__title {
        height: 25px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.white};
        font-size: 24px;
        font-weight: bold;
        border-radius: 10px 10px 0 0px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.white};
        }
      }

      .contain__inputCard-component {
        ${'' /* min-height: 400px; */}
        margin: auto;
        background-color: #526581;
        border-radius: 15px;
        width: 100%;

        .stepForm__contain-typeInput {
          margin: auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 90px 90px 1fr;
          justify-self: start;
          column-gap: 20px;

          #name__0 {
            grid-column-start: 1;
            grid-row-start: 1;
            background-color: ${colors.primaryColorLight};
            padding: 30px;
            border-radius: 0px 0px 0 10px;
            display: flex;
            gap: 10px;
            flex-direction: column;
            height: 166px;
            span {
              color: white;
            }
          }

          #headquarters__1 {
            grid-column-start: 2;
            grid-row-start: 1;
            background-color: ${colors.primaryColorLight};
            ${'' /* padding: 30px; */}
            border-radius: 0 0px 10px 0px;
            display: flex;
            gap: 10px;
            flex-direction: column;
            ${'' /* height: 166px; */}
            span {
              color: white;
            }
          }

          .contain__form-dropzone {
            grid-row-start: 5;
            grid-column-start: 1;
            grid-column-end: 3;
            align-self: baseline;
            border-radius: 10px;
            ${'' /* max-width: 310px; */}
            ${'' /* padding-left: 60px; */}

            .speech__conf__right {
              width: 50%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;

              .speech__label__container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
              }
            }
          }

          .contain__input {
            border-radius: 10px;
            padding: 5px;
            height: 100px;
            ${'' /* max-width: 200px; */}
            width:100%;
          }

          .contain__select {
            ${'' /* width: 200px; */}
            align-self: center;
            grid-row-start: 2;
            height: 90px;
            padding: 5px;
            font-size: 16px;

            .select {
              font-size: 16px;
              width: 200px;
              max-width: 200px;
            }
          }
        }
      }
    }
  }

  .contain__button-save {
    height: 60px;
    border-radius: 15px 15px 0 0;
    width: 100%;

    opacity: 1;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${'' /* background-color: ${colors.primaryColorLight}; */}
  }
  .cancel_button {
    background-color: ${colors.primaryColor};
    color: white;
    height: 35px;
    border-radius: 10px;
    width: 100px;
    font-size: 15px;
    margin-right: 20px;
    -webkit-border-radius: 15px;

    &:hover {
      color: white;
      background-color: ${colors.primaryColor};
    }
  }

  .save_edit_button {
    color: black;
    width: 100px;
    height: 35px;
    background-color: ${colors.greenGYGAS};
    font-size: 15px;
    border-radius: 15px;
    -webkit-border-radius: 15px;

    &:hover {
      background-color: ${colors.greenGYGASLight};
    }
  }

  .save_button {
    height: 35px;
    color: black;
    border-radius: 15px;
    width: 100px;
    background-color: ${colors.greenGYGAS};
    font-size: 15px;
    margin-right: 20px;

    &:hover {
      background-color: ${colors.greenGYGASLight};
    }
  }

  .save_button,
  .next_step {
    color: black;
    background-color: ${colors.greenGYGAS};
    border-radius: 15px;
    width: 100px;
    font-size: 15px;
    margin-right: 20px;

    &:hover {
      background-color: ${colors.greenGYGASLight};
    }
  }

  .form__body__alert {
    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: 0 0 15px 15px;

    .container__cards {
      margin-top: 70px;
      border-radius: 10px;
      width: 100%;
      ${'' /* background-color: ${colors.primaryColorLight}; */}

      .contain__inputCard-component {
        width: 100%;
        margin: auto;
        border-radius: 15px;

        .stepForm__contain-typeInput {
          border-radius: 15px;
          padding: 40px 20px 40px 40px;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 40px;
          background-color: ${colors.primaryColorLight};
        }
      }

      .form__title {
        width: 85%;
        margin: auto;
        font-size: 24px;
        font-weight: bold;
        color: ${colors.primaryColorLight};
        padding: 20px;
      }

      .title__Form_title {
        font-size: 24px;
        color: #334155;
      }
    }
  }

  .form__body__cat {
    width: 80%;
    margin: auto;
    padding-bottom: 20px;

    .form__title {
      /* padding: 40px; */
      font-size: 24px;
      font-weight: bold;
      color: ${colors.primaryColorLight};
      width: 100%;
      margin: auto;
    }

    .container__cards {
      width: 80%;
      border-radius: 10px;
      margin-top: 70px;

      .stepForm__contain-typeInput {
        background-color: ${colors.primaryColorLight};
        grid-template-columns: 1fr;
        width: 100%;
        font-size: 16px;
      }

      .title__Form_title {
        display: flex;
      }
    }
  }

  .error__container {
    width: 65%;
    margin: auto;
    margin-top: 100px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      width: 100%;
    }
    .error__body {
      text-align: left;
    }
    .error__title {
      color: red;
    }
  }

  ${
    '' /* .swal2-container {
    .swal2-title {
      .swal2-actions { */
  }
  .customConfirmButtonSwal {
    border: none;
    color: black;
    background-color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${
    '' /* }
    } */
  }

  .customDeleteButtonSwal {
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    opacity: 0.7;
    background-color: #cd3434;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
  }

  .customDeleteButtonSwal:hover {
    background-color: #cd3434;
    opacity: 1;
  }

  .customConfirmButtonSwal:hover {
    background-color: #94ce0dff;
    color: white;
  }

  .customCancelButtonSwal {
    margin-right: 10px;
    border: none;
    opacity: 0.7;
    background-color: #94ce0dff;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    width: 180px;
    height: 28px;
  }

  &:hover {
    background-color: #22262e;
    opacity: 1;
  }

  .customConfirmButtonSwalPassword {
    border: none;
    color: black;
    background-color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customConfirmButtonSwalPassword:hover {
    background-color: lightgrey;
    color: black;
    width: 100%;
  }

  .copyPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
  }

  .copyImage {
    width: 20%;
  }

  .copyImageIcon {
    height: 25px;
  }
  ${'' /* } */}
`;
