import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const UsersScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: visible;
  ${'' /* background-color: ${colors.primaryColor}; */}

  .contain__table {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 65px;
    padding-left: 20px;
    overflow: scroll;
  }

  .users-container {
    background-color: ${colors.backgroundColor};
    border-radius: 15px;
  }

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .userlogo__container,
  .fullName {
    max-width: 45px;
    width: auto;
    min-width: auto;
    height: 20px;
  }

  #firstname__0 {
    position: fixed;
    top: 171px;
    left: 300px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    border-radius: 10px 0 0 0;
    ${'' /* text-align: center; */}
    padding-top: 30px;
    padding-left: 20px;
    margin: auto;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .input {
      padding-left: 10px;
      width: 150px;
    }
  }

  #lastname__1 {
    position: fixed;
    top: 171px;
    left: 496px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    border-radius: 0 10px 0 0;
    ${'' /* text-align: center; */}
    padding-top: 30px;
    padding-left: 20px;
    width: 260px;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .input {
      width: 150px;
    }
  }

  #secondname__2 {
    position: fixed;
    top: 255px;
    left: 300px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${'' /* text-align: center; */}
    padding-top: 10px;
    padding-left: 20px;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .input {
      width: 150px;
    }
  }

  #email__3 {
    position: fixed;
    top: 255px;
    left: 496px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${
      '' /* text-align: center;
    padding-left: 30px; */
    }
     width: 260px;
    padding-top: 10px;
    padding-left: 20px;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .input {
      width: 210px;
    }
  }

  #companyId {
    position: fixed;
    top: 318px;
    left: 300px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${'' /* text-align: center; */}
    ${'' /* padding-left: 25px; */}
    max-width: 50px;
    padding-right: 25px;
    padding-top: 10px;
    padding-left: 20px;
    z-index: 999;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .contain__select {
      width: 150px;
    }
  }

  #departmentId {
    position: fixed;
    top: 318px;
    left: 496px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${'' /* text-align: center; */}

    width: 260px;
    ${'' /* padding-left: 25px; */}
    padding-right: 25px;
    padding-top: 10px;
    padding-left: 20px;
    z-index: 999;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .contain__select {
      width: 150px;
    }
  }

  #category {
    position: fixed;
    top: 382px;
    left: 496px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${'' /* text-align: center; */}

    width: 50px;
    padding-left: 20px;
    width: 260px;
    padding-right: 25px;
    padding-top: 5px;
    z-index: 99;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .contain__select {
      width: 150px;
    }
  }

  #SIPExtension {
    position: fixed;
    top: 382px;
    ${'' /* height:100%; */}
    left: 300px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${'' /* text-align: center; */}

    width: 50px;
    padding-left: 20px;
    padding-right: 25px;
    padding-top: 5px;
    z-index: 99;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .contain__select {
      width: 150px;
    }
  }

  #captureId {
    position: fixed;
    top: 440px;
    left: 300px;
    width: 456px;
    background-color: ${colors.secondaryColorLight};
    ${'' /* color: ${colors.white}; */}
    ${'' /* text-align: center; */}
    padding-left: 20px;

    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 45px;
    border-radius: 0 0 10px 10px;

    ${
      '' /* .textRequired {
      color: ${colors.white};
    } */
    }

    .contain__select {
      width: 150px;
    }
  }

  #roleId,
  #accesslevelId {
    background-color: ${colors.secondaryColorLight};
    padding-left: 30px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  #voicemailPIN__14,
  #isVoicemail__13,
  #isvoicemailEmail__15 {
    background-color: ${colors.secondaryColorLight};
    padding-left: 30px;
    padding-right: 25px;
    padding-top: 5px;
    ${'' /* padding-bottom: 20px; */}
    position: relative;
    height: 65px;
    input {
      height: 35px;
    }
  }
  #isvoicemailEmail__15 {
    left: -334px;
    border-radius: 0 10px 10px 0px;
    width: 290px;
  }
  #isVoicemail__13 {
    border-radius: 10px 0px 0 10px;
  }
  #voicemailPIN__14 {
    left: -165px;
    width: 135px;
    ${'' /* height: 84px; */}
  }
  #roleId {
    border-radius: 10px 0 0 10px;
    ${'' /* padding-right: 140px; */}
  }

  #isCallForward__16,
  #callForward__17,
  #isNotAnswer__18,
  #notAnswer__19 {
    background-color: ${colors.secondaryColorLight};
    padding-left: 30px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 10px;
    position: relative;
    height: 65px;
    input {
      height: 35px;
    }
  }
  #isCallForward__16 {
    border-radius: 10px 0px 0 10px;
  }
  #isNotAnswer__18 {
    left: -335px;
    width: 200px;
  }
  #callForward__17 {
    left: -165px;
    width: 135px;
  }
  #accesslevelId {
    border-radius: 0px 10px 10px 0;
    position: relative;
    left: -35px;
  }

  #notAnswer__19 {
    left: 450px;
    top: -75px;
    width: 135px;
    padding-left: 20px;
    border-radius: 0 10px 10px 0px;
  }

  #isRainbow__20,
  #isTeams__21 {
    background-color: ${colors.secondaryColorLight};
    padding-left: 30px;
    padding-right: 25px;
    padding-top: 5px;
    ${'' /* padding-bottom: 20px; */}
    position: relative;
    height: 65px;
    top: -70px;
  }

  #isTeams__21 {
    left: -35px;
    width: 300px;
    border-radius: 0 10px 10px 0px;
  }
  #isRainbow__20 {
    border-radius: 10px 0 0 10px;
    padding-right: 45px;
    width: max-content;
  }
  .user__logo {
    width: 30px;
    height: 30px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
    border-radius: 50%;
  }

  .users__screen {
    width: 100%;
    height: 100%;
    ${
      '' /* border: 1px solid ${colors.primaryColor};
    background-color: ${colors.backgroundColor}; */
    }
    ${'' /* border-radius: 15px; */}
  }

  .stepForm {
    width: 100%;
    margin: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    /* max-width: 600px; */
    row-gap: 13px;
    row-gap: 20px;
    margin: auto;
    max-width: unset;

    &__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      ${'' /* text-align: left; */}
      color: #0c3fc4;
      font-size: 14px;
      font-weight: bold;
      ${'' /* top: 50px; */}
      margin: auto;
      width: 100%;
    }
  }

  .form__title {
    height: 45px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    text-align: left;
    color: ${colors.black};
    font-size: 14px;
    font-weight: bold;
    top: 50px;
    margin: auto;
    width: 100%;
    ${'' /* background-color: ${colors.primaryColor}; */}
    border-radius: 10px 10px 0 0px;
    padding: 5px;
  }

  ${
    '' /* .title__Form_title {
      color: ${colors.backgroundColor};
    } */
  }

  .stepForm__contain-typeInput {
    grid-template-columns: unset;

    .basicRow {
      grid-column: span 1;
    }

    .contain__select {
      ${'' /* grid-column: span 3; */}
      ${
        '' /* grid-row-start: 1;
        grid-column-start: 1; */
      }
        width: 100%;
      min-width: 200px;
    }
  }

  ${
    '' /* .form__body {
      margin-top: 0;
      top: 0;
      min-height: 220px;
      width: 100%;
      ${'' /* max-width: 600px; */
  }
  ${
    '' /* .icon__Form_title {
        color: white;
      margin-left: 20px;
    
      } */
  }
     */

    .contain__input {
    ${'' /* position: relative; */}
    top: -60px;
    ${'' /* left: 150px; */}
    ${
      '' /* grid-column-start: 1;
      grid-row-start: 1; */
    }
  }

  .input-disabled {
    width: 250px;
    background-color: hsl(0, 0%, 95%);
    border: 0;
  }
  #allow {
    ${'' /* grid-column-start: 2; */}
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }
`;
