import styled from 'styled-components';

export const CostumerServiceScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #f8fafc;
  border-radius: 10px;
  ${
    '' /* .contain__table {
    margin: auto;
    width: 100%;
    justify-self: center;
    box-sizing: border-box;
    height: calc(100% - 55px);
    // //transition: all 1s easy-out;
  } */
  }
  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    justify-self: center;
    width: 100%;
    padding: 0;
    padding-left: 28px;
    padding-top: 20px;
  }
`;
