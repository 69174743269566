import React, { useRef, useState } from 'react';
// import { Breadcrumb } from '../Breadcrumb/Breadcrumb';
// import { IconLogout } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';
import Restricted from '../Permission/PermissionConsumer';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { routesWide } from '../../navigation/routes';
import { colors } from '../../theme/colors';
import { menuSettings } from '../../data/dataSettings';
// import { loginUserAction } from '../../redux/actions/login/actionCreators';
// import { getUsersPermissionAction } from '../../redux/actions/settingsScreen/user/actionCreators';
import logoZerovoz from 'src/assets/images/logo/zerovoz-white.png';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import { array, bool, string } from 'prop-types';
// import { useSwal } from '../../hooks/useSwal';
import PermissionProvider from '../Permission/PermissionProvider';
import { MenuTopWrapper } from './MenuTop.style';
import { useStore } from 'react-redux';
import { IconSettings } from '@tabler/icons-react';
import { DropDown } from '../DropDown/DropDown';
import { IconCaretDownFilled } from '@tabler/icons-react';
export const MenuTop = ({ menu, menuType, isRestricted = true }) => {
  const { pathname } = useLocation();

  const pathNameSeparate = pathname.split('/')[2];
  const pathNameSeparate2 = pathname.split('/')[1];

  const { getState } = useStore();
  const { isMenuOpen } = false;

  const permissions = getState()['arrayUsersPermission'];
  const userData = getState()['login']['user'];
  const [windowLocation1, setWindowLocation1] = useState(pathNameSeparate2);

  // const initialState = {
  //   authChecked: false,
  //   loggedIn: false,
  //   user: {},
  // };

  // const initialStatePermissions = [];

  // const logout = () => {
  //   typeSwal('logout').then(async (result) => {
  //     if (result.isConfirmed) {
  //       localStorage.removeItem('tokenStorage');
  //       dispatch(loginUserAction(initialState));
  //       dispatch(getUsersPermissionAction(initialStatePermissions));
  //       navigate('/login', { replace: true });
  //     }
  //   });
  // };

  const subtitlePermissionsUser = permissions.map((item) => item.subtitle);

  const isMenuPref = menuType === 'Preferencias';
  const userPrefix = userData?.firstName?.charAt(0).toUpperCase() + userData?.lastName?.charAt(0).toUpperCase();
  const userFullName = userData?.firstName + ' ' + userData?.lastName;

  // const handleOpenSettings = () => {
  //   navigate(`./${routesWide.preferences}/${routesPreferences.configurationAsterisk}`, {});
  // };
  const changeView = () => {
    setWindowLocation1(windowLocation1 !== '' ? windowLocation1 : '');
  };

  const classPreffix = isMenuPref ? 'menupref__' : menuType === 'Usuarios' ? '' : '';
  const refContainerUser = useRef(null);
  const refContainerSettings = useRef(null);

  return (
    <PermissionProvider permissionsUser={subtitlePermissionsUser}>
      <MenuTopWrapper isMenuOpen={isMenuOpen} className={classPreffix + 'header__container'}>
        <img src={logoZerovoz} alt="logoZerovoz" />
        <div className={menuType !== 'Preferencias' ? 'buttons__container' : 'menupref__container'}>
          {menuType === 'Preferencias' &&
            menu.map((item, index) =>
              isRestricted === true ? (
                <Restricted key={index} to={item.restricted}>
                  <Link to={'/' + pathNameSeparate2 + '/' + item.path} replace>
                    <div id={item.id} className={item.path !== pathNameSeparate && !isMenuPref ? classPreffix + 'container__link' : classPreffix + 'container__link-active'}>
                      <div className={item.path !== pathNameSeparate ? classPreffix + 'item-link' : classPreffix + 'link-active'} onClick={changeView}>
                        <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={classPreffix + 'menuTop__icon'} />
                        {item.text}
                      </div>
                    </div>
                  </Link>
                </Restricted>
              ) : (
                <Link key={index} to={item.path} replace>
                  <div id={item.id} className={item.path !== pathNameSeparate || isMenuPref ? 'container__link' : 'container__link-active link-active'}>
                    <div className={item.path !== pathNameSeparate || isMenuPref ? 'item-link' : 'link-active'} onClick={changeView}>
                      <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={'menuTop__icon'} />
                      {item.text}
                    </div>
                  </div>
                </Link>
              )
            )}
          {menuType === 'Preferencias' && menu.length === 0 ? <span className="title__Form_title">{menuType}</span> : null}
        </div>
        {menuType !== 'Preferencias' ? (
          <div className={classPreffix + 'header__buttons'}>
            <div className={classPreffix + 'item__contain'} id="settings-button">
              {/* <IconSettings className={classPreffix + 'item__image'} height={'20px'} onClick={handleOpenSettings} /> */}
              <DropDown
                // onClick={handleOpenSettings}
                data={menuSettings[0]}
                // reducerForm={reducerForm}
                layout={'2'}
                menuButtonsDropDown={menuSettings}
                Icon={IconSettings}
                direction={'column'}
                // position={"calc(5% + 30px)"}
                refContainer={refContainerSettings}
                iconColor={colors.primaryColorLight}
                // text={'Configuración'}
              />
            </div>
            <div className={classPreffix + 'user__item__contain'}>
              <div className="user-prefix">{userPrefix}</div>
              {userFullName}
              <DropDown
                layout={'2'}
                data={menu[0]}
                menuButtonsDropDown={menu}
                Icon={IconCaretDownFilled}
                direction={'column'}
                // position={'calc(5% + 30px);'}
                refContainer={refContainerUser}
                // onClick={logout}
                // iconColor={colors.grey}
              />
            </div>
            {/* <div className={classPreffix + 'item__contain'} id="settings-button">
              <IconSettings className={classPreffix + 'item__image'} height={'20px'} />
            </div> */}
            {/* <div onClick={logout} className={classPreffix + 'item__contain'} id="logout-button">
              <IconLogout className={classPreffix + 'item__image'} height={'20px'} />
            </div> */}
          </div>
        ) : null}
      </MenuTopWrapper>
      {/* <ReactTooltip className="tooltip" anchorId="settings-button" place="bottom-start" content="Configuración" delayShow={1000} /> */}
      {/* <ReactTooltip className="tooltip" anchorId="logout-button" place="bottom-start" content="Cerrar sesion" delayShow={1000} /> */}
      {/* <ReactTooltip className="tooltip" anchorId="settings-button" place="bottom" content="Configuración de usuario" delayShow={1000} /> */}
      {/* <ReactTooltip className="tooltip" anchorId="user-button" place="bottom-start" content={userData.email} delayShow={1000} /> */}
    </PermissionProvider>
  );
};

MenuTop.propTypes = { menu: array, isRestricted: bool, menuType: string };
