import React from 'react';
import { Navigate } from 'react-router-dom';

import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { OverviewNumberingWrapper } from './OverviewNumberingScreen.style';
import { menuNumbering } from '../../../data/dataNumbering';

export const OverviewNumberingScreen = () => {
  return (
    <OverviewNumberingWrapper>
      <Navigate to="/numbering/numberingList" />
      <LinkButtonBig menuTop={menuNumbering} />
    </OverviewNumberingWrapper>
  );
};
