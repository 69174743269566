import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardGird } from '../../../components/CardGrid/CardGird';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterIvr, dataScreenIvr } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import useView from '../../../hooks/useView';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import { useSwal } from '../../../hooks/useSwal';
import { IvrScreenWrapper } from './IvrScreen.style';
import { selectedDocActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { useDispatch } from 'react-redux';
// import { NavTable } from '../../../components/NavTable/NavTable';
// import { updateIvrThunk } from '../../../redux/thunks/applicationsThunks';

export const IvrScreen = () => {
  const { ivr, getIvr, loading, deleteIvr, getDestination } = useApplications();
  const { typeSwal } = useSwal();

  // const [checkBoxesActives] = useState([]);
  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const { notFound } = dataScreenIvr;
  const { isCardView } = useView();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  let navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    getIvr();
    setSearch([]);
    getDestination();
  }, [getIvr, setSearch]);

  const handleOpenFormCreate = () => {
    dispatch(selectedDocActionCreator(ivr[0]));
    navigate(`/${routesWide.applications}/${routesApplications.createIvr}`, {});
  };

  const handleOpenFormEdit = (event) => {
    // getDestination();
    dispatch(selectedDocActionCreator(ivr[Number(event.id)]));

    // dispatch(updateIvrThunk(ivr[Number(event.id)]));
    navigate(`/${routesWide.applications}/${routesApplications.updateIvr}`, { state: ivr[Number(event.id)] });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteIvr(item.original);
          } else {
            response = await deleteIvr(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getIvr();
            typeSwal('delete');
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleUploadFile = () => {};

  const DATA = Array.isArray(ivr)
    ? ivr.map(
        ({
          Company,
          Destination,
          Soundprompt,
          id,
          name,
          destinationOneId,
          destinationTwoId,
          destinationThreeId,
          destinationFourId,
          destinationFiveId,
          destinationSixId,
          destinationSevenId,
          destinationEightId,
          destinationNineId,
          destinationTimeoutId,
          destinationZeroId,
          timeOut,
        }) => {
          return {
            id,
            name,
            company: Company?.name,
            companyId: Company?.id,
            destination: Destination,
            soundprompt: Soundprompt?.description,
            soundpromptId: Soundprompt?.id,
            soundpromptFileName: Soundprompt?.filename,
            destinationOneId,
            destinationTwoId,
            destinationThreeId,
            destinationFourId,
            destinationFiveId,
            destinationSixId,
            destinationSevenId,
            destinationEightId,
            destinationNineId,
            destinationTimeoutId,
            destinationZeroId,
            timeOut,
          };
        }
      )
    : [];

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Locución',
      accessor: 'soundprompt',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
  ];
  const dataExport = Array.isArray(ivr)
    ? ivr.map(({ Company, Soundprompt, name }) => {
        return {
          Nombre: name,
          Locución: Soundprompt?.description,
          Sede: Company?.name,
        };
      })
    : [];

  const dataCard = () => {
    if (search?.length > 0) {
      return search?.map(
        ({
          Company,
          Destination,
          Soundprompt,
          id,
          name,
          destinationOneId,
          destinationTwoId,
          destinationThreeId,
          destinationFourId,
          destinationFiveId,
          destinationSixId,
          destinationSevenId,
          destinationEightId,
          destinationNineId,
          destinationTimeoutId,
          destinationZeroId,
          timeOut,
        }) => ({
          title: name,
          subtitle: Soundprompt?.description,
          text: Company?.name,
          id,
          name,
          company: Company?.name,
          companyId: Company?.id,
          destination: Destination,
          soundprompt: Soundprompt?.description,
          soundpromptId: Soundprompt?.id,
          soundpromptFileName: Soundprompt?.filename,
          destinationOneId,
          destinationTwoId,
          destinationThreeId,
          destinationFourId,
          destinationFiveId,
          destinationSixId,
          destinationSevenId,
          destinationEightId,
          destinationNineId,
          destinationTimeoutId,
          destinationZeroId,
          timeOut,
        })
      );
    } else
      return Array.isArray(ivr)
        ? ivr.map(
            ({
              Company,
              Destination,
              Soundprompt,
              id,
              name,
              destinationOneId,
              destinationTwoId,
              destinationThreeId,
              destinationFourId,
              destinationFiveId,
              destinationSixId,
              destinationSevenId,
              destinationEightId,
              destinationNineId,
              destinationTimeoutId,
              destinationZeroId,
              timeOut,
            }) => {
              return {
                title: name,
                subtitle: Soundprompt?.description,
                text: Company?.name,
                id,
                name,
                company: Company?.name,
                companyId: Company?.id,
                destination: Destination,
                soundprompt: Soundprompt?.description,
                soundpromptId: Soundprompt?.id,
                soundpromptFileName: Soundprompt?.filename,
                destinationOneId,
                destinationTwoId,
                destinationThreeId,
                destinationFourId,
                destinationFiveId,
                destinationSixId,
                destinationSevenId,
                destinationEightId,
                destinationNineId,
                destinationTimeoutId,
                destinationZeroId,
                timeOut,
              };
            }
          )
        : [];
  };

  const dataToCards = dataCard();

  const template = [];

  return (
    <IvrScreenWrapper isFilter={isFilter.open}>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
        />
        {ivr && !ivr[0]?.status ? (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterIvr} setSearch={setSearch} />
          </div>
        ) : null}
        {/* <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteIvr} /> */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : ivr && ivr[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <>
          <div className="contain__table">
            <TableInfo
              COLUMNS={COLUMNS}
              DATA={search?.length === 0 ? DATA : search}
              format={'list'}
              deleteFunction={handleDelete}
              updateFunction={handleOpenFormEdit}
              onClick={handleOpenFormEdit}
              setIsBottom={setIsBottom}
              isNavTable={true}
              checkboxesActives={checkBoxesActives}
              setCheckboxesActives={setCheckboxesActives}
            />
          </div>
        </>
      )}
    </IvrScreenWrapper>
  );
};
