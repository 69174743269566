import React, { useEffect, useRef, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateNumberingList, modalNumberingList } from '../../../data/dataNumbering';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import usePreferences from '../../../hooks/usePreferences';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
// import { addDestinationCalendarAction, addDestinationOpenAction } from '../../../redux/actions/numbering/actionCreator';
import { func, string } from 'prop-types';
import { useDepartments } from '../../../hooks/useDepartments';
// import { optionsDestination } from '../../../data/dataApplications';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
// import { NavButtons } from '../../../components/NavButtons/NavButtons';
// import { isRowDestEditableActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { PageScreen } from '../../../components/PageScreen/PageScreen';
// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { useDispatch } from 'react-redux';
import { isModalOpenActionCreator, selectedDocActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
// import { ExtensionsFormScreen } from '../ExtensionsScreen/ExtensionsFormScreen';
// import { optionsDestination } from '../../../data/dataApplications';

export const ListNumberFormScreen = ({ crudType }) => {
  const { listNumberingForm, createNumberingList, formListNumberingReducer, updateNumberingList, getNumberingList } = useNumbering();
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { getDepartments, departmentsHook } = useDepartments();
  const { getCalendar, calendarArrayHook, scheduleArrayHook, getScheduleDB } = usePreferences();
  const { getApplicationsLocutions, arrayLocutionsHook, getDestination } = useApplications();

  const { selectedDoc, isModalOpen } = useAppSelector((state) => state.system);

  // const [, setIsModalOpen] = useState(false);
  // const [render, setRender] = useState(false);

  const { typeSwal } = useSwal();

  const [checkboxesActives, setCheckboxesActives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [newCrudType, setNewCrudType] = useState(crudType);

  // const [showModal, setShowModal] = useState(isModalOpen);

  const dispatch = useDispatch();
  const location = useLocation();

  // const isModalForm = newCrudType === 'editRule' || newCrudType === 'createRule';

  const data = isModalOpen ? modalNumberingList : formCreateNumberingList;

  useEffect(() => {
    getHeadquarter();
    getDepartments();
    getCalendar();
    getScheduleDB();
    getApplicationsLocutions();
    getDestination();
    // setSearch([]);

    listNumberingForm(
      { action: 'clearForm' },
      {
        form: 'numberingList',
      }
    );
  }, []);

  useEffect(() => {
    if (newCrudType === 'edit') {
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        {
          form: 'numberingList',
          element: 'id',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form: 'numberingList',
          element: 'name',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: formListNumberingReducer.ddi || location.state?.ddi,
        },
        {
          form: 'numberingList',
          element: 'ddi',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.companyId,
        },
        {
          form: 'numberingList',
          element: 'companyId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'Company',
        },
        {
          value: location.state?.companyId,
          label: location.state?.company,
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.departmentId,
        },
        {
          form: 'numberingList',
          element: 'departmentId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'Department',
        },
        {
          value: location.state?.departmentId,
          label: location.state?.department,
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'calendars',
        },
        location.state?.calendars
      );

      location.state &&
        Object.keys(location.state).map((item) =>
          data.steps?.map((item2) =>
            item2.input?.map((item3) => {
              if (item === item3.ref?.element) {
                return listNumberingForm(
                  {
                    action: item3.cardType,
                    value: location.state[item],
                  },
                  {
                    form: item3.ref.form,
                    element: item3.ref.element,
                  }
                );
              } else {
                return null;
              }
            })
          )
        );
    } else if (isModalOpen) {
      const newLocu = arrayLocutionsHook.find((itemLocu) => itemLocu.description === selectedDoc?.Locucion);

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'soundPrompt',
        },
        {
          value: newLocu?.id,
          label: newLocu?.description,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'calendar',
        },
        {
          value: selectedDoc?.Calendario,
          label: selectedDoc?.Calendario,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'schedule',
        },
        {
          value: selectedDoc?.Horario,
          label: selectedDoc?.Horario,
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'destination',
        },
        {
          value: selectedDoc?.Destino,
          label: selectedDoc?.Destino,
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'calendars',
        },
        selectedDoc?.calendars
      );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof formListNumberingReducer === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input?.map((item2) => {
            switch (item2.ref?.element) {
              case 'companyId':
                item2.options = [];
                arrayHeadquarterHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const company = {
                    value: item3.id?.toString(),
                    label: item3.name,
                  };
                  // setSelectedCompany(company.value);
                  const found = item2.options.some((e) => e.value === company.value);
                  if (!found && company.value !== undefined) {
                    item2.options.push(company);
                  }
                });
                break;
              case 'departmentId':
                item2.options = [];
                departmentsHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const depart = {
                    value: item3.id?.toString(),
                    label: item3.name,
                  };
                  // setSelectedCompany(company.value);
                  const found = item2.options.some((e) => e.value === depart.value);
                  if (!found && depart.value !== undefined) {
                    item2.options.push(depart);
                  }
                });
                break;
              default:
                item2.options = location.state[item2.ref.element];
                break;
            }
          });
        });

        if (typeof formListNumberingReducer === 'object') {
          if ((newCrudType === 'edit' || newCrudType === 'editRule') && (formListNumberingReducer.ddi === null || formListNumberingReducer.ddi !== location.state.ddi)) {
            setLoadingCounter(loadingCounter + 1);
          } else if (newCrudType === 'edit') {
            data.steps.map((item) => {
              item.input?.map((item2) => {
                let newValue;
                switch (item2.ref?.element) {
                  case 'ddi':
                    item2.defaultValues = formListNumberingReducer.ddi;
                    break;
                  case 'name':
                    item2.defaultValues = formListNumberingReducer.name;
                    break;
                  case 'companyId':
                    newValue = item2.options.find((itemCompany) => Number(itemCompany.value) === formListNumberingReducer.companyId);
                    // .filter((item3) => {
                    //   if (Number(item3.value) === formListNumberingReducer.companyId) {
                    //     return (item2.defaultValues = item3);
                    //   }
                    //   return false;
                    // });
                    item2.defaultValues = newValue;
                    break;
                  case 'Company':
                    item2.options.filter((item3) => {
                      if (Number(item3.value) === formListNumberingReducer.Company) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    // item2.defaultValues = formListNumberingReducer.Company;
                    break;
                  case 'departmentId':
                    item2.options.filter((item3) => {
                      if (Number(item3.value) === formListNumberingReducer.departmentId) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  case 'soundPromptCloseId':
                    item2.options.filter((item3) => {
                      if (Number(item3.value) === formListNumberingReducer.soundPromptCloseId) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  case 'soundPrompt':
                    // item2.options.filter((item3) => {
                    // if (Number(item3.value) === formListNumberingReducer.soundPrompt) {
                    //     return (item2.defaultValues = item3);
                    //   }
                    //   return false;
                    // });
                    item2.defaultValues = formListNumberingReducer.soundPrompt;
                    break;
                  case 'destinationCloseType':
                    // item2.options.filter((item3) => {
                    //   if (Number(item3.value) === formListNumberingReducer.destinationCloseType.value) {
                    //     return (item2.defaultValues = item3);
                    //   }
                    //   return false;
                    // });
                    item2.defaultValues = formListNumberingReducer.destinationCloseType;
                    break;
                  case 'destinationCloseTypeId':
                    // item2.options.filter((item3) => {
                    // if (Number(item3.value) === formListNumberingReducer.destinationCloseTypeId) {
                    item2.defaultValues = formListNumberingReducer.destinationCloseTypeId;
                    //   }
                    //   return false;
                    // });
                    break;
                  case 'destinationCloseId':
                    item2.options.filter((item3) => {
                      if (Number(item3.value) === formListNumberingReducer.destinationCloseId) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  case 'destinationClose':
                    item2.defaultValues = formListNumberingReducer.destinationClose;
                    break;

                  default:
                    // item2.defaultValues = formListNumberingReducer[item2.ref?.element];
                    break;
                }
                setDataSave(true);
                return item2;
              });
              return item;
            });
            setLoading(false);
          } else if (newCrudType === 'create') {
            data.steps.map((item) => {
              item.input.map((item2) => {
                switch (item2.ref.element) {
                  case 'companyId':
                    item2.options.filter((item3) => {
                      if (Number(item3.value) === formListNumberingReducer.companyId) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  case 'Company':
                    item2.options.filter((item3) => {
                      if (item3.value === formListNumberingReducer.Company) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  default:
                    item2.defaultValues = '';
                    break;
                }
                return item2;
              });
              return item;
            });
            setLoading(false);
          } else if (isModalOpen) {
            data.steps.map((item) => {
              item.input.map((item2) => {
                switch (item2.ref.element) {
                  case 'calendar':
                    item2.options = [];
                    calendarArrayHook.forEach((itemCalendar) => {
                      item2.options.push({ value: itemCalendar[0]?.CalendarName.id, label: itemCalendar[0]?.CalendarName.name });
                    });
                    break;
                  case 'schedule':
                    item2.options = [];
                    scheduleArrayHook.forEach((itemSched) => {
                      item2.options.push({ value: itemSched[0]?.Schedule.id, label: itemSched[0]?.Schedule.name });
                      // const newCal = { value: itemSched[0]?.Schedule.id, label: itemSched[0]?.Schedule.name };
                    });
                    break;
                  case 'soundPrompt':
                    item2.options = [];
                    arrayLocutionsHook.forEach((itemSound) => {
                      item2.options.push({ value: itemSound?.id, label: itemSound?.description });
                      // const newCal = { value: itemSched[0]?.Schedule.id, label: itemSched[0]?.Schedule.name };
                    });
                    break;
                  default:
                }
              });
            });

            data.steps.map((item) => {
              item.input.map((item2) => {
                switch (item2.ref.element) {
                  case 'id':
                    item2.defaultValues = selectedDoc.id;
                    break;
                  case 'calendar':
                    item2.defaultValues = selectedDoc.Calendario;
                    break;
                  case 'soundPrompt':
                    item2.defaultValues = selectedDoc.Locucion;
                    break;
                  case 'destination':
                    item2.defaultValues = selectedDoc.destination;
                    break;
                  case 'schedule':
                    item2.defaultValues = selectedDoc.Horario;
                    break;
                  case 'isDefault':
                    item2.defaultValues = selectedDoc.isDefault;
                    break;
                  default:
                    item2.defaultValues = '';
                    break;
                }
                return item2;
              });
              return item;
            });
            setLoading(false);
          }
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [newCrudType, loadingCounter]);

  const COLUMNS = [
    {
      Header: ' ',
      accessor: 'isDefault',
    },
    {
      Header: 'Calendario',
      accessor: 'Calendario',
    },
    {
      Header: 'Horario',
      accessor: 'Horario',
    },
    {
      Header: 'Locución',
      accessor: 'Locucion',
    },
    // {
    //   Header: 'Destino',
    //   accessor: 'TipoDestino',
    // },
    {
      Header: 'Destino',
      accessor: 'Destino',
    },
  ];

  const tableInstance = useRef(null);
  // const [showEditRow, setShowEditRow] = useState(false);

  const handleAddRule = () => {
    const newCalendar = location.state?.calendars;

    newCalendar.push(formListNumberingReducer.calendar);

    listNumberingForm(
      {
        action: 'select-option',
      },
      {
        form: 'numberingList',
        element: 'calendar',
      },
      newCalendar
    );

    // dispatch(isModalOpenActionCreator(false));
    // return false;
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            // response = await deleteNumberingList(item.original);
          } else {
            // response = await deleteNumberingList(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getNumberingList();
            typeSwal('delete');
          }
          if (response.status === 203) {
            getNumberingList();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleEditRule = (item) => {
    setNewCrudType('editRule');
    dispatch(selectedDocActionCreator(item.original));

    dispatch(isModalOpenActionCreator(true));
    listNumberingForm(
      {
        action: 'select-option',
        // value: location.state?.id,
      },
      {
        form: 'numberingList',
        element: 'calendar',
      },
      { value: location.state?.id, label: location.state?.name }
    );
  };

  const dataRules = () => {
    const arrayRules = [];
    // location.state &&      location.state
    selectedDoc?.calendars?.forEach((row, index) => {
      const newRow = {
        id: row.id,
        Calendario: row?.label,
        Horario: selectedDoc.schedules[index]?.label,
        Locucion: selectedDoc.soundPrompts[index]?.label,
        // TipoDestino: ,
        Destino: selectedDoc.destinationsType[index]?.label + ': ' + selectedDoc.destinations[index]?.label,
        isDefault: selectedDoc?.isDefaults && selectedDoc?.isDefaults[index] !== undefined ? selectedDoc?.isDefaults[index] : '',
      };
      row.label ? arrayRules.push(newRow) : null;
    });
    return arrayRules;
  };
  const template = [];

  const arrayRules = dataRules();

  const option = arrayRules.map(({ Calendario, Horario, Locucion }) => ({
    Calendario,
    Horario,
    Locucion,
  }));

  const [isEditMode, setIsEditMode] = useState(false);

  const handleOnClickOut = (event) => {
    event.preventDefault();
  };

  const handleOnCancel = () => {
    dispatch(isModalOpenActionCreator(false));
  };

  const handleOnClickIn = (event) => {
    event.preventDefault();
  };

  const handleOpenFormEdit = (item) => {
    dispatch(selectedDocActionCreator(item.original));
    dispatch(isModalOpenActionCreator(true));
  };

  const handleOpenFormCreate = () => {
    dispatch(isModalOpenActionCreator(true));
    setNewCrudType('createRule');
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (newCrudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', createNumberingList);
    } else if (newCrudType === 'edit') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingList);
    } else if (isModalOpen) {
      handleAddRule(location.state);
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingList);
    }
  };

  return (
    <div className={!isModalOpen ? 'form__container' : 'modal__container'}>
      {/* !isModalOpen ? 'form__container' : 'modal__container'}> */}
      {/* onClick={isModalForm ? handleOnClickOut : null}> */}
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          arrScreen={['Numeración externa']}
          data={data}
          onSubmit={isModalOpen ? handleAddRule : onSubmit}
          onCancel={isModalOpen ? handleOnCancel : null}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={newCrudType}
          onEditRule={handleEditRule}
          reducerForm={formCreateNumberingList}
        />
      )}
      {!isModalOpen ? (
        <div className="numb_contain__table">
          <NavButtons
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
            DATA={[data]}
            onClickCreate={handleOpenFormCreate}
            // onClickAssign={handleClickAssign}
            setSearch={setSearch}
            handleOpenFormCreate={handleOpenFormCreate}
            optionButtons={{
              add: {
                position: 'left',
                function: handleOpenFormCreate,
              },
              search: {
                position: 'right',
                data: option,
                columns: COLUMNS,
              },

              file: {
                position: 'right',
                // data: dataExport,
                template,
              },
            }}
          />
          <TableInfo
            format={'list'}
            handleEditRule={handleEditRule}
            COLUMNS={COLUMNS}
            isNavTable={true}
            DATA={search.length === 0 ? arrayRules : search}
            deleteFunction={handleDelete}
            ref={tableInstance}
            setIsBottom={setIsBottom}
            askedBy={'ListNumberForm'}
            isOptions={false}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
            onClickCreate={handleOpenFormCreate}
            handleOpenFormEdit={handleOpenFormEdit}
            onClick={handleEditRule}
            setDataCard={() => {
              false;
            }}
          />
          {isModalOpen ? (
            <div className="modal__form__container" onClick={handleOnClickOut}>
              <div className="modal__form__body" onClick={handleOnClickIn}>
                <ListNumberFormScreen crudType={'editRule'} />;
              </div>
            </div>
          ) : null}

          {/*  <PageScreen
          arrScreen={['NumExterna']}
          dataSave={dataSave}
          data={data}
          onSubmit={onSubmit}
          render={render}
          setRender={setRender}
          form={listNumberingForm}
          crudType={'edit'}
          maxwidth={'1000px'}
          reducerForm={listNumberingForm}
          handleOpenFormCreate={handleOpenFormCreate}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isMenuOpen={isMenuOpen}
        /><FormScreen
          arrScreen={['Numeración externa']}
          data={data}
          onSubmit={isModalOpen ? handleAddRule : onSubmit}
          onCancel={isModalOpen ? handleOnCancel : null}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={newCrudType}
          onEditRule={handleEditRule}
          reducerForm={formCreateNumberingList}
        /> */}
        </div>
      ) : null}
    </div>
  );
};

ListNumberFormScreen.propTypes = { crudType: string, onModalSubmit: func };
