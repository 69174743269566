import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes, { bool, func, object } from 'prop-types';
import { useStore } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PermissionProvider from '../Permission/PermissionProvider';
import Restricted from '../Permission/PermissionConsumer';
import { useDispatch } from 'react-redux';
import { NavMenuWrapper } from './NavMenuLeft.style';
import { isMenuOpenActionCreator, isModalOpenActionCreator, isSubMenuOpenActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import logo from '../../assets/images/logo/logo-color.png';
// import { Button } from '../Button/Button';
// import { ToastContainer } from 'react-toastify';
// import { IconMenu2, IconPin } from '@tabler/icons-react';
import { menuApplications } from '../../data/dataApplications';
import { menuEM } from '../../data/dataEM';
import { menuNumbering } from '../../data/dataNumbering';
import { menuCallRegister } from '../../data/dataCallRegister';
// import { IconMathGreater } from '@tabler/icons-react';
import { menuOutbound } from '../../data/dataOutbound';
import { menuSettings } from '../../data/dataSettings';

// import { colors } from '../../theme/colors';
// import { IconUser } from '@tabler/icons-react';
// import { IconLogout } from '@tabler/icons-react';
// import { loginUserAction } from '../../redux/actions/login/actionCreators';
// import { getUsersPermissionAction } from '../../redux/actions/settingsScreen/user/actionCreators';
// import { useSwal } from '../../hooks/useSwal';
import { useAppSelector } from '../../redux/hooks/hooks';
import { IconArrowRight } from '@tabler/icons-react';
import { IconArrowLeft } from '@tabler/icons-react';
// import { DropDown } from '../DropDown/DropDown';
// import { IconDotsVertical } from '@tabler/icons-react';
// import logoTpartner from '../../assets/images/logo/logo-alone-blanco.png';
export const NavMenuLeft = ({ menu }) => {
  const { menuTop } = menu;

  // const { typeSwal } = useSwal();
  // const navigate = useNavigate();

  // const { pathname } = useLocation();
  const { getState } = useStore();

  const dispatch = useDispatch();
  const { isMenuOpen, isModalOpen, isSubMenuOpen } = useAppSelector((state) => state.system);
  const userData = useAppSelector((state) => state.login.user);
  // getState()['login']['user'];
  // const [menuPinned, setMenuPinned] = useState(isMenuPinned);
  const [, setMenu] = useState([]);
  const [itemClicked, setItemClicked] = useState(false);
  const [subMenu, setSubMenu] = useState([]);
  const [menuType, setMenuType] = useState('');
  const [menuTypeSP, setMenuTypeSP] = useState('');

  const urLocation = window.location.pathname;

  const [urlMainSectionSelected, setUrlMainSectionSelected] = useState(urLocation.split('/')[1]);
  const [, setUrlOptionSelected] = useState(urLocation.split('/')[2]) || '';

  const permissions = getState()['arrayUsersPermission'];
  const titlePermissionsUser = permissions.map((item) => item.title);

  const openMenuOptions = (item) => {
    const newMenuType = item.currentTarget.id;

    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : null;

    setItemClicked(false);
    let newMenuTypeTranslated = '';
    let newMenuTypeTranslatedSP = '';

    switch (newMenuType) {
      case 'Aplicaciones':
        newMenuTypeTranslated = 'applications';
        newMenuTypeTranslatedSP = newMenuType;
        setMenu(menuApplications);
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'applications':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Aplicaciones';
        setMenu(menuApplications);
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'Emergency manager':
        newMenuTypeTranslated = 'EM';
        newMenuTypeTranslatedSP = newMenuType;
        setMenu(menuEM);
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'EM':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Emergency manager';
        setMenu(menuEM);
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'Dispositivos':
        newMenuTypeTranslated = 'numbering';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'numbering':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Dispositivos';
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'Llamadas':
        newMenuTypeTranslated = 'cdr';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'cdr':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Llamadas';
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'Configuración':
        newMenuTypeTranslated = 'settings';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'settings':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Configuración';
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'Rutas de salida':
        newMenuTypeTranslated = 'outboundRoutes';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'outboundRoutes':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Rutas de salida';
        dispatch(isSubMenuOpenActionCreator(!isSubMenuOpen));
        break;
      case 'Usuarios':
        newMenuTypeTranslated = 'users';
        newMenuTypeTranslatedSP = newMenuType;
        break;
      case 'users':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Usuarios';
        break;
      case 'Dashboard':
        newMenuTypeTranslated = 'dashboard';
        newMenuTypeTranslatedSP = newMenuType;
        break;
      case 'dashboard':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Dashboard';
        break;
      default:
        newMenuTypeTranslated = newMenuType || menuType;
        break;
    }

    setMenuType(newMenuTypeTranslated);
    setMenuTypeSP(newMenuTypeTranslatedSP);

    getSubMenu(newMenuTypeTranslated);
  };

  const handleOpenMenu = () => {
    dispatch(isMenuOpenActionCreator(!isMenuOpen));
  };

  const openSubMenuOptions = (itemSubOption) => {
    setUrlOptionSelected(itemSubOption.currentTarget.innerText);

    !isMenuOpen ? dispatch(isMenuOpenActionCreator(false)) : null;
    dispatch(isSubMenuOpenActionCreator(false));
    getSubMenu(urlMainSectionSelected);
    getSubMenu(urlMainSectionSelected, window.location.pathname.split('/')[2]);

    setSubMenu([]);

    setUrlOptionSelected(itemSubOption.currentTarget.innerText || urLocation.split('/')[2]);
    getSubMenu(urlMainSectionSelected, urLocation.split('/')[2]);
    setItemClicked(true);
  };

  const isMenuPref = menuTop === 'Preferencias';
  const classPreffix = isMenuPref ? 'menupref__' : '';

  const getSubMenu = (parentMenu, menuOption) => {
    // if (itemNew !== 'new') {
    let arraySubMenu = [];

    setUrlMainSectionSelected(parentMenu);
    // setUrlOptionSelected(menuOption);
    setUrlOptionSelected(menuOption);
    setMenuType(parentMenu);

    switch (parentMenu) {
      case 'applications':
        arraySubMenu = menuApplications;
        break;
      case 'EM':
        arraySubMenu = menuEM;
        break;
      case 'numbering':
        arraySubMenu = menuNumbering;
        break;
      case 'cdr':
        arraySubMenu = menuCallRegister;
        break;
      case 'settings':
        arraySubMenu = menuSettings;
        break;
      case 'outboundRoutes':
        arraySubMenu = menuOutbound;
        break;
      default:
        arraySubMenu = [];
        break;
    }

    const result = arraySubMenu?.map((item, index) =>
      item.restricted === true ? (
        <Restricted key={index} to={parentMenu + '/' + item.restricted}>
          <div onClick={openSubMenuOptions} id={item.id} className={item.path === urLocation.split('/')[2] ? classPreffix + 'container__link-active' : classPreffix + 'container__link'}>
            <div className={item.restricted === urLocation.split('/')[2] ? classPreffix + 'link-active' : classPreffix + 'item-link'}>
              <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={classPreffix + 'menuTop__icon'} />
              {item.text}
            </div>
          </div>
        </Restricted>
      ) : (
        <Link key={index} to={parentMenu + '/' + item.path} replace>
          <div onClick={openSubMenuOptions} id={item.id} className={item.path === urLocation.split('/')[2] ? 'container__link-active link-active' : 'container__link item-link'}>
            <div className={item.path === urLocation.split('/')[2] ? 'link-active' : 'item-link'}>
              <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={'menuTop__icon'} />
              {item.text}
            </div>
          </div>
        </Link>
      )
    );
    //  parentMenu !== 'users'
    //    ? setSubMenu(
    //        <div className="item__options__container">
    //          {!isMenuOpen ? <span className={'submenu__title'}>{menuTypeSP}</span> : null} {result}
    //        </div>
    //      )
    //    : null;
    setSubMenu(
      <div className="item__options__container">
        {!isMenuOpen ? <span className={'submenu__title'}>{menuTypeSP}</span> : null} {result}
      </div>
    );

    // !isSubMenuOpen ? dispatch(isSubMenuOpenActionCreator(true)) : null;
    // setSubMenu(
    //   <DropDown
    //     data={newMenu[0]}
    //     reducerForm={reducerForm}
    //     layout={'1'}
    //     menuButtonsDropDown={newMenu}
    //     Icon={IconArrowBadgeDown}
    //     direction={'column'}
    //     position={'calc(5% + 30px)'}
    //     refContainer={refContainer}
    //     iconColor={colors.primaryColorLight}
    //     text={'Aplicaciones'}
    //   />
    // );
    // }

    // setLocation1(parentMenu);
  };

  // const initialState = {
  //   authChecked: false,
  //   loggedIn: false,
  //   user: {},
  // };

  // const initialStatePermissions = [];

  // const logout = () => {
  //   typeSwal('logout').then(async (result) => {
  //     if (result.isConfirmed) {
  //       localStorage.removeItem('tokenStorage');
  //       dispatch(loginUserAction(initialState));
  //       dispatch(getUsersPermissionAction(initialStatePermissions));
  //       navigate('/login', { replace: true });
  //     }
  //   });
  // };

  useEffect(() => {
    getSubMenu(urlMainSectionSelected, urLocation.split('/')[2]);
  }, [urlMainSectionSelected, urLocation]);

  return (
    <PermissionProvider permissionsUser={titlePermissionsUser}>
      <NavMenuWrapper isMenuOpen={isMenuOpen} className="contain__navLeft">
        <div className="container__top">
          {/* <div className="hamburguer__container">
            <IconMenu2 className={'icon__menu'} id="menu" onClick={handleMenu} />
            {isMenuOpen ? <IconPin className={!isMenuPinned ? 'icon__pin' : 'icon__pin__active'} id="pin" onClick={handlePin} /> : null}
          </div> */}
          {menuTop.map((itemMenu, index) =>
            itemMenu.text === 'Divider' ? (
              <div key={index} className="item__divider" />
            ) : (
              <Restricted key={index} to={itemMenu.restricted}>
                <Link
                  to={itemMenu.path === 'dashboard' || itemMenu.path === 'users' ? '../' + itemMenu.path : null}
                  replace
                  className={itemMenu.text === 'Configuración' ? 'navleft-link__settings' : 'navleft-link'}>
                  <div
                    onClick={openMenuOptions}
                    // itemMenu.path === 'dashboard' || itemMenu.path === 'users' ? openMenuOptions : null}
                    className={itemMenu.path === urlMainSectionSelected ? 'item__active' : 'item__contain'}
                    id={itemMenu.path}>
                    <itemMenu.image className="item__image" height={'40px'} />
                    {isMenuOpen ? <p className="item__text">{itemMenu.text}</p> : null}
                    {/* {itemMenu.text !== 'Dashboard' && itemMenu.text !== 'Usuarios' && <IconMathGreater height={'30px'} width={'30px'} className={'icon__moreOptions'} strokeWidth={2} />} */}
                  </div>
                </Link>
                {isSubMenuOpen && !itemClicked && menuTypeSP === itemMenu?.text && itemMenu.text !== 'Usuarios' ? subMenu : null}
              </Restricted>
            )
          )}

          {/* <div className={classPreffix + 'login__buttons'}>
            {isMenuOpen ? (
              <div className={classPreffix + 'item__contain'} id="user-button">
                <IconUser className={classPreffix + 'item__image__user'} height={'20px'} />
              </div>
            ) : null}

            
          </div> */}
        </div>
        <div onClick={handleOpenMenu} className={classPreffix + 'openMenu__container'}>
          {!isMenuOpen ? <IconArrowRight className={classPreffix + 'icon_openMenu'} /> : <IconArrowLeft className={classPreffix + 'icon_openMenu'} />}
        </div>

        {!subMenu ? (
          <>
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#menu" place="bottom" content="Ver / Ocultar menú lateral" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#pin" place="bottom" content="Anclar / Desanclar menú lateral" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#dashboard" place="right" content="Dashboard" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#users" place="right" content="Usuarios" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#numbering" place="right" content="Numeraciones" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#applications" place="right" content="Aplicaciones" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#cdr" place="right" content="Llamadas" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#outboundRoutes" place="right" content="Rutas de salida" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#preferences" place="right" content="Configuración de usuario" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#EM" place="right" content="Emergency Manager" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#settings" place="right" content="Configuración" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#logout-button" place="bottom-start" content="Cerrar sesión" delayShow={200} />
            {/* <ReactTooltip className="tooltip" anchorId="settings-button" place="bottom" content="Configuración de usuario" delayShow={1000} /> */}
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#user-button" place="bottom-start" content={userData.email} delayShow={200} />
          </>
        ) : null}
      </NavMenuWrapper>
    </PermissionProvider>
  );
};

NavMenuLeft.propTypes = {
  menu: PropTypes.object.isRequired,
  isClosed: bool,
  setIsClosed: func,
  user: object,
  reducerForm: object,
};
