import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const QueueScreenWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    padding: 20px 36px 0;
    // //transition: all 1s easy-out;
    position: relative;
  }

  .contain__notFound {
    height: calc(100% - 55px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    width: ${(p) => (p.isFilter ? '300px' : '0px')};
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    // //transition: width 0.3s ease-in-out;
    overflow: hidden;
  }

  .tooltip {
    background: ${colors.primaryColor};
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 10px;
    padding-bottom: 25px;
    text-align: left;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: auto;
    text-align: left;
  }
`;
