import { actionTypesUser } from './actionTypes';

export const createUsersAction = (user) => {
  return {
    type: actionTypesUser.create,
    user,
  };
};

export const deleteUsersAction = (user) => {
  return {
    type: actionTypesUser.delete,
    user,
  };
};

export const updateUsersAction = (user) => {
  return {
    type: actionTypesUser.update,
    user,
  };
};

export const getUsersAction = (users) => {
  return {
    type: actionTypesUser.get,
    users,
  };
};

export const getUsersPermissionAction = (permissions) => {
  return {
    type: actionTypesUser.getUsersPermission,
    permissions,
  };
};

export const getAccessLevelsAction = (accesslevels) => {
  return {
    type: actionTypesUser.getAccessLevel,
    accesslevels,
  };
};

//actions for user FORM

export const changeUserFormIdAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormId,
    payload,
  };
};
export const changeUserFormNameAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormName,
    payload,
  };
};

export const changeUserFormFirstSurnameAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormFirstSurname,
    payload,
  };
};

export const changeUserFormSecondSurnameAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormSecondSurname,
    payload,
  };
};

export const changeUserFormEmailAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormEmail,
    payload,
  };
};

export const changeUserFormAccessLevelsAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormAccessLevels,
    payload,
  };
};

export const changeUserFormPasswordAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormPassword,
    payload,
  };
};

export const changeUserFormCompanyAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormCompany,
    payload,
  };
};

export const changeUserFormRolesAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormRoles,
    payload,
  };
};

export const changeUserFormCaptureAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormCapture,
    payload,
  };
};

export const changeUserFormDepartmentAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormDepartment,
    payload,
  };
};

export const changeUserFormisSIPExtension = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisSIPExtension,
    payload,
  };
};

export const addUserFormSIPExtensionAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormSIPExtension,
    payload,
  };
};

export const changeUserFormSIPExtensionAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormSIPExtension,
    payload,
  };
};
export const changeUserFormCallForwardAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormCallForward,
    payload,
  };
};
export const changeUserFormNotAnswerAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormNotAnswer,
    payload,
  };
};

export const addUserFormisVoicemail = (payload) => {
  return {
    type: actionTypesUser.addUserFormisVoicemail,
    payload,
  };
};
export const changeUserFormisVoicemail = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisVoicemail,
    payload,
  };
};

export const changeUserFormisTeams = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisTeams,
    payload,
  };
};
export const changeUserFormisRainbow = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisRainbow,
    payload,
  };
};
export const changeUserFormisWebPhoneAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisWebPhone,
    payload,
  };
};
export const addUserFormCategoryAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormCategory,
    payload,
  };
};
export const changeUserFormisCallForwardAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisCallForward,
    payload,
  };
};
export const changeUserFormIsNotAnswerAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormIsNotAnswer,
    payload,
  };
};

export const changeUserFormisvoicemailEmail = (payload) => {
  return {
    type: actionTypesUser.changeUserFormisvoicemailEmail,
    payload,
  };
};

export const changeUserFormvoicemailPIN = (payload) => {
  return {
    type: actionTypesUser.changeUserFormvoicemailPIN,
    payload,
  };
};
export const changeUserRingTimeout = (payload) => {
  return {
    type: actionTypesUser.changeUserRingTimeout,
    payload,
  };
};
export const addUserFormRolesAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormRoles,
    payload,
  };
};
export const addUserFormisSIPExtensionAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormisSIPExtension,
    payload,
  };
};

export const addUserFormAccessLevelAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormAccessLevels,
    payload,
  };
};
export const addUserFormCompanyAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormCompany,
    payload,
  };
};
export const addUserFormDepartmentAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormDepartment,
    payload,
  };
};
export const removeUserFormRolesAction = (payload) => {
  return {
    type: actionTypesUser.removeUserFormRoles,
    payload,
  };
};
export const clearUserFormAction = () => {
  return {
    type: actionTypesUser.clearUserForm,
  };
};

export const clearUserFormRolesAction = () => {
  return {
    type: actionTypesUser.clearUserFormRoles,
  };
};

export const clearUserFormDepartAction = () => {
  return {
    type: actionTypesUser.clearUserFormDept,
  };
};

export const clearUserFormCaptureAction = () => {
  return {
    type: actionTypesUser.clearUserFormCapture,
  };
};
