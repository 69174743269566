import styled from 'styled-components';

import { colors } from '../../theme/colors.js';

export const NumberingsNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 100%;
  margin: auto;
  position: fixed;
  background-color: ${colors.backgroundColor};
  border-radius: 15px;
  left: ${(p) => (p.isMenuOpen ? '229px' : '70px')};

  ${
    '' /* .container__cards {
    width: 80%;
    margin: auto;
  } */
  }

  ${
    '' /* .contain__inputCard-component {
    padding-top: 100px;
  } */
  }


 

  #identification__2 {
    position: absolute;
    top: 10px;
    padding-left: 5px;
    width: 1000px;
  }

  .listnumber__container {
    .form__container {
      margin: auto;
      border-radius: 10px;
      background-color: ${colors.backgroundColor};

      .contain__input {
        input {
          background-color: white;
          border: none;
          font-weight: bold;
          font-size: 20px;
          padding: 10px;
          width: 500px;
          max-width: unset;
        }
      }
    }
  }
`;
