import { actionsTypesEM } from './actionTypes';

export const getEMAction = (arrayEM) => {
  return {
    type: actionsTypesEM.getEM,
    arrayEM,
  };
};

export const getEMListExtensionAction = (arrayEMListExtension) => {
  return {
    type: actionsTypesEM.getEMListExtension,
    arrayEMListExtension,
  };
};

export const getEMAlarmsAction = (arrayEMAlarms) => {
  return {
    type: actionsTypesEM.getEMAlarms,
    arrayEMAlarms,
  };
};

// CRUD Diffusion List

export const createEMListExtensionAction = (arrayEMListExtension) => {
  return {
    type: actionsTypesEM.createEMListExtension,
    arrayEMListExtension,
  };
};

export const updateEMListExtensionAction = (arrayEMListExtension) => {
  return {
    type: actionsTypesEM.updateEMListExtension,
    arrayEMListExtension,
  };
};

export const deleteEMListExtensionAction = (arrayEMListExtension) => {
  return {
    type: actionsTypesEM.deleteEMListExtension,
    arrayEMListExtension,
  };
};

// Form Diffusion List
export const changeEMListFormIdAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMListFormId,
    payload: payload,
  };
};

export const changeEMListFormNameAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMListFormName,
    payload: payload,
  };
};

export const addEMListFormExtensionAction = (payload) => {
  return {
    type: actionsTypesEM.addEMListFormExtension,
    payload: payload,
  };
};

export const removeEMListFormExtensionAction = (payload) => {
  return {
    type: actionsTypesEM.removeEMListFormExtension,
    payload: payload,
  };
};
export const clearEMListFormExtensionAction = () => {
  return {
    type: actionsTypesEM.clearEMListFormExtension,
    payload: null,
  };
};

export const clearMultiselectEMListFormExtensionAction = (payload) => {
  return {
    type: actionsTypesEM.clearMultiselectEMListFormExtension,
    payload,
  };
};

//CRUD ALERTS
export const createEMAlertsAction = (alert) => {
  return {
    type: actionsTypesEM.createEMAlerts,
    alert,
  };
};

export const updateEMAlertsAction = (alert) => {
  return {
    type: actionsTypesEM.updateEMAlerts,
    alert,
  };
};

export const deleteEMAlertsAction = (alert) => {
  return {
    type: actionsTypesEM.deleteEMAlerts,
    alert,
  };
};

// Form Alerts

export const changeEMAlertsFormIdAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMAlertsFormId,
    payload,
  };
};
export const changeEMAlertsNameAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMAlertsName,
    payload,
  };
};

export const changeEMAlertsCodeAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMAlertsCode,
    payload,
  };
};

export const changeEMAlertsConfirmationNumberAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMAlertsConfirmationNumber,
    payload,
  };
};

export const addEMAlertsSecondaryListDiffusionAction = (payload) => {
  return {
    type: actionsTypesEM.addSecondaryEMAlertsListDiffusion,
    payload,
  };
};

export const removeSecondayEMAlertsListDiffusionAction = (payload) => {
  return {
    type: actionsTypesEM.removeSecondatEMAlertsListDiffusion,
    payload,
  };
};

export const addEMAlertsListDiffusionAction = (payload) => {
  return {
    type: actionsTypesEM.addEMAlertsListDiffusion,
    payload,
  };
};
export const removeEMAlertsListDiffusionAction = (payload) => {
  return {
    type: actionsTypesEM.removeEMAlertsListDiffusion,
    payload,
  };
};
export const addEMAlertsLocutionsAction = (payload) => {
  return {
    type: actionsTypesEM.addEMAlertsLocutions,
    payload,
  };
};
export const removeEMAlertsLocutionsAction = (payload) => {
  return {
    type: actionsTypesEM.removeEMAlertsLocutions,
    payload,
  };
};

export const changeEMAlertsCallAndRecordAction = (callAndRecord) => {
  return {
    type: actionsTypesEM.changeEMAlertsCallAndRecord,
    callAndRecord,
  };
};

export const clearEMAlertsFormAction = () => {
  return {
    type: actionsTypesEM.clearEMAlertsForm,
    payload: null,
  };
};

export const changeEMAlertsExternalAction = (payload) => {
  return {
    type: actionsTypesEM.changeEMAlertsExternal,
    payload,
  };
};

export const clearEMAlertsListDiffusionAction = () => {
  return {
    type: actionsTypesEM.clearEMAlertsListDiffusion,
  };
};

export const clearEMAlertsSecondaryListDiffusionAction = () => {
  return {
    type: actionsTypesEM.clearEMAlertsSecondaryListDiffusion,
  };
};

export const clearEMAlertsLocutionsAction = () => {
  return {
    type: actionsTypesEM.clearEMAlertsLocutions,
  };
};

//HISTORY

export const getHistoryAction = (histories) => {
  return {
    type: actionsTypesEM.getHistory,
    histories,
  };
};

export const loadFirstItemsHistoryAction = (histories) => {
  return {
    type: actionsTypesEM.loadFirstItemsHistory,
    histories,
  };
};

export const loadNextItemsHistoryAction = (histories) => {
  return {
    type: actionsTypesEM.loadNextItemsHistory,
    histories,
  };
};
