export const actionsTypes = {
  // CRUD Numbering List
  getNumberingList: 'GET_NUMBERING_LIST',
  getNumberingListModal: 'GET_NUMBERING_LIST_MODAL',
  createNumberingList: 'CREATE_NUMBERING_LIST',
  createNumberingListModal: 'CREATE_NUMBERING_LIST_MODAL',
  updateNumberingList: 'UPDATE_NUMBERING_LIST',
  updateNumberingListModal: 'UPDATE_NUMBERING_LIST_MODAL',
  deleteNumberingList: 'DELETE_NUMBERING_LIST',
  cleanGetNumberingList: 'CLEAN_GET_NUMBERING_LIST',
  importFileNumberingList: 'IMPORT_FILE_NUMBERING_LIST',
  getDdiLink: 'GET_DDI_LINK',
  deleteMarca: 'DELETE_MARCA',
  deleteModelo: 'DELETE_MODELO',
  deletePlantilla: 'DELETE_PLANTILLA',
  // CRUD Extensions
  getExtension: 'GET_EXTENSIONS',
  getExternalNumbRules: 'GET_MODAL_EXT_NUMB_RULES',
  getMarcas: 'GET_MARCAS',
  getModelos: 'GET_MODELOS',
  getPlantillas: 'GET_PLANTILLAS',

  createMarca: 'CREATE_MARCA',
  createModelo: 'CREATE_MODELO',
  createPlantilla: 'CREATE_PLANTILLA',
  createExtension: 'CREATE_EXTENSION',
  updateExtension: 'UPDATE_EXTENSION',
  updatePlantilla: 'UPDATE_PLANTILLA',
  updateMarca: 'UPDATE_MARCA',
  updateModelo: 'UPDATE_MODELO',
  deleteExtension: 'DELETE_EXTENSION',
  importFileExtension: 'IMPORT_FILE_EXTENSION',

  changeMarcaId: 'CHANGE_MARCA_ID',
  changeMarcaNombre: 'CHANGE_MARCA_NOMBRE',
  clearMarcaForm: 'CLEAR_MARCA_FORM',
  clearModeloForm: 'CLEAR_MODELO_FORM',
  changeModeloId: 'CHANGE_MODELO_ID',
  changeModeloNombre: 'CHANGE_MODELO_NOMBRE',
  changeModeloMarca: 'CHANGE_MODELO_MARCA',

  clearPlantillaModelo: 'CLEAR_MODELO',

  clearPlantillaForm: 'CLEAR_PLANTILLA_FORM',
  clearPlantillaNombre: 'CLEAR_PLANTILLA_NOMBRE',
  changePlantillaId: 'CHANGE_PLANTILLA_ID',
  changePlantillaMarca: 'CHANGE_PLANTILLA_MARCA',
  changePlantillaModelo: 'CHANGE_PLANTILLA_MODELO',
  changePlantillaNombre: 'CHANGE_PLANTILLA_NOMBRE',
  changeExtensionMarca: 'CHANGE_EXTENSION_MARCA',
  changePlantillaBlfs: 'CHANGE_PLANTILLA_BLFS',
  changePlantillaObservaciones: 'CHANGE_PLANTILLA_OBSERVACIONES',
  // Form Extensions
  changeExtensionModelo: 'CHANGE_EXTENSION_MODELO',
  changeExtensionPlantilla: 'CHANGE_EXTENSION_PLANTILLA',
  changeExtensionId: 'CHANGE_EXTENSION_ID',
  changeExtensionNumber: 'CHANGE_EXTENSION_NUMBER',
  changeExtensionName: 'CHANGE_EXTENSION_NAME',
  changeExtensionPassword: 'CHANGE_EXTENSION_PASSWORD',
  changeExtensionRingTimeout: 'CHANGE_EXTENSION_RING_TIMEOUT',
  addExtensionIdentification: 'ADD_EXTENSION_IDENTIFICATION',
  removeExtensionIdentification: 'REMOVE_EXTENSION_IDENTIFICATION',
  addTypeExtension: 'ADD_EXTENSION_TYPE',
  addMarcaExtension: 'ADD_EXTENSION_MARCA',
  addModeloExtension: 'ADD_EXTENSION_MODELO',
  removeTypeExtension: 'REMOVE_EXTENSION_TYPE',
  clearExtensionForm: 'CLEAR_EXTENSION_FORM',
  changeExtensionCallForward: 'CHANGE_EXTENSION_CALL_FORWARD',
  changeExtensionCallForwardNA: 'CHANGE_EXTENSION_CALL_FORWARD_NA',
  addExtensionCompanyId: 'ADD_EXTENSION_COMPANY_ID',
  removeExtensionCompanyId: 'REMOVE_EXTENSION_COMPANY_ID',
  addExtensionRecord: 'ADD_EXTENSION_RECORD',
  removeExtensionRecord: 'REMOVE_EXTENSION_RECORD',
  addExtensionPikedGroup: 'ADD_EXTENSION_PIKED_GROUP',
  addExtensionUser: 'ADD_EXTENSION_USER',
  removeExtensionAllow: 'REMOVE_EXTENSION_ALLOW',
  removeExtensionPikedGroup: 'REMOVE_EXTENSION_PIKED_GROUP',
  clearExtensionsPikedGroup: 'CLEAR_EXTENSIONS_PIKED_GROUP',
  changeExtensionVoicemailActive: 'CHANGE_EXTENSION_VOICEMAIL_ACTIVE',
  changeExtensionFilterType: 'CHANGE_EXTENSION_FILTERTYPE',

  addExtensionDepartment: 'ADD_EXTENSION_DEPARTMENT',
  addExtensionCategories: 'ADD_EXTENSION_CATEGORIES',
  changeExtensionCallWaiting: 'CHANGE_EXTENSION_CALL_WAITING',
  changeExtensionSipPeerId: 'CHANGE_EXTENSION_SIP_PEER_ID',
  addExtensionAllow: 'ADD_EXTENSION_ALLOW',
  clearExtensionAllow: 'CLEAR_EXTENSION_ALLOW',
  removeExtensionDepartment: 'REMOVE_EXTENSION_DEPARTMENT',
  removeExtensionCategories: 'REMOVE_EXTENSION_CATEGORIES',
  addNumberingListSoundPromptCloseId: 'ADD_NUMBERING_LIST_SOUNDPROMPTCLOSEID',
  addNumberingListDepartmentId: 'ADD_NUMBERING_LIST_DEPARTMENTID',
  changeNumberingListCompanyId: 'ADD_NUMBERING_LIST_COMPANYID',
  changeNumberingListId: 'CHANGE_NUMBERING_LIST_ID',
  changeNumberingListModalId: 'CHANGE_MODAL_RULEID',
  changeNumberingListNumber: 'CHANGE_NUMBERING_LIST_NUMBER',
  changeNumberingListName: 'CHANGE_NUMBERING_LIST_NAME',
  addNumberingListHq: 'ADD_NUMBERING_LIST_HQ',
  removeNumberingListHq: 'REMOVE_NUMBERING_LIST_HQ',
  clearNumberingListForm: 'CLEAR_NUMBERING_LIST_FORM',
  clearNumberingListModal: 'CLEAR_NUMBERING_LIST_MODAL',
  addNumberingListCompanyId: 'ADD_NUMBERING_LIST_COMPANY_ID',
  removeNumberingListCompanyId: 'REMOVE_NUMBERING_LIST_COMPANY_ID',
  addNumberingListDepartment: 'ADD_NUMBERING_LIST_DEPARTMENT',
  removeNumberingListDepartment: 'REMOVE_NUMBERING_LIST_DEPARTMENT',
  addNumberingListCalendar: 'ADD_MODAL_CALENDAR',
  addNumberingListSchedule: 'ADD_MODAL_SCHEDULE',
  addNumberingListSoundPrompt: 'ADD_MODAL_SOUND_PROMPT',
  addNumberingListDestination: 'ADD_MODAL_DESTINATION',
  addNumberingListSoundPromptClose: 'ADD_NUMBERING_LIST_SOUND_PROMPT_CLOSE',
  addNumberingListDestinationClose: 'ADD_NUMBERING_LIST_DESTINATION_CLOSE',
  addNumberingListDestinationCloseId: 'ADD_NUMBERING_LIST_DESTINATION_CLOSEID',
  addNumberingListDestinationCloseTypeId: 'ADD_NUMBERING_LIST_DESTINATION_TYPECLOSEID',
  addNumberingListDestinationCloseType: 'ADD_NUMBERING_LIST_DESTINATION_TYPECLOSE',
  addNumberingListDestinationType: 'ADD_MODAL_DESTINATIONTYPE',
  addNumberingListCalendars: 'ADD_NUMBERING_LIST_CALENDARS',
  addNumberingListLocutions: 'ADD_NUMBERING_LIST_LOCUTIONS',
  addNumberingListTimes: 'ADD_NUMBERING_LIST_TIMES',
  addNumberingListSoundPrompts: 'ADD_NUMBERING_LIST_SOUNDPROMPTS',
  addNumberingListSchedules: 'ADD_NUMBERING_LIST_SCHEDULES',
  addNumberingListDestinations: 'ADD_NUMBERING_LIST_DESTINATIONS',
  addNumberingListIsDefault: 'ADD_MODAL_ISDEFAULT',
  addNumberingListDispositivos: 'ADD_NUMBERING_LIST_DISPOSITIVOS',
  addNumberingListIsDefaults: 'ADD_NUMBERING_LIST_ISDEFAULTS',
  addNumberingListDestinationsType: 'ADD_NUMBERING_LIST_DESTINATIONSTYPE',
  addNumberingListDestinationTypeClose: 'ADD_NUMBERING_LIST_DESTINATION_TYPECLOSE',
  addDestinationCalendar: 'ADD_NEW_DESTINATION_CALENDAR',
  removeDestinationCalendar: 'REMOVE_DESTINATION_CALENDAR',
  addDestinationOpen: 'ADD_DESTINATION_OPEN',
  clearNumberingListCalendar: 'CLEAR_NUMBERING_LIST_CALENDAR',
  clearNumberingListSchedule: 'CLEAR_NUMBERING_LIST_SCHEDULE',
  clearNumberingListSoundPrompt: 'CLEAR_NUMBERING_LIST_SOUND_PROMPT',
  clearNumberingListDestination: 'CLEAR_NUMBERING_LIST_DESTINATION',
  clearNumberingListSoundPromptClose: 'CLEAR_NUMBERING_LIST_SOUND_PROMPT_CLOSE',
  clearNumberingListDestinationClose: 'CLEAR_NUMBERING_LIST_DESTINATION_CLOSE',
  clearNumberingListCompanyId: 'CLEAR_NUMBERING_LIST_COMPANY',
  clearListNumberingDepartment: 'CLEAR_LIST_NUMBERING_DEPARTMENT',
  removeDestinationOpen: 'REMOVE_DESTINATION_OPEN',
};
