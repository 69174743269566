import React, { useRef } from 'react';
import { colors } from '../../theme/colors';
import { Card } from '../Card/Card';
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch';
import { CardGridWrapper } from './CardGrid.style';
import { array, func, string } from 'prop-types';

export const CardGird = ({ data, onClick, toggleSwitch }) => {
  const scrollContainerRef = useRef(null);

  // function handleUserScroll() {
  //     const scrollContainer = scrollContainerRef.current

  //     const scrollTop = scrollContainer.scrollTop
  //     const scrollHeight = scrollContainer.scrollHeight

  //     if (scrollTop + 720 >= scrollHeight) {
  //         setIsBottom(true)
  //     }
  // }

  // useEffect(() => {
  //     const scrollContainer = scrollContainerRef.current
  //     scrollContainer.addEventListener('scroll', handleUserScroll)
  //     return () => scrollContainer.removeEventListener('scroll', handleUserScroll)
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <CardGridWrapper ref={scrollContainerRef}>
      {data.map((item, index) => (
        <div key={index}>
          <Card
            title={item.title}
            subtitle={item.subtitle}
            text={item.text}
            text2={item.text2}
            icon={item.icon}
            icon2={item.icon2}
            icon3={item.icon3}
            onClick={onClick}
            data={item}
            component={
              item.component === 'toggle' ? (
                <ToggleSwitch data={item} isActive={item.isActive} toggleSwitch={toggleSwitch} color={colors.primaryColor} />
              ) : item.component === 'audio' ? (
                <audio src={item.audio} controls />
              ) : (
                ''
              )
            }
          />
        </div>
      ))}
    </CardGridWrapper>
  );
};

CardGird.propTypes = { data: array, onClick: func, toggleSwitch: string, setIsBottom: func };
