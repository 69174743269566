// import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const StepFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  #role {
    grid-column-start: 1;
  }
  #firstname {
    grid-column-start: 1;
  }

  .generatedPassword {
    display: none;
  }

  #isNotAnswer {
    grid-column-start: 3;
  }

  #isVoicemail {
    grid-column-start: 1;
  }

  #isvoicemailEmail {
    grid-column-start: 1;
  }

  #isRainbow {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  #notAnswer {
    grid-column-start: 4;
  }

  #isCallForward {
    grid-column-start: 1;
  }

  #voicemailPIN,
  #callForward,
  #notAnswer {
    width: 80px;
  }

  .dropzone {
    width: 100%;
    min-width: 250px;
  }
  .stepForm__section__title {
    width: max-content;
    font-size: 14px;

    svg {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      padding: 0 5px 0;
      color: ${colors.primaryColor};
    }
  }

  .psw__icons__container {
    color: #4a5056;
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    svg {
      width: 35px;
      height: 35px;
      cursor: pointer;
      color: #0c3fc4;
      border: 1px solid #0c3fc4;
      border-radius: 5px;
      padding: 5px;

      &:hover {
        color: #ffffff;
        background-color: #0c3fc4;
        border-radius: 10px;
      }
    }
  }

  .error {
    color: ${colors.red};
    font-size: ${newFontSize.span};
    margin-top: 2px;
  }

  .basic__destination {
    color: ${colors.primaryColor};
    box-sizing: border-box;
    height: 100%;
    position: relative;
    top: 10px;
    left: -115px;
    border: none;
  }

  .ivr__contain-typeInput {
    ${'' /* width: 80%; */}
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: flex-start;
    ${'' /* column-gap: 26px; */}
    row-gap: 20px;
    ${'' /* padding: 70px 40px 40px 40px; */}
    border-radius: 15px;
    margin-bottom: 50px;
    margin: auto;

    .contain__select,
    .contain__input,
    .phone__notPressed__select {
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        width: max-content;
        border-radius: 10px;
      }
    }

    span {
      color: white;
      font-weight: bold;
    }

    .containerPhone {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 44;
    }
  }

  .icon__add__destination {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 10px;
    color: white;
    padding: 5px;
    background-color: ${colors.blue};
    margin-left: 30px;

    &:hover {
      background-color: ${colors.green};
    }
  }

  .contain__input {
    width: 100%;
  }

  .containerPhone {
    width: 100%;
  }

  .soundw__contain-typeInput {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    column-gap: 26px;
    row-gap: 20px;
    padding: 40px;
    width: 100%;
    border-radius: 10px;

    span {
      color: ${colors.white};
    }
    .contain__select {
      width: unset;

      div {
        font-size: 14px;
      }
    }
  }

  .pref1__contain-typeInput {
    display: grid;
    grid-template-columns: 80px 200px 200px;
    justify-content: center;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    column-gap: 26px;
    row-gap: 20px;
    padding: 40px;
    width: 100%;
    border-radius: 10px;

    span {
      color: ${colors.white};
    }
    .contain__select {
      width: unset;

      div {
        font-size: 14px;
      }
    }
  }
  .pref3__contain-typeInput {
    display: grid;
    grid-template-columns: 80px 200px 200px;
    justify-content: center;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    column-gap: 26px;
    row-gap: 20px;
    padding: 40px;
    width: 100%;
    border-radius: 10px;

    span {
      color: ${colors.white};
    }
    .contain__select {
      width: unset;

      div {
        font-size: 14px;
      }
    }
  }

  #soundPrompt__1 {
    grid-column-start: 2;
    grid-row-start: 1;
    background-color: ${colors.primaryColorLight};
    padding: 30px;
    display: flex;
    height: 166px;
    gap: 10px;
    flex-direction: column;
    width: 100%;

    span {
      color: white;
      ${
        '' /* position: relative;
      top: -25px;
      left: 75px; */
      }
    }

    ${
      '' /* audio {
      position: relative;
      top: 50px;
      left: -185px; */
    }
    ${'' /* width: 120px; */}
    ${'' /* } */}
  }

  .numb__contain-typeInput {
    height: 100%;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 15px;
    ${'' /* padding: 0px 20px 0px 20px; */}
    gap: 20px;

    span {
      color: ${colors.white};
    }
    .contain__select,
    .contain__input {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 300px;

      div {
        border-radius: 10px;
      }
    }
  }

  .queue__toggle {
    display: flex;
    flex-direction: column;
    height: 62px;
    align-items: center;

    .toggle__item {
      height: 34px;
    }
  }

  .contain__select__destination {
    display: -webkit-box;

    .destination-select {
      flex-direction: column;
      display: flex;
      justify-contente: flex-end;
    }
  }

  .queue__contain-typeInput {
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: right;
    display: grid;
    column-gap: 26px;
    grid-template-columns: 0.5fr 2fr;
    row-gap: 20px;
    margin: auto;
    background-color: ${colors.primaryColorLight};
    padding: 40px;
    ${'' /* box-shadow: 2px 2px 4 px 0px #526581; */}

    span {
      color: white;
    }
    .contain__select {
      max-width: 545px;

      div {
        font-size: 14px;
      }
    }

    .queue__toggle {
      display: flex;
      flex-direction: column;
      height: 62px;
      align-items: center;

      .toggle__item {
        height: 34px;
      }
    }
  }

  .queue2__contain-typeInput {
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    display: grid;
    column-gap: 26px;
    grid-template-columns: 200px 200px 200px;
    ${'' /* row-gap: 20px; */}
    margin: auto;
    background-color: ${colors.primaryColorLight};
    padding: 40px;
    ${'' /* box-shadow: 2px 2px 4 px 0px #526581; */}

    span {
      color: white;
    }
    .contain__select {
      max-width: 545px;

      div {
        font-size: 14px;
      }
    }

    .queue__toggle {
      display: flex;
      flex-direction: column;
      height: 62px;
      align-items: center;

      .toggle__item {
        height: 34px;
      }
    }
  }

  .sorted__header__icon {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    padding: 5px;
    color: ${colors.backgroundColor};
  }

  .ghost {
    background-color: ${colors.primaryColor} !important;
    border-radius: 5px;
    color: white;
    padding-left: 5px;
  }

  .sortableDrag:not(:last-child) {
    margin-bottom: 8px;
  }

  .queue__sortable {
    width: 50%;
    background-color: ${colors.grey};
    color: ${colors.white};
    border-radius: 10px;
    position: relative;
    align-items: center;
    justify-content: start;
    height: 100%;
    max-height: 180px;
  }

  .sorted__container {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    border-radius: 10px;
    height: 100%;
    ${'' /* max-height: 180px; */}
    color: ${colors.primaryColor};
    left: -385px;
    top: 55px;
  }

  .sorted__title {
    // margin-left: 5px;
    margin-bottom: 2px;
    color: ${colors.grey};
  }

  .sorted__header {
    width: 97%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sorted__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sorted__pos {
    width: 20px;
    font-weight: bold;
  }

  .sorted__item {
    cursor: grab;
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 0.5px solid ${colors.primaryColor};
    text-transform: capitalize;
    background-color: ${colors.primaryColorLight};
    border-radius: 5px;
    color: ${colors.white};

    &:last-child: {
      border-bottom: none;
    }
    &:hover {
      background-color: ${colors.primaryColor};
      color: ${colors.white};

      .item__icon {
        color: ${colors.white};
      }
    }
    &:active {
      background-color: ${colors.primaryColor};
    }
  }

  .item__icon {
    color: ${colors.white};
  }

  .queue__list {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border-bottom: 1px solid ${colors.white};
    text-transform: capitalize;
  }

  .textRequired {
    color: ${colors.primaryColor};
    font-size: ${newFontSize.span};
    font-weight: ${weight.bold};
    width: max-content;
  }

  .form__body__locution {
    .stepForm__contain-typeInput {
      padding: 40px;

      span {
        color: wbite;
      }

      .contain__select {
        grid-row-start: 1;
        font-size: 16px;
      }
    }
  }

  .basicRow {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .stepForm {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    row-gap: 13px;

    &__header {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &__number {
      background-color: ${colors.primaryColor};
      font-size: ${newFontSize.button};
      color: ${colors.white};
      font-weight: ${weight.medium};
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0;
    }

    &__title {
      color: ${colors.primaryColor};
      font-weight: ${weight.bold};
      font-family: ${fontFamily.font};
      margin-left: 5px;

      .title {
        margin: 0;
      }
    }

    &__contain-typeInput__hidden {
      visibility: hidden;
    }

    &__contain-typeInput {
      .contain__select,
      .contain__input {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      span {
        color: white;
      }
    }

    margin: auto;

    .contain__select {
      display: flex;
      flex-direction: column;
      height: max-content;
      max-width: 220px;
    }

    .contain__input {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;
      max-width: 200px;
      position: relative;
      grid-row-start: 1;
    }

    .contain__toggleButton {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;
      position: relative;
      grid-row-start: 9;
      grid-column-start: 2;

      &__item {
        height: 27px;
        width: 220px;
      }
    }

    .contain__form-dropzone {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;

      .dropzone {
        width: 100%;
        display: grid;
      }
    }

    .contain__titleInputPair {
      min-height: 96px;
    }

    &__toggle {
      display: flex;
      flex-direction: column;
      height: 52px;
      width: 100%;
      gap: 10px;
      align-items: flex-start;
      flex: 1;

      div &:nth-child {
        position: relative;
        left: -20px;
      }

      .toggle__item {
        height: 34px;
        width: max-content;
      }
    }

    &__radio {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: max-content;
      padding: 0 5px 10px 0;

      .contain__items-radio {
        display: flex;
        gap: 10px;
        min-height: 35px;

        div {
          width: max-content;
        }
      }
    }

    .schedule_container {
      grid-column: 1 / 4;
    }

    .calendar_container {
      width: 100%;
      grid-column: 1 / 4;

      .calendar_wrapper {
        margin-top: 15px;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        border: 1px solid #d2d3d4;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .color {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .container_multiFormPagesWrapper {
    position: relative;
    width: 100%;
  }
`;
