// import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

// export const StepFormWrapper = styled(motion.div)`
export const StepFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  #role {
    grid-column-start: 1;
  }
  #firstname {
    grid-column-start: 1;
  }

  .generatedPassword {
    display: none;
  }

  #isNotAnswer {
    grid-column-start: 3;
  }

  #isVoicemail {
    grid-column-start: 1;
  }

  #isvoicemailEmail {
    grid-column-start: 1;
  }

  #isRainbow {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  #roleId {
    grid-column-start: 1;
  }

  #notAnswer {
    grid-column-start: 4;
  }

  #isCallForward {
    grid-column-start: 1;
  }

  #voicemailPIN,
  #callForward,
  #notAnswer {
    ${
      '' /* position: relative;
    left: -30px; */
    }
    width: 80px;
  }

  .stepForm__section__title {
    width: 100%;
    min-width: 800px;
    font-size: 14px;
    color: ${colors.primaryColor};
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .psw__icons__container {
    color: #4a5056;
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    svg {
      width: 35px;
      height: 35px;
      cursor: pointer;
      color: #0c3fc4;
      border: 1px solid #0c3fc4;
      border-radius: 5px;
      padding: 5px;

      &:hover {
        color: #ffffff;
        background-color: #0c3fc4;
        border-radius: 10px;
      }
    }
  }

  .error {
    color: ${colors.red};
    font-size: ${newFontSize.span};
    margin-top: 2px;
  }

  .basic__destination {
    color: ${colors.primaryColor};
    box-sizing: border-box;
    height: 100%;
    position: relative;
    top: 10px;
    left: -115px;
    border: none;
  }

  .ivr__contain-typeInput {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: flex-start;
    column-gap: 26px;
    row-gap: 20px;
    margin-bottom: 50px;

    .contain__select {
      width: 50%;
    }
  }

  .icon__add__destination {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 10px;
    color: white;
    padding: 5px;
    background-color: ${colors.blue};
    margin-left: 30px;
    ${'' /* margin-top: 12px; */}

    &:hover {
      background-color: ${colors.green};
    }
  }

  .contain__select {
    width: max-content;
    ${'' /* z-index: 901; */}
  }

  .contain__input {
    width: 200px;
    ${'' /* grid-row-start: 2; */}
  }

  .containerPhone {
    width: 100%;
  }

  ${
    '' /* .numb_contain__table {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: calc(100% - 55px);
   
  } */
  }

  .soundw__contain-typeInput {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    column-gap: 26px;
    row-gap: 20px;
    max-width: 645px;
    margin: auto;
    margin-bottom: 50px;

    .contain__select {
      width: 180px;
    }
  }

  .numb__contain-typeInput {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    margin: auto;

    .contain__input {
      max-width: 100px;
    }
    .input {
      max-width: 100px;
    }

    .basicRow {
      width: unset;
    }
    .contain__select {
      width: 180px;
    }

    .contain__select__calendar {
      width: 180px;
    }

    .contain__select__calendar {
      &::before {
        background-color: ${colors.green};
        color: red;
      }
    }
    .container__cards {
      top: 40px;

      &:last-child(1) {
        background-color: ${colors.blue};
      }
    }
  }

  .queue__toggle {
    display: flex;
    flex-direction: column;
    height: 62px;
    align-items: center;

    .toggle__item {
      height: 34px;
    }
  }

  .contain__select__destination {
    display: -webkit-box;

    .destination-select {
      flex-direction: column;
      display: flex;
      justify-contente: flex-end;
    }
  }

  .queue__contain-typeInput {
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: right;
    display: grid;
    column-gap: 26px;
    grid-template-columns: 0.5fr 2fr;
    row-gap: 20px;
    margin: auto;

    .contain__select {
      max-width: 545px;
    }

    .queue__toggle {
      display: flex;
      flex-direction: column;
      height: 62px;
      align-items: center;

      .toggle__item {
        height: 34px;
      }
    }
  }

  .sorted__header__icon {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    padding: 5px;
    color: ${colors.grey};
  }

  .ghost {
    background-color: ${colors.primaryColor} !important;
    border-radius: 5px;
    color: white;
    padding-left: 5px;
  }

  .sortableDrag:not(:last-child) {
    margin-bottom: 8px;
  }

  .queue__sortable {
    width: 50%;
    background-color: ${colors.grey};
    color: ${colors.white};
    border-radius: 10px;
    position: relative;
    align-items: center;
    justify-content: start;
    height: 100%;
    max-height: 180px;
  }

  .sorted__container {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    border-radius: 10px;
    height: 100%;
    max-height: 180px;
    color: ${colors.primaryColor};
    left: -385px;
    top: 55px;
  }

  .sorted__title {
    // margin-left: 5px;
    margin-bottom: 2px;
    color: ${colors.grey};
  }

  .sorted__header {
    width: 97%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sorted__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sorted__pos {
    width: 20px;
    font-weight: bold;
  }

  .sorted__item {
    cursor: grab;
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid ${colors.greyLine};
    text-transform: capitalize;
    background-color: #abb2c6;
    border-radius: 5px;

    &:last-child: {
      border-bottom: none;
    }
    &:hover {
      background-color: ${colors.primaryColorLight};
      color: ${colors.white};

      .item__icon {
        color: ${colors.backgroundColor};
      }
    }
    &:active {
      background-color: ${colors.primaryColor};
    }
  }

  .item__icon {
    color: ${colors.grey};
  }

  .queue__list {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border-bottom: 1px solid ${colors.white};
    text-transform: capitalize;
  }

  .textRequired {
    color: ${colors.primaryColor};
    font-size: ${newFontSize.span};
    font-weight: ${weight.regular};

    // margin-left: 10px;
  }

  .contain__select {
    width: 100%;
    ${'' /* font-weight: ${weight.bold}; */}
  }

  .basicRow {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .stepForm {
    width: 100%;
    ${'' /* height: 100px; */}
    margin: auto;
    justify-content: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    ${'' /* max-width: ${(props) => (props.maxwidth === undefined ? '600px' : props.maxwidth)}; */}
    row-gap: 13px;

    &__header {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &__number {
      background-color: ${colors.primaryColor};
      font-size: ${newFontSize.button};
      color: ${colors.white};
      font-weight: ${weight.medium};
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0;
    }

    &__title {
      ${'' /* font-size: ${newFontSize.body}; */}
      color: ${colors.primaryColor};
      font-weight: ${weight.bold};
      font-family: ${fontFamily.font};
      margin-left: 5px;

      .title {
        margin: 0;
      }
    }

    &__contain-typeInput__hidden {
      visibility: hidden;
    }

    &__contain-typeInput {
      ${'' /* max-width: 750px; */}
      box-sizing: border-box;
      align-items: center;
      gap: 40px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    row-gap: 20px;
    margin: auto;

    .contain__select {
      display: flex;
      flex-direction: column;
      height: max-content;
      max-width: 220px;
    }

    .contain__input {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;
      max-width: 200px;
      position: relative;
    }

    .contain__toggleButton {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;
      position: relative;

      &__item {
        height: 27px;
        width: 220px;
      }
    }

    .contain__form-dropzone {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;

      .dropzone {
        width: 100%;
        display: grid;
      }
    }

    .contain__titleInputPair {
      min-height: 96px;
    }

    &__toggle {
      display: flex;
      flex-direction: column;
      height: 52px;
      width: 100%;
      align-items: flex-start;
      flex: 1;
      ${'' /* grid-column-start: 1; */}

      div &:nth-child {
        position: relative;
        left: -20px;
      }

      .toggle__item {
        height: 34px;
        width: 100%;
      }
    }

    &__radio {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 0 5px 10px 0;

      .contain__items-radio {
        display: flex;
        gap: 10px;
        min-height: 35px;
        width: 100%;
      }
    }

    .schedule_container {
      grid-column: 1 / 4;
    }

    .calendar_container {
      width: 100%;
      grid-column: 1 / 4;

      .calendar_wrapper {
        margin-top: 15px;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        border: 1px solid #d2d3d4;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .color {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .container_multiFormPagesWrapper {
    position: relative;
    width: 100%;
  }
`;
