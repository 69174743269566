import styled from 'styled-components';
import { colors } from '../../theme/colors';

import { newFontSize, weight } from '../../theme/fonts';

export const PreferencesScreenWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
.contain__table {
  width:80%;
  }

.menupref__container {
    position: relative;
    top: 20px;
    color: black;
    display: flex;
    justify-content: center;

    &__link {
      box-sizing: border-box;
      height: 100%;
      width: fit-content;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      align-items: start;
      border-radius: 15px;
      justify-content: center;

      .menuTop__icon {
        margin-right: 5px;
      }

      &:hover {
        cursor: pointer;
        color: ${colors.blue};
      }

      .item-link {
        box-sizing: border-box;
        width: max-content;
        color: ${colors.white};
        font-weight: ${weight.medium};
        // font-size: ${newFontSize.h3};
        line-height: 16px;
        padding: 5px;
        letter-spacing: 0.25px;
        display: flex;
        justify-content: center;
        align-items: center;
        // color:${colors.white};
        border-radius: 15px;

        &:hover {
          cursor: pointer;
          color: ${colors.white};
          border: 1px solid ${colors.white};
          background: ${colors.blue};
          // //transition: all 0.3s;
          border-radius: 15px;

          .menuTop__icon {
            color: ${colors.white};
            // //transition: all 0.3s;
            margin-right: 5px;
          }
        }
      }
      &__link-active {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      max-width: 190px
      display: flex;
      justify-items: center;
      align-items: center;
      background-color: ${colors.blue};
      border-radius: 10px;
      border: 2px solid ${colors.white};
      // //transition: all 0.3s;
      
      .menuTop__icon {
        color: ${colors.white};
        // //transition: all 0.3s;
        // margin-right: 5px;
      }
    }

  }

  .menupref__link-active {
    box-sizing: border-box;
    width: max-content;
    color: ${colors.white};
    background-color: ${colors.blue};
    font-weight: ${weight.medium};
    // font-size: ${newFontSize.h3};
    line-height: 16px;
    padding: 5px;
    letter-spacing: 0.25px;    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;    
  
    &:hover{
     cursor:pointer;
    }
  }

}

  // .header__container {
  //   box-sizing: border-box;
  //   width: 100%;
  //   height: 50px;
  //   position: fixed;
  //   top: 60px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 10px;
  //   font-size: 20px;
  //   border-radius: 15px;
  // }
`;
