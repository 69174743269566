import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const AppsScreenWrapper = styled.div`
  ${'' /* .applications { */}
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  ${'' /* padding-top: 10px; */}
  background-color: ${colors.backgroundColor};

  .distributionTable {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .diffusion-list {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin-right: 70px;
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-top: 70px;
    border-radius: 10px;
    background-color: ${colors.backgroundColor};

    .contain__table {
      box-sizing: border-box;
      height: calc(100% - 55px);
      position: relative;
      display: flex;
      left: 15px;
    }
  }

  .diffusion__distributionTable {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 110px;
    /* padding-top: 138px;
  padding-left: 200px; */
    overflow: scroll;
    left: 130px;
  }
`;
