import axios from 'axios';
import { getSchedulesAction, getCalendarAction } from '../actions/preferences/actionCreators';
import { array } from 'prop-types';

// const API = `${import.meta.env.VITE_APP_URL}`;

const urlBack = import.meta.env.VITE_BACK_URL;
// Schedule

const joinHelper = (scheduleArray) => {
  const updatedScheduleArray = scheduleArray.map((schedule) => {
    let i = 0;

    while (i < schedule.length) {
      schedule[i].print = true;
      schedule[i].ScheduleTime.hourInit = schedule[i].ScheduleTime.hourInit.slice(0, 5);
      schedule[i].ScheduleTime.hourEnd = schedule[i].ScheduleTime.hourEnd.slice(0, 5);
      schedule[i].scheduleString = schedule[i].ScheduleTime.hourInit + ' - ' + schedule[i].ScheduleTime.hourEnd;

      const actualId = i;
      const actualDay = schedule[i].ScheduleTime.weekDay;
      ++i;

      while (i < schedule.length && schedule[i].ScheduleTime.weekDay === actualDay) {
        schedule[i].ScheduleTime.hourInit = schedule[i].ScheduleTime.hourInit.slice(0, 5);
        schedule[i].ScheduleTime.hourEnd = schedule[i].ScheduleTime.hourEnd.slice(0, 5);
        schedule[actualId].scheduleString += '</br> ' + schedule[i].ScheduleTime.hourInit + ' - ' + schedule[i].ScheduleTime.hourEnd;
        ++i;
      }
    }
    return schedule;
  });
  return updatedScheduleArray;
};

export const createScheduleThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    const response = await axios.post(`${urlBack}preferences/schedule/create`, { data: payload });

    setLoading(false);
    return response;
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

export const getSchedulesThunk = (setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const { data } = await axios.get(`${urlBack}preferences/schedule`);
    dispatch(getSchedulesAction(joinHelper(data)));

    setLoading(false);
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

export const updateScheduleThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    const response = await axios.patch(`${urlBack}preferences/schedule/update`, { data: payload });

    setLoading(false);
    return response;
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

export const deleteScheduleThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    const response = await axios.delete(`${urlBack}preferences/schedule/delete`, { data: payload });

    setLoading(false);
    return response;
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

// Calendar

export const getCalendarThunk = (setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const { data } = await axios.get(`${urlBack}preferences/calendar`);

    dispatch(getCalendarAction(data));
    setLoading(false);
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

export const createCalendarThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    await axios.post(`${urlBack}preferences/calendar/create`, { data: payload }).then((data) => {
      setLoading(false);
      return data;
    });
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

export const updateCalendarThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    const data = await axios.patch(`${urlBack}preferences/calendar/update`, { data: payload });

    setLoading(false);
    return data;
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

export const deleteCalendarThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    const data = await axios.delete(`${urlBack}preferences/calendar/delete`, { data: payload });

    return data;
  } catch (error) {
    if(import.meta.env.VITE_DEBUG==='true') console.log(error);
  }
};

joinHelper.propTypes = { scheduleArray: array };
