import sortBlack from '../../assets/images/table/sort-black.svg';
import sort from '../../assets/images/table/sort.svg';
import sortAscending from '../../assets/images/table/sortAscending.svg';
import sortDescending from '../../assets/images/table/sortDescending.svg';

export const nameTables = {
  Pbx_Company_Link: 'Sedes',
  Pbx_Department: 'Departamentos',
  Pbx_User: 'Usuarios',
  Pbx_Extensions: 'Extensiones',
  Pbx_Categories: 'Categorías',
  Pbx_Queue: 'Colas',
  Pbx_Moh: 'Música en espera',
  Pbx_Voicemail: 'Buzón de voz',
  Wide_Module_EM_Alarms: 'Alertas',
  Pbx_Outbound_Routes: 'Troncales',
  CompanyLink: 'Sede y departamento',
  ConferenceRoom: 'Conferencia',
  Pbx_Ddi: 'Listado de numeración ',
  OutboundRoute: 'Troncales',
  Pbx_Soundprompt: 'Locuciones',
};

export const dataTable = {
  sort: {
    image: sort,
    alt: 'sort',
  },
  sortBlack: {
    image: sortBlack,
    alt: 'sort',
  },
  sortAscending: {
    image: sortAscending,
    alt: 'sortAscending',
  },
  sortDescending: {
    image: sortDescending,
    alt: 'sortDescending',
  },
};
