import styled from 'styled-components';
// import { colors } from '../../theme/colors.js';

export const CallRegisterNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  ${'' /* background-color: ${colors.primaryColor}; */}
  ${'' /* padding-left: ${(p) => (!p.isMenuOpen ? '60px' : '218px')}; */}
`;
