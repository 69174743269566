import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const TableInfoWrapper = styled.div`
  width: 100%;
  border-radius: 10px;

  .icon-activated {
    color: ${colors.green};
    margin-left: 10px;
  }

  .isDefault {
    max-width: 50px;
    min-width: unset;
  }

  .icon-deactivated {
    color: ${colors.red};
    margin-left: 10px;
  }

  .iconDevices__column {
    min-width: unset;
    max-width: 50px;
    td {
      min-width: unset;
      max-width: 50px;
    }
  }
  .iconDevices__container {
    display: flex;
    ${'' /* color: ${colors.blue}; */}
    gap: 5px;
    justify-content: center;
    min-width: unset;
  }

  .icon-fav-activated {
    color: ${colors.burlywood};
    margin-left: 10px;
  }

  .icon-fav-deactivated {
    color: ${colors.black};
    margin-left: 10px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    background: ${colors.primaryColor};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  audio {
    height: 25px;
  }

  .last-td div:last-child {
    display: flex;
    align-items: right;
    width: 100%;
    justify-content: flex-end;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    border-radius: 10px;
  }

  table thead tr th {
    ${'' /* border-right: 1px solid #7b7474; */}
    border-left: none;
    text-align: start;
    ${'' /* min-width: 150px; */}
  }

  tr th:first-child {
    max-width: 40px;
    padding-left: 10px;
  }

  ${
    '' /* thead tr:first-child th:nth-child(2) div:first-child {
    justify-content: center;
  } */
  }

  .tooltip {
    background: ${colors.primaryColor};
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 10px;
    padding-bottom: 25px;
    text-align: left;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: auto;
    text-align: left;
  }

  .tooltip-container {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: white;
    border-radius: 10px;
  }

  .tooltip-Locutions {
    display: flex;
    color: black;
    border-radius: 10px;
    height: 100%;
    width: 200px;

    audio {
      width: 135px;
      height: 20px;
      text-align: left;
    }
  }

  .tooltip-locution {
    width: 200px;
  }

  .tooltip-body {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    border-radius: 10px;
    background-color: none;
  }

  .edit-buttons {
    text-align: right;
  }

  .icons__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .icon__row {
      opacity: 0.7;
      border-radius: 5px;
      width: 30px;
      height: 30px;
      padding: 2px;

      &:hover {
        cursor: pointer;
        background-color: #0c3fc4;
        opacity: 1;
        border-radius: 5px;
        color: white;
      }
    }
  }

  .col__fullName {
    min-width: 230px;
  }

  .SIPExtensionId {
    width: 40px;
    min-width: unset;

    h3 {
      padding-left: unset;
    }

    .tooltip-column {
      margin: auto;
    }
  }

  table td {
    height: 55px;
    ${'' /* max-width: 40px; */}
    border-left: none;
    ${'' /* font-weight: ${weight.regular}; */}
    line-height: 16px;
    letter-spacing: 0.25px;
    ${'' /* color: #0c3fc4; */}
    margin: auto;
    text-transform: math-auto;
    text-transform: capitalize;

    &:last-child {
      width: 100%;
      height: 55px;
    }
  }

  table thead {
    position: relative;
    margin-left: 10px;
    z-index: 2;
  }

  th tr {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: bold;
    max-width: 120px;
  }

  td {
    text-align: left;
    min-width: 160px;
    padding: 10px;
  }

  .icon-volumen {
    cursor: pointer;
    margin-left: 20px;
    width: 25px;
    height: 25px;
  }

  table th {
    font-size: ${newFontSize.button};
    color: ${colors.white};
    letter-spacing: 0.25px;
    font-weight: ${weight.medium};
    line-height: 16px;
    border-right: none;
    height: 40px;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  table tr:last-child {
    border-radius: 10px;
    border-right: none;
    ${'' /* width: 100%; */}
    text-align: right;
  }

  table th:last-child {
    border-right: none;
  }

  table th:nth-child(5) {
    ${'' /* width: 100%; */}

    h3 {
      width: 100%;
      text-align: center;
    }
  }

  table th:nth-child(6) {
    ${'' /* width: 100%; */}
    h3 {
      width: 100%;
      text-align: left;
    }

    .tooltip-column {
      min-width: 120px;
      width: 100%;
    }
  }

  table td:nth-child(2) {
    cursor: pointer;
    border-left: none;
    ${'' /* font-weight: 700; */}
    ${'' /* color: #0C3FC4; */}
  }

  table td:nth-child(3) {
    cursor: pointer;
    border-left: none;
    overflow: hidden;
    ${'' /* min-width: 200px; */}
  }

  table td:nth-child(1) {
    min-width: 40px;
    border-left: none;
    color: ${colors.blue};
    cursor: pointer;
  }

  .selected-row td:nth-child(2) {
    color: white;
    width: 20%;
    max-width: 40px;
  }

  tr:hover {
    td {
      cursor: pointer;
      color: ${colors.primaryColor};
    }

    .passwordWatcher__contain,
    .icon__password,
    .extensions__format,
    .icon-usersView {
      ${'' /* background-color: ${colors.primaryColorLight}; */}
      color: white;
      stroke: white;
      border: none;
    }

    &:last-child {
      width: 100%;
    }
  }

  thead {
    tr:hover {
      cursor: pointer;

      &:last-child {
        width: auto;
      }
    }
  }

  td div:first-child {
    border-left: none;
    font-weight: 700;
    color: ${colors.primaryColors};
    border-color: ${colors.primaryColor};
    cursor: pointer;
    width: max-content;

    .tooltip__audio {
      padding: 5px;
      color: white;
      width: 100%;
      font-weight: normal;
      z-index: 700;
    }
  }

  .checkbox {
    margin: auto;

    max-width: 50px;
    min-width: unset;

    .tooltip-column:first-child {
      width: 0px;
    }
  }

  .spacer {
    width: 30px;

    &:last-child {
      width: 100%;
    }
  }

  .image-header {
    width: 20px;
    height: 20px;
  }

  .image-showPassword {
    &:hover {
      cursor: pointer;
    }
  }
`;
