import React, { useState } from 'react';
import usePreferences from '../../hooks/usePreferences';
import moment from 'moment/moment';

import Month from './Month';
import Day from './Day';

import { ContainerCalendar } from './Calendar.style';
import { useEffect } from 'react';
import { array, func, number, object, string } from 'prop-types';

const Calendar = ({ selectedDays, setSelectedDays, monthNames, weekDays, monthsDisplayed, data, crudType }) => {
  const [currentYear, setCurrentYear] = useState(2024);
  const [currentMonth, setCurrentMonth] = useState(2);
  const { calendarForm } = usePreferences();
  const [colorEdit, setColorEdit] = useState();
  const [fistRender, setFistRender] = useState(true);

  useEffect(() => {
    if (crudType === 'create' || (crudType === 'edit' && calendarForm.color !== colorEdit)) {
      setSelectedDays([
        {
          ...selectedDays[0],
          color: calendarForm.color,
        },
      ]);
      setColorEdit(calendarForm.color);
    }

    if (crudType === 'edit' && fistRender) {
      if (data.defaultValues) {
        const month = Number(data.defaultValues[0].days[0].split('-')[1]);
        setCurrentMonth(month !== null ? (month === 1 ? 2 : month === 12 ? 11 : month) : 2);
        const year = Number(data.defaultValues[0].days[0].split('-')[2]);
        setCurrentYear(year !== null ? year : 2024);
        setSelectedDays(data.defaultValues);
        setFistRender(false);
      }
    }
  }, [calendarForm]);

  const monthsId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const onClick = (event, type) => {
    event.preventDefault();

    if (type === 'next') setCurrentYear(currentYear + 1);
    else if (type === 'previous') setCurrentYear(currentYear - 1);
  };

  const handleChangeMonth = (event) => {
    if (event === 'Increment') {
      if (currentMonth === 11) setCurrentMonth(2);
      else setCurrentMonth(currentMonth + 1);
    } else if (event === 'Decrement') {
      if (currentMonth === 2) setCurrentMonth(11);
      else setCurrentMonth(currentMonth - 1);
    }
  };
  const allMonthDays = monthsId.map((month) => {
    const days = moment(`${currentYear}-${month}`, 'YYYY-MM').daysInMonth();
    let firstDay = moment(`${currentYear}-${month}-01`, 'YYYY-MM-DD').day();

    if (firstDay === 0) firstDay = 6;
    else --firstDay;

    const calendarRows = [];
    let calendarDays = [];
    var iWithDays = firstDay;

    // Whitespaces before the first day of the month
    for (let i = 0; i < firstDay; i++) calendarDays.push(<td key={`${Math.random()}`}></td>);

    // Days of the month every row, is a week, and an element in the array
    for (let i = 1; i <= days; i++) {
      if (iWithDays % 7 === 0) {
        calendarRows.push(<tr key={`week_${iWithDays / 7}_${month}_${currentYear}`}>{calendarDays}</tr>);
        calendarDays = [];
      }

      ++iWithDays;

      calendarDays.push(
        <td key={`${i}_${month}_${currentYear}`}>
          <Day day={i} month={month} year={currentYear} selectedDays={selectedDays} setSelectedDays={setSelectedDays} crudType={crudType} />
        </td>
      );
    }

    // Last row of the month
    if (calendarDays.length > 0) calendarRows.push(<tr key={days + 1}>{calendarDays}</tr>);

    return {
      currentMonth: month,
      calendarRows: calendarRows,
    };
  });

  return (
    <ContainerCalendar>
      <div className="header">
        <button onClick={(event) => onClick(event, 'previous')}> {'<'} </button>
        <h3> {currentYear} </h3>
        <button onClick={(event) => onClick(event, 'next')}> {'>'} </button>
      </div>

      {monthsDisplayed !== 12 ? (
        <div className="edit_container">
          <div className="week_buttons">
            <button onClick={() => handleChangeMonth('Decrement')}> {'<'} </button>
            <button onClick={() => handleChangeMonth('Increment')}> {'>'} </button>
          </div>
          {
            <div className="edit_months">
              {allMonthDays.map((month) => {
                if (month.currentMonth === currentMonth || month.currentMonth === currentMonth + 1) {
                  return <Month key={month.currentMonth} monthName={monthNames[month.currentMonth]} currentYear={currentYear} calendarRows={month.calendarRows} weekDays={weekDays} />;
                } else return null;
              })}
            </div>
          }
        </div>
      ) : (
        <div className="months_container">
          {allMonthDays.map((month) => (
            <Month key={month.currentMonth} monthName={monthNames[month.currentMonth]} currentYear={currentYear} calendarRows={month.calendarRows} weekDays={weekDays} />
          ))}
        </div>
      )}
    </ContainerCalendar>
  );
};

Calendar.propTypes = { selectedDays: array, setSelectedDays: func, monthNames: array, weekDays: array, monthsDisplayed: number, data: object, crudType: string };

export default Calendar;
