import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { number } from 'prop-types';

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
// import { NavButtons } from '../../../components/NavButtons/NavButtons';
// import { useHeadquarter } from '../../../hooks/useHeadquarter';
// import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
// import { dataScreenCompany } from '../../../data/dataPreferences';
// import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
// import { CardGird } from '../../../components/CardGrid/CardGird';
// import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import { useSwal } from '../../../hooks/useSwal';
// import { nameTables } from '../../../data/dataTable';
import { routesNumbering, routesWide } from '../../../navigation/routes';

import { ModeloScreenWrapper } from './ModeloScreen.style';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { useRef } from 'react';
import useNumbering from '../../../hooks/useNumbering';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { deleteModeloThunk } from '../../../redux/thunks/numberingThunks';

export const ModeloScreen = () => {
  const { arrayModelosHook, listNumberingForm, getModelos, loading, uploadFileListNumbering, getNumberingList } = useNumbering();
  const { typeSwal } = useSwal();
  const [isFilter, setIsFilter] = useState({ open: false, active: false });

  let navigate = useNavigate();

  // const { notFound } = dataScreenCompany;
  const [search, setSearch] = useState([]);
  // const [selectedId, setSelectedId] = useState('');
  const [checkboxesActives, setCheckboxesActives] = useState([]);

  const tableInstance = useRef(null);
  const [, setIsBottom] = useState(false);
  // const [enable, setEnable] = useState(0);

  // const [objectCompanyLink, setObjectCompanyLink] = useState({
  //   departments: [],
  //   title: '',
  //   id: number,
  //   ip: number,
  //   port: number,
  //   ipBackup: number,
  //   portBackup: number,
  // });

  useEffect(() => {
    getModelos();
    setSearch([]);

    listNumberingForm(
      { action: 'clearForm' },
      {
        form: 'modelo',
      }
    );
  }, [setSearch]);

  const DATA = arrayModelosHook?.map(({ id, modelo, Marca }) => ({
    id,
    modelo,
    marca: Marca.marca,
    marcaId: { value: Marca.id, label: Marca.marca },
  }));

  // const handleClick = (item) => {
  //   let newName = companyLink.filter((item2) => item2.Company?.id === Number(item.original.id));
  //   setObjectCompanyLink({
  //     departments: newName,
  //     title: item.original.text,
  //     id: item.original.id,
  //     ip: item.original.ip,
  //     port: item.original.port,
  //     ipBackup: item.original.ipBackup,
  //     portBackup: item.original.portBackup,
  //   });
  //   // setEnable(item.id);
  // };

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.numbering}/${routesNumbering.createmodelo}`);
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.numbering}/${routesNumbering.updatemodelo}`, {
      state: item.original,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteModeloThunk(item);
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getModelos();
          }
          if (response.status === 203) {
            //   let belongTables = [];
            //   response.data.map((item) =>
            //     Object.keys(nameTables).forEach((key) => {
            //       if (key.includes(item)) {
            //         belongTables.push(nameTables[key]);
            //       }
            //     })
            //   );
            typeSwal('error', ' el borrado de modelos');
            getModelos();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  // const dataToCards = objectCompanyLink.departments.map(({ Department }) => ({
  //   title: Department.name,
  // }));

  // menuButtonsDropDownTable.forEach((item) => {
  //   if (item.id === 1) {
  //     item.function = handleOpenFormEdit;
  //   } else if (item.id === 2) {
  //     item.function = handleDelete;
  //   }
  // });

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'modelo',
    },
    {
      Header: 'Marca',
      accessor: 'marca',
    },
  ];

  const template = [
    {
      Nombre: '',
    },
  ];

  const dataExport = arrayModelosHook?.map(({ name }) => ({
    Nombre: name,
  }));

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileListNumbering(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getNumberingList();
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <ModeloScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          handleOpenFormCreate={handleOpenFormCreate}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          onClickCreate={handleOpenFormCreate}
          // onClickAssign={handleOpenFormAssign}
          // totalDocs={users.length}
          deleteFunction={handleDelete}
          checkboxesActives={checkboxesActives}
          setCheckboxesActives={setCheckboxesActives}
          isNavTable={true}
          DATA={DATA}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <div className="contain__table">
          <TableInfo
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            format={'list'}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            isNavTable={true}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
      )}
    </ModeloScreenWrapper>
  );
};
