import styled from 'styled-components';
import { colors } from '../../theme/colors';
// import { newFontSize, weight } from '../../theme/fonts';

export const ModalPromptWrapper = styled.div`
  .modal {
    width: 80%;
    height: auto;
    max-width: max-content;
    min-width: 20rem;
    min-height: 10rem;
    text-align: center;
    position: fixed;
    color: black;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${colors.backgroundColor};
    border-radius: 15px;
    z-index: 101;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(167, 164, 164);
  }

  .modal-warning {
    width: 51%;
    height: auto;
    max-width: max-content;
    max-width: 25rem;
    min-height: 5rem;
    text-align: center;
    position: fixed;
    color: black;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cac9c9;
    border-radius: 15px;
    z-index: 103;
    display: flex;
    flex-direction: column;

    border: 1px solid rgb(167, 164, 164);
  }
  .container_form {
    max-height: 450px;
  }

  .basic {
    color: black;
    background: none;
  }

  .blur-container {
    position: fixed;
    width: 100%;
    height: 120rem;
    background: #000000cf;
    margin: auto;
    top: 0px;
    left: 0px;
    z-index: 102;
  }

  .blur-container-warning {
    position: fixed;
    width: 100%;
    height: 120rem;
    background: #000000cf;
    margin: auto;
    top: 0px;
    left: 0px;
    z-index: 102;
  }

  .container_form {
    max-height: 450px;
  }

  .modal-header {
    position: relative;
    background-color: ${colors.primaryColor};
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px 15px 2px 2px;
  }

  .modal-title {
    color: ${colors.backgroundColor};
    font-weight: 500;
    font-size: 15px;
    margin: 15px;
  }

  .modal-select {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: ${colors.primaryColor};
  }

  .modal-btn-accept {
    width: 40%;
    height: 30px;
    color: ${colors.primaryColor};
    background-color: ${colors.greenGYGAS};
    border-radius: 8px;
    font-weight: 700;
    border: none;
  }

  .modal-btn-cancel {
    width: 40%;
    height: 30px;
    color: white;
    background-color: #003566;
    border-radius: 8px;
    font-weight: 700;
    border: none;
  }

  .modal-btn-close {
    position: relative;
    width: 30px;
    height: 30px;
    right: 10px;
    color: white;
    background-color: ${colors.primaryColor};
    border-radius: 8px;
    font-weight: 700;
    border: 1px solid white;

    &:hover {
      background-color: ${colors.red};
    }
  }

  .modal-body {
    display: flex;
    padding: 25px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .overflowY-auto {
    overflow-y: auto;
  }

  .overflowY-none {
    overflow-y: unset;
  }

  .overflow-auto {
    overflow: auto;
  }

  .message-header {
    width: 60%;
    margin: auto;
    display: inline-flex;
    justify-content: space-around;
  }

  .message-content {
    margin: auto;
    margin-bottom: 50px;
    text-align: justify;
    font-size: 14px;
    width: 90%;
    color: black;
  }

  .modal-message {
    height: fit-content;
    font-size: 18px;
    color: black;
    text-align: center;
    padding: 12px;
    margin: auto;
    margin-top: 30px;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
`;
