import { routesSettings } from '../../navigation/routes';
import users from '../../assets/images/preferences/stars.svg';
import click from '../../assets/images/notFound/click.svg';
import trophy from '../../assets/images/setting/trophy.svg';
import mail from '../../assets/images/setting/mail.png';
import crown from '../../assets/images/setting/crown.svg';
import mushroom from '../../assets/images/setting/mushroom.svg';
import flame from '../../assets/images/setting/flame.svg';
import stethoscope from '../../assets/images/forms/stethoscope.svg';
// import speakerphone from '../../assets/images/forms/speakerphone.svg';

// import alarm from '../../assets/images/forms/alarm.svg';
// import phoneOutgoing from '../../assets/images/forms/phone-outgoing.svg';
// import phone from '../../assets/images/forms/phone.svg';
import { IconLicense, IconHome, IconWorldWww, IconApiApp, IconSchema, IconSettings, IconUserCheck, IconCalendar } from '@tabler/icons-react';

// Form Icons
import textInput from '../../assets/images/forms/textInput.svg';
import graph from '../../assets/images/forms/graph.svg';
import briefcase from '../../assets/images/forms/briefcase.svg';
import { typeRegex } from '../dataRegex';

//Icons screens
import hash from '../../assets/images/navMenu/hash.svg';
import layoutGrid from '../../assets/images/navMenu/layout-grid.svg';

import hatchecks from '../../assets/images/navMenu/hatchecks.svg';
import arrowUpRight from '../../assets/images/navMenu/corner-up-right.svg';
// import settings from '../../assets/images/navMenu/settings-2.svg';

export const menuSettings = [
  {
    active: true,
    header: 'Sedes',
    text: 'Sedes',
    name: 'Sedes',
    path: routesSettings.company,
    icon: IconHome,
    restricted: 'permissions',
    id: 1,
    content: 'Configuración de sedes',
  },
  {
    active: true,
    header: 'Departamentos',
    name: 'Departamentos',
    text: 'Departamentos',
    path: routesSettings.department,
    icon: IconSchema,
    restricted: 'numbering',
    id: 2,
    content: 'Configuración de departamentos',
  },
  // {
  //   id: 10,
  //   active: true,
  //   header: 'Terminales',
  //   name: 'Terminales',
  //   text: 'Terminales',
  //   path: routesSettings.department,
  //   icon: IconTerminal,
  //   restricted: 'terminales',
  //   content: 'Configuración de terminales',
  // buttons: [
  //   {
  //     id: 1,
  //     active: true,
  //     header: 'Marca',
  //     name: 'Marca',
  //     text: 'Marca',
  //     path: routesSettings.permissions,
  //     icon: IconUserCheck,
  //     // restricted: 'permissions',
  //     content: 'Configuración de Marcas',
  //   },
  //   {
  //     id: 2,
  //     active: true,
  //     header: 'Modelo',
  //     name: 'Modelo',
  //     text: 'Modelo',
  //     path: routesSettings.permissions,
  //     icon: IconUserCheck,
  //     // restricted: 'permissions',
  //     content: 'Configuración de Modelo',
  //   },
  // ],
  // },
  {
    id: 4,
    active: true,
    header: 'Roles',
    name: 'Roles',
    text: 'Roles',
    path: routesSettings.permissions,
    icon: IconUserCheck,
    restricted: 'permissions',
    content: 'Configuración de roles',
  },
  {
    active: true,
    header: 'APIs',
    name: 'APIs',
    text: 'APIs',
    path: routesSettings.api,
    icon: IconApiApp,
    restricted: 'api',
    id: 5,
    content: 'Información de la API',
  },
  {
    active: true,
    header: 'Dominios',
    name: 'Dominios',
    text: 'Dominios',
    path: routesSettings.domains,
    icon: IconWorldWww,
    restricted: 'domains',
    id: 6,
  },
  {
    active: true,
    header: 'Lista blanca',
    name: 'Lista blanca',
    text: 'Lista blanca',
    path: routesSettings.whitelist,
    icon: IconLicense,
    restricted: 'whitelist',
    id: 7,
  },

  {
    active: true,
    header: 'Ajustes horarios',
    text: 'Ajustes horarios',
    name: 'Ajustes horarios',
    path: routesSettings.calendar,
    icon: IconCalendar,
    restricted: 'users',
    id: 8,
  },
  {
    active: true,
    header: 'Preferencias',
    text: 'Preferencias',
    name: 'Preferencias',
    path: routesSettings.configurationAsterisk,
    icon: IconSettings,
    restricted: 'users',
    id: 9,
  },
];

export const menuSedes = [
  {
    text: 'Sedes',
    path: routesSettings.company,
    icon: IconLicense,
    restricted: 'permissions',
    id: 1,
    content: 'Configuración de roles',
  },
  {
    text: 'Departamentos',
    path: routesSettings.department,
    icon: IconLicense,
    restricted: 'permissions',
    id: 2,
    content: 'Configuración de roles',
  },
];

export const dataScreenUsers = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ningun usuario creado',
    subtitle: 'Empieza por configurar un nuevo usuario para visualizar en tu escritorio',
    textButton: 'Crear un nuevo usuario',
  },
  icon: {
    // users,
    trophy,
    mail,
  },
};

export const dataScreenNotFound = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'Dashboard',
    subtitle: 'Esta pantalla está en construcción.',
    textButton: 'Crear',
  },
  icon: {
    users,
    trophy,
    mail,
  },
};

export const dataSettingsNotFound = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'Settings',
    subtitle: '',
    textButton: 'Crear',
  },
  icon: {
    users,
    trophy,
    mail,
  },
};

export const menusTitleAndSubtitle = [
  {
    title: 'Dashboard',
    // id: 2,
    id: 1,
    icon: hash,
    subtitle: [{ text: 'Dashboard', id: 27 }],
  },
  {
    title: 'Usuarios',
    // id: 1,
    id: 2,
    icon: users,
    subtitle: [{ text: 'Usuarios', id: 29 }],
  },
  {
    title: 'Numeraciones',
    id: 3,
    icon: hash,
    subtitle: [
      { text: 'Usuarios', id: 1 },
      { text: 'Dispositivos', id: 2 },
    ],
  },
  {
    title: 'Aplicaciones',
    // id: 3,
    id: 4,
    icon: layoutGrid,
    subtitle: [
      { text: 'Buzones', id: 1 },
      { text: 'Colas', id: 2 },
      { text: 'Conferencias', id: 3 },
      { text: 'Ivr', id: 4 },
      { text: 'Locuciones', id: 5 },
      { text: 'Marcación abreviada', id: 6 },
      { text: 'Música en espera', id: 7 },
    ],
  },
  {
    title: 'Registro de llamadas',
    // id: 4,
    id: 15,
    icon: hatchecks,
    subtitle: [{ text: "CDR's", id: 20 }],
  },
  {
    title: 'Rutas de salida',
    // id: 8,
    id: 16,
    icon: arrowUpRight,
    subtitle: [
      { text: 'Grupo Catetorias', id: 21 },
      { text: 'Troncales', id: 22 },
    ],
  },
  // {
  //   title: 'Configuración',
  //   id: 17,
  //   icon: settings,
  //   subtitle: [
  //     { text: 'Calendario y horario', id: 23 },
  //     { text: 'Configuración de protocolo', id: 24 },
  //   ],
  // },
];

// export const formCreateUser = {
//   title: 'Crear usuario',
//   titleEdit: 'Editar datos de usuario',
//   textButton: 'Guardar',
//   steps: [
//     {
//       number: '1',
//       title: 'Step1',
//       id: 1,
//       nextStepText: 'Guardar',
//       columns: 4,
//       input: [
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Nombre',
//           placeholder: 'Nombre',
//           icon: textInput,
//           ref: { form: 'user', element: 'firstname' },
//           defaultValues: null,
//           required: {
//             text: '* Nombre',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Primer Apellido',
//           placeholder: 'Primer Apellido',
//           icon: textInput,
//           ref: { form: 'user', element: 'lastname' },
//           defaultValues: null,
//           required: {
//             text: '* Primer Apellido',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Segundo Apellido',
//           icon: textInput,
//           placeholder: 'Segundo Apellido',
//           ref: { form: 'user', element: 'secondname' },
//           defaultValues: null,
//           required: {
//             text: '* Segundo Apellido',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Correo Electrónico',
//           placeholder: 'example@example.com',
//           icon: briefcase,
//           ref: { form: 'user', element: 'email' },
//           defaultValues: null,
//           required: {
//             text: '* Email',
//             validation: true,
//             errorMessage: '* Debe ser un email correcto',
//             regex: typeRegex.inputMail,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           placeholder: 'Selecciona una sede',
//           label: 'Sede',
//           // ref: { form: 'user', element: 'companyId' },
//           ref: { form: 'user', element: 'companyId', type: 'dynamic' },
//           icon: stethoscope,
//           options: [],
//           defaultValues: [],
//           required: {
//             text: '* Sede',
//             validation: true,
//             errorMessage: 'Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           label: 'Departamento',
//           placeholder: 'Departamento',
//           icon: stethoscope,
//           ref: { form: 'user', element: 'departmentId', type: 'associatedData' },
//           options: [],
//           defaultValues: [],
//           required: {
//             text: '* Departamento',
//             validation: true,
//             errorMessage: '* Departamento obligatorio',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           icon: stethoscope,
//           label: 'Dpto. Captura',
//           placeholder: 'Dpto. Captura',
//           ref: { form: 'user', element: 'captureId', type: 'associatedData' },
//           options: [],
//           defaultValues: null,
//           required: {
//             text: '* Dpto. Captura',
//             validation: true,
//             errorMessage: '* Dpto. Captura obligatoria',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           label: 'Extensión',
//           placeholder: 'SIP Extension',
//           icon: textInput,
//           ref: { form: 'user', element: 'SIPExtension' },
//           defaultValues: null,
//           required: {
//             text: '* Extensión',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           placeholder: '············',
//           label: 'Contraseña',
//           ref: { form: 'user', element: 'password' },
//           defaultValues: null,
//           required: {
//             text: '* Contraseña',
//             validation: true,
//             errorMessage: '* 8 caracters mínimo 1 letra 1 número',
//             regex: typeRegex.inputPassword,
//           },
//         },

//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           placeholder: 'Selecciona un rol',
//           label: 'Rol',
//           ref: { form: 'user', element: 'role' },
//           icon: graph,
//           required: {
//             text: '* Rol',
//             validation: true,
//             errorMessage: '* Es obligatorio seleccionar un rol',
//             regex: typeRegex.inputNumber,
//           },
//           options: [
//             {
//               label: 'superadmin',
//               value: '10',
//             },
//             {
//               label: 'admin',
//               value: '11',
//             },
//             {
//               label: 'operator',
//               value: '12',
//             },
//             {
//               label: 'agent',
//               // label: 'user',
//               value: '13',
//             },
//             {
//               label: 'user',
//               // label: 'user',
//               value: '14',
//             },
//           ],
//           defaultValues: {},
//         },
//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           placeholder: 'Selecciona el nivel de acceso',
//           label: 'Nivel acceso',
//           ref: { form: 'user', element: 'accesslevelId' },
//           icon: graph,
//           required: {
//             text: '* Nivel acceso',
//             validation: true,
//             errorMessage: 'Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//           options: [],
//           defaultValues: {},
//         },
//         {
//           layoutId: 1,
//           cardType: 'select-option',
//           label: 'Categoría',
//           placeholder: 'Categoría',
//           icon: stethoscope,
//           ref: { form: 'user', element: 'categories' },
//           options: [],
//           defaultValues: {},
//           required: {
//             text: '* Categoría',
//             validation: true,
//             errorMessage: '* Categoría obligatoria',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'toggle',
//           placeholder: 'Avisos',
//           label: 'Buzón de voz',
//           ref: { form: 'user', element: 'isVoicemail' },
//           icon: graph,
//           required: {
//             text: 'Buzón de voz',
//             validation: false,
//             errorMessage: 'Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//           options: [],
//           defaultValues: {},
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'PIN Buzón',
//           placeholder: 'PIN Buzón',
//           icon: textInput,
//           ref: { form: 'user', element: 'voicemailPIN' },
//           defaultValues: null,
//           required: {
//             text: '* PIN Buzón',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'toggle',
//           placeholder: 'Aviso via email',
//           label: 'Aviso via email',
//           ref: { form: 'user', element: 'isvoicemailEmail' },
//           icon: graph,
//           required: {
//             text: 'E-mail',
//             validation: false,
//             errorMessage: '',
//             regex: /^/,
//           },
//           options: [],
//           defaultValues: false,
//         },
//         {
//           layoutId: 1,
//           cardType: 'toggle',
//           label: 'Desvío inmediato',
//           //  icon: phone,
//           placeholder: 'Desvío inmediato',
//           ref: { form: 'user', element: 'isCallForward' },
//           defaultValues: null,
//           required: {
//             text: 'Desvío inmediato',
//             validation: true,
//             errorMessage: '',
//             regex: /^/,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Número  de desvío inmediato',
//           //  icon: phone,
//           placeholder: 'Numero inmediato',
//           ref: { form: 'user', element: 'callForward' },
//           defaultValues: null,
//           required: {
//             text: '* Número',
//             validation: true,
//             errorMessage: '',
//             regex: /^/,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'toggle',
//           label: 'Desvío por no contestar',
//           //  icon: phone,
//           placeholder: 'Desvío por no contestar',
//           ref: { form: 'user', element: 'isNotAnswer' },
//           defaultValues: null,
//           required: {
//             text: 'Desvío por no contestar',
//             validation: true,
//             errorMessage: '',
//             regex: /^/,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Número de desvío por no contestar',
//           //  icon: phone,
//           placeholder: 'Número no contestar',
//           ref: { form: 'user', element: 'notAnswer' },
//           defaultValues: null,
//           required: {
//             text: '* Número ',
//             validation: true,
//             errorMessage: '',
//             regex: /^/,
//           },
//         },
//       ],
//     },
//   ],
// };

export const formCreateUser = {
  textButton: 'Crear Usuario',
  title: 'Crear nuevo usuario',
  titleEdit: 'Editar usuario',
  steps: [
    {
      number: '1',
      title: 'Datos generales',
      id: 1,
      nextStepText: 'Guardar',
      columns: 2,
      input: [
        {
          layoutId: 1,
          title: '',
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'user', element: 'firstname' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Primer Apellido',
          placeholder: 'Primer Apellido',
          icon: textInput,
          ref: { form: 'user', element: 'lastname' },
          defaultValues: null,
          required: {
            text: '* Primer Apellido',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Segundo Apellido',
          icon: textInput,
          placeholder: 'Segundo Apellido',
          ref: { form: 'user', element: 'secondname' },
          defaultValues: null,
          required: {
            text: '* Segundo Apellido',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Correo Electrónico',
          placeholder: 'example@example.com',
          icon: briefcase,
          ref: { form: 'user', element: 'email' },
          defaultValues: null,
          required: {
            text: '* Email',
            validation: true,
            errorMessage: '* Debe ser un email correcto',
            regex: typeRegex.inputMail,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          placeholder: 'Selecciona una sede',
          label: 'Sede',
          // ref: { form: 'user', element: 'companyId' },
          ref: { form: 'user', element: 'companyId', type: 'dynamic' },
          icon: stethoscope,
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: 'Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.multiselect,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Departamento',
          placeholder: 'Departamento',
          icon: stethoscope,
          ref: { form: 'user', element: 'departmentId', type: 'associatedData' },
          options: [],
          defaultValues: null,
          required: {
            text: '* Departamento',
            validation: false,
            errorMessage: '* Departamento obligatorio',
            regex: typeRegex.multiselect,
          },
        },
        {
          layoutId: 1,
          cardType: 'multiselect',
          label: 'Dpto. Captura',
          placeholder: 'Dpto. Captura',
          icon: stethoscope,
          ref: { form: 'user', element: 'captureId', type: 'associatedData' },
          // , type: 'associatedData' },
          defaultValues: null,
          options: [],
          required: {
            text: '* Dpto. Captura',
            validation: true,
            errorMessage: '* Dpto. Captura obligatorio',
            regex: typeRegex.multiselect,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Extensión',
          placeholder: 'SIP Extension',
          icon: textInput,
          ref: { form: 'user', element: 'SIPExtension' },
          defaultValues: null,
          required: {
            text: '* Extensión',
            validation: false,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Categoría',
          placeholder: 'Categoría',
          icon: stethoscope,
          ref: { form: 'user', element: 'category' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Categoría',
            validation: false,
            errorMessage: '* Categoría obligatoria',
            regex: typeRegex.multiselect,
          },
        },
        {
          layoutId: 1,
          title: 'Seguridad',
          cardType: 'input',
          placeholder: '············',
          label: 'Contraseña',
          ref: { form: 'user', element: 'password' },
          defaultValues: null,
          required: {
            text: '* Contraseña',
            validation: false,
            errorMessage: '* 8 caracters mínimo 1 letra 1 número',
            regex: typeRegex.inputPassword,
          },
        },
        {
          layoutId: 1,
          cardType: 'generateKey',
          ref: { form: 'user', element: 'password' },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          placeholder: 'Selecciona un rol',
          label: 'Rol',
          ref: { form: 'user', element: 'roleId' },
          icon: graph,
          required: {
            text: '* Rol',
            validation: false,
            errorMessage: '* Es obligatorio seleccionar un rol',
            regex: typeRegex.inputNumber,
          },
          options: [
            {
              label: 'superadmin',
              value: '10',
            },
            {
              label: 'admin',
              value: '11',
            },
            {
              label: 'operator',
              value: '12',
            },
            {
              label: 'agent',
              // label: 'user',
              value: '13',
            },
            {
              label: 'user',
              // label: 'user',
              value: '14',
            },
          ],
          defaultValues: {},
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          placeholder: 'Nivel de acceso',
          label: 'Nivel acceso',
          ref: { form: 'user', element: 'accesslevelId' },
          icon: graph,
          required: {
            text: '* Nivel acceso',
            validation: false,
            errorMessage: 'Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
          options: [],
          defaultValues: {},
        },

        {
          layoutId: 1,
          cardType: 'toggle',
          title: 'Avisos',
          placeholder: 'Avisos',
          label: 'Buzón de voz',
          ref: { form: 'user', element: 'isVoicemail' },
          icon: graph,
          required: {
            text: 'Buzón de voz',
            validation: false,
            errorMessage: 'Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
          options: [],
          defaultValues: {},
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'PIN Buzón',
          placeholder: 'PIN Buzón',
          icon: textInput,
          ref: { form: 'user', element: 'voicemailPIN' },
          defaultValues: null,
          required: {
            text: '* PIN Buzón',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          placeholder: 'Aviso via email',
          label: 'Aviso via email',
          ref: { form: 'user', element: 'isvoicemailEmail' },
          icon: graph,
          required: {
            text: 'E-mail',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
          options: [],
          defaultValues: false,
        },
        {
          layoutId: 1,
          title: 'Desvios',
          cardType: 'toggle',
          label: 'Desvío inmediato',
          //  icon: phone,
          placeholder: 'Desvío inmediato',
          ref: { form: 'user', element: 'isCallForward' },
          defaultValues: null,
          required: {
            text: 'Desvío inmediato',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número  de desvío inmediato',
          //  icon: phone,
          placeholder: 'Numero inmediato',
          ref: { form: 'user', element: 'callForward' },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          label: 'Desvío no contestar',
          //  icon: phone,
          placeholder: 'Desvío no contestar',
          ref: { form: 'user', element: 'isNotAnswer' },
          defaultValues: null,
          required: {
            text: 'Desvío no contestar',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número de desvío por no contestar',
          //  icon: phone,
          placeholder: 'Número no contestar',
          ref: { form: 'user', element: 'notAnswer' },
          defaultValues: null,
          required: {
            text: '* Número ',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          title: ' ',
          placeholder: 'Activar Rainbow',
          label: 'Activar Rainbow',
          ref: { form: 'user', element: 'isRainbow' },
          icon: graph,
          required: {
            text: 'Rainbow',
            validation: false,
            errorMessage: 'Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
          options: [],
          defaultValues: false,
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          placeholder: 'Activar Teams',
          label: 'Activar Teams',
          ref: { form: 'user', element: 'isTeams' },
          icon: graph,
          required: {
            text: 'Teams',
            validation: false,
            errorMessage: 'Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
          options: [],
          defaultValues: false,
        },
      ],
    },
  ],
};

export const dataScreenSettings = {
  permissions: {
    profile: 'perfil',
    profiles: 'perfiles',
    member: 'Miembro de Tpartner',
    allFunctionalitiesText: 'Perfil con todas las funcionalidades',
    functionalitiesText: 'Perfil con permisos en las siguientes pantallas',
    allFunctionalitiesRole: 'superadmin',
  },
  icon: {
    trophy,
    crown,
    mushroom,
    flame,
  },
  edit: {
    title: 'Rol',
  },
};

export const formCreateApiKey = {
  title: 'Creación de una nueva API Key',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: 'Esta es tu nueva Api Key',
      id: 1,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'apiKey', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'generateKey',
          ref: { form: 'apiKey', element: 'apiKey' },
          required: {
            text: '* ApiKey',
            validation: false,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataScreenPermissions = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay nigún permiso creado',
    subtitle: 'Empieza por añadir un nuevo permiso',
    textButton: 'Crear permiso',
  },
  icon: {
    // users,
    // home,
  },
};

export const dataScreenApi = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay niguna ApiKey creada',
    subtitle: 'Empieza por configurar una nueva ApiKey para visualizar en tu escritorio',
    textButton: 'Crear API Key',
  },
  icon: {
    // users,
    // home,
  },
};

export const formCreateDomains = {
  title: 'Configuración de un nuevo dominio',
  titleEdit: 'Edita el dominio',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Doninio',
          placeholder: 'Doninio',
          icon: textInput,
          ref: { form: 'domains', element: 'domain' },
          defaultValues: null,
          required: {
            text: '* Dominio',
            validation: true,
            errorMessage: '* Debe ser un dominio correcto',
            regex: typeRegex.domainRegex,
          },
        },
      ],
    },
  ],
};

export const dataScreenDomains = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ningun dominio creado',
    subtitle: 'Empieza por configurar un nuevo dominio para visualizar en tu escritorio',
    textButton: 'Crear un nuevo dominio',
  },
  icon: {
    // users,
    // trophy,
    // mail,
  },
};
export const dataFilterDomains = {
  filters: [
    {
      label: 'Dominio',
      type: 'select',
      ref: 'domains',
      options: [],
    },
  ],
};

export const dataFilterApis = {
  filters: [
    {
      label: 'Api',
      type: 'select',
      ref: 'apis',
      options: [],
    },
  ],
};

export const formCreateWhitelist = {
  title: 'Configuración de una nueva whitelist',
  titleEdit: 'Edita la whitelist',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'IP',
          placeholder: 'Dirección IP',
          icon: textInput,
          ref: { form: 'whitelist', element: 'ip_addr' },
          defaultValues: null,
          required: {
            text: '* IP',
            validation: true,
            errorMessage: '* Debe ser una ip correcta',
            regex: typeRegex.inputIP,
          },
        },
      ],
    },
  ],
};

export const dataScreenWhiteList = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna lista blanca creada',
    subtitle: 'Empieza por configurar un nueva lista blanca para visualizar en tu escritorio',
    textButton: 'Crear una nueva lista blanca',
  },
  icon: {
    // users,
    // trophy,
    // mail,
  },
};

export const dataFilterWhiteList = {
  filters: [
    {
      label: 'ip_addr',
      type: 'select',
      ref: 'ip_addr',
      options: [],
    },
  ],
};

export const dataConfiguracionAsterisk = {
  title: 'Configuraciones globales',
  titleEdit: 'Edita la configuación',
  tabs: [
    {
      // textButton: 'Crear configuracion',
      id: 1,
      text: 'SBC SIP/SJSIP',
      steps: [
        {
          number: '1',
          title: 'SBC',
          id: 1,
          nextStepText: 'Siguiente Paso',
          lastStep: true,
          input: [
            {
              cardType: 'input',
              label: 'IP Local',
              placeholder: 'IP Local',
              icon: textInput,
              ref: { form: 'config', element: 'ipLocal' },
              defaultValues: null,
              required: {
                text: '* IP Local',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Dominio',
              placeholder: 'Dominio',
              icon: textInput,
              ref: { form: 'config', element: 'domain' },
              defaultValues: null,
              required: {
                text: '* Dominio',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Puerto WSS',
              placeholder: 'Puerto WSS',
              icon: textInput,
              ref: { form: 'config', element: 'portWss' },
              defaultValues: null,
              required: {
                text: '* Puerto WSS',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Path WSS',
              placeholder: 'Path WSS',
              icon: textInput,
              ref: { form: 'config', element: 'pathWss' },
              defaultValues: null,
              required: {
                text: '* Path WSS',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Contraseña',
              placeholder: 'Contraseña',
              icon: textInput,
              ref: { form: 'config', element: 'password' },
              defaultValues: null,
              required: {
                text: '* Contraseña',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
          ],
        },
      ],
    },
    {
      id: 2,
      text: 'Sístema',
      steps: [
        {
          number: '1',
          title: 'Protocolo de interfaz: SIP/',
          id: 1,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'input',
              label: 'Azure subscripción key',
              placeholder: 'Azure subscripción key',
              icon: textInput,
              ref: { form: 'config', element: 'azure_subscripcion_key' },
              defaultValues: null,
              required: {
                text: '* Azure subscripción key',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Ruta indicación de sonido',
              placeholder: 'Ruta indicación de sonido',
              icon: textInput,
              ref: { form: 'config', element: 'ruta_indicacion_de_sonido' },
              defaultValues: null,
              required: {
                text: '* Ruta indicación de sonido',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Número máximo de extensiones',
              placeholder: 'Número máximo de extensiones',
              icon: textInput,
              ref: { form: 'config', element: 'numero_maximo_de_extensiones' },
              defaultValues: null,
              required: {
                text: '* Número máximo de extensiones',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Digito de marcación',
              placeholder: 'Digito de marcación',
              icon: textInput,
              ref: { form: 'config', element: 'digito_de_marcacion' },
              defaultValues: null,
              required: {
                text: '* Digito de marcación',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Administrador secreto',
              placeholder: 'Administrador secreto',
              icon: textInput,
              ref: { form: 'config', element: 'administrador_secreto' },
              defaultValues: null,
              required: {
                text: '* Administrador secreto',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Usuario administrador',
              placeholder: 'Usuario administrador',
              icon: textInput,
              ref: { form: 'config', element: 'usuario_administrador' },
              defaultValues: null,
              required: {
                text: '* Usuario administrador',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Puerto administrador',
              placeholder: 'Puerto administrador',
              icon: textInput,
              ref: { form: 'config', element: 'puerto_administrador' },
              defaultValues: null,
              required: {
                text: '* Puerto administrador',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'IP administrador',
              placeholder: 'IP administrador',
              icon: textInput,
              ref: { form: 'config', element: 'ip_administrador' },
              defaultValues: null,
              required: {
                text: '* IP administrador',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Extensión',
              placeholder: 'Extensión inicio',
              icon: textInput,
              ref: { form: 'config', element: 'extension_start' },
              defaultValues: null,
              required: {
                text: '* Inicio rango de extensiones',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Extensión',
              placeholder: 'Extensión fin',
              icon: textInput,
              ref: { form: 'config', element: 'extension_end' },
              defaultValues: null,
              required: {
                text: '* Fin',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Inicio',
              icon: textInput,
              ref: { form: 'config', element: 'group_start' },
              defaultValues: null,
              required: {
                text: '* Inicio rango de grupos',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Fin',
              icon: textInput,
              ref: { form: 'config', element: 'group_end' },
              defaultValues: null,
              required: {
                text: 'Fin',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Inicio',
              icon: textInput,
              ref: { form: 'config', element: 'mailBoxes_start' },
              defaultValues: null,
              required: {
                text: '* Inicio rango buzones',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Fin',
              icon: textInput,
              ref: { form: 'config', element: 'mailBoxes_end' },
              defaultValues: null,
              required: {
                text: 'Fin',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Inicio',
              icon: textInput,
              ref: { form: 'config', element: 'conference_start' },
              defaultValues: null,
              required: {
                text: '* Inicio rango de conferencias',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Fin',
              icon: textInput,
              ref: { form: 'config', element: 'conference_end' },
              defaultValues: null,
              required: {
                text: 'Fin',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Inicio',
              icon: textInput,
              ref: { form: 'config', element: 'shortMarking_start' },
              defaultValues: null,
              required: {
                text: '* Inicio rango marc. abreviada',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              placeholder: 'Fin',
              icon: textInput,
              ref: { form: 'config', element: 'shortMarking_end' },
              defaultValues: null,
              required: {
                text: 'Fin',
                validation: false,
                errorMessage: '* Número',
                regex: /^/,
              },
            },
            // {
            //   cardType: 'titleInputPair',
            //   label: 'Extensión',
            //   inputs: [
            //     {
            //       placeholder: 'Inicio',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'extension_start' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Inicio',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //     {
            //       placeholder: 'Fin',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'extension_end' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Fin',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cardType: 'titleInputPair',
            //   label: 'Grupo',
            //   inputs: [
            //     {
            //       placeholder: 'Inicio',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'group_start' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Inicio',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //     {
            //       placeholder: 'Fin',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'group_end' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Fin',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cardType: 'titleInputPair',
            //   label: 'Búzon',
            //   inputs: [
            //     {
            //       placeholder: 'Inicio',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'mailBoxes_start' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Inicio',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //     {
            //       placeholder: 'Fin',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'mailBoxes_end' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Fin',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //   ],
            // },

            // {
            //   cardType: 'input',
            //   label: 'Inicio',
            //   placeholder: 'Inicio',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'conference_start' },
            //   defaultValues: null,
            //   required: {
            //     text: '* Conferencia Inicio',
            //     validation: false,
            //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
            //     regex: /^/,
            //   },
            // },
            // {
            //   cardType: 'input',
            //   label: 'Fin',
            //   placeholder: 'Fin',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'conference_end' },
            //   defaultValues: null,
            //   required: {
            //     text: '* Fin',
            //     validation: false,
            //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
            //     regex: /^/,
            //   },
            // },
            // {
            //   cardType: 'titleInputPair',
            //   label: 'Conferencia',
            //   inputs: [
            //     {
            //       placeholder: 'Inicio',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'conference_start' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Inicio',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //     {
            //       placeholder: 'Fin',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'conference_end' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Fin',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cardType: 'titleInputPair',
            //   label: 'Marcación abreviada',
            //   inputs: [
            //     {
            //       placeholder: 'Inicio',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'shortMarking_start' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Inicio',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //     {
            //       placeholder: 'Fin',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'shortMarking_end' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Fin',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cardType: 'titleInputPair',
            //   label: 'Cola',
            //   inputs: [
            //     {
            //       placeholder: 'Inicio',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'cola_start' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Inicio',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //     {
            //       placeholder: 'Fin',
            //       icon: textInput,
            //       ref: { form: 'config', element: 'cola_end' },
            //       defaultValues: null,
            //       required: {
            //         text: 'Fin',
            //         validation: false,
            //         errorMessage: '* Número',
            //         regex: /^/,
            //       },
            //     },
            //   ],
            // },
            //   ],
            // },
            // {
            //   number: '2',
            //   title: 'Variables del sistema/ Dial',
            //   id: 2,
            //   nextStepText: 'Siguiente Paso',
            //   input: [
            // {
            //   cardType: 'input',
            //   label: 'OPT',
            //   placeholder: 'OPT',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'opt' },
            //   defaultValues: null,
            //   required: {
            //     text: 'OPT',
            //     validation: true,
            //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
            //     //regex: typeRegex.inputText,
            //     regex: /^/,
            //   },
            // },
            // {
            //   cardType: 'input',
            //   label: 'Timeout',
            //   placeholder: 'Timeout',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'timeout' },
            //   defaultValues: [2],
            //   required: {
            //     text: 'Timeout',
            //     validation: true,
            //     errorMessage: '* Solo números',
            //     //regex: typeRegex.inputOnlyNumbers,
            //     regex: /^/,
            //   },
            // },
            // {
            //   cardType: 'input',
            //   label: 'Timeout_CF',
            //   placeholder: 'Timeout_CF',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'timeout_cf' },
            //   defaultValues: null,
            //   required: {
            //     text: 'Timeout_CF',
            //     validation: true,
            //     errorMessage: '* Solo números',
            //     //regex: typeRegex.inputOnlyNumbers,
            //     regex: /^/,
            //   },
            // },
            // {
            //   cardType: 'input',
            //   label: 'Out_Timeout',
            //   placeholder: 'Out_Timeout',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'out_timeout' },
            //   defaultValues: null,
            //   required: {
            //     text: 'Out_Timeout',
            //     validation: true,
            //     errorMessage: '* Solo números',
            //     //regex: typeRegex.inputOnlyNumbers,
            //     regex: /^/,
            //   },
            // },
            // {
            //   cardType: 'input',
            //   label: 'Out_options',
            //   placeholder: 'Out_options',
            //   icon: textInput,
            //   ref: { form: 'config', element: 'out_options' },
            //   defaultValues: null,
            //   required: {
            //     text: 'Out_options',
            //     validation: true,
            //     errorMessage: '* Solo números',
            //     //regex: typeRegex.inputOnlyNumbers,
            //     regex: /^/,
            //   },
            // },
          ],
        },
        // {
        //   number: '3',
        //   title: 'Variables del sistema/ Principales',
        //   id: 3,
        //   nextStepText: 'Siguiente Paso',
        //   input: [],
        // },
      ],
    },
    {
      id: 3,
      text: 'Teams / Rainbow',
      steps: [
        {
          number: '1',
          title: 'Teams / Rainbow',
          id: 1,
          nextStepText: 'Siguiente Paso',
          lastStep: true,
          input: [
            {
              cardType: 'toggle',
              label: 'Teams',
              placeholder: 'Teams',
              icon: textInput,
              ref: { form: 'config', element: 'isTeams' },
              defaultValues: null,
              required: {
                text: '* Teams',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              layoutId: 2,
              cardType: 'select-option',
              placeholder: 'Selecciona troncal',
              label: 'Troncal',
              ref: { form: 'config', element: 'teamsTroncalId' },
              icon: graph,
              required: {
                text: '* Troncal',
                validation: true,
                errorMessage: 'Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
              options: [],
              defaultValues: {},
            },
            {
              cardType: 'input',
              label: 'Prefix',
              placeholder: 'Teams Prefix',
              icon: textInput,
              ref: { form: 'config', element: 'Teams_URL' },
              defaultValues: null,
              required: {
                text: '* Prefix',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'toggle',
              label: 'Rainbow',
              placeholder: 'Rainbow',
              icon: textInput,
              ref: { form: 'config', element: 'isRainbow' },
              defaultValues: null,
              required: {
                text: '* Rainbow',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              layoutId: 4,
              cardType: 'select-option',
              placeholder: 'Selecciona troncal',
              label: 'Troncal',
              ref: { form: 'config', element: 'rainbowTroncalId' },
              icon: graph,
              required: {
                text: '* Troncal',
                validation: true,
                errorMessage: 'Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
              options: [],
              defaultValues: {},
            },
            {
              cardType: 'input',
              label: 'Prefix',
              placeholder: 'Rainbow Prefix',
              icon: textInput,
              ref: { form: 'config', element: 'Rainbow_URL' },
              defaultValues: null,
              required: {
                text: '* Prefix',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
          ],
        },
      ],
    },
    {
      id: 4,
      text: 'Variables',
      steps: [
        {
          number: '1',
          title: 'Dial',
          id: 1,
          nextStepText: 'Siguiente Paso',
          lastStep: true,
          input: [
            {
              cardType: 'input',
              label: 'OPT',
              placeholder: 'OPT',
              icon: textInput,
              ref: { form: 'config', element: 'opt' },
              defaultValues: ['TtHhbl(pre-dial-handler^s^1)'],
              required: {
                text: '* OPT',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'TimeOut',
              placeholder: 'TimeOut',
              icon: textInput,
              ref: { form: 'config', element: 'timeout' },
              defaultValues: ['60'],
              required: {
                text: '* TimeOut',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'TimeOut CF',
              placeholder: 'TimeOut CF',
              icon: textInput,
              ref: { form: 'config', element: 'timeout_cf' },
              defaultValues: ['30'],
              required: {
                text: '* TimeOut CF',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: /^/,
              },
            },

            {
              cardType: 'input',
              label: 'Out_Timeout',
              placeholder: 'Out_Timeout',
              icon: textInput,
              ref: { form: 'config', element: 'out_timeout' },
              defaultValues: null,
              required: {
                text: 'Out_Timeout',
                validation: true,
                errorMessage: '* Solo números',
                //regex: typeRegex.inputOnlyNumbers,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Out_options',
              placeholder: 'Out_options',
              icon: textInput,
              ref: { form: 'config', element: 'out_options' },
              defaultValues: null,
              required: {
                text: 'Out_options',
                validation: true,
                errorMessage: '* Solo números',
                //regex: typeRegex.inputOnlyNumbers,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Capturar llamada',
              placeholder: 'Capturar llamada',
              icon: textInput,
              ref: { form: 'config', element: 'capturar_llamada' },
              defaultValues: null,
              required: {
                text: 'Capturar llamada',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Transferencia ciega',
              placeholder: 'Transferencia ciega',
              icon: textInput,
              ref: { form: 'config', element: 'transferencia_ciega' },
              defaultValues: null,
              required: {
                text: 'Transferencia ciega',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Colgar llamada',
              placeholder: 'Colgar llamada',
              icon: textInput,
              ref: { form: 'config', element: 'colgar_llamada' },
              defaultValues: null,
              required: {
                text: 'Colgar llamada',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'Transferencia atendida',
              placeholder: 'Transferencia atendida',
              icon: textInput,
              ref: { form: 'config', element: 'tranferencia_atendida' },
              defaultValues: null,
              required: {
                text: 'Transferencia atendida',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'radio',
              label: 'Selección múltiple según preferencias',
              placeholder: 'Selección múltiple según preferencias',
              ref: { form: 'config', element: 'preferences' },
              defaultValues: '',
              radioType: 'multi',
              options: [
                { label: 'QSHOWNAME', value: 'QSHOWNAME', id: 1 },
                { label: 'VM_AUTO_CREATION', value: 'VM_AUTO_CREATION', id: 2 },
                { label: 'WEBEXTERNALDB', value: 'WEBEXTERNALDB', id: 3 },
                { label: 'ERESPON', value: 'ERESPON', id: 4 },
                { label: 'MODO_ENCUESTAS', value: 'MODO_ENCUESTAS', id: 5 },
                { label: 'DebugMode', value: 'DebugMode', id: 6 },
                // {
                //   label: 'CALLBACK_MODE',
                //   value: 'CALLBACK_MODE',
                //   id: 7,
                //   selectData: {
                //     label: 'CALLBACK_AUDIO_ID',
                //     placeholder: 'Selecciona locución',
                //     ref: { form: 'config', element: 'callback_audio_id' },
                //     options: [],
                //     defaultValues: {},
                //   },
                // },
                {
                  label: 'sub_notify_API',
                  value: 'sub_notify_API',
                  id: 8,
                  inputData: {
                    label: 'external_API_URL',
                    placeholder: 'external_API_URL',
                    ref: { form: 'config', element: 'external_api_url' },
                    options: [],
                    defaultValues: null,
                    required: {
                      text: 'external_API_URL',
                      validation: false,
                      errorMessage: 'Selecciona una opción',
                      regex: typeRegex.inputText,
                    },
                  },
                },
              ],
              required: {
                text: 'Selección múltiple según preferencias',
                validation: false,
                errorMessage: 'Selecciona una opción',
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'select-option',
              label: 'Audio',
              placeholder: 'Audio',
              icon: textInput,
              ref: { form: 'config', element: 'callback_audio_id' },
              defaultValues: null,
              required: {
                text: 'Audio',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'CONFRECPATH',
              placeholder: 'CONFRECPATH',
              icon: textInput,
              ref: { form: 'config', element: 'confrecpath' },
              defaultValues: null,
              required: {
                text: 'CONFRECPATH',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'VMGREETPATH',
              placeholder: 'VMGREETPATH',
              icon: textInput,
              ref: { form: 'config', element: 'vmgreetpath' },
              defaultValues: null,
              required: {
                text: 'VMGREETPATH',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'CALLRECPATH',
              placeholder: 'CALLRECPATH',
              icon: textInput,
              ref: { form: 'config', element: 'callrecpath' },
              defaultValues: null,
              required: {
                text: 'CALLRECPATH',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'MYDYNAMIC_FEATURES',
              placeholder: 'MYDYNAMIC_FEATURES',
              icon: textInput,
              ref: { form: 'config', element: 'mydynamic_features' },
              defaultValues: null,
              required: {
                text: 'MYDYNAMIC_FEATURES',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'DF_RINGTIMEOUT',
              placeholder: 'DF_RINGTIMEOUT',
              icon: textInput,
              ref: { form: 'config', element: 'df_ringtimeout' },
              defaultValues: null,
              required: {
                text: 'DF_RINGTIMEOUT',
                validation: true,
                errorMessage: '* Solu números',
                //regex: typeRegex.inputOnlyNumbers,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'FORMATO_DEFAULT_VM',
              placeholder: 'FORMATO_DEFAULT_VM',
              icon: textInput,
              ref: { form: 'config', element: 'formato_default_vm' },
              defaultValues: null,
              required: {
                text: 'FORMATO_DEFAULT_VM',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'BXFER_COUNT',
              placeholder: 'BXFER_COUNT',
              icon: textInput,
              ref: { form: 'config', element: 'bxfer_count' },
              defaultValues: null,
              required: {
                text: 'BXFER_COUNT',
                validation: true,
                errorMessage: '* Solu números',
                //regex: typeRegex.inputOnlyNumbers,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'SELTIMEOUT',
              placeholder: 'SELTIMEOUT',
              icon: textInput,
              ref: { form: 'config', element: 'seltimeout' },
              defaultValues: null,
              required: {
                text: 'SELTIMEOUT',
                validation: true,
                errorMessage: '* Solu números',
                //regex: typeRegex.inputOnlyNumbers,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'PREFIX_MOV_CORP',
              placeholder: 'PREFIX_MOV_CORP',
              icon: textInput,
              ref: { form: 'config', element: 'prefix_mov_corp' },
              defaultValues: null,
              required: {
                text: 'PREFIX_MOV_CORP',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'PRI_MOV',
              placeholder: 'PRI_MOV',
              icon: textInput,
              ref: { form: 'config', element: 'pri_mov' },
              defaultValues: null,
              required: {
                text: 'PRI_MOV',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'NUMCORTA_ORIGIN',
              placeholder: 'NUMCORTA_ORIGIN',
              icon: textInput,
              ref: { form: 'config', element: 'numcorta_origin' },
              defaultValues: null,
              required: {
                text: 'NUMCORTA_ORIGIN',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'CODELEN',
              placeholder: 'CODELEN',
              icon: textInput,
              ref: { form: 'config', element: 'codelen' },
              defaultValues: null,
              required: {
                text: 'CODELEN',
                validation: true,
                errorMessage: '* Solu números',
                //regex: typeRegex.inputOnlyNumbers,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'OPC_COLA',
              placeholder: 'OPC_COLA',
              icon: textInput,
              ref: { form: 'config', element: 'opc_cola' },
              defaultValues: null,
              required: {
                text: 'OPC_COLA',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            {
              cardType: 'input',
              label: 'MAX_INBOUND_CHAN',
              placeholder: 'MAX_INBOUND_CHAN',
              icon: textInput,
              ref: { form: 'config', element: 'max_inbound_chan' },
              defaultValues: null,
              required: {
                text: 'MAX_INBOUND_CHAN',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                //regex: typeRegex.inputText,
                regex: /^/,
              },
            },
            // {
            //   cardType: 'toggleButton',
            //   label: 'Multimáquina/Monomáquina',
            //   textLeft: 'Multimáquina',
            //   textRight: 'Monomáquina',
            //   ref: { form: 'config', element: 'maquina' },
            //   defaultValues: null,
            //   required: {
            //     text: 'MAX_INBOUND_CHAN',
            //     validation: true,
            //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
            //     //regex: typeRegex.inputText,
            //     regex: /^/,
            //   },
            // },
          ],
        },
      ],
    },
  ],
};
