import React, { useState } from 'react';
import { IconX } from '@tabler/icons-react';

import { ModalWrapper } from './Modal.style';
import { Search } from '../Search/Search';
import { Button } from '../Button/Button';
import { colors } from '../../theme/colors';
import { TableInfo } from '../TableInfo/TableInfo';
import { array, bool, func, string } from 'prop-types';

export const Modal = ({ show, handleClose, children, dataModal, columnsModal, setDataCard, isWarning, isModalOpen }) => {
  const [search, setSearch] = useState([]);
  const classModal = isWarning ? 'modal-warning' : 'modal';

  const message = !isWarning ? 'Selecciona los agentes a los que les quieras asignar una estrategia' : 'Al salir se perderan los cambios realizados.';

  const handleOnClickInModal = (event) => {
    event.stopPropagation();
  };

  const handleOk = (event) => {
    event.stopPropagation();
    isWarning(false);
    show(false);
  };

  const handleCloseOk = (event) => {
    event.stopPropagation();
    isModalOpen(false);
    isWarning(false);
    show(false);
  };

  return (
    show && (
      <ModalWrapper>
        <div className={classModal} onClick={handleOnClickInModal}>
          <div className="modal__title">
            <p className="modal__title__item"> Aviso</p>
            <div onClick={handleClose} className="modal__title__icon">
              <IconX height={'20px'} width={'20px'} className="modal__title__icon__item" />
            </div>
          </div>

          {!isWarning ? (
            <div className="modal__search">
              <Search setSearch={setSearch} data={dataModal} columns={columnsModal} />
            </div>
          ) : (
            { message }
          )}
          {search[0]?.notFound ? (
            <div className="modal__notFound">
              <h4>{search[0].notFound}</h4>
            </div>
          ) : (
            children === 'table' && (
              <div className="modal__children">
                <TableInfo format={'list'} COLUMNS={columnsModal} DATA={search.length === 0 ? dataModal : search} isOptions={false} isNavTable={false} setDataCard={setDataCard} isCheckbox={true} />
              </div>
            )
          )}
          <div className={classModal + '__button'}>
            <Button
              backgroundColor={colors.primaryColor}
              border={'0px'}
              colorText={colors.white}
              width={'45%'}
              // Icon={IconPlus}
              onClick={!isWarning ? handleClose : handleOk}
              hoverBackgroundColor={colors.secondaryColor}
              hoverIcon={colors.primaryColor}
              text={!isWarning ? 'Añadir selección' : 'Aceptar'}
              hoverColorText={colors.primaryColor}
            />
            {isWarning ? (
              <Button
                backgroundColor={colors.primaryColor}
                border={'0px'}
                colorText={colors.white}
                width={'45%'}
                // Icon={IconPlus}
                onClick={handleCloseOk}
                hoverBackgroundColor={colors.secondaryColor}
                hoverIcon={colors.primaryColor}
                text={'Cancelar'}
                hoverColorText={colors.primaryColor}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </ModalWrapper>
    )
  );
};

Modal.propTypes = { show: bool, handleClose: func, children: string, dataModal: array, columnsModal: array, setDataCard: func, isWarning: func, isModalOpen: func };
