import { actionTypesUser } from '../../../actions/settingsScreen/user/actionTypes';

export const usersReducer = (users = [], action) => {
  let newArrayUsers;
  switch (action.type) {
    case actionTypesUser.get:
      newArrayUsers = [...action.users];
      break;
    default:
      newArrayUsers = users;
  }
  return newArrayUsers;
};
export const usersAccessLevelReducer = (arrayAccessLevel = [], action) => {
  let newArrayAccessLevel;
  switch (action.type) {
    // action for looking permissions user connection
    case actionTypesUser.getAccessLevel:
      newArrayAccessLevel = [...action.accesslevels];
      break;
    default:
      newArrayAccessLevel = arrayAccessLevel;
  }

  return newArrayAccessLevel;
};

export const usersPermissionReducer = (arrayPermissionUser = [], action) => {
  let newArrayPermissionsUser;
  switch (action.type) {
    // action for looking permissions user connection
    case actionTypesUser.getUsersPermission:
      newArrayPermissionsUser = [...action.permissions];
      break;
    default:
      newArrayPermissionsUser = arrayPermissionUser;
  }

  return newArrayPermissionsUser;
};

export const usersFormReducer = (
  state = {
    id: null,
    firstname: '',
    lastname: '',
    secondname: '',
    email: '',
    password: '',
    permissions: '',
    roleId: '',
    companyId: '',
    departmentId: '',
    captureId: '',
    category: '',
    accesslevelId: '',
    isVoicemail: '',
    voicemailPIN: '',
    isvoicemailEmail: '',
    isRainbow: '',
    isTeams: '',
    SIPExtension: '',
  },
  action
) => {
  switch (action.type) {
    case actionTypesUser.changeUserFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormName:
      state = {
        ...state,
        firstname: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormFirstSurname:
      state = {
        ...state,
        lastname: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormSecondSurname:
      state = {
        ...state,
        secondname: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormEmail:
      state = {
        ...state,
        email: action.payload,
      };
      break;
    case actionTypesUser.changeUserRingTimeout:
      state = {
        ...state,
        ringTimeout: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormPassword:
      state = {
        ...state,
        password: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormAccessLevels:
      state = {
        ...state,
        accesslevelId: action.payload,
      };
      break;
    case actionTypesUser.addUserFormAccessLevels:
      state = {
        ...state,
        accesslevelId: action.payload,
      };
      break;
    case actionTypesUser.addUserFormCompany:
      state = {
        ...state,
        companyId: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormCompany:
      state = {
        ...state,
        companyId: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormisCallForward:
      state = {
        ...state,
        isCallForward: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormCallForward:
      state = {
        ...state,
        callForward: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormIsNotAnswer:
      state = {
        ...state,
        isNotAnswer: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormNotAnswer:
      state = {
        ...state,
        notAnswer: action.payload,
      };
      break;
    case actionTypesUser.addUserFormDepartment:
      state = {
        ...state,
        departmentId: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormCapture:
      state = {
        ...state,
        captureId: action.payload,
      };
      break;

    case actionTypesUser.changeUserFormDepartment:
      state = {
        ...state,
        departmentId: action.payload,
      };
      break;
    case actionTypesUser.addUserFormRoles:
      state = {
        ...state,
        roleId: action.payload,
      };
      break;
    case actionTypesUser.addUserFormisSIPExtension:
      state = {
        ...state,
        isSIPExtension: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormisSIPExtension:
      state = {
        ...state,
        isSIPExtension: action.payload,
      };
      break;
    case actionTypesUser.addUserFormCategory:
      state = {
        ...state,
        category: action.payload,
      };
      break;
    // case actionTypesUser.addUserFormCategories:
    //   state = {
    //     ...state,
    //     category: action.payload,
    //   };
    //   break;
    case actionTypesUser.addUserFormSIPExtension:
      state = {
        ...state,
        SIPExtension: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormSIPExtension:
      state = {
        ...state,
        SIPExtensionId: action.payload,
      };
      break;
    case actionTypesUser.addUserFormisRainbow:
      state = {
        ...state,
        isRainbow: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormisRainbow:
      state = {
        ...state,
        isRainbow: action.payload,
      };
      break;
    case actionTypesUser.addUserFormisTeams:
      state = {
        ...state,
        isTeams: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormisTeams:
      state = {
        ...state,
        isTeams: action.payload,
      };
      break;
    case actionTypesUser.addUserFormisVoicemail:
      state = {
        ...state,
        isVoicemail: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormisVoicemail:
      state = {
        ...state,
        isVoicemail: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormvoicemailPIN:
      state = {
        ...state,
        voicemailPIN: action.payload,
      };
      break;
    case actionTypesUser.addUserFormisvoicemailEmail:
      state = {
        ...state,
        isvoicemailEmail: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormisvoicemailEmail:
      state = {
        ...state,
        isvoicemailEmail: action.payload,
      };
      break;
    case actionTypesUser.changeUserFormRoles:
      state = {
        ...state,
        roleId: action.payload,
      };
      break;

    case actionTypesUser.removeUserFormRoles:
      state = {
        ...state,
        roleId: '',
      };
      break;
    case actionTypesUser.clearUserFormRoles:
      state = {
        ...state,
        roleId: '',
      };
      break;
    case actionTypesUser.clearUserFormCapture:
      state = {
        ...state,
        captureId: '',
      };
      break;
    case actionTypesUser.clearUserFormDept:
      state = {
        ...state,
        departmentId: '',
      };
      break;
    case actionTypesUser.clearUserForm:
      state = {
        id: null,
        firstname: '',
        lastname: '',
        secondname: '',
        email: '',
        password: '',
        roleId: '',
        companyId: '',
        departmentId: '',
        captureId: '',
        category: '',
        accesslevelId: '',
        isVoicemail: '',
        voicemailPIN: '',
        isvoicemailEmail: '',
        isTeams: '',
        isRainbow: '',
        SIPExtension: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
