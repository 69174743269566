import React from 'react';

// import { Header } from '../../components/Header/Header';
// import { MenuTop } from '../../components/MenuTop/MenuTop';
import { NumberingNavigation } from '../../navigation/NumberingNavigation/NumberingNavigation';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';
// import { menuNumbering } from '../../data/dataNumbering';
import PermissionProvider from '../../components/Permission/PermissionProvider';

// import { useBlocker } from 'react-router-dom';
import './NumberingScreen.css';

export const NumberingScreen = () => {
  // window.addEventListener('beforeload', () => {
  // if (modified_inputs.size) {
  //   const unsaved_changes_warning = 'Changes you made may not be saved.';
  //   evt.returnValue = unsaved_changes_warning;
  //   return unsaved_changes_warning;
  // }
  //   console.log('beforeload: ');
  // });
  // window.addEventListener('onbeforeunload', () => {
  // if (modified_inputs.size) {
  //   const unsaved_changes_warning = 'Changes you made may not be saved.';
  //   evt.returnValue = unsaved_changes_warning;
  //   return unsaved_changes_warning;
  // }
  //   console.log('beforeunload: ');
  // });

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  return (
    <PermissionProvider>
      <div className="numbering">
        {/* <Header image={menuNavLeft.menuTop[2].image} text={menuNavLeft.menuTop[2].text} /> */}
        {/* <MenuTop menu={menuNumbering} /> */}
        <NumberingNavigation />
      </div>
    </PermissionProvider>
  );
};
