import { actionTypesLogin } from '../../actions/login/actionTypes';

const initialState = {
  authChecked: false,
  loggedIn: false,
  user: {},
};

export const loginReducer = (user = initialState, action) => {
  let newUser;
  switch (action.type) {
    case actionTypesLogin.login:
      newUser = {
        ...user,
        authChecked: true,
        loggedIn: true,
        user: action.user,
      };
      break;
    default:
      newUser = user;
  }
  return newUser;
};
