import * as React from 'react';
import { array, func, object, string } from 'prop-types';
import Select from 'react-select';
import { useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { ConferenceFormScreen } from '../../screens/ApplicationsScreen/ConferenceScreen/ConferenceFormScreen';
import { Link } from 'react-router-dom';
import { IvrFormScreen } from '../../screens/ApplicationsScreen/IvrScreen/IvrFormScreen';
import { MailboxesFormScreen } from '../../screens/ApplicationsScreen/MailboxesScreen/MailboxesFormScreen';
import { useRef } from 'react';
import { LocutionsScreen } from '../../screens/ApplicationsScreen/LocutionsScreen/LocutionsScreen';
import { QueueScreen } from '../../screens/ApplicationsScreen/QueueScreen/QueueScreen';
import { ExtensionsScreen } from '../../screens/NumberingScreen/ExtensionsScreen/ExtensionsScreen';
import { EMScreen } from '../../screens/EMScreen/EMScreen';
import { OutboundScreen } from '../../screens/OutboundScreen/OutboundScreen';
import { isDataModifiedActionCreator, isLocationModifiedActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { useDispatch, useSelector } from 'react-redux';
import { ModalPromptWrapper } from './ModalPrompt.style';
// import './ModalPrompt.style.css';
// import { useDispatch } from 'react-redux';
// import { isDataModifiedActionCreator, isLocationModifiedActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { Modal } from '../Modal/Modal';

export const ModalPrompt = ({ isOpen, content, valueSelect1, selectedValue, item }) => {
  const [valueSelect, setValueSelect] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [navigateTo, setNavigateTo] = useState(null);
  const [form, setForm] = useState('');

  const [isDataProtected, setIsDataProtected] = useState(true);
  const { isLocationModified } = useSelector((state) => state.system);
  const dispatch = useDispatch();

  const select = useRef(null);

  // const { isDataModified, isLocationModified } = useSelector((state) => state.system);

  const handleCancelClick = (event) => {
    // if (!isDataProtected) {
    //   console.log('handleCancelClick: data not protected');
    // } else {
    event.preventDefault(event);

    setIsDataProtected(false);
    !isDataProtected ? isOpen(false) : '';
    console.log('handleCancelClick: Canceled');

    dispatch(isLocationModifiedActionCreator(false));
    dispatch(isDataModifiedActionCreator(false));
    isDataProtected ? selectedValue(valueSelect) : '';
    event.returnValue = '';
    event.stopPropagation();

    // }
  };

  const handleOnChange = (value) => {
    event.preventDefault(event);
    setValueSelect(value);
    isOpen(true);
    setNavigateTo(null);
    setIsDataProtected(false);
    console.log('Selected: ' + value?.name + ': ' + value?.label);
  };

  // const cancelClass = valueSelect1 === 'Error' ? 'modal-btn-cancel display-none' : 'modal-btn-cancel';

  const onSubmit = (event) => {
    event.preventDefault(event);

    isOpen(false);
    selectedValue(valueSelect);
    // isDataProtected ? selectedValue(valueSelect) : '';
    setIsDataProtected(true);
    dispatch(isLocationModifiedActionCreator(false));
    dispatch(isDataModifiedActionCreator(false));
  };

  const [WarningON, setWarningON] = useState(false);

  const handleOnClickOutModal = (event) => {
    event.preventDefault(event);
    !isDataProtected ? setWarningON(true) : isOpen(false);

    dispatch(isLocationModifiedActionCreator(true));
    console.log('Click fuera del modal by: ' + event.currentTarget.className);
  };

  const handleOnClickInModal = (event) => {
    event.stopPropagation();
  };

  // const isWarning = isDataModified && isLocationModified;

  // const setShowWarning = (status) => {
  //   if (status !== undefined) {
  //     setWarningON(status);
  //     isOpen(status);
  //     console.log('setShowWarning updated: ' + status);
  //   }
  // };
  const modalClassName = WarningON || isLocationModified ? 'modal-warning' : 'modal';
  const blurClassName = WarningON || isLocationModified ? '-warning' : '';

  // addEventListener('beforeunload', () => {
  //   if (!isDataProtected) {
  //     //   const unsaved_changes_warning = 'Changes you made may not be saved.';
  //     //   evt.returnValue = unsaved_changes_warning;
  //     //   return unsaved_changes_warning;
  //     console.log('ModalPrompt beforeunload: Data will be lost! ');
  //   }
  // });

  // const [WarningON, setWarningON] = useState(false);
  const contentSelect = content?.length > 0 ? content : [''];

  const getContent = () => {
    // <h3 className="modal-message">{content}</h3>;

    return WarningON || isLocationModified ? (
      'Se perderán las modificaciones realizadas.'
    ) : (
      <div className="modal-content">
        {!isFormVisible && !WarningON ? (
          <div className="modal-select">
            <Select
              className="basic-single"
              onChange={handleOnChange}
              // onChange={''}
              classNamePrefix="select"
              defaultValue={selectedValue}
              isClearable
              isSearchable
              options={contentSelect}
              placeholder="Seleccionar"
              ref={select}
              itemForm={item}
            />
            <IconPlus
              className="btn-Crear"
              onClick={() => {
                const newForm = (valueSelect1) => {
                  switch (valueSelect1) {
                    case 'Conferencia':
                      return <ConferenceFormScreen crudType="create" />;
                    case 'Búzon':
                      return <MailboxesFormScreen crudType="create" />;
                    case 'IVR':
                      return <IvrFormScreen crudType="create" />;
                    case 'Locución':
                      return <LocutionsScreen crudType="create" />;
                    case 'Cola':
                      return <QueueScreen crudType="create" />;
                    case 'Usuarios':
                      return <ExtensionsScreen crudType="create" />;
                    case 'Emergency Manager':
                      return <EMScreen crudType="create" />;
                    case 'Número externo':
                      return <OutboundScreen crudType="create" />;
                    case 'Colgar llamada':
                      return true;
                    default:
                      return;
                  }
                };

                setForm(newForm(valueSelect1));
                setNavigateTo(form);
                setIsFormVisible(true);

                // console.log('Create: ' + valueSelect1);
              }}
            />
          </div>
        ) : (
          ''
        )}
        <Link to={navigateTo}>{form}</Link>
      </div>
    );
  };

  return (
    // isOpen === true ? (
    <ModalPromptWrapper className={'blur-container' + blurClassName} onClick={handleOnClickOutModal}>
      {/* {WarningON ? (
        // <Modal show={setShowWarning} onClick={handleCancelClick} dataModal={['Warning!!']} isWarning={(status) => status} isModalOpen={(status) => status}>
        <ModalPrompt content={['Aviso']} isOpen={() => isOpen} />
      ) : (
        // </Modal>
        ''
      )} */}
      <div className={modalClassName} onClick={handleOnClickInModal}>
        <div className="modal-header">
          <h1 className="modal-title">{!WarningON && !isLocationModified ? valueSelect1 : 'Aviso'}</h1>
          <button onClick={handleCancelClick} className="modal-btn-close" title="btn-close" placeholder="btn-close">
            X
          </button>
        </div>

        <form onSubmit={onSubmit} method="POST">
          <div className="modal-body">{getContent()}</div>
          <div className="modal-buttons">
            <button type="submit" className="modal-btn-accept" title="btn-accept" placeholder="btn-accept">
              {!WarningON && !isLocationModified ? 'Asignar' : 'Salir sin guardar.'}
            </button>
            {/* <button onClick={handleCancelClick} className={cancelClass} title="btn-cancel" placeholder="btn-cancel">
            Cancelar
          </button> */}
          </div>
        </form>
      </div>
    </ModalPromptWrapper>
  );
  // : null;
};

ModalPrompt.propTypes = { isOpen: func, selectedValue: func, content: array, valueSelect1: string, valueSelect2: string, item: object, type: string };
