// import { arraysAreEqual } from '../../../utils/AppUtils';
import { actionsTypes } from '../../actions/numbering/actionsTypes';

export const numberingReducer = (listNumbering = [], action) => {
  let newListNumbering;
  switch (action.type) {
    case actionsTypes.getNumberingList:
      newListNumbering = [...action.arrayNumbering];
      break;

    default:
      newListNumbering = listNumbering;
  }
  return newListNumbering;
};

export const getDdiLinkReducer = (ddiLink = [], action) => {
  let newDdiLink;
  switch (action.type) {
    case actionsTypes.getDdiLink:
      newDdiLink = [...action.payload];
      break;

    default:
      newDdiLink = ddiLink;
      break;
  }
  return newDdiLink;
};

export const numberingExtensionsReducer = (arrayNumberingExtensions = [], action) => {
  let newArrayNumberingExtensions;
  switch (action.type) {
    case actionsTypes.getExtension:
      newArrayNumberingExtensions = [...action.arrayNumbering];
      break;

    default:
      newArrayNumberingExtensions = arrayNumberingExtensions;
  }

  return newArrayNumberingExtensions;
};

export const marcasReducer = (arrayMarcas = [], action) => {
  let newArray = [];
  switch (action.type) {
    case actionsTypes.getMarcas:
      newArray = [...action.arrayMarcas];
      break;

    default:
      newArray = arrayMarcas;
  }

  return newArray;
};

export const modelosReducer = (arrayModelos = [], action) => {
  let newArray = [];
  switch (action.type) {
    case actionsTypes.getModelos:
      newArray = [...action.arrayModelos];
      break;

    default:
      newArray = arrayModelos;
  }

  return newArray;
};

export const plantillasReducer = (arrayPlantillas = [], action) => {
  let newArray = [];

  switch (action.type) {
    case actionsTypes.getPlantillas:
      newArray = [...action.arrayPlantillas];
      break;

    default:
      newArray = arrayPlantillas;
  }

  return newArray;
};

export const formListNumberingReducer = (
  state = {
    id: '',
    ddi: '',
    name: '',
    calendars: [],
    department: { label: '', value: '' },
    companyId: { label: '', value: '' },
    destinationType: { label: '', value: '' },
    destination: { label: '', value: '' },
    destinationCalendar: [
      {
        id: 1,
        calendar: {
          label: 'Predeterminado',
          value: 'Predeterminado',
          initialState: true,
        },
        // destinationOpen: [
        //   {
        //     id: 1,
        //     schedule: {
        //       label: '',
        //       value: '',
        //       initialState: true,
        //     },
        //     soundPrompt: {
        //       label: '',
        //       value: '',
        //       initialState: true,
        //     },
        //     destination: {
        //       label: '',
        //       value: '',
        //       initialState: true,
        //     },
        //   },
        // ],
        soundPromptCloseId: {
          label: '',
          value: '',
          initialState: true,
        },
        destinationCloseId: {
          label: '',
          value: '',
          initialState: true,
        },
      },
    ],
  },
  action
) => {
  const destinationCalendar = [...state.destinationCalendar];

  // const refCalendar = 'calendar';
  // const optionRef = action.payload?.optionRefPage;

  // const existingObj = destinationCalendar.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionRef);

  // const refSchedule = 'schedule';
  const optionRefSchedule = action.payload?.optionRefPage;
  const optionRefScheduleDestinationOpen = action.payload && action.payload.destinationOpen ? action.payload.destinationOpen : '';

  const existingObjSchedule = destinationCalendar.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionRefSchedule);
  // const destinationOpenObjectRefSchedule =
  existingObjSchedule && existingObjSchedule.destinationOpen
    ? existingObjSchedule?.destinationOpen.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionRefScheduleDestinationOpen)
    : '';

  // const updatedObjectSchedule = {
  //   ...destinationOpenObjectRefSchedule,
  //   [refSchedule]: {
  //     label: action.payload?.label,
  //     value: action.payload?.value,
  //     initialState: false,
  //   },
  // };

  // const index = existingObjSchedule && existingObjSchedule.destinationOpen ? existingObjSchedule?.destinationOpen.findIndex((obj) => obj?.id === optionRefScheduleDestinationOpen) : '';

  // const refSoundPrompt = 'soundPrompt';
  // const optionRefSoundPrompt = action.payload?.optionRefPage;
  // const optionRefSoundPromptDestinationOpen = action.payload && action.payload.destinationOpen ? action.payload.destinationOpen : '';

  // const existingObjSoundPrompt = destinationCalendar.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionRefSoundPrompt);
  // const destinationOpenObjectRefSoundPrompt = existingObjSoundPrompt?.destinationOpen.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionRefSoundPromptDestinationOpen);

  // const updatedObjectSoundPrompt = {
  //   ...destinationOpenObjectRefSoundPrompt,
  //   [refSoundPrompt]: {
  //     label: action.payload?.label,
  //     value: action.payload?.value,
  //     initialState: false,
  //   },
  // };

  // const refDestination = 'destination';
  // const optionDestination = action.payload?.optionRefPage;
  // const optionRefDestinationDestinationOpen = action.payload?.destinationOpen;

  // const existingObjDestination = destinationCalendar.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionDestination);
  // const destinationOpenObjectRefDestination = existingObjDestination?.destinationOpen.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionRefDestinationDestinationOpen);

  // const updatedObjectDestination = {
  //   ...destinationOpenObjectRefDestination,
  //   [refDestination]: {
  //     label: action.payload?.label,
  //     value: action.payload?.value,
  //     initialState: false,
  //   },
  // };

  // const refSoundPromptClose = 'soundPromptClose';
  // const optionSoundPromptClose = action.payload?.optionRefPage;

  // const existingObjSoundPromptClose = destinationCalendar.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionSoundPromptClose);
  // const refDestinationClose = 'destinationClose';
  // const optionDestinationClose = action.payload?.optionRefPage;

  // const existingObDestinationClose = destinationCalendar.find((obj) => obj.prototype?.hasOwnProperty.call('id') && obj?.id === optionDestinationClose);

  const newId = action.payload;
  const newObject = {
    id: newId,
    calendar: {
      label: '',
      value: '',
      initialState: true,
    },
    // destinationOpen: [
    //   {
    //     id: 1,
    //     schedule: {
    //       label: '',
    //       value: '',
    //       initialState: true,
    //     },
    //     soundPrompt: {
    //       label: '',
    //       value: '',
    //       initialState: true,
    //     },
    //     destination: {
    //       label: '',
    //       value: '',
    //       initialState: true,
    //     },
    //   },
    // ],
    soundPromptCloseId: {
      label: '',
      value: '',
      initialState: true,
    },
    destinationCloseId: {
      label: '',
      value: '',
      initialState: true,
    },
  };

  const found = state.destinationCalendar.some((e) => e.id === newId);

  const idToDelete = action.payload;
  const updatedDestinationCalendarDelete = state.destinationCalendar.filter((destinationCalendarObject) => destinationCalendarObject?.id !== idToDelete);
  let refIdDestination = action.payload?.newDestinationOpen?.id;
  let refId = action.payload?.refId;
  let newDestinationOpen = {
    id: refIdDestination,
    schedule: {
      label: '',
      value: '',
      initialState: true,
    },
    soundPrompt: {
      label: '',
      value: '',
      initialState: true,
    },
    destination: {
      label: '',
      value: '',
      initialState: true,
    },
  };

  const idPage = action.payload?.idPage;
  let idDestinationOpen = action.payload?.idDestinationOpen;
  // Buscar el objeto en destinationCalendar que coincide con la idPage
  const indexPage = state.destinationCalendar.findIndex((obj) => obj?.id === idPage);

  switch (action.type) {
    case actionsTypes.changeNumberingListId:
      state = {
        ...state,
        id: Number(action.payload),
      };
      break;
    case actionsTypes.changeNumberingListNumber:
      state = {
        ...state,
        ddi: action.payload,
      };
      break;
    case actionsTypes.changeNumberingListName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionsTypes.addNumberingListCompanyId:
      state = {
        ...state,
        companyId: Number(action.payload.value),
        Company: action.payload,
      };
      break;

    case actionsTypes.clearNumberingListCompanyId:
      state = {
        ...state,
        companyId: {},
      };
      break;
    case actionsTypes.addNumberingListDepartmentId:
      state = {
        ...state,
        departmentId: Number(action.payload),
      };
      break;
    case actionsTypes.clearListNumberingDepartment:
      state = {
        ...state,
        Department: {},
      };
      break;
    case actionsTypes.addNumberingListTimes:
      state = {
        ...state,
        times: action.payload,
      };
      break;
    case actionsTypes.addNumberingListDepartment:
      state = {
        ...state,
        Department: action.payload,
      };
      break;
    case actionsTypes.addNumberingListLocutions:
      state = {
        ...state,
        soundPrompts: action.payload,
      };
      break;
    case actionsTypes.addNumberingListDestinationsType:
      state = {
        ...state,
        destinationsType: action.payload,
      };
      break;
    case actionsTypes.addNumberingListDestinations:
      state = {
        ...state,
        destinations: action.payload,
      };
      break;
    case actionsTypes.addNumberingListCalendar:
      // if (existingObj) {
      //   if (existingObj.prototype?.hasOwnProperty.call(refCalendar)) {
      //     existingObj[refCalendar].label = action.payload?.label;

      //     existingObj[refCalendar].value = action.payload?.value;
      //     existingObj[refCalendar].initialState = false;
      //   } else {
      //     existingObj[refCalendar] = {
      //       label: action.payload?.label,
      //       value: action.payload?.value,
      //       initialState: false,
      //     };
      //   }
      // } else {
      //   let newObject = {
      //     [refCalendar]: {
      //       label: action.payload?.label,
      //       value: action.payload?.value,
      //       initialState: false,
      //     },
      //     destinationOpen: [
      //       {
      //         id: 1,
      //         schedule: {
      //           label: '',
      //           value: '',
      //           initialState: true,
      //         },
      //         soundPrompt: {
      //           label: '',
      //           value: '',
      //           initialState: true,
      //         },
      //         destination: {
      //           label: '',
      //           value: '',
      //           initialState: true,
      //         },
      //       },
      //     ],
      //     soundPromptClose: {
      //       label: '',
      //       value: '',
      //       initialState: true,
      //     },
      //     destinationClose: {
      //       label: '',
      //       value: '',
      //       initialState: true,
      //     },
      //     id: optionRef,
      //   };
      //   destinationCalendar.push(newObject);
      // }

      state = { ...state, calendar: action.payload };

      break;

    case actionsTypes.addNumberingListSchedule:
      // existingObjSchedule && existingObjSchedule.destinationOpen
      //   ? (existingObjSchedule.destinationOpen = existingObjSchedule.destinationOpen.map((obj) => {
      //       if (obj.id === optionRefScheduleDestinationOpen) {
      //         return updatedObjectSchedule;
      //       }

      //       return obj;
      //     }))
      //   : '';

      // if (index === -1) {
      //   existingObjSchedule?.destinationOpen.push({
      //     id: optionRefScheduleDestinationOpen,
      //     schedule: {
      //       label: action.payload?.label,
      //       value: action.payload?.value,
      //       initialState: false,
      //     },
      //     soundPrompt: {
      //       label: '',
      //       value: '',
      //       initialState: true,
      //     },
      //     destination: {
      //       label: '',
      //       value: '',
      //       initialState: true,
      //     },
      //   });
      // }

      state = { ...state, schedule: action.payload };

      break;

    case actionsTypes.addNumberingListSoundPrompt:
      state = { ...state, soundPrompt: action.payload };

      break;
    case actionsTypes.addNumberingListDestination:
      // existingObjDestination && existingObjDestination.destinationOpen
      //   ? (existingObjDestination.destinationOpen = existingObjDestination?.destinationOpen.map((obj) => {
      //       if (obj.id === optionRefDestinationDestinationOpen) {
      //         return updatedObjectDestination;
      //       }
      //       return obj;
      //     }))
      //   : '';

      // state = { ...state, destinationCalendar };
      state = { ...state, destination: action.payload };
      break;
    case actionsTypes.addNumberingListSoundPromptClose:
      // if (existingObjSoundPromptClose) {
      //   if (existingObjSoundPromptClose.prototype?.hasOwnProperty.call(refSoundPromptClose)) {
      //     existingObjSoundPromptClose[refSoundPromptClose].label = action.payload?.label;
      //     existingObjSoundPromptClose[refSoundPromptClose].value = action.payload?.value;
      //     existingObjSoundPromptClose[refSoundPromptClose].initialState = false;
      //   } else {
      //     existingObjSoundPromptClose[refSoundPromptClose] = {
      //       label: action.payload?.label,
      //       value: action.payload?.value,
      //       initialState: false,
      //     };
      //   }
      // } else {
      //   let newObject = {
      //     [refSoundPromptClose]: {
      //       label: action.payload?.label,
      //       value: action.payload?.value,
      //       initialState: false,
      //     },
      //     id: optionSoundPromptClose,
      //   };
      //   destinationCalendar.push(newObject);
      // }

      state = {
        ...state,
        soundPromptClose: action.payload,
      };

      break;

    case actionsTypes.addNumberingListSoundPrompts:
      state = {
        ...state,
        // destinationClose: action.payload,
        soundPrompts: action.payload,
      };
      break;
    case actionsTypes.addNumberingListSchedules:
      state = {
        ...state,
        // destinationClose: action.payload,
        schedules: action.payload,
      };
      break;
    case actionsTypes.addNumberingListDestinationCloseId:
      state = {
        ...state,
        // destinationClose: action.payload,
        destinationCloseId: action.payload,
      };
      break;
    case actionsTypes.addNumberingListDestinationClose:
      state = {
        ...state,
        // destinationClose: action.payload,
        destinationClose: action.payload,
      };
      break;

    case actionsTypes.addNumberingListDestinationCloseTypeId:
      state = {
        ...state,
        destinationCloseTypeId: action.payload,
      };
      break;
    case actionsTypes.addNumberingListDestinationCloseType:
      state = {
        ...state,
        destinationCloseType: action.payload,
      };
      break;
    // case actionsTypes.addNumberingListDestinations:
    //   state = {
    //     ...state,
    //     dispositivos: action.payload,
    //   };
    //   break;
    // case actionsTypes.addNumberingListDestinationCloseType:
    //   state = {
    //     ...state,
    //     destinationCloseType: action.payload,
    //     // destinationTypeCloseId: action.payload?.value,
    //   };
    //   break;
    case actionsTypes.addNumberingListDestinationType:
      state = {
        ...state,
        destinationType: action.payload,
        // destination:action.payload.
        // destinationTypeCloseId: action.payload?.value,
      };
      break;
    case actionsTypes.addNumberingListIsDefaults:
      state = {
        ...state,
        isDefaults: action.payload,
        // destination:action.payload.
        // destinationTypeCloseId: action.payload?.value,
      };
      break;
    case actionsTypes.addNumberingListIsDefault:
      state = {
        ...state,
        isDefault: action.payload,
        // destination:action.payload.
        // destinationTypeCloseId: action.payload?.value,
      };
      break;
    case actionsTypes.addNumberingListCalendars:
      state = {
        ...state,
        calendars: action.payload,
      };
      break;

    case actionsTypes.addNumberingListSoundPromptCloseId:
      state = {
        ...state,
        soundPromptCloseId: action.payload,
      };
      break;
    case actionsTypes.addDestinationCalendar:
      if (!found) {
        const updatedDestinationCalendar = state.destinationCalendar.concat(newObject);
        return {
          ...state,
          destinationCalendar: updatedDestinationCalendar,
        };
      } else {
        return state;
      }

    case actionsTypes.removeDestinationCalendar:
      return {
        ...state,
        destinationCalendar: updatedDestinationCalendarDelete,
      };

    case actionsTypes.addDestinationOpen:
      destinationCalendar.forEach((item) => {
        if (item.id === refId) {
          const found = item.destinationOpen.some((e) => e.id === refIdDestination);

          if (!found) {
            item.destinationOpen.push(newDestinationOpen);
          }
        }
      });

      break;

    case actionsTypes.removeDestinationOpen:
      if (indexPage !== -1) {
        // Buscar el objeto en destinationOpen que coincide con la idDestinationOpen
        const indexDestinationOpen = state.destinationCalendar[indexPage].destinationOpen.findIndex((obj) => obj.id === idDestinationOpen);

        if (indexDestinationOpen !== -1) {
          // Eliminar el objeto que coincide con la idDestinationOpen
          const updatedDestinationOpen = [...state.destinationCalendar[indexPage].destinationOpen];
          updatedDestinationOpen.splice(indexDestinationOpen, 1);

          // Crear una copia del objeto encontrado en destinationCalendar
          const updatedObj = { ...state.destinationCalendar[indexPage] };

          // Actualizar la propiedad destinationOpen del objeto encontrado en destinationCalendar
          updatedObj.destinationOpen = updatedDestinationOpen;

          // Crear una copia de destinationCalendar y reemplazar el objeto encontrado con el objeto modificado
          const updatedDestinationCalendar = [...state.destinationCalendar];
          updatedDestinationCalendar[indexPage] = updatedObj;

          // Devolver el nuevo estado con el objeto modificado
          return { ...state, destinationCalendar: updatedDestinationCalendar };
        }
      }

      // Si no se encuentra el objeto que coincide con la idPage o la idDestinationOpen, devolver el estado original
      return state;
    // const pageIndex = state.destinationCalendar.findIndex((page) => page.id === idPage)

    // // Actualiza el array destinationOpen eliminando el último objeto
    // state.destinationCalendar[pageIndex].destinationOpen.splice(-1, 1)

    // // Devuelve el nuevo estado modificado
    // return { ...state }

    case actionsTypes.clearNumberingListCalendar:
      destinationCalendar.forEach((item) => {
        if (item.id === action.payload?.optionRefPage) {
          item.calendar = {
            label: '',
            value: '',
            initialState: false,
          };
        }
      });

      break;
    case actionsTypes.clearNumberingListSchedule:
      destinationCalendar.forEach((item) => {
        if (item.id === action.payload?.optionRefPage) {
          item.destinationOpen.forEach((item2) => {
            if (item2.id === action.payload?.destinationOpen) {
              item2.schedule = {
                label: '',
                value: '',
                initialState: false,
              };
            }
          });
        }
      });

      break;
    case actionsTypes.clearNumberingListSoundPrompt:
      destinationCalendar.forEach((item) => {
        if (item.id === action.payload?.optionRefPage) {
          item.destinationOpen.forEach((item2) => {
            if (item2.id === action.payload?.destinationOpen) {
              item2.soundPrompt = {
                label: '',
                value: '',
                initialState: false,
              };
            }
          });
        }
      });
      break;
    case actionsTypes.clearNumberingListDestination:
      destinationCalendar.forEach((item) => {
        if (item.id === action.payload?.optionRefPage) {
          item.destinationOpen.forEach((item2) => {
            if (item2.id === action.payload?.destinationOpen) {
              item2.destination = {
                label: '',
                value: '',
                initialState: false,
              };
            }
          });
        }
      });
      break;
    case actionsTypes.clearNumberingListSoundPromptClose:
      destinationCalendar.forEach((item) => {
        if (item.id === action.payload?.optionRefPage) {
          item.soundPromptClose = {
            label: '',
            value: '',
            initialState: false,
          };
        }
      });
      break;
    case actionsTypes.clearNumberingListDestinationClose:
      destinationCalendar.forEach((item) => {
        if (item.id === action.payload?.optionRefPage) {
          item.destinationClose = {
            label: '',
            value: '',
            initialState: false,
          };
        }
      });
      break;

    case actionsTypes.clearNumberingListForm:
      state = {
        id: '',
        ddi: '',
        name: '',
        companyId: '',
        calendars: '',
        departmentId: '',
        Department: { label: '', value: '' },
        Company: { label: '', value: '' },
        destinationCalendar: [
          {
            id: 1,
            calendar: {
              label: 'Predeterminado',
              value: 'Predeterminado',
            },
            // destinationOpen: [
            //   {
            //     id: 1,
            //     schedule: {
            //       label: '',
            //       value: '',
            //       initialState: true,
            //     },
            //     soundPrompt: {
            //       label: '',
            //       value: '',
            //       initialState: true,
            //     },
            //     destination: {
            //       label: '',
            //       value: '',
            //       initialState: true,
            //     },
            //   },
            // ],
            soundPromptClose: {
              label: '',
              value: '',
              initialState: true,
            },
            destinationClose: {
              label: '',
              value: '',
              initialState: true,
            },
          },
        ],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const importFileListNumberingReducer = (file = [], action) => {
  let newFile;
  switch (action.type) {
    case actionsTypes.importFileNumberingList:
      newFile = [...action.file];
      break;

    default:
      newFile = file;
      break;
  }
  return newFile;
};

//reducer for create extensions

export const formExtensionsReducer = (
  state = {
    id: '',
    // number: '',
    // name: '',
    userId: '',
    typeExtension: [],
    password: '',
    identification: [],
    marca: [],
    modelo: [],
    plantilla: [],
    // ringTimeout: '',
    // callForward: '',
    // callForwardNA: '',
    // companyId: [],
    // record: [],
    // pikedGroup: [],
    // voicemailActive: false,
    // department: [],
    // category: [],
    // callWaiting: false,
    // sipPeerId: '',
    allow: [],
  },
  action
) => {
  // const arrayRecord = { ...state }.record;
  // const arrayAllow = { ...state }.allow || [];
  // const arrayPikedGroup = { ...state }.pikedGroup;
  // const index = arrayRecord.map((p) => p.value).indexOf(action.payload?.value);
  // const indexPikedGroup = arrayPikedGroup.map((p) => p.value).indexOf(action.payload);
  // let newArray = [];
  // let newValue = [];
  switch (action.type) {
    case actionsTypes.changeExtensionId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionsTypes.changeExtensionNumber:
      state = {
        ...state,
        number: action.payload,
      };
      break;
    case actionsTypes.changeExtensionMarca:
      state = {
        ...state,
        marca: action.payload,
      };
      break;
    case actionsTypes.changeExtensionModelo:
      state = {
        ...state,
        modelo: action.payload,
      };
      break;
    case actionsTypes.changeExtensionPlantilla:
      state = {
        ...state,
        plantilla: action.payload,
      };
      break;
    case actionsTypes.changeExtensionName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionsTypes.changeExtensionPassword:
      state = {
        ...state,
        password: action.payload,
      };
      break;
    case actionsTypes.addExtensionIdentification:
      state = {
        ...state,
        identification: action.payload,
      };
      break;

    case actionsTypes.removeExtensionIdentification:
      state = {
        ...state,
        identification: [],
      };
      break;
    case actionsTypes.addTypeExtension:
      state = {
        ...state,
        typeExtension: action.payload,
      };
      break;
    case actionsTypes.removeTypeExtension:
      state = {
        ...state,
        typeExtension: [],
      };
      break;

    case actionsTypes.changeUserFormCallForward:
      state = {
        ...state,
        callForward: action.payload,
      };
      break;
    case actionsTypes.changeExtensionCallForwardNA:
      state = {
        ...state,
        callForwardNA: action.payload,
      };
      break;
    case actionsTypes.addExtensionCompanyId:
      state = {
        ...state,
        companyId: action.payload,
      };
      break;
    case actionsTypes.addExtensionPlantilla:
      state = {
        ...state,
        plantilla: action.payload,
      };
      break;
    case actionsTypes.addMarcaExtension:
      state = {
        ...state,
        marca: action.payload,
      };
      break;
    case actionsTypes.addModeloExtension:
      state = {
        ...state,
        modelo: action.payload,
      };
      break;
    case actionsTypes.addExtensionDepartment:
      state = {
        ...state,
        department: action.payload,
        Department: action.payload,
        departmentId: action.payload.value,
      };
      break;
    case actionsTypes.removeExtensionDepartment:
      state = {
        ...state,
        Department: [],
      };
      break;
    case actionsTypes.removeExtensionCompanyId:
      state = {
        ...state,
        companyId: [],
      };
      break;
    // case actionsTypes.addExtensionRecord:
    //   if (index > -1) {
    //     arrayRecord.splice(index, 1);
    //   } else {
    //     arrayRecord.push(action.payload);
    //   }
    //   state = {
    //     ...state,
    //     record: arrayRecord,
    //   };
    //   break;

    case actionsTypes.addExtensionPikedGroup:
      state = {
        ...state,
        pikedGroup: action.payload,
      };
      break;
    // case actionsTypes.removeExtensionPikedGroup:
    // if (indexPikedGroup > -1) {
    //   arrayPikedGroup.splice(indexPikedGroup, 1);
    // }
    // state = {
    //   ...state,
    //   pikedGroup: arrayPikedGroup,
    // };
    // break;
    case actionsTypes.clearExtensionsPikedGroup:
      state = {
        ...state,
        pikedGroup: [],
      };
      break;

    case actionsTypes.changeExtensionFilterType:
      state = {
        ...state,
        filterType: action.payload,
      };
      break;
    case actionsTypes.changeExtensionVoicemailActive:
      state = {
        ...state,
        voicemailActive: action.payload,
      };
      break;
    case actionsTypes.changeExtensionCallWaiting:
      state = {
        ...state,
        callWaiting: action.payload,
      };

      break;
    case actionsTypes.addExtensionCategories:
      state = {
        ...state,
        category: action.payload,
      };
      break;
    case actionsTypes.removeExtensionCategories:
      state = {
        ...state,
        category: [],
      };
      break;

    case actionsTypes.changeExtensionSipPeerId:
      state = {
        ...state,
        sipPeerId: action.payload,
      };
      break;
    case actionsTypes.addExtensionUser:
      state = {
        ...state,
        userId: action.payload,
      };
      break;
    case actionsTypes.removeExtensionAllow:
      state = {
        ...state,
        allow: action.payload,
      };
      break;
    case actionsTypes.addExtensionAllow:
      // newArray = action.payload;
      // if (arrayAllow?.soundPrompt === '') {
      // } else if (!arraysAreEqual(arrayAllow.soundPrompt, action.payload)) {
      //   if (action.payload?.length) {
      //     newArray = action.payload;
      //   } else {
      //     newArray.push(action.payload);
      //   }
      // state.soundPrompt = arraySoundPrompt?.soundPrompt;
      // }
      // state.allow?.length > 0 && (!Array.isArray(action.payload) || (Array.isArray(action.payload) && action.payload.length !== state.allow?.length)) ? state.allow?.push(action.payload) : null;

      // newValue = !Array.isArray(action.payload) ? state?.allow : action.payload;
      state = {
        ...state,
        allow: action.payload,
      };
      break;
    case actionsTypes.clearExtensionAllow:
      state = {
        ...state,
        allow: [],
      };
      break;
    case actionsTypes.clearExtensionForm:
      state = {
        id: '',
        userId: '',
        // number: '',
        // name: '',
        password: '',
        identification: [],
        typeExtension: [],
        marca: [],
        modelo: [],
        plantilla: [],
        // ringTimeout: '',
        // callForward: '',
        // callForwardNA: '',
        // companyId: [],
        // record: [],
        // pikedGroup: [],
        // voicemailActive: false,
        // department: [],
        // category: [],
        // callWaiting: false,
        // sipPeerId: '',
        allow: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const formMarcaReducer = (
  state = {
    id: '',
    marca: '',
  },
  action
) => {
  switch (action.type) {
    case actionsTypes.changeMarcaId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionsTypes.changeMarcaNombre:
      state = {
        ...state,
        marca: action.payload,
      };
      break;
    case actionsTypes.clearMarcaForm:
      state = {
        id: '',
        // nombre: '',
        marca: '',
        // modelo: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const formModeloReducer = (
  state = {
    id: '',
    marca: '',
    modelo: '',
  },
  action
) => {
  switch (action.type) {
    case actionsTypes.changeModeloId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionsTypes.changeModeloNombre:
      state = {
        ...state,
        modelo: action.payload,
      };
      break;
    case actionsTypes.changeModeloMarca:
      state = {
        ...state,
        marca: action.payload,
      };
      break;
    case actionsTypes.clearModeloNombre:
      state = {
        ...state,
        modelo: action.payload,
      };
      break;
    case actionsTypes.clearModeloForm:
      state = {
        id: '',
        marca: '',
        modelo: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const formPlantillaReducer = (
  state = {
    id: '',
    nombre: '',
    marca: '',
    modelo: '',
    blfs: '',
    observaciones: '',
  },
  action
) => {
  switch (action.type) {
    case actionsTypes.changePlantillaId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionsTypes.changePlantillaNombre:
      state = {
        ...state,
        nombre: action.payload,
      };
      break;
    case actionsTypes.changePlantillaMarca:
      state = {
        ...state,
        marca: action.payload,
      };
      break;
    case actionsTypes.changePlantillaModelo:
      state = {
        ...state,
        modelo: action.payload,
      };
      break;
    case actionsTypes.changePlantillaObservaciones:
      state = {
        ...state,
        observaciones: action.payload,
      };
      break;
    case actionsTypes.changePlantillaBlfs:
      state = {
        ...state,
        blfs: action.payload,
      };
      break;
    case actionsTypes.clearPlantillaNombre:
      state = {
        ...state,
        modelo: action.payload,
      };
      break;
    case actionsTypes.clearPlantillaForm:
      state = {
        id: '',
        nombre: '',
        marca: '',
        modelo: '',
        blfs: '',
        observaciones: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const NBDeleteExtensionReducer = (extension = {}, action) => {
  let newExtensionDelete;
  switch (action.type) {
    case actionsTypes.deleteNBExtension:
      newExtensionDelete = { ...action.extension };
      break;
    default:
      newExtensionDelete = extension;
  }
  return newExtensionDelete;
};

export const importFileExtensionReducer = (file = [], action) => {
  let newFile;
  switch (action.type) {
    case actionsTypes.importFileExtension:
      newFile = [...action.file];
      break;

    default:
      newFile = file;
      break;
  }
  return newFile;
};

export const createNumberingListReducer = (numberingList = {}, action) => {
  let newNumberingList;
  switch (action.type) {
    case actionsTypes.createNumberingList:
      newNumberingList = { ...action.payload };
      break;

    default:
      newNumberingList = numberingList;
      break;
  }
  return newNumberingList;
};
