export const colors = {
  white: '#FFFFFF',
  whiteLight: '#F8FAFC',
  black: '#1E2530',
  primaryColor: '#334155',
  primaryColorLight: '#526581',
  secondaryColor: '#95A3BFFF',
  backgroundColor: '#F8FAFC',
  grey: '#4A5056',
  greyHover: '#EAECEF',
  greyHoverCard: '#f5f5f5',
  greyBorder: '#E6E3E8',
  greyActive: '#B9C0CA',
  greyLine: '#E1E0E0',
  greyText: '#180E0E',
  greySpanLabel: '#E1E0E0',
  blue: '#0C3FC4',
  lightblue: '#164FDDFF',
  blueCheckbox: '#B9C7DA',
  blueHover: '#EBFAFF',
  green: '#29903B',
  greenGYGAS: '#94CE0D',
  greenGYGASLight: '#A1E00FFF',
  greenHover: '#DAF1DB',
  red: '#CD3434',
  redHover: '#FDCFCF',
  burlywood: '#deb887',
  gradient: 'linear-gradient(90deg, rgba(48,125,209,1) 0%, rgba(53,120,196,1) 47%, rgba(66,152,219,1) 100%)',
};
