import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formMarca } from '../../../data/dataNumbering';
// import { useDepartments } from '../../../hooks/useDepartments';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import useNumbering from '../../../hooks/useNumbering';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { useDispatch } from 'react-redux';
import { isModalOpenActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const MarcaForm = ({ crudType }) => {
  const { listNumberingForm, updateMarca, createMarca, arrayMarcasHook, formMarcaReducer } = useNumbering();
  // const { departmentsHook } = useDepartments();
  const { getMarcas } = useNumbering();
  const { typeSwal } = useSwal();
  const { isModalOpen } = useAppSelector((state) => state.system);

  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);

  const data = formMarca;

  useEffect(() => {
    // getMarcas();
  }, []);

  useEffect(() => {
    listNumberingForm({ action: 'clearForm' }, { form: 'marca' });

    if (crudType === 'edit') {
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        { form: 'marca', element: 'id' }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.marca,
        },
        { form: 'marca', element: 'marca' }
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayMarcasHook === 'object' && arrayMarcasHook.length > 0) {
        if (crudType === 'edit' && (formMarcaReducer.marca === '' || formMarcaReducer.marca !== location.state?.marca)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((step) => {
            step.input.map((item) => {
              switch (item.ref.element) {
                case 'id':
                  item.defaultValues = formMarcaReducer.id;
                  break;
                case 'marca':
                  item.defaultValues = formMarcaReducer.marca;

                  break;
                default:
                  break;
              }
              return item;
            });
            return step;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((step) => {
            step.input.map((item) => {
              switch (item.ref.element) {
                case 'marca':
                  item.defaultValues = '';

                  break;
                default:
                  break;
              }
              return item;
            });
            return step;
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter]);

  const handleOnCancel = () => {
    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getMarcas, `/${routesWide.numbering}/${routesNumbering.marcasList}`, 'marca', createMarca);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', listNumberingForm, getMarcas, `/${routesWide.numbering}/${routesNumbering.marcasList}`, 'marca', updateMarca);
    }
  };

  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          data={data}
          onSubmit={onSubmit}
          onCancel={handleOnCancel}
          form={listNumberingForm}
          dataSave={dataSave}
          dataTabs={data.tabs || data.steps}
          // initialData={EMFormListExtensions}
          crudType={crudType}
          arrScreen={['Marca']}
        />
      )}
    </div>
  );
};

MarcaForm.propTypes = { crudType: string };
