import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateWhitelist } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';

const WhiteListForm = ({ crudType }) => {
  const { settingsForm, updateWhitelist, getWhitelist, createWhitelist, whitelistForm } = useSettings();

  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);

  const data = formCreateWhitelist;

  useEffect(() => {
    settingsForm({ action: 'clearForm' }, { form: 'whiteList' });
    if (crudType === 'edit') {
      settingsForm({ action: 'id', value: location.state?.id }, { form: 'whitelist', element: 'id' });
      settingsForm({ action: 'input', value: location.state?.ip_addr }, { form: 'whitelist', element: 'ip_addr' });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (crudType === 'edit' && (location.state?.ipAddr === '' || location.state?.ip_addr !== whitelistForm.ipAddr)) {
        setLoadingCounter(loadingCounter + 1);
      } else if (crudType === 'edit') {
        data.steps.map((item) => {
          item.input.map((item2) => {
            switch (item2.ref.element) {
              case 'ip_addr':
                item2.defaultValues = location.state?.ip_addr;
                break;
              default:
                break;
            }
            return item2;
          });
          return item;
        });
        setLoading(false);
      } else if (crudType === 'create') {
        setLoading(false);
        data.steps.map((item) => {
          // itemTab.steps.map((item) => {
          item.input.map((item2) => {
            item2.defaultValues = '';
          });
          // });
        });
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', settingsForm, getWhitelist, `/${routesWide.settings}/${routesSettings.whitelist}`, 'whiteList', createWhitelist);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', settingsForm, getWhitelist, `/${routesWide.settings}/${routesSettings.whitelist}`, 'whiteList', updateWhitelist);
    }
  };

  return (
    <>
      {loading ? <LoaderSpinner /> : <FormScreen onCancel={handleOnCancel} arrScreen={['Lista blanca']} data={data} onSubmit={onSubmit} form={settingsForm} dataSave={dataSave} crudType={crudType} />}
    </>
  );
};

WhiteListForm.propTypes = { crudType: string };

export default WhiteListForm;
