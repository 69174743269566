import { useCallback, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  addApplicationsLocutionsFormFile,
  addApplicationsLocutionsFormHeadquarters,
  addConferenceFormCompanyAction,
  addIvrFormCompanyAction,
  addIvrFormDestinationAction,
  addIvrFormSoundPromptAction,
  addMailboxesFormCompanyAction,
  addMailboxesFormSoundPromptAction,
  // addQueueFormMohIdAction,
  addQueueFormAgentsAction,
  // addQueueFormCompanyIdAction,
  addQueueFormDestinationAction,
  // addQueueFormMusicClassAction,
  // addQueueFormPreferencesAction,
  // addQueueFormSoundPromptAction,
  // addQueueFormStrategyAction,
  addShortMarkingFormCompanyAction,
  addSongWaitingFormCompanyAction,
  addSongWaitingFormSoundPromptAction,
  changeApplicationsLocutionsFormId,
  changeApplicationsLocutionsFormName,
  changeConferenceFormIdAction,
  changeConferenceFormNameAction,
  changeConferenceFormNumberAction,
  changeConferenceFormPinAction,
  changeConferenceFormRecordAction,
  changeIvrFormIdAction,
  changeIvrFormNameAction,
  changeMailboxesFormAstVoicemailIdAction,
  changeMailboxesFormEmailAction,
  changeMailboxesFormIdAction,
  changeMailboxesFormNameAction,
  changeMailboxesFormNumberAction,
  changeMailboxesFormPinAction,
  changeQueueFormAgentIsActiveAction,
  changeQueueFormAgentIsExternalAction,
  // changeQueueFormCallToUseExtensionAction,
  changeQueueFormIdAction,
  // changeQueueFormIdAstQueueAction,
  // changeQueueFormMaxWaitingUsersAction,
  changeQueueFormNameAction,
  changeQueueFormNumberAction,
  // changeQueueFormRetryAction,
  // changeQueueFormSoundpromptIdAction,
  // changeQueueFormStrategyAction,
  changeQueueFormisvirtualAction,
  // changeQueueFormTimeoutPriorityAction,
  // changeQueueFormTimeoutQueueAction,
  changeShortMarkingFormDestinoAction,
  changeShortMarkingFormIdAction,
  changeShortMarkingFormNumCortoAction,
  changeShortMarkingNombreAction,
  changeSongWaitingFormIdAction,
  changeSongWaitingFormNameAction,
  clearConferenceFormAction,
  clearIvrFormAction,
  clearLocutionsFormAction,
  clearMailboxesFormAction,
  clearQueueFormAction,
  clearQueueFormCompanyIdAction,
  clearQueueFormDestinationAction,
  clearQueueFormMusicClassAction,
  clearShortMarkingFormAction,
  clearSongWaitingFormAction,
  clearSongWaitingFormSoundPromptAction,
  removeApplicationsLocutionsFormHeadquarters,
  removeConferencesCompanyAction,
  removeIvrCompanyAction,
  removeIvrFormDestinationAction,
  removeIvrSoundPromptAction,
  removeMailboxesCompanyAction,
  removeMailboxesSoundPromptAction,
  removeQueueFormAgentDeleteAction,
  removeShortMarkingCompanyAction,
  removeSongWaitingFormCompanyAction,
  removeSongWaitingFormSoundPromptAction,
  clearQueueFormNameAction,
  changeQueueFormTimeRingAction,
  changeQueueFormMaxLengthAction,
  changeQueueFormTimeoutHangAction,
  changeQueueFormRetryAction,
  changeQueueFormisvRecordAction,
  changeQueueFormisWelcomeAction,
  addQueueFormSurveyAction,
  addQueueFormPromptDestinationAction,
  addQueueFormAlertinfoAction,
  changeQueueFormisSurveyAction,
  changeQueueFormExtensionIdAction,
  addQueueFormExtensionsAction,
  addQueueFormFallOverAction,
  removeQueueFormAgentAction,
  changeQueueFormStrategyIdAction,
  changeQueueFormmaxWaitTimeAction,
  changeQueueFormisPromptAction,
  addQueueFormCustomPromptIdAction,
  addQueueFormCustomWelcomeIdAction,
  changeQueueFormqueueMaxWaitTimeAction,
  changeQueueFormisAgentLogAction,
  changeQueueFormisDisplayNameEditedAction,
  changeQueueFormdisplayNameEditedAction,
  changeQueueFormisCallerAnnouceAction,
  changeQueueFormannounceIntervalAction,
  changeQueueFormannouceWaitTimeAction,
  addQueueFormleaveWhenEmptyAction,
  addQueueFormdialEmptyAction,
  changeQueueFormisHoldLogAction,
  changeQueueFormMohIdAction,
  addQueueFormFallOverTypeAction,
  addQueueFormDestinationTypeAction,
  addQueueFormDestinationIdAction,
  addQueueFormDestinationTypeIdAction,
  addQueueFormPromptDestinationIdAction,
  addQueueFormPromptDestinationTypeIdAction,
  addQueueFormPromptDestinationTypeAction,
  addQueueFormFallOverIdAction,
  addQueueFormFallOverTypeIdAction,
} from '../redux/actions/applications/actionCreators';
import {
  createApplicationsLocutionsThunk,
  updateApplicationsLocutionsThunk,
  deleteAPPLocutionsThunk,
  getApplicationsLocutionsThunk,
  getConferencesThunk,
  createConferenceThunk,
  updateConferenceThunk,
  deleteConferenceThunk,
  getMailboxesThunk,
  createMailboxesThunk,
  updateMailboxesThunk,
  deleteMailboxesThunk,
  createSongWaitingThunk,
  updateSongWaitingThunk,
  deleteSongWaitingThunk,
  getShortMarkingThunk,
  createShortMarkingThunk,
  updateShortMarkingThunk,
  deleteShortMarkingThunk,
  uploadFileLocutionsThunk,
  uploadFileConferenceThunk,
  uploadFileMailboxesThunk,
  uploadFileSongWaitingThunk,
  uploadFileShortMarkingThunk,
  getSongWaitingLinkThunk,
  getAudioLocutionsThunk,
  createIvrThunk,
  updateIvrThunk,
  deleteIvrThunk,
  getDestinationThunk,
  getQueueThunk,
  createQueueThunk,
  updateQueueThunk,
  deleteQueueThunk,
  uploadFileQueueThunk,
  getIvrsThunk,
} from '../redux/thunks/applicationsThunks';

const useApplications = () => {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const [loading, setLoading] = useState(true);

  //locutions
  const arrayLocutionsHook = useSelector((locutions) => locutions.getApplicationsLocutions);
  const applicationsFormLocutions = useSelector((locutions) => locutions.arrayApplicationsFormLocutions);
  const objectCreateAPPFormLocutions = useSelector((locutions) => locutions.objectCreateAPPFormLocutions);

  const audios = useSelector((item) => item.getAudiosReducer);

  const getApplicationsLocutions = useCallback(() => {
    let response = dispatch(getApplicationsLocutionsThunk(setLoading));
    return response;
  }, [dispatch]);

  const createApplicationsLocutions = useCallback(() => {
    const locutions = getState()['objectCreateAPPFormLocutions'];
    let response = dispatch(createApplicationsLocutionsThunk(locutions, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateApplicationsLocutions = useCallback(() => {
    const locutions = getState()['objectCreateAPPFormLocutions'];
    let response = dispatch(updateApplicationsLocutionsThunk(locutions, setLoading));
    return response;
  }, [dispatch, getState]);

  const getAudioLocutions = useCallback(() => {
    let response = dispatch(getAudioLocutionsThunk(setLoading));
    return response;
  }, [dispatch]);

  const deleteAPPLocutions = useCallback(
    (locution) => {
      let response = dispatch(deleteAPPLocutionsThunk(locution, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileLocutions = useCallback(
    (file) => {
      let response = dispatch(uploadFileLocutionsThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //conferences

  const conferences = useSelector((item) => item.getConferencesReducer);
  const conferenceForm = useSelector((item) => item.conferenceFormReducer);

  const getConferences = useCallback(() => {
    dispatch(getConferencesThunk(setLoading));
  }, [dispatch]);

  const createConference = useCallback(() => {
    const conference = getState()['conferenceFormReducer'];
    let response = dispatch(createConferenceThunk(conference, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateConference = useCallback(() => {
    const conference = getState()['conferenceFormReducer'];
    let response = dispatch(updateConferenceThunk(conference, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteConference = useCallback(
    (conference) => {
      let response = dispatch(deleteConferenceThunk(conference, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileConferences = useCallback(
    (file) => {
      let response = dispatch(uploadFileConferenceThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //mailboxes

  const mailboxes = useSelector((item) => item.getMailboxesReducer);
  const mailboxesForm = useSelector((item) => item.mailboxesFormReducer);

  const getMailboxes = useCallback(() => {
    dispatch(getMailboxesThunk(setLoading));
  }, [dispatch]);

  const createMailboxes = useCallback(() => {
    const mailboxes = getState()['mailboxesFormReducer'];
    let response = dispatch(createMailboxesThunk(mailboxes, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateMailboxes = useCallback(() => {
    const mailboxes = getState()['mailboxesFormReducer'];
    let response = dispatch(updateMailboxesThunk(mailboxes, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteMailboxes = useCallback(
    (mailboxes) => {
      let response = dispatch(deleteMailboxesThunk(mailboxes, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileMailboxes = useCallback(
    (file) => {
      let response = dispatch(uploadFileMailboxesThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //songWaiting

  const songWaiting = useSelector((item) => item.getSongWaitingReducer);
  const songWaitingLink = useSelector((item) => item.getSongWaitingLinkReducer);
  const songWaitingForm = useSelector((item) => item.songWaitingFormReducer);

  const getSongWaitingLink = useCallback(() => {
    dispatch(getSongWaitingLinkThunk(setLoading));
  });

  const createSongWaiting = useCallback(() => {
    const songWaiting = getState()['songWaitingFormReducer'];
    let response = dispatch(createSongWaitingThunk(songWaiting, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateSongWaiting = useCallback(() => {
    const songWaiting = getState()['songWaitingFormReducer'];
    let response = dispatch(updateSongWaitingThunk(songWaiting, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteSongWaiting = useCallback(
    (songWaiting) => {
      let response = dispatch(deleteSongWaitingThunk(songWaiting, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileSongWaiting = useCallback(
    (file) => {
      let response = dispatch(uploadFileSongWaitingThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //shortMarking

  const shortMarking = useSelector((item) => item.getShortMarkingReducer);
  const shortMarkingForm = useSelector((item) => item.shortMarkingFormReducer);

  const getShortMarking = useCallback(() => {
    dispatch(getShortMarkingThunk(setLoading));
  }, [dispatch]);

  const createShortMarking = useCallback(() => {
    const shortMarking = getState()['shortMarkingFormReducer'];
    let response = dispatch(createShortMarkingThunk(shortMarking, setLoading));
    return response;
  }, [getState, dispatch]);

  const updateShortMarking = useCallback(() => {
    const shortMarking = getState()['shortMarkingFormReducer'];
    let response = dispatch(updateShortMarkingThunk(shortMarking, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteShortMarking = useCallback(
    (shortMarking) => {
      let response = dispatch(deleteShortMarkingThunk(shortMarking, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileShortMarking = useCallback(
    (file) => {
      let response = dispatch(uploadFileShortMarkingThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //ivr

  const ivr = useSelector((item) => item.getIvrReducer);
  const ivrForm = useSelector((item) => item.ivrFormReducer);

  const getIvr = useCallback(() => {
    dispatch(getIvrsThunk(setLoading));
  }, [dispatch]);

  const createIvr = useCallback(() => {
    const conference = getState()['ivrFormReducer'];
    let response = dispatch(createIvrThunk(conference, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateIvr = useCallback(() => {
    const conference = getState()['ivrFormReducer'];
    let response = dispatch(updateIvrThunk(conference, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteIvr = useCallback(
    (ivr) => {
      let response = dispatch(deleteIvrThunk(ivr, setLoading));
      return response;
    },
    [dispatch]
  );

  const destination = useSelector((item) => item.getDestinationReducer);

  const getDestination = useCallback(() => {
    dispatch(getDestinationThunk(setLoading));
  }, [dispatch]);

  //Queue

  const queues = useSelector((item) => item.getQueueReducer);
  const queuesForm = useSelector((item) => item.queueFormReducer);

  const getQueues = useCallback(() => {
    dispatch(getQueueThunk(setLoading));
  }, [dispatch]);

  const createQueue = useCallback(() => {
    const queue = getState()['queueFormReducer'];
    let response = dispatch(createQueueThunk(queue, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateQueue = useCallback(() => {
    const queue = getState()['queueFormReducer'];
    let response = dispatch(updateQueueThunk(queue, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteQueue = useCallback(
    (queue) => {
      let response = dispatch(deleteQueueThunk(queue, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileQueue = useCallback(
    (file) => {
      let response = dispatch(uploadFileQueueThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //Form Applications

  const applicationsForm = useCallback((arg, ref, inputValue) => {
    if (arg.action) {
      switch (ref.form) {
        case 'locution':
          switch (arg.action) {
            case 'clear':
              dispatch(clearLocutionsFormAction());
              break;
            case 'select-option':
              dispatch(addApplicationsLocutionsFormHeadquarters(inputValue));
              break;
            case 'remove-value':
              dispatch(removeApplicationsLocutionsFormHeadquarters(arg.removedValue?.value));
              break;
            case 'input':
              if (ref.element === 'description') {
                dispatch(changeApplicationsLocutionsFormName(arg.value));
              }
              if (ref.element === 'id') {
                dispatch(changeApplicationsLocutionsFormId(arg.value));
              }
              break;
            case 'dropzone':
              if (inputValue !== undefined) {
                dispatch(addApplicationsLocutionsFormFile(inputValue));
              } else {
                dispatch(addApplicationsLocutionsFormFile(arg.value));
              }
              break;
            default:
              break;
          }
          break;
        case 'conference':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearConferenceFormAction());
              break;
            case 'select-option':
              dispatch(addConferenceFormCompanyAction(inputValue));
              break;
            case 'clear':
              if (ref.element === 'company') {
                dispatch(removeConferencesCompanyAction());
              }
              break;

            case 'toggle':
              if (ref.element === 'record') {
                dispatch(changeConferenceFormRecordAction(arg.value));
              }
              break;
            case 'input':
              switch (ref.element) {
                case 'name':
                  dispatch(changeConferenceFormNameAction(arg.value));
                  break;
                case 'number':
                  dispatch(changeConferenceFormNumberAction(arg.value));
                  break;
                case 'pinConf':
                  dispatch(changeConferenceFormPinAction(arg.value));
                  break;
                case 'id':
                  dispatch(changeConferenceFormIdAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          break;
        case 'mailboxes':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearMailboxesFormAction());
              break;
            case 'clear':
              if (ref.element === 'company') {
                dispatch(removeMailboxesCompanyAction());
              }

              if (ref.element === 'soundPrompt') {
                dispatch(removeMailboxesSoundPromptAction());
              }
              break;
            case 'select-option':
              switch (ref.element) {
                case 'company':
                  dispatch(addMailboxesFormCompanyAction(inputValue));
                  break;
                case 'soundPrompt':
                  dispatch(addMailboxesFormSoundPromptAction(inputValue));
                  break;
                default:
                  break;
              }
              break;
            case 'input':
              switch (ref.element) {
                case 'number':
                  dispatch(changeMailboxesFormNumberAction(arg.value));
                  break;
                case 'name':
                  dispatch(changeMailboxesFormNameAction(arg.value));
                  break;
                case 'pin':
                  dispatch(changeMailboxesFormPinAction(arg.value));
                  break;
                case 'email':
                  dispatch(changeMailboxesFormEmailAction(arg.value));
                  break;
                case 'id':
                  dispatch(changeMailboxesFormIdAction(arg.value));
                  break;
                case 'astVoicemailId':
                  dispatch(changeMailboxesFormAstVoicemailIdAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          break;

        case 'songWaiting':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearSongWaitingFormAction());
              break;
            case 'clear':
              if (ref.element === 'company') {
                dispatch(removeSongWaitingFormCompanyAction());
              }
              if (ref.element === 'soundPrompt') {
                dispatch(clearSongWaitingFormSoundPromptAction());
              }
              break;
            case 'multiselect':
              switch (ref.element) {
                // case 'extensionId':
                //   dispatch(addQueueFormExtensionsAction(inputValue));
                //   break;
                case 'soundPrompt':
                  dispatch(addSongWaitingFormSoundPromptAction(inputValue ? inputValue : arg.option ? { value: arg.option?.value, label: arg.option?.label } : arg.value));
                  // arg.value ? arg.value : inputValue));
                  // dispatch(changeQueueFormExtensionAction(arg.value || inputValue));
                  break;
                default:
                  break;
              }
              break;
            case 'select-option':
              switch (ref.element) {
                case 'company':
                  dispatch(addSongWaitingFormCompanyAction(inputValue ? inputValue : arg.option));
                  break;
                // case 'soundPrompt':
                //   dispatch(addSongWaitingFormSoundPromptAction(arg.option));

                // arg.option ? { value: Number(arg.option?.value), label: arg.option?.label } : arg.value[0] || { value: Number(inputValue.value), label: inputValue.label };
                case 'soundPrompt':
                  dispatch(
                    addSongWaitingFormSoundPromptAction(arg.option ? { value: arg.option?.value, label: arg.option?.label } : arg.value || { value: inputValue.value, label: inputValue.label })
                  );
                  break;

                default:
                  break;
              }
              break;

            case 'remove-value':
              dispatch(removeSongWaitingFormSoundPromptAction(inputValue));
              // arg.removedValue;
              break;
            case 'input':
              switch (ref.element) {
                case 'name':
                  dispatch(changeSongWaitingFormNameAction(arg.value));
                  break;

                case 'id':
                  dispatch(changeSongWaitingFormIdAction(arg.value));
                  break;
                default:
                  break;
              }
              break;

            default:
              break;
          }
          break;
        case 'shortMarking':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearShortMarkingFormAction());
              break;
            case 'select-option':
              dispatch(addShortMarkingFormCompanyAction(inputValue));
              break;
            case 'clear':
              if (ref.element === 'company') {
                dispatch(removeShortMarkingCompanyAction());
              }
              break;

            case 'input':
              switch (ref.element) {
                case 'nombre':
                  dispatch(changeShortMarkingNombreAction(arg.value));
                  break;
                case 'num_corto':
                  dispatch(changeShortMarkingFormNumCortoAction(arg.value));
                  break;
                case 'destino':
                  dispatch(changeShortMarkingFormDestinoAction(arg.value));
                  break;
                case 'id':
                  dispatch(changeShortMarkingFormIdAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          break;
        case 'ivr':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearIvrFormAction());
              break;
            case 'clear':
              if (ref.element === 'company') {
                dispatch(removeIvrCompanyAction());
              }
              if (ref.element === 'soundPrompt') {
                dispatch(removeIvrSoundPromptAction());
              }
              break;
            case 'select-option':
              switch (ref.element) {
                case 'company':
                  dispatch(addIvrFormCompanyAction(inputValue));
                  break;
                case 'soundPrompt':
                  dispatch(addIvrFormSoundPromptAction(inputValue));
                  break;
                default:
                  break;
              }
              break;
            case 'input':
              switch (ref.element) {
                case 'name':
                  dispatch(changeIvrFormNameAction(arg.value));
                  break;
                case 'id':
                  dispatch(changeIvrFormIdAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            case 'addDestination':
              dispatch(addIvrFormDestinationAction(inputValue));
              break;
            case 'removeDestination':
              dispatch(removeIvrFormDestinationAction(inputValue));
              break;
            default:
              break;
          }
          break;
        case 'queue':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearQueueFormAction());
              break;
            case 'remove-value':
              dispatch(removeQueueFormAgentAction(inputValue));
              break;
            case 'clear':
              switch (ref.element) {
                case 'companyId':
                  dispatch(clearQueueFormCompanyIdAction());
                  break;
                case 'musicclass':
                  dispatch(clearQueueFormMusicClassAction());
                  break;
                case 'destination':
                  dispatch(clearQueueFormDestinationAction());
                  break;
                case 'name':
                  dispatch(clearQueueFormNameAction());
                  break;
                default:
                  break;
              }
              break;
            case 'input':
              switch (ref.element) {
                case 'id':
                  dispatch(changeQueueFormIdAction(arg.value));
                  break;
                case 'name':
                  dispatch(changeQueueFormNameAction(arg.value));
                  break;
                case 'number':
                  dispatch(changeQueueFormNumberAction(arg.value));
                  break;
                case 'time_ring':
                  dispatch(changeQueueFormTimeRingAction(arg.value));
                  break;
                case 'timeHangup':
                  dispatch(changeQueueFormTimeoutHangAction(arg.value));
                  break;
                case 'time_retry':
                  dispatch(changeQueueFormRetryAction(arg.value));
                  break;
                case 'queueMaxLength':
                  dispatch(changeQueueFormMaxLengthAction(arg.value));
                  break;
                case 'maxWaitTime':
                  dispatch(changeQueueFormmaxWaitTimeAction(arg.value));
                  break;
                case 'queueMaxWaitTime':
                  dispatch(changeQueueFormqueueMaxWaitTimeAction(arg.value));
                  break;
                case 'displayNameEdited':
                  dispatch(changeQueueFormdisplayNameEditedAction(arg.value));
                  break;
                case 'annouceWaitTime':
                  dispatch(changeQueueFormannouceWaitTimeAction(arg.value));
                  break;
                case 'destinationId':
                  dispatch(addQueueFormDestinationIdAction(arg.value));
                  break;
                case 'destinationTypeId':
                  dispatch(addQueueFormDestinationTypeIdAction(arg.value));
                  break;
                case 'promptDestinationId':
                  dispatch(addQueueFormPromptDestinationIdAction(arg.value));
                  break;
                case 'promptDestinationTypeId':
                  dispatch(addQueueFormPromptDestinationTypeIdAction(arg.value));
                  break;
                // case 'promptDestination':
                //   dispatch(addQueueFormPromptDestinationAction(arg.value));
                //   break;
                // case 'promptDestinationType':
                //   dispatch(addQueueFormPromptDestinationTypeAction(arg.value));
                //   break;
                case 'announceInterval':
                  dispatch(changeQueueFormannounceIntervalAction(arg.value));
                  break;
                case 'falloverDestinationId':
                  dispatch(addQueueFormFallOverIdAction(arg.value));
                  break;
                case 'falloverDestinationTypeId':
                  dispatch(addQueueFormFallOverTypeIdAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            case 'multiselect':
              switch (ref.element) {
                case 'extensions':
                  dispatch(
                    addQueueFormExtensionsAction(
                      inputValue ? inputValue : arg?.option ? { value: arg?.option?.value, label: arg?.option?.label } : arg?.value || { value: inputValue?.value, label: inputValue?.label }
                    )
                  );

                  break;
                default:
                  break;
              }
              break;
            case 'select-option':
              switch (ref.element) {
                case 'number':
                  dispatch(changeQueueFormNumberAction(arg.value));
                  break;
                case 'destinations':
                  dispatch(addQueueFormDestinationAction(arg.value));
                  break;
                case 'extensionId':
                  dispatch(changeQueueFormExtensionIdAction(arg.value ? arg.value : { value: Number(inputValue.value), label: inputValue.label }));
                  break;
                case 'extensions':
                  dispatch(addQueueFormExtensionsAction(arg.option ? { value: arg.option?.value, label: arg.option?.label } : arg.value || { value: inputValue.value, label: inputValue.label }));
                  break;
                case 'strategyId':
                  dispatch(changeQueueFormStrategyIdAction(arg.option ? { value: arg.option?.value, label: arg.option?.label } : arg.value || { value: inputValue?.value, label: inputValue?.label }));
                  break;
                case 'mohId':
                  dispatch(
                    changeQueueFormMohIdAction(
                      arg.option
                        ? { value: arg.option?.value, label: arg.option?.label }
                        : arg.value
                          ? { value: arg.value.id ? arg.value.id : arg.value, label: arg.value.name ? arg.value.name : arg.value }
                          : { value: inputValue.value, label: inputValue.label }
                    )
                  );
                  break;
                case 'survey_soundpromptId':
                  dispatch(addQueueFormSurveyAction(inputValue));
                  break;
                case 'alertinfo':
                  dispatch(addQueueFormAlertinfoAction(inputValue));
                  break;
                case 'customWelcomeId':
                  dispatch(addQueueFormCustomWelcomeIdAction(inputValue));
                  break;
                case 'customPromptId':
                  dispatch(addQueueFormCustomPromptIdAction(inputValue));
                  break;
                case 'destinationId':
                  dispatch(addQueueFormDestinationIdAction(inputValue || arg.value));
                  break;
                case 'destinationTypeId':
                  dispatch(addQueueFormDestinationTypeIdAction(inputValue || arg.value));
                  break;
                case 'Destination':
                  dispatch(addQueueFormDestinationAction(inputValue));
                  break;
                case 'DestinationType':
                  dispatch(addQueueFormDestinationTypeAction(inputValue));
                  break;

                case 'leaveWhenEmpty':
                  dispatch(addQueueFormleaveWhenEmptyAction(inputValue));
                  break;
                case 'dialEmpty':
                  dispatch(addQueueFormdialEmptyAction(inputValue));
                  break;

                // case 'alertinfo':
                //   dispatch(addQueueFormStrategyAction(inputValue));
                //   break;
                // case 'promptDestinationId':
                //   dispatch(addQueueFormPromptDestinationAction(inputValue));
                //   break;
                case 'promptDestination':
                  dispatch(addQueueFormPromptDestinationAction(inputValue));
                  break;
                case 'promptDestinationType':
                  dispatch(addQueueFormPromptDestinationTypeAction(inputValue));
                  break;
                case 'FalloverDestination':
                  dispatch(addQueueFormFallOverAction(inputValue));
                  break;
                case 'FalloverDestinationType':
                  dispatch(addQueueFormFallOverTypeAction(inputValue));
                  break;
                default:
                  break;
              }
              break;
            case 'toggle':
              switch (ref.element) {
                case 'isvirtual':
                  dispatch(changeQueueFormisvirtualAction(arg.value));
                  break;
                case 'isRecord':
                  dispatch(changeQueueFormisvRecordAction(arg.value));
                  break;
                case 'isCustomWelcome':
                  dispatch(changeQueueFormisWelcomeAction(arg.value));
                  break;
                case 'isSurvey':
                  dispatch(changeQueueFormisSurveyAction(arg.value));
                  break;
                case 'isCustomPrompt':
                  dispatch(changeQueueFormisPromptAction(arg.value));
                  break;
                case 'isAgentLog':
                  dispatch(changeQueueFormisAgentLogAction(arg.value));
                  break;
                case 'isDisplayNameEdited':
                  dispatch(changeQueueFormisDisplayNameEditedAction(arg.value));
                  break;
                case 'isCallerAnnouce':
                  dispatch(changeQueueFormisCallerAnnouceAction(arg.value));
                  break;
                case 'isHoldLog':
                  dispatch(changeQueueFormisHoldLogAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            case 'destinations':
              dispatch(addQueueFormDestinationAction(inputValue));
              break;

            case 'agents':
              switch (ref.element) {
                case 'addButton':
                  dispatch(addQueueFormAgentsAction(inputValue));
                  break;
                case 'toggleButton':
                  dispatch(changeQueueFormAgentIsActiveAction(inputValue));
                  break;
                case 'switchButton':
                  dispatch(changeQueueFormAgentIsExternalAction(inputValue));
                  break;
                case 'delete':
                  dispatch(removeQueueFormAgentDeleteAction(inputValue));
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  });

  return {
    //locutions
    arrayLocutionsHook,
    getApplicationsLocutions,
    createApplicationsLocutions,
    updateApplicationsLocutions,
    applicationsForm,
    objectCreateAPPFormLocutions,
    deleteAPPLocutions,
    uploadFileLocutions,
    getAudioLocutions,
    audios,
    //formApplications
    applicationsFormLocutions,

    //conferences
    conferences,
    getConferences,
    createConference,
    updateConference,
    deleteConference,
    conferenceForm,
    uploadFileConferences,
    //mailboxes
    mailboxes,
    getMailboxes,
    mailboxesForm,
    createMailboxes,
    updateMailboxes,
    deleteMailboxes,
    uploadFileMailboxes,

    //songWaiting
    songWaiting,
    songWaitingLink,
    // getSongWaiting,
    getSongWaitingLink,
    songWaitingForm,
    createSongWaiting,
    updateSongWaiting,
    deleteSongWaiting,
    uploadFileSongWaiting,

    //shortMarking
    shortMarking,
    getShortMarking,
    shortMarkingForm,
    createShortMarking,
    deleteShortMarking,
    updateShortMarking,
    uploadFileShortMarking,

    //loading
    loading,

    //ivr
    ivr,
    getIvr,
    ivrForm,
    createIvr,
    updateIvr,
    deleteIvr,
    getDestination,
    destination,

    //queue
    queues,
    getQueues,
    createQueue,
    updateQueue,
    deleteQueue,
    uploadFileQueue,
    queuesForm,
  };
};

export default useApplications;
