import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useSettings from '../../../hooks/useSettings';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';

// import { CardGird } from '../../../components/CardGrid/CardGird';
// import { dataScreenSettings } from '../../../data/dataSettings';

import '../../../styles/Screens/SettingsScreen/PermissionsScreen.css';
import { PermissionsWrapper } from './PermissionsScreen.style';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { dataScreenPermissions } from '../../../data/dataSettings';
import { useSwal } from '../../../hooks/useSwal';
import { selectedDocActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { useDispatch } from 'react-redux';
// import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
// import { NavTable } from '../../../components/NavTable/NavTable';
import { routesSettings, routesWide } from '../../../navigation/routes';

const PermissionsScreen = () => {
  const { privileges, roles, users, getRoles, getPrivileges, getUsers } = useSettings();
  const { typeSwal } = useSwal();
  // const [isFilter, setIsFilter] = useState({
  //   open: false,
  //   active: false,
  // });

  const [checkBoxesActives, setCheckboxesActives] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { notFound } = dataScreenPermissions;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    getPrivileges();
    getRoles();
    getUsers();
  }, [getPrivileges, getRoles]);

  useEffect(() => {
    setTimeout(() => {
      if (privileges.length === 0 || roles.length === 0 || users.length === 0) {
        setLoadingCounter(loadingCounter + 1);
      } else {
        setLoading(false);
      }
    }, 100);
  }, [loadingCounter]);

  const handleCreatePermission = () => {
    // if (data.title !== 'Superadmin') {

    navigate('/settings/permissions/create');
    // }
  };

  const handleOpenFormEdit = (data) => {
    // if (data.title !== 'Superadmin') {
    const selectedDoc = { name: data.original ? data.original.name : data.name, id: Number(data.id) };
    dispatch(selectedDocActionCreator(selectedDoc));
    navigate('/settings/permissions/edit', { state: data.original });
    // }
  };

  // const handleOpenFormEdit = (item) => {
  //   const selectedDoc = { name: item.original ? item.original.name : item.name, id: Number(item.id) };
  //   dispatch(selectedDocActionCreator(selectedDoc));
  //   navigate('/settings/permissions/update', { state: item.original });
  // };

  const handlePermissionDelete = () => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (import.meta.env.VITE_DEBUG === 'true') console.log('Asked to delete permission');
        } catch (err) {
          if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
        }
      }
    });
  };

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
  ];

  const DATA = roles.map(({ id, name }) => ({
    id,
    name,
  }));

  const tableInstance = useRef(null);

  const dataExport = privileges.map(({ name }) => ({
    name,
  }));

  const template = [
    {
      name: '',
    },
  ];

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.settings}/${routesSettings.permissionsCreate}`, {});
  };

  return (
    <PermissionsWrapper className="permissions__screen">
      <div className={'contain__buttons'}>
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleCreatePermission,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },

            file: {
              position: 'right',
              data: dataExport,
              template,
              import: false,
            },
          }}
          setSearch={setSearch}
          checkboxesActives={checkBoxesActives}
          setCheckboxesActives={setCheckboxesActives}
          handleOpenFormCreate={handleOpenFormCreate}
          isNavTable={true}
          // totalDocs={whitelist.length}
        />
        {/* {!privileges[0]?.status ? (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataScreenPermissions} setSearch={setSearch} />
          </div>
        ) : null} */}
        {/* //<NavTable checkboxesActives={checkBoxesActives} totalDocs={roles.length} /> */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : users[0].status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            // updateFunction={handleOpenFormEdit}
            deleteFunction={handlePermissionDelete}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            // setIsBottom={setIsBottom}
            isNavTable={false}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
            setDataCard={() => {
              false;
            }}
          />
        </div>
      )}
    </PermissionsWrapper>
  );
};

export default PermissionsScreen;
