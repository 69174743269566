import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMenuOpen: true,
  isModalOpen: false,
  isSubMenuOpen: false,
  isMenuPinned: false,
  isRowDestEditable: false,
  isLocationModified: false,
  isDataModified: false,
  selectedDocs: {},
  selectedDoc: {},
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    isMenuOpen: (system, action) => ({
      ...system,
      isMenuOpen: action.payload,
    }),
    isModalOpen: (ui, action) => ({
      ...ui,
      isModalOpen: action.payload,
    }),
    isSubMenuOpen: (ui, action) => ({
      ...ui,
      isSubMenuOpen: action.payload,
    }),
    isMenuPinned: (system, action) => ({
      ...system,
      isMenuPinned: action.payload,
    }),
    isDataModified: (system, action) => ({
      ...system,
      isDataModified: action.payload,
    }),
    isLocationModified: (system, action) => ({
      ...system,
      isLocationModified: action.payload,
    }),
    selectedDocs: (system, action) => ({
      ...system,
      selectedDocs: action.payload,
    }),
    selectedDoc: (system, action) => ({
      ...system,
      selectedDoc: action.payload,
    }),
    isRowDestEditable: (system, action) => ({
      ...system,
      isRowDestEditable: action.payload,
    }),
  },
});

export const {
  isMenuOpen: isMenuOpenActionCreator,
  isMenuPinned: isMenuPinnedActionCreator,
  isModalOpen: isModalOpenActionCreator,
  isSubMenuOpen: isSubMenuOpenActionCreator,
  isDataModified: isDataModifiedActionCreator,
  isLocationModified: isLocationModifiedActionCreator,
  selectedDocs: selectedDocsActionCreator,
  selectedDoc: selectedDocActionCreator,
  isRowDestEditable: isRowDestEditableActionCreator,
} = systemSlice.actions;

export default systemSlice.reducer;
