import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// import { IconRainbow, IconSettings, IconVariable } from '@tabler/icons-react';
import { FormScreen } from '../FormScreen/FormScreen';
import { PageScreenWrapper } from './PageScreen.style';
import { array, bool, func, object, string } from 'prop-types';
// import { IconPhone } from '@tabler/icons-react';
// import { IconCloud } from '@tabler/icons-react';
// import { IconUsers } from '@tabler/icons-react';
import useNumbering from '../../hooks/useNumbering';
import { getIcon } from '../../utils/AppUtils';

// import { clearExtensionFormAction } from '../../redux/actions/numbering/actionCreator';
// import { ListNumberModalFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberModalFormScreen';
// import { ListNumberFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberFormScreen';
// import { ListNumberModalFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberModalFormScreen';
// import { optionsAllow } from '../../data/dataOutbound';
// import { getArrayFormatted } from '../../utils/AppUtils';
// import { useStore } from 'react-redux';
// import { usersFormReducer } from '../../redux/reducers/settingsReducers/userPosts/userReducer';

export const PageScreen = ({
  isEditMode,
  data,
  unSavedChanges,
  setUnSavedChanges,
  isModalOpen,

  isMenuOpen,
  // setIsModalOpen,
  // handleOpenFormCreate,
  onCancel,
  render,
  setRender,
  onSubmit,
  form,
  crudType,
  maxwidth,
  arrScreen,
  reducerForm,
  dataModalButtonToTable,
}) => {
  const [pageSelected, setPageSelected] = useState(1);
  // const navigate = useNavigate();
  // const navigate = useNavigate();
  const { getExtensions } = useNumbering();

  const [checkboxesActives, setCheckboxesActives] = useState([]);

  useEffect(() => {
    getExtensions();
  }, []);

  const handlePageSelect = (id) => {
    setRender(!render);
    setPageSelected(id);
  };

  // const icon = (tab) => {
  //   let icon = {};
  //   // const result = () => {
  //   switch (tab.text) {
  //     case 'Teams / Rainbow':
  //       icon = <IconRainbow />;
  //       break;
  //     case 'Sístema':
  //       icon = <IconSettings />;
  //       break;
  //     case 'Variables':
  //       icon = <IconVariable />;
  //       break;
  //     case 'SBC SIP/SJSIP':
  //       icon = <IconPhone />;
  //       break;
  //     default:
  //       // icon = <IconSettings />;
  //       icon = null;
  //       break;
  //   }

  //   return icon;
  // };

  // const COLUMNS = [

  //   {
  //     Header: ' ',
  //     accessor: '',

  //     Cell: ({ row }) =>
  //       row.original.typeExtension === 'rainbow' ? <IconRainbow /> : row.original?.typeExtension === 'virtual' ? <IconCloud /> : row.original?.typeExtension === 'SIP' ? <IconPhone /> : null,
  //   },

  //   {
  //     Header: 'Id.',
  //     accessor: 'identification',
  //   },
  //   {
  //     Header: 'Tipo',
  //     accessor: 'typeExtension',
  //   },
  // ];

  // const tableInstance = useRef(null);

  // const handleOpenFormEdit = (item) => {
  //   const newExt = arrayNumberingExtensionsHook?.filter((item2) => item2.Ddi.ddi === item.original.identification);

  //   navigate('/numbering/extensions/update', {
  //     state: newExt[0],
  //   });
  // };

  // const [isDropdownActive, setIsDropdownActive] = useState(false);
  // const [extensionCrudType, setExtensionCrudType] = useState('createUserDispo');

  // const handleClickAssign = () => {
  //   // typeSwal(type, name, hookForm, action, path, form, hookFunction);
  //   // setExtensionCrudType('createUserDispo');
  //   // location.state = item.original;
  //   setExtensionCrudType('assignUserDispo');
  //   setIsModalOpen(true);
  //   setIsDropdownActive(false);
  // };

  // const handleClickCreate = () => {

  //   setIsModalOpen(true);
  //   setIsDropdownActive(false);
  // };

  // const arrayRules = [];

  // const handleOnClickOut = (event) => {
  //   event.preventDefault();
  //   dispatch(isModalOpenActionCreator(true));
  //   dispatch(isModalOpenActionCreator(false));
  //   dispatch(clearExtensionFormAction());
  //   dispatch(selectedDocActionCreator({}));
  //   setIsDropdownActive(false);
  // };

  // const handleOnClickIn = (event) => {
  //   event.stopPropagation();
  // };

  // const { getState } = useStore();
  // const { isMenuOpen } = getState()['system'];

  // const option_Rules = arrayDispositivos.map(({ identification, typeExtension }) => ({
  //   identification,
  //   typeExtension,
  // }));

  //  const dataExport = Array.isArray(usersFormReducer)
  //    ? users.map(({ Company, Soundprompt, name }) => {
  //        return {
  //          Nombre: name,
  //          Locución: Soundprompt?.description,
  //          Sede: Company?.name,
  //        };
  //      })
  //    : [];

  // const COLUMNS_RULES = [
  //   {
  //     Header: ' ',
  //     accessor: 'isDefault',
  //   },
  //   {
  //     Header: 'Calendario',
  //     accessor: 'Calendario',
  //   },
  //   {
  //     Header: 'Horario',
  //     accessor: 'Horario',
  //   },
  //   {
  //     Header: 'Locución',
  //     accessor: 'Locucion',
  //   },
  //   // {
  //   //   Header: 'Destino',
  //   //   accessor: 'TipoDestino',
  //   // },
  //   {
  //     Header: 'Destino',
  //     accessor: 'Destino',
  //   },
  // ];

  // const handleEditRule = (item) => {
  //   // setNewCrudType('editRule');
  //   dispatch(selectedDocActionCreator(item.original));

  //   dispatch(isModalOpenActionCreator(true));
  // };

  // const handleOpenFormCreate = () => {
  //   dispatch(isModalOpenActionCreator(true));
  //   //  setNewCrudType('createRule');
  // };

  return (
    <PageScreenWrapper id="modalRoot" isMenuOpen={isMenuOpen} isModalOpen={isModalOpen}>
      {/* <FormTitle formTitle={['Config']} type={crudType} /> */}

      <div className="pageScreen">
        <div className="pageScreen__form">
          {data.tabs ? (
            <div className="pageScreen__tabs">
              {data.tabs.map((tab) => (
                <div className={`pageScreen__tabs__tab ${pageSelected === tab.id ? 'active' : 'inactive'}`} key={'tab__' + tab.id} onClick={() => handlePageSelect(tab.id)}>
                  {getIcon(tab, 'pageScreen')}
                  <p className={`pageScreen__tabs__tab__item`}>{tab.text}</p>
                </div>
              ))}
            </div>
          ) : null}

          <FormScreen
            arrScreen={arrScreen}
            data={data.tabs ? data.tabs[pageSelected - 1] : data}
            onSubmit={onSubmit}
            onCancel={onCancel}
            form={form}
            dataTabs={data.tabs || []}
            crudType={crudType}
            reducerForm={reducerForm}
            unSavedChanges={unSavedChanges}
            setUnSavedChanges={setUnSavedChanges}
            maxwidth={maxwidth}
            title={false}
            dataModalButtonToTable={dataModalButtonToTable}
            isEditMode={isEditMode}
            setCheckboxesActives={setCheckboxesActives}
            checkboxesActives={checkboxesActives}
          />
        </div>
      </div>
    </PageScreenWrapper>
  );
};

PageScreen.propTypes = {
  data: object,
  dataExport: object,
  render: bool,
  setRender: func,
  onSubmit: func,
  onCancel: func,
  form: func,
  crudType: string,
  maxwidth: string,
  arrScreen: array,
  reducerForm: object,
  dataModalButtonToTable: object,
  isEditMode: bool,
  row: string,
  checkboxesActives: func,
  setCheckboxesActives: func,
  handleOpenFormCreate: func,
  isModalOpen: bool,
  setIsModalOpen: func,
  isMenuOpen: bool,
  handleDelete: func,
  unSavedChanges: bool,
  setUnSavedChanges: func,
};
