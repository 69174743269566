import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const ToggleButtonWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 10px;

  &:hover {
    cursor: pointer;
  }

  .toggleButton {
    height: 100%;
    width: 550px;
    border: 1px solid ${colors.greyBorder};
    border-radius: 8px;
    display: flex;
    ${'' /* gap: 20px; */}
    ${
      '' /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    }
    ${'' /* background-color: ${colors.greyBorder}; */}
    padding: 2px;
    gap: 20px;

    svg {
      width: 40px;
      heigth: 40px;
    }

    &__left,
    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50%;
      ${'' /* gap: 20px; */}
      font-size: ${newFontSize.h4};
      font-weight: ${weight.regular};
      color: ${colors.primaryColor};
      ${'' /* background-color: ${colors.secondaryColorLight}; */}
      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }

    &__left {
      border-right: 1px solid ${colors.greyBorder};
      background-color: ${(props) => (props.isActive && props.twoButtonActive === 'false' ? colors.greenGYGAS : colors.secondaryColorLight)};
      border-radius: 6px;
      color: ${colors.primaryColor};
    }

    &__right {
      background-color: ${(props) => (props.isActive && props.twoButtonActive !== 'false' ? colors.greenGYGAS : colors.secondaryColorLight)};
      border-radius: 6px;
      color: ${colors.primaryColor};
    }
  }
`;
