import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateLocutions } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
// import AudioPlayer from '../../../components/AudioPlayer/AudioPlayer';
// import { useStore } from 'react-redux';
// import fs from 'react-native-fs';

export const LocutionsFormScreen = ({ crudType }) => {
  const { applicationsForm, createApplicationsLocutions, updateApplicationsLocutions, objectCreateAPPFormLocutions, getApplicationsLocutions } = useApplications();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { typeSwal } = useSwal();

  // const { getState } = useStore();
  // const {  } = getState();

  const location = useLocation();
  const [unSavedChanges, setUnSavedChanges] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState();
  const [dataSave, setDataSave] = useState(false);
  const [loading, setLoading] = useState(true);

  // const [isActive, setIsActive] = useState(location.state?.type);

  const data = formCreateLocutions;

  useEffect(() => {
    applicationsForm({ action: 'clear' }, { form: 'locution' });
  }, []);

  useEffect(() => {
    getHeadquarter();

    // if (crudType === 'edit') {
    applicationsForm(
      {
        action: 'input',
        value: location.state?.id || '',
      },
      {
        form: 'locution',
        element: 'id',
      }
    );

    applicationsForm(
      {
        action: 'input',
        value: location.state?.description || '',
      },
      {
        form: 'locution',
        element: 'description',
      }
    );
    applicationsForm(
      {
        action: 'select-option',
      },
      {
        form: 'locution',
        element: 'headquarters',
      },
      {
        label: location.state?.group || '',
        value: String(location.state?.companyId) || '',
      }
    );
    // RNFS.writeFileSync('file.ogg', Buffer.from(objectCreateAPPFormLocutions?.file.replace('data:audio/ogg; codecs=opus;base64,', ''), 'base64'));

    if (crudType !== 'create') {
      applicationsForm(
        {
          action: 'dropzone',
        },
        {
          form: 'locution',
          element: 'file',
        },
        {
          label: location.state?.filename,
          value: String(location.state?.id),
        }
      );
    }

    applicationsForm(
      {
        action: 'toggleButton',
        value: location.state?.type ? location.state?.type : false,
      },
      {
        form: 'locution',
        element: 'type',
      }
    );
    // }

    // setLoadingCounter(0);
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            item2.defaultValues = '';
            item2.options = [];
            if (item2.ref.element === 'headquarters') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            // if (item2.ref.element === 'headquarters') {
            //   item2.options = [];
            //   arrayHeadquarterHook.forEach((item6) => {
            //     const headquarter = {
            //       value: item6.id?.toString(),
            //       label: item6.label,
            //     };
            //     const found = arrayHeadquarterHook?.some((e) => e.id.toString() === headquarter.value);
            //     if (!found) {
            //       item2.options.push(headquarter);
            //     }
            //   });
            // }

            return item2;
          });
          return item;
        });

        if (crudType === 'edit' && (objectCreateAPPFormLocutions.locutionName === '' || objectCreateAPPFormLocutions.locutionName !== location.state?.description)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit' && objectCreateAPPFormLocutions.headquarters.label === location.state?.group) {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'description':
                  item2.defaultValues = objectCreateAPPFormLocutions.locutionName;
                  break;

                case 'file':
                  item2.defaultValues = objectCreateAPPFormLocutions.file;
                  break;

                case 'headquarters':
                  item2.options.filter((item3) => {
                    if (item3.label === objectCreateAPPFormLocutions.headquarters.label) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'type':
                  item2.defaultValues = objectCreateAPPFormLocutions.type ? objectCreateAPPFormLocutions.type : location.state?.type;
                  break;
                default:
                  // item2.defaultValues = objectCreateAPPFormLocutions[item2.ref.element];
                  break;
              }
              // setDataSave(true);
              setLoading(false);
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    setUnSavedChanges(false);
    if (crudType === 'create') {
      typeSwal('createLoader', '', applicationsForm, getApplicationsLocutions, `/${routesWide.applications}/${routesApplications.locutions}`, 'locution', createApplicationsLocutions);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', applicationsForm, getApplicationsLocutions, `/${routesWide.applications}/${routesApplications.locutions}`, 'locution', updateApplicationsLocutions);
    }
  };
  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          onCancel={handleOnCancel}
          dataTabs={data.tabs || data.steps}
          arrScreen={['Locución']}
          data={data}
          onSubmit={onSubmit}
          form={applicationsForm}
          dataSave={dataSave}
          crudType={crudType}
          reducerForm={objectCreateAPPFormLocutions}
        />
      )}
    </div>
  );
};

LocutionsFormScreen.propTypes = { crudType: string };
