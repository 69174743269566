import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateEMAlerts } from '../../../data/dataEM';
import useApplications from '../../../hooks/useApplications';
import useEM from '../../../hooks/useEM';
import { useSwal } from '../../../hooks/useSwal';
import { routesEM, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';

export const AlertsFormScreen = ({ crudType }) => {
  const { EMFormAlerts, EMform, arrayEM, getEM, createEMAlerts, updateEMAlerts, getEMAlerts } = useEM();

  const { typeSwal } = useSwal();

  const { arrayLocutionsHook, getApplicationsLocutions } = useApplications();

  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const data = formCreateEMAlerts;

  useEffect(() => {
    getEM();
    getApplicationsLocutions();
    EMform(
      { action: 'clearForm' },
      {
        form: 'alerts',
      }
    );
    if (crudType === 'edit') {
      EMform(
        {
          action: 'toggle',
          value: location.state?.callAndRecord.toggle === 1 ? true : false,
        },
        {
          form: 'alerts',
          element: 'callAndRecord',
        }
      );
      EMform(
        {
          action: 'toggle',
          value: location.state?.externo.toggle === 1 ? true : false,
        },
        {
          form: 'alerts',
          element: 'external',
        }
      );

      EMform(
        {
          action: 'id',
          value: location.state?.id,
        },
        { form: 'alerts', element: 'id' }
      );
      EMform(
        {
          action: 'input',
          value: location.state?.name,
        },
        { form: 'alerts', element: 'name' }
      );
      EMform(
        {
          action: 'input',
          value: location.state?.keyCall,
        },
        { form: 'alerts', element: 'keyCall' }
      );
      EMform(
        {
          action: 'input',
          value: location.state?.confirmationNumber,
        },
        { form: 'alerts', element: 'confirmationNumber' }
      );
      EMform(
        {
          action: 'select-option',
        },
        {
          form: 'alerts',
          element: 'locution',
        },
        {
          label: location.state?.description,
          value: String(location.state?.soundPromptId),
        }
      );
      EMform(
        {
          action: 'select-option',
        },
        {
          form: 'alerts',
          element: 'diffusionList',
        },
        {
          label: location.state?.listDiffusionName,
          value: String(location.state?.listDiffusionId),
        }
      );
      EMform(
        {
          action: 'select-option',
        },
        {
          form: 'alerts',
          element: 'secondaryDiffusionList',
        },
        {
          label: location.state?.secondaryListDiffusionName,
          value: String(location.state?.secondaryListDiffusionId),
        }
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayEM === 'object' && arrayEM.length > 0 && arrayLocutionsHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'diffusionList') {
              item2.options = [];
              arrayEM.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                let diffusionList = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === diffusionList.value);
                if (!found && diffusionList.value !== undefined) {
                  item2.options.push(diffusionList);
                  item2.originalOptions.push(diffusionList);
                }
              });
            }
            if (item2.ref.element === 'locution') {
              arrayLocutionsHook.forEach((item3) => {
                if (item3.description === null) {
                  item3.description = 'not defined';
                }
                let locution = {
                  label: item3.description,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === locution.value);
                if (!found && locution.value !== undefined) {
                  item2.options.push(locution);
                }
              });
            }
            if (item2.ref.element === 'secondaryDiffusionList') {
              item2.options = [];
              arrayEM.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                let diffusionList = {
                  label: item3.name,
                  value: item3?.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === diffusionList.value);
                if (!found && diffusionList.value !== undefined) {
                  item2.options.push(diffusionList);
                }
              });
            }

            return item2;
          });
          return item;
        });
        if (crudType === 'edit' && (EMFormAlerts.name === '' || EMFormAlerts.name !== location.state?.name)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = EMFormAlerts.name;
                  break;
                case 'keyCall':
                  item2.defaultValues = EMFormAlerts.keyCall;
                  break;
                case 'diffusionList':
                  item2.options.filter((item3) => {
                    if (EMFormAlerts.listDiffusion.value === item3.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'locution':
                  item2.options.filter((item3) => {
                    if (EMFormAlerts.locution.value === item3.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'confirmationNumber':
                  item2.defaultValues = EMFormAlerts.confirmationNumber;
                  break;
                default:
                  break;
                case 'secondaryDiffusionList':
                  // item2.options.filter((item3) => {
                  //   if (EMFormAlerts.secondaryListDiffusion.value === item3.value) {
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = EMFormAlerts.secondaryListDiffusion;
                  break;
                case 'secondaryListDiffusion':
                  // item2.options.filter((item3) => {
                  //   if (EMFormAlerts.secondaryListDiffusion.value === item3.value) {
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = EMFormAlerts.secondaryDiffusionList;
                  break;
                case 'callAndRecord':
                  item2.defaultValues = EMFormAlerts.callAndRecord;
                  break;
                case 'external':
                  item2.defaultValues = EMFormAlerts.external;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter]);

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      try {
        typeSwal('createLoader', '', EMform, getEMAlerts, `/${routesWide.EM}/${routesEM.alerts}`, 'alerts', createEMAlerts);
      } catch (err) {
        return err;
      }
    } else if (crudType === 'edit') {
      try {
        typeSwal('createLoader', '', EMform, getEMAlerts, `/${routesWide.EM}/${routesEM.alerts}`, 'alerts', updateEMAlerts);
      } catch (err) {
        return err;
      }
    }
  };

  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          onCancel={handleOnCancel}
          reducerForm={EMFormAlerts}
          data={data}
          onSubmit={onSubmit}
          form={EMform}
          dataSave={dataSave}
          initialData={EMform}
          crudType={crudType}
          arrScreen={['Alerta']}
        />
      )}
    </div>
  );
};

AlertsFormScreen.propTypes = { crudType: string };
