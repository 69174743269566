import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formTrunks, optionsAllow, optionsDTMF, optionsNat, optionsTransport } from '../../../data/dataOutbound';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
import { routesOutbound, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import { getArrayFormatted } from '../../../utils/AppUtils';

export const TrunksOutboundScreenForm = ({ crudType }) => {
  const { outboundRoutesForm, createOutboundRoutesTrunks, updateOutboundRoutesTrunks, outboundRoutesFormReducer, getOutboundRoutesTrunks } = useOutbound();
  const { typeSwal } = useSwal();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const location = useLocation();

  const data = formTrunks;

  const [unSavedChanges, setUnSavedChanges] = useState(false);

  useEffect(() => {
    getHeadquarter();
    getOutboundRoutesTrunks();

    outboundRoutesForm(
      {
        action: 'clearForm',
      },
      {
        form: 'trunks',
      }
    );
  }, []);

  useEffect(() => {
    if (crudType === 'edit') {
      // const nameAllow = location.state?.allow === null ? '' : location.state?.allow;
      // const nameDisallow = location.state?.disallow === null ? '' : location.state?.disallow;
      const nameDTMF = location.state?.dtmf === null ? '' : location.state?.dtmf;
      const nameNat = location.state?.nat === null ? '' : location.state?.nat;
      const nameTransport = location.state?.transport === null ? '' : location.state?.transport;

      // const nameAllowArray = nameAllow?.split(', ');
      // const nameDisallowArray = nameDisallow?.split(', ');
      const nameNatArray = Array.isArray(nameNat) ? nameNat?.split(', ') : [nameNat];

      // const newAllow = optionsAllow?.filter((item) => nameAllowArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      // let newDisallow = optionsDisallow?.filter((item) => nameDisallowArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      const newNat = optionsNat?.filter((item) => nameNatArray.includes(item)).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      const newTransport = optionsTransport?.filter((item) => item.value === nameTransport).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      const newDTMF = optionsDTMF?.filter((item) => item.value === nameDTMF).map((item) => ({ label: item.label, value: item.value, id: item.id }));

      let newAllow = [];

      if (Array.isArray(location.state?.allow)) {
        newAllow = location.state?.allow;
      } else {
        newAllow = getArrayFormatted(location.state?.allow, optionsAllow, 'allow');
      }

      // let newDisallow = '';
      // if (Array.isArray(location.state?.newDisallow)) {
      //   newDisallow = location.state?.newDisallow;
      // } else {
      //   newDisallow = getArrayFormatted(location.state?.newDisallow, optionsDisallow, 'allow');
      // }

      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'allow',
        },
        newAllow
      );
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'disallow',
        },
        newAllow
      );

      outboundRoutesForm(
        {
          action: 'select-option',
          value: { value: location.state?.companyId, label: location.state?.company },
        },
        {
          form: 'trunks',
          element: 'companyId',
        }
      );

      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'dtmf',
        },
        newDTMF
      );

      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'nat',
        },
        newNat
      );
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'transport',
        },
        newTransport
      );
      outboundRoutesForm(
        {
          action: 'id',
          value: location.state?.kamDispatcherId,
        },
        {
          form: 'trunks',
          element: 'kamDispatcherId',
        }
      );
      outboundRoutesForm(
        {
          action: 'id',
          value: location.state?.context,
        },
        {
          form: 'trunks',
          element: 'context',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.password,
        },
        {
          form: 'trunks',
          element: 'notPassword',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.description,
        },
        {
          form: 'trunks',
          element: 'description',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.fromDomain,
        },
        {
          form: 'trunks',
          element: 'fromDomain',
        }
      );

      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.userDefault,
        },
        {
          form: 'trunks',
          element: 'defaultuser',
        }
      );

      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.qualify,
        },
        {
          form: 'trunks',
          element: 'qualify',
        }
      );
      outboundRoutesForm(
        {
          action: 'toggle',
          value: location.state?.regServer?.toggle === 0 ? false : true,
        },
        {
          form: 'trunks',
          element: 'regServer',
        }
      );

      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form: 'trunks',
          element: 'name',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form: 'trunks',
          element: 'server',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.port,
        },
        {
          form: 'trunks',
          element: 'port',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.deny,
        },
        {
          form: 'trunks',
          element: 'deny',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.permit,
        },
        {
          form: 'trunks',
          element: 'permit',
        }
      );
      outboundRoutesForm(
        {
          action: 'id',
          value: location.state?.id,
        },
        {
          form: 'trunks',
          element: 'id',
        }
      );
      // Object.keys(location.state).map((item) =>
      //   data.steps.map((item2) =>
      //     item2.input.map((item3) => {
      //       if (item === item3.ref.element) {
      //         return outboundRoutesForm(
      //           {
      //             action: item3.cardType,
      //             value: item3.cardType === 'toggle' ? (location.state?.regserver.toggle === 0 ? false : true) : location.state[item],
      //           },
      //           {
      //             form: item3.ref.form,
      //             element: item3.ref.element,
      //           },
      //           {
      //             label: location.state[item],
      //             value: location.state[item] === true ? 1 : 0,
      //           }
      //         );
      //       } else {
      //         return null;
      //       }
      //     })
      //   )
      // );
    } else if (crudType === 'create') {
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'companyId',
        },
        data.steps[0].input[4].ref.element
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.cardType === 'select-option') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            return item2;
          });
          return item;
        });

        if (crudType === 'edit' && (outboundRoutesFormReducer.name === '' || outboundRoutesFormReducer.name !== location.state?.name || outboundRoutesFormReducer.id !== location.state?.id)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'companyId':
                  item2.options.filter((item3) => {
                    if (Number(item3.value) === outboundRoutesFormReducer.companyId.value) {
                      return (item2.defaultValues = item3);
                    }
                    // return false;
                  });
                  break;
                case 'fromDomain':
                  item2.defaultValues = outboundRoutesFormReducer.fromDomain;
                  break;
                case 'userDefault':
                  item2.defaultValues = outboundRoutesFormReducer.defaultuser;
                  break;
                case 'allow':
                  item2.defaultValues = outboundRoutesFormReducer.allow;
                  break;
                case 'port':
                  item2.defaultValues = outboundRoutesFormReducer.port;
                  break;
                case 'context':
                  item2.defaultValues = location.state.context ? location.state.context : outboundRoutesFormReducer.context;
                  break;
                case 'permit':
                  item2.defaultValues = outboundRoutesFormReducer.permit;
                  break;
                case 'deny':
                  item2.defaultValues = outboundRoutesFormReducer.deny;
                  break;
                case 'dtmf':
                  item2.defaultValues = outboundRoutesFormReducer.dtmf;
                  break;
                case 'disallow':
                  // location.state?.disallow.split(', ');

                  item2.defaultValues = outboundRoutesFormReducer.disallow;

                  // outboundRoutesForm(
                  //   {
                  //     action: 'select-option',
                  //   },
                  //   {
                  //     form: 'trunks',
                  //     element: 'disallow',
                  //   },
                  //   outboundRoutesFormReducer.disallow
                  // );
                  // }
                  // optionsDisallow
                  //   .filter((item) => location.state?.disallow.split(', ').includes(item.value))
                  //   .map((item) => ({ label: item.label, value: item.value, id: item.id }));
                  break;
                case 'regserver':
                  item2.defaultValues = outboundRoutesFormReducer.regServer.toggle;
                  break;
                default:
                  item2.defaultValues = outboundRoutesFormReducer[item2.ref.element];
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((item) => {
            // itemTab.steps.map((item) => {
            item.input.map((item2) => {
              item2.defaultValues = '';
            });
            // });
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    setUnSavedChanges(false);
    if (crudType === 'create') {
      typeSwal('createLoader', '', outboundRoutesForm, getOutboundRoutesTrunks, `/${routesWide.outboundRoutes}/${routesOutbound.trunks}`, 'trunks', createOutboundRoutesTrunks);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', outboundRoutesForm, getOutboundRoutesTrunks, `/${routesWide.outboundRoutes}/${routesOutbound.trunks}`, 'trunks', updateOutboundRoutesTrunks);
    }
  };

  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          reducerForm={outboundRoutesFormReducer}
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          onCancel={handleOnCancel}
          arrScreen={['Troncal']}
          data={data}
          onSubmit={onSubmit}
          form={outboundRoutesForm}
          dataSave={dataSave}
          crudType={crudType}
        />
      )}
    </div>
  );
};

TrunksOutboundScreenForm.propTypes = { crudType: string };
