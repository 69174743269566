export const actionsTypesSettings = {
  getPrivileges: 'getPrivileges',
  getMenuSubmenu: 'GET_MENU_SUBMENU',
  postPrivileges: 'POST_PRIVILEGES',
  getRoles: 'GET_ROLES',

  //Api
  getApi: 'GET_API',
  changeApiForm: 'CHANGE_API_FORM',
  changeNameApiForm: 'CHANGE_NAME_API_FORM',
  clearApiForm: 'CLEAR_API_FORM',

  //Domains
  getDomains: 'GET_DOMAINS',
  changeConfigFormisNewUserNotify: 'CHANGE_CONFIG_FORM_isNewUserNotify',
  changeDomainsFormDomain: 'CHANGE_DOMAINS_FORM_DOMAIN',
  changeDomainsFormId: 'CHANGE_DOMAINS_FORM_ID',
  clearFormDomains: 'CLEAR_FORM_DOMAINS',

  //Whitelist
  getWhitelist: 'GET_WHITELIST',
  addConfigFormisRainbow: 'ADD_CONFIG_FORM_ISRAINBOW',
  changeWhitelistFormIpAddr: 'CHANGE_WHITELIST_FORM_IP_ADDR',
  changeWhitelistFormId: 'CHANGE_WHITELIST_FORM_ID',
  clearFormWhitelist: 'CLEAR_FORM_WHITELIST',

  //config
  getConfig: 'GET_CONFIG',
  getSettings: 'GET_SETTINGS',
  clearFormConfig: 'CLEAR_FORM_CONFIG',
  changeSettingsFormIdAction: 'CHANGE_CONFIG_FORM_ID',
  addSettingsFormIdAction: 'ADD_SETTINGS_FORM_ID',
  changeConfigFormisTeams: 'CHANGE_CONFIG_FORM_isTeams',

  changeConfigFormTeamsURL: 'CHANGE_CONFIG_FORM_Teams_URL',
  changeConfigFormisRainbow: 'CHANGE_CONFIG_FORM_isRainbow',
  changeConfigFormRainbowURL: 'CHANGE_CONFIG_FORM_Rainbow_URL',
  changeConfigFormisSoftphone: 'CHANGE_CONFIG_FORM_isSoftphone',
  changeConfigFormIpLocal: 'CHANGE_CONFIG_FORM_IP_LOCAL',
  changeConfigFormDomain: 'CHANGE_CONFIG_FORM_DOMAIN',
  changeConfigFormPortWss: 'CHANGE_CONFIG_FORM_PORT_WSS',
  changeConfigFormPathWss: 'CHANGE_CONFIG_FORM_PATH_WSS',
  changeConfigFormPassword: 'CHANGE_CONFIG_FORM_PASSWORD',
  changeConfigFormAzureSubscripcionKey: 'CHANGE_CONFIG_FORM_AZURE_SUBSCRIPVCION_KEY',
  changeConfigFormRutaIndicacionDeSonido: 'CHANGE_CONFIG_FORM_RUTA_INDICACION_DE_SONIDO',
  changeConfigFormNumeroMaximoDeExtensiones: 'CHANGE_CONFIG_FORM_NUMERO_MAXIMO_DE_EXTENSIONES',
  changeConfigFormDigitoDeMarcacion: 'CHANGE_CONFIG_FORM_DIGITO_DE_MARCACION',
  changeConfigFormAdministradorSecreto: 'CHANGE_CONFIG_FORM_ADMINISTRADOR_SECRETO',
  changeConfigFormUsuarioAdministrador: 'CHANGE_CONFIG_FORM_USUARIO_ADMINISTRADOR',
  changeConfigFormPuertoAdministrador: 'CHANGE_CONFIG_FORM_PUERTO_ADMINISTRADOR',
  changeConfigFormIpAdministrador: 'CHANGE_CONFIG_FORM_IP_ADMINISTRADOR',
  changeConfigFormExtensionStart: 'CHANGE_CONFIG_FORM_EXTENSION_START',
  changeConfigFormExtensionEnd: 'CHANGE_CONFIG_FORM_EXTENSION_END',
  changeConfigFormGroupStart: 'CHANGE_CONFIG_FORM_GROUP_START',
  changeConfigFormGroupEnd: 'CHANGE_CONFIG_FORM_GROUP_END',
  changeConfigFormMailBoxesStart: 'CHANGE_CONFIG_FORM_MAIL_BOXES_START',
  changeConfigFormMailBoxesEnd: 'CHANGE_CONFIG_FORM_MAIL_BOXES_END',
  changeConfigFormConferenceStart: 'CHANGE_CONFIG_FORM_CONFERENCE_START',
  changeConfigFormConferenceEnd: 'CHANGE_CONFIG_FORM_CONFERENCE_END',
  changeConfigFormShortMarkingStart: 'CHANGE_CONFIG_FORM_SHORT_MARKING_START',
  changeConfigFormShortMarkingEnd: 'CHANGE_CONFIG_FORM_SHORT_MARKING_END',
  changeConfigFormColaStart: 'CHANGE_CONFIG_FORM_COLA_START',
  changeConfigFormColaEnd: 'CHANGE_CONFIG_FORM_COLA_END',
  changeConfigFormOpt: 'CHANGE_CONFIG_FORM_OPT',
  changeConfigFormTimeout: 'CHANGE_CONFIG_FORM_TIMEOUT',
  changeConfigFormTimeoutCf: 'CHANGE_CONFIG_FORM_TIMEOUT_CF',
  changeConfigFormOutTimeout: 'CHANGE_CONFIG_FORM_OUT_TIMEOUT',
  changeConfigFormOutOptions: 'CHANGE_CONFIG_FORM_OUT_OPTIONS',
  changeConfigFormConfrecpath: 'CHANGE_CONFIG_FORM_CONFRECPATH',
  changeConfigFormVmgreetpath: 'CHANGE_CONFIG_FORM_VMGREETPATH',
  changeConfigFormCallrecpath: 'CHANGE_CONFIG_FORM_CALLRECPATH',
  changeConfigFormMydynamicFearures: 'CHANGE_CONFIG_FORM_MYDINAMIC_FEATURES',
  changeConfigFormDfRingtimeout: 'CHANGE_CONFIG_FORM_DF_RINGTIMEOUT',
  changeConfigFormFormatoDefaultVm: 'CHANGE_CONFIG_FORM_FORMATO_DEFAULT_VM',
  changeConfigFormBxferCount: 'CHANGE_CONFIG_FORM_BXFER_COUNT',
  changeConfigFormSeltimeout: 'CHANGE_CONFIG_FORM_SELTIMEOUT',
  changeConfigFormPrefixMovCorp: 'CHANGE_CONFIG_FORM_PREFIX_MOV_CORP',
  changeConfigFormPriMov: 'CHANGE_CONFIG_FORM_PRI_MOV',
  changeConfigFormNumcortaOrigin: 'CHANGE_CONFIG_FORM_NUMCORTA_ORIGIN',
  changeConfigFormCodelen: 'CHANGE_CONFIG_FORM_CODELEN',
  changeConfigFormOpcCola: 'CHANGE_CONFIG_FORM_OPC_COLA',
  changeConfigFormMaxInboundChan: 'CHANGE_CONFIG_FORM_MAX_INBOUND_CHAN',
  changeConfigFormMaquina: 'CHANGE_CONFIG_FORM_MAQUINA',
  addConfigFormPreferences: 'ADD_CONFIG_FORM_PREFERENCES',
  changeConfigFormSubNotifyApi: 'CHANGE_CONFIG_FORM_SUB_NOTIFY_API',
  addConfigFormCallbackAudioId: 'CHANGE_CONFIG_FORM_CALLBACK_AUDIO_ID',
  changeConfigFormCapturarLlamada: 'CHANGE_CONFIG_FORM_CAPTURAR_LLAMADA',
  changeConfigFormTransferenciaCiega: 'CHANGE_CONFIG_FORM_TRANSFERENCIA_CIEGA',
  changeConfigFormColgarLlamada: 'CHANGE_CONFIG_FORM_COLGAR_LLAMADA',
  changeConfigFormTransferenciaAtendida: 'CHANGE_CONFIG_FORM_TRANSFERENCIA_ATENDIDA',

  addConfigFormRainbow_TroncalId: 'ADD_CONFIG_FORM_RAINBOW_TRONCAL_ID',
  addConfigFormTeams_TroncalId: 'ADD_CONFIG_FORM_TEAMS_TRONCAL_ID',
  changeConfigFormRainbow_TroncalId: 'CHANGE_CONFIG_FORM_RAINBOW_TRONCAL_ID',
  changeConfigFormTeams_TroncalId: 'CHANGE_CONFIG_FORM_TEAMS_TRONCAL_ID',
  // changeConfigFormisRainbow: 'CHANGE_CONFIG_FORM_ISRAINBOW',
  // changeConfigFormisTeams: 'CHANGE_CONFIG_FORM_ISTEAMS',
};
