import React, { useEffect } from 'react';
import Select from 'react-select';
import { useHeadquarter } from '../../hooks/useHeadquarter';
import { useValidationForm } from '../../hooks/useValidationForm';
import { SelectWrapper } from './MySelect.style';
import { array, bool, func, number, object, string } from 'prop-types';
import { getUserData } from '../../utils/AppUtils';
import useSettings from '../../hooks/useSettings';
import useNumbering from '../../hooks/useNumbering';

export const MySelect = ({
  item,
  options,
  handleIsDisabled,
  placeholder,
  inputRef,
  defaultValue,
  isMulti,
  isSearchable,
  form,
  setInputValue,
  setErrorInput,
  setValidationNormalInput,
  validationNormalInput,
  allSteps,
  idPage,
  destinationOpen,
  isDisabled,
  setValidateReducer,
  reducerForm,
  firstData,
  value,
}) => {
  const { getHeadquarter, companyLink } = useHeadquarter();
  const { arrayModelosHook } = useNumbering();
  const { users } = useSettings();
  const { validationForm } = useValidationForm();

  useEffect(() => {
    getHeadquarter();
  }, []);

  try {
    const handleSelectChange = (inputValue, action, item) => {
      if (reducerForm !== undefined) {
        const newisDisabled = handleIsDisabled({ element: 'marca', form: 'extension' });
        console.log(newisDisabled);
        item.idDestination = destinationOpen === undefined ? null : destinationOpen;
        item.idPage = idPage;
        item.action = action.action;

        let responseReducerValidate = validationForm(reducerForm, firstData, item);
        responseReducerValidate && setValidateReducer && setValidateReducer(responseReducerValidate);
      }

      if (item.form === 'plantilla') {
        const associatedDataInputs = allSteps.reduce((inputs, step) => [...inputs, ...step.input.filter((input) => input.ref && input.ref.type === 'associatedModelo')], []);

        if (item.element === 'marca') {
          associatedDataInputs.forEach((assocInput) => {
            const newArray = arrayModelosHook?.filter((item) => item.marca === inputValue.value);
            assocInput.options = newArray.map((itemModelo) => {
              return { value: itemModelo.id, label: itemModelo.modelo };
            });
            assocInput.defaultValues = {
              value: arrayModelosHook[0]?.id,
              label: arrayModelosHook[0]?.modelo,
            };

            setInputValue((prevState) => ({
              ...prevState,
              ['modelo']: '',
            }));
          });
          form({ action: 'clear' }, { form: 'plantilla', element: 'modelo' });
        }
      }

      if (item.form === 'extension') {
        if (item.element === 'typeExtension' || item.element === 'userId') {
          // if (item.element === 'typeExtension') {
          const newItemMarca = {
            action: 'select-option',
            // item.element !== 'typeExtension' ? 'input' : 'select',
            element: 'marca',
            form: 'extension',
            idDestination: item.idDestination,
            idPage: item.idPage,
          };

          setInputValue((prevState) => ({
            ...prevState,
            ['marca']: { value: 1, label: 'Marca1' },
          }));

          form(item.form, newItemMarca, {});

          const newItemModelo = {
            action: 'select-option',
            // item.element !== 'typeExtension' ? 'input' : 'select',
            element: 'modelo',
            form: 'extension',
            idDestination: item.idDestination,
            idPage: item.idPage,
          };

          setInputValue((prevState) => ({
            ...prevState,
            ['modelo']: { value: 1, label: 'Modelo1' },
          }));

          form(item.form, newItemModelo, {});

          const newItemPlantilla = {
            action: 'select-option',
            // item.element !== 'typeExtension' ? 'input' : 'select',
            element: 'plantilla',
            form: 'extension',
            idDestination: item.idDestination,
            idPage: item.idPage,
          };

          setInputValue((prevState) => ({
            ...prevState,
            ['plantilla']: { value: 1, label: 'Plantilla1' },
          }));

          form(item.form, newItemPlantilla, {});

          const newItemAllow = {
            action: 'select-option',
            // item.element !== 'typeExtension' ? 'input' : 'select',
            element: 'allow',
            form: 'extension',
            idDestination: item.idDestination,
            idPage: item.idPage,
          };

          setInputValue((prevState) => ({
            ...prevState,
            ['allow']: { value: 1, label: 'Allow' },
          }));

          form(item.form, newItemAllow, {});
          // }

          if (reducerForm.steps[0].input[0].defaultValues.label && reducerForm.steps[0].input[1].defaultValues?.value) {
            // if (firstData.steps[0].input[0].defaultValues?.value && reducerForm.steps[0].input[1].defaultValues.value) {
            const assocInput = { ref: { element: 'identification' } };

            const newItemCapture = {
              action: 'input',
              // item.element !== 'typeExtension' ? 'input' : 'select',
              element: assocInput.ref.element || item.element,
              form: 'extension',
              idDestination: item.idDestination,
              idPage: item.idPage,
            };

            const newAction = { action: 'input' };
            assocInput.defaultValues = {
              value: inputValue.value,
              label: inputValue.label,
            };

            if (assocInput?.defaultValues.value) {
              const userData = getUserData(users, assocInput.defaultValues.value);
              // const userData = getUserData(users, firstData.steps[0].input[0].defaultValuesreducerForm.steps[0].input[0].defaultValues?.value);

              // firstData.steps[0].input[0].defaultValues?.label || reducerForm.steps[0].input[1].defaultValues.value
              //   ?
              //  reducerForm[assocInput.ref.element] = assocInput?.defaultValues;
              const newUser = item.element !== 'typeExtension' ? assocInput.defaultValues.label : userData?.firstname + ' ' + userData?.lastname;
              const newType = item.element === 'typeExtension' ? assocInput.defaultValues.label : firstData.steps[0].input[1].defaultValues?.label;

              if (newUser) {
                const newIdentification = newUser.replace(' ', '_') + '__' + newType + '__' + userData?.SIPExtensionId;

                setInputValue((prevState) => ({
                  ...prevState,
                  [assocInput.ref.element]: newIdentification,
                }));
                // : null;

                form(newAction, newItemCapture, newIdentification);
              }
              // }
            }
          }
        }
      }

      if (item.type === 'dynamic' || item.type === 'associatedData') {
        const associatedDataInputs = allSteps.reduce((inputs, step) => [...inputs, ...step.input.filter((input) => input.ref && input.ref.type === 'associatedData')], []);

        let arrayDepartments = [];
        if (Array.isArray(inputValue)) {
          //   inputValue = inputValue.map((input) => input.value);
          inputValue.forEach((itemDept) => {
            const departments = companyLink.filter((itemFilter) => itemFilter.Company.id === +itemDept?.value).map((item) => item.Department);

            if (Array.isArray(inputValue)) {
              departments.forEach((department) => {
                arrayDepartments.push(department);
              });
            } else {
              arrayDepartments.push(departments);
            }
          });
        } else {
          arrayDepartments = companyLink.filter((itemFilter) => itemFilter.Company.id === +inputValue?.value).map((item) => item.Department);
        }

        const newItemCapture = {
          action: item.action,
          element: item.element,
          //  === 'companyId' ? 'Company' : item.element,
          form: item.form,
          idDestination: item.idDestination,
          idPage: item.idPage,
          type: 'associatedData',
        };

        const newDepartment = {
          action: item.action,
          element: 'Department',
          //  === 'companyId' ? 'Company' : item.element,
          form: item.form,
          idDestination: item.idDestination,
          idPage: item.idPage,
          type: 'associatedData',
        };

        associatedDataInputs.forEach((assocInput) => {
          assocInput.options.splice(0, assocInput.options.length);
          assocInput.options = arrayDepartments?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }));
          assocInput.defaultValues = {
            value: arrayDepartments[0]?.id,
            label: arrayDepartments[0]?.name,
          };
          setInputValue((prevState) => ({
            ...prevState,
            [assocInput.ref.element]: assocInput?.defaultValues,
          }));

          form(action, newDepartment, assocInput?.defaultValues);
          // reducerForm[assocInput.ref.element] = assocInput?.defaultValues;

          if (item.form === 'user') {
            allSteps[0].input[5].defaultValues = assocInput?.defaultValues;
            allSteps[0].input[6].defaultValues = assocInput?.defaultValues;

            if (item.element === 'companyId') {
              setInputValue((prevState) => ({
                ...prevState,
                [item.element]: inputValue,
              }));
              // setInputValue((prevState) => ({
              //   ...prevState,
              //   Company: inputValue,
              // }));
            }
          } else if (item.form === 'numberingList') {
            // allSteps[0].input[3].defaultValues = assocInput?.defaultValues;

            if (item.element === 'companyId') {
              const newItemCapture = {
                action: item.action,
                element: 'Company',
                //  === 'companyId' ? 'Company' : item.element,
                form: item.form,
                idDestination: item.idDestination,
                idPage: item.idPage,
                type: 'associatedData',
              };

              setInputValue((prevState) => ({
                ...prevState,
                [item.element]: Number(inputValue.value),
                Company: inputValue,
              }));

              form(action, newItemCapture, inputValue);
              // setInputValue((prevState) => ({
              //   ...prevState,
              //   Company: inputValue,
              // }));
            }
          }
        });

        form(action, newItemCapture, inputValue);
      }

      if (inputValue?.length > 0 || action.action === 'clear') {
        if (action.action === 'remove-value' || action.action === 'clear') {
          setInputValue((prevState) => ({
            ...prevState,
            [item.element]: inputValue,
          }));
          // action.cardType = 'multiselect';
          form(action, item, inputValue);
        }
      }
      Array.isArray(inputValue)
        ? inputValue.forEach((input) => {
            const newState = item.ref?.element ? item.ref?.element : item.element;
            setInputValue((prevState) => ({
              ...prevState,
              [newState]: { value: Number(input?.value), label: input?.label },
            }));
          })
        : setInputValue((prevState) => ({
            ...prevState,
            [item.element]: inputValue,
          }));

      if (inputValue?.label === '' || inputValue?.length === 0 || inputValue === null) {
        setErrorInput(true);
        setValidationNormalInput((prevState) => [...prevState, item.element]);
      }
      if (inputValue?.length === 0) {
        setInputValue((prevState) => ({
          ...prevState,
          [item.element]: '',
        }));
      } else {
        setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.element));
        if (validationNormalInput.length === 0) setErrorInput(false);
      }

      // if (inputValue) {
      //   inputValue.optionRefPage = idPage;
      //   inputValue.destinationOpen = destinationOpen;
      // }

      if (action.removedValues) {
        action.removedValues[0].optionRefPage = idPage;
        action.removedValues[0].destinationOpen = destinationOpen;
      }
      if (action.removedValue) {
        action.removedValue.optionRefPage = idPage;
        action.removedValue.destinationOpen = destinationOpen;
      }

      form(action, item, inputValue);
    };

    return (
      <SelectWrapper>
        {Object.keys(defaultValue || '').length > 0 ? (
          value ? (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
              inputRef={inputRef}
              value={defaultValue[0] !== undefined ? defaultValue : item.defaultValues}
              // defaultValue[0] !== undefined ? { value: defaultValue[0], label: options[0].label } :
              // value={defaultValue}
              isMulti={isMulti}
              isSearchable={isSearchable}
              isClearable={true}
              isDisabled={isDisabled}
              // setIsDisabled={handleIsDisabled}
              setValidateReducer={setValidateReducer}
              className={isDisabled ? 'disabled' : ''}
              // disabled={item.ref.element === 'SIPExtension' && form.isSIPExtension === 0 ? true : false}
            />
          ) : (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
              inputRef={inputRef}
              defaultValue={defaultValue[0] !== undefined ? defaultValue : item.defaultValues}
              isMulti={isMulti}
              isSearchable={isSearchable}
              isClearable={true}
              isDisabled={isDisabled}
              // setIsDisabled={handleIsDisabled}
              setValidateReducer={setValidateReducer}
            />
          )
        ) : (
          <Select
            options={options}
            // options[0]?.name !== undefined ? options.map((option) => ({ value: option.value, label: option.label + ' - ' + option.name, name: option.name })) : options}
            placeholder={placeholder}
            onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
            inputRef={inputRef}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={true}
            setValidateReducer={setValidateReducer}
          />
        )}
      </SelectWrapper>
    );
  } catch (error) {
    if (import.meta.env.VITE_DEBUG === 'true') console.log(error);
  }
};
MySelect.propTypes = {
  item: object,
  options: array,
  placeholder: string,
  handleIsDisabled: func,
  inputRef: object,
  // defaultValue: PropTypes.oneOfType([PropTypes.oneOf([PropTypes.object, PropTypes.array])]),
  defaultValue: array,
  isMulti: bool,
  isSearchable: bool,
  form: func,
  setInputValue: func,
  setErrorInput: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  allSteps: array,
  idPage: number,
  destinationOpen: number,
  isDisabled: bool,
  setValidateReducer: func,
  reducerForm: object,
  firstData: object,
  value: array,
};
