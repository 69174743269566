import { array } from 'prop-types';
import React from 'react';
import { RadialBarChart, RadialBar, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const RadialChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart width="100%" height="100%" cx="50%" cy="50%" startAngel={0} endAngle={270} innerRadius="30%" outerRadius="100%" data={data}>
        <RadialBar background clockWise={true} dataKey="respondidas" cornerRadius={10}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </RadialBar>
        <Legend iconSize={10} verticalAlign="center" layout="vertical" align="right" wrapperStyle={{ bottom: 30, right: 60, lineHeight: '24px' }} iconType="circle" />
        <Tooltip />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

RadialChart.propTypes = { data: array };

export default RadialChart;
