import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateDiffusionList } from '../../../data/dataEM';
import useEM from '../../../hooks/useEM';
import useNumbering from '../../../hooks/useNumbering';
import { useSwal } from '../../../hooks/useSwal';
import { routesEM, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const DiffusionListForm = ({ crudType }) => {
  const { createEMListExtension, updateEMListExtension, getListDiffusionExtension, EMform, EMFormListExtensions, getEMListExtension } = useEM();
  const { arrayNumberingExtensionsHook, getExtensions } = useNumbering();
  const { typeSwal } = useSwal();

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);

  const data = formCreateDiffusionList;

  const [unSavedChanges, setUnSavedChanges] = useState(false);

  useEffect(() => {
    getExtensions();
    getListDiffusionExtension();
    EMform({ action: 'clearForm' }, { form: 'diffusionList' });

    if (crudType === 'edit') {
      // let actionId = {
      //   action: 'id',
      //   value: location.state?.id,
      // };

      // EMform(actionId, { form: 'diffusionList', element: 'id' });
      // let actionName = { action: 'input', value: location.state?.title };
      // EMform(actionName, { form: 'diffusionList', element: 'name' });
      EMform(
        {
          action: 'id',
          value: location.state?.id,
        },
        {
          form: 'diffusionList',
          element: 'id',
        }
      );
      EMform(
        {
          action: 'input',
          value: location.state?.title,
        },
        {
          form: 'diffusionList',
          element: 'name',
        }
      );
      // location.state?.arrayExtensions.forEach((item) => {

      const newArrayExtensions = [];
      location.state?.extensionsId !== null
        ? location.state.extensionsId?.map((item) => {
            const found = arrayNumberingExtensionsHook.find((ext) => ext.id === item);
            found ? newArrayExtensions.push({ value: found.id, label: found.identification }) : null;
          })
        : null;
      EMform(
        {
          action: 'multiselect',
        },
        {
          form: 'diffusionList',
          element: 'extensions',
        },
        newArrayExtensions
      );
      // });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayNumberingExtensionsHook === 'object' && arrayNumberingExtensionsHook.length > 0) {
        data.steps.map((item) => {
          if (item.input[1].cardType === 'multiselect' || item.input[1].cardType === 'select-option') {
            item.input[1].options = [];
          }
          const result =
            item.input[1].cardType === 'multiselect'
              ? arrayNumberingExtensionsHook.map((item2) => {
                  if (item2.extension === null) {
                    item2.extension = 'not defined';
                  }
                  const extension = {
                    // name: item2.name,
                    label: item2.identification,
                    value: item2?.id,
                  };
                  const found = item.input[1].options.some((e) => e.value === extension.value);
                  if (!found && extension.value !== undefined) {
                    item.input[1].options.push(extension);
                  }
                  return item;
                })
              : null;
          return result;
        });
        if (crudType === 'edit' && (EMFormListExtensions.listName === '' || EMFormListExtensions.listName !== location.state?.title)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = EMFormListExtensions.listName;
                  break;
                case 'extensionsId':
                  item2.defaultValues = EMFormListExtensions.extensionsId
                    ? EMFormListExtensions.extensionsId
                    : location.state?.extensionsId
                      ? location.state.extensionsId
                      : EMFormListExtensions.extensionsId;
                  break;
                case 'listExtensions':
                  // item2.defaultValues = [];
                  // item2.options.filter((item3) => {
                  //   if (location.state?.arrayExtensions.map((item4) => item4.Extension.id.toString()).includes(item3.value)) {
                  //     if (!item2.defaultValues.map((item5) => item5.value).includes(item3.value)) {
                  //       return item2.defaultValues.push(item3);
                  //     }
                  //   }
                  //   return false;
                  // });
                  // if (item2.options) {
                  //   if (arraysAreEqual(location.state?.arrayExtensions, EMFormListExtensions?.extensions) === false) {
                  //     location.state?.arrayExtensions = EMFormListExtensions?.extensions;
                  //     location.state?.arrayExtensions.forEach((item) => {
                  //       // if (item.Extension?.id !== 'undefined' || item.value != 'undefined') {
                  //       EMform(
                  //         {
                  //           action: 'select-option',
                  //           value: item,
                  //         },
                  //         {
                  //           form: 'diffusionList',
                  //           element: 'listExtensions',
                  //         }
                  //       );
                  //       // }
                  //     });
                  //   }
                  // return (
                  // item2.defaultValues = location.state?.arrayExtensions;
                  // }
                  // item2.defaultValues = location.state?.arrayExtensions.map((item) => {
                  //   return { value: item.Extension.id, label: item.Extension.name };
                  // });
                  // break;
                  // if (item2.options) {
                  //   if (arraysAreEqual(location.state?.arrayExtensions, EMFormListExtensions?.extensions) === false) {
                  //     location.state?.arrayExtensions = EMFormListExtensions?.extensions;
                  //     location.state?.arrayExtensions.forEach((item) => {
                  //       EMform(
                  //         {
                  //           action: 'select-option',
                  //           option: {
                  //             label: item.Extension?.extension || item.label,
                  //             value: String(item.Extension?.id) || item.value,
                  //           },
                  //         },
                  //         {
                  //           form: 'diffusionList',
                  //           element: 'listExtensions',
                  //         }
                  //       );
                  //     });

                  break;
                default:
                  // item2.defaultValues = EMFormListExtensions[item2.ref.element]?.length !== undefined ? EMFormListExtensions[item2.ref.element] : location.state[item2.ref.element];
                  break;
              }

              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter]);

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    setUnSavedChanges(false);
    if (crudType === 'create') {
      try {
        typeSwal('createLoader', '', EMform, getEMListExtension, `/${routesWide.EM}/${routesEM.diffusionLists}`, 'diffusionList', createEMListExtension);
      } catch (err) {
        return err;
      }
    } else if (crudType === 'edit') {
      try {
        typeSwal('createLoader', '', EMform, getEMListExtension, `/${routesWide.EM}/${routesEM.diffusionLists}`, 'diffusionList', updateEMListExtension);
      } catch (err) {
        if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
      }
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          onCancel={handleOnCancel}
          reducerForm={EMFormListExtensions}
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          arrScreen={['Lista de difusión']}
          data={data}
          onSubmit={onSubmit}
          form={EMform}
          dataSave={dataSave}
          initialData={EMFormListExtensions}
          crudType={crudType}
        />
      )}
    </>
  );
};

DiffusionListForm.propTypes = { crudType: string };
