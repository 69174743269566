import { useEffect } from 'react';
import useApplications from './useApplications';
import { useDepartments } from './useDepartments';
import useEM from './useEM';
import { useHeadquarter } from './useHeadquarter';
import useNumbering from './useNumbering';
import { useOutbound } from './useOutboundRoutes';

export const useSelect = () => {
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { departmentsHook, getDepartments } = useDepartments();
  const { getCategoriesGroupHook, categoriesGroupHook, arrayOutboundRoutesTrunksHook, getOutboundRoutesTrunks } = useOutbound();
  const { arrayNumberingHook, getNumberingList } = useNumbering();
  const { arrayLocutionsHook } = useApplications();
  const { arrayEM, getEM } = useEM();

  useEffect(() => {
    if (arrayHeadquarterHook.length === 0) {
      getHeadquarter();
    }
    if (departmentsHook.length === 0) {
      getDepartments();
    }
    if (categoriesGroupHook.length === 0) {
      getCategoriesGroupHook();
    }
    if (arrayNumberingHook.length === 0) {
      getNumberingList();
    }
    if (arrayLocutionsHook.length === 0) {
      // getApplicationsLocutions();
    }
    if (arrayOutboundRoutesTrunksHook.length === 0) {
      getOutboundRoutesTrunks();
    }
    if (arrayEM.length === 0) {
      getEM();
    }
  }, []);

  const roleUser = [
    { name: 'superAdmin', id: 10 },
    { name: 'admin', id: 11 },
    { name: 'operator', id: 12 },
    { name: 'agent', id: 13 },
    { name: 'user', id: 14 },
  ];

  let maxLength = 0;
  let allData = [arrayHeadquarterHook, departmentsHook, categoriesGroupHook, arrayNumberingHook, arrayLocutionsHook, arrayOutboundRoutesTrunksHook, arrayEM];

  let returnLarger = (arr) => {
    if (arr.length > maxLength) {
      maxLength = arr.length;
    }
  };

  allData.map((item) => returnLarger(item));

  const optionTemplate = () => {
    const optionsTemplate = [];

    for (let i = 0; i < maxLength; i++) {
      optionsTemplate.push({
        Sede: arrayHeadquarterHook[i]?.name,
        Sede_id: arrayHeadquarterHook[i]?.id,
        Departamento: departmentsHook[i]?.name,
        Departamento_id: departmentsHook[i]?.id,
        Categoria: categoriesGroupHook[i]?.description,
        Categoria_id: categoriesGroupHook[i]?.id,
        Identificación: arrayNumberingHook[i]?.description,
        Identificación_id: arrayNumberingHook[i]?.id,
        Locución: arrayLocutionsHook[i]?.description,
        Locución_id: arrayLocutionsHook[i]?.id,
        RutaSaliente: arrayOutboundRoutesTrunksHook[i]?.description,
        RutaSaliente_id: arrayOutboundRoutesTrunksHook[i]?.id,
        ListaDeDifusión: arrayEM[i]?.name,
        ListaDeDifusión_id: arrayEM[i]?.id,
        Role: roleUser[i]?.name,
        Role_id: roleUser[i]?.id,
      });
    }
    return optionsTemplate;
  };
  return {
    optionTemplate,
  };
};
