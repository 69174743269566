import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formTrunks, optionsAllow, optionsDisallow, optionsDTMF, optionsNat, optionsTransport } from '../../../data/dataOutbound';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
import { routesOutbound, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';

export const TrunksOutboundScreenForm = ({ crudType }) => {
  const { outboundRoutesForm, createOutboundRoutesTrunks, updateOutboundRoutesTrunks, outboundRoutesFormReducer, getOutboundRoutesTrunks } = useOutbound();
  const { typeSwal } = useSwal();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const location = useLocation();

  const data = formTrunks;

  useEffect(() => {
    getHeadquarter();
    getOutboundRoutesTrunks();
  }, [getHeadquarter]);

  useEffect(() => {
    outboundRoutesForm(
      {
        action: 'clearForm',
      },
      {
        form: 'trunks',
      }
    );

    if (crudType === 'edit') {
      const nameAllow = location.state.allow === null ? '' : location.state.allow;
      const nameDisallow = location.state.disallow === null ? '' : location.state.disallow;
      const nameDTMF = location.state.dtmfmode === null ? '' : location.state.dtmfmode;
      const nameNat = location.state.nat === null ? '' : location.state.nat;
      const nameTransport = location.state.transport === null ? '' : location.state.transport;

      const nameAllowArray = nameAllow.split(', ');
      const nameDisallowArray = nameDisallow.split(', ');
      const nameNatArray = nameNat.split(', ');

      const newAllow = optionsAllow.filter((item) => nameAllowArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      let newDisallow = optionsDisallow.filter((item) => nameDisallowArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      const newNat = optionsNat.filter((item) => nameNatArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      const newTransport = optionsTransport.filter((item) => item.value === nameTransport).map((item) => ({ label: item.label, value: item.value, id: item.id }));
      const newDTMF = optionsDTMF.filter((item) => item.value === nameDTMF).map((item) => ({ label: item.label, value: item.value, id: item.id }));

      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'allow',
        },
        newAllow
      );
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'disallow',
        },
        newDisallow
      );
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'dtmfmode',
        },
        newDTMF
      );
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'nat',
        },
        newNat
      );
      outboundRoutesForm(
        {
          action: 'select-option',
        },
        {
          form: 'trunks',
          element: 'transport',
        },
        newTransport
      );
      outboundRoutesForm(
        {
          action: 'id',
          value: location.state.kamDispatcherId,
        },
        {
          form: 'trunks',
          element: 'kamDispatcherId',
        }
      );
      outboundRoutesForm(
        {
          action: 'input',
          value: location.state.password,
        },
        {
          form: 'trunks',
          element: 'notPassword',
        }
      );

      Object.keys(location.state).map((item) =>
        data.steps.map((item2) =>
          item2.input.map((item3) => {
            if (item === item3.ref.element) {
              return outboundRoutesForm(
                {
                  action: item3.cardType,
                  value: item3.cardType === 'toggle' ? (location.state.regServer.toggle === 0 ? false : true) : location.state[item],
                },
                {
                  form: item3.ref.form,
                  element: item3.ref.element,
                },
                {
                  label: location.state[item],
                  value: location.state[item] === true ? 1 : 0,
                }
              );
            } else {
              return null;
            }
          })
        )
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.cardType === 'select-option') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            return item2;
          });
          return item;
        });

        if (crudType === 'edit' && (outboundRoutesFormReducer.name === '' || outboundRoutesFormReducer.name !== location.state.name || outboundRoutesFormReducer.id !== location.state.id)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'companyId':
                  item2.options.filter((item3) => {
                    if (item3.value === outboundRoutesFormReducer.headquarters) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'disallow':
                  // location.state.disallow.split(', ');

                  if (item2.defaultValues !== outboundRoutesFormReducer.disallow) {
                    outboundRoutesForm(
                      {
                        action: 'select-option',
                      },
                      {
                        form: 'trunks',
                        element: 'disallow',
                      },
                      outboundRoutesFormReducer.disallow
                    );
                  }
                  // optionsDisallow
                  //   .filter((item) => location.state.disallow.split(', ').includes(item.value))
                  //   .map((item) => ({ label: item.label, value: item.value, id: item.id }));
                  break;
                default:
                  item2.defaultValues = outboundRoutesFormReducer[item2.ref.element];
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [loadingCounter, crudType]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', outboundRoutesForm, getOutboundRoutesTrunks, `/${routesWide.outboundRoutes}/${routesOutbound.trunks}`, 'trunks', createOutboundRoutesTrunks);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', outboundRoutesForm, getOutboundRoutesTrunks, `/${routesWide.outboundRoutes}/${routesOutbound.trunks}`, 'trunks', updateOutboundRoutesTrunks);
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen arrScreen={['Troncal']} data={data} onSubmit={onSubmit} form={outboundRoutesForm} dataSave={dataSave} crudType={crudType} />}</>;
};

TrunksOutboundScreenForm.propTypes = { crudType: string };
