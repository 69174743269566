import React from 'react';
import { FaqsScreenWrapper } from './FaqsScreen.style';

export const FaqsScreen = () => {
  return (
    <FaqsScreenWrapper>
      <h1>FaqsScreen</h1>
    </FaqsScreenWrapper>
  );
};
