import React from 'react';
import { Navigate } from 'react-router-dom';

import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { menuCallRegister } from '../../../data/dataCallRegister';
import { OverviewCallRegisterWrapper } from './OverviewCallRegister.style';

export const OverviewCallRegister = () => {
  return (
    <OverviewCallRegisterWrapper>
      <Navigate to="/cdr/general" />
      <LinkButtonBig menuTop={menuCallRegister} />
    </OverviewCallRegisterWrapper>
  );
};
