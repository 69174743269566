import React from 'react';
import usePermission from '../../hooks/usePermissions';
// import PermissionContext from './PermissionContext';
import { object, oneOfType, string, array } from 'prop-types';

const Restricted = ({ to, fallback, children }) => {
  const isAllowedTo = usePermission(to);

  if (isAllowedTo) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

Restricted.propTypes = { to: string, children: oneOfType([array, object]), fallback: string };

export default Restricted;
