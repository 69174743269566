import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const ModalWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  background: rgba(217, 217, 217, 0.59);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-warning {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    height: 80%;
    max-height: 270px;
    width: 50%;
    max-width: 300px;
    background-color: #fefefe;
    overflow: hidden;
    position: absolute;
    top: 40vh;
    display: flex;
    justify-content: space-around;

    border: 1px solid rgb(167, 164, 164);
    border-radius: 15px 15px 2px 2px;

    &__button {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      height: 34px;
      align-items: center;
    }
  }

  .modal {
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    height: 95%;
    width: 95%;
    background-color: ${colors.white};
    overflow: hidden;
    position: relative;

    &__title {
      ${
        '' /* position: relative;
      width: 100%;
      border-radius: 15px 15px 2px 2px; */
      }

      &__item {
        ${'' /* margin-top: 0; */}
        border-radius: 15px 15px 2px 2px;
        background-color: ${colors.primaryColor};
        font-size: ${newFontSize.h3};
        color: ${colors.primaryColorLight};
        font-weight: ${weight.medium};
      }

      &__icon {
        position: absolute;
        top: -10px;
        right: 10px;

        &__item {
          color: ${colors.primaryColor};

          &:hover {
            color: black;
            cursor: pointer;
          }
        }
      }
    }

    &__search {
      position: relative;
      margin-top: 23px;
      padding: 0 36px;
      z-index: 999;
    }

    &__children {
      height: 68%;
    }

    &__notFound {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__button {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 34px;
      align-items: center;
    }
  }
`;
