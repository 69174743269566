import React from 'react';
import { inputNumberData } from '../../data/dataInputNumber';

import { InputNumberWrapper } from './InputNumber.style';
import { array, func } from 'prop-types';

export const InputNumber = ({ onChange, defaultValues }) => {
  const { arrowDown, arrowUp } = inputNumberData;

  const handleChangeNumber = (e) => {
    const number = e.target.value;

    onChange(number);
  };

  return (
    <InputNumberWrapper>
      <div className="inputNumber">
        <input type={'number'} id={'timeout'} name={'timeout'} className="inputNumber__display" defaultValue={defaultValues} onChange={(e) => handleChangeNumber(e)} />
        <div className="inputNumber__buttons">
          <div className={'inputNumber__button'} onClick={() => handleChangeNumber('increment')}>
            <img src={arrowUp.img} alt={arrowUp.alt} />
          </div>
          <div className={'inputNumber__button'} onClick={() => handleChangeNumber('decrement')}>
            <img src={arrowDown.img} alt={arrowDown.alt} />
          </div>
        </div>
      </div>
    </InputNumberWrapper>
  );
};

InputNumber.propTypes = { onChange: func, defaultValues: array };
