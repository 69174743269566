import React from 'react';
import { ProfileHelpScreenWrapper } from './ProfileHelpScreen.style';

export const ProfileHelpScreen = () => {
  return (
    <ProfileHelpScreenWrapper>
      <h1>ProfileHelpScreen</h1>
    </ProfileHelpScreenWrapper>
  );
};
