import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const DistributionTableWrapper = styled.div`
  height: 100%;
  width: 100%;

  .title {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 59.49px;

    &__contain {
      display: flex;
      border-bottom: 1px solid ${colors.greyLine};
      height: 100%;
      align-items: center;
    }
    &__icon {
      height: 20px;
      margin-right: 3px;
    }
    &__text {
      margin: 0;
      font-weight: ${weight.medium};
      font-size: ${newFontSize.button};
      line-height: 16px;
      letter-spacing: 0.25px;
      color: ${colors.primaryColor};
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(1, 0.4fr 3fr);
    height: calc(100% - 59.49px);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 80px;
    left: ${(p) => (p.isMenuOpen ? '270px' : '80px')};

    .options {
      border-radius: 10px;
      padding-top: 80px;
      border-right: 1px solid ${colors.greyLine};
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 2;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
        background: white;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 1px;
        background: ${colors.primaryColorLight};
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 80%;
      }
    }

    .option {
      display: flex;
      height: 50px;
      align-items: center;
      border-bottom: 1px solid ${colors.greyLine};
      justify-content: space-between;
      background-color: ${colors.backgroundColor};

      &:hover {
        background-color: ${colors.primaryColorLight};
        color: ${colors.white};
        cursor: pointer;
        border-radius: 10px;

        &__textNoIcon {
          margin: 0;
          margin-left: 19px;
          font-weight: ${weight.regular};
          // font-size: ${newFontSize.body};
          line-height: 16px;
          letter-spacing: 0.25px;
          color: ${colors.white};
        }
      }

      &__iconText {
        display: flex;
        align-items: center;
        ${'' /* color: ${colors.primaryColor}; */}
      }
      &__icon {
        margin-right: 9px;
        height: 10px;
      }
      &__text {
        margin: 0;
        font-weight: ${weight.regular};
        font-size: ${newFontSize.body};
        line-height: 16px;
        letter-spacing: 0.25px;
        color: ${colors.white};
      }
      &__textNoIcon {
        margin: 0;
        margin-left: 19px;
        font-weight: ${weight.regular};
        // font-size: ${newFontSize.body};
        line-height: 16px;
        letter-spacing: 0.25px;
        ${'' /* color: ${colors.primaryColor}; */}
      }
    }

    .active {
      background-color: ${colors.primaryColor};
      color: ${colors.white};
      border-radius: 10px;
    }

    .component {
      grid-column-start: 2;
      grid-column-end: 4;
      width: 100%;
      height: 100%;
      position: relative;
      margin-right: 0;
      // overflow-y: scroll;
      // overflow-x: hidden;
      margin: 15px 0 0 20px;

      &::-webkit-scrollbar {
        width: 2px;
        // background: white;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 2px;
        background: ${colors.primaryColor};

        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 80%;
      }
    }
  }
`;
