import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FaqsScreen } from '../../screens/HelpScreen/FaqsScreen/FaqsScreen';
import { GuideScreen } from '../../screens/HelpScreen/GuideScreen/GuideScreen';
import { OverviewHelp } from '../../screens/HelpScreen/OverviewHelp/OverviewHelp';
import { ProfileHelpScreen } from '../../screens/HelpScreen/ProfileHelpScreen/ProfileHelpScreen';
import { TutorialsScreen } from '../../screens/HelpScreen/TutorialsScreen/TutorialsScreen';
import { routesHelp } from '../routes';
import { HelpNavigationWrapper } from './HelpNavigation.style';

export const HelpNavigation = () => {
  return (
    <HelpNavigationWrapper>
      <Routes>
        <Route path="/" element={<OverviewHelp />} />
        <Route path={routesHelp.faqs} element={<FaqsScreen />} />
        <Route path={routesHelp.guide} element={<GuideScreen />} />
        <Route path={routesHelp.tutorials} element={<TutorialsScreen />} />
        <Route path={routesHelp.profile} element={<ProfileHelpScreen />} />
      </Routes>
    </HelpNavigationWrapper>
  );
};
