import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardGird } from '../../../components/CardGrid/CardGird';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterLocutions, dataScreenLocutions } from '../../../data/dataApplications';
import { nameTables } from '../../../data/dataTable';
import useApplications from '../../../hooks/useApplications';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import { LocutionsScreenWrapper } from './LocutionsScreen.style';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import { IconCloudUpload, IconBrandHipchat, IconPlayerPlayFilled } from '@tabler/icons-react';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';

// import { NavTable } from '../../../components/NavTable/NavTable';

export const LocutionsScreen = () => {
  const { arrayLocutionsHook, getApplicationsLocutions, deleteAPPLocutions, uploadFileLocutions, audios, loading } = useApplications();
  const { typeSwal } = useSwal();
  const { isCardView } = useView();

  const [checkBoxesActives, setCheckboxesActives] = useState([]);
  const [showAudioControl, setShowAudioControl] = useState('');

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const { notFound, icon } = dataScreenLocutions;

  let navigate = useNavigate();

  const filesMP3 = audios.audio;

  useEffect(() => {
    getApplicationsLocutions();
    setSearch([]);
  }, [setSearch, getApplicationsLocutions, showAudioControl]);

  function foundFile(filename) {
    let file;
    if (filesMP3.find((file) => file.includes(filename))) {
      file = filename;
    }
    if (file !== undefined) {
      if (import.meta.env.VITE_NODE_ENV === 'production' || import.meta.env.VITE_NODE_ENV === 'test' || import.meta.env.VITE_NODE_ENV === 'development') {
        // return import.meta.env.VITE_APP_URL.replace('api', 'uploads') + 'mp3/' + file;
        return import.meta.env.VITE_APP_UPLOAD_FOLDER + 'mp3/' + file;
        // return 'mp3/' + file;
      } else {
        return import.meta.env.VITE_APP_UPLOAD_FOLDER + 'mp3/' + file;
      }
    }
  }

  const DATA = arrayLocutionsHook.map(({ filename, description, createdAt, type, id, companyId, Company }) => ({
    filename,
    description,
    createdAt,
    id,
    type,
    companyId,
    group: Company?.name,
    filepath: foundFile(filename),
  }));

  const handleShowAudioControl = (row) => {
    // event.preventDefault;
    setShowAudioControl(row.original.id);
  };
  const COLUMNS = [
    {
      Header: '',
      accessor: 'type',
      disableSortBy: true,
      maxWidth: 5,
      width: 5,
      Cell: ({ row }) => <div>{row.original.type === 1 || row.original.type === true ? <IconBrandHipchat /> : <IconCloudUpload />}</div>,
    },
    {
      Header: 'Nombre',
      accessor: 'description',
      maxWidth: 700,
      minWidth: 540,
      width: 600,
    },
    {
      Header: 'Archivo',
      accessor: 'filename',
    },
    {
      Header: 'Sede',
      accessor: 'group',
    },
    {
      Header: 'Locución',
      accessor: 'filepath',
      disableSortBy: true,
      // row.original.filepath ? '..' + row.original.filepath :
      Cell: ({ row }) => (
        <div className="tooltip-Locutions">
          {showAudioControl === row.original.id ? (
            <audio src={'/uploads/mp3/' + row.original.filename} controls autoPlay />
          ) : (
            <IconPlayerPlayFilled onClick={() => handleShowAudioControl(row)} className="audioplayer__icon" />
          )}
        </div>
      ),
    },
  ];

  const dataExport = arrayLocutionsHook.map(({ filename, description, Company }) => ({
    Identificación: filename,
    Descripción: description,
    Grupo: Company?.name,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(({ filename, description, createdAt, id, companyId, group }) => ({
        title: filename,
        icon: icon.locutions,
        subtitle: description,
        id,
        icon2: icon.home,
        text: group,
        audio: foundFile(filename),
        component: 'audio',
        filename,
        description,
        createdAt,
        companyId,
        group,
      }));
    } else {
      return DATA.map(({ filename, description, createdAt, id, companyId, Company }) => ({
        title: filename,
        icon: icon.locutions,
        subtitle: description,
        id,
        icon2: icon.home,
        text: Company?.name,
        audio: foundFile(filename),
        component: 'audio',
        filename,
        description,
        createdAt,
        companyId,
        group: Company?.name,
      }));
    }
  };

  const dataToCards = dataCard();

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.applications}/${routesApplications.createLocutions}`, {});
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.applications}/${routesApplications.updateLocutions}`, {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteAPPLocutions(item.original);
          } else {
            response = await deleteAPPLocutions(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getApplicationsLocutions();
            typeSwal('delete');
          }
          if (response.status === 203) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            getApplicationsLocutions();
            typeSwal('error', table);
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Nombre: '',
      'Grupo Sede id': '',
      Identificación: '',
    },
  ];

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileLocutions(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        getApplicationsLocutions();
        typeSwal('upload');
      }
    } catch (err) {
      return err;
    } finally {
      // window.location.reload()
    }
  };
  const [showTopBtn, setShowTopBtn] = React.useState(false); // useEffect(() => {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <LocutionsScreenWrapper className="screen__inside-size" isFilter={isFilter.open}>
      {showTopBtn && <ScrollToTop />}
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          isNavTable={true}
          DATA={DATA}
          handleOpenFormCreate={handleOpenFormCreate}
          checkboxesActives={checkBoxesActives}
          setCheckboxesActives={setCheckboxesActives}
        />
        {!arrayLocutionsHook[0]?.status ? (
          <div className="contain__menuFilter">
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterLocutions} setSearch={setSearch} />
          </div>
        ) : null}
        {/* <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteAPPLocutions} totalDocs={arrayLocutionsHook.length} /> */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayLocutionsHook[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
          {/* <div className={'contain__menuFilter'}>
                        <MenuFilter
                            isFilter={isFilter}
                            setIsFilter={setIsFilter}
                            data={items}
                            filter={dataFilterLocutions}
                            setSearch={setSearch}
                            whichFilters={whichFilters}
                            setWhichFilters={setWhichFilters}
                        />
                    </div> */}
        </div>
      ) : (
        <>
          <div className="contain__table">
            <TableInfo
              COLUMNS={COLUMNS}
              DATA={search.length === 0 ? DATA : search}
              format={'list'}
              deleteFunction={handleDelete}
              updateFunction={handleOpenFormEdit}
              ref={tableInstance}
              onClick={handleOpenFormEdit}
              setIsBottom={setIsBottom}
              isNavTable={false}
              checkboxesActives={checkBoxesActives}
              setCheckboxesActives={setCheckboxesActives}
            />
            {/* <div className={'contain__menuFilter'}>
                            <MenuFilter
                                isFilter={isFilter}
                                setIsFilter={setIsFilter}
                                data={items}
                                filter={dataFilterLocutions}
                                setSearch={setSearch}
                                whichFilters={whichFilters}
                                setWhichFilters={setWhichFilters}
                            />
                        </div> */}
          </div>
        </>
      )}
    </LocutionsScreenWrapper>
  );
};

LocutionsScreen.propTypes = { row: string };
