import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ExtensionsFormScreen } from '../../screens/NumberingScreen/ExtensionsScreen/ExtensionsFormScreen';

import { ExtensionsScreen } from '../../screens/NumberingScreen/ExtensionsScreen/ExtensionsScreen';
import { ListNumberFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberFormScreen';
import { ListNumberScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberScreen';
import { OverviewNumberingScreen } from '../../screens/NumberingScreen/OverviewNumberingScreen/OverviewNumberingScreen';
import { routesNumbering } from '../routes';
import { NumberingsNavigationWrapper } from './NumberingNavigation.style';
import { useStore } from 'react-redux';
import { MarcaScreen } from '../../screens/NumberingScreen/Marca/MarcaScreen';
import { ModeloScreen } from '../../screens/NumberingScreen/Modelo/ModeloScreen';
import { PlantillaScreen } from '../../screens/NumberingScreen/Plantilla/PlantillaScreen';
import { PlantillaForm } from '../../screens/NumberingScreen/Plantilla/PlantillaForm';
import { MarcaForm } from '../../screens/NumberingScreen/Marca/MarcaForm';
import { ModeloForm } from '../../screens/NumberingScreen/Modelo/ModeloForm';

export const NumberingNavigation = () => {
  const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];

  return (
    <NumberingsNavigationWrapper isMenuOpen={isMenuOpen} className="listnumber__container">
      <Routes>
        <Route path="/" element={<OverviewNumberingScreen />} />
        <Route path={routesNumbering.marcasList} element={<MarcaScreen />} />
        <Route path={routesNumbering.createmarca} element={<MarcaForm crudType="create" />} />
        <Route path={routesNumbering.updatemarca} element={<MarcaForm crudType="edit" />} />

        <Route path={routesNumbering.modelosList} element={<ModeloScreen />} />
        <Route path={routesNumbering.createmodelo} element={<ModeloForm crudType="create" />} />
        <Route path={routesNumbering.updatemodelo} element={<ModeloForm crudType="edit" />} />

        <Route path={routesNumbering.plantillasList} element={<PlantillaScreen />} />
        <Route path={routesNumbering.createPlantilla} element={<PlantillaForm crudType="create" />} />
        <Route path={routesNumbering.updatePlantilla} element={<PlantillaForm crudType="edit" />} />

        <Route path={routesNumbering.numberingList} element={<ListNumberScreen />} />
        <Route path={routesNumbering.createNumberingList} element={<ListNumberFormScreen crudType="create" />} />
        <Route path={routesNumbering.updateNumberingList} element={<ListNumberFormScreen crudType="edit" />} />
        <Route path={routesNumbering.extensions} element={<ExtensionsScreen />} />
        <Route path={routesNumbering.createExtensions} element={<ExtensionsFormScreen crudType="create" />} />
        <Route path={routesNumbering.updateExtensions} element={<ExtensionsFormScreen crudType="edit" />} />
      </Routes>
    </NumberingsNavigationWrapper>
  );
};
