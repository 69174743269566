import styled from 'styled-components';
import { colors } from '../../theme/colors.js';
import { newFontSize, weight } from '../../theme/fonts';

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 20px;

  .box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 10px;
    //transition: all 0.3s;

    __title {
      color: ${colors.black};
      font-weight: ${weight.regular};
      font-size: ${newFontSize.label};
      padding: 0 5px;
      width: 100px;
    }
  }
`;
