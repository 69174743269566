import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const AppsScreenWrapper = styled.div`
  ${'' /* .applications { */}
  ${'' /* position:fixed; */}
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  ${'' /* padding-top: 10px; */}
  background-color: ${colors.backgroundColor};

  .contain__table {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 70px;
    padding-left: 20px;
    ${'' /* overflow: scroll; */}
  }

  .distributionTable {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  ${
    '' /* .pageScreen__tabs {
    padding-top: 100px;
    margin: auto;
  } */
  }

  ${
    '' /* #soundPrompt__5 {
    display: flex;
    min-width: 210px;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;

    audio {
      justify-content: center;
      align-items: center;
    }
  } */
  }
 audio {
    ${'' /* position: absolute; */}
    width: 175px;
    height: 32px;
  }
  .container__cards {
    width: 80%;
    margin: auto;
    ${'' /* padding-top: 20px; */}

    .stepForm__contain-typeInput {
      .contain__input,
      .contain__select {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        font-size: 16px;
      }
    }

    .form__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: flex-end;
      text-align: left;
      color: ${colors.primaryColor};
      font-size: 14px;
      font-weight: bold;
      margin: auto;
      border-radius: 10px 10px 0 0px;
      padding: 5px;
    }
  }
  .form__user__logo {
    top: 90px;
  }

  .diffusion__distributionTable {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 110px;
    /* padding-top: 138px;
  padding-left: 200px; */
    overflow: scroll;
    left: 130px;
  }
`;
