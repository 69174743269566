import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imagesLogin, keywords } from '../../data/dataLogin';
import { useLoginForm } from '../../hooks/useLoginForm';
import { useSwal } from '../../hooks/useSwal';
import useUsers from '../../hooks/useUsers';
import { LoginWrapper, BubbleContainer } from './LoginScreen.style';
import PropTypes from 'prop-types';
import { IconEye, IconKey, IconEyeOff, IconMail } from '@tabler/icons-react';

// import PasswordMask from 'react-password-mask';
// import { useRef } from 'react';
// import IconButton from '@mui/icons-material';
// import InputLabel from '@material-ui/core/InputLabel';
// import Visibility from '@mui/icons-material/Visibility';
// import InputAdornment from '@mui/material/InputAdornment';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import Input from '@mui/material/Input';

export const LoginScreen = ({ setAuth }) => {
  const { handleChange, userData, setUserData, initialData } = useLoginForm();
  const { getLoginUser } = useUsers();
  const { typeSwal } = useSwal();

  let navigate = useNavigate();
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // let inputRef = useRef();
  // const showIcon = () => <i className="fa fa-eye" aria-hidden="true"></i>;
  // const hideIcon = () => <i className="fa fa-eye-slash" aria-hidden="true"></i>;

  useEffect(() => {
    setTimeout(() => {
      if (loading) {
        const user = JSON.parse(localStorage.getItem('tokenStorage')) || false;
        if (user) {
          setAuth(user);
          navigate('/', { replace: true });
        } else {
          setLoadingCounter(loadingCounter + 1);
        }
      }
    }, 100);
  }, [loading, loadingCounter]);

  const onLoginUp = async (evt) => {
    evt.preventDefault();
    let response = await getLoginUser(userData);
    if (response?.status === 203) {
      typeSwal('errorLogin');
    } else {
      setLoading(true);
      setUserData(initialData);
    }
  };

  // const [values, setValues] = React.useState({
  //   password: '',
  //   showPassword: false,
  // });

  const handleClickShowPassword = () => {
    //   setValues({
    //     ...values,
    //     showPassword: !values.showPassword,
    //   });
    setShowPassword(!showPassword);
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const handlePasswordChange = (prop) => (event) => {
  //   setValues({
  //     ...values,
  //     [prop]: event.target.value,
  //   });
  // };

  return (
    <LoginWrapper>
      <div className="bubble1">
        <div className="bubble1__contain">
          <BubbleContainer time={25}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.4 374.7">
              <path d="M309.9 70.6c37.8 52.7 39.8 128.7 15.4 184.1-24.3 55.4-75 90.1-125.4 107.4-50.4 17.4-100.4 17.4-136.2-3.3-35.7-20.7-57.2-62-62.4-102.1-5.2-40.2 5.8-79 29.1-128.3C53.6 79.1 89.1 19.3 143.7 4.1 198.3-11.2 272 18 309.9 70.6z"></path>
            </svg>
          </BubbleContainer>
        </div>
      </div>
      <div className="form">
        <div className="form__logo">
          <img src={imagesLogin.logoCompany} alt="logo-company" />
        </div>
        <div className="form__title">
          {/* <h1>Wide 4</h1> */}
          <h4>{keywords.subtitle}</h4>
        </div>
        <div className="form__subtitle">
          <h1>{keywords.title}</h1>
        </div>
        <div className="form__contain">
          <form className="form__steps-contain">
            <div className="form__contain-email">
              <div className="form__contain_email-label">
                <IconMail color={'white'} alt="email" />
                <label>{keywords.email}</label>
              </div>
              <div className="form__contain_email-input">
                <input type="email" id="email" value={userData.email} autoComplete="username" placeholder="ejemplo@gmail.com" onChange={(evt) => handleChange(evt)} />
              </div>
            </div>
            <div className="form__contain-password">
              <div className="form__contain_password-label">
                <IconKey color={'white'} alt="fingerprint" />
                <label>{keywords.password}</label>
              </div>
              <div className="form__contain_password-input">
                <input
                  type={showPassword ? 'text' : 'password'}
                  // type={'password'}
                  id="password"
                  autoComplete="current-password"
                  value={userData.password}
                  placeholder="*********"
                  onChange={(evt) => handleChange(evt)}
                  // onChange={handlePasswordChange('password')}
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  //       {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {!showPassword ? <IconEye onClick={handleClickShowPassword} className="icon__showPass" /> : <IconEyeOff onClick={handleClickShowPassword} className="icon__showPass" />}
                {/* <PasswordMask id="password" name="password" placeholder="Enter password" value={this.state.password} onChange={this.handleChange.bind(this)} /> */}
              </div>
            </div>
            <div className="form__contain-button">
              <button className="button" onClick={(e) => onLoginUp(e, setAuth)}>
                {keywords.button}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="bubble2">
        <BubbleContainer time={35}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 747.2 726.7">
            <path d="M539.8 137.6c98.3 69 183.5 124 203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 144.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"></path>
          </svg>
          {/* <img src={logo} /> */}
        </BubbleContainer>
      </div>
    </LoginWrapper>
  );
};

LoginScreen.propTypes = { setAuth: PropTypes.func };
