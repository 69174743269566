import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const ToggleButtonWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${'' /* padding-top: 10px; */}
  ${'' /* padding: 5px; */}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }

  .active__label {
    opacity: 1;
    font-weight: bold;
    width: max-content;
  }

  .label {
    opacity: 1;
    ${'' /* background-color: ${colors.backgroundColor}; */}
    width: 120px;
  }

  .toggle__options {
    display: flex;

    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
    ${'' /* gap: 10px; */}
    color: ${colors.white};
    ${'' /* border-radius: 10px 0 0 10px; */}
    padding: 5px;
    border-radius: 10px;
    ${'' /* border-style: inset; */}
    ${'' /* border: 1px solid ${colors.primaryColorLight}; */}
    ${
      '' /* border-left: none;
    border-right: none; */
    }
    ${'' /* background-color: ${colors.primaryColorLight}; */}
    padding: 15px;

    &__left,
    &__right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${'' /* padding: 10px; */}
      gap: 20px;
      height: 50px;
      width: 200px;
      font-size: ${newFontSize.h4};
      font-weight: ${weight.regular};
      color: ${colors.white};
    }
    &__left {
      border-right: 1px solid ${colors.greyBorder};
      background-color: ${(props) => (props.isActive === 2 ? colors.secondaryColorLight : colors.greenGYGAS)};
      border-radius: 10px 0 0 10px;
      color: ${(props) => (props.isActive === 2 ? colors.primaryColor : colors.white)};
    }

    &__right {
      background-color: ${(props) => (props.isActive === 2 ? colors.greenGYGAS : colors.secondaryColorLight)};
      border-radius: 0 10px 10px 0;
      color: ${(props) => (props.isActive === 2 ? colors.primaryColor : colors.white)};
    }

    &:hover {
      background-color: ${colors.primaryColor};
      span {
        opacity: 1;
      }
    }
  }

  .toggleButton {
    width: 440px;
    display: flex;
    border: 1px solid ${colors.primaryColorLight};
    border-style: inset;
    border-radius: 10px;
    justify-content: flex-end;
    svg {
      width: 100%;
      height: 30px;
      padding: 0;
      border: none;
      background-color: unset;
      color: ${colors.primaryColor};
      flex: 1;
    }

    .toggle__options {
      &__left,
      &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 100%;
        font-size: ${newFontSize.h4};
        font-weight: ${weight.bold};
        color: ${colors.white};
        transition: 0.5s linear;
        ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}
        background-color: ${colors.primaryColorLight};
        border: 0.5px solid ${colors.primaryColor};
        ${'' /* box-shadow: 2px 2px 4px 0px ${colors.secondaryColor}; */}

        &:hover {
          opacity: 0.7;
          border: 1px solid ${colors.primaryColorLight};
          color: ${colors.primaryColor};
          background-color: ${colors.greenGYGASLight};
          -webkit-transition: all 0.5s linear;
          -moz-transition: all 0.5s linear;
          -o-transition: all 0.5s linear;
          transition: all 0.5s linear;
          ${
            '' /* -moz-box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
          -webkit-box-shadow: 2px 2px 4px 0px ${colors.primaryColorDark}; */
          }
          ${'' /* box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight}; */}

          span,
          svg {
            ${'' /* opacity: 1; */}
            color: ${colors.primaryColor};
            ${'' /* font-weight: ${weight.bold}; */}
            ${
              '' /* max-width: 140px;
            width: 100%; */
            }
          }
        }

        svg {
          color: ${colors.white};
        }

        span {
          opacity: 1;
          color: ${colors.white};
          font-weight: ${weight.bold};
          max-width: 140px;
          width: 100%;
        }
      }

      &__left {
        border-radius: 10px 0 0 10px;
        color: ${colors.white};
        padding: 5px;
        opacity: 1;
        padding: 10px;

        &:hover {
          -moz-border-radius: 7px 7px 7px 7px;
          -webkit-border-radius: 10px 0 0 10px;
        }
      }

      &__right {
        border-radius: 0 10px 10px 0;
        color: ${colors.white};
        padding: 5px;
        opacity: 1;
        padding: 10px;

        &:hover {
          -moz-border-radius: 7px 7px 7px 7px;
          -webkit-border-radius: 0 10px 10px 0;
        }
      }
    }

    .active {
      background-color: ${colors.greenGYGAS};
      color: ${colors.primaryColor};
      opacity: 1;
      transition: background-color 1s ease-out;
      box-shadow: unset;
      border: 0.5px solid ${colors.primaryColor};

      svg {
        color: ${colors.primaryColor};
      }

      span {
        color: ${colors.primaryColor};
      }

      &:hover {
        font-weight: ${weight.bold};
        background-color: ${colors.greenGYGASLight};
        svg {
        }
      }
    }
  }
`;
