import styled from 'styled-components';
import { colors } from '../../../theme/colors.js';

export const ListNumberModalWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  background-color: ${colors.backgroundColor};
  border-radius: 15px;

  .contain__button-save {
    position: relative;
    top: 0px;
  }
  .contain__inputCard-component {
    padding: 20px 20px 30px 20px;
  }

  .form__title {
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding-left: 10px;
    gap: 10px;
    background-color: ${colors.primaryColor};
    color: ${colors.backgroundColor};
  }

  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    padding: 20px 36px 0;
    //transition: all 1s easy-out;
  }

  .contain__notFound {
    height: calc(100% - 55px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
