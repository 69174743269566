import { actionTypesPreferences } from '../../actions/preferences/actionTypes';

// Schedule Array Reducer
export const scheduleReducer = (scheduleArray = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypesPreferences.getSchedules:
      scheduleArray = [...payload];
      break;

    default:
      // scheduleArray = scheduleArray;
      break;
  }

  return scheduleArray;
};

// Schedule Form Reducer

const initalState = {
  scheduleId: '',
  scheduleHoursId: [],
  name: '',
  schedule: {},
};

export const scheduleFormReducer = (scheduleState = initalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypesPreferences.changeScheduleName:
      scheduleState = {
        ...scheduleState,
        name: payload,
      };
      break;

    case actionTypesPreferences.changeScheduleId:
      scheduleState = {
        ...scheduleState,
        scheduleId: payload,
      };
      break;

    case actionTypesPreferences.changeSchedule:
      scheduleState = {
        ...scheduleState,
        schedule: payload,
      };
      break;

    case actionTypesPreferences.addScheduleTimeId:
      scheduleState = {
        ...scheduleState,
        scheduleHoursId: [...scheduleState.scheduleHoursId, payload],
      };
      break;

    case actionTypesPreferences.removeScheduleTimeId:
      scheduleState = {
        ...scheduleState,
        scheduleHoursId: scheduleState.scheduleHoursId.filter((id) => id !== payload),
      };
      break;

    case actionTypesPreferences.clearScheduleForm:
      scheduleState = initalState;
      break;

    default:
      break;
  }
  return scheduleState;
};

// Calendar Array Reducer
export const calendarReducer = (calendarArray = [], action) => {
  let newCalendarArray;
  const { type, payload } = action;

  switch (type) {
    case actionTypesPreferences.getCalendar:
      newCalendarArray = [...payload];
      break;

    default:
      newCalendarArray = calendarArray;
      break;
  }

  return newCalendarArray;
};

// Calendar Form Reducer

const initalStateCalendar = {
  calendarId: '',
  calendarDaysId: [],
  name: '',
  color: '',
  days: [],
};

export const calendarFormReducer = (calendarState = initalStateCalendar, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypesPreferences.changeCalendarName:
      calendarState = {
        ...calendarState,
        name: payload,
      };
      break;

    case actionTypesPreferences.changeCalendarColor:
      calendarState = {
        ...calendarState,
        color: payload,
      };
      break;

    case actionTypesPreferences.changeCalendarDays:
      calendarState = {
        ...calendarState,
        days: payload,
      };
      break;

    case actionTypesPreferences.changeCalendarLinkId:
      calendarState = {
        ...calendarState,
        calendarId: payload,
      };
      break;

    case actionTypesPreferences.addCalendarDaysId:
      calendarState = {
        ...calendarState,
        calendarDaysId: payload,
      };
      break;

    case actionTypesPreferences.clearCalendarForm:
      calendarState = initalStateCalendar;
      break;

    default:
      break;
  }
  return calendarState;
};
