import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const UsersScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: auto;

  .users-container {
    background-color: ${colors.backgroundColor};
    border-radius: 15px;
  }

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .userlogo__container,
  .fullName {
    max-width: 45px;
    width: auto;
    min-width: auto;
    height: 20px;
  }

  .user__logo {
    width: 30px;
    height: 30px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
    border-radius: 50%;
  }

  .users__screen {
    width: 100%;
    height: 100%;
    border: 1px solid ${colors.primaryColor};
    background-color: ${colors.backgroundColor};
    border-radius: 15px;
    ${
      '' /* position: fixed;
    left: ${(p) => (p.isMenuOpen ? '229px' : '68px')}; */
    }
    ${'' /* top: top; */}
  }

  ${
    '' /* .modal__form__body {
    width: 700px;
    height: auto;
    position: relative;
    background-color: white;
    top: 200px;
    margin: auto;
    z-index: 9;
    opacity: 1;
    border-radius: 15px;

    .container__cards .contain__inputCard-component {
      width: 100%;
      height: 100%;
      padding: 40px;
      /* padding-right: 220px; */
  } */}

    ${
      '' /* .contain__button-save {
      opacity: 1;
      width: 100%;
      height: 45px;
      position: relative;
      top: -15px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-radius: 10px;
      color: white;
      z-index: unset;

      button {
        color: ${colors.black};
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        border-radius: 10px;
        ${'' /* width: 120px; */
    }
    ${
      '' /* font-size: 14px;
        margin-right: 20px;
      }

      button:hover {
        color: white;
      }

      .cancel_button {
        background-color: ${colors.primaryColor};
        height: 35px;
        border: 1px solid #474849;

        &:hover {
          color: white;
          background-color: ${colors.primaryColorLight};
        }
      }

      .save_button {
        color: black;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: #2faa43;
        }
      } */
    }
   

    .stepForm {
      width: 100%;
      margin: auto;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      /* max-width: 600px; */
      row-gap: 13px;
      row-gap: 20px;
      margin: auto;
      max-width: unset;

      &__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        ${'' /* text-align: left; */}
        color: #0c3fc4;
        font-size: 14px;
        font-weight: bold;
        ${'' /* top: 50px; */}
        margin: auto;
        width: 100%;
      }
    }

    .form__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      text-align: left;
      color: ${colors.black};
      font-size: 14px;
      font-weight: bold;
      top: 50px;
      margin: auto;
      width: 100%;
      ${'' /* background-color: ${colors.primaryColor}; */}
      border-radius: 10px 10px 0 0px;
      padding: 5px;
      
     
    }

    ${
      '' /* .title__Form_title {
      color: ${colors.backgroundColor};
    } */
    }

    .stepForm__contain-typeInput {
      ${
        '' /* .stepForm__toggle {
          grid-column: span 4 / span 4;
        } */
      }

      .basicRow {
        grid-column: span 1;
      }

      .contain__select {
        ${'' /* grid-column: span 3; */}
        ${
          '' /* grid-row-start: 1;
        grid-column-start: 1; */
        }
        width: 100%;
        min-width: 200px;
      }
    }

    ${
      '' /* .form__body {
      margin-top: 0;
      top: 0;
      min-height: 220px;
      width: 100%;
      ${'' /* max-width: 600px; */
    }
      .icon__Form_title {
        color: white;
      margin-left: 20px;
    
      }
     */

    .contain__input {
      ${'' /* position: relative; */}
      top: -60px;
      ${'' /* left: 150px; */}
      ${
        '' /* grid-column-start: 1;
      grid-row-start: 1; */
      }
    }

    .input-disabled {
      width: 250px;
      background-color: hsl(0, 0%, 95%);
      border: 0;
    }
    #allow {
      ${'' /* grid-column-start: 2; */}
    }
  

  .contain__menuFilter {
    height: calc(100% - 54px);
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }
`;
