import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessLevelsThunk } from '../redux/thunks/accesslevelsThunk';

export const useAccessLevels = () => {
  const dispatch = useDispatch();
  //   const { getState } = useStore();
  const [, setLoading] = useState(true);

  const arrayAccessLevelsHook = useSelector((accessLevels) => accessLevels.usersAccessLevelReducer);
  // const accesslevelsHook = useSelector((accessLevels) => accessLevels.departmentGetReducer);
  //   const departmentForm = useSelector((item) => item.formDepartmentReducer);
  //   const loadListAccessLevels = useSelector((item) => item.loadListAccessLevels);

  const getAccessLevels = useCallback(() => {
    dispatch(getAccessLevelsThunk(setLoading));
  }, [dispatch]);

  return {
    arrayAccessLevelsHook,
    getAccessLevels,
  };
};
