import React from 'react';
import { GuideScreenWrapper } from './GuideScreen.style';

export const GuideScreen = () => {
  return (
    <GuideScreenWrapper>
      <h1>GuideScreen</h1>
    </GuideScreenWrapper>
  );
};
