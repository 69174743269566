import React from 'react';
// import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';

import './ProfileScreen.css';

export const ProfileScreen = () => {
  return (
    <div className="profile-screen">
      {/* <Header image={menuNavLeft.menuBottom[0].image} text={menuNavLeft.menuBottom[0].text} /> */}
      <MenuTop menu={[]} />
    </div>
  );
};
