import React from 'react';
// import { IconArrowAutofitContent } from '@tabler/icons-react';

import {
  IconMicrophone,
  IconUsers,
  IconPhone,
  IconMicrophone2,
  IconPhoneOff,
  IconRainbow,
  IconResize,
  IconCloud,
  IconStack,
  IconGridDots,
  IconSchema,
  IconApiApp,
  IconWorldWww,
} from '@tabler/icons-react';
import { optionsDestination } from '../data/dataApplications';
import { IconSettings } from '@tabler/icons-react';
import { IconVariable } from '@tabler/icons-react';
import { IconUser } from '@tabler/icons-react';
import { IconDevices } from '@tabler/icons-react';
import { IconNumbers } from '@tabler/icons-react';
import { IconBrandMedium } from '@tabler/icons-react';
import { IconCoinMonero } from '@tabler/icons-react';
import { IconReplace } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import { IconPlaylist } from '@tabler/icons-react';
import { IconChartArcs3 } from '@tabler/icons-react';
import { IconActivity } from '@tabler/icons-react';
import { IconChartLine } from '@tabler/icons-react';
import { IconHeadset } from '@tabler/icons-react';
import { IconBoxPadding } from '@tabler/icons-react';
import { IconArrowWaveRightUp } from '@tabler/icons-react';
import { IconAlertOctagon } from '@tabler/icons-react';
import { IconSpeakerphone } from '@tabler/icons-react';
import { IconBook } from '@tabler/icons-react';
import { IconHome } from '@tabler/icons-react';
import { IconShield } from '@tabler/icons-react';
import { IconLicense } from '@tabler/icons-react';
import { IconCalendar } from '@tabler/icons-react';
import { IconLayoutDashboard } from '@tabler/icons-react';

export const getDispo = (arrayNumberingExtensionsHook, userId) => {
  let newArray = '';

  arrayNumberingExtensionsHook?.forEach((itemExt) => {
    itemExt.userId === userId ? (newArray = newArray !== '' ? newArray + ', ' + itemExt.typeExtension : newArray + itemExt.typeExtension) : null;
  });

  return newArray;
};

export const getCalendarsInfo = (calendars, calendarArrayHook) => {
  const newCalendars = [];
  calendars.forEach((itemCalendar) =>
    calendarArrayHook?.find((itemCal) => {
      if (itemCal[0].CalendarName.id === itemCalendar) {
        newCalendars.push(itemCal[0]);
        // ({ value: newCalendars.length + 1, id: itemCal[0].CalendarName.id, label: itemCal[0].CalendarName.name });
      }
    })
  );
  return newCalendars;
};

export const getCalendarInfo = (item, calendarArrayHook) => {
  const idCal = item?.id || 1;

  const newValue = calendarArrayHook[0]?.forEach((itemCal) => {
    itemCal?.CalendarName.id === idCal;
  });
  return newValue;
};

export const getIcon = (row, askedBy) => {
  let newIcon;

  switch (askedBy) {
    case 'form':
      switch (row) {
        case 'usersList':
          newIcon = <IconUser className="icon__Form_title" />;
          break;
        case 'users':
          newIcon = <IconUser className="icon__Form_title" />;
          break;
        case 'extensions':
          newIcon = <IconDevices className="icon__Form_title" />;
          break;
        case 'numberingList':
          newIcon = <IconNumbers className="icon__Form_title" />;
          break;
        case 'marcas':
          newIcon = <IconBrandMedium className="icon__dispositivo" />;
          break;
        case 'marcasList':
          newIcon = <IconBrandMedium className="icon__dispositivo" />;
          break;
        case 'modelos':
          newIcon = <IconCoinMonero className="icon__dispositivo" />;
          break;
        case 'modelosList':
          newIcon = <IconCoinMonero className="icon__dispositivo" />;
          break;
        case 'plantillas':
          newIcon = <IconReplace className="icon__dispositivo" />;
          break;
        case 'plantillasList':
          newIcon = <IconReplace className="icon__dispositivo" />;
          break;
        case 'mailboxes':
          newIcon = <IconMail className="icon__dispositivo" />;
          break;
        case 'conference':
          newIcon = <IconMicrophone2 className="icon__dispositivo" />;
          break;
        case 'ivr':
          newIcon = <IconPhone className="icon__dispositivo" />;
          break;
        case 'locutions':
          newIcon = <IconMicrophone className="icon__dispositivo" />;
          break;
        case 'shortMarking':
          newIcon = <IconResize className="icon__dispositivo" />;
          break;
        case 'songWaiting':
          newIcon = <IconPlaylist className="icon__dispositivo" />;
          break;
        case 'queue':
          newIcon = <IconStack className="icon__dispositivo" />;
          break;
        case 'general':
          newIcon = <IconChartArcs3 className="icon__dispositivo" />;
          break;
        case 'activity':
          newIcon = <IconActivity className="icon__dispositivo" />;
          break;
        case 'productivity':
          newIcon = <IconChartLine className="icon__dispositivo" />;
          break;
        case 'customer':
          newIcon = <IconHeadset className="icon__dispositivo" />;
          break;
        case 'groupcategories':
          newIcon = <IconGridDots className="icon__dispositivo" />;
          break;
        case 'categories':
          newIcon = <IconBoxPadding className="icon__dispositivo" />;
          break;
        case 'trunks':
          newIcon = <IconArrowWaveRightUp className="icon__dispositivo" />;
          break;
        case 'alerts':
          newIcon = <IconAlertOctagon className="icon__dispositivo" />;
          break;
        case 'diffusionLists':
          newIcon = <IconSpeakerphone className="icon__dispositivo" />;
          break;
        case 'history':
          newIcon = <IconBook className="icon__dispositivo" />;
          break;
        case 'company':
          newIcon = <IconHome className="icon__dispositivo" />;
          break;
        case 'department':
          newIcon = <IconSchema className="icon__dispositivo" />;
          break;
        case 'permissions':
          newIcon = <IconShield className="icon__dispositivo" />;
          break;
        case 'apiKey':
          newIcon = <IconApiApp className="icon__dispositivo" />;
          break;
        case 'domains':
          newIcon = <IconWorldWww className="icon__dispositivo" />;
          break;
        case 'whitelist':
          newIcon = <IconLicense className="icon__dispositivo" />;
          break;
        case 'calendar':
          newIcon = <IconCalendar className="icon__dispositivo" />;
          break;
        case 'configurationAsterisk':
          newIcon = <IconSettings className="icon__dispositivo" />;
          break;
        case 'dashboard':
          newIcon = <IconLayoutDashboard className="icon__dispositivo" />;
          break;
        case 'Dashboard':
          newIcon = <IconLayoutDashboard className="icon__dispositivo" />;
          break;
        default:
          newIcon = null;
      }
      break;
    case 'extensions':
      switch (row.row.original.typeExtension) {
        case 'rainbow':
          newIcon = <IconRainbow className="icon__dispositivo" />;
          break;
        case 'virtual':
          newIcon = <IconCloud className="icon__dispositivo" />;
          break;
        case 'SIP':
          newIcon = <IconPhoneOff className="icon__dispositivo" />;
          break;
        case 'teams':
          newIcon = <IconUsers className="icon__dispositivo" />;
          break;
        case 'terminal':
          newIcon = <IconPhone className="icon__dispositivo" />;
          break;
        default:
          newIcon = null;
      }
      break;
    case 'pageScreen':
      switch (row.text) {
        case 'Servicios externos':
          newIcon = <IconRainbow />;
          break;
        case 'Sístema':
          newIcon = <IconSettings />;
          break;
        case 'Variables':
          newIcon = <IconVariable />;
          break;
        case 'SBC SIP/SJSIP':
          newIcon = <IconPhone />;
          break;
        case 'Alta usuarios':
          newIcon = <IconUsers />;
          break;
        default:
      }
      break;
    default:
      return null;
  }
  return newIcon;
};
export const getUserData = (arrayUsers, userId) => {
  const newData = arrayUsers?.find((itemUser) => itemUser.id === userId);

  return newData;
};

export const getArrayFiltered = (arrayToFilter, itemName, itemValue) => {
  const stringToSearch = itemValue !== 'SIP' ? itemValue.toLowerCase() : itemValue;
  const newArray = arrayToFilter?.filter((item) => item[itemName] === stringToSearch);

  return newArray;
};

export const getArrayFormatted = (arrayToFormat, fromData, formatType) => {
  const paramIsArray = Array.isArray(arrayToFormat);

  let newArray = [];

  if ((paramIsArray && arrayToFormat.length) || formatType === 'allow') {
    switch (formatType) {
      case 'allow':
        if (fromData) {
          const allowArr = !Array.isArray(arrayToFormat) ? arrayToFormat?.split(',') : arrayToFormat;

          // : [arrayToFormat];
          if (Array.isArray(allowArr) || !allowArr || allowArr[0] === undefined || allowArr[0]?.value === undefined) {
            allowArr?.forEach((newValue) => {
              const valueToSearch = newValue.replace(' ', '').toLowerCase();
              const newExt = fromData?.find((ext2) => ext2?.value === valueToSearch);

              newExt ? newArray.push({ value: newExt.id, label: newExt.label }) : null;
            });
          } else {
            newArray.push(allowArr);
          }
        }

        break;
      case 'typeExtension':
        if (fromData) {
          const allowArr = !Array.isArray(arrayToFormat) ? arrayToFormat?.split(',') : arrayToFormat;

          // : [arrayToFormat];
          if (Array.isArray(allowArr) && !allowArr[0]?.value) {
            allowArr?.forEach((newValue) => {
              const valueToSearch = newValue.replace(' ', '').toLowerCase();
              const newExt = fromData?.find((ext2) => ext2?.value === valueToSearch);

              newExt ? newArray.push({ value: newExt.id, label: newExt.label }) : null;
            });
          } else {
            newArray.push(allowArr);
          }
        }

        break;
      case 'ini':
        fromData
          ? arrayToFormat.forEach((newValue) => {
              const newExt = fromData?.find(
                (ext2) => ext2?.id.toString() === (newValue?.id !== undefined ? newValue.id?.toString() : newValue?.value ? newValue?.value?.toString() : newValue?.toString())
              );

              newExt
                ? newArray.push({
                    value: newExt?.id ? newExt?.id : newValue?.id ? newValue.id : newValue,
                    label: newExt?.identification ? newExt?.identification : newValue?.name ? newValue?.name : newValue,
                  })
                : null;
            })
          : null;

        break;
      case 'state':
        fromData
          ? arrayToFormat.forEach((newValue) => {
              const newExt = fromData?.find((ext2) => ext2?.id.toString() === (newValue?.id !== undefined ? newValue.id?.toString() : newValue?.value?.toString() || newValue?.toString()));

              newExt
                ? newArray.push({ value: newExt?.id ? newExt?.id : newValue?.id ? newValue.id : newValue, label: newExt?.name ? newExt?.name : newValue?.name ? newValue?.name : newValue })
                : null;
            })
          : null;

        break;
      case 'sort':
        fromData
          ? arrayToFormat.forEach((newValue) => {
              const newExt = fromData?.find(
                (ext2) => ext2?.id.toString() === (newValue?.id !== undefined ? newValue.id?.toString() : newValue?.value ? newValue?.value?.toString() : newValue?.toString())
              );

              newExt ? newArray.push({ id: newValue.value ? newValue.value : newValue, name: newExt?.name }) : null;
            })
          : null;

        break;
      case 'onlyFormat':
        newArray = arrayToFormat.map((newValue) => {
          return { id: newValue.value, name: newValue.label };
        });
        break;

      default:
        break;
    }
  } else {
    const newExt = fromData?.find((ext2) => ext2?.value === arrayToFormat);
    return newExt;
  }

  return newArray.length > 0 ? newArray : arrayToFormat;
};

// export const getDestinationInfo = (destinationId, destinationTypeId, arrayDestination) => {
//   const idType = optionsDestination[destinationTypeId - 1];
//   let arrayToSearch = [];

//   switch (idType.ref) {
//     case 'queue':
//       arrayToSearch = arrayDestination;
//       break;
//     case 'conference':
//       arrayToSearch = arrayDestination;
//       break;
//     case 'voicemail':
//       arrayToSearch = arrayDestination;
//       break;
//     default:
//       arrayToSearch = arrayDestination;
//       break;
//   }
//   const newValue = arrayToSearch.length ? arrayToSearch?.find((item) => item?.id === destinationId) : '';
//   return newValue;
// };

export const getDestination = (itemId, itemType) => {
  // const options = [
  //   { value: 'conference', label: 'Conferencia', ref: 'conference', options: [] },
  //   {
  //     value: 'voicemail',
  //     label: 'Búzon',
  //     ref: 'voicemail',
  //     options: [],
  //     arrayName: 'voicemail',
  //   },
  //   {
  //     value: 'ivr',
  //     label: 'IVR',
  //     ref: 'ivr',
  //     options: [],
  //     arrayName: 'ivr',
  //   },
  //   {
  //     value: 'soundPrompt',
  //     label: 'Locución',
  //     options: [],
  //     ref: 'soundPrompt',
  //     arrayName: 'soundPrompt',
  //   },
  //   {
  //     value: 'queue',
  //     label: 'Cola',
  //     options: [],
  //     ref: 'queue',
  //     arrayName: 'queue',
  //   },
  //   {
  //     value: 'extension',
  //     label: 'Usuarios',
  //     options: [],
  //     ref: 'extension',
  //     arrayName: 'extension',
  //   },
  //   {
  //     value: 'emergencyManager',
  //     label: 'Emergency Manager',
  //     options: [],
  //     ref: 'emergencyManager',
  //     arrayName: 'emergencyManager',
  //   },
  //   {
  //     value: 'external',
  //     label: 'Numero externo',
  //     type: 'input',
  //     ref: 'external',
  //     arrayName: 'external',
  //   },
  //   {
  //     value: 'hangup',
  //     label: 'Colgar llamada',
  //     ref: 'hangup',
  //     arrayName: 'hangup',
  //   },
  // ];

  if (itemId && itemType) {
    // let itemTable = '';
    // switch (options[itemType].value) {
    //   case 'queue':
    const itemTable = optionsDestination[itemType].ref;
    let found = '';

    found = optionsDestination.find((e) => e.value === itemTable);
    if (found) {
      // optionsDestination.push(found);
      return { value: found.id, label: found.label };
    }
    return false;
  }
};

export const arraysAreEqual = (object1, object2) => {
  let equals = false;

  if (object1 && object2) {
    equals = object1 === object2 ? true : false;

    if (!equals) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        equals = false;
      } else {
        for (let key of keys1) {
          if (object1[key] !== undefined && object2[key] !== undefined) {
            if (object1[key].value !== object2[key].value) {
              equals = false;
            } else {
              equals = true;
            }
          }
        }
      }
    }
  }
  return equals;
};
