import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardGird } from '../../../components/CardGrid/CardGird';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
// import { ToggleSwitch } from '../../../components/ToggleSwich/ToggleSwitch';
import { dataScreenTrunks, formTrunks } from '../../../data/dataOutbound';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
// import { colors } from '../../../theme/colors.js';
import { nameTables } from '../../../data/dataTable';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { HideColumn } from '../../../components/HideColumn/HideColumn';
import useView from '../../../hooks/useView';

import { number, string } from 'prop-types';

export const TrunksOutboundScreen = () => {
  const { arrayOutboundRoutesTrunksHook, getOutboundRoutesTrunks, updateOutboundRoutesTrunks, deleteOutboundRoutesTrunks, outboundRoutesForm, uploadFileTrunks, loading } = useOutbound();
  const { typeSwal } = useSwal();
  const { isCardView } = useView();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const { notFound, icon } = dataScreenTrunks;

  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const navigate = useNavigate();

  const dataTrunks = formTrunks.steps;

  useEffect(() => {
    getOutboundRoutesTrunks();
    setSearch([]);
    outboundRoutesForm(
      {
        action: 'clearForm',
      },
      {
        form: 'trunks',
      }
    );
  }, [getOutboundRoutesTrunks, setSearch]);

  const updateRegserver = (data, isOn, setIsOn) => {
    typeSwal('modify').then(async (result) => {
      if (result.isConfirmed) {
        Object.keys(data).map((item) =>
          dataTrunks.map((item2) =>
            item2.input.forEach((item3) => {
              if (item === item3.ref.element) {
                return outboundRoutesForm(
                  {
                    action: item3.cardType,
                    value: item3.ref.element === 'regServer' ? !isOn : data[item],
                  },
                  {
                    form: item3.ref.form,
                    element: item3.ref.element,
                  },
                  {
                    label: data[item],
                    value: String(data[item]),
                  }
                );
              }
            })
          )
        );
        setIsOn(!isOn);
        updateOutboundRoutesTrunks();
        typeSwal('success');
      }
    });
  };

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Descripción',
      accessor: 'description',
    },
    {
      Header: 'Password',
      accessor: 'password',
      disableSortBy: true,
      Cell: ({ row, index }) => <HideColumn row={row} id={row.id + '__' + index} data={row.original.password} text={'Contraseña'} />,
    },
    {
      Header: 'Fromdomain',
      accessor: 'fromDomain',
    },
    {
      Header: 'Servidor',
      accessor: 'server',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
    {
      Header: 'Usuario defecto',
      accessor: 'userDefault',
    },
    {
      Header: 'Outbound prefix',
      accessor: 'outboundPrefix',
    },
    // {
    //   Header: 'Registrar',
    //   accessor: 'regServer',
    //   disableSortBy: true,
    //   Cell: ({ row }) => <ToggleSwitch isActive={row.values.regServer.toggle ? true : false} data={row.original} toggleSwitch={updateRegserver} color={colors.primaryColor} />,
    // },
  ];

  const DATA = arrayOutboundRoutesTrunksHook.map(
    ({ name, allow, server, user, domain, disallow, regserver, Ipaddr, port, context, permit, deny, transport, nat, dtmf, qualify, description, id, companyId, sipPeerId, Company }) => ({
      name,
      description,
      // password:secret,
      id,
      companyId,
      sipPeerId,
      company: Company?.name,
      server,
      userDefault: user,
      outboundPrefix: port,
      fromDomain: domain,
      regServer: { toggle: regserver, id },
      allow,
      disallow,
      Ipaddr,
      port,
      context,
      permit,
      deny,
      transport,
      nat,
      dtmf,
      qualify,
    })
  );

  const dataExport = arrayOutboundRoutesTrunksHook.map(({ AstSipPeer, description, Company }) => ({
    Nombre: AstSipPeer?.name,
    Descripción: description,
    Compañia: Company?.name,
    Server: AstSipPeer?.host,
    UserDefault: AstSipPeer?.defaultuser,
    'Outbound Prefix': AstSipPeer?.port,
    Dominio: AstSipPeer?.fromdomain,
    'Reg Server': +AstSipPeer?.regserver,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(
        ({
          name,
          description,
          password,
          id,
          companyId,
          sipPeerId,
          company,
          server,
          userDefault,
          outboundPrefix,
          fromDomain,
          regServer,
          allow,
          disallow,
          Ipaddr,
          port,
          context,
          permit,
          deny,
          transport,
          nat,
          dtmfmode,
          qualify,
        }) => ({
          title: description,
          icon: icon.code,
          subtitle: name,
          icon2: icon.home,
          text: company,
          icon3: icon.alarm,
          text2: userDefault,
          isActive: regServer?.toggle,
          name,
          description,
          password,
          id,
          companyId,
          sipPeerId,
          company,
          server,
          userDefault,
          outboundPrefix,
          fromDomain,
          regServer,
          allow,
          disallow,
          Ipaddr,
          port,
          context,
          permit,
          deny,
          transport,
          nat,
          dtmfmode,
          qualify,
        })
      );
    } else {
      return DATA.map(({ AstSipPeer, description, id, companyId, sipPeerId, Company }) => ({
        title: description,
        icon: icon.code,
        subtitle: AstSipPeer?.name,
        icon2: icon.home,
        text: Company?.name,
        icon3: icon.alarm,
        text2: AstSipPeer?.defaultuser,
        isActive: AstSipPeer?.regserver,

        name: AstSipPeer?.name,
        description,
        password: AstSipPeer?.pbxpassword,
        id,
        companyId,
        sipPeerId,
        company: Company?.name,
        server: AstSipPeer?.host,
        userDefault: AstSipPeer?.defaultuser,
        outboundPrefix: AstSipPeer?.port,
        fromDomain: AstSipPeer?.fromdomain,
        regServer: {
          toggle: +AstSipPeer?.regserver,
          id: AstSipPeer?.id,
        },

        allow: AstSipPeer?.allow,
        disallow: AstSipPeer?.disallow,
        Ipaddr: AstSipPeer?.ipaddr,
        port: AstSipPeer?.port,
        context: AstSipPeer?.context,
        permit: AstSipPeer?.permit,
        deny: AstSipPeer?.deny,
        transport: AstSipPeer?.transport,
        nat: AstSipPeer?.nat,
        dtmfmode: AstSipPeer?.dtmfmode,
        qualify: AstSipPeer?.qualify,
      }));
    }
  };

  const dataToCards = dataCard();

  const handleOpenFormCreate = () => {
    navigate('/outboundRoutes/trunks/create', {});
  };

  const handleOpenFormEdit = (item) => {
    navigate('/outboundRoutes/trunks/update', {
      state: item.original ? item.original : item,
    });
  };

  // const functionAsync = async (response) => {
  //     let categoriesDelete = []
  //     response.data.map(async (item2) => {
  //         let idCategories = { idCategories: item2.id }
  //         await deleteCategories(idCategories).then((value) => {
  //             categoriesDelete.push(value.status)
  //         })
  //     })
  //     return categoriesDelete
  // }

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteOutboundRoutesTrunks(item.original);
          } else {
            response = await deleteOutboundRoutesTrunks(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getOutboundRoutesTrunks();
          }
          if (response.status === 203) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            typeSwal('error', table);
            getOutboundRoutesTrunks();
          }
        } catch (err) {
          if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileTrunks(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        getOutboundRoutesTrunks();
        typeSwal('upload').then(() => {
          window.location.reload();
        });
      }
    } catch (err) {
      return err;
    }
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Nombre: '',
      Descripción: '',
      Contraseña: '',
      FromDomain: '',
      Servidor: '',
      'Sede Id': '',
      Usuario: '',
      'Outbound Prefijo': '',
      Registrar: '1 o 0',
      Ipaddr: '',
      Port: '',
      Context: '',
      Permit: '',
      Deny: '',
      // transport,
      Nat: '',
      // dtmfmode,
      Allow: '',
      Disallow: '',
      Qualify: '',
    },
  ];

  return (
    <div className="trunksoutboundscreen">
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          deleteFunction={handleDelete}
          importFile={handleUploadFile}
          setSearch={setSearch}
          handleOpenFormCreate={handleOpenFormCreate}
          checkboxesActives={checkBoxesActives}
          setCheckboxesActives={setCheckboxesActives}
          isNavTable={true}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayOutboundRoutesTrunksHook[0]?.status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} toggleSwitch={updateRegserver} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            updateFunction={handleOpenFormEdit}
            deleteFunction={handleDelete}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isNavTable={false}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
      )}
    </div>
  );
};

TrunksOutboundScreen.propTypes = { row: string, index: number };
