export const actionTypesDepartment = {
  getDepartment: 'GET_DEPARTMENT',
  createDepartment: 'CREATE_DEPARTMENT',
  deleteDepartment: 'DELETE_DEPARTMENT',
  updateDepartment: 'UPDATE_DEPARTMENT',
  changeDepartmentNameForm: 'CHANGE_DEPARTMENT_NAME_FORM',
  changeDepartmentddiIdForm: 'CHANGE_DEPARTMENT_ddiId_FORM',
  changeDepartmentIdForm: 'CHANGE_DEPARTMENT_ID_FORM',
  clearDepartmentForm: 'CLEAR_DEPARTMENT_FORM',
  loadFirstItemsDepartment: 'LOAD_FIRST_ITEMS_DEPARTMENT',
  loadNextItemsDepartment: 'LOAD_NEXT_ITEMS_DEPARTMENT',
};
