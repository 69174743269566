import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const DistributionTableWrapper = styled.div`
  height: 100%;
  width: 100%;

  .title {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 59.49px;

    &__contain {
      display: flex;
      border-bottom: 1px solid ${colors.greyLine};
      height: 100%;
      align-items: center;
    }
    &__icon {
      height: 20px;
      margin-right: 3px;
    }
    &__text {
      margin: 0;
      font-weight: ${weight.medium};
      font-size: ${newFontSize.button};
      line-height: 16px;
      letter-spacing: 0.25px;
      color: ${colors.black};
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(1, 0.4fr 3fr);
    height: calc(100% - 59.49px);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 170px;
    left: ${(p) => (p.isMenuOpen ? '160px' : '70px')};

    .options {
      border-right: 1px solid ${colors.greyLine};
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 2;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
        background: white;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 1px;
        background: ${colors.primaryColor};
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 80%;
      }
    }
    .option {
      display: flex;
      height: 60px;
      align-items: center;
      border-bottom: 1px solid ${colors.greyLine};
      justify-content: space-between;
      //transition: all 0.3s;

      &:hover {
        background-color: ${colors.greyHover};
        cursor: pointer;
        //transition: all 0.3s;
      }

      &__iconText {
        display: flex;
        align-items: center;
      }
      &__icon {
        margin-right: 9px;
        height: 10px;
      }
      &__text {
        margin: 0;
        font-weight: ${weight.regular};
        font-size: ${newFontSize.body};
        line-height: 16px;
        letter-spacing: 0.25px;
        color: ${colors.black};
      }
      &__textNoIcon {
        margin: 0;
        margin-left: 19px;
        font-weight: ${weight.regular};
        // font-size: ${newFontSize.body};
        line-height: 16px;
        letter-spacing: 0.25px;
        color: ${colors.black};
      }
    }

    .active {
      background-color: ${colors.greyHover};
      -webkit-box-shadow: inset -2px 0px 0px 0px ${colors.primaryColor};
      -moz-box-shadow: inset -2px 0px 0px 0px ${colors.primaryColor};
      box-shadow: inset -2px 0px 0px 0px ${colors.primaryColor};
      p {
        color: ${colors.black};
      }
    }

    .component {
      grid-column-start: 2;
      grid-column-end: 4;
      width: 100%;
      height: 100%;
      position: relative;
      margin-right: 0;
      // overflow-y: scroll;
      // overflow-x: hidden;
      margin: 15px 0 0 20px;
      &::-webkit-scrollbar {
        width: 2px;
        // background: white;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 2px;
        background: ${colors.primaryColor};

        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 80%;
      }
    }
  }
`;
