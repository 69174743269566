import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { dataScreenDiffusionList } from '../../../data/dataEM';
import useEM from '../../../hooks/useEM';
// import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
// import { CardGird } from '../../../components/CardGrid/CardGird';
import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import { nameTables } from '../../../data/dataTable';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { useSwal } from '../../../hooks/useSwal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { routesEM, routesWide } from '../../../navigation/routes';
import '../../../styles/Screens/EMScreens/DiffusionListScreen.css';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { string } from 'prop-types';
import { IconEye } from '@tabler/icons-react';
import useNumbering from '../../../hooks/useNumbering';

export const DiffusionListScreen = () => {
  const { arrayEM, extensions, deleteEMListExtensions, getListDiffusionExtension, loading } = useEM();
  const { getExtensions } = useNumbering();
  const { typeSwal } = useSwal();

  const navigate = useNavigate();

  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const [search, setSearch] = useState([]);
  const [objectExtensions, setObjectExtensions] = useState({
    arrayExtensions: [],
    title: '',
  });
  const [, setEnable] = useState(0);
  const [, setIsBottom] = useState(false);

  useEffect(() => {
    getListDiffusionExtension();
    getExtensions();
    setSearch([]);
  }, [setSearch]);

  useEffect(() => {
    let newListDiffusion = extensions.filter((item) => item.moduleEmListId === arrayEM[0]?.id);
    setObjectExtensions({
      arrayExtensions: newListDiffusion,
      title: arrayEM[0]?.name,
    });

    setEnable(arrayEM[0]?.id);
  }, [extensions, arrayEM]);

  const handleClick = (item) => {
    let newExtension = extensions.filter((object) => object.moduleEmListId === item.id);
    setObjectExtensions({
      arrayExtensions: newExtension,
      title: item.text,
    });
    setEnable(item.id);
  };

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.EM}/${routesEM.createDiffusionList}`, {});
  };

  const handleOpenFormEdit = (item) => {
    handleClick(item);
    navigate(`/${routesWide.EM}/${routesEM.updateDiffusionList}`, {
      state: {
        title: item.original.name,
        id: item.id,
        arrayExtensions: objectExtensions.arrayExtensions.map((item) => {
          const newExt = {
            value: item.Extension?.id ? item.Extension?.id : item.value,
            label: item.Extension?.identification ? item.Extension?.identification : item.label,
          };
          return newExt;
        }),
      },
    });
  };

  // const handleOpenFormEdit2 = (item) => {
  //   navigate(`/${routesWide.numbering}/${routesNumbering.updateExtensions}`, {
  //     state: {
  //       state: item.data,
  //       route: `/${routesWide.EM}/${routesEM.diffusionLists}`,
  //     },
  //   });
  // };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteEMListExtensions(item);
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getListDiffusionExtension();
            typeSwal('delete');
          }

          if (response.status === 203) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            typeSwal('error', table);
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  // function dataEditExtensions(data) {
  //   if (!data) return;
  //   const {
  //     extension,
  //     name,
  //     password,
  //     id,
  //     ringTimeout,
  //     typeExtension,
  //     Ddi,
  //     callForward,
  //     callForwardNA,
  //     companyId,
  //     record,
  //     voicemailActive,
  //     Company,
  //     Department,
  //     CategoriesGroup,
  //     callWaiting,
  //     sipPeerId,
  //     AstSipPeer,
  //   } = data;

  //   return {
  //     extension,
  //     name,
  //     password,
  //     id,
  //     ringTimeout,
  //     typeExtension,
  //     identification: Ddi?.ddi,
  //     ddiId: Ddi?.id,
  //     callForward,
  //     callForwardNA,
  //     companyId,
  //     record,
  //     voicemailActive,
  //     company: Company?.name,
  //     department: Department?.name,
  //     departmentId: Department?.id,
  //     categoryGroup: CategoriesGroup?.description,
  //     categoryGroupId: CategoriesGroup?.id,
  //     callWaiting,
  //     sipPeerId,
  //     namedpickupgroup: AstSipPeer?.namedpickupgroup,
  //     allow: AstSipPeer?.allow,
  //   };
  // }

  // const dataToCards = objectExtensions.arrayExtensions.map((item) => ({
  //   title: item.Extension?.name,
  //   icon: dataScreenDiffusionList.icon.phone,
  //   subtitle: item.Extension?.extension,
  //   icon2: dataScreenDiffusionList.icon.home,
  //   text: item.Extension?.typeExtension,
  //   icon3: dataScreenDiffusionList.icon.hierarchy,
  //   text2: item.Extension?.callForward,
  //   id: item.Extension?.id,
  //   boolean: item.Extension?.voicemailActive,
  //   // data: item.Extension,
  //   data: dataEditExtensions(item.Extension),
  // }));

  // const newData = [];
  // const DATA2 = arrayEM?.forEach((itemDif) => {
  //   extensions?.find((itemExt) => {
  //     if (itemExt.EMList.id === itemDif.id) {
  //       newData.push({ id: itemExt.EMList.id, name: itemExt.EMList.name });
  //     }
  //     // return newData;
  //   });
  // });

  // console.log(DATA2);

  const DATA = arrayEM?.map(({ id, name }) => ({
    id,
    name,
  }));

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Extensiones',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => {
        const result = [];
        extensions?.find((object) => {
          if (object.id === row.original?.id) {
            result.push({ value: object.Extension.id, label: object.Extension.name });
          }
        });

        return (
          <>
            <ReactTooltip
              className="tooltip"
              clickable={true}
              anchorSelect={'#icon__viewMohLinks' + row.index}
              place="bottom"
              content={() =>
                result.map((sound) => {
                  return (
                    <div key={sound.value + row.index} className="tooltip__audio">
                      <span>
                        <br />
                        {sound.value} - {sound.label}
                      </span>
                      {/* <audio src={import.meta.env.VITE_APP_UPLOAD_FOLDER + 'mp3/' + sound.filename} controls /> */}
                    </div>
                  );
                })
              }
              delayShow={1000}
            />
            <IconEye type="Change" row={row} id={'icon__viewMohLinks' + row.index} className={'icon__view'} />
          </>
        );
      },
    },
  ];

  menuButtonsDropDownTable.forEach((item) => {
    if (item.id === 1) {
      item.function = handleOpenFormEdit;
    } else if (item.id === 2) {
      item.function = handleDelete;
    }
  });

  const { notFound } = dataScreenDiffusionList;
  const tableInstance = useRef(null);

  // const option = arrayEM.map((item) => ({
  //   text: item.name,
  //   id: item.id,
  // }));

  const newArray = arrayEM.map((item) => {
    return {
      keyCall: item.keyCall,
      description: item.Soundprompt?.description,
      name: item.name,
      title: item.title,
      filename: item.callAndRecord === '0' || item.callAndRecord === 0 ? item.Soundprompt?.filename : 'Llamar y grabar activado',
      callAndRecord: { toggle: item.callAndRecord, id: item.id },
      id: item.id,
      soundPromptId: item.Soundprompt?.id,
      listDiffusionName: item.principalList?.name,
      listDiffusionId: item.principalList?.id,
      secondaryListDiffusionId: item.secondaryList?.id,
      secondaryListDiffusionName: item.secondaryList?.name,
      confirmationNumber: item.confirmationNumber,
    };
  });

  const dataExport = newArray.map(({ keyCall, name, filename, callAndRecord, listDiffusionName, secondaryListDiffusionName }) => ({
    'Código alerta': keyCall,
    Nombre: name,
    Locución: filename,
    'Llamar y grabar': callAndRecord.toggle,
    'Lista de difusión': listDiffusionName,
    'Lista de difusion secundaria': secondaryListDiffusionName,
  }));

  const template = [
    {
      'Codigo Alerta': '',
      Nombre: '',
      'Numero de confirmacion': '',
      'Lista_de_difusión *(id)': '',
      'Lista de difusión secundaria *(id)': '',
      'Locución *(id)': '',
      'Llamar y grabar': '0 o 1',
    },
  ];

  return (
    <div className="diffusion-list">
      <div className="screen__inside-size">
        <div className="contain__buttons">
          <NavButtons
            optionButtons={{
              add: {
                position: 'left',
                function: handleOpenFormCreate,
              },
              search: {
                position: 'right',
                data: DATA,
                columns: COLUMNS,
              },
              file: {
                position: 'right',
                data: dataExport,
                template,
              },
            }}
            isNavTable={true}
            handleOpenFormCreate={handleOpenFormCreate}
            deleteFunction={handleDelete}
            setSearch={setSearch}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
        {loading ? (
          <LoaderSpinner />
        ) : arrayEM[0]?.status ? (
          <div className="contain__table">
            <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
          </div>
        ) : search[0]?.notFound ? (
          <div className="contain__notFound">
            <h4>{search[0].notFound}</h4>
          </div>
        ) : (
          <div className="contain__table">
            {/* <DistributionTable
                title={title}
                option={option}
                component={<CardGird data={dataToCards} onClick={handleOpenFormEdit2} />}
                handleClick={handleClick}
                menuButtonsDropDown={menuButtonsDropDownTable}
                enable={enable}
              />
            </div> */}
            <TableInfo
              format={'list'}
              COLUMNS={COLUMNS}
              DATA={search.length === 0 ? DATA : search}
              deleteFunction={null}
              updateFunction={null}
              isOptions={false}
              cursorPointer={true}
              onClick={handleOpenFormEdit}
              ref={tableInstance}
              setIsBottom={setIsBottom}
              isCheckbox={true}
              isNavTable={false}
              checkboxesActives={checkBoxesActives}
              setCheckboxesActives={setCheckboxesActives}
              setDataCard={() => {
                false;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DiffusionListScreen.propTypes = { row: string };
