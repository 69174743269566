import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const InputWrapper = styled.div`
  width: 100%;

  .input {
    border: 1px solid ${colors.greyBorder};
    border-radius: 10px;
    ${
      '' /* border-right: 0;
    border-left: 0; */
    }
    font-size: ${newFontSize.label};
    color: ${colors.black};
    width: 100%;
    max-width: 300px;
    height: 38px;
    padding: 0 0 0 10px;
    margin: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    ${'' /* font-weight: ${weight.bold}; */}

    &::placeholder {
      font-weight: ${weight.light};
      font-size: ${newFontSize.label};
      color: ${colors.grey};
    }

    &:focus {
      padding-left: 10px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
