import React from 'react';
// import { Header } from '../../components/Header/Header';
// import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';
// import { menuOutbound } from '../../data/dataOutbound';
import { OutboundNavigation } from '../../navigation/OutboundNavigation/OutboundNavigation';

import '../../styles/Screens/OutboundScreen.css';

export const OutboundScreen = () => {
  return (
    <div className="outbound-screen">
      {/* <Header image={menuNavLeft.menuTop[5].image} text={menuNavLeft.menuTop[5].text} /> */}
      {/* <MenuTop menu={menuOutbound} /> */}
      <OutboundNavigation />
    </div>
  );
};
