import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateUser } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import useUsers from '../../../hooks/useUsers';
import { routesWide } from '../../../navigation/routes';

import { object, string } from 'prop-types';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useDepartments } from '../../../hooks/useDepartments';
import { useAccessLevels } from '../../../hooks/useAccessLevel';
// import FallbackRender from '../../../components/FallbackRenderer/FallbackRenderer';
// import { ErrorBoundary } from 'react-error-boundary';
import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { useDispatch, useStore } from 'react-redux';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { isModalOpenActionCreator, selectedDocActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import useNumbering from '../../../hooks/useNumbering';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import useNumbering from '../../../hooks/useNumbering';
// import { getCompanyLinkReducer } from '../../../redux/reducers/headquarter/headquarterReducer';
import { nameTables } from '../../../data/dataTable';
// import { ExtensionsFormScreen } from '../../NumberingScreen/ExtensionsScreen/ExtensionsFormScreen';
import Modal from 'react-modal';
import { NavButtons } from '../../../components/NavButtons/NavButtons';

import { ExtensionsFormScreen } from '../../../screens/NumberingScreen/ExtensionsScreen/ExtensionsFormScreen';
// import { NavButtons } from '../NavButtons/NavButtons';
// import { useNavigate } from 'react-router-dom';
import { getIcon } from '../../../utils/AppUtils';
import { optionsAllow } from '../../../data/dataOutbound';
import { getArrayFormatted } from '../../../utils/AppUtils';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import PasswordWatcher from '../../../components/PasswordWatcher/PasswordWatcher';
import useApplications from '../../../hooks/useApplications';

const UserScreenForm = ({ crudType }) => {
  const { postCreateUser, userForm, updateUser } = useUsers();
  const { getSettingsData, getConfigData } = useSettings();
  // const navigate = useNavigate();
  const [extensionCrudType, setExtensionCrudType] = useState('modalEditUserDispo');
  const dispatch = useDispatch();
  // const [, setIsDropdownActive] = useState(false);
  Modal.setAppElement('#root');
  const { getCategoriesHook, categoriesHook } = useOutbound();
  const { getDepartments, departmentsHook } = useDepartments();
  const { deleteExtensions, getExtensions, dataExport } = useNumbering();

  const { getState } = useStore();

  const { isMenuOpen, isModalOpen } = getState()['system'];
  const { usersFormReducer } = getState();

  const [, setIsModalOpen] = useState(false);
  const [checkboxesActives, setCheckboxesActives] = useState([]);

  const [render, setRender] = useState(false);
  const { getUsers, users } = useSettings();
  const { typeSwal } = useSwal();
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { queues } = useApplications();
  const { getAccessLevels, arrayAccessLevelsHook } = useAccessLevels();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);

  const [showTab, setShowTab] = useState('Dispositivos');

  const [, setIsBottom] = useState(false);

  const [search, setSearch] = useState([]);
  const { arrayNumberingExtensionsHook } = useNumbering();
  const [showPassword, setShowPassword] = useState(false);

  const [unSavedChanges, setUnSavedChanges] = useState(false);

  const data = formCreateUser;

  const styles = {
    overlay: {
      position: 'absolute',
      top: '0',
      left: '0',
      margin: 'auto',
      right: 0,
      bottom: 0,
      // width: '600px',
      // height: '300px',
      borderRadius: '10px',
      opacity: '1',
      backgroundColor: '#52658188',
    },
    content: {
      width: '800px',
      height: 'auto',
      overflow: 'visible',
      position: 'absolute',
      top: '20%',
      left: '25%',

      border: '1px solid #ccc',
      borderRadius: '10px',
      background: '#fff',
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      padding: '0px',
      margin: 'auto',
    },
  };

  const { state } = useLocation();

  useEffect(() => {
    getSettingsData();
    getConfigData();
    getHeadquarter();
    getDepartments();
    getAccessLevels();
    getExtensions();
    getCategoriesHook();
  }, [showTab]);

  const [docSettings] = useState(getState()['getConfigReducer']);

  useEffect(() => {
    userForm(
      { action: 'clearForm' },
      {
        form: 'user',
      }
    );

    if (crudType === 'create' || crudType === 'edit') {
      userForm(
        {
          action: 'id',
          value: location.state?.id,
        },
        { form: 'user', element: 'id' }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.firstname,
        },
        { form: 'user', element: 'firstname' }
      );
      userForm(
        {
          action: 'input',
          value: location.state?.lastname,
        },
        { form: 'user', element: 'lastname' }
      );
      userForm(
        {
          action: 'input',
          value: location.state?.email,
        },
        { form: 'user', element: 'email' }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.callForward,
        },
        { form: 'user', element: 'callForward' }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.secondname,
        },
        { form: 'user', element: 'secondname' }
      );
      userForm(
        {
          action: 'input',
          value: location.state?.voicemailPIN,
        },
        { form: 'user', element: 'voicemailPIN' }
      );

      userForm(
        {
          action: 'select-option',
          value: location.state?.SIPExtensionId,
          label: location.state?.SIPExtensionId,
        },
        { form: 'user', element: 'SIPExtension' }
        // { value: location.state?.SIPExtensionId, label: location.state?.SIPExtensionId }
      );

      const newCategory = categoriesHook?.find((itemCapt) => itemCapt.id === location.state?.category);

      newCategory
        ? userForm(
            {
              action: 'select-option',
              value: location.state?.category,
              label: newCategory?.description,
            },
            { form: 'user', element: 'category' }
          )
        : null;

      // const newCapture = [];
      // location.state?.captureId.forEach((itemCapture) => {
      //   const found = departmentsHook.find((itemCapt) => itemCapt.id === itemCapture);

      //   found ? newCapture.push({ value: found.id, label: found.name }) : null;
      // });

      // newCapture.length > 0
      //   ?
      location.state?.captureId.forEach((itemCapture) => {
        const found = departmentsHook.find((itemCapt) => itemCapt.id === itemCapture.value);

        found
          ? userForm(
              {
                action: 'select-option',
              },
              { form: 'user', element: 'captureId' },
              { value: found.id, label: found.name }
            )
          : null;
      });

      // : null;

      userForm(
        {
          action: 'select-option',
        },
        {
          form: 'user',
          element: 'companyId',
        },
        {
          value: state?.companyId,
          label: state?.Company,
        }
      );

      userForm(
        {
          action: 'select-option',
          value: usersFormReducer?.departmentId?.value || location.state?.departmentId,
          label: usersFormReducer?.departmentId?.label || location.state?.Department,
        },
        {
          form: 'user',
          element: 'departmentId',
        }
      );

      const newAccessLevel = arrayAccessLevelsHook.find((itemAcc) => {
        const newItem = itemAcc.id === location.state?.accesslevelId;
        return newItem;
      });
      newAccessLevel
        ? userForm(
            {
              action: 'select-option',
              value: newAccessLevel.id,
              label: newAccessLevel.name,
            },
            {
              form: 'user',
              element: 'accesslevelId',
            }
          )
        : null;

      userForm(
        {
          action: 'select-option',
          value: location.state?.roleId,
          label: location.state?.role,
        },
        {
          form: 'user',
          element: 'roleId',
        }
      );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isvoicemailEmail,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isvoicemailEmail',
        }
      );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isVoicemail,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isVoicemail',
        }
      );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isTeams || false,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isTeams',
        }
      );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isRainbow || false,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isRainbow',
        }
      );
      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isWebPhone === 1 ? true : false,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isWebPhone',
        }
      );
      userForm(
        {
          action: 'toggle',
          value: location.state?.isCallForward || false,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isCallForward',
        }
      );

      userForm(
        {
          action: 'toggle',
          value: location.state?.isNotAnswer || false,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isNotAnswer',
        }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.notAnswer || false,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'notAnswer',
        }
      );

      // location.state &&
      //   Object.keys(location.state).map((item) =>
      //     data.steps.map((item2) =>
      //       item2.input.map((item3) => {
      //         if (
      //           item3.ref.element !== 'accesslevelId' &&
      //           item3.ref.element !== 'roleId' &&
      //           item3.ref.element !== 'departmentId' &&
      //           item3.ref.element !== 'Department' &&
      //           item3.ref.element !== 'companyId' &&
      //           item3.ref.element !== 'Company' &&
      //           item3.ref.element !== 'notAnswer' &&
      //           item3.ref.element !== 'isNotAnswer' &&
      //           item3.ref.element !== 'isCallForward' &&
      //           item3.ref.element !== 'Password' &&
      //           item3.ref.element !== 'password' &&
      //           // item3.ref.element !== 'voicemailPIN' &&
      //           item3.ref.element !== 'SIPExtensionId' &&
      //           item3.ref.element !== 'captureId' &&
      //           item3.ref.element !== 'category' &&
      //           item === item3.ref.element
      //         ) {
      //           return userForm(
      //             {
      //               action: item3.cardType,
      //               value: location.state[item],
      //               label: location.state[item],
      //             },
      //             {
      //               form: item3.ref.form,
      //               element: item3.ref.element,
      //             }
      //           );
      //         } else {
      //           return null;
      //         }
      //       })
      //     )
      //   );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof usersFormReducer === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'companyId') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  value: item3.id,
                  label: item3.name,
                };
                // setSelectedCompany(company.value);
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }

            if (item2.ref.element === 'accesslevelId') {
              arrayAccessLevelsHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const accesslevel = {
                  label: item3.name,
                  value: item3.id,
                };
                const found = item2.options.some((e) => e.value === accesslevel.value);
                if (!found && accesslevel.value !== undefined) {
                  item2.options.push(accesslevel);
                }
              });
            }

            if (item2.ref.element === 'SIPExtension') {
              item2.options = [];
              for (let i = docSettings?.extension_start; i < docSettings?.extension_end; i++) {
                const assigned = users.some((itemUser) => Number(itemUser.SIPExtensionId) === i);
                if (!assigned) {
                  // const found = item2.options.some((e) => e.value === location.state?.SIPExtensionId);
                  // if (!found) {
                  item2.options.push({
                    label: i,
                    value: i,
                  });
                  // }
                }
              }
            }
            if (item2.ref.element === 'category') {
              item2.options = [];
              categoriesHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const itemCat = {
                  value: item3.id,
                  label: item3.description,
                };
                const found = item2.options.some((e) => e.value === itemCat.value);
                if (!found && itemCat.value !== undefined) {
                  item2.options.push(itemCat);
                }
              });
            }
          });
        });
      }

      if (typeof usersFormReducer === 'object') {
        if (crudType === 'edit' && (usersFormReducer?.firstname === null || usersFormReducer?.firstname !== location.state?.firstname)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'firstname':
                  item2.defaultValues = usersFormReducer.firstname;
                  break;
                case 'lastname':
                  item2.defaultValues = usersFormReducer.lastname;
                  break;
                case 'secondname':
                  item2.defaultValues = usersFormReducer.secondname;
                  break;
                case 'email':
                  item2.defaultValues = usersFormReducer.email;
                  break;
                // case 'password':
                //   item2.defaultValues = usersFormReducer.password;
                // break;
                case 'accesslevelId':
                  item2.defaultValues = usersFormReducer.accesslevelId;

                  break;
                case 'companyId':
                  item2.defaultValues = { value: location.state?.companyId, label: location.state?.Company };
                  // { value: usersFormReducer?.companyId.value, label: usersFormReducer?.companyId.label };
                  break;
                case 'departmentId':
                  item2.defaultValues = { value: usersFormReducer?.departmentId?.value, label: usersFormReducer?.departmentId?.label };
                  break;
                case 'roleId':
                  item2.options.filter((item3) => {
                    if (location.state?.roleId === Number(item3.value)) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'Company':
                  item2.options.forEach((item3) => {
                    if (item3.value === location.state?.companyId) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'AccessLevel':
                  item2.defaultValues = { value: location.state?.accesslevelId, label: location.state?.AccessLevel } || usersFormReducer.AccessLevel;
                  break;
                case 'isVoicemail':
                  item2.defaultValues = usersFormReducer.isVoicemail;
                  break;
                case 'isvoicemailEmail':
                  item2.defaultValues = usersFormReducer.isvoicemailEmail;
                  break;
                case 'voicemailPIN':
                  item2.defaultValues = usersFormReducer.voicemailPIN;
                  break;
                case 'callForward':
                  item2.defaultValues = usersFormReducer.callForward;
                  break;
                case 'isCallForward':
                  item2.defaultValues = usersFormReducer.isCallForward;
                  break;
                case 'notAnswer':
                  item2.defaultValues = usersFormReducer.notAnswer;
                  break;
                case 'isNotAnswer':
                  item2.defaultValues = usersFormReducer.isNotAnswer;
                  break;
                case 'isTeams':
                  item2.defaultValues = usersFormReducer.isTeams;
                  break;
                case 'isRainbow':
                  item2.defaultValues = usersFormReducer.isRainbow;
                  break;
                case 'isWebPhone':
                  item2.defaultValues = usersFormReducer.isWebPhone;
                  break;
                case 'SIPExtension':
                  item2.defaultValues = usersFormReducer.SIPExtension;
                  break;
                case 'category':
                  item2.defaultValues = usersFormReducer.category;
                  break;
                case 'captureId':
                  item2.defaultValues = usersFormReducer?.captureId;
                  break;

                default:
                  // item2.defaultValues = usersFormReducer[item2.ref?.element];
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              item2.defaultValues = '';
            });
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter]);

  const handleOpenFormCreate = (item) => {
    setExtensionCrudType('modalCreateUserDispo');
    dispatch(isModalOpenActionCreator(true));
    dispatch(selectedDocActionCreator(item ? item.original : { userId: { value: location.state?.id, label: location.state?.Usuario }, typeExtension: { value: 'SIP', label: 'SIP' } }));
    // navigate('/users/usersList/create', {});
  };

  const handleOnCancel = () => {
    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    setUnSavedChanges(false);
    if (crudType === 'create') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.users}`, 'user', postCreateUser);
      ///${routesUser.usersList}/${routesUser.userCreate}
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.users}`, 'user', updateUser);
    }
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteExtensions(item.original);
          } else {
            response = await deleteExtensions(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getExtensions();
            typeSwal('delete');
          } else if (response.status === 205) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            getExtensions();
            typeSwal('error', table);
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleSaveExtension = () => {
    getExtensions();
    dispatch(isModalOpenActionCreator(false));
  };

  const COLUMNS = [
    {
      Header: '  ',
      id: 'iconDevices__column',
      accessor: '',
      Cell: (row) => {
        // const newIcon = <IconRainbow className="icon__dispositivo" />;
        const newIcon = getIcon(row, 'extensions');
        return newIcon;
        // switch (row.original.typeExtension) {
        //   case 'rainbow':
        //     return <IconRainbow className="icon__dispositivo" />;

        //   case 'virtual':
        //     return <IconCloud className="icon__dispositivo" />;

        //   case 'SIP':
        //     return <IconPhoneOff className="icon__dispositivo" />;

        //   case 'teams':
        //     return <IconUsers className="icon__dispositivo" />;

        //   case 'terminal':
        //     return <IconPhone className="icon__dispositivo" />;

        //   default:
        //     break;
        // }
      },
    },
    {
      Header: 'Tipo',
      accessor: 'typeExtension',
    },
    {
      Header: 'Identificador',
      accessor: 'identification',
    },

    { Header: 'Contraseña', accessor: 'password', Cell: ({ row }) => <PasswordWatcher row={row} showPassword={showPassword} text={'Contraseña'} />, disableSortBy: true },
  ];

  const COLUMNS__QUEUES = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Extensión',
      accessor: 'Extension.firstname',
    },
    {
      Header: 'Estrategia',
      accessor: 'Strategy.name',
    },
    {
      Header: 'Grabar',
      accessor: 'isRecord',
    },
    {
      Header: 'Destino timeout',
      accessor: 'DestinationType.label',
    },
    // {
    //   Header: 'Agentes',
    //   accessor: '',
    //   disableSortBy: true,
    //   Cell: ({ row }) => <IconEye type="Change" row={row} id="icon__viewAgents" className={'icon__view'} />,
    // },
  ];
  const tableInstance = useRef(null);

  const handleOpenFormEdit = (item) => {
    setExtensionCrudType('modalEditUserDispo');
    dispatch(isModalOpenActionCreator(true));
    // setIsDropdownActive(false);
    if (item.original) {
      const newExt = arrayNumberingExtensionsHook?.filter((item2) => item2.identification === item.original?.identification);
      newExt[0].allow = getArrayFormatted(newExt[0]?.allow, optionsAllow, 'allow');
      newExt[0].typeExtension = { label: newExt[0].typeExtension, value: newExt[0].typeExtension };
      dispatch(selectedDocActionCreator(newExt[0]));
    } else {
      dispatch(selectedDocActionCreator(arrayNumberingExtensionsHook[0]));
    }
    // { label: newExt[0].typeExtension, value: newExt[0].typeExtension };
    // [0].toUpperCase() + newExt[0].typeExtension.slice(1), value: newExt[0].typeExtension };
    dispatch(isModalOpenActionCreator(true));
    // navigate('/users/usersList/update');
    // , { state: newExt[0] });

    // location.state = newExt[0];
    // navigate('/numbering/extensions/update', {
    //   state: newExt[0],
    // });
  };

  const handleClickAssign = () => {
    // typeSwal(type, name, hookForm, action, path, form, hookFunction);
    // setExtensionCrudType('createUserDispo');
    // location.state = formExtensionsReducer;
    // setExtensionCrudType('assignUserDispo');
    dispatch(isModalOpenActionCreator(true));
    // setIsDropdownActive(false);
  };

  // const dataRules = (data2) => {
  //   arrayNumberingExtensionsHook?.forEach((row) => {
  //     if (row.userId === data2?.id) {
  //       const newRow = {
  //         // id: row?.id,
  //         identification: row.Ddi?.ddi,
  //         // extension: row.extension,
  //         // userId: row?.User.fullName,
  //         typeExtension: row.typeExtension,
  //       };
  //       row.id ? arrayRules.push(newRow) : null;
  //     }
  //   });
  //   return arrayRules;
  // };

  const handleClickCreate = () => {
    // typeSwal(type, name, hookForm, action, path, form, hookFunction);
    // location.state = item.original;
    if (arrayRules.length > 0) {
      setExtensionCrudType('modalCreateUserDispo');
      dispatch(isModalOpenActionCreator(true));
      dispatch(selectedDocActionCreator(arrayNumberingExtensionsHook[0]));
    } else {
      setIsModalOpen(true);
    }
  };

  const arrayRules = [];
  const dataDispositivos = (formData) => {
    arrayNumberingExtensionsHook?.forEach((row) => {
      if (row.User?.email === formData?.email) {
        //  formData?.tabs[0].steps[0].input[3].defaultValues;
        const newRow = {
          // id: formData.tabs[0].steps[0].input[3].defaultValues,
          identification: row.identification,
          // extension: row.extension,
          // userId: row?.User.fullName,
          typeExtension: row.typeExtension.label ? row.typeExtension.label : row.typeExtension,
          password: row.AstSipPeer?.pbxpassword || row.password,
        };
        row.id ? arrayRules.push(newRow) : null;
      }
    });
    return arrayRules;
  };

  const dataQueues = (formData) => {
    const arrayQueues = [];
    queues?.forEach((row) => {
      if (row.extensionId === formData?.id) {
        //  formData?.tabs[0].steps[0].input[3].defaultValues;
        const newRow = {
          // id: formData.tabs[0].steps[0].input[3].defaultValues,
          Nombre: row.name,
        };
        row.id ? arrayQueues.push(newRow) : null;
      }
    });
    return arrayQueues;
  };

  const arrayDispositivos = dataDispositivos(usersFormReducer);
  const arrayQueues = dataQueues(usersFormReducer);

  const template = [];

  // const location = useLocation();
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   // execute on location change
  //   setCount(count + 1);
  //   console.log('Location changed!', location.pathname);
  // }, [location]);
  const handleTabs = () => {
    const newTab = showTab === 'Dispositivos' ? 'Colas' : 'Dispositivos';
    setShowTab(newTab);
  };
  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PageScreen
          arrScreen={['Usuario']}
          dataSave={dataSave}
          data={data}
          onSubmit={onSubmit}
          onCancel={handleOnCancel}
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          render={render}
          setRender={setRender}
          form={userForm}
          crudType={'edit'}
          maxwidth={'1000px'}
          reducerForm={usersFormReducer}
          handleOpenFormCreate={handleOpenFormCreate}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isMenuOpen={isMenuOpen}
          handleDelete={handleDelete}
          // setCheckboxesActives={setCheckboxesActives}
          // checkboxesActives={checkboxesActives}
        />
      )}

      <div className="user__contain__table">
        <div className="table__header__tabs">
          <button className="table__header__tab active" onClick={handleTabs}>
            Dispositivos
          </button>
          <button className="table__header__tab" onClick={handleTabs}>
            Colas
          </button>
        </div>
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleClickCreate,
            },
            search: {
              position: 'right',
              data: arrayDispositivos,
              columns: COLUMNS,
            },

            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          setSearch={setSearch}
          onClickCreate={handleClickCreate}
          onClickAssign={handleClickAssign}
          // isDropdownActive={isDropdownActive}
          checkboxesActives={checkboxesActives}
          setCheckboxesActives={setCheckboxesActives}
          deleteFunction={handleDelete}
          isNavTable={true}
          handleShowEditRow={handleClickCreate}
          DATA={arrayDispositivos}
          handleOpenFormCreate={crudType === 'assignUserDispo' ? handleClickAssign : handleClickCreate}
          // data?.tabs ? data.tabs : data}
        />
        {showTab === 'Dispositivos' ? (
          <TableInfo
            format={'list'}
            // handleEditRule={handleEditRule}
            COLUMNS={COLUMNS}
            // isNavTable={true}
            DATA={search.length === 0 ? arrayDispositivos : search}
            askedBy={'UserForm'}
            // deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            setShowPassword={setShowPassword}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
            // isDropdownActive={isDropdownActive}
            handleOpenFormEdit={handleOpenFormEdit}
            handleClickCreate={handleClickCreate}
            handleClickAssign={handleClickAssign}
            setDataCard={() => {
              false;
            }}
          />
        ) : (
          <TableInfo
            format={'list'}
            // handleEditRule={handleEditRule}
            COLUMNS={COLUMNS__QUEUES}
            // isNavTable={true}
            DATA={search.length === 0 ? arrayQueues : search}
            askedBy={'UserForm'}
            // deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            setShowPassword={setShowPassword}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
            // isDropdownActive={isDropdownActive}
            handleOpenFormEdit={handleOpenFormEdit}
            handleClickCreate={handleClickCreate}
            handleClickAssign={handleClickAssign}
            setDataCard={() => {
              false;
            }}
          />
        )}
        <Modal isOpen={isModalOpen} onRequestClose={handleOnCancel} className={'modal'} style={styles} contentLabel="Dispositivos" isWarning={arrayRules.length === 0 ? true : false}>
          {arrayRules.length > 0 ? <ExtensionsFormScreen crudType={extensionCrudType} onSave={handleSaveExtension} /> : null}
        </Modal>
      </div>
    </>
  );
};

UserScreenForm.propTypes = { crudType: string, row: object };

export default UserScreenForm;
