import { actionTypesDepartment } from '../../actions/department/actionTypes';

export const departmentGetReducer = (departments = [], action) => {
  let newDepartment;
  switch (action.type) {
    case actionTypesDepartment.getDepartment:
      newDepartment = [...action.departments];
      break;
    default:
      newDepartment = departments;
  }
  return newDepartment;
};

export const formDepartmentReducer = (state = { name: '', id: '' }, action) => {
  switch (action.type) {
    case actionTypesDepartment.changeDepartmentNameForm:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesDepartment.changeDepartmentIdForm:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesDepartment.changeDepartmentddiIdForm:
      state = {
        ...state,
        ddiId: action.payload,
      };
      break;

    case actionTypesDepartment.clearDepartmentForm:
      state = {
        name: '',
        id: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
