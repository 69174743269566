import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
import { ConferenceFormScreen } from '../../screens/ApplicationsScreen/ConferenceScreen/ConferenceFormScreen';
import { ConferenceScreen } from '../../screens/ApplicationsScreen/ConferenceScreen/ConferenceScreen';
import { IvrFormScreen } from '../../screens/ApplicationsScreen/IvrScreen/IvrFormScreen';
import { IvrScreen } from '../../screens/ApplicationsScreen/IvrScreen/IvrScreen';
import { LocutionsFormScreen } from '../../screens/ApplicationsScreen/LocutionsScreen/LocutionsFormScreen';
import { LocutionsScreen } from '../../screens/ApplicationsScreen/LocutionsScreen/LocutionsScreen';
import { MailboxesFormScreen } from '../../screens/ApplicationsScreen/MailboxesScreen/MailboxesFormScreen';
import { MailboxesScreen } from '../../screens/ApplicationsScreen/MailboxesScreen/MailboxesScreen';
import { OverviewApplications } from '../../screens/ApplicationsScreen/OverviewApplications/OverviewApplications';
import { QueueFormScreen } from '../../screens/ApplicationsScreen/QueueScreen/QueueFormScreen';
import { QueueScreen } from '../../screens/ApplicationsScreen/QueueScreen/QueueScreen';
import { ShortMarkingFormScreen } from '../../screens/ApplicationsScreen/ShortMarkingScreen/ShortMarkingFormScreen';
import { ShortMarkingScreen } from '../../screens/ApplicationsScreen/ShortMarkingScreen/ShortMarkingScreen';
import { SongWaitingFormScreen } from '../../screens/ApplicationsScreen/SongWaitingScreen/SongWaitingFormScreen';
import { SongWaitingScreen } from '../../screens/ApplicationsScreen/SongWaitingScreen/SongWaitingScreen';
import { routesApplications } from '../routes';
import { ApplicationsWrapper } from './ApplicationsNavigation.style';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

export const ApplicationsNavigation = () => {
  const [showTopBtn, setShowTopBtn] = React.useState(false); // useEffect(() => {

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <ApplicationsWrapper>
      {showTopBtn && <ScrollToTop />}
      <Routes>
        <Route path="/" element={<OverviewApplications />} />
        <Route path={routesApplications.createMailboxes} element={<MailboxesFormScreen crudType="create" />} />
        <Route path={routesApplications.updateMailboxes} element={<MailboxesFormScreen crudType="edit" />} />
        <Route path={routesApplications.mailboxes} element={<MailboxesScreen />} />
        <Route path={routesApplications.queue} element={<QueueScreen />} />
        <Route path={routesApplications.createQueue} element={<QueueFormScreen crudType="create" />} />
        <Route path={routesApplications.updateQueue} element={<QueueFormScreen crudType="edit" />} />
        <Route path={routesApplications.conference} element={<ConferenceScreen />} />
        <Route path={routesApplications.createConferences} element={<ConferenceFormScreen crudType="create" />} />
        <Route path={routesApplications.updateConferences} element={<ConferenceFormScreen crudType="edit" />} />
        <Route path={routesApplications.ivr} element={<IvrScreen />} />
        <Route path={routesApplications.createIvr} element={<IvrFormScreen crudType="create" />} />
        <Route path={routesApplications.updateIvr} element={<IvrFormScreen crudType="edit" />} />
        <Route path={routesApplications.locutions} element={<LocutionsScreen />} />
        <Route path={routesApplications.createLocutions} element={<LocutionsFormScreen crudType="create" />} />
        <Route path={routesApplications.updateLocutions} element={<LocutionsFormScreen crudType="edit" />} />

        <Route path={routesApplications.shortMarking} element={<ShortMarkingScreen />} />
        <Route path={routesApplications.createShortMarking} element={<ShortMarkingFormScreen crudType="create" />} />
        <Route path={routesApplications.updateShortMarking} element={<ShortMarkingFormScreen crudType="edit" />} />
        <Route path={routesApplications.songWaiting} element={<SongWaitingScreen />} />
        <Route path={routesApplications.createSongWaiting} element={<SongWaitingFormScreen crudType="create" />} />
        <Route path={routesApplications.updateSongWaiting} element={<SongWaitingFormScreen crudType="edit" />} />
      </Routes>
    </ApplicationsWrapper>
  );
};
