import React from 'react';
import './OverviewScreen.css';

export const OverviewScreen = () => {
  return (
    <div className="overview">
      <div className="containtTop"></div>
      <div className="containtCenter">
        <div className="left"></div>
        <div className="rigth"></div>
      </div>
      <div className="containtBottom"></div>
    </div>
  );
};
