import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
// import { formCompany } from '../../../data/dataPreferences';
// import { useDepartments } from '../../../hooks/useDepartments';
// import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import useNumbering from '../../../hooks/useNumbering';
import { formPlantilla } from '../../../data/dataNumbering';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { useDispatch } from 'react-redux';
import { isModalOpenActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const PlantillaForm = ({ crudType }) => {
  // const { FormCompany, companyForm, createCompany, updateCompany, getHeadquarter } = useNumbering();
  // const { departmentsHook } = useDepartments();
  const { getModelos, getMarcas, arrayModelosHook, formPlantillaReducer, getPlantillas, arrayMarcasHook, createPlantilla, updatePlantilla, listNumberingForm, arrayPlantillasHook } = useNumbering();
  const { typeSwal } = useSwal();

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);
  const { isModalOpen } = useAppSelector((state) => state.system);

  const dispatch = useDispatch();

  const data = formPlantilla;

  const [unSavedChanges, setUnSavedChanges] = useState(false);

  useEffect(() => {
    getModelos();
    getMarcas();
    listNumberingForm({ action: 'clearForm' }, { form: 'plantilla' });

    if (crudType === 'edit') {
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        { form: 'plantilla', element: 'id' }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.nombre,
        },
        { form: 'plantilla', element: 'nombre' }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'plantilla',
          element: 'marca',
        },
        location.state?.Marca
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'plantilla',
          element: 'modelo',
          type: 'associatedModelo',
        },
        location.state?.Modelo
      );

      listNumberingForm(
        {
          action: 'input',
        },
        {
          form: 'plantilla',
          element: 'observaciones',
        },
        location.state?.observaciones
      );

      listNumberingForm(
        {
          action: 'input',
        },
        {
          form: 'plantilla',
          element: 'blfs',
        },
        location.state?.blfs
      );
    } else {
      listNumberingForm(
        {
          action: 'input',
          value: '',
        },
        { form: 'plantilla', element: 'nombre' }
      );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayPlantillasHook === 'object' && arrayPlantillasHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'marca') {
              item2.options = [];
              arrayMarcasHook?.forEach((item3) => {
                if (item3?.marca === null) {
                  item3.marca = 'not defined';
                }
                const marca = {
                  value: item3?.id,
                  label: item3?.marca,
                };
                const found = item2.options.some((e) => e.value === marca.value);
                if (!found && marca.value !== undefined) {
                  item2.options.push(marca);
                }
              });
            }

            if (item2.ref.element === 'modelo') {
              item2.options = [];

              const found = arrayModelosHook.filter((model) => formPlantillaReducer.marca.value === model.marca);
              //   const found = item2.options.some((e) => e.value === modelo.value);

              if (found) {
                found.forEach((modelo) => {
                  const newModelo = {
                    value: modelo?.id,
                    label: modelo?.modelo,
                  };
                  item2.options.push(newModelo);
                });
                // item2.options.push({ value: modelo.value, label: modelo.label });
              } else {
                item2.options = [];
              }
            }
          });
          // return item;
        });
        if (crudType === 'edit' && formPlantillaReducer?.nombre === '' && location.state?.nombre === '' && formPlantillaReducer?.nombre !== location.state?.nombre) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((step) => {
            step.input.map((item) => {
              switch (item.ref.element) {
                case 'nombre':
                  item.defaultValues = formPlantillaReducer?.nombre || location.state?.nombre;
                  break;
                case 'marca':
                  item.defaultValues = formPlantillaReducer?.marca || location.state?.Marca;
                  break;
                case 'modelo':
                  item.defaultValues = formPlantillaReducer?.modelo || location.state?.Modelo;
                  break;
                case 'observaciones':
                  item.defaultValues = formPlantillaReducer?.observaciones || location.state?.observaciones;
                  break;
                case 'blfs':
                  item.defaultValues = formPlantillaReducer?.blfs || location.state?.blfs;
                  break;
                // case 'plantilla':
                //   item.defaultValues = location.state?.nombre || formPlantillaReducer?.plantilla;
                // break;
                default:
                  break;
              }
              return item;
            });
            return step;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              item2.defaultValues = 'pep';
              // formPlantillaReducer[item2.ref.element];
            });
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    setUnSavedChanges(false);
    if (crudType === 'create') {
      typeSwal('createLoader', '', formPlantillaReducer, getPlantillas, `/${routesWide.numbering}/${routesNumbering.plantillasList}`, 'plantilla', createPlantilla);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', formPlantillaReducer, getPlantillas, `/${routesWide.numbering}/${routesNumbering.plantillasList}`, 'plantilla', updatePlantilla);
    }
  };

  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          data={data}
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          onSubmit={onSubmit}
          onCancel={handleOnCancel}
          form={listNumberingForm}
          dataSave={dataSave}
          dataTabs={data.tabs || data.steps}
          // initialData={EMFormListExtensions}
          crudType={crudType}
          arrScreen={['Plantilla']}
        />
      )}
    </div>
  );
};

PlantillaForm.propTypes = { crudType: string };
