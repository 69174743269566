import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserScreenForm from '../../screens/UsersScreen/UsersScreen/UserScreenForm';
import UsersScreen from '../../screens/UsersScreen/UsersScreen/UsersScreen';
import { routesUser } from '../routes';
// import { UsersScreenWrapper } from '../../screens/UsersScreen/UsersScreen/UsersScreen.style';
// import { useStore } from 'react-redux';

const UsersNavigation = () => {
  // const { getState } = useStore();
  // const { isMenuOpen } = getState()['system'];

  return (
    // <UsersScreenWrapper isMenuOpen={isMenuOpen}>
    <Routes>
      <Route path="/" element={<UsersScreen />} />
      <Route path={routesUser.usersList} element={<UsersScreen />} />
      <Route path={routesUser.userCreate} element={<UserScreenForm crudType={'create'} />} />
      <Route path={routesUser.userUpdate} element={<UserScreenForm crudType={'edit'} />} />
    </Routes>
    // </UsersScreenWrapper>
  );
};

export default UsersNavigation;
