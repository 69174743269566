import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const PageScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .pageScreen__title {
    position: fixed;
    background-color: white;
    left: 13%;
    box-sizing: border-box;
    width: auto;
    // min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      background-color: white;
      margin-left: 10px;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.bold};
      letter-spacing: 0.25px;
    }
  }

  .icon__dispositivo {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 5px;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
  }

  .user__contain__table {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    z-index: 900;
    padding-bottom: 225px;
    background-color: ${colors.backgroundColor};

    .modal__form__body {
      .form__body {
        width: 100%;
        height: auto;
        ${'' /* max-width: 600px; */}
        margin: auto;
        border-radius: 10px;
        background-color: ${colors.backgroundColor};
        position: relative;
        top: 20%;

        .form__title {
          width: 100%;
          padding-top: unset;
          text-align: left;
          align-items: center;
          gap: 10px;
        }

        .icon__Form_title {
          color: ${colors.primaryColor};
        }
      }
      .container__cards {
        ${'' /* width: 80%; */}
        padding-top: unset;
      }
    }

    .left {
      width: 100%;
      height: 30px;
      padding-left: 5px;
      display: flex;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      ${'' /* min-width: 800px; */}

      ${
        '' /* .icon-plus {
        width: 35px;
        height: 35px;
        padding: 5px;
        background-color: #0c3fc4;
        color: white;

        &:hover {
          cursor: pointer;
          border-radius: 10px;
        }
      } */
      }

      ${
        '' /* .icon-menu {
        color: #0c3fc4;
        width: 100%;
        padding-left: 5px;
      } */
      }
    }

    .tooltip-column {
      width: 100%;
      color: white;
      display: flex;
      justify-content: space-between;
      padding-right: 5px;

      tr th:last-child div:last-child {
        justify-content: left;
      }
    }

    thead {
      background-color: ${colors.primaryColor};
      border-radius: 10px;
      color: white;
      position: relative;
      margin-left: 10px;
      z-index: 2;
    }

    .nav__buttons__container {
      height: 50px;
      width: 60%;
    }
    .user__table {
      height: 50px;
      width: 60%;
    }

    .section__name {
      width: max-content;
      display: flex;
      align-items: center;
      color: #0c3fc4;
      height: 30px;
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      z-index: 90;
      padding-left: 5px;
    }

    .navtable__container {
      width: 100%;
      height: 30px;
      position: relative;
      top: 0px;
      left: 0px;
      color: rgb(0, 0, 0);
      display: flex;
      justify-content: flex-end;
      z-index: 9;
      text-align: end;
      flex-direction: row-reverse;

      table {
        position: relative;
        top: -40px;
        left: 40px;
      }
    }

    tr th:nth-child(2) {
      border-left: none;
      font-weight: 700;
      cursor: pointer;
      width: 40px;
      min-width: unset;
    }

    .row {
      color: black;
      width: 100%;
      border-bottom: rgb(215, 215, 215) 1px solid;

      ${
        '' /* &:hover {
        td {
          color: ${colors.blue};
        }
      }
      td {
        cursor: auto;
        color: black;
      } */
      }
    }
  }

  .pageScreen {
    width: 100%;
    background-color: ${colors.backgroundColor};
    height: 80%;
    border-radius: 10px;
    ${'' /* z-index: 550; */}

    .form__body__users {
      ${'' /* width: 100%; */}
      ${'' /* position:absolute; */}
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
      gap: 30px;
      align-items: center;
      border-radius: 0 0 15px 15px;
      ${'' /* padding-top: 58px; */}

      .form__title {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        text-align: left;
        color: ${colors.primaryColorLight};
        font-weight: bold;
      }

      .icon__Form_title {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: ${colors.primaryColor};
      }

      .title__Form_title {
        display: flex;
        align-items: center;
        color: ${colors.primaryColor};
        font-size: 16px;
      }

      .container__cards {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;

        .contain__inputCard-component {
          width: 100%;
          height: 100%;
          padding: 40px;
          padding-right: 220px;

          .section__name {
            padding-left: 0;
          }
        }
        .section_name {
          padding-left: 0;
        }
        .stepForm__contain-typeInput {
          gap: 10px;
        }

        .contain__select {
          width: unset;
        }
      }
    }

    &__tabs {
      display: flex;
      justify-content: flex-start;
      border: none;
      position: fixed;
      z-index: 101;
      margin: auto;
      padding: 20px;
      height: 60px;
      width: 100%;

      &__tab {
        min-width: 130px;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: ${colors.blue};

        &:hover {
          cursor: pointer;
        }

        &__item {
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
          // font-size: ${newFontSize.h3};

          border: none;
        }
      }

      .active {
        min-height: 35px;
        padding: 5px;
        color: ${colors.blue};
        border-radius: 10px 10px 0 0;
        border-bottom: 2px solid ${colors.blue};
        font-weight: ${weight.bold};
      }

      .inactive {
        min-height: 35px;
        padding: 5px;
        color: ${colors.black};
        border-radius: 10px 10px 0 0;

        &:hover {
          cursor: pointer;
          color: ${colors.blue};
        }
      }
    }

    &__form {
      height: 100%;
      width: 100%;
      ${'' /* padding-left: ${(p) => (!p.isMenuOpen ? '30px' : '160px')}; */}
      ${'' /* padding-right: 30px; */}
      ${'' /* border: 1px solid ${colors.primaryColor}; */}
      ${
        '' /* background-color: ${colors.backgroundColor};
      border-radius: 15px 0; */
      }

      .contain__button-save {
        opacity: 1;
        height: 35px;
        position: fixed;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        top: 75px;
        right: 10px;
        ${'' /* z-index: 718; */}

        .cancel_button {
          color: lightgrey;
          background-color: ${colors.primaryColor};
          /* background-color: #c142269a; */
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: ${colors.primaryColorLight};
          }
        }

        .save_edit_button {
          color: black;
          background-color: ${colors.greenGYGAS};
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          margin-right: 20px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }
      }
    }
  }
`;
