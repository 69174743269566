import React from 'react';
// import { iconButton } from '../../data/dataButtons';
import { ButtonPasswordWrapper } from './ButtonPassword.style';
import { func, string } from 'prop-types';

import { IconEye, IconPencil } from '@tabler/icons-react';
// import { Button } from '../Button/Button';

export const ButtonPassword = ({ onClick, type }) => {
  // const { passwordWatcher } = IconEye;
  return (
    <ButtonPasswordWrapper onClick={onClick}>
      {/* <img src={passwordWatcher.icon} alt={passwordWatcher.alt} /> */}
      {type === 'Change' ? <IconPencil className="icon__password" /> : <IconEye className="icon__password" />}
      <div className="text__password">{/* <p>{text}</p> */}</div>
    </ButtonPasswordWrapper>
  );
};

ButtonPassword.propTypes = { onClick: func, text: string, type: string };
