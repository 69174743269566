import React from 'react';
// import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
// import { menuSettings } from '../../../data/dataSettings';
import { Navigate } from 'react-router-dom';

import '../../../styles/Screens/SettingsScreen/OverviewSettingsScreen.css';
import { OverviewScreen } from '../../OverviewScreen/OverviewScreen';

export const OverviewSettingsScreen = () => {
  return (
    <div className="overview-settings">
      <Navigate to="/settings" />
      {/* <LinkButtonBig menuTop={menuSettings} /> */}
      <OverviewScreen />
    </div>
  );
};
