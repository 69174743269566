import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterMailboxes, dataScreenMailBoxes } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { MailboxesScreenWrapper } from './MailboxesScreen.style';
import { string } from 'prop-types';
// import { NavTable } from '../../../components/NavTable/NavTable';

export const MailboxesScreen = () => {
  const { mailboxes, getMailboxes, deleteMailboxes, loading } = useApplications();
  const { typeSwal } = useSwal();
  const { isCardView } = useView();

  // const [checkBoxesActives] = useState([]);
  const [checkboxesActives, setCheckBoxesActive] = useState([]);
  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const { notFound } = dataScreenMailBoxes;

  const navigate = useNavigate();

  useEffect(() => {
    getMailboxes();
    setSearch([]);
  }, [setSearch, getMailboxes]);

  const COLUMNS = [
    {
      Header: 'Número',
      accessor: 'extension',
    },
    {
      Header: 'Nombre',
      accessor: 'fullname',
    },
    {
      Header: 'Pin',
      accessor: 'password',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Locución',
      accessor: 'soundPrompt',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
  ];

  const DATA = mailboxes.map(({ extension, id, AstVoicemail, password, email, Soundprompt, Company }) => ({
    id,
    extension,
    fullname: AstVoicemail?.fullname,
    password,
    email,
    soundPrompt: Soundprompt?.description,
    soundPromptId: Soundprompt?.id,
    company: Company?.name,
    companyId: Company?.id,
    Company,
    Soundprompt,
    astVoicemailId: AstVoicemail?.uniqueid,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(({ id, extension, fullname, password, email, soundPrompt, soundPromptId, company, companyId, Company, Soundprompt, astVoicemailId }) => ({
        title: fullname,
        subtitle: extension,
        text: soundPrompt,
        text2: company,
        id,
        extension,
        fullname,
        password,
        email,
        soundPrompt,
        soundPromptId,
        company,
        companyId,
        Company,
        Soundprompt,
        astVoicemailId,
      }));
    } else {
      return DATA.map(({ extension, id, AstVoicemail, password, email, Soundprompt, Company }) => ({
        title: AstVoicemail?.fullname,
        subtitle: extension,
        text: Soundprompt?.description,
        text2: Company?.name,
        id,
        extension,
        fullname: AstVoicemail?.fullname,
        password,
        email,
        soundPrompt: Soundprompt?.description,
        soundPromptId: Soundprompt?.id,
        company: Company?.name,
        companyId: Company?.id,
        Company,
        Soundprompt,
        astVoicemailId: AstVoicemail?.uniqueid,
      }));
    }
  };

  const dataToCards = dataCard();

  const dataExport = mailboxes.map(({ extension, AstVoicemail, password, email, Soundprompt, Company }) => ({
    Número: extension,
    'Nombre completo': AstVoicemail?.fullname,
    Pin: password,
    Email: email,
    Locución: Soundprompt?.description,
    Sede: Company?.name,
  }));

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.applications}/${routesApplications.createMailboxes}`, {});
  };
  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.applications}/${routesApplications.updateMailboxes}`, {
      state: item && item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteMailboxes(item.original);
          } else {
            response = await deleteMailboxes(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getMailboxes();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  // const handleUploadFile = async (file) => {
  //   try {
  //     let response = await uploadFileMailboxes(file);
  //     if (response.status === 200 || response.status === 201 || response.status === 204) {
  //       typeSwal('upload');
  //       getMailboxes();
  //     }
  //   } catch (err) {
  //     return err;
  //   }
  // };

  const tableInstance = useRef(null);

  const template = [
    {
      Número: '',
      Nombre: '',
      Pin: '',
      Email: '',
      'Locución Id': '',
      'Sede Id': '',
    },
  ];

  return (
    <MailboxesScreenWrapper isFilter={isFilter.open}>
      {/* <MenuTop menu={menuApplications} /> */}
      {/* <div className=""> */}
      <div className={'contain__buttons'}>
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
            filter: {
              position: 'right',
            },
          }}
          // importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
        />
      </div>
      {!mailboxes[0]?.status ? (
        <div className={'contain__menuFilter'}>
          <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterMailboxes} setSearch={setSearch} />
        </div>
      ) : null}
      {/* <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteMailboxes} /> */}
      {/* </div> */}
      {loading ? (
        <LoaderSpinner />
      ) : mailboxes[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            format={'list'}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            isNavTable={true}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckBoxesActive}
            // checkboxesActives={checkboxesActives}
          />
        </div>
      )}
    </MailboxesScreenWrapper>
  );
};

MailboxesScreen.propTypes = { row: string };
