import React, { useCallback, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
// Import your Modal component
import { bool, func, string } from 'prop-types';
import { Modal } from '../../components/Modal/Modal';

export function NavigationBlocker({ when, message, setUnSavedChanges }) {
  const [showModal, setShowModal] = useState(when);
  const [nextLocation, setNextLocation] = useState(null);

  const navigate = useNavigate();

  const blocker = useCallback(
    ({ currentLocation, nextLocation: next }) => {
      if (when && currentLocation.pathname !== next.pathname) {
        setNextLocation(next);
        setShowModal(true);
        return true;
      }
      return false;
    },
    [when]
  );

  useBlocker(blocker);
  // useEffect(() => {
  //   if (blocker.state === 'blocked' && when) {
  //     setUnSavedChanges(true);
  //   }
  // }, [blocker, when]);

  const handleConfirm = (event) => {
    event.stopPropagation();
    setShowModal(false);
    setUnSavedChanges(false);
    navigate(nextLocation.pathname);
    // Handle navigation confirmation
    if (blocker.state === 'blocked') {
      blocker.proceed();
    } else {
      navigate(nextLocation.pathname);
    }
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setShowModal(false);
    setNextLocation(null);
    // setUnSavedChanges(false);

    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  };

  return (
    <>
      {/* {showModal && ( */}
      <Modal
        content={[message]}
        // selectedValue={(value) => {
        //   if (value) {
        //     handleConfirm();
        //   } else {
        //     handleCancel();
        //   }
        // }}
        nextLocation={nextLocation}
        show={showModal}
        setShow={setShowModal}
        unSavedChanges={showModal}
        setUnSavedChanges={setUnSavedChanges}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
      />
      {/* )} */}
    </>
  );
}
NavigationBlocker.propTypes = { setUnSavedChanges: func, when: bool, message: string, unSavedChanges: bool };
