import styled from 'styled-components';
// import { colors } from '../../theme/colors.js';

export const WideWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  ${'' /* background-color: ${colors.backgroundColor}; */}
  border-radius: 15px;
  overflow: auto;
  ${'' /* margin: auto; */}
`;
