import React, { useEffect, useState } from 'react';
import { IconSquare, IconCheck } from '@tabler/icons-react';

import { CheckboxWrapper } from './Checkbox.style';
import { array, bool, func, object } from 'prop-types';

export const Checkbox = ({ row, checkBoxAllSelected, setCheckBoxAllSelected, checkboxesActives, setCheckboxesActives, DATA }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (checkBoxAllSelected) {
      setIsActive(true);
      if (row !== undefined) {
        const found = checkboxesActives.some((checkbox) => checkbox.id === row.id);
        if (!found) {
          checkboxesActives.push(row);
          const newCheckboxes = checkboxesActives;
          const found2 = checkboxesActives.some((checkbox) => checkbox.id === row.id);
          setIsActive(true);
          if (!found2) {
            newCheckboxes.map((item) => setCheckboxesActives([...checkboxesActives, item]));
          }
        }
      }
    } else {
      setIsActive(false);
      if (row !== undefined) {
        setCheckboxesActives([]);
      }
    }
  }, [checkBoxAllSelected]);

  //   } else {
  //     const found = row !== undefined ? checkboxesActives.some((checkbox) => checkbox.id === row.id) : false;
  //     if (found) {
  //       setIsActive(true);
  //     }
  //   }
  // }, [checkBoxAllSelected, checkboxesActives, row]);

  const handleCheckbox = () => {
    if (row === undefined) {
      setCheckBoxAllSelected(!checkBoxAllSelected);
      if (checkBoxAllSelected) {
        setCheckboxesActives(DATA);
      } else {
        setCheckboxesActives([]);
      }
    } else {
      const found = checkboxesActives.some((checkbox) => checkbox.id === row.id);

      if (found) {
        const filteredRows = checkboxesActives.filter((checkbox) => checkbox.id !== row.id);
        setCheckboxesActives(filteredRows);
        setIsActive(false);
        // let newCheckboxesValues = checkboxesActives;
        // const indexFound = newCheckboxesValues.findIndex((checkboxesActive) => checkboxesActive.id === row.id);

        // newCheckboxesValues = newCheckboxesValues.splice(indexFound, 1);

        // indexFound >= 0 ? delete checkboxesActives[indexFound] : '';

        // setCheckboxesActives(newCheckboxesValues);
      } else {
        setCheckboxesActives([...checkboxesActives, row]);
        setIsActive(true);
      }
    }
  };
  // const handleIsChecked = () => {
  //   const found =
  //     row & Array.isArray(checkboxesActives) & (checkboxesActives.length > 0)
  //       ? checkboxesActives.some((checkbox) => checkbox.id === row.id || (checkbox.soundpromptId && checkbox.soundpromptId === row.id))
  //       : false;
  //   // const found = checkboxesActives.some((checkbox) => checkbox.id === row.id);
  //   if (found) {
  //     const filteredRows = Array.isArray(checkboxesActives) ? checkboxesActives.filter((checkbox) => checkbox.id !== row.id || (checkbox.soundpromptId && checkbox.soundpromptId === row.id)) : [];
  //     setIsActive(!isActive);
  //     setCheckboxesActives(filteredRows);
  //   } else {
  //     setIsActive(false);
  //     setCheckboxesActives([...checkboxesActives, row]);
  //   }
  // };

  // useEffect(() => {
  //   handleIsChecked();
  // }, [checkboxesActives]);

  return (
    <CheckboxWrapper>
      {!isActive && !checkBoxAllSelected ? (
        // checkboxesActives.length === 0 ? (
        // checkboxesActives.some((checkbox) => checkbox.id === row.id || (checkbox.soundpromptId && checkbox.soundpromptId === row.id))
        <div className="checkbox__icon">
          <IconSquare className="checkbox__icon__square" strokeWidth={1} height={'20px'} onClick={handleCheckbox} />
        </div>
      ) : isActive ? (
        <div className="checkbox__icon">
          {/* <IconSquare className="checkbox__icon__square" strokeWidth={1} height={'20px'} onClick={handleCheckbox} />{' '} */}
          <IconCheck className="checkbox__icon__check" strokeWidth={2} height={'20px'} onClick={handleCheckbox} />
        </div>
      ) : (
        <div className="checkbox__icon">
          <IconSquare className="checkbox__icon__square" strokeWidth={1} height={'20px'} onClick={handleCheckbox} />
        </div>
      )}
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = { setInputValue: func, row: object, checkBoxAllSelected: bool, setCheckBoxAllSelected: func, checkboxesActives: array, setCheckboxesActives: func, DATA: array };
