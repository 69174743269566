import React from 'react';

import PermissionContext from './PermissionContext';
import { array, object, oneOfType } from 'prop-types';
// import { ErrorBoundary } from 'react-error-boundary';
// import FallbackRender from '../../components/FallbackRenderer/FallbackRenderer';

const PermissionProvider = ({ permissionsUser, children }) => {
  const isAllowedTo = permissionsUser && permissionsUser.length > 0 ? (permission) => permissionsUser.includes(permission) : () => false;

  return (
    // <ErrorBoundary fallbackRender={FallbackRender}>
    <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>
    // </ErrorBoundary>
  );
};

PermissionProvider.propTypes = { permissionsUser: array, children: oneOfType([array, object]) };

export default PermissionProvider;
