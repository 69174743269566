import { object } from 'prop-types';
import React, { createContext, useState } from 'react';

export const Context = createContext();

export const AppContext = ({ children }) => {
  const [currentObjectUser, setCurrentObjectUser] = useState({});
  const [dataPermissions, setDataPermissions] = useState({});
  return (
    <Context.Provider
      value={{
        currentObjectUser,
        setCurrentObjectUser,
        dataPermissions,
        setDataPermissions,
      }}>
      {children}
    </Context.Provider>
  );
};

AppContext.propTypes = { children: object };
