import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const PhoneWrapper = styled.div`
  height: 100%;
  width: 100%;

  .phone {
    height: 100%;
    width: 100%;
    padding-top: 20px;
    gap: 10px;

    &__left {
      display: flex;

      flex-direction: column;
    }

    &__container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: left;
      column-gap: 45px;
      padding: 10px;
    }

    &__key {
      height: 50px;
      width: 50px;
    }

    &__keys {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      column-gap: 5px;
      row-gap: 5px;
      max-width: 200px;
      max-height: 200px;
    }

    &__selection {
      height: 100%;
      width: 100%;
    }

    &__selection__items {
      // height: 100%;
      height: 50px;
      width: 100%;
      display: flex;
      // margin-bottom: 30px;
      column-gap: 30px;
      justify-content: space-around;
      align-items: end;
    }

    &__key__active {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: ${colors.black};
      text-align: left;
      height: 40px;
      flex: 1;
    }

    &__select {
      flex: 4;
      height: 100%;
    }
    &__list__actives {
      flex: 2;
    }
  }

  .phone__info {
    display: grid;
    grid-template-columns: 1fr 8fr;
    // margin-top: 70px;
    height: auto;
    width: 100%;
    // row-gap: 30px;
    column-gap: 20px;
    background-color: ${colors.greyHoverCard};
    border-radius: 15px;
    font-size: 12px;
    padding: 20px;
    align-items: baseline;
    padding-bottom: 10px;

    .phone__option__active {
      height: 100%;
      width: 100%;
      max-width: 300px;

      .phone__option__item {
        background: ${colors.secondaryColor};
        border: 1px solid ${colors.greyLine};
        border-radius: 5px;
        align-self: center;
        display: grid;
        padding: 8px 15px;
        // width: 390px;
        grid-template-columns: 9fr 1fr;

        p {
          margin: 0px;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: ${colors.black};
          align-self: center;
        }

        img {
          justify-self: end;
          align-self: center;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .phone__notPressed {
    height: 100%;
    width: 300px;
    margin-top: 30px;
    display: flex;

    flex-direction: column;
    align-items: baseline;
    justify-content: start;
    // margin-bottom: 30px;

    &__contain {
      height: 100%;
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      column-gap: 20px;
    }

    &__select {
      width: 300px;
    }

    &__title {
      &__item {
        // font-weight: 500;
        ${'' /* font-size: 16px; */}
        line-height: 18px;
        color: ${colors.black};
        align-self: center;
      }
    }
  }

  .phone__timeOut {
    height: 100%;

    margin-top: 20px;

    &__contain {
      height: 100%;
      width: 100%;
    }
    &__title {
      p {
        margin: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: ${colors.black};
        align-self: center;
      }
    }
    &__input {
      margin-top: 10px;
      height: 36px;

      p {
        margin: 0px;
        font-size: 14px;
      }
    }
  }
`;
