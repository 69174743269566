import React from 'react';
import { useRef, useState } from 'react';
// import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';

import './GeneralScreen.css';

export const GeneralScreen = () => {
  const [, setIsBottom] = useState(false);
  const tableInstance = useRef(null);

  const COLUMNS = [
    {
      Header: 'Sede',
      accessor: 'site',
    },
    {
      Header: 'Dpto',
      accessor: 'department',
    },
    {
      Header: 'Fecha',
      accessor: 'date',
    },
    {
      Header: 'Tipo',
      accessor: 'type',
    },
    {
      Header: 'Origen',
      accessor: 'origin',
    },
    {
      Header: 'Ext Origen',
      accessor: 'extOrigin',
    },
    {
      Header: 'Destino',
      accessor: 'destination',
    },
    {
      Header: 'Ext Destino',
      accessor: 'extDestination',
    },

    {
      Header: 'Duración',
      accessor: 'duration',
    },
    {
      Header: 'Estado',
      accessor: 'status',
    },
  ];

  const DATA = [
    {
      id: '123456',
      date: '2021-01-01',
      type: 'Inbound',
      origin: '123456',
      extOrigin: '12',
      destination: '123456',
      extDestination: '20',
      site: 'CAP',
      department: 'Cardiologia',
      duration: '20s',
      status: '2',
    },
    {
      id: '324324',
      date: '2021-01-01',
      type: 'Inbound',
      origin: '123456',
      extOrigin: '14',
      destination: '123456',
      extDestination: '30',
      site: 'Hospital',
      department: 'Pediatría',
      duration: '10s',
      status: '1',
    },
  ];
  const dataExport = [];

  // const handleOpenFormCreate = () => {
  // dispatch(selectedDocActionCreator(ivr[0]));
  // navigate(`/${routesWide.applications}/${routesApplications.createIvr}`, {});
  // };

  const handleOpenInfo = () => {};
  const template = [];
  return (
    <div className="general-screen">
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            // add: {
            //   position: 'left',
            //   function: handleOpenFormCreate,
            // },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
        />
      </div>

      {/* {false ? (
        <LoaderSpinner />
      ) : ( */}
      <div className="contain__table">
        <TableInfo
          format={'list'}
          ref={tableInstance}
          DATA={DATA}
          COLUMNS={COLUMNS}
          updateFunction={null}
          deleteItem={null}
          isOptions={false}
          onClick={handleOpenInfo}
          setIsBottom={setIsBottom}
          isCheckbox={false}
          isNavTable={false}
          setDataCard={() => {
            false;
          }}
        />
      </div>
      {/* )} */}
    </div>
  );
};
