import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ButtonPassword } from '../ButtonPassword/ButtonPassword';
// import { iconButton } from '../../data/dataButtons';
import { HideColumnWrapper } from './HideColumn.style';
import { useSwal } from '../../hooks/useSwal';
import { string } from 'prop-types';
import { IconClipboardCopy } from '@tabler/icons-react';

export const HideColumn = ({ text, data, id }) => {
  // const { copy } = iconButton;
  const { typeSwal } = useSwal();

  const handleCopy = () => {
    // navigator.clipboard.writeText(data);
    // event.current.select();

    // document.execCommand('copy');

    return false;
  };

  const handlePassword = () => {
    typeSwal(text, data);
  };

  return (
    <HideColumnWrapper>
      <>
        <ButtonPassword onClick={handlePassword} text={text} />
        <div className="hideColumn__contain" onClick={handleCopy}>
          {/* <img className="hideColumn__copy" src={copy.icon} alt={copy.alt} /> */}
          <IconClipboardCopy className="hideColumn__copy" />
          <div className="hideColumn__tooltip" id={id} data-tooltip-delay-hide={1000} />
        </div>
        <ReactTooltip className="tooltip" anchorSelect={id + '__' + text} place="top" content={'¡Copiado!'} events={['click']} />
      </>
    </HideColumnWrapper>
  );
};

HideColumn.propTypes = { text: string, data: string, id: string };
