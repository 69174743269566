import bubble from '../../assets/images/login/bubble.png';
import bubble2 from '../../assets/images/login/bubble2.png';
import logoCompany from '../../assets/images/logo/zerovoz-white.png';
import iconLogout from '../../assets/images/login/logout.svg';
import mail from '../../assets/images/login/mail.svg';
// import fingerprint from '../../assets/images/login/fingerprint.svg';

import { IconPassword } from '@tabler/icons-react';

export const keywords = {
  title: '',
  subtitle: 'Más allá de las soluciones de comunicaciones estándar',
  email: 'Correo electrónico',
  password: 'Contraseña',
  button: 'Iniciar sesión',
};

export const imagesLogin = {
  bubble,
  bubble2,
  logoCompany,
  mail,
  IconPassword,
};

export const messagesLogin = {
  success: 'Bienvenido a la aplicación :)',
  error: 'Email o password incorrectos',
};

export const logoutIcon = {
  icon: iconLogout,
};
