import React from 'react';
import { dataActivityGraphs } from '../../../data/dataCallRegister';
import LineCharts from '../../../components/Graphs/Charts';
import BarCharts from '../../../components/Graphs/BarCharts';
import PieCharts from '../../../components/Graphs/PieCharts';
import TableChart from '../../../components/Graphs/TableChart';

import './ActivityScreen.css';

const ActivityScreen = () => {
  const dataLine = [
    { name: 'Lunes', salientes: 320, entrantes: 100, duracionSal: 314, duracionEnt: 200 },
    { name: 'Martes', salientes: 0, entrantes: 100, duracionSal: 210, duracionEnt: 123 },
    { name: 'Miércoles', salientes: 200, entrantes: 220, duracionSal: 100, duracionEnt: 176 },
    { name: 'Jueves', salientes: 250, entrantes: 90, duracionSal: 240, duracionEnt: 231 },
    { name: 'Viernes', salientes: 140, entrantes: 20, duracionSal: 212, duracionEnt: 254 },
    { name: 'Sábado', salientes: 430, entrantes: 132, duracionSal: 321, duracionEnt: 287 },
    { name: 'Domingo', salientes: 290, entrantes: 140, duracionSal: 132, duracionEnt: 197 },
  ];

  const dataKeys = [
    { dataKey: 'salientes', color: '#B49A6A' },
    { dataKey: 'entrantes', color: '#88A5C0' },
    { dataKey: 'duracionSal', color: '#8E88AF' },
    { dataKey: 'duracionEnt', color: '#74A6A2' },
  ];

  const dataBar = [
    { name: 'Lunes', num: 100, color: '#7357F6' },
    { name: 'Martes', num: 313, color: '#ED7E63' },
    { name: 'Miércoles', num: 300, color: '#91B5B2' },
    { name: 'Jueves', num: 400, color: '#F0BD58' },
    { name: 'Viernes', num: 231, color: '#7393B2' },
  ];

  const dataPie = [
    { name: 'Lunes', num: 100, color: '#7357F6' },
    { name: 'Martes', num: 313, color: '#ED7E63' },
    { name: 'Miércoles', num: 300, color: '#7A9E9A' },
    { name: 'Jueves', num: 400, color: '#F0BD58' },
    { name: 'Viernes', num: 231, color: '#84BBF0' },
  ];

  const dataPie2 = [
    { name: 'Lunes', num: 200, color: '#F0BD58' },
    { name: 'Martes', num: 113, color: '#97DFD8' },
    { name: 'Miércoles', num: 430, color: '#84BBF0' },
    { name: 'Jueves', num: 123, color: '#7357F6' },
    { name: 'Viernes', num: 531, color: '#ED7E63' },
  ];

  const dataTable = [
    { num: '90213123', ext: '1', name: 'Juan', seconds: 100 },
    { num: '92131234', ext: '2', name: 'Adrian', seconds: 200 },
    { num: '91231234', ext: '3', name: 'Pedro', seconds: 300 },
    { num: '91231234', ext: '4', name: 'Maria', seconds: 400 },
    { num: '91231234', ext: '5', name: 'Jose', seconds: 500 },
  ];

  return (
    <div className="activity-screen">
      {/* <div className="selector">Semana</div> */}
      <div className="graph_grid">
        <div className="totals__container">
          <div className="first">
            <div className="container">
              <div className="graph__header">
                <h5>{dataActivityGraphs.topGraphs[0].title}: </h5>
                <h3>100</h3>
              </div>
              <div className="stats">
                {/* <p>100</p> */}
                <div className="result">
                  <p className="percentatge positive">+50,11 %</p>
                  <img alt="" src={dataActivityGraphs.upArrow} width="50px" height="50px" />
                </div>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="container">
              <div className="graph__header">
                <h5> {dataActivityGraphs.topGraphs[1].title}:</h5>
                <h3>430</h3>
              </div>
              <div className="stats">
                {/* <p>5:00 min</p> */}
                <div className="result">
                  <p className="percentatge positive">+8,78 % </p>
                  <img alt="" src={dataActivityGraphs.upArrow} width="50px" height="50px" />
                </div>
              </div>
            </div>
          </div>
          <div className="third">
            <div className="container">
              <div className="graph__header">
                <h5> {dataActivityGraphs.topGraphs[2].title}: </h5>
                <h3>430</h3>
              </div>
              <div className="stats">
                <div className="result">
                  <p className="percentatge negative">-0,65 % </p>
                  <img alt="" src={dataActivityGraphs.downArrow} width="50px" height="50px" />
                </div>
              </div>
            </div>
          </div>
          <div className="fourth">
            <div className="container">
              <div className="graph__header">
                <h5> {dataActivityGraphs.topGraphs[3].title}: </h5>
                <h3> 1:15 min</h3>
              </div>
              <div className="stats">
                {/* <p>1:15 min</p> */}
                <div className="result">
                  <p className="percentatge negative">-11,66 % </p>
                  <img alt="" src={dataActivityGraphs.downArrow} width="50px" height="50px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="lateral">
          <div className="fixed border">
            <h3> {dataActivityGraphs.lateralGraph.title} </h3>
            <div>
              <p>Mapamundi</p>
              <p>Countries</p>
            </div>
          </div>
        </div> */}

        <div className="graph border">
          <LineCharts data={dataLine} dataKeys={dataKeys} />
        </div>

        <div className="bottom_one border centered">
          <h3> {dataActivityGraphs.bottomgraphs.first.title} </h3>
          <div className="chart_contain">
            <BarCharts data={dataBar} />
          </div>
        </div>
        <div className="bottom_two border centered">
          <h3> {dataActivityGraphs.bottomgraphs.second.title} </h3>
          <div className="pie_first">
            <PieCharts
              data={dataPie}
              pieProps={{ innerRadius: '60%', layout: 'vertical', verticalAlign: 'center', align: '', width: '' }}
              wrapperStyle={{ lineHeight: '34px', top: '50%', right: '-10%', transform: 'translateY(-50%)' }}
            />
          </div>
        </div>
        <div className="bottom_three border centered">
          <h3> {dataActivityGraphs.bottomgraphs.third.title} </h3>
          <div className="chart_contain">
            <TableChart data={dataTable} />
          </div>
        </div>
        <div className="bottom_fourth border">
          <h3> {dataActivityGraphs.bottomgraphs.fourth.title} </h3>
          <div className="pie_second">
            <PieCharts data={dataPie2} pieProps={{ innerRadius: '0%', layout: 'horizontal', verticalAlign: 'bottom', align: 'center', width: '' }} wrapperStyle={{ lineHeight: '15px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityScreen;
