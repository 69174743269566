import React, { useEffect, useState } from 'react';

import { NavMenuLeft } from '../../components/NavMenuLeft/NavMenuLeft';
import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { bool, func, object } from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import Popout from 'react-popout';
import { NavMenuLeftWrapper } from './NavMenuLeftScreen.style.jsx';
import { isLocationModifiedActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers.jsx';
import { IconHeadset } from '@tabler/icons-react';
// import { useState } from 'react';
// import { Modal } from '../../components/Modal/Modal.jsx';
// import { MenuTop } from '../../components/MenuTop/MenuTop.jsx';

export const NavMenuLeftScreen = ({ user }) => {
  // export const NavMenuLeftScreen = ({ user, setIsClosed }) => {
  // const { isDataModified, isLocationModified } = useSelector((state) => state.system);
  // const [isWarning, setIsWarning] = useState(false);
  const [isPopout, setIsPopout] = useState(false);

  const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];
  const dispatch = useDispatch();
  // const setShowWarning = (status) => {
  //   event.preventDefault(event);
  //   if (status !== undefined) {
  //     setIsWarning(status);

  //     console.log('setShowWarning updated: ' + status);
  //   }
  // };

  const [lastLocation, setLastLocation] = React.useState('');

  useEffect(() => {
    // execute on location change
    if (lastLocation !== '' && lastLocation !== location.pathname) {
      dispatch(isLocationModifiedActionCreator(true));
      // setShow(true);
      console.log('Location changed!', location.pathname);
    }
    setLastLocation(location.pathname);
  }, [location]);
  // const handleOnClick = () => {
  //   event.stopPropagation();
  //   navigator.block;
  //   isDataModified && isLocationModified ? setIsWarning(true) : '';
  // };

  const handlePopout = () => {
    setIsPopout(true);
  };

  // const handlePopupClosed = () => {
  //   setIsPopout(false);
  // };

  return (
    <NavMenuLeftWrapper isMenuOpen={isMenuOpen} className="navMenuLeft__container">
      {/* {isWarning || isLocationModified ? <Modal content={['Aviso']} show={isLocationModified} isWarning={false} selectedValue={(value) => value} type="isDirty" is isDirty={true} /> : ''} */}
      <NavMenuLeft isMenuOpen={isMenuOpen} menu={menuNavLeft} user={user} />
      <div className="nav__buttons__container">
        <IconHeadset onClick={handlePopout} />
        {isMenuOpen ? <span className="nav__button">Softphone</span> : null}
        {isPopout && (
          <Popout containerClassName="popout__container" url="../../../softphone.html" title="Softphone - Wide Zerovoz">
            <div className="view__name">Softphone</div>
            <button onClick={() => setIsPopout(false)}>Close</button>
          </Popout>
        )}
      </div>
    </NavMenuLeftWrapper>
  );
};

NavMenuLeftScreen.propTypes = { user: object, isClosed: bool, setIsClosed: func };
