import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { LoginScreen } from '../../screens/LoginScreen/LoginScreen';
import StaticScreen from '../../screens/StaticScreen/StaticScreen';
import { StaticNavigationScreenWrapper } from './StaticNavigation.style';
import { useStore } from 'react-redux';
// import { Modal } from '../../components/Modal/Modal';
// import { isLocationModifiedActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { WarnUserBeforeReloadOrExit } from '../../components/WarnUserBeforeReloadOrExit/WarnUserBeforeReloadOrExit';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';

// import { ToastContainer } from 'react-toastify';

export const StaticNavigation = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(JSON.parse(localStorage.getItem('tokenStorage')) || false);

  const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];

  const setAuth = (value) => {
    setIsAuthenticated(value);
  };

  useEffect(() => {
    localStorage.setItem('tokenStorage', JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  // const dispatch = useDispatch();
  // const [lastLocation, setLastLocation] = React.useState('');
  // const [show, setShow] = React.useState(true);

  // useEffect(() => {
  //   // execute on location change
  //   if (lastLocation !== '' && lastLocation !== location.pathname) {
  //     dispatch(isLocationModifiedActionCreator(true));
  //     setShow(true);
  //     console.log('Location changed!', location.pathname);
  //   }
  //   setLastLocation(location.pathname);
  // }, [location]);

  // useEffect(() => {
  //   setShow(true);
  //   const handleBeforeUnload = (event) => {
  //     console.log('stepf handleBeforeUnload');
  //     // Perform actions before the component unloads
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  // const {  } = useSelector((state) => state.system);
  // const [isDirty, setIsFormDirty] = React.useState(isDataModified);
  // const [isWarned, setIsWarned] = React.useState(false);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route exact path={'/*'} element={isAuthenticated === false ? <Navigate to={'/login'} replace /> : <StaticScreen />} />
        <Route path="/login" element={<LoginScreen setAuth={setAuth} />} />
      </Route>
    )
  );
  return (
    <StaticNavigationScreenWrapper className="App__container" isMenuOpen={isMenuOpen}>
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true,
          v7_fetcherPersist: true,
          v7_normalizeFormMethod: true,
          v7_relativeSplatPath: true,
        }}
      />
    </StaticNavigationScreenWrapper>
  );
};
