import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formDepartment } from '../../../data/dataPreferences';
import { useDepartments } from '../../../hooks/useDepartments';
import usePreferences from '../../../hooks/usePreferences';
import { useSwal } from '../../../hooks/useSwal';
import { routesPreferences, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import useNumbering from '../../../hooks/useNumbering';

export const DepartmentForm = ({ crudType }) => {
  const { createDepartment, updateDepartment, departmentForm, getDepartments } = useDepartments();
  const { arrayNumberingHook } = useNumbering();
  const { preferencesForm } = usePreferences();
  const { typeSwal } = useSwal();

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const location = useLocation();

  const data = formDepartment;

  useEffect(() => {
    preferencesForm(
      { action: 'clear' },
      {
        form: 'department',
      }
    );

    if (crudType === 'edit') {
      setDataSave(true);
      preferencesForm(
        {
          action: 'select-option',
        },
        {
          form: 'department',
          element: 'ddiId',
        },
        { value: location.state?.Ddi.id, label: location.state?.Ddi.description }
      );

      preferencesForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form: 'department',
          element: 'name',
        }
      );

      // Object.keys(location.state).map((item) =>
      //   data.steps.map((item2) =>
      //     item2.input.map((item3) => {
      //       if (item === item3.ref.element && item3.ref.element !== 'ddiId') {
      //         return preferencesForm(
      //           {
      //             action: item3.cardType === 'id' ? 'input' : item3.cardType,
      //             value: location.state[item],
      //           },
      //           {
      //             form: item3.ref.form,
      //             element: item3.ref.element,
      //           }
      //         );
      //       } else {
      //         return null;
      //       }
      //     })
      //   )
      // );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof departmentForm === 'object') {
        data.steps.map((step) => {
          const result = step.input.map((input) => {
            input.defaultValues = '';
            input.options = [];
            if (input.ref.element === 'ddiId') {
              arrayNumberingHook.forEach((itemNumb) => {
                if (itemNumb.ddi === null) {
                  itemNumb.ddi = 'not defined';
                }
                const departmentValue = {
                  value: itemNumb.id,
                  label: itemNumb.ddi,
                };
                const foundDepartment = input.options.some((option) => option.value === departmentValue.value);
                if (!foundDepartment && departmentValue.value !== undefined) {
                  input.options.push(departmentValue);
                }
              });
            }
            return step;
          });
          return result;
        });

        if (crudType === 'edit' && (departmentForm.ddiId === '' || departmentForm.ddiId !== location.state?.ddiId)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = departmentForm.name;
                  break;
                case 'ddiId':
                  item2.defaultValues = { value: location.state?.Ddi?.id, label: location.state?.Ddi?.description };
                  break;
                default:
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', preferencesForm, getDepartments, `/${routesWide.preferences}/${routesPreferences.department}`, 'department', createDepartment);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', preferencesForm, getDepartments, `/${routesWide.preferences}/${routesPreferences.department}`, 'department', updateDepartment);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          onCancel={handleOnCancel}
          reducerForm={departmentForm}
          arrScreen={['Departamento']}
          data={data}
          onSubmit={onSubmit}
          form={preferencesForm}
          dataSave={dataSave}
          crudType={crudType}
        />
      )}
    </>
  );
};

DepartmentForm.propTypes = { crudType: string };
