import React from 'react';
import ReactDOM from 'react-dom';

import { Modal } from '../components/Modal/Modal';

export const useRenderModal = () => {
  const renderModal = (content, handleClose, dataModal, columnsModal, setDataCard) => {
    const modalRoot = document.getElementById('modalRoot');

    const element = (
      <Modal show={true} handleClose={handleClose} dataModal={dataModal} columnsModal={columnsModal} setDataCard={setDataCard}>
        {content}
      </Modal>
    );

    return ReactDOM.createPortal(element, modalRoot);
  };

  return { renderModal };
};
