import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { IconRainbow, IconSettings, IconVariable } from '@tabler/icons-react';
import { FormScreen } from '../FormScreen/FormScreen';
import { PageScreenWrapper } from './PageScreen.style';
import { array, bool, func, object, string } from 'prop-types';
// import { IconPhone } from '@tabler/icons-react';
// import { IconCloud } from '@tabler/icons-react';
// import { IconUsers } from '@tabler/icons-react';
import useNumbering from '../../hooks/useNumbering';
import { TableInfo } from '../TableInfo/TableInfo';
import PasswordWatcher from '../PasswordWatcher/PasswordWatcher';
import { ExtensionsFormScreen } from '../../screens/NumberingScreen/ExtensionsScreen/ExtensionsFormScreen';
import { NavButtons } from '../NavButtons/NavButtons';
// import { useNavigate } from 'react-router-dom';
import { isModalOpenActionCreator, selectedDocActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { optionsAllow } from '../../data/dataOutbound';
import { getArrayFormatted, getIcon } from '../../utils/AppUtils';
import { clearExtensionFormAction } from '../../redux/actions/numbering/actionCreator';
import { ListNumberModalFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberModalFormScreen';
// import { ListNumberFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberFormScreen';
// import { ListNumberModalFormScreen } from '../../screens/NumberingScreen/ListNumber/ListNumberModalFormScreen';
// import { optionsAllow } from '../../data/dataOutbound';
// import { getArrayFormatted } from '../../utils/AppUtils';
// import { useStore } from 'react-redux';
// import { usersFormReducer } from '../../redux/reducers/settingsReducers/userPosts/userReducer';

export const PageScreen = ({
  isEditMode,
  data,
  dataExport,
  isModalOpen,
  isMenuOpen,
  // setIsModalOpen,
  // handleOpenFormCreate,
  render,
  setRender,
  onSubmit,
  form,
  crudType,
  maxwidth,
  arrScreen,
  reducerForm,
  dataModalButtonToTable,
}) => {
  const [pageSelected, setPageSelected] = useState(1);
  // const navigate = useNavigate();
  // const navigate = useNavigate();
  const [, setIsBottom] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState([]);
  const { arrayNumberingExtensionsHook, getExtensions } = useNumbering();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const [checkboxesActives, setCheckboxesActives] = useState([]);

  useEffect(() => {
    getExtensions();
  }, []);

  const handlePageSelect = (id) => {
    setRender(!render);
    setPageSelected(id);
  };

  // const icon = (tab) => {
  //   let icon = {};
  //   // const result = () => {
  //   switch (tab.text) {
  //     case 'Teams / Rainbow':
  //       icon = <IconRainbow />;
  //       break;
  //     case 'Sístema':
  //       icon = <IconSettings />;
  //       break;
  //     case 'Variables':
  //       icon = <IconVariable />;
  //       break;
  //     case 'SBC SIP/SJSIP':
  //       icon = <IconPhone />;
  //       break;
  //     default:
  //       // icon = <IconSettings />;
  //       icon = null;
  //       break;
  //   }

  //   return icon;
  // };

  const COLUMNS = [
    {
      Header: '  ',
      id: 'iconDevices__column',
      accessor: '',
      Cell: ({ row }) => {
        const newIcon = getIcon(row, 'extensions');
        return newIcon;
        // switch (row.original.typeExtension) {
        //   case 'rainbow':
        //     return <IconRainbow className="icon__dispositivo" />;

        //   case 'virtual':
        //     return <IconCloud className="icon__dispositivo" />;

        //   case 'SIP':
        //     return <IconPhoneOff className="icon__dispositivo" />;

        //   case 'teams':
        //     return <IconUsers className="icon__dispositivo" />;

        //   case 'terminal':
        //     return <IconPhone className="icon__dispositivo" />;

        //   default:
        //     break;
        // }
      },
    },
    {
      Header: 'Tipo',
      accessor: 'typeExtension',
    },
    {
      Header: 'Identificador',
      accessor: 'identification',
    },

    { Header: 'Contraseña', accessor: 'password', Cell: ({ row }) => <PasswordWatcher row={row} showPassword={showPassword} text={'Contraseña'} />, disableSortBy: true },
  ];

  const tableInstance = useRef(null);

  const handleOpenFormEdit = (item) => {
    setExtensionCrudType('edit');
    dispatch(isModalOpenActionCreator(true));
    setIsDropdownActive(false);
    if (item.original) {
      const newExt = arrayNumberingExtensionsHook?.filter((item2) => item2.identification === item.original?.identification);
      newExt[0].allow = getArrayFormatted(newExt[0]?.allow, optionsAllow, 'allow');
      newExt[0].typeExtension = { label: newExt[0].typeExtension, value: newExt[0].typeExtension };
      dispatch(selectedDocActionCreator(newExt[0]));
    } else {
      dispatch(selectedDocActionCreator(arrayNumberingExtensionsHook[0]));
    }
    // { label: newExt[0].typeExtension, value: newExt[0].typeExtension };
    // [0].toUpperCase() + newExt[0].typeExtension.slice(1), value: newExt[0].typeExtension };
    dispatch(isModalOpenActionCreator(true));
    // navigate('/users/usersList/update');
    // , { state: newExt[0] });

    // location.state = newExt[0];
    // navigate('/numbering/extensions/update', {
    //   state: newExt[0],
    // });
  };

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [extensionCrudType, setExtensionCrudType] = useState('createUserDispo');

  const handleClickAssign = () => {
    // typeSwal(type, name, hookForm, action, path, form, hookFunction);
    // setExtensionCrudType('createUserDispo');
    // location.state = formExtensionsReducer;
    setExtensionCrudType('assignUserDispo');
    dispatch(isModalOpenActionCreator(true));
    setIsDropdownActive(false);
  };
  // const COLUMNS = [

  //   {
  //     Header: ' ',
  //     accessor: '',

  //     Cell: ({ row }) =>
  //       row.original.typeExtension === 'rainbow' ? <IconRainbow /> : row.original?.typeExtension === 'virtual' ? <IconCloud /> : row.original?.typeExtension === 'SIP' ? <IconPhone /> : null,
  //   },

  //   {
  //     Header: 'Id.',
  //     accessor: 'identification',
  //   },
  //   {
  //     Header: 'Tipo',
  //     accessor: 'typeExtension',
  //   },
  // ];

  // const tableInstance = useRef(null);

  // const handleOpenFormEdit = (item) => {
  //   const newExt = arrayNumberingExtensionsHook?.filter((item2) => item2.Ddi.ddi === item.original.identification);

  //   navigate('/numbering/extensions/update', {
  //     state: newExt[0],
  //   });
  // };

  // const [isDropdownActive, setIsDropdownActive] = useState(false);
  // const [extensionCrudType, setExtensionCrudType] = useState('createUserDispo');

  // const handleClickAssign = () => {
  //   // typeSwal(type, name, hookForm, action, path, form, hookFunction);
  //   // setExtensionCrudType('createUserDispo');
  //   // location.state = item.original;
  //   setExtensionCrudType('assignUserDispo');
  //   setIsModalOpen(true);
  //   setIsDropdownActive(false);
  // };

  // const handleClickCreate = () => {

  //   setIsModalOpen(true);
  //   setIsDropdownActive(false);
  // };

  // const arrayRules = [];
  const dataRules = (data2) => {
    arrayNumberingExtensionsHook?.forEach((row) => {
      if (row.userId === data2?.id) {
        const newRow = {
          // id: row?.id,
          identification: row.Ddi?.ddi,
          // extension: row.extension,
          // userId: row?.User.fullName,
          typeExtension: row.typeExtension,
        };
        row.id ? arrayRules.push(newRow) : null;
      }
    });
    return arrayRules;
  };

  // const handleOnClickOut = (event) => {
  //   event.preventDefault();
  //   setIsModalOpen(false);
  //   setIsDropdownActive(false);
  // };
  // const handleOnClickIn = (event) => {
  //   event.stopPropagation();
  // };
  const handleClickCreate = () => {
    // typeSwal(type, name, hookForm, action, path, form, hookFunction);
    // location.state = item.original;
    // setExtensionCrudType('createUserDispo');
    dispatch(isModalOpenActionCreator(true));
    dispatch(selectedDocActionCreator(arrayNumberingExtensionsHook[0]));
    // navigate('/users/usersList/create', {
    //   state: arrayNumberingExtensionsHook[0],
    // });
    setIsDropdownActive(false);
  };

  const arrayRules = [];

  const dataDispositivos = (formData) => {
    arrayNumberingExtensionsHook?.forEach((row) => {
      if (row.User?.email === formData?.email) {
        //  formData?.tabs[0].steps[0].input[3].defaultValues;
        const newRow = {
          // id: formData.tabs[0].steps[0].input[3].defaultValues,
          identification: row.identification,
          // extension: row.extension,
          // userId: row?.User.fullName,
          typeExtension: row.typeExtension,
          password: row.AstSipPeer?.pbxpassword || row.password,
        };
        row.id ? arrayRules.push(newRow) : null;
      }
    });
    return arrayRules;
  };

  const handleOnClickOut = (event) => {
    event.preventDefault();
    dispatch(isModalOpenActionCreator(true));
    dispatch(isModalOpenActionCreator(false));
    dispatch(clearExtensionFormAction());
    dispatch(selectedDocActionCreator({}));
    setIsDropdownActive(false);
  };

  const handleOnClickIn = (event) => {
    event.stopPropagation();
  };

  // const { getState } = useStore();
  // const { isMenuOpen } = getState()['system'];

  const arrayDispositivos = arrScreen[0] === 'Usuario' ? dataDispositivos(reducerForm) : dataRules(data);

  const template = [];

  const option_Rules = arrayDispositivos.map(({ identification, typeExtension }) => ({
    identification,
    typeExtension,
  }));

  //  const dataExport = Array.isArray(usersFormReducer)
  //    ? users.map(({ Company, Soundprompt, name }) => {
  //        return {
  //          Nombre: name,
  //          Locución: Soundprompt?.description,
  //          Sede: Company?.name,
  //        };
  //      })
  //    : [];

  const COLUMNS_RULES = [
    {
      Header: ' ',
      accessor: 'isDefault',
    },
    {
      Header: 'Calendario',
      accessor: 'Calendario',
    },
    {
      Header: 'Horario',
      accessor: 'Horario',
    },
    {
      Header: 'Locución',
      accessor: 'Locucion',
    },
    // {
    //   Header: 'Destino',
    //   accessor: 'TipoDestino',
    // },
    {
      Header: 'Destino',
      accessor: 'Destino',
    },
  ];

  const handleEditRule = (item) => {
    // setNewCrudType('editRule');
    dispatch(selectedDocActionCreator(item.original));

    dispatch(isModalOpenActionCreator(true));
  };

  const handleOpenFormCreate = () => {
    dispatch(isModalOpenActionCreator(true));
    //  setNewCrudType('createRule');
  };

  return (
    <PageScreenWrapper id="modalRoot" isMenuOpen={isMenuOpen} isModalOpen={isModalOpen}>
      {/* <FormTitle formTitle={['Config']} type={crudType} /> */}

      <div className="pageScreen">
        <div className="pageScreen__form">
          {data.tabs ? (
            <div className="pageScreen__tabs">
              {data.tabs.map((tab) => (
                <div className={`pageScreen__tabs__tab ${pageSelected === tab.id ? 'active' : 'inactive'}`} key={tab.id} onClick={() => handlePageSelect(tab.id)}>
                  {getIcon(tab, 'pageScreen')}
                  <p className={`pageScreen__tabs__tab__item`}>{tab.text}</p>
                </div>
              ))}
            </div>
          ) : null}

          <FormScreen
            arrScreen={arrScreen}
            data={data.tabs ? data.tabs[pageSelected - 1] : data}
            onSubmit={onSubmit}
            form={form}
            dataTabs={data.tabs || []}
            crudType={crudType}
            reducerForm={reducerForm}
            maxwidth={maxwidth}
            title={false}
            dataModalButtonToTable={dataModalButtonToTable}
            isEditMode={isEditMode}
            setCheckboxesActives={setCheckboxesActives}
            checkboxesActives={checkboxesActives}
          />
        </div>

        {arrScreen[0] === 'Usuario' || arrScreen[0] === 'Numeración externa' ? (
          <div className="user__contain__table">
            {isModalOpen ? (
              <div className="modal__form__container" onClick={handleOnClickOut}>
                {/* onClick={handleOnClickOut}> */}
                <div className="modal__form__body" onClick={handleOnClickIn}>
                  <ExtensionsFormScreen crudType={extensionCrudType} />
                </div>
              </div>
            ) : (
              <>
                <NavButtons
                  optionButtons={{
                    add: {
                      position: 'left',
                      function: handleClickCreate,
                    },
                    search: {
                      position: 'right',
                      data: arrayDispositivos,
                      columns: COLUMNS,
                    },

                    file: {
                      position: 'right',
                      data: dataExport,
                      template,
                    },
                  }}
                  setSearch={setSearch}
                  onClickCreate={handleClickCreate}
                  onClickAssign={handleClickAssign}
                  isDropdownActive={isDropdownActive}
                  checkboxesActives={checkboxesActives}
                  setCheckboxesActives={setCheckboxesActives}
                  isNavTable={false}
                  handleShowEditRow={handleClickCreate}
                  DATA={arrayDispositivos}
                  handleOpenFormCreate={crudType === 'assignUserDispo' ? handleClickAssign : handleClickCreate}
                  // data?.tabs ? data.tabs : data}
                />
                <TableInfo
                  format={'list'}
                  // handleEditRule={handleEditRule}
                  COLUMNS={COLUMNS}
                  // isNavTable={true}
                  DATA={search.length === 0 ? arrayDispositivos : search}
                  askedBy={'UserForm'}
                  // deleteFunction={handleDelete}
                  updateFunction={handleOpenFormEdit}
                  setShowPassword={setShowPassword}
                  ref={tableInstance}
                  onClick={handleOpenFormEdit}
                  setIsBottom={setIsBottom}
                  checkboxesActives={checkboxesActives}
                  setCheckboxesActives={setCheckboxesActives}
                  isDropdownActive={isDropdownActive}
                  handleOpenFormEdit={handleOpenFormEdit}
                  handleClickCreate={handleClickCreate}
                  handleClickAssign={handleClickAssign}
                  setDataCard={() => {
                    false;
                  }}
                />
              </>
            )}
          </div>
        ) : null}

        {arrScreen[0] === 'NumExterna' ? (
          <div className="dispo__contain__table">
            {isModalOpen ? (
              <div className="modal__form__container" onClick={handleOnClickOut}>
                {/* onClick={handleOnClickOut}>  */}
                <div className="modal__form__body" onClick={handleOnClickIn}>
                  <ListNumberModalFormScreen crudType={'editRule'} />;
                </div>
              </div>
            ) : (
              <>
                <NavButtons
                  // setIsEditMode={setIsEditMode}
                  isEditMode={isEditMode}
                  DATA={[data]}
                  onClickCreate={handleOpenFormCreate}
                  // onClickAssign={handleClickAssign}
                  setSearch={setSearch}
                  handleOpenFormCreate={handleOpenFormCreate}
                  optionButtons={{
                    add: {
                      position: 'left',
                      function: handleOpenFormCreate,
                    },
                    search: {
                      position: 'right',
                      data: option_Rules,
                      columns: COLUMNS,
                    },

                    file: {
                      position: 'right',
                      // data: dataExport,
                      template,
                    },
                  }}
                />
                <TableInfo
                  format={'list'}
                  handleEditRule={handleEditRule}
                  COLUMNS={COLUMNS_RULES}
                  isNavTable={true}
                  DATA={search.length === 0 ? arrayRules : search}
                  // deleteFunction={handleDelete}
                  ref={tableInstance}
                  setIsBottom={setIsBottom}
                  askedBy={'ListNumberForm'}
                  isOptions={false}
                  checkboxesActives={checkboxesActives}
                  setCheckboxesActives={setCheckboxesActives}
                  onClickCreate={handleOpenFormCreate}
                  handleOpenFormEdit={handleOpenFormEdit}
                  onClick={handleEditRule}
                  setDataCard={() => {
                    false;
                  }}
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    </PageScreenWrapper>
  );
};

PageScreen.propTypes = {
  data: object,
  dataExport: object,
  render: bool,
  setRender: func,
  onSubmit: func,
  form: func,
  crudType: string,
  maxwidth: string,
  arrScreen: array,
  reducerForm: object,
  dataModalButtonToTable: object,
  isEditMode: bool,
  row: string,
  checkboxesActives: func,
  setCheckboxesActives: func,
  handleOpenFormCreate: func,
  isModalOpen: bool,
  setIsModalOpen: func,
  isMenuOpen: bool,
};
