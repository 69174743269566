import React from 'react';
import { TitleInputPairWrapper } from './TitleInputPair.style';
import { Input } from '../Input/Input';
import { array, bool, func, object } from 'prop-types';

export const TitleInputPair = ({
  item,
  form,
  inputValue,
  setErrorInput,
  setInputValue,
  setValidationNormalInput,
  validationNormalInput,
  arrScreen,
  inputValueUnique,
  setInputValueUnique,
  errorInput,
}) => {
  return (
    <TitleInputPairWrapper>
      <div className="titleInputPair">
        <div className="titleInputPair__title">{item.label}</div>
        <div className="titleInputPair__inputs">
          {item.inputs.map((input, index) => {
            return (
              <div className='"titleInputPair__input' key={index}>
                <span className="textRequired">{input.required.text}</span>
                <Input
                  item={input}
                  form={form}
                  inputValue={inputValue}
                  setErrorInput={setErrorInput}
                  setInputValue={setInputValue}
                  setValidationNormalInput={setValidationNormalInput}
                  validationNormalInput={validationNormalInput}
                  arrScreen={arrScreen}
                  inputValueUnique={inputValueUnique}
                  setInputValueUnique={setInputValueUnique}
                  type="number"
                />
                {errorInput && validationNormalInput.includes(input.ref.element) ? <span className="error"> {input.required.errorMessage}</span> : null}
                {inputValueUnique.some((obj) => obj.element === input.ref.element) ? <span className="error"> {input.required.errorMessageUnique}</span> : null}
              </div>
            );
          })}
        </div>
      </div>
    </TitleInputPairWrapper>
  );
};

TitleInputPair.propTypes = {
  item: object,
  form: func,
  inputValue: object,
  setErrorInput: func,
  setInputValue: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  arrScreen: array,
  inputValueUnique: array,
  setInputValueUnique: func,
  errorInput: bool,
};
