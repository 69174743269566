import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterQueue, dataScreenQueue, optionsDestination } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { QueueScreenWrapper } from './QueueScreen.style';
// import { NavTable } from '../../../components/NavTable/NavTable';
// import useNumbering from '../../../hooks/useNumbering';
import useEM from '../../../hooks/useEM';
import useNumbering from '../../../hooks/useNumbering';
// import { IconEye } from '@tabler/icons-react';
import { string } from 'prop-types';
import { getArrayFormatted } from '../../../utils/AppUtils';
import useSettings from '../../../hooks/useSettings';
// import useNumbering from '../../../hooks/useNumbering';
// import { getArrayFormatted } from '../../../utils/AppUtils';

export const QueueScreen = () => {
  const { queues, applicationsForm, getMailboxes, mailboxes, getQueues, getConferences, conferences, deleteQueue, getApplicationsLocutions, uploadFileQueue, loading } = useApplications();
  const { arrayNumberingExtensionsHook, getNumberingList, getExtensions } = useNumbering();
  const { extensions } = useEM();
  const { users, getUsers } = useSettings();

  const [checkboxesActives, setCheckboxesActives] = useState([]);
  const { typeSwal } = useSwal();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const { notFound } = dataScreenQueue;

  let navigate = useNavigate();

  useEffect(() => {
    applicationsForm(
      { action: 'clearForm' },
      {
        form: 'queue',
      }
    );
  }, []);

  useEffect(() => {
    getConferences();
    getMailboxes();
    getApplicationsLocutions();
    getExtensions();
    getNumberingList();
    getQueues();
    getUsers();
  }, []);

  const newArray = queues[0];
  const arrayExtensions = [];

  newArray?.extensions && newArray?.extensions.length > 0 && arrayNumberingExtensionsHook
    ? newArray?.extensions.forEach((ext) => {
        // const newData = queueForm?.extensions !== '' ? queueForm : location.state;
        // newData?.extensions.forEach((ext) => {
        let found = [];
        found = arrayNumberingExtensionsHook?.find((e) => e.id.toString() === ext.toString());
        // item2.options?.filter((item3) => {
        if (found) {
          arrayExtensions.push({ label: found?.destinationValue ? found.destinationValue : found.name, value: found?.id ? found.id : found.value });
        }
        return false;
      })
    : null;

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Extensión',
      accessor: 'Extension.firstname',
    },
    {
      Header: 'Estrategia',
      accessor: 'Strategy.name',
    },
    {
      Header: 'Grabar',
      accessor: 'isRecord',
    },
    {
      Header: 'Destino timeout',
      accessor: 'DestinationType.label',
    },
    // {
    //   Header: 'Agentes',
    //   accessor: '',
    //   disableSortBy: true,
    //   Cell: ({ row }) => <IconEye type="Change" row={row} id="icon__viewAgents" className={'icon__view'} />,
    // },
  ];

  const getDestinationInfo = (destinationId, destinationTypeId) => {
    if (destinationId !== null) {
      const idType = optionsDestination[destinationTypeId - 1];
      let arrayToSearch = [];

      switch (idType?.ref) {
        case 'queue':
          arrayToSearch = queues;
          break;
        case 'conference':
          arrayToSearch = conferences;
          break;
        case 'voicemail':
          arrayToSearch = mailboxes;
          break;
        case 'extension':
          arrayToSearch = arrayNumberingExtensionsHook;
          break;
        default:
          break;
      }
      const newValue = arrayToSearch.length ? arrayToSearch?.find((item) => item?.id === destinationId) : '';
      return newValue !== '' && newValue?.AstVoicemail
        ? { value: newValue?.id, label: newValue?.AstVoicemail?.mailbox }
        : newValue.id
          ? { value: newValue.id, label: newValue.identification }
          : { value: idType.id, label: idType.label };
    }
  };
  const getUserInfo = (extensionId) => {
    const found = users.find((user) => user.id === extensionId);

    return found;
  };

  const DATA = queues.map(
    ({
      name,
      extensions,
      Strategy,
      promptDestinationId,
      promptDestinationTypeId,
      // PromptDestination,
      PromptDestinationType,
      destinationId,
      destinationTypeId,
      // Destination,
      DestinationType,
      alertinfo,
      extensionId,
      time_ring,
      id,
      survey_soundpromptId,
      isCustomWelcome,
      customWelcomeId,
      isCustomPrompt,
      customPromptId,
      isRecord,
      mohId,
      Moh,
      number,
      strategyId,
      isSurvey,
      isAgentLog,
      falloverDestinationId,
      falloverDestinationTypeId,
      // FalloverDestination,
      FalloverDestinationType,
      announceInterval,
      queueMaxWaitTime,
      maxWaitTime,
      timeHangup,
      time_retry,
      isDisplayNameEdited,
      displayNameEdited,
      isCallerAnnouce,
      annouceWaitTime,
      leaveWhenEmpty,
      dialEmpty,
      isHoldLog,
    }) => ({
      id,
      name,
      extensionId,
      Extension: getUserInfo(extensionId),
      isRecord,
      number,
      mohId,
      Moh,
      isAgentLog,
      queueMaxWaitTime,
      isDisplayNameEdited,
      displayNameEdited,
      leaveWhenEmpty,
      dialEmpty,
      isHoldLog,
      // isWelcomePrompt: isWelcomePrompt === 1 ? 'Sí' : 'No',
      isCustomPrompt,
      customPromptId,
      isCustomWelcome,
      isCallerAnnouce,
      // : isCustomWelcome === 1 ? 'Sí' : 'No',
      customWelcomeId,
      strategyId,
      Strategy,
      time_ring,
      survey_soundpromptId,

      promptDestinationId,
      promptDestinationTypeId,
      PromptDestinationData: getDestinationInfo(promptDestinationId, promptDestinationTypeId),
      PromptDestinationType: { value: PromptDestinationType?.id, label: PromptDestinationType?.name },

      destinationId,
      destinationTypeId,
      // Destination,
      Destination: getDestinationInfo(destinationId, destinationTypeId),
      DestinationType: { value: DestinationType?.id, label: DestinationType?.name },

      alertinfo,
      extensions: getArrayFormatted(extensions, arrayNumberingExtensionsHook, 'ini'),
      arrayExtensions,
      isSurvey,

      falloverDestinationId,
      falloverDestinationTypeId,
      FalloverDestinationData: getDestinationInfo(falloverDestinationId, falloverDestinationTypeId),
      FalloverDestinationType: { value: FalloverDestinationType?.id, label: FalloverDestinationType?.name },

      announceInterval,
      annouceWaitTime,
      maxWaitTime,
      timeHangup,
      time_retry,
    })
  );

  const dataExport = queues.map(({ name, extensionId }) => ({
    Nombre: name,
    Número: extensionId,
  }));

  const [objectSongWaiting, setObjectSongWaiting] = useState({
    arrayExtensions: [],
    title: '',
  });

  const handleClick = (item) => {
    const newExtension = extensions.filter((object) => object.Moh?.id === item.original?.extensionId);
    setObjectSongWaiting({
      arrayExtensions: newExtension || item.original,
      title: item.original.name,
    });
    // setEnable(item.id);
  };

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.applications}/${routesApplications.createQueue}`, {});
  };

  const handleOpenFormEdit = (item) => {
    handleClick(item);
    item.original.arrayExtensions = objectSongWaiting.arrayExtensions;
    navigate(`/${routesWide.applications}/${routesApplications.updateQueue}`, {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteQueue(item.original);
          } else {
            response = await deleteQueue(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getQueues();
            typeSwal('delete');
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const tableInstance = useRef(null);

  const template = [];

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileQueue(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        getQueues();
        typeSwal('upload');
      }
    } catch (err) {
      return err;
    } finally {
      // window.location.reload()
    }
  };

  return (
    <QueueScreenWrapper isFilter={isFilter.open}>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          isNavTable={true}
          DATA={DATA}
          handleOpenFormCreate={handleOpenFormCreate}
          checkboxesActives={checkboxesActives}
          setCheckboxesActives={setCheckboxesActives}
        />
        {/* //{!queues[0]?.status ? ( */}
        <div className={'contain__menuFilter'}>
          <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterQueue} setSearch={setSearch} />
        </div>
        {/* <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteQueue} /> */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : queues[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            format={'list'}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
            isNavTable={true}
          />
        </div>
      )}
      <ReactTooltip
        className="tooltip"
        anchorSelect="#icon__viewAgents"
        place="bottom"
        content={arrayExtensions.map((ext) => {
          return (
            <span key={ext.value}>
              <br />
              {ext.value} - {ext.label}
            </span>
          );
        })}
        delayShow={1000}
      />
    </QueueScreenWrapper>
  );
};

QueueScreen.propTypes = { row: string };
