import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import DayWeek from './DayWeek';
import usePreferences from '../../hooks/usePreferences';

import { scheduleDaysForm } from '../../data/dataPreferences';

import { ScheduleFormWrapper } from './ScheduleForm.style';
import { useEffect } from 'react';
import { string } from 'prop-types';

const ScheduleForm = ({ form, crudType }) => {
  const [schedule, setSchedule] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });

  const { refactorSchedule } = usePreferences();

  const location = useLocation();

  const handleInputChange = (dayWeek, hours) => {
    setSchedule((prevState) => ({
      ...prevState,
      [dayWeek]: hours,
    }));
  };

  useEffect(() => {
    if (crudType === 'edit') {
      const scheduleRefactor = refactorSchedule(location.state?.schedule);
      setSchedule(scheduleRefactor);
    }
  }, []);

  useEffect(() => {
    form({ action: 'input', value: schedule }, { form: 'schedule', element: 'hour' });
  }, [schedule]);

  return (
    <ScheduleFormWrapper>
      {scheduleDaysForm.map((day) => (
        <DayWeek key={day.value} dayWeek={day.label} dayValue={day.value} editHours={handleInputChange} crudType={crudType} />
      ))}
    </ScheduleFormWrapper>
  );
};

ScheduleForm.propTypes = { form: string, crudType: string };

export default ScheduleForm;
