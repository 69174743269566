import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { ChangePassword } from '../../../components/ChangePassword/ChangePassword';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataScreenUsers } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import useUsers from '../../../hooks/useUsers';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { array, func, string } from 'prop-types';
//import '../../../styles/Screens/SettingsScreen/UserScreen.css';
// import { UsersScreenWrapper } from './UsersScreen.style';
import { useDispatch, useStore } from 'react-redux';
import { getSettingsThunk } from '../../../redux/thunks/settingsThunks';
import useNumbering from '../../../hooks/useNumbering';
import { getDispo } from '../../../utils/AppUtils';
import { IconCloud, IconPhone, IconRainbow, IconUsers } from '@tabler/icons-react';
import { useDepartments } from '../../../hooks/useDepartments';
import { UsersScreenWrapper } from './UsersScreen.style';
import { IconPhoneOff } from '@tabler/icons-react';
// import { UsersScreenWrapper } from './UsersScreen.style';
// import { MenuTop } from '../../../components/MenuTop/MenuTop';
// import { useDepartments } from '../../../hooks/useDepartments';

const UsersScreen = () => {
  const { getUsers, users, getSettingsData } = useSettings();
  const { deleteUser, userForm } = useUsers();
  const { getExtensions, arrayNumberingExtensionsHook, listNumberingForm, getMarcas, getModelos, getPlantillas } = useNumbering();
  const { typeSwal } = useSwal();
  const [checkboxesActives, setCheckboxesActives] = useState([]);
  const dispatch = useDispatch();
  const { departmentsHook } = useDepartments();
  const [loading, setLoading] = useState(true);

  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const { notFound } = dataScreenUsers;

  const navigate = useNavigate();
  useEffect(() => {
    userForm(
      { action: 'clearForm' },
      {
        form: 'user',
      }
    );
    listNumberingForm(
      { action: 'clearForm' },
      {
        form: 'extension',
      }
    );
  }, []);

  useEffect(() => {
    getUsers();
    setSearch([]);
    getExtensions();
    getMarcas();
    getModelos();
    getPlantillas();

    // getDepartments();
    dispatch(getSettingsThunk(setLoading));
    getSettingsData();
  }, []);

  const COLUMNS = [
    {
      Header: '',
      accessor: 'fullName',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span className="user__logo">{row.original.fullName}</span>;
      },
    },
    {
      Header: 'Nombre',
      accessor: 'Usuario',
    },
    // {
    //   Header: 'Apellido',
    //   accessor: 'lastname',
    // },
    // {
    //   Header: 'Password',
    //   accessor: '',
    //   // disableSortBy: true,
    //   Cell: ({ row }) => <ChangePassword type="Change" row={row} />,
    // },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Extensión',
      accessor: 'SIPExtensionId',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span className="extensions__format">{row.original.SIPExtensionId}</span>;
      },
    },

    {
      Header: 'Dispositivos',
      accessor: 'dispositivos',
      Cell: ({ row, index }) => {
        //   if (arrayNumberingExtensionsHook?.length > 0) {
        //     const itemList = getDispo(arrayNumberingExtensionsHook, row.original.id);
        const newCompList = [];
        row.cells[5].value?.includes('virtual') ? newCompList.push(<IconCloud className="icon-usersView" key={'virtual_' + index} />) : null;
        // key={row.cells[5].column.id}
        row.cells[5].value?.includes('SIP') ? newCompList.push(<IconPhoneOff color="white" className="icon-usersView" key={'SIP_' + index} />) : null;
        row.cells[5].value?.includes('rainbow') ? newCompList.push(<IconRainbow className="icon-usersView" key={'rainbow_' + index} />) : null;
        row.cells[5].value?.includes('teams') ? newCompList.push(<IconUsers className="icon-usersView" key={'teams_' + index} />) : null;
        row.cells[5].value?.includes('terminal') ? newCompList.push(<IconPhone className="icon-usersView" key={'terminal_' + index} />) : null;

        return newCompList;
      },
      disableSortBy: true,
    },
    {
      Header: 'Rol',
      accessor: 'role',
    },
    {
      Header: 'Departamento',
      accessor: 'Department',
    },
    {
      Header: 'Sede',
      accessor: 'Company',
    },

    // {
    //   Header: 'Nivel Acceso',
    //   accessor: 'AccessLevel',
    // },
  ];

  const getDepartment = (valueToFind, type) => {
    const newArrayData = [];
    let newData = '';

    if (type !== 'name') {
      valueToFind?.forEach((itemDept) => {
        const newDept = departmentsHook.find((e) => e.id === itemDept);

        newDept ? newArrayData.push({ value: newDept.id, label: newDept.name }) : null;
      });
      return newArrayData;
    } else {
      const newDept = departmentsHook.find((e) => e.id === valueToFind);

      newData = newDept ? newDept.name : '';
      return newData;
    }
  };

  const DATA = users.map(
    ({
      firstname,
      lastname,
      secondname,
      companyId,
      Company,
      departmentId,
      Department,
      email,
      Extension,
      id,
      password,
      Role,
      accesslevelId,
      AccessLevel,
      isSIPExtension,
      SIPExtensionId,
      isVoicemail,
      isvoicemailEmail,
      voicemailPIN,
      isTeams,
      isRainbow,
      ringTimeout,
      category,
      isCallForward,
      callForward,
      isNotAnswer,
      notAnswer,
      AstSipPeer,
      captureId,
      isWebPhone,
    }) => ({
      fullName: firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase(),
      Usuario: firstname + ' ' + lastname,
      firstname,
      lastname,
      secondname,
      email,
      id,
      password,
      category,
      Extension,
      role: Role.name,
      roleId: Role.id,
      companyId,
      Company: Company?.name,
      departmentId: Department ? { value: Department?.id, label: Department?.name } : departmentId,
      Department: getDepartment(departmentId, 'name'),
      AccessLevel: AccessLevel?.name,

      dispositivos: arrayNumberingExtensionsHook?.length > 0 ? getDispo(arrayNumberingExtensionsHook, id) : [],
      accesslevelId,
      isSIPExtension,
      SIPExtensionId,
      isVoicemail,
      isvoicemailEmail,
      AstSipPeer,
      voicemailPIN,
      ringTimeout,
      isTeams,
      isRainbow,
      isWebPhone,
      notAnswer,
      callForward,
      isNotAnswer,
      isCallForward,
      captureId: getDepartment(captureId, 'capture'),
    })
  );

  const dataExport = users.map(({ firstname, lastname, secondname, email, Role, type }) => ({
    Nombnre: firstname,
    Apellido: lastname,
    'Segundo apellido': secondname,
    Email: email,
    Role: Role.name,
    type,
  }));

  const handleOpenFormCreate = () => {
    navigate('/users/usersList/create', {});
  };

  const handleOpenFormEdit = (item) => {
    navigate('/users/usersList/update', {
      state: item.original ? item.original : item,
    });
  };

  const handleOpenFormAssign = (item) => {
    navigate('/users/usersList/update', {
      state: item.original ? item.original : item,
    });
  };

  const handleUserDelete = (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteUser(item.original);
          } else {
            response = await deleteUser(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
          }
        } catch (err) {
          if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
        }
      }
    });
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Nombre: '',
      Apellido: '',
      'Rol *(id)': '',
      Password: '',
      Email: '',
      type: '',
    },
  ];

  const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];

  return (
    <UsersScreenWrapper isMenuOpen={isMenuOpen}>
      <div className="users__screen">
        <div className={'contain__buttons'}>
          <NavButtons
            optionButtons={{
              add: {
                position: 'left',
                function: handleOpenFormCreate,
              },
              search: {
                position: 'right',
                data: DATA,
                columns: COLUMNS,
              },
              // filter: {
              //   position: 'right',
              // },
              file: {
                position: 'right',
                data: dataExport,
                template,
              },
            }}
            setSearch={setSearch}
            setIsFilter={setIsFilter}
            isFilter={isFilter}
            handleOpenFormCreate={handleOpenFormCreate}
            onClickCreate={handleOpenFormCreate}
            onClickAssign={handleOpenFormAssign}
            totalDocs={users.length}
            isNavTable={true}
            DATA={DATA}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>

        {!loading ? (
          <LoaderSpinner />
        ) : users[0] && users[0].prototype?.hasOwnProperty.call('status') && users[0].status ? (
          <div className="contain__table">
            <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
          </div>
        ) : search[0]?.notFound ? (
          <div className="contain__notFound">
            <h4>{search[0].notFound}</h4>
          </div>
        ) : (
          <div className="contain__table">
            <TableInfo
              format={'list'}
              COLUMNS={COLUMNS}
              DATA={search.length === 0 ? DATA : search}
              updateFunction={handleOpenFormEdit}
              deleteFunction={handleUserDelete}
              ref={tableInstance}
              onClick={handleOpenFormEdit}
              onClickCreate={handleOpenFormCreate}
              onClickAssign={handleOpenFormAssign}
              setIsBottom={setIsBottom}
              isNavTable={true}
              checkboxesActives={checkboxesActives}
              setCheckboxesActives={setCheckboxesActives}
            />
          </div>
        )}
      </div>
    </UsersScreenWrapper>
  );
};

{
  /* <ReactTooltip className="tooltip" anchorSelect={'#dispositivos'} place="bottom" delayShow={500} /> */
}
UsersScreen.propTypes = { row: string, checkBoxesActives: array, setCheckBoxesActives: func };

export default UsersScreen;
