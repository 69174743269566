import styled from 'styled-components';
import { colors } from '../../theme/colors.js';

import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const ButtonWrapper = styled.button`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  //transition: all 0.3s;
  width: 100%;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.hoverBackgroundColor};
    //transition: all 0.3s;
    p {
      color: ${(props) => props.hoverColorText};
      //transition: all 0.3s;
    }
    .button__icon {
      color: ${(props) => props.hoverIcon};
      // background: ${(props) => props.hoverIcon};
    }
  }

  p {
    margin: 0;
    color: ${(props) => props.colorText};
    font-size: ${newFontSize.button};
    font-family: ${fontFamily.font};
    font-weight: ${weight.medium};
    //transition: all 0.3s;
  }

  .button__icon {
    color: ${colors.white};
    height: 40px;
    // width: 40px;
    width: ${(p) => (!p.isMenuOpen ? '65px' : '100px')};
    display: flex;
    justify-content: flex-start;

    &:hover {
      color: ${colors.blue};
      background-color: ${colors.white};
      border-radius: 10px;
    }
  }
`;
