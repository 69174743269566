import styled, { keyframes } from 'styled-components';
import { colors } from '../../theme/colors';

const fadeInOut = keyframes`
    0% {
        opacity: 0;
        }
        25% {
          opacity: 1;
          }
          75% {
            opacity: 1;
            }
          100% {
            opacity: 0;
            }
`;

export const HideColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .hideColumn__contain {
    width: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 10px;
    padding: 5px;
    ${'' /* border: 1px solid ${colors.greyLine}; */}
    margin-left: 7px;
    position: relative;

    ${
      '' /* &:hover {
      background-color: ${colors.secondaryColor};
      border: 1px solid ${colors.primaryColor};
    } */
    }

    .hideColumn__copy {
      height: 30px;
      width: 30px;
      padding: 3px;
      color: ${colors.grey};

      ${
        '' /* &:hover {
        border: 1px solid #334155;
        border-radius: 10px;
      } */
      }
    }
  }

  ${
    '' /* .hideColumn__tooltip {
    height: 25px;
    width: 25px;

    &:hover {
      color: ${colors.white};
      background-color: ${colors.blue};
      border-radius: 10px;
    }
  } */
  }
  .tooltip {
    border-radius: 8px;
    font-size: 12px;
    z-index: 9999;
    animation: ${fadeInOut} 3s linear none;
    background: ${colors.primaryColor};
    width: max-content;
    padding: 5px 10px;
  }
`;
