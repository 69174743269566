import React from 'react';

import { StaticNavigation } from './navigation/StaticNavigation/StaticNavigation.jsx';
import { AppContext } from './components/AppContext/AppContext';
import { AppScreenWrapper } from './App.style.jsx';
import { useStore } from 'react-redux';

// import './App.css';

function App() {
  const { getState } = useStore();
  const { isMenuOpen, isModalOpen } = getState()['system'];

  return (
    <AppScreenWrapper className="App" isMenuOpen={isMenuOpen} isModalOpen={isModalOpen}>
      <AppContext>
        <StaticNavigation />
      </AppContext>
    </AppScreenWrapper>
  );
}

export default App;
