export const actionsTypesEM = {
  getEM: 'GET_EM',
  // CRUD DIffusion List
  getEMListExtension: 'GET_EM_LIST_EXTENSION',
  createEMListExtension: 'CREATE_EM_LIST_EXTENSION',
  updateEMListExtension: 'UPDATE_EM_LIST_EXTENSION',
  deleteEMListExtension: 'DELETE_EM_LIST_EXTENSION',

  // CRUD Alerts
  getEMAlarms: 'GET_EM_ALARMS',
  createEMAlerts: 'CREATE_EM_ALERTS',
  updateEMAlerts: 'UPDATE_EM_ALERTS',
  deleteEMAlerts: 'DELETE_EM_ALERTS',

  // Form Diffusion List
  changeEMListFormId: 'CHANGE_EM_LIST_FORM_ID',
  changeEMListFormName: 'CHANGE_EM_LIST_FORM_NAME',
  addEMListFormExtension: 'ADD_EM_LIST_FORM_EXTENSION',
  removeEMListFormExtension: 'REMOVE_EM_LIST_FORM_EXTENSION',
  clearEMListFormExtension: 'CLEAR_EM_LIST_FORM_EXTENSION',
  clearMultiselectEMListFormExtension: 'CLEAR_MULTISELECT_EM_LIST_FORM_EXTENSION',

  // Form Alerts
  changeEMAlertsFormId: 'CHANGE_EM_ALERTS_ID',
  changeEMAlertsName: 'CHANGE_EM_ALERTS_NAME',
  changeEMAlertsCode: 'CHANGE_EM_ALERTS_CODE',
  changeEMAlertsConfirmationNumber: 'CHANGE_EM_ALERTS_CONFIMATION_NUMBER',
  addEMAlertsListDiffusion: 'ADD_EM_ALERTS_LIST_DIFFUSION',
  addSecondaryEMAlertsListDiffusion: 'ADD_EM_ALERTS_SECONDARY_LIST_DIFFUSION',
  removeEMAlertsListDiffusion: 'REMOVE_EM_ALERTS_LIST_DIFFUSION',
  removeSecondatEMAlertsListDiffusion: 'REMOVE_EM_ALERTS_SECONDARY_LIST_DIFFUSION',
  addEMAlertsLocutions: 'ADD_EM_ALERTS_LOCUTIONS',
  removeEMAlertsLocutions: 'REMOVE_EM_ALERTS_LOCUTIONS',
  changeEMAlertsCallAndRecord: 'CHANGE_EM_ALERTS_CALL_AND_RECORD',
  clearEMAlertsForm: 'CLEAR_EM_ALERTS_FORM',
  changeEMAlertsExternal: 'CHANGE_EM_ALERTS_EXTERNAL',
  clearEMAlertsListDiffusion: 'CLEAR_EM_ALERTS_LIST_DIFFUSION',
  clearEMAlertsSecondaryListDiffusion: 'CLEAR_EM_ALERTS_SECONDARY_LIST_DIFFUSION',
  clearEMAlertsLocutions: 'CLEAR_EM_ALERTS_LOCUTIONS',

  //types History
  getHistory: 'GET_HISTORY',
  loadFirstItemsHistory: 'LOAD_FIRST_ITEMS_HISTORY',
  loadNextItemsHistory: 'LOAD_NEXT_ITEMS_HISTORY',
};
