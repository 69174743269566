import styled from 'styled-components';
// import { colors } from './../../../theme/colors.js';

export const HistoryWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;

  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    padding: 20px 36px 0;
    //transition: all 1s ease-out;
  }

  .contain__notFound {
    height: calc(100% - 55px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contain__table-statistics {
    position: absolute;
    top: 295px;

    height: 180px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding-left: 30px;
  }

  .contain__statistics {
    position: relative;
    top: 40px;
    // overflow-x: hidden;
    // overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex: 2;

    div::-webkit-scrollbar {
      display: none;
    }
  }

  .stadistics_charts {
    // height: calc(100% - 20px);
    display: flex;
    ${'' /* background-color: ${colors.greyLine};s */}
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    gap: 10px;
    ${'' /* padding-top: 60px; */}
    padding-right: 36px;
    width: 50%;
    flex: 1;
    ${'' /* margin: auto; */}

    .graph {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      ${'' /* padding: 65px 0px 20px 0px; */}
      height: 260px;
      font-size: 16px;
      text-transform: capitalize;
    }

    .border {
      border: 1px solid #eeecec;
      border-radius: 16px;
    }
  }
`;
