import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import { CardDropDrown } from '../../../../components/CardDropDown/CardDropDrown';
import LoaderSpinner from '../../../../components/LoaderSpinner/LoaderSpinner';
// import { NavButtons } from '../../../../components/NavButtons/NavButtons';
import { menusTitleAndSubtitle } from '../../../../data/dataSettings';
import useSettings from '../../../../hooks/useSettings';

import { EditPermissionsWrapper } from './EditPermissions.style';
// import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { string } from 'prop-types';
import { formCreateRole } from '../../../../data/dataPreferences';

export const EditPermissions = ({ crudType }) => {
  const { privileges, getPrivileges, getRoles, postPrivileges, roleFormReducer, getMenusSubmenus } = useSettings();
  // const { edit } = dataScreenSettings;

  // const { getState } = useStore();
  // const navigate = useNavigate();
  // const location = useLocation();
  // const dataRole = location.state;
  // const { id: idRole, name: roleName } = getState().system.selectedDoc;
  const data = formCreateRole;

  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    getRoles();
    getPrivileges();
    getMenusSubmenus();
  }, [getPrivileges, getMenusSubmenus, getRoles]);

  const voidDataRole = { id: '', name: '' };

  const location = useLocation();
  const dataRole = location.state !== null ? location.state : voidDataRole;

  const { id: idRole, name: roleName } = dataRole;

  const submenusToRole = privileges.filter((item) => item.rolesId === idRole);
  const submenusId = submenusToRole.map((item) => item.submenusId);

  useEffect(() => {
    setTimeout(() => {
      if (privileges.length !== 0) {
        // && submenusToRole.length !== 0
        setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [loadingCounter]);

  useEffect(() => {
    // userForm(
    //   { action: 'clearForm' },
    //   {
    //     form: 'user',
    //   }
    // );
    // if (crudType === 'edit') {
    //   userForm(
    //     {
    //       action: 'id',
    //       value: location.state?.id,
    //     },
    //     { form: 'user', element: 'id' }
    //   );
    //   userForm(
    //     {
    //       action: 'select-option',
    //     },
    //     {
    //       form: 'extension',
    //       element: 'companyId',
    //     },
    //     {
    //       label: state.Company,
    //       value: String(state.companyId),
    //     }
    //   );
    // }
  }, [loadingCounter]);
  // const handleCloseSettings = () => {
  //   //setCurrentActiveMenus(privileges);
  //   navigate('/settings/permissions', {});
  // };
  useEffect(() => {
    setTimeout(() => {
      if (crudType === 'edit') {
        data.steps.map((item) => {
          item.input.map((item2) => {
            switch (item2.ref.element) {
              case 'name':
                item2.defaultValues = roleFormReducer.name;
                break;

              default:
                break;
            }
            return item2;
          });
          setLoading(false);
        });
      }
    });
  }),
    [crudType, loadingCounter];

  const toggleSwitch = (data, isOn, setIsOn) => {
    setIsOn(!isOn);
    const permission = {
      submenuId: data.id,
      active: !isOn,
      role: dataRole.id,
    };
    postPrivileges(permission);
  };

  // const handleCancel = () => {
  //   history.back();
  // };

  const handleOnCancel = () => {
    history.back();
  };

  return (
    <EditPermissionsWrapper>
      <div className="edit-permissions">
        {/* <div className="contain__buttons"> */}
        {/* <NavButtons
            optionButtons={{
              return: {
                position: 'left',
                function: handleCloseSettings,
              },
            }}
          /> */}
        {/* </div> */}
        {loading ? (
          <LoaderSpinner />
        ) : (
          <div className="permission">
            <div className="permission__title">
              {/* <img src={dataRole.iconTitle} alt={rolesName} /> */}
              <h3>{roleName}</h3>
            </div>

            <div className="permission__contain-cards">
              {menusTitleAndSubtitle.map((item) => (
                <div className="permission__card" key={item.id}>
                  <CardDropDrown item={item} toggleSwitch={toggleSwitch} submenusId={submenusId} selectedId={selectedId} setSelectedId={setSelectedId} layout={item.id} />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="contain__button-save">
          <button onClick={handleOnCancel} className={'cancel_button'}>
            Cancelar
          </button>
          <button className={crudType === 'edit' ? 'save_button' : selectedId === data.steps?.length ? 'save_button' : 'next_step'}>
            {crudType === 'edit' ? 'Guardar' : selectedId === data.steps?.length ? data.textButton : 'Guardar'}
          </button>
        </div>
      </div>
    </EditPermissionsWrapper>
  );
};

EditPermissions.propTypes = { crudType: string };
