import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const ApiScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  ${
    '' /* position: relative;
  left: 70px;
  top: 70px;
  padding: 10px; */
  }
  width: 100%;

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .contain__table {
    width: 100%;
    box-sizing: border-box;
    height: calc(100% - 55px);
    //transition: all 1s easy-out;
    display: flex;
    justify-content: space-between;
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    width: ${(p) => (p.isFilter ? '300px' : '0px')};
    //transition: width 0.3s ease-in-out;
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }
`;
