import styled from 'styled-components';

import { colors } from '../../theme/colors.js';

export const NumberingsNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 100%;
  margin: auto;
  position: fixed;
  background-color: ${colors.backgroundColor};
  border-radius: 15px;
  left: ${(p) => (p.isMenuOpen ? '229px' : '70px')};

  ${
    '' /* .container__cards {
    width: 80%;
    margin: auto;
  } */
  }

  ${
    '' /* .contain__inputCard-component {
    padding-top: 100px;
  } */
  }
`;
