import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import { ShowHideCard } from '../ShowHideCard/ShowHideCard';
import { NavCardSlider } from './NavCardSlider/NavCardSlider';

import { CardSliderWrapper } from './CardSlider.style';
import useApplications from '../../hooks/useApplications';
import { array, func, object } from 'prop-types';

export const CardSlider = ({ item, form, setInputValue, setErrorInput, setValidationNormalInput, validationNormalInput, allSteps, dataModalButtonToTable, dataForm }) => {
  const { queuesForm } = useApplications();

  const [isShowCard, setIsShowCard] = useState(true);
  const [dataCard, setDataCard] = useState([]);
  const [strategy, setStrategy] = useState(0);
  const [dataCard2, setDataCard2] = useState([]);
  const [dataCard3, setDataCard3] = useState([]);

  const handleIsShowCard = () => {
    setIsShowCard(!isShowCard);
  };

  useEffect(() => {
    if (dataCard.length > 0 && dataCard2.length === 0 && dataCard3.length === 0) {
      const action = {
        action: dataForm.ref.element,
      };
      const ref = {
        form: dataForm.ref.form,
        element: 'addButton',
      };

      form(action, ref, dataCard);
    } else {
      if (dataCard.length > 0 || dataCard2.length > 0 || dataCard3.length > 0) {
        const action = {
          action: dataForm.ref.element,
        };
        const ref = {
          form: dataForm.ref.form,
          element: 'addButton',
        };

        if (strategy === 2) {
          let newArrOrder = dataCard.concat(dataCard2, dataCard3);

          form(action, ref, newArrOrder);
        }

        if (strategy === 3) {
          dataCard.map((item) => (item.groupStrategy = 1));
          dataCard2.map((item) => (item.groupStrategy = 2));
          dataCard3.map((item) => (item.groupStrategy = 3));

          let newArrGroupOrder = dataCard.concat(dataCard2, dataCard3);

          form(action, ref, newArrGroupOrder);
        }
      }
    }
  }, [dataCard]);

  useEffect(() => {
    setStrategy(queuesForm.strategy.value);
    if (queuesForm.strategy.value !== 3) {
      setDataCard(queuesForm.agents);
    } else {
      let newDataCard = [...dataCard];
      let newDataCard2 = [...dataCard2];
      let newDataCard3 = [...dataCard3];
      queuesForm.agents.forEach((agent) => {
        if (agent.order === 1) {
          newDataCard.push(agent);
        }
        if (agent.order === 2) {
          newDataCard2.push(agent);
        }
        if (agent.order === 3) {
          newDataCard3.push(agent);
        }
      });
      setDataCard(newDataCard);
      setDataCard2(newDataCard2);
      setDataCard3(newDataCard3);
    }
  }, [queuesForm.strategy.value]);

  let idAgentsInactive = queuesForm.agentsInactive.map((agent) => agent.id);
  let idAgentsExternal = queuesForm.agentsExternal.map((agent) => agent.id);

  return (
    <CardSliderWrapper isShowCard={isShowCard}>
      <div className="cardSlider">
        <div className="cardSlider__nav">
          <NavCardSlider
            item={item}
            handleIsShowCard={handleIsShowCard}
            isShowCard={isShowCard}
            setInputValue={setInputValue}
            setErrorInput={setErrorInput}
            setValidationNormalInput={setValidationNormalInput}
            validationNormalInput={validationNormalInput}
            allSteps={allSteps}
            form={form}
            dataModalButtonToTable={dataModalButtonToTable}
            setDataCard={setDataCard}
            setStrategy={setStrategy}
          />
        </div>
        {strategy === 1 ? (
          <div className="cardSlider__cards--grid">
            {dataCard.map((item) => (
              <ShowHideCard
                key={item.id}
                isShowCard={isShowCard}
                item={item}
                dataCard={dataCard}
                setDataCard={setDataCard}
                form={form}
                dataForm={dataForm}
                inactive={idAgentsInactive.includes(item.id)}
                external={idAgentsExternal.includes(item.id)}
              />
            ))}
          </div>
        ) : strategy === 2 ? (
          <div className="cardSlider__cards--column-contain-order">
            <div className="cardSlider__cards--column">
              <ReactSortable list={dataCard} setList={setDataCard} group="shared" animation={200} delayOnTouchStart={true} delay={2} ghostClass="sortable-ghost">
                {dataCard.map((item, index) => (
                  <div className="cardSlider__cards--column-number" key={item.id}>
                    <div className="cardSlider__cards--column-number-item">{index + 1}</div>
                    <ShowHideCard
                      key={item.id}
                      isShowCard={isShowCard}
                      item={item}
                      dataCard={dataCard}
                      setDataCard={setDataCard}
                      form={form}
                      dataForm={dataForm}
                      inactive={idAgentsInactive.includes(item.id)}
                      external={idAgentsExternal.includes(item.id)}
                    />
                  </div>
                ))}
              </ReactSortable>
            </div>
            <div className="cardSlider__cards--column">
              <ReactSortable list={dataCard2} setList={setDataCard2} group="shared" animation={200} delayOnTouchStart={true} delay={2} ghostClass="sortable-ghost">
                {dataCard2.map((item, index) => (
                  <div className="cardSlider__cards--column-number" key={item.id}>
                    <div className="cardSlider__cards--column-number-item">{index + dataCard.length + 1}</div>
                    <ShowHideCard
                      key={item.id}
                      isShowCard={isShowCard}
                      item={item}
                      dataCard={dataCard2}
                      setDataCard={setDataCard2}
                      form={form}
                      dataForm={dataForm}
                      inactive={idAgentsInactive.includes(item.id)}
                      external={idAgentsExternal.includes(item.id)}
                    />
                  </div>
                ))}
              </ReactSortable>
            </div>
            <div className="cardSlider__cards--column">
              <ReactSortable list={dataCard3} setList={setDataCard3} group="shared" animation={200} delayOnTouchStart={true} delay={2} ghostClass="sortable-ghost">
                {dataCard3.map((item, index) => (
                  <div className="cardSlider__cards--column-number" key={item.id}>
                    <div className="cardSlider__cards--column-number-item">{index + dataCard.length + dataCard2.length + 1}</div>
                    <ShowHideCard
                      key={item.id}
                      isShowCard={isShowCard}
                      item={item}
                      dataCard={dataCard3}
                      setDataCard={setDataCard3}
                      form={form}
                      dataForm={dataForm}
                      inactive={idAgentsInactive.includes(item.id)}
                      external={idAgentsExternal.includes(item.id)}
                    />
                  </div>
                ))}
              </ReactSortable>
            </div>
          </div>
        ) : strategy === 3 ? (
          <div className="cardSlider__cards--column-contain">
            <div className="cardSlider__cards--column">
              <div className="cardSlider__cards--column-name">Grupo 1</div>
              <ReactSortable list={dataCard} setList={setDataCard} group="shared" animation={200} delayOnTouchStart={true} delay={2} ghostClass="sortable-ghost">
                {dataCard.map((item) => (
                  <ShowHideCard
                    key={item.id}
                    isShowCard={isShowCard}
                    item={item}
                    dataCard={dataCard}
                    setDataCard={setDataCard}
                    form={form}
                    dataForm={dataForm}
                    inactive={idAgentsInactive.includes(item.id)}
                    external={idAgentsExternal.includes(item.id)}
                  />
                ))}
              </ReactSortable>
            </div>
            <div className="cardSlider__cards--column">
              <div className="cardSlider__cards--column-name">Grupo 2</div>
              <ReactSortable list={dataCard2} setList={setDataCard2} group="shared" animation={200} delayOnTouchStart={true} delay={2} ghostClass="sortable-ghost">
                {dataCard2.map((item) => (
                  <ShowHideCard
                    key={item.id}
                    isShowCard={isShowCard}
                    item={item}
                    dataCard={dataCard2}
                    setDataCard={setDataCard2}
                    form={form}
                    dataForm={dataForm}
                    inactive={idAgentsInactive.includes(item.id)}
                    external={idAgentsExternal.includes(item.id)}
                  />
                ))}
              </ReactSortable>
            </div>
            <div className="cardSlider__cards--column">
              <div className="cardSlider__cards--column-name">Grupo 3</div>
              <ReactSortable list={dataCard3} setList={setDataCard3} group="shared" animation={200} delayOnTouchStart={true} delay={2} ghostClass="sortable-ghost">
                {dataCard3.map((item) => (
                  <ShowHideCard
                    key={item.id}
                    isShowCard={isShowCard}
                    item={item}
                    dataCard={dataCard3}
                    setDataCard={setDataCard3}
                    form={form}
                    dataForm={dataForm}
                    inactive={idAgentsInactive.includes(item.id)}
                    external={idAgentsExternal.includes(item.id)}
                  />
                ))}
              </ReactSortable>
            </div>
          </div>
        ) : null}
      </div>
    </CardSliderWrapper>
  );
};

CardSlider.propTypes = {
  item: object,
  form: func,
  setInputValue: func,
  setErrorInput: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  allSteps: array,
  dataModalButtonToTable: object,
  dataForm: object,
};
