import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

// const getColor = (props) => {
//   if (props.isDragAccept) {
//     return colors.blue;
//   }
//   if (props.isDragReject) {
//     return colors.red;
//   }

//   return colors.greyLine;
// };

export const MyDropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .icon__download {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    border-radius: 10px;
    background-color: ${colors.primaryColorLight};
    color: ${colors.backgroundColor};

    &:hover {
      background-color: ${colors.primaryColor};
      color: ${colors.backgroundColor};
      -webkit-transition: all 0.5s linear;
      -moz-transition: all 0.5s linear;
      -o-transition: all 0.5s linear;
      transition: all 0.5s linear;
      -moz-box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
      -webkit-box-shadow: 2px 2px 4px 0px ${colors.primaryColorDark};
      box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
      -moz-border-radius: 10px 10px 10px 10px;
      -webkit-border-radius: 10px 10px 10px 10px;
    }
  }

  .dropzone__file__download {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: ${colors.primaryColor};
    font-size: ${newFontSize.h4};
    font-weight: ${weight.regular};
    cursor: pointer;

    svg {
      padding: 3px;
      min-width: 40px;
      height: 40px;

      &:hover {
        border: 1px solid ${colors.primaryColorLight};
      }

      &:hover,
      &:focus,
      &:active {
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
        -moz-box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
        -webkit-box-shadow: 2px 2px 4px 0px ${colors.primaryColorDark};
        box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
        -moz-border-radius: 7px 7px 7px 7px;
      }
    }
  }
  .speech__buttons__right {
    display: flex;
    width: 65%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .speech__buttons__player {
    min-width: 70px;
  }

  #language {
    width: 140px;
    height: unset;
    font-size: 14px;
  }
  #voice,
  #lang {
    text-align: left;
    width: 130px;
    height: unset;
    border-radius: 10px;
    font-size: 14px;
    border: none;
  }

  #lang,
  #gender {
    max-width: 140px;
  }

  .dropzone {
    position: relative;
    top: -200px;

    .toggleButton {
      height: 100%;
      width: 440px;
      border: 1px solid ${colors.greyBorder};
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      background-color: ${colors.backgroundColor};
      opacity: 1;

      &__left,
      &__right {
        cursor: pointer;
        height: 50px;
        width: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        gap: 20px;
        font-size: ${newFontSize.h4};
        font-weight: ${weight.regular};
        color: ${colors.primaryColor};
      }

      &__left {
        border-right: 1px solid ${colors.greyBorder};
        background-color: ${(props) => (props.isActive === 1 ? colors.greenGYGAS : colors.greenGYGAS)};
        border-radius: 6px;
        color: ${colors.primaryColor};
      }

      &__right {
        background-color: ${(props) => (props.isActive === 2 ? colors.greenGYGAS : colors.secondaryColorLight)};
        border-radius: 6px;
        color: ${colors.primaryColor};
      }
    }
  }

  .Azure__App {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-start;
    gap: 5px;
    max-height: 134px;
    background-color: ${colors.secondaryColor};
    padding: 10px;
    border-radius: 0 0 10px 10px;

    .speech__buttons {
      padding-top: 10px;
      display: flex;
      ${'' /* flex-direction: column; */}
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 0.9rem;
      width: 90%;

      ${'' /* background-color: ${colors.backgroundColor}; */}
    }

    .speech__buttons__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      column-gap: 1rem;
      width: 100%;

      button {
        padding: 5px;
        width: 120px;
        height: 35px;
        border-radius: 10px;
        background-color: ${colors.primaryColorLight};
        color: ${colors.backgroundColor};

        &:active {
          box-shadow: none;
          transform: translateY(5px);
        }
      }

      .button_create {
        color: ${colors.backgroundColor};
        font-weight: bold;
        padding: 10px;

        &:hover {
          background-color: ${colors.primaryColor};
          color: ${colors.backgroundColor};
        }
      }

      .button_start {
        ${'' /* background-color: ${colors.greenGYGASLight}; */}
        color: ${colors.primaryColor};
        font-weight: bold;
        ${'' /* padding: 3px; */}
        width: max-content;
        height: 35px;

        &:hover {
          color: ${colors.backgroundColor};
        }
      }

      .button_pause,
      .button_stop {
        color: ${colors.primaryColor};
        font-weight: bold;

        &:hover {
          color: ${colors.backgroundColor};
        }
      }
    }
    button {
      padding: 5px;
      width: max-content;
      height: 35px;
      border-radius: 10px;
      background-color: ${colors.primaryColorLight};
      color: ${colors.backgroundColor};
      font-weight: bold;
    }

    .button_start {
      height: 35px;
      width: 35px;

      color: ${colors.primaryColor};
      font-weight: bold;
      border-radius: 10px;
      padding: 3px;

      &:hover {
        background-color: ${colors.primaryColorLight};
        color: ${colors.backgroundColor};
      }
    }
    .button_stop {
      height: 35px;
      width: 35px;
      font-weight: bold;
      padding: 3px;
      color: ${colors.primaryColor};

      &:hover {
        color: ${colors.black};
      }
    }

    .button_pause {
      height: 35px;
      width: 35px;
      font-weight: bold;
      padding: 3px;
      color: ${colors.primaryColor};

      &:hover {
        color: ${colors.black};
      }
    }
  }

  .icon__success {
    color: ${colors.primaryColor};
    stroke: black;
    fill: black;
  }

  .speech__container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${colors.secondaryColor};
    border-radius: 10px 10px 0 0;

    .speech__conf {
      width: 92%;
      display: flex;
      gap: 20px;
      ${'' /* padding: 10px; */}
      padding: 30px;
      margin: auto;
      flex-direction: column;

      textarea {
        height: 70px;
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        max-width: 400px;
      }

      svg {
        color: white;
      }

      .speech_controls {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        gap: 7px;
        border: 1px solid white;
        border-radius: 10px;
        padding: 10px;
      }

      .speech__conf__left,
      .speech__conf__right {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .speech__label__container {
          display: flex;
          min-width: 70px;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .input__speech {
      width: 100%;
      height: 130px;
      margin: auto;
    }

    select {
      height: 35px;
      width: 100%;

      border-radius: 5px;
    }
  }

  .icon__microphone {
    color: ${colors.primaryColor};
    position: relative;
    top: -50px;
    left: 240px;
    width: 40px;
    height: 40px;
    padding: 5px;

    &:hover {
      background-color: ${colors.primaryColor};
      color: white;
      border-radius: 10px;
    }
  }

  .toggleButton {
    border: none;
  }

  .presentation {
    input {
      display: none;
    }
    /* position: relative; */
    top: -100px;
    width: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .icon__upload {
      cursor: pointer;
      position: relative;
      right: 245px;
      ${'' /* top: 115px; */}
      border-radius: 15px;
      color: #334155;
      min-width: 60px;
      height: 60px;
      padding: 5px;
      ${'' /* margin-right: 130px; */}

      ${
        '' /* &:hover {
      
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
        -moz-box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
        -webkit-box-shadow: 2px 2px 4px 0px ${colors.primaryColorDark};
        box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
        -moz-border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
      } */
      }
    }
  }

  .contain__dropzone {
    width: 100%;
    max-width: 450px;
    border-radius: 7px;
    outline: none;
    display: flex;
    text-align: left;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -30px;

    p {
      width: 440px;
      min-height: 230px;
      background-color: ${colors.white};
      border: 2px solid ${colors.primaryColorLight};
      height: 110px;
      padding: 20px;
      ${'' /* padding-top: 20px; */}
      border-radius: 10px;
      text-align: center;
    }

    .dropzone__item {
      width: 100%;
      font-size: ${newFontSize.span};
      margin: 0 0 5px 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      font-weight: ${weight.bold};
      ${'' /* color: ${colors.black}; */}
      height: 34px;
      ${
        '' /* position: relative;
      top: 110px;
      left: 7px; */
      }
    }
  }
`;
export const ContainData = styled.div`
  width: 100%;
  ${'' /* margin-top: 5px; */}
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${'' /* height: 10px; */}
  flex-direction: row;
  ${'' /* gap: 60px; */}

  .dropzone__file__container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    top: -70px;
    padding: 5px;
    padding-right: 20px;
    ${'' /* padding-top: 20px; */}
  }

  .dropzone__file {
    width: 100%;
    max-width: 290px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    ${'' /* gap: 10px; */}
    grid-row-start: 3;
    grid-column-start: 1;
    z-index: 9;
    gap: 10px;

    audio {
      position: relative;
      left: -5px;
      min-width: 250px;
      width: 100%;
      height: 40px;
      ${
        '' /* -webkit-transition: all 0.5s linear;
      -moz-transition: all 0.5s linear;
      -o-transition: all 0.5s linear;
      transition: all 0.5s linear;
      -moz-box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
      -webkit-box-shadow: 2px 2px 4px 0px ${colors.primaryColorDark};
      box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
      -moz-border-radius: 7px 7px 7px 7px;
      -webkit-border-radius: 50px 50px 50px 50px; */
      }

      ${
        '' /* &:hover,
      &:focus,
      &:active {
        -webkit-box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
        box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        transform: scale(1.05);
      } */
      }
    }

    audio::-webkit-media-controls-panel {
      background-color: ${colors.white};
    }

    audio::-webkit-media-controls-play-button {
      background-color: ${colors.greenGYGAS};
      border-radius: 50%;
    }

    audio::-webkit-media-controls-play-button:hover {
      background-color: rgba(177, 212, 224, 0.7);
    }
    .dropzone__item {
      width: max-content;
      font-size: ${newFontSize.span};
      margin: 0 0 5px 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      font-weight: ${weight.bold};
      ${'' /* color: ${colors.black}; */}
      height: 34px;
      ${
        '' /* position: relative;
      top: 110px;
      left: 7px; */
      }
    }

    .dropzone__success,
    .dropzone__current {
      font-size: 12px;
      margin: 0;
      ${'' /* padding-top: 180px; */}
      text-align: center;
      ${'' /* font-weight: ${weight.bold}; */}
      ${'' /* color: ${colors.primaryColor}; */}
      ${'' /* margin-left: 5px; */}
      width: max-content;
      ${'' /* padding-top: 50px; */}
    }

    .dropzone__error {
      font-size: ${newFontSize.span};
      margin: 0;
      text-align: center;
      font-weight: ${weight.bold};
      color: ${colors.red};
    }
  }
`;
