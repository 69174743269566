import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize } from '../../theme/fonts';

export const ShowHideCardWrapper = styled.div`
  height: 100%;
  cursor: grab;
  margin-top: 10px;

  .opacity {
    opacity: 0;
    //transition: all 1 s;
  }

  .showHideCard {
    position: relative;
    width: 206px;
    height: ${(props) => (props.isShowCard ? '150px' : '37px')};
    border: 1px solid ${colors.greyBorder};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: grid;
    grid-template-rows: ${(props) => (props.isShowCard ? '1fr 2fr 1fr' : '1fr')};
    background-color: ${(props) => (props.isCardActive ? colors.white : colors.greyHoverCard)};
    border-radius: 5px;

    transition-delay: ${(p) => (p.isShowCard ? 0.5 : 0.5)}s;

    &:hover {
      //transition: all 0.3s;
      border: 1px solid ${colors.primaryColor};
    }

    &__header,
    &__body {
      border-bottom: ${(props) => (props.isShowCard ? `1px solid ${colors.greyBorder}` : '0')};
      width: 100%;
      height: 100%;
      //transition: all 0.3s;
      transition-delay: ${(p) => (p.isShowCard ? 0.5 : 0.5)}s;
    }

    &__header {
      display: grid;
      position: ${(props) => (props.isShowCard ? 'relative' : 'absolute')};
      //transition: all 0.3s;
      transition-delay: ${(p) => (p.isShowCard ? 0.5 : 0.5)}s;

      &__text {
        margin: 0;
        justify-self: center;
        align-self: center;
        font-size: ${newFontSize.body};
        color: ${(props) => (props.isCardActive ? colors.primaryColor : colors.greyText)};
        //transition: all 0.3s;
      }
      &__icon {
        color: #7a7a7a;
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
        z-index: 9;
      }
    }

    &__body {
      display: grid;
      //transition: all 0.3s;
      transition-delay: ${(p) => (p.isShowCard ? 1 : 0)}s;

      &__toggleButton {
        height: 28px;
        width: 155px;
        justify-self: center;
        align-self: center;
      }

      &__disable {
        opacity: 0;
        //transition: opacity 1s;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__footer {
      width: 100%;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      //transition: all 0.3s;
      transition-delay: ${(p) => (p.isShowCard ? 1 : 0)}s;

      &__text {
        margin: 0;
        font-size: ${newFontSize.body};
        align-self: center;
        justify-self: center;
        color: ${(props) => (props.isCardActive && props.isToggleSwitchActive === false ? colors.primaryColor : colors.greyText)};
        //transition: all 0.3s;
      }

      &__toggleSwitch {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
