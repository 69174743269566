import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { number } from 'prop-types';

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { dataScreenCompany } from '../../../data/dataPreferences';
// import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
// import { CardGird } from '../../../components/CardGrid/CardGird';
import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import { useSwal } from '../../../hooks/useSwal';
import { nameTables } from '../../../data/dataTable';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { CompanyScreenWrapper } from './CompanyScreen.style';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { useRef } from 'react';

export const CompanyScreen = () => {
  const { arrayHeadquarterHook, getHeadquarter, companyLink, deleteCompany, loading } = useHeadquarter();
  const { typeSwal } = useSwal();

  let navigate = useNavigate();

  const { notFound } = dataScreenCompany;

  const [search, setSearch] = useState([]);
  // const [selectedId, setSelectedId] = useState('');
  const [checkboxesActives, setCheckboxesActives] = useState([]);

  const tableInstance = useRef(null);
  const [, setIsBottom] = useState(false);
  // const [enable, setEnable] = useState(0);

  const [objectCompanyLink, setObjectCompanyLink] = useState({
    departments: [],
    title: '',
    id: number,
    ip: number,
    port: number,
    ipBackup: number,
    portBackup: number,
  });

  useEffect(() => {
    getHeadquarter();
    setSearch([]);
  }, [getHeadquarter]);

  useEffect(() => {
    if (objectCompanyLink.title === '' || objectCompanyLink.title === undefined) {
      const newName = companyLink.filter((item) => item.Company?.id === arrayHeadquarterHook[0]?.id);
      setObjectCompanyLink({
        departments: newName,
        title: arrayHeadquarterHook[0]?.name,
        id: arrayHeadquarterHook[0]?.id,
        ip: arrayHeadquarterHook[0]?.ip,
        port: arrayHeadquarterHook[0]?.port,
        ipBackup: arrayHeadquarterHook[0]?.ipBackup,
        portBackup: arrayHeadquarterHook[0]?.portBackup,
      });
      // setEnable(arrayHeadquarterHook[0]?.id);
    }
  }, [companyLink, arrayHeadquarterHook]);

  const option = arrayHeadquarterHook.map(({ name, id, ip, port, ipBackup, portBackup }) => ({
    text: name,
    id,
    name,
    ip,
    port,
    ipBackup,
    portBackup,
  }));

  // const handleClick = (item) => {
  //   let newName = companyLink.filter((item2) => item2.Company?.id === Number(item.original.id));
  //   setObjectCompanyLink({
  //     departments: newName,
  //     title: item.original.text,
  //     id: item.original.id,
  //     ip: item.original.ip,
  //     port: item.original.port,
  //     ipBackup: item.original.ipBackup,
  //     portBackup: item.original.portBackup,
  //   });
  //   // setEnable(item.id);
  // };

  const handleOpenFormCreate = () => {
    navigate('/preferences/company/create', {});
  };

  const handleOpenFormEdit = (item) => {
    let newName = companyLink.filter((item2) => item2.Company?.id === Number(item.original.id));
    const itemCompany = newName.map((item) => {
      return { value: item.Department.id, label: item.Department.name };
    });

    item.original.departments = itemCompany;
    navigate(`/${routesWide.settings}/${routesSettings.updateCompany}`, {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await deleteCompany(item || objectCompanyLink);
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getHeadquarter();
          }
          if (response.status === 203) {
            let belongTables = [];
            response.data.map((item) =>
              Object.keys(nameTables).forEach((key) => {
                if (key.includes(item)) {
                  belongTables.push(nameTables[key]);
                }
              })
            );
            typeSwal('error', belongTables[0]);
            getHeadquarter();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  // const dataToCards = objectCompanyLink.departments.map(({ Department }) => ({
  //   title: Department.name,
  // }));

  menuButtonsDropDownTable.forEach((item) => {
    if (item.id === 1) {
      item.function = handleOpenFormEdit;
    } else if (item.id === 2) {
      item.function = handleDelete;
    }
  });

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    // {
    //   Header: 'Sede',
    //   accessor: 'company',
    // },
  ];

  const template = [
    {
      Nombre: '',
    },
  ];

  const dataExport = arrayHeadquarterHook.map(({ name }) => ({
    Nombre: name,
  }));

  return (
    <CompanyScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: option,
              columns: COLUMNS,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          onClickCreate={handleOpenFormCreate}
          setSearch={setSearch}
          isNavTable={true}
          deleteFunction={handleDelete}
          checkboxesActives={checkboxesActives}
          setCheckboxesActives={setCheckboxesActives}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayHeadquarterHook[0].status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? option : search}
            format={'list'}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            isNavTable={false}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
      )}
    </CompanyScreenWrapper>
  );
};
