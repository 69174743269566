import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const AlertsWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  border-radius: 10px;
  background-color: ${colors.backgroundColor};
  ${'' /* padding-top: 10px; */}

  .contain__cardGrid {
    box-sizing: border-box;
    padding: 20px 36px 0;
    //transition: all 1s easy-out;
  }
`;
