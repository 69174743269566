import React from 'react';
import { func, object } from 'prop-types';

const FallbackRender = ({ error }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  // wrongAction('error');
  console.log(error);

  return (
    <div role="alert" className="error__container">
      <p>
        Something went wrong: <span className="error__title">{error.message}</span>
      </p>
      <p className="error__body">{error.stack}</p>
    </div>
  );
};

export default FallbackRender;

FallbackRender.propTypes = {
  error: object,
  resetErrorBoundary: func,
};
