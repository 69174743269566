import Axios from 'axios';

import {
  getNumberingListAction,
  updateNumberingListAction,
  deleteNumberingListAction,
  getExtensionAction,
  createExtensionAction,
  updateExtensionAction,
  createNumberingListAction,
  getDdiLinkAction,
  getMarcasAction,
  getModelosAction,
  getPlantillasAction,
  createMarcaAction,
  updateMarcaAction,
  createModeloAction,
  createPlantillaAction,
  updatePlantillaAction,
  updateModeloAction,
  deleteMarcaAction,
  deleteModeloAction,
  deletePlantillaAction,
  updateNumberingListModalAction,
  createNumberingListModalAction,
  getNumberingListModalAction,
} from '../actions/numbering/actionCreator';

// const urlApi = import.meta.env.VITE_APP_URL;
const env = import.meta.env.VITE_NODE_ENV || 'development';
const urlBack = env === 'development' ? import.meta.env.VITE_BACK_DEV_URL : import.meta.env.VITE_BACK_URL;
const token = import.meta.env.VITE_APP_TOKEN;

export const getNumberingListThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const arrayNumbering = await Axios.get(`${urlBack}numbering/numberingList`, {
      headers: {
        Authorization: token,
        //apiKey: 'po3oef9aoc7t35fglvpxl',
      },
    });
    const ddiLink = await Axios.get(`${urlBack}numbering/numberingList/link`, {
      headers: {
        Authorization: token,
        //apiKey: 'po3oef9aoc7t35fglvpxl',
      },
    });

    dispatch(getNumberingListAction(arrayNumbering.data));
    dispatch(getDdiLinkAction(ddiLink.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getExtensionThunk = (setLoading) => async (dispatch) => {
  try {
    const arrayNumbering = await Axios.get(`${urlBack}numbering/extensions`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getExtensionAction(arrayNumbering.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getMarcasThunk = (setLoading) => async (dispatch) => {
  try {
    const arrayMarcas = await Axios.get(`${urlBack}numbering/marcasList`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getMarcasAction(arrayMarcas.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getModelosThunk = (setLoading) => async (dispatch) => {
  try {
    const arrayModelos = await Axios.get(`${urlBack}numbering/modelosList`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getModelosAction(arrayModelos.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getPlantillasThunk = (setLoading) => async (dispatch) => {
  try {
    const arrayPlantillas = await Axios.get(`${urlBack}numbering/plantillasList`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getPlantillasAction(arrayPlantillas.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const createExtensionThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/extensions/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createExtensionAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const createMarcaThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/marcas/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createMarcaAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const createModeloThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/modelos/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createModeloAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const createPlantillaThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/plantillas/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createPlantillaAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteExtensionThunk = (payload, setLoading) => async () => {
  setLoading(true);

  try {
    const response = await Axios.delete(`${urlBack}numbering/extensions/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteMarcaThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const response = await Axios.delete(`${urlBack}numbering/marcas/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteMarcaAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deletePlantillaThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const response = await Axios.delete(`${urlBack}numbering/plantillas/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deletePlantillaAction(payload));
    }

    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteModeloThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const response = await Axios.delete(`${urlBack}numbering/modelos/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteModeloAction(payload));
    }

    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateExtensionThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/extensions/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateExtensionAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updatePlantillaThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/plantillas/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updatePlantillaAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateMarcaThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/marcas/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateMarcaAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateModeloThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/modelos/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateModeloAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const changeExtensionPasswordThunk = (newPassword) => async () => {
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/extensions/updatePassword`,
      { data: newPassword },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getNumberingListModalThunk = (payload) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `${urlBack}numbering/numberingList/modal`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(getNumberingListModalAction(response.data));
    }

    return response;
  } catch (err) {
    return err;
  }
};
export const createNumberingListThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/numberingList/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createNumberingListAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const createNumberingListModalThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/modal/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createNumberingListModalAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateNumberingListModalThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/modal/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateNumberingListModalAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateNumberingListThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/numberingList/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateNumberingListAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteNumberingListThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}numbering/numberingList/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteNumberingListAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const getSipPasswordThunk = async (extension) => {
  try {
    const response = await Axios.post(
      `${urlBack}numbering/extensions/password`,
      { data: extension },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) return response.data;
    else if (response.status === 404) return 'Not defined';
  } catch (error) {
    return error;
  }
};

export const updateFileExtensionThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}numbering/extensions/updateFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const uploadFileListNumberingThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}numbering/numberingList/updateFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    return response;
  } catch (err) {
    return err;
  }
};
