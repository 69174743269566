import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize } from '../../theme/fonts';
import { weight } from '../../theme/fonts';

export const NavTableWrapper = styled.div`
  width: 100%;
    height: 30px;
    text-align: flex-end;
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0px 0;
    top: 75px;
    gap: 10px;
    justify-content: space-between;
    padding: 0 10px 0 0px;

    span {
        padding-right: 10px;
     }

.icon_trash_container {
      width: max-content;
      height: 30px;
      display: flex;
      padding: 5px;
      gap: 5px;
      color: white;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: ${colors.primaryColorLight};
      border-radius: 7px;


      &:hover {
        cursor: pointer;
       background-color: ${colors.primaryColor};
      }

      .icon_trash {
        width: 20px;
        height: 20px;
      
      }
      
      &__disabled {
        width: max-content;
        height: 30px;
        display: flex;
        padding: 5px;
        gap: 5px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color:white;
        border-radius: 7px;
        background-color: ${colors.primaryColorLight};

        .icon_trash {
          width: 20px;
          height: 20px;
        }
      
        ${
          '' /* span {
        padding-right: 10px;
        } */
        }
      }
      
      
    }

  .navTable {
    display: inline-flex;
    height: 100%;
    gap: 10px;
    border-radius: 10px;

    &::last-child {
      border-radius: 10px;
    }

    &__selectedItems {
      width: max-content;
    margin: 0;
    font-weight: 500;
    justify-content: center;
        ${'' /* text-align: left; */}
    }

    &__trash,
    &__copy,
    &__export {
      ${
        '' /* width: 40px;
      height: 40px; */
      }
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 10px;
    }

    &__icon {
      color:blue;

      &:hover
      color: ${colors.black};
    }
    &__text {
      font-size: ${newFontSize.body};
      color: ${colors.black};
      font-weight: ${weight.medium};
    }

  

    &:hover {
      background-color: ${colors.blue};

      cursor: pointer;
      color: ${colors.white};

      .navTable__trash__icon,
      .navTable__copy__icon,
      .navTable__export__icon {
        color: ${colors.white};
        height: 30px;
      }

      .navTable__trash__text,
      .navTable__copy__text,
      .navTable__export__text {
        font-size: ${newFontSize.body};
        color: ${colors.white};
        font-weight: ${weight.medium};
      }
    }

    .disabled {
      height: 25px;
      width: 25px;
      opacity: 0.7;

    &:hover {
       opacity: 0.7;
         border-radius: 10px;     
      background-color:white;
      color: ${colors.black}; 
       }
    }
  }
  &__trash,
  &__copy {
  }

  .disabled {
     height: 25px;
    width: 25px;
    opacity: 0.7;   
  }



  .navTable__trash__icon {
    cursor: pointer; 
    height: 40px;
    width: 40px;
    padding: 5px;
        
   &:hover {
      border-radius: 10px;     
      background-color: ${colors.blue};
      color: ${colors.white};
    } 
  }
`;
