import styled from 'styled-components';
// import { colors } from '../../theme/colors.js';

export const CallRegiserScreenWrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .contain__table {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 70px;
    padding-left: 20px;
    overflow: scroll;
  }
  ${'' /* padding-top: 50px; */}
  ${'' /* padding-left: 10px; */}
  ${'' /* background-color: ${colors.primaryColor}; */}
`;
