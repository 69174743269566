import 'regenerator-runtime/runtime';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { dataDropzone } from '../../data/dataDropzone';

import { ContainData, MyDropzoneWrapper } from './MyDropzone.style';
import { func, object } from 'prop-types';

import { useSpeech, useVoices } from 'react-text-to-speech';
import { ToggleButton } from '../ToggleButton/ToggleButton';

export const MyDropzone = ({ item, inputValue, accept, setInputValue, form }) => {
  const { dropzone } = dataDropzone;
  const [textToRender, setTextToRender] = useState('Escribe un texto');

  const { languages, voices } = useVoices();
  const [lang, setLang] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [voiceURI, setVoiceURI] = useState('');
  const {
    // Text, // Component that returns the modified text property
    speechStatus, // String that stores current speech status
    // isInQueue, // Boolean that stores whether a speech utterance is either being spoken or present in queue
    start, // Function to start the speech or put it in queue
    pause, // Function to pause the speech
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({ text: textToRender, lang, voiceURI });

  const handleDropzoneChange = (file, item) => {
    setInputValue((prevState) => ({
      ...prevState,
      [item.ref.element]: file,
    }));
    let action = { action: 'dropzone', value: file };
    form(action, item.ref);
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    // fileRejections,
    //isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      handleDropzoneChange(acceptedFiles, item);
    },
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path}>
      {file.path}
      {/* - {file.size} bytes */}
    </span>
  ));

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //     <span key={file.path}>
  //         {file.path}
  //         {/* - {file.size} bytes */}
  //         <span>
  //             {errors.map((e) => (
  //                 <span key={e.code}>{e.message}</span>
  //             ))}
  //         </span>
  //     </span>
  // ))
  // const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser not supported for speech recognition.</span>;
  // }
  const handleOnChange = (item) => {
    setVoiceURI(voiceURI);
    setTextToRender(item.target.value);
  };

  return (
    <>
      <MyDropzoneWrapper isDragAccept={isDragAccept} isDragReject={isDragReject}>
        {inputValue?.file?.label ? (
          <ContainData>
            <div className="dropzone__file">{/* <span className="dropzone__current">{dropzone.textCurrentFile}</span> */}</div>
          </ContainData>
        ) : (
          <ContainData>
            <div className="dropzone__file">
              <span className="dropzone__success">{dropzone.textDropzoneSuccess}</span>
              {acceptedFileItems.length > 0 ? <span className="dropzone__item">{acceptedFileItems}</span> : <span className="dropzone__item"> &nbsp; </span>}
            </div>
            {/* <div className="dropzone__file">
                            <span className="dropzone__error">
                                {dropzone.textDropzoneError}
                            </span>
                            <span className="dropzone__item">
                                {fileRejectionItems}
                            </span>
                        </div> */}
          </ContainData>
        )}

        <ToggleButton twoButtonActive={acceptedFileItems ? 'true' : 'false'} isActive={isActive} setIsActive={setIsActive} textRight="Archivo de audio" textLeft="Texto como audio" />
        <div className="contain__dropzone">
          <div className="presentation" {...getRootProps({})}>
            <input {...getInputProps()} />
            {inputValue?.file?.label ? <p>{dropzone.textDropzone}</p> : <p>{dropzone.textDropzone}</p>}
            <span className="dropzone__item">{inputValue?.file?.label}</span>
          </div>
        </div>
        <div className="speech__container">
          <textarea className={'input__speech'} value={textToRender} onChange={handleOnChange}></textarea>
          <div>
            <p htmlFor="lang">Idioma: </p>
            <select
              id="lang"
              value={lang}
              onChange={(e) => {
                setLang(e.target.value);
                setVoiceURI('');
              }}>
              <option value="">Seleccionar...</option>
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p htmlFor="voice">Voz: </p>
            <select id="voice" value={voiceURI} onChange={(e) => setVoiceURI(e.target.value)}>
              <option value="">Seleccionar...</option>
              {voices
                .filter((voice) => !lang || voice.lang === lang)
                .map(({ voiceURI }) => (
                  <option key={voiceURI} value={voiceURI}>
                    {voiceURI}
                  </option>
                ))}
            </select>
          </div>
          <div className="speech__buttons">
            {speechStatus !== 'started' ? (
              <button className={'button_start'} onClick={start}>
                Iniciar
              </button>
            ) : (
              <button className={'button_pause'} onClick={pause}>
                Pausa
              </button>
            )}
            <button className={'button_stop'} onClick={stop}>
              Detener
            </button>
          </div>
        </div>
      </MyDropzoneWrapper>
    </>
  );
};

MyDropzone.propTypes = { item: object, inputValue: object, accept: object, setInputValue: func, form: func };
