import { actionsTypes } from './actionsTypes';

// CRUD Numbering List

export const getNumberingListAction = (arrayNumbering) => {
  return {
    type: actionsTypes.getNumberingList,
    arrayNumbering,
  };
};

export const cleanGetNumberingListAction = () => {
  return {
    type: actionsTypes.cleanGetNumberingList,
  };
};

export const createNumberingListAction = (payload) => {
  return {
    type: actionsTypes.createNumberingList,
    payload,
  };
};
export const updateNumberingListAction = (payload) => {
  return {
    type: actionsTypes.updateNumberingList,
    payload,
  };
};
export const deleteNumberingListAction = (payload) => {
  return {
    type: actionsTypes.deleteNumberingList,
    payload,
  };
};

export const deletePlantillaAction = (payload) => {
  return {
    type: actionsTypes.deletePlantilla,
    payload,
  };
};

export const deleteMarcaAction = (payload) => {
  return {
    type: actionsTypes.deleteMarca,
    payload,
  };
};

export const deleteModeloAction = (payload) => {
  return {
    type: actionsTypes.deleteModelo,
    payload,
  };
};
export const importFileNumberingListAction = (file) => {
  return {
    type: actionsTypes.importFileNumberingList,
    file,
  };
};

export const addNumberingListCalendarAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListCalendar,
    payload,
  };
};

export const addNumberingListScheduleAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListSchedule,
    payload,
  };
};

export const addNumberingListSoundPromptAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListSoundPrompt,
    payload,
  };
};

export const addNumberingListDestinationAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestination,
    payload,
  };
};

export const addNumberingListSoundPromptCloseAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListSoundPromptClose,
    payload,
  };
};
export const addNumberingListDestinationCloseIdAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationCloseId,
    payload,
  };
};
export const addNumberingListDestinationCloseAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationClose,
    payload,
  };
};

export const addNumberingListDestinationTypeAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationType,
    payload,
  };
};
export const addNumberingListDestinationCloseTypeIdAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationCloseTypeId,
    payload,
  };
};
export const addNumberingListDestinationCloseTypeAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationCloseType,
    payload,
  };
};

export const addNumberingListCalendarsAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListCalendars,
    payload,
  };
};
export const addNumberingListTimesAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListTimes,
    payload,
  };
};
export const addNumberingListLocutionsAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListLocutions,
    payload,
  };
};
export const addNumberingListDestinationsTypeAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationsType,
    payload,
  };
};
export const addNumberingListDestinationsAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinations,
    payload,
  };
};

export const addNumberingListIsDefaultsAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListIsDefaults,
    payload,
  };
};
export const addNumberingListIsDefaultAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListIsDefault,
    payload,
  };
};
export const addNumberingListDispositivosAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDispositivos,
    payload,
  };
};
export const addNumberingListSchedulesAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListSchedules,
    payload,
  };
};
export const addNumberingListSoundPromptsAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListSoundPrompts,
    payload,
  };
};

export const addNumberingListDestinationTypeCloseAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDestinationTypeClose,
    payload,
  };
};
export const addDestinationCalendarAction = (payload) => {
  return {
    type: actionsTypes.addDestinationCalendar,
    payload,
  };
};

export const removeDestinationCalendarAction = (payload) => {
  return {
    type: actionsTypes.removeDestinationCalendar,
    payload,
  };
};

export const addDestinationOpenAction = (payload) => {
  return {
    type: actionsTypes.addDestinationOpen,
    payload,
  };
};

export const getDdiLinkAction = (payload) => {
  return {
    type: actionsTypes.getDdiLink,
    payload,
  };
};

//CRUD Extension

export const getExtensionAction = (arrayNumbering) => {
  return {
    type: actionsTypes.getExtension,
    arrayNumbering,
  };
};

export const getMarcasAction = (arrayMarcas) => {
  return {
    type: actionsTypes.getMarcas,
    arrayMarcas,
  };
};
export const getModelosAction = (arrayModelos) => {
  return {
    type: actionsTypes.getModelos,
    arrayModelos,
  };
};
export const getPlantillasAction = (arrayPlantillas) => {
  return {
    type: actionsTypes.getPlantillas,
    arrayPlantillas,
  };
};
export const createExtensionAction = (payload) => {
  return {
    type: actionsTypes.createExtension,
    payload,
  };
};

export const createMarcaAction = (payload) => {
  return {
    type: actionsTypes.createMarca,
    payload,
  };
};

export const createModeloAction = (payload) => {
  return {
    type: actionsTypes.createModelo,
    payload,
  };
};

export const createPlantillaAction = (payload) => {
  return {
    type: actionsTypes.createPlantilla,
    payload,
  };
};

export const updateExtensionAction = (payload) => {
  return {
    type: actionsTypes.updateExtension,
    payload,
  };
};

export const updatePlantillaAction = (payload) => {
  return {
    type: actionsTypes.updatePlantilla,
    payload,
  };
};

export const updateMarcaAction = (payload) => {
  return {
    type: actionsTypes.updateMarca,
    payload,
  };
};

export const updateModeloAction = (payload) => {
  return {
    type: actionsTypes.updateModelo,
    payload,
  };
};

export const deleteExtensionAction = (payload) => {
  return {
    type: actionsTypes.deleteExtension,
    payload,
  };
};

export const importFileExtensionAction = (file) => {
  return {
    type: actionsTypes.importFileExtension,
    file,
  };
};

// Form Extensions

export const changeExtensionIdAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionId,
    payload,
  };
};
export const changeExtensionNumberAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionNumber,
    payload,
  };
};

export const changeExtensionNameAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionName,
    payload,
  };
};

export const changeExtensionPasswordAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionPassword,
    payload,
  };
};

export const changeExtensionGroupAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionGroup,
    payload,
  };
};

export const changeExtensionRingTimeoutAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionRingTimeout,
    payload,
  };
};

export const addExtensionIdentificationAction = (payload) => {
  return {
    type: actionsTypes.addExtensionIdentification,
    payload,
  };
};

export const removeExtensionIdentificationAction = (payload) => {
  return {
    type: actionsTypes.removeExtensionIdentification,
    payload,
  };
};
export const addTypeExtensionAction = (payload) => {
  return {
    type: actionsTypes.addTypeExtension,
    payload,
  };
};
export const changeExtensionMarcaAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionMarca,
    payload,
  };
};
export const changePlantillaMarcaAction = (payload) => {
  return {
    type: actionsTypes.changePlantillaMarca,
    payload,
  };
};
export const changeModeloIdAction = (payload) => {
  return {
    type: actionsTypes.changeModeloId,
    payload,
  };
};
export const changeModeloNombreAction = (payload) => {
  return {
    type: actionsTypes.changeModeloNombre,
    payload,
  };
};
export const changePlantillaIdAction = (payload) => {
  return {
    type: actionsTypes.changePlantillaId,
    payload,
  };
};
export const clearPlantillaFormAction = (payload) => {
  return {
    type: actionsTypes.clearPlantillaForm,
    payload,
  };
};
export const clearModeloFormAction = (payload) => {
  return {
    type: actionsTypes.clearModeloForm,
    payload,
  };
};
export const clearMarcaFormAction = (payload) => {
  return {
    type: actionsTypes.clearMarcaForm,
    payload,
  };
};
export const clearPlantillaModeloAction = (payload) => {
  return {
    type: actionsTypes.clearPlantillaNombre,
    payload,
  };
};

export const changePlantillaNombreAction = (payload) => {
  return {
    type: actionsTypes.changePlantillaNombre,
    payload,
  };
};

export const changePlantillaBlfsAction = (payload) => {
  return {
    type: actionsTypes.changePlantillaBlfs,
    payload,
  };
};
export const changePlantillaObservacionesAction = (payload) => {
  return {
    type: actionsTypes.changePlantillaObservaciones,
    payload,
  };
};
export const changePlantillaModeloAction = (payload) => {
  return {
    type: actionsTypes.changePlantillaModelo,
    payload,
  };
};
export const changeExtensionModeloAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionModelo,
    payload,
  };
};
export const changeExtensionPlantillaAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionPlantilla,
    payload,
  };
};

export const removeTypeExtensionAction = (payload) => {
  return {
    type: actionsTypes.removeTypeExtension,
    payload,
  };
};

export const clearExtensionFormAction = (payload) => {
  return {
    type: actionsTypes.clearExtensionForm,
    payload,
  };
};
export const changeExtensionCallForwardAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionCallForward,
    payload,
  };
};
export const changeExtensionCallForwardNAAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionCallForwardNA,
    payload,
  };
};
export const addExtensionCompanyIdAction = (payload) => {
  return {
    type: actionsTypes.addExtensionCompanyId,
    payload,
  };
};
export const addExtensionPlantillaAction = (payload) => {
  return {
    type: actionsTypes.addExtensionPlantilla,
    payload,
  };
};

export const removeExtensionCompanyIdAction = (payload) => {
  return {
    type: actionsTypes.removeExtensionCompanyId,
    payload,
  };
};

export const addExtensionDepartmentAction = (payload) => {
  return {
    type: actionsTypes.addExtensionDepartment,
    payload,
  };
};
export const addExtensionRecordAction = (payload) => {
  return {
    type: actionsTypes.addExtensionRecord,
    payload,
  };
};

export const addExtensionPikedGroupAction = (payload) => {
  return {
    type: actionsTypes.addExtensionPikedGroup,
    payload,
  };
};
export const addExtensionUserAction = (payload) => {
  return {
    type: actionsTypes.addExtensionUser,
    payload,
  };
};

export const removeExtensionAllowAction = (payload) => {
  return {
    type: actionsTypes.removeExtensionAllow,
    payload,
  };
};

export const removeExtensionPikedGroupAction = (payload) => {
  return {
    type: actionsTypes.removeExtensionPikedGroup,
    payload,
  };
};

export const clearExtensionsPikedGroupAction = (payload) => {
  return {
    type: actionsTypes.clearExtensionsPikedGroup,
    payload,
  };
};

export const changeExtensionFilterTypeAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionFilterType,
    payload,
  };
};

export const changeExtensionVoicemailActiveAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionVoicemailActive,
    payload,
  };
};

export const addExtensionCategoriesAction = (payload) => {
  return {
    type: actionsTypes.addExtensionCategories,
    payload,
  };
};

export const changeExtensionCallWaitingAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionCallWaiting,
    payload,
  };
};

export const addExtensionAllowAction = (payload) => {
  return {
    type: actionsTypes.addExtensionAllow,
    payload,
  };
};

export const changeExtensionSipPeerIdAction = (payload) => {
  return {
    type: actionsTypes.changeExtensionSipPeerId,
    payload,
  };
};

export const clearExtensionAllowAction = (payload) => {
  return {
    type: actionsTypes.clearExtensionAllow,
    payload,
  };
};

export const removeExtensionDepartmentAction = () => {
  return {
    type: actionsTypes.removeExtensionDepartment,
  };
};

export const removeExtensionCategoriesAction = () => {
  return {
    type: actionsTypes.removeExtensionCategories,
  };
};

// Form Numbering List
export const changeNumberingListIdAction = (payload) => {
  return {
    type: actionsTypes.changeNumberingListId,
    payload,
  };
};
export const changeMarcaNombreAction = (payload) => {
  return {
    type: actionsTypes.changeMarcaNombre,
    payload,
  };
};
export const changeModeloMarcaAction = (payload) => {
  return {
    type: actionsTypes.changeModeloMarca,
    payload,
  };
};
export const changeMarcaIdAction = (payload) => {
  return {
    type: actionsTypes.changeMarcaId,
    payload,
  };
};
export const changeNumberingListNumberAction = (payload) => {
  return {
    type: actionsTypes.changeNumberingListNumber,
    payload,
  };
};

export const addNumberingListDepartmentIdAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDepartmentId,
    payload,
  };
};
export const addNumberingListSoundPromptCloseIdAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListSoundPromptCloseId,
    payload,
  };
};
export const changeNumberingListCompanyIdAction = (payload) => {
  return {
    type: actionsTypes.changeNumberingListCompanyId,
    payload,
  };
};
export const changeNumberingListNumberIdAction = (payload) => {
  return {
    type: actionsTypes.changeNumberingListNumberId,
    payload,
  };
};
export const changeNumberingListNameAction = (payload) => {
  return {
    type: actionsTypes.changeNumberingListName,
    payload,
  };
};

export const addNumberingListHqAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListHq,
    payload,
  };
};

export const removeNumberingListHqAction = (payload) => {
  return {
    type: actionsTypes.removeNumberingListHq,
    payload,
  };
};

export const clearNumberingListFormAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListForm,
    payload,
  };
};

export const addNumberingListCompanyIdAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListCompanyId,
    payload,
  };
};

export const removeNumberingListCompanyIdAction = (payload) => {
  return {
    type: actionsTypes.removeNumberingListCompanyId,
    payload,
  };
};

export const addNumberingListDepartmentAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListDepartment,
    payload,
  };
};

export const addNumberingListRecordAction = (payload) => {
  return {
    type: actionsTypes.addNumberingListRecord,
    payload,
  };
};

export const clearNumberingListCalendarAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListCalendar,
    payload,
  };
};

export const clearNumberingListScheduleAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListSchedule,
    payload,
  };
};

export const clearNumberingListSoundPromptAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListSoundPrompt,
    payload,
  };
};

export const clearNumberingListDestinationAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListDestination,
    payload,
  };
};

export const clearNumberingListSoundPromptCloseAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListSoundPromptClose,
    payload,
  };
};

export const clearNumberingListDestinationCloseAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListDestinationClose,
    payload,
  };
};

export const clearNumberingListCompanyIdAction = (payload) => {
  return {
    type: actionsTypes.clearNumberingListCompanyId,
    payload,
  };
};

export const clearListNumberingDepartmentAction = (payload) => {
  return {
    type: actionsTypes.clearListNumberingDepartment,
    payload,
  };
};

export const removeDestinationOpenAction = (payload) => {
  return {
    type: actionsTypes.removeDestinationOpen,
    payload,
  };
};
