export const typeRegex = {
  inputText: /^[\w+( \w+)].{2,50}$/,
  inputNumberOneDigit: /^(?=.*\d)/,
  //accept all character min 3 length
  inputNumber: /\d\d/, //not accept letters
  inputOnlyNumbers: /^[0-9]{6}$/,
  inputPassword: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d][a-zA-Z0-9!@#$%^&*)(+=._-]{7,}$/, //min 1 letter 1 number maybe can contain special character
  inputDropzone: /([\s\S]{10,})/,
  inputPrefix: /^(?:[+].*\d)$/, // accept number & + necessary
  inputMail: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
  inputIP: /^(\d{1,3}\.){3}\d{1,3}$/,
  inputMinOneCharacter: /.+/,
  domainRegex: /^([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})$/,
  number1digit: /^[^a-zA-Z]{1,2}$/,
  textNoSpaces: /^[\w+( \w+)].[\S]{2,50}$/,
  multiselect: /\{\s/,
};
