import { actionTypesPreferences } from './actionTypes';

// Schedule Actions

export const getSchedulesAction = (payload) => {
  return { type: actionTypesPreferences.getSchedules, payload };
};

// Schedule Form Actions

export const changeScheduleNameAction = (payload) => {
  return { type: actionTypesPreferences.changeScheduleName, payload };
};

export const changeScheduleIdAction = (payload) => {
  return { type: actionTypesPreferences.changeScheduleId, payload };
};

export const changeScheduleHoursAction = (payload) => {
  return { type: actionTypesPreferences.changeSchedule, payload };
};

export const addScheduleTimeIdAction = (payload) => {
  return { type: actionTypesPreferences.addScheduleTimeId, payload };
};

export const removeScheduleTimeIdAction = (payload) => {
  return { type: actionTypesPreferences.removeScheduleTimeId, payload };
};

export const clearScheduleFormAction = () => {
  return { type: actionTypesPreferences.clearScheduleForm, payload: '' };
};

// Calendar Actions

export const getCalendarAction = (payload) => {
  return { type: actionTypesPreferences.getCalendar, payload };
};

// Calendar Form Actions

export const changeCalendarNameAction = (payload) => {
  return { type: actionTypesPreferences.changeCalendarName, payload };
};

export const changeCalendarColorAction = (payload) => {
  return { type: actionTypesPreferences.changeCalendarColor, payload };
};

export const changeCalendarDaysAction = (payload) => {
  return { type: actionTypesPreferences.changeCalendarDays, payload };
};

export const changeCalendarLinkIdAction = (payload) => {
  return { type: actionTypesPreferences.changeCalendarLinkId, payload };
};

export const addCalendarDaysIdAction = (payload) => {
  return { type: actionTypesPreferences.addCalendarDaysId, payload };
};

export const clearCalendarFormAction = () => {
  return { type: actionTypesPreferences.clearCalendarForm, payload: '' };
};
