import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataScreenDomains } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import '../../../styles/Screens/SettingsScreen/Domains.css';

export const Domains = () => {
  const { loading, domains, getDomains, deleteDomain, uploadFileDomain } = useSettings();
  const { typeSwal } = useSwal();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const { notFound } = dataScreenDomains;

  let navigate = useNavigate();

  useEffect(() => {
    getDomains();
  }, []);

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.settings}/${routesSettings.createDomains}`, {});
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileDomain(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getDomains();
      }
    } catch (err) {
      return err;
    }
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteDomain(item.original);
          } else {
            response = await deleteDomain(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getDomains();
          }
        } catch (err) {
          if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
        }
      }
    });
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.settings}/${routesSettings.updateDomains}`, {
      state: item.original ? item.original : item,
    });
  };

  const DATA = domains.map(({ domain, id }) => ({
    domain,
    id,
  }));
  const COLUMNS = [
    {
      Header: 'Dominio',
      accessor: 'domain',
    },
  ];
  const dataExport = domains.map(({ domain }) => ({
    Dominio: domain,
  }));

  const template = [
    {
      Dominio: '',
    },
  ];
  // const items = domains.map(({ domain, id }) => ({
  //   domain,
  //   id,
  // }));

  const tableInstance = useRef(null);

  return (
    <div className="domains">
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },

            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
              import: false,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          totalDocs={domains.length}
          isNavTable={true}
          checkboxesActives={checkBoxesActives}
          setCheckboxesActives={setCheckboxesActives}
          handleOpenFormCreate={handleOpenFormCreate}
        />
        {/* {domains[0]?.status ? null : (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={items} filter={dataFilterDomains} setSearch={setSearch} />
          </div>
        )} */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : domains[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : (
        <>
          <div className="contain__table">
            <TableInfo
              COLUMNS={COLUMNS}
              DATA={search.length === 0 ? DATA : search}
              format={'list'}
              deleteFunction={handleDelete}
              updateFunction={handleOpenFormEdit}
              ref={tableInstance}
              onClick={handleOpenFormEdit}
              setIsBottom={setIsBottom}
              isNavTable={true}
              checkboxesActives={checkBoxesActives}
              setCheckboxesActives={setCheckboxesActives}
            />
          </div>
        </>
      )}
    </div>
  );
};
