import styled from 'styled-components';

export const StaticNavigationScreenWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  /* .form-contain {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px; 
  
    height: 100%;
    position: fixed;
    left: ${(props) => (props.isMenuOpen ? '228px' : '70px')};
  }*/
`;
