import React from 'react';
// import { Header } from '../../components/Header/Header';
// import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';
import PreferencesNavigation from '../../navigation/PreferencesNavigation/PreferencesNavigation';
import { PreferencesScreenWrapper } from './PreferencesScreen.style';
// import { menuSettings } from '../../data/dataSettings';
// import { useLocation } from 'react-router-dom';
// import { menuPreferences } from '../../data/dataPreferences';

const PreferencesScreen = () => {
  // const location = useLocation();
  // const isPrefHorarias =
  //   location.pathname.includes('preferences/calendar') ||
  //   location.pathname.includes('preferences/schedule') ||
  //   location.pathname.includes('settings/calendar') ||
  //   location.pathname.includes('settings/schedule') ||
  //   location.pathname.includes('settings/company') ||
  //   location.pathname.includes('settings/department')
  //     ? true
  //     : false;

  return (
    <PreferencesScreenWrapper>
      {/* <Header image={menuNavLeft.menuTop[6].image} text={menuNavLeft.menuTop[6].text} /> */}
      {/* <MenuTop menu={menuSettings} /> */}
      {/* {isPrefHorarias ? <MenuTop menuType={'Preferencias'} menu={menuPreferences} /> : null} */}

      <PreferencesNavigation />
    </PreferencesScreenWrapper>
  );
};

export default PreferencesScreen;
