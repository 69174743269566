import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
// import { AnimatePresence } from 'framer-motion';
import { IconToggleLeft, IconToggleRight, IconDotsVertical, IconPencil, IconCheck, IconX, IconStarFilled, IconStar } from '@tabler/icons-react';
// import { Tooltip as ReactTooltip } from 'react-tooltip';

import { menuButtonsDropDownTable } from '../../data/dataButtons';
import { DropDown } from '../DropDown/DropDown.jsx';
import { Checkbox } from '../Checkbox/Checkbox';
import { TableInfoWrapper } from './TableInfo.style';
import { dataTable } from '../../data/dataTable';
import { colors } from '../../theme/colors';
import { array, bool, func, number, string } from 'prop-types';
import useApplications from '../../hooks/useApplications.js';
import AudioPlayer from '../AudioPlayer/AudioPlayer.jsx';
// import { NavButtons } from '../NavButtons/NavButtons.jsx';
// import { useLocation } from 'react-router-dom';
// import useNumbering from '../../hooks/useNumbering.js';

// import { NavButtons } from '../NavButtons/NavButtons.jsx';

export const TableInfo = React.forwardRef(
  (
    {
      format,
      handleEditRule,
      // handleOpenFormEdit,
      // handleClickCreate,
      // handleClickAssign,
      // handleShowEditRow,
      checkboxesActives,
      setCheckboxesActives,
      handleFav,
      COLUMNS,
      DATA,
      askedBy,
      deleteFunction,
      updateFunction,
      isOptions = false,
      onClick,
      setShowPassword,
      showPassword,
      isCheckbox = true,
      // isDropdownActive,
    },
    ref
  ) => {
    const [checkBoxAllSelected, setCheckBoxAllSelected] = useState(false);
    // const [checkboxesActives, setCheckboxesActives] = useState([]);
    const [, setAudios] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const { songWaitingLink } = useApplications();
    // const { formListNumberingReducer } = useNumbering();

    // const location = useLocation();

    const scrollContainerRef = useRef(null);

    menuButtonsDropDownTable.forEach((item) => {
      if (item.id === 1) {
        item.function = updateFunction;
      } else if (item.id === 2) {
        item.function = deleteFunction;
      }
    });

    const sortItems = (prev, curr, columnId) => {
      if (typeof prev.original[columnId] === 'number') {
        if (prev.original[columnId] > curr.original[columnId]) {
          return 1;
        } else if (prev.original[columnId] < curr.original[columnId]) {
          return -1;
        } else {
          return 0;
        }
      } else if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
        return 1;
      } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    };

    COLUMNS?.map(
      (item) =>
        (item.sortType = (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        })
    );

    function addColumnOption() {
      const found = COLUMNS.some((item) => item.Header === 'options');
      if (!found) {
        COLUMNS.push({
          Header: 'options',
          className: 'options',
          Cell: ({ row }) => {
            let refContainer = useRef(null);
            return (
              <DropDown
                layout={row.id}
                data={row}
                menuButtonsDropDown={menuButtonsDropDownTable}
                Icon={IconDotsVertical}
                direction={'column'}
                position={'calc(5% + 30px);'}
                refContainer={refContainer}
                iconColor={colors.grey}
              />
            );
          },
        });
        return COLUMNS;
      }
    }
    if (isOptions) {
      addColumnOption();
    }

    function addColumnCheckbox() {
      const found = COLUMNS?.some((item) => item.Header === 'checkbox');
      if (!found) {
        COLUMNS?.unshift({
          Header: 'checkbox',
          className: 'checkbox',

          disableSortBy: true,
        });
      }
    }
    if (isCheckbox) {
      addColumnCheckbox();
    }
    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => DATA, [DATA]);

    const tableInstance = useTable(
      { columns, data },
      // useFilters,
      // useGlobalFilter,
      useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useImperativeHandle(ref, () => tableInstance);

    const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const [objectSongWaiting, setObjectSongWaiting] = useState({
      arraySongWaiting: [],
      title: '',
    });

    const [idSelected, setIdSelected] = useState('');
    const [isAudioPlayerOpen, setIsAudioPlayerOpen] = useState(false);

    const handleCLick = (item) => {
      if (item.original !== undefined) {
        setIsSelected(true);
        setIdSelected(item.original.id);
        const newSongWaiting = songWaitingLink.filter((object) => (object.mohId !== undefined ? object.mohId === item.original.id : ''));
        setObjectSongWaiting({
          arraySongWaiting: newSongWaiting,
          title: item.original?.filename,
        });
      }
    };

    const handleShowLocutions = (item) => {
      setIsAudioPlayerOpen(true);
      setIsClicked(true);
      handleCLick(item);
    };

    const handleIsOpen = (status) => {
      if (status !== undefined) {
        setIsAudioPlayerOpen(status);

        // console.log('IsAudioPlayerOpen updated: ' + status);
        return status;
      }
    };

    // const handleEditRule = (item) => {
    //   const newCalendar = item.Calendario;

    //   const newCalendarData =
    // const newSchedule = item.Horario;
    // const newSoundprompt = item.Locucion;
    // const newDestType = item.Destino.split(': ')[0];
    // const newDest = item.Destino.split(': ')[1];
    // const newIsDefault = item.isDefault;
    // console.log(newCalendar);
    // console.log(newSchedule);
    // console.log(newSoundprompt);
    // console.log(newDestType);
    // console.log(newDest);
    // console.log(newIsDefault);
    // formListNumberingReducer.calendar = { value: item.id, label: newCalendar };
    // listNumberingForm(
    //   {
    //     action: 'select-option',
    //   },
    //   {
    //     form: 'numberingList',
    //     element: 'calendar',
    //   },
    //   {
    //     value: newCalendar,
    //     label: newCalendar,
    //   }
    // );
    // };

    useEffect(() => {
      setAudios([...columns]);
    }, [checkBoxAllSelected]);

    const handleClassTable = () => {
      switch (askedBy) {
        case 'numberingScreen':
          return 'numb__table';

        case 'UserForm':
          return 'user__table';

        default:
          return '';
      }
    };
    // const arrayHeaderGroups = headerGroups[0]?.headers.length ? headerGroups[0] : headerGroups;

    return (
      <TableInfoWrapper id="table" ref={scrollContainerRef} className={handleClassTable()}>
        {/* <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormEdit,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            filter: {
              position: 'right',
            },
            file: {
              position: 'right',
              data: isFilter.active ? filterDataExport : allDataExport,
              template,
            },
          }}
          onClickCreate={handleClickCreate}
          onClickAssign={handleClickAssign}
          isDropdownActive={isDropdownActive}
          checkboxesActives={checkboxesActives}
          setCheckboxesActives={setCheckboxesActives}
          isNavTable={true}
          handleShowEditRow={handleShowEditRow}
          DATA={data?.tabs ? data.tabs : data}
        /> */}
        {/* {arrayNumberingHook[0]?.status ? null : (
          <div className="contain__menuFilter">
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterExtension} setSearch={setSearch} />
          </div>
        )}
        <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteNumberingList} />  */}
        {format === 'list' ? (
          <table {...getTableProps()}>
            <thead key={headerGroups.length}>
              {headerGroups?.map((itemHeaderGroups, indexHeader) => (
                // <tr key={headerGroups.id} {...headerGroups.getHeaderGroupProps()}>
                <tr key={indexHeader}>
                  {itemHeaderGroups?.headers.map((column, index) => (
                    <React.Fragment key={column.id + '__' + index}>
                      {/* column.id + '__' + index + '__' + DATA[index]?.id}> */}
                      <th className={column.className || column.id}>
                        {/* <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}></th> */}
                        {/* <div> */}
                        <div className={column.id === 'dispositivos' ? 'tooltip-column_devices' : 'tooltip-column'}>
                          {column.Header === 'options' ? (
                            ''
                          ) : column.Header === 'checkbox' ? (
                            <Checkbox
                              checkBoxAllSelected={checkBoxAllSelected}
                              setCheckBoxAllSelected={setCheckBoxAllSelected}
                              checkboxesActives={checkboxesActives}
                              setCheckboxesActives={setCheckboxesActives}
                              DATA={DATA}
                              // id={column.id + '__' + DATA[index]?.id}
                              // row={column.row?.original}
                            />
                          ) : (
                            <h3>{column.render('Header')}</h3>
                          )}

                          {/* <div className="image-header"> */}
                          {column.Header === 'options' ||
                          column.Header === ' ' ||
                          column.Header === '  ' ||
                          column.Header === 'Fav.' ||
                          (column.disableSortBy && column.Header !== 'Contraseña SIP') ? (
                            ''
                          ) : column.Header === 'Contraseña SIP' ? (
                            <div className="image-showPassword" onClick={() => handleShowPassword()}>
                              {showPassword ? <IconToggleRight size={'18px'} color={colors.primaryColor} /> : <IconToggleLeft size={'18px'} color={colors.black} />}
                            </div>
                          ) : column.isSorted ? (
                            column.isSortedDesc ? (
                              <img className="image-header" src={dataTable.sortDescending.image} alt={dataTable.sortDescending.alt} />
                            ) : (
                              <img className="image-header" src={dataTable.sortAscending.image} alt={dataTable.sortAscending.alt} />
                            )
                          ) : (
                            <img className="image-header" src={askedBy === 'UserForm' || askedBy === 'ListNumberForm' ? dataTable.sort.image : dataTable.sortBlack.image} alt={dataTable.sort.alt} />
                          )}
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                      </th>
                      {/* {index !== 0 && <th className="spacer"></th>} */}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {/* <AnimatePresence> */}
              {rows?.map((row, indexRow) => {
                prepareRow(row);

                return (
                  <tr key={row.original.id + '__' + indexRow + '__' + row.index} className={row.original?.id === idSelected ? 'selected-row' : 'row'}>
                    {/* <tr key={row.id} className={row.original.id === idSelected ? 'selected-row' : ''} {...row.getRowProps()}></tr> */}
                    {row?.cells?.map((cell, index) => {
                      return isOptions && index === row.cells.length - 1 ? (
                        <td key={cell.column.id + '__' + cell.row.id + '__' + cell.row.original.id} className={'last-td'} onClick={handleCLick}>
                          {cell.render('Cell', {})}
                        </td>
                      ) : (
                        <React.Fragment key={cell.column.id + '__' + cell.row.id + '__' + cell.row.original.id}>
                          {/* key={cell.column.id + '__' + cell.row.original.id}> */}
                          <td
                            // onMouseOver={handleMouseOver}
                            className={
                              cell.column.id === 'dispositivos'
                                ? 'iconDevices__container'
                                : cell.column.id === 'fullName'
                                  ? 'userlogo__container'
                                  : cell.column.id === 'User.fullName'
                                    ? 'col__fullName'
                                    : cell.column.id
                            }
                            // key={cell.column.id + '__' + cell.row.id + '__' + cell.row.original.id}
                            // id={cell.column.id + '__' + cell.row.id + '__' + cell.row.original.id}
                            onClick={
                              cell?.column?.Header === 'Contraseña SIP' ||
                              cell?.column?.Header === 'Registrar' ||
                              cell?.column?.Header === 'Llamar y grabar' ||
                              cell?.column?.Header === 'Password' ||
                              cell?.column?.Header === 'checkbox' ||
                              cell?.column?.Header === 'Sede' ||
                              cell?.column?.Header === 'Contraseña' ||
                              cell?.column?.Header === 'Agentes'
                                ? () => {}
                                : cell?.column?.Header === 'Locuciones'
                                  ? () => handleShowLocutions
                                  : () => onClick(row)
                            }
                            // {...cell.getCellProps()}>
                          >
                            {cell?.column?.Header === 'checkbox' ? (
                              <Checkbox
                                // key={cell.column.id + '__' + DATA[index]?.id}
                                checkBoxAllSelected={checkBoxAllSelected}
                                setCheckBoxAllSelected={setCheckBoxAllSelected}
                                row={cell.row}
                                id={cell.column.id + '__' + cell.row.id + '__' + cell.row.original.id}
                                checkboxesActives={checkboxesActives}
                                setCheckboxesActives={setCheckboxesActives}
                              />
                            ) : cell?.column?.Header === ' ' && row.original.isDefault === 1 ? (
                              <IconStarFilled className={'icon-fav-activated'} data-tooltip-id="my-anchor-element" onClick={() => handleShowLocutions(row)}></IconStarFilled>
                            ) : cell?.column?.Header === ' ' ? (
                              <IconStar onClick={handleFav} className={'icon-fav-deactivated'} color="grey" />
                            ) : cell?.column?.Header === 'Activado' || cell?.column.Header === 'checkbox' || cell?.column?.Header === 'Virtual' || cell?.column?.Header === 'Grabar' ? (
                              row.original.isRecord === 1 || row.original.isvirtual === 'Sí' || row.original.record === 'Si' || row.original.isDefault === 1 ? (
                                <IconCheck className={'icon-activated'} data-tooltip-id="my-anchor-element" onClick={() => handleShowLocutions(row)}></IconCheck>
                              ) : (
                                <IconX className={'icon-deactivated'} data-tooltip-id="my-anchor-element" onClick={() => handleShowLocutions(row)}></IconX>
                                //  )
                              )
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                          {/* {index !== 0 && <td className="spacer" onClick={() => onClick(row)}></td>} */}
                        </React.Fragment>
                      );
                    })}
                    {row.original.isDefault !== undefined && isOptions ? (
                      <td className="icons__container">
                        <IconPencil className="icon__row" id="edit-button" onClick={handleEditRule(row.original)} />
                        {/* <ReactTooltip className="tooltip" anchorSelect={'#edit-button'} place="bottom" content={'Editar'} delayShow={500} /> */}
                        {/* <IconCopy className="icon__row" />
                        <IconRotateClockwise2 className="icon__row" /> */}
                      </td>
                    ) : null}
                  </tr>
                );
              })}
              {/* </AnimatePresence> */}
            </tbody>
          </table>
        ) : (
          ''
        )}
        {isAudioPlayerOpen && objectSongWaiting.arraySongWaiting.length > 0 ? (
          // <Tooltip id="my-anchor-element" clickable="true" place="bottom" className="tooltip-container" positionStrategy="fixed">
          <div className="tooltip-Locutions">
            <div className="tooltip-body">
              {objectSongWaiting.arraySongWaiting.length > 0 && isSelected && isClicked ? (
                <AudioPlayer
                  allTracks={objectSongWaiting.arraySongWaiting?.map((itemLocucion) => itemLocucion.Soundprompt)}
                  title={objectSongWaiting.title}
                  isOpen={handleIsOpen}
                  DATA={DATA}
                  COLUMNS={COLUMNS}
                />
              ) : null}
            </div>
          </div>
        ) : (
          // </Tooltip>
          ''
        )}
      </TableInfoWrapper>
    );
  }
);

TableInfo.displayName = 'TableInfo';
TableInfo.propTypes = {
  format: string,
  handleEditRule: func,
  COLUMNS: array,
  DATA: array,
  deleteFunction: func,
  updateFunction: func,
  isOptions: bool,
  onClick: func,
  setIsBottom: func,
  setShowPassword: func,
  handleFav: func,
  showPassword: bool,
  isCheckbox: bool,
  isNavTable: bool,
  setDataCard: func,
  row: string,
  idSelected: number,
  checkboxesActives: array,
  askedBy: string,
  setCheckboxesActives: func,
  isDropdownActive: bool,
  handleOpenFormEdit: func,
  handleClickCreate: func,
  handleClickAssign: func,
  handleShowEditRow: func,
};
