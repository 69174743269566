import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useEM from '../../../hooks/useEM';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { ToggleSwitch } from '../../../components/ToggleSwich/ToggleSwitch';
import { colors } from '../../../theme/colors';
import { routesEM, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { dataScreenAlerts } from '../../../data/dataEM';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';

import { AlertsWrapper } from './AlertsScreen.style';
import { string } from 'prop-types';
// import { MenuTop } from '../../../components/MenuTop/MenuTop';

export const AlertsScreens = () => {
  const { arrayEMAlerts, getEMAlerts, deleteEMAlerts, uploadFileAlerts, loading } = useEM();
  // changeEMAlertsCallAndRecordHook,
  // const { audios } = useApplications();
  const { typeSwal } = useSwal();
  const { isCardView } = useView();

  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const { notFound, icon } = dataScreenAlerts;
  // const [, setCallAndRecord] = useState({});

  const tableInstance = useRef(null);

  // const filesMP3 = audios.audios;

  // function foundFile(filename) {
  //   let file;
  //   if (filesMP3.find((file) => file.includes(filename))) {
  //     file = filename;
  //   }
  //   if (import.meta.env.NODE_ENV === 'production' || import.meta.env.NODE_ENV === 'test') {
  //     return import.meta.env.VITE_APP_URL.replace('api', 'uploads') + '/mp3/' + file;
  //   } else {
  //     return import.meta.env.VITE_APP_UPLOAD_FOLDER + '/mp3/' + file;
  //   }
  // }

  const navigate = useNavigate();

  useEffect(() => {
    getEMAlerts();
  }, []);

  // const toggleSwitch = (data, isOn, setIsOn) => {
  //   if (data.toggle !== 0) {
  //     typeSwal('modify').then(async (result) => {
  //       if (result.isConfirmed) {
  //         let callAndRecordVariable = {
  //           idAlert: data.id,
  //           callAndRecord: !isOn,
  //         };
  //         changeEMAlertsCallAndRecordHook(callAndRecordVariable);

  //         setIsOn(!isOn);

  //         setCallAndRecord((prevState) => ({
  //           ...prevState,
  //           [data]: { idSubmenu: data, isActive: !isOn },
  //         }));

  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 600);
  //       }
  //     });
  //   }
  // };

  const COLUMNS = [
    {
      Header: 'Código alerta',
      accessor: 'keyCall',
    },
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => <p style={{ textAlign: 'left' }}>{row.values.name}</p>,
    },
    {
      Header: 'Lista difusión',
      accessor: 'listDiffusionName',
    },
    {
      Header: 'Lista difusión 2',
      accessor: 'secondaryListDiffusionName',
    },
    {
      Header: 'Confirmación',
      accessor: 'confirmationNumber',
    },
    {
      Header: 'Locución',
      accessor: 'description',
      Cell: ({ row }) => (
        // {row.values.callAndRecord.toggle === '1' || row.values.callAndRecord.toggle === 1 ? (
        <div>
          <audio style={{ width: '210px', height: '35px' }} src={row.original.filename} controls />
        </div>
        // ) : null}   }
      ),
    },
    {
      Header: 'Llamar y grabar',
      accessor: 'callAndRecord',
      disableSortBy: true,
      Cell: ({ row }) => (
        <ToggleSwitch
          version={true}
          isActive={row.values.callAndRecord.toggle ? { callAndRecord: true } : { callAndRecord: false }}
          color={colors.primaryColor}
          data={row.values.callAndRecord}
          toggleSwitch={() => {}}
        />
      ),
    },
  ];

  const newArray = arrayEMAlerts.map((item) => {
    return {
      keyCall: item.keyCall,
      description: item.Soundprompt?.description,
      name: item.name,
      title: item.title,
      filename: item.callAndRecord === '0' || item.callAndRecord === 0 ? item.Soundprompt?.filename : 'Llamar y grabar activado',
      callAndRecord: { toggle: item.callAndRecord, id: item.id },
      id: item.id,
      soundPromptId: item.Soundprompt?.id,
      listDiffusionName: item.principalList?.name,
      listDiffusionId: item.principalList?.id,
      secondaryListDiffusionId: item.secondaryList?.id,
      secondaryListDiffusionName: item.secondaryList?.name,
      confirmationNumber: item.confirmationNumber,
    };
  });

  const DATA = arrayEMAlerts.map(({ keyCall, name, title, Soundprompt, callAndRecord, id, externo, principalList, secondaryList, confirmationNumber }) => ({
    keyCall,
    name,
    description: Soundprompt?.description,
    title,
    filename: callAndRecord === '0' || callAndRecord === 0 ? Soundprompt?.filename : 'Llamar y grabar activado',
    callAndRecord: { toggle: callAndRecord, id: id },
    externo: { toggle: externo, id: id },
    id,
    soundPromptId: Soundprompt?.id,
    listDiffusionName: principalList?.name,
    listDiffusionId: principalList?.id,
    confirmationNumber,
    secondaryListDiffusionId: secondaryList?.id,
    secondaryListDiffusionName: secondaryList?.name,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(
        ({ keyCall, name, description, filename, callAndRecord, id, soundPromptId, listDiffusionName, listDiffusionId, confirmationNumber, secondaryListDiffusionId, secondaryListDiffusionName }) => ({
          title: name,
          subtitle: keyCall,
          text: listDiffusionName,
          icon: icon.code,
          icon2: icon.list,
          icon3: icon.volume,
          text2: filename,
          isActive: callAndRecord?.toggle ? { callAndRecord: true } : { callAndRecord: false },
          keyCall,
          name,
          description,
          filename,
          callAndRecord,
          id,
          soundPromptId,
          listDiffusionName,
          listDiffusionId,
          confirmationNumber,
          secondaryListDiffusionId,
          secondaryListDiffusionName,
        })
      );
    } else {
      return DATA.map(({ keyCall, description, name, filename, callAndRecord, id, soundPromptId, listDiffusionName, listDiffusionId, confirmationNumber }) => ({
        title: name,
        subtitle: keyCall,
        text: listDiffusionName,
        icon: icon.code,
        icon2: icon.list,
        icon3: icon.volume,
        text2: filename,
        isActive: callAndRecord?.toggle ? { callAndRecord: true } : { callAndRecord: false },
        keyCall,
        name,
        description,
        filename,
        callAndRecord,
        id,
        soundPromptId,
        listDiffusionName,
        listDiffusionId,
        confirmationNumber,
      }));
    }
  };

  const dataToCards = dataCard();

  const dataExport = newArray.map(({ keyCall, name, filename, callAndRecord, listDiffusionName, secondaryListDiffusionName }) => ({
    'Código alerta': keyCall,
    Nombre: name,
    Locución: filename,
    'Llamar y grabar': callAndRecord.toggle,
    'Lista de difusión': listDiffusionName,
    'Lista de difusion secundaria': secondaryListDiffusionName,
  }));

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.EM}/${routesEM.createAlerts}`, {});
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.EM}/${routesEM.updateAlerts}`, {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteEMAlerts(item.original);
          } else {
            response = await deleteEMAlerts(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getEMAlerts();
            typeSwal('delete');
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileAlerts(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getEMAlerts();
      }
    } catch (err) {
      return err;
    }
  };

  const template = [
    {
      'Codigo Alerta': '',
      Nombre: '',
      'Numero de confirmacion': '',
      'Lista_de_difusión *(id)': '',
      'Lista de difusión secundaria *(id)': '',
      'Locución *(id)': '',
      'Llamar y grabar': '0 o 1',
    },
  ];

  return (
    <AlertsWrapper className="screen__inside-size">
      {/* <MenuTop menu={menuEM} setIsClosed={setIsClosed} /> */}
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },

            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          checkboxesActives={checkBoxesActives}
          setCheckboxesActives={setCheckboxesActives}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayEMAlerts[0]?.status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          {' '}
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            isNavTable={true}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
      )}
    </AlertsWrapper>
  );
};

AlertsScreens.propTypes = { row: string };
