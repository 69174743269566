import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formModelo } from '../../../data/dataNumbering';
// import { useDepartments } from '../../../hooks/useDepartments';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import useNumbering from '../../../hooks/useNumbering';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const ModeloForm = ({ crudType }) => {
  const { arrayMarcasHook, listNumberingForm, getModelos, updateModelo, createModelo, arrayModelosHook, formModeloReducer } = useNumbering();
  // const { departmentsHook } = useDepartments();
  const { getMarcas } = useNumbering();
  const { typeSwal } = useSwal();

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);

  const data = formModelo;

  useEffect(() => {
    getModelos();
    getMarcas();

    listNumberingForm({ action: 'clearForm' }, { form: 'modelo' });

    if (crudType === 'edit') {
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        { form: 'modelo', element: 'id' }
      );
    }
    listNumberingForm(
      {
        action: 'input',
        value: location.state?.modelo,
      },
      { form: 'modelo', element: 'modelo' }
    );

    listNumberingForm(
      {
        action: 'select-option',
      },
      { form: 'modelo', element: 'marca' },
      location.state?.marcaId
    );
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayModelosHook === 'object' && arrayModelosHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'marca') {
              item2.options = [];
              arrayMarcasHook?.forEach((item3) => {
                if (item3?.marca === null) {
                  item3.marca = 'not defined';
                }
                const marca = {
                  value: item3?.id,
                  label: item3?.marca,
                };
                const found = item2.options.some((e) => e.value === marca.value);
                if (!found && marca.value !== undefined) {
                  item2.options.push(marca);
                }
              });
            }
          });
          // return item;
        });

        if (crudType === 'edit' && (formModeloReducer?.modelo === '' || formModeloReducer?.marca !== location.state?.marcaId)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((step) => {
            step.input.map((item) => {
              switch (item.ref.element) {
                case 'id':
                  item.defaultValues = formModeloReducer?.id;
                  break;
                case 'marca':
                  item.defaultValues = formModeloReducer?.marca;
                  // { value: formMarcaReducer.id, label: formMarcaReducer.marca };

                  break;
                case 'modelo':
                  item.defaultValues = formModeloReducer?.modelo;
                  // { value: formMarcaReducer.id, label: formMarcaReducer.marca };

                  break;
                default:
                  break;
              }
              return item;
            });
            return step;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 1000);
  });

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getModelos, `/${routesWide.numbering}/${routesNumbering.modelosList}`, 'modelo', createModelo);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', listNumberingForm, getModelos, `/${routesWide.numbering}/${routesNumbering.modelosList}`, 'modelo', updateModelo);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          data={data}
          onSubmit={onSubmit}
          form={listNumberingForm}
          dataSave={dataSave}
          dataTabs={data.tabs || data.steps}
          // initialData={EMFormListExtensions}
          crudType={crudType}
          arrScreen={['Modelo']}
        />
      )}
    </>
  );
};

ModeloForm.propTypes = { crudType: string };
