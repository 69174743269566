import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const FormScreenWrapper = styled.div`
  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }



.contain__inputCard-component__modal{
    padding: 40px 60px 40px;
    background-color: ${colors.backgroundColor};
    border-radius: 0 0 10px 10px;
    height: 100%;
    
    .stepForm__contain-typeInput{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      column-gap: 5px;
      row-gap: 20px;
    }

    .textRequired{
      color: ${colors.primaryColor};
    }

    div {
        font-size: 14px;
        &:hover{
        box-shadow: unset;}
      }

  .contain__select{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
font-size: 16px;

    div {font-size: 16px;border-radius: 10px;}

    span:first-child {
      padding-left: 5px;
    }
  }
}



  &::-webkit-scrollbar-thumb {
    width: 1px;
    background: ${colors.primaryColor};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .contain__inputCard-component{
    padding:20px:
  }
  
  
  
  .formScreen__title__text {
    font-weight: bold;
    font-size: ${newFontSize.h3};
    color: #22262e;
  }

  .section__icon {
    background-color: ${colors.blue};
    font-weight: 900;
    width: 20px;
    height: 20px;
  }

  .section__name {
    // font-weight: bold;
    ${'' /* width: 30px; */}
    color: ${colors.blue};
    // border-bottom: 3px solid ${colors.blue};
  }

  .section__container {
    ${'' /* margin-bottom: 5px; */}
      background-color: ${colors.primaryColorLight};
      
      span {
        color: ${colors.white};
      }
  }

  .container__title {
    box-sizing: border-box;
    width: 100%;
    min-height: 80px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid ${colors.greyBorder};

    .contain__title {
      color: ${colors.black};
      ${'' /* font-size: ${newFontSize.h3}; */}
      font-weight: ${weight.medium};
      letter-spacing: 0.25px;
    }
  }

  .button-back {
    width: 45px;
    height: 45px;
    border-radius: 15px;
    color: ${colors.blue};
  }

  .button-back:hover {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    color: white;
    background-color: ${colors.blue};
  }

  .header-title {
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .header-iconback {
    position: relative;
    left: 200px;
  }

  .hidden {
    visibility: hidden;
  }

  ${
    '' /* .modal__container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

.form__body{
  height: 100%;
    .container__cards{
      height: 100%;
    }}

    
  } */
  }

   .cards__header {
      ${'' /* margin-top:20px; */}
      width: 100%;
      display: flex;
      align-items: center;
    }

  .container_form {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-width: ${(props) => (props.maxwidth === undefined ? '800px' : props.maxwidth)};
    margin-top: 120px;
    margin-bottom: 50px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .container__cards {
      width: 100%;
      row-gap: 26px;
      height: fit-content;



      .contain__inputCard-component {
        display: grid;
        justify-self: center;
        width: 100%;
        
      }
    }

    .contain__button-save {
      height: 70px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 20px;
      

      button {
        height: 50px;
        width: 200px;
        border: none;
        color: ${colors.secondaryColor};
        ${'' /* font-size: ${newFontSize.button}; */}
        font-family: ${fontFamily.font};
        font-weight: ${weight.light};
        border-radius: 8px;
        font-weight: ${weight.bold};
      }

      .save_button {
        &:hover {
          cursor: pointer;
          color: ${colors.white};
          background-color: ${colors.blue};
        }
      }

      .save_button {
        &:hover {
          cursor: pointer;
          color: ${colors.white};
          background-color: ${colors.primaryColor};
        }
      }

      .next_step {
        &:hover {
          cursor: pointer;
          color: ${colors.white};
          background-color: ${colors.blue};
        }
      }
    }
  }
`;
