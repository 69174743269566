import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { formCreateApiKey } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { useLocation } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';

export const ApiScreenForm = ({ crudType }) => {
  const { createApi, settingsForm, getApi } = useSettings();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { typeSwal } = useSwal();
  // const [refresh, setRefresh] = useState(false);

  const data = formCreateApiKey;

  useEffect(() => {
    settingsForm(
      { action: 'clearForm' },
      {
        form: 'apiKey',
      }
    );
    // }, []);

    // useEffect(() => {
    // getApi();

    if (crudType === 'edit') {
      // setRefresh(true);
      // data.tabs.map((tab) => {

      settingsForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form: 'apiKey',
          element: 'name',
        }
      );

      // data.steps.map((item) => {
      //   item.input.map((item2) => {
      //     if (item2.carType === 'input') {
      //       settingsForm(
      //         {
      //           action: item2.cardType,
      //           value: item2.defaultValues,
      //         },
      //         {
      //           form: 'apiKey',
      //           element: item2.ref.element,
      //         }
      //       );
      //     }
      //   });
      // });
      // });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (crudType === 'edit' && (location.state?.domain === '' || location.state?.domain !== ApiScreenForm.domain)) {
        setLoadingCounter(loadingCounter + 1);
      } else if (crudType === 'edit') {
        data.steps.map((item) => {
          item.input.map((item2) => {
            switch (item2.ref.element) {
              case 'name':
                item2.defaultValues = location.state?.name;
                break;
              default:
                break;
            }
            return item2;
          });
          return item;
        });
        setLoading(false);
      } else if (crudType === 'create') {
        setLoading(false);
        data.steps.map((item) => {
          // itemTab.steps.map((item) => {
          item.input.map((item2) => {
            item2.defaultValues = '';
          });
          // });
        });
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = () => {
    if (crudType === 'create') {
      typeSwal('createLoader', '', settingsForm, getApi, `/${routesWide.settings}/${routesSettings.api}`, 'api', createApi);
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen arrScreen={['API']} onCancel={handleOnCancel} data={data} onSubmit={onSubmit} crudType={crudType} form={settingsForm} />}</>;
};

ApiScreenForm.propTypes = { crudType: string };
