import styled from 'styled-components';
// import { colors } from '../../../theme/colors';
// import { newFontSize, weight } from '../../../theme/fonts';

export const PermissionsWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  .permissions__screen {
    box-sizing: border-box;
    height: calc(100% - 55px);
    margin-top: 110px;
    width: 100%;
    margin-left: 65px;
  }
  .contain__cardGrid {
    height: 100%;
  }
`;
