import 'regenerator-runtime/runtime';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { dataDropzone } from '../../data/dataDropzone';

import { ContainData, MyDropzoneWrapper } from './MyDropzone.style';
import { bool, func, object } from 'prop-types';

// import { useVoices } from 'react-text-to-speech';
// // import { ToggleButton } from '../ToggleButton/ToggleButton';

// import { IconSpeakerphone, IconLanguage, IconCircleCheckFilled, IconMan, IconPlayerStop, IconBrandSoundcloud } from '@tabler/icons-react';
// import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
// import { SpeechSynthesisVoice } from 'microsoft-cognitiveservices-speech-sdk';

// import { IconBrandSpeedtest } from '@tabler/icons-react';
// import { IconPlayerPause } from '@tabler/icons-react';
// import { IconExclamationCircle } from '@tabler/icons-react';
import { VoiceSynthesizer } from '../VoiceSynthtetizer/VoiceSynthtetizer';
// import { IconCircleCheckFilled } from '@tabler/icons-react';
import { useStore } from 'react-redux';
import { IconCloudUpload } from '@tabler/icons-react';
// import AudioPlayer from '../AudioPlayer/AudioPlayer';
// import { IconDownload } from '@tabler/icons-react';
// import { IconLanguage } from '@tabler/icons-react';

export const MyDropzone = ({ item, inputValue, accept, setInputValue, form }) => {
  const { dropzone } = dataDropzone;

  const { getState } = useStore();
  const { objectCreateAPPFormLocutions } = getState();
  // const [isPaused, setIsPaused] = useState(false);
  // const [utterance, setUtterance] = useState(null);
  // const [textToRender, setTextToRender] = useState('Por favor, Escribe un texto para que lo convierta a audio...');
  // const [isPaused, setIsPaused] = useState(false);
  // const [utterance, setUtterance] = useState(null);
  // const [voice, setVoice] = useState(null);
  // // const [pitch, setPitch] = useState(1);
  // const [processError, setProcessError] = useState('');
  // const [volume, setVolume] = useState(1);
  // const [gender, setGender] = useState(1);

  // const key = '99bde9706401434c9412e07512989441';
  // const region = 'francecentral';

  // const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);
  // const [rate, setRate] = useState(1);
  // const [pitch, setPitch] = useState(1);
  // const [lang, setLang] = useState('');
  // const [voiceURI, setVoiceURI] = useState('');
  // // const [utterance, setUtterance] = useState(null);
  // // const [selectedSex, setSselectedSex] = useState('Hombre');

  // const { languages } = useVoices();

  // // let synthesizer = new sdk.SpeechSynthesizer(speechConfig);
  // const [arrayVoices, setArrayVoices] = useState([]);
  // // const [arrayVoices2, setArrayVoices2] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const arraySexos = ['Hombre', 'Mujer'];
  // const arrayPitch = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  const {
    getRootProps,
    getInputProps,
    // acceptedFiles,
    // fileRejections,
    //isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      handleDropzoneChange(acceptedFiles, item);
    },
  });

  // const [filteredVoices, setFilteredVoices] = useState([]);
  // const [selectedGender, setSelectedGender] = useState('All');
  // const [voices, setVoices] = useState([]);

  // const acceptedFileItems = acceptedFiles.map((file) => (
  //   <span key={file.path}>
  //     {file.path}
  //   </span>
  // ));

  // useEffect(() => {
  //   const synth = window.speechSynthesis;
  //   const u = new SpeechSynthesisUtterance(textToRender);

  //   u.onend = function () {
  //     setLoading(false);
  //   };
  //   // const newTye= new SpeechSynthesisVoice();
  //   // console.log(newTye)
  //   new sdk.SpeechSynthesizer(speechConfig).getVoices().then((itemArrayVoices) => {
  //     formatArrayVoices(itemArrayVoices.voices);
  //   });
  //   // synth.getVoices();
  //   // formatArrayVoices(arrayNewVoices);
  //   // setArrayVoices(itemArrayVoices.voices);
  //   // });
  //   // new sdk.SpeechSynthesizer(speechConfig).getVoices().then((itemArrayVoices) => {
  //   //   formatArrayVoices(itemArrayVoices.voices);
  //   //   // setArrayVoices(itemArrayVoices.voices);
  //   // });
  //   // const syntVoices = speechSynthesis.getVoices();
  //   // const newArrayVoices = syntVoices.getVoices();
  //   // .then((arrayVoic) => formatArrayVoices(arrayVoic));
  //   // .then((itemVoice) => {

  //   const newVoices = synth.getVoices();

  //   // });

  //   setUtterance(u);
  //   setVoice(newVoices[0]);
  //   // setArrayVoices(voices);

  //   return () => {
  //     synth.cancel();
  //   };
  // }, [textToRender]);

  // useEffect(() => {
  //   // const u = new SpeechSynthesisUtterance(textToRender);
  //   // setUtterance(u);
  //   synthesizer?.getVoices().then((itemVoice) => {
  //     formatArrayVoices(itemVoice.voices);
  //   });

  //   console.log(arrayVoices2.length);

  //   // const voicesSynth = window.speechSynthesis.getVoices();
  //   setArrayVoices2(arrayVoices);
  // }, []);
  // const synthesizer = new sdk.SpeechSynthesizer(speechConfig);

  // useEffect(() => {
  //   // Get subscription key and region from environment variables
  //   // const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.REACT_APP_SPEECH_KEY, process.env.REACT_APP_SPEECH_REGION);

  //   // Create speech synthesizer

  //   // Get available voices
  //   synthesizer
  //     .getVoicesAsync()
  //     .then((result) => {
  //       if (result.voices && result.voices.length > 0) {
  //         setVoices(result.voices);
  //         setFilteredVoices(result.voices);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error getting voices:', error);
  //     });

  //   // Cleanup
  //   return () => {
  //     synthesizer.close();
  //   };
  // }, []);

  // const filterVoicesByGender = (gender) => {
  //   setSelectedGender(gender);
  //   if (gender === 'All') {
  //     setFilteredVoices(voices);
  //   } else {
  //     const filtered = voices.filter((itemvoice) => itemvoice.gender === (gender === 'Female' ? 2 : 1));
  //     setFilteredVoices(filtered);
  //     setArrayVoices(filteredVoices);
  //   }
  // };
  // if (speechConfig) {
  //   speechConfig.speechSynthesisLanguage = lang;
  //   speechConfig.speechSynthesisVoiceName = voiceURI;
  //   speechConfig.speechSynthesisOutputFormat = 'audio-24khz-48kbitrate-mono-mp3';
  // }

  // const formatArrayVoices = (arrayToProcess) => {
  //   const newArrayVoices = [];
  //   // const oriArrayVoices = window.speechSynthesis.getVoices();

  //   (arrayToProcess.voices ? arrayToProcess.voices : arrayToProcess).forEach((itemVoice) => {
  //     const { locale, privLocalName, privName } = itemVoice;
  //     const newVoice = { ...voice };
  //     // // { displayName, gender: gender, voiceURI: name, name: name, lang: locale, localService: true, default: false };
  //     // //  lang: locale, gender, name, shortName, voiceURI: displayName, localService: locale
  //     // //
  //     newVoice.default = true;
  //     newVoice.localService = true;
  //     newVoice.lang = locale || itemVoice.lang;
  //     newVoice.voiceURI = privName || itemVoice.name;
  //     newVoice.name = privLocalName || itemVoice.voiceURI;
  //     newVoice.gender = itemVoice.gender || '';

  //     newArrayVoices.push(newVoice);
  //   });
  //   //  setArrayVoices(voices.voices);
  //   setArrayVoices(newArrayVoices);
  // };

  // Create the speech synthesizer.

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //     <span key={file.path}>
  //         {file.path}
  //         {/* - {file.size} bytes */}
  //         <span>
  //             {errors.map((e) => (
  //                 <span key={e.code}>{e.message}</span>
  //             ))}
  //         </span>
  //     </span>
  // ))
  // const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser not supported for speech recognition.</span>;
  // }

  const handleDropzoneChange = (file, item) => {
    const newFile = file[0] ? file[0] : file;

    setInputValue((prevState) => ({
      ...prevState,
      [item?.ref?.element]: newFile,
    }));
    let action = { action: 'dropzone', value: file[0] ? file[0] : file };
    form(action, item?.ref);
  };

  // const handleOnChange = (itemValue) => {
  //   // setVoiceURI(itemValue.target.value);
  //   if (!voice) {
  //     const newVoice = arrayVoices.find((itemVoice) => itemVoice.name === itemValue.target.value);
  //     newVoice ? setVoice(newVoice) : null;
  //   }
  //   setTextToRender(textToRender);
  //   // setText(item.target.value);
  // };

  // const handleSelectChange = (e) => {
  //   setSselectedSex(e.target.value);
  //   setPitch(e.target.value);
  // };
  // const handlePlay = () => {
  //   try {
  //     setLoading(true);
  //     setProcessError('');
  //     // const synth = window.speechSynthesis;

  //     // for (let i = 0; i < arrayVoices.length; i++) {
  //     //   if (arrayVoices[i].privDisplayName === voiceURI || arrayVoices[i].name === voiceURI) {
  //     //     // const newGender = newArrayVoices[i].getGender();
  //     //     // if (newGender)
  //     //     utterance.voice = arrayVoices[i];
  //     //   }
  //     // }

  //     if (isPaused) {
  //       synthesizer.resume();
  //     } else {
  //       utterance.pitch = pitch;
  //       utterance.lang = lang;
  //       utterance.rate = rate;
  //       utterance.volume = volume;
  //       utterance.voice = voice.displayName;
  //       synthesizer.speak(utterance);

  //       // setLoading(false);
  //     }
  //     // setLoading(false);
  //     setIsPaused(false);
  //   } catch (error) {
  //     setIsPaused(false);
  //     playStandard();
  //     setProcessError(error.message);
  //     // setLoading(false);
  //   }
  //   // setLoading(false);
  // };

  // const playStandard = () => {
  //   const synth = window.speechSynthesis;
  //   utterance.pitch = pitch;
  //   utterance.lang = lang;
  //   utterance.rate = rate;
  //   utterance.volume = volume;
  //   synth.speak(utterance);
  // };

  // const handlePause = () => {
  //   const synth = window.speechSynthesis;

  //   isPaused === false ? synth.pause() : synth.resume();
  //   setIsPaused(!isPaused);
  // };

  // const handleStop = () => {
  //   const synth = window.speechSynthesis;

  //   synth.cancel();

  //   setProcessError('');
  //   setIsPaused(false);
  //   setLoading(false);
  // };

  // const handleVoiceChange = (event) => {
  //   //  window.speechSynthesis.getVoices();
  //   const newVoice = arrayVoices.find((v) => v.name === event.target.value);

  //   setVoice(newVoice);
  //   setVoiceURI(event.target.value);
  //   handleOnChange(event);
  // };

  // const handlePitchChange = (event) => {
  //   setPitch(parseFloat(event.target.value));
  // };

  // const handleRateChange = (event) => {
  //   setRate(parseFloat(event.target.value));
  // };

  // const handleVolumeChange = (event) => {
  //   setVolume(parseFloat(event.target.value));
  // };

  const urlAudio =
    inputValue?.file && inputValue?.file[0] && inputValue?.file[0]?.name
      ? inputValue?.file[0].name
      : objectCreateAPPFormLocutions?.file?.label
        ? objectCreateAPPFormLocutions.file.label
        : inputValue?.file?.label
          ? inputValue?.file?.label
          : inputValue?.file?.name;

  return (
    <MyDropzoneWrapper isDragAccept={isDragAccept} isDragReject={isDragReject}>
      <ContainData>
        <div className="dropzone__file">
          <div className="dropzone__file__container">
            <span className="dropzone__item">
              {inputValue?.file && inputValue?.file[0] && inputValue?.file[0]?.name ? inputValue?.file[0]?.name : inputValue?.file?.label ? inputValue?.file?.label : inputValue?.file?.name}
            </span>

            {/* <IconCircleCheckFilled className={'icon__success'} /> */}
            {/* <span className="dropzone__success">{dropzone.textDropzoneSuccess}</span> */}
            {/* <span className="dropzone__item">{acceptedFileItems}</span> */}
            {/* <a href={'/uploads/mp3/' + urlAudio} download>
              <IconDownload className={'icon__download'} />
            </a> */}
            <div className="dropzone__file__download">
              <audio src={'/uploads/mp3/' + urlAudio} controls />
            </div>
          </div>
        </div>
        {objectCreateAPPFormLocutions.type === false || objectCreateAPPFormLocutions.type === 2 ? (
          <div className="contain__dropzone">
            {inputValue?.file?.label ? <p>{dropzone.textDropzone}</p> : <p>{dropzone.textDropzone}</p>}
            <div {...getRootProps({})} className={'presentation'}>
              <input {...getInputProps()} style={{ border: 'none', clip: 'unset', position: 'relative', top: '75px', left: '-350px' }} />
              <IconCloudUpload className={'icon__upload'} />
            </div>
          </div>
        ) : null}
        {objectCreateAPPFormLocutions.type === true ? <VoiceSynthesizer form={form} setInputValue={setInputValue} onDrop={handleDropzoneChange} /> : null}
      </ContainData>
    </MyDropzoneWrapper>
  );
};

MyDropzone.propTypes = { reducerForm: object, isDisabled: bool, item: object, inputValue: object, accept: object, setInputValue: func, form: func, reducerform: object };
