import React from 'react';
import { useState } from 'react';
import Select from 'react-select';

import { iconButton } from '../../../data/dataButtons';
import { colors } from '../../../theme/colors';
import { Button } from '../../Button/Button';

import { MenuFilterWrapper } from './MenuFilter.style';
import { array, func, object } from 'prop-types';

export const MenuFilter = ({ isFilter, data, filter, setIsFilter, setSearch }) => {
  const { filters } = filter;

  const [whichFilters, setWhichFilters] = useState({});

  {
    filters && data && data.length > 0 && filters.length > 0
      ? filters?.map((filter) =>
          data?.map((item) =>
            Object.keys(item).forEach((key) => {
              if (key === filter.ref) {
                if (!filter.options?.map((item2) => item2.prototype?.hasOwnProperty.call('label') && item2.label.includes(item[key])).includes(true)) {
                  filter.options.push({
                    label: item[key],
                    value: item[key],
                  });
                }
              }
            })
          )
        )
      : null;
  }

  function compareObjects() {
    return data.filter((item) => Object.entries(whichFilters).every(([key, value]) => item.Object.prototype.isPrototypeOf.call(key) && item[key] === value));
  }

  const handleSelectChange = (selectedOption, action, filter) => {
    if (action.action === 'clear') {
      let removeValue = action.removedValues[0].label;
      let newObject = Object.keys(whichFilters).reduce((result, key) => {
        if (whichFilters[key] !== removeValue) {
          result[key] = whichFilters[key];
        }
        return result;
      }, {});
      setWhichFilters(newObject);
    } else {
      setWhichFilters({
        ...whichFilters,
        [filter.ref]: selectedOption.value,
      });
    }
  };

  const handleSubmitFilter = () => {
    const result = compareObjects();
    if (result.length === 0) {
      setSearch([{ notFound: iconButton.search.notFound }]);
    } else {
      if (Object.keys(whichFilters).length === 0) {
        setSearch([]);
        setIsFilter({
          ...isFilter,
          open: false,
          active: false,
        });
      } else {
        setSearch(result);
        setIsFilter({
          ...isFilter,
          open: false,
          active: true,
        });
      }
    }
  };

  const handleCloseFilter = () => {
    setIsFilter({
      ...isFilter,
      open: false,
    });
  };

  return (
    <MenuFilterWrapper isFilter={isFilter?.open}>
      <div className={'menuFilter'}>
        <div className="menuFilter__header">
          <div className="menuFilter__title">
            <img src={iconButton?.filter.icon} alt={iconButton?.filter.alt} />
            <p>{iconButton?.filter.text}</p>
          </div>
          <div className="menuFilter__close" onClick={handleCloseFilter}>
            <img src={iconButton?.closed.icon} alt={iconButton?.closed.alt} />
          </div>
        </div>

        <div className="menuFilter__content">
          {filters && filters.length > 0
            ? filters.map(
                (filter, index) =>
                  filter.type === 'select' && (
                    <div key={index} className="menuFilter__content__select">
                      <p>{filter.label}</p>
                      <Select
                        options={filter.options}
                        onChange={(selectedOption, action) => handleSelectChange(selectedOption, action, filter)}
                        isClearable
                        placeholder={iconButton.filter.placeholder}
                      />
                    </div>
                  )
              )
            : null}
        </div>
        <div className="menuFilter__button">
          <Button
            backgroundColor={colors.green}
            colorText={colors.white}
            hoverBackgroundColor={colors.greyHover}
            width="100%"
            border={'0px'}
            text={iconButton.filter.textButton}
            hoverColorText={colors.grey}
            onClick={handleSubmitFilter}
          />
        </div>
      </div>
    </MenuFilterWrapper>
  );
};

MenuFilter.propTypes = { isFilter: object, data: array, filter: object, setIsFilter: func, setSearch: func };
