export const actionTypesOutboundRoutes = {
  //actions trunks
  getTrunks: 'GET_TRUNKS',
  createTrunks: 'CREATE_TRUNKS',
  updateTrunks: 'UPDATE_TRUNKS',
  deleteTrunks: 'DELETE_TRUNKS',

  //actions form trunks
  addTrunksFormHeadquarters: 'CHANGE_TRUNKS_FORM_HEADQUARTERS',
  removeTrunksFormHeadquarters: 'REMOVE_TRUNKS_FORM_HEADQUARTERS',
  clearTrunksForm: 'CLEAR_TRUNKS_FORM',
  changeTrunksFormId: 'CHANGE_TRUNKS_FORM_ID',
  changeTrunksFormName: 'CHANGE_TRUNKS_FORM_NAME',
  changeTrunksFormIpaddr: 'CHANGE_TRUNKS_FORM_IPADDR',
  changeTrunksFormPort: 'CHANGE_TRUNKS_FORM_PORT',
  changeTrunksFormContext: 'CHANGE_TRUNKS_FORM_CONTEXT',
  changeTrunksFormPermit: 'CHANGE_TRUNKS_FORM_PERMIT',
  changeTrunksFormDeny: 'CHANGE_TRUNKS_FORM_DENY',
  changeTrunksFormTransport: 'CHANGE_TRUNKS_FORM_TRANSPORT',
  changeTrunksFormNat: 'CHANGE_TRUNKS_FORM_NAT',
  changeTrunksFormDFMDMode: 'CHANGE_TRUNKS_FORM_DFMFMODE',
  changeTrunksFormAllow: 'CHANGE_TRUNKS_FORM_ALLOW',
  changeTrunksFormDisallow: 'CHANGE_TRUNKS_FORM_DISALLOW',
  changeTrunksFormQualify: 'CHANGE_TRUNKS_FORM_QUALIFY',
  changeOutboundRoutesTrunksFormRegisterServer: 'CHANGE_TRUNKS_REGSERVER',
  changeTrunksFormDescription: 'CHANGE_TRUNKS_FORM_DESCRIPTION',
  changeTrunksFormPassword: 'CHANGE_TRUNKS_FORM_PASSWORD',
  changeOutboundRoutesTrunksFormDomain: 'CHANGE_TRUNKS_FORM_DOMAIN',
  changeTrunksFormServer: 'CHANGE_TRUNKS_FORM_SERVER',
  changeOutboundRoutesTrunksFormUserDefault: 'CHANGE_TRUNKS_FORM_USER',
  changeTrunksFormPrefix: 'CHANGE_TRUNKS_FORM_PREFIX',
  changeTrunksFormRegister: 'CHANGE_TRUNKS_FORM_REGISTER',
  changeTrunksFormSipPeerId: 'CHANGE_TRUNKS_FORM_SIPPEERID',
  clearTrunksFormAllow: 'CLEAR_TRUNKS_FORM_ALLOW',
  clearTrunksFormDisallow: 'CLEAR_TRUNKS_FORM_DISALLOW',
  clearTrunksFormNat: 'CLEAR_TRUNKS_FORM_NAT',
  changeTrunksFormKamDispatcherId: 'CHANGE_TRUNKS_FORM_KAMDISPATCHERID',
  clearTrunksFormCompany: 'CLEAR_TRUNKS_FORM_COMPANY',
  clearTrunksFormTransport: 'CLEAR_TRUNKS_FORM_TRANSPORT',
  clearTrunksFormDFMDMode: 'CLEAR_TRUNKS_FORM_DFMFMODE',

  //categoriesLink
  getCategoriesLink: 'GET_CATEGORIES_LINK',

  //categoriesGroup
  getCategoriesGroup: 'GET_CATEGORIES_GROUP',
  changeCategoriesGroupFormName: 'CHANGE_CATEGORIES_GROUP_FORM_NAME',
  addCategoryFormGroup: 'ADD_CATEGORY_FORM_GROUP',
  removeCategoryFormGroup: 'REMOVE_CATEGORY_FORM_GROUP',
  clearCategoryFormGroup: 'CLEAR_CATEGORY_FORM_GROUP',
  changeCategoriesGroupFormId: 'CHANGE_CATEGORIES_GROUP_FORM_ID',
  clearMultiselectCategoriesGroupForm: 'CLEAR_MULTISELECT_CATEGORIES_GROUP_FORM',

  //categories
  getCategories: 'GET_CATEGORIES',

  //actions form categories
  changeCategoriesFormName: 'CHANGE_CATEGORIES_FORM_NAME',
  changeCategoriesFormId: 'CHANGE_CATEGORIES_FORM_ID',
  changeCategoriesFormPrefix: 'CHANGE_CATEGORIES_FORM_PREFIX',
  clearCategoriesForm: 'CLEAR_CATEGORIES_FORM',
  addCategoriesOutboundRouteForm: 'ADD_CATEGORIES_OUTBOUND_ROUTES_FORM',
  removeCategoriesOutboundRouteForm: 'REMOVE_CATEGORIES_OUTBOUND_ROUTES_FORM',
  addCategoriesOutboundRouteFailForm: 'ADD_CATEGORIES_OUTBOUND_ROUTES_FAIL_FORM',
  removeCategoriesOutboundRouteFailForm: 'REMOVE_CATEGORIES_OUTBOUND_ROUTES_FAIL_FORM',
  clearCategoriesOutboundRouteForm: 'CLEAR_CATEGORIES_OUTBOUND_ROUTES_FORM',
  clearCategoriesOutboundRouteFailForm: 'CLEAR_CATEGORIES_OUTBOUND_ROUTES_FAIL_FORM',
};
