import Axios from 'axios';

import { getCategoriesAction, getCategoriesGroupAction, getCategoriesLinkAction, getOutboundRoutesTrunksAction } from '../actions/outboundRoutes/actionCreators';

// const urlApi = import.meta.env.VITE_APP_URL;
const urlBack = import.meta.env.VITE_BACK_URL;

export const getOutboundRoutesGetTrunksThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const trunks = await Axios.get(`${urlBack}outboundRoutes/trunks`);
    dispatch(getOutboundRoutesTrunksAction(trunks.data));
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
  }
};

export const createOutboundRoutesTrunksThunk = (trunks, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlBack}outboundRoutes/trunks/create`, {
      data: trunks,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

//update only toggle register
export const updateOutboundRoutesTrunksRegisterThunk = (register, setLoading) => async () => {
  try {
    const response = await Axios.patch(`${urlBack}outboundRoutes/trunks/updateRegister`, {
      data: register,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

//update all fields
export const updateOutboundRoutesTrunksThunk = (trunks, setLoading) => async () => {
  try {
    const response = await Axios.patch(`${urlBack}outboundRoutes/trunks/update`, {
      data: trunks,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteOutboundRoutesTrunksThunk = (trunks, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}outboundRoutes/trunks/delete`, {
      data: trunks,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const uploadFileTrunksThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    var response = await Axios.post(`${urlBack}outboundRoutes/trunks/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

//categoriesGroup thunks
export const getCategoriesGroupAndLinkThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const categoriesGroup = await Axios.get(`${urlBack}outboundRoutes/categoriesGroup`);
    const categories = await Axios.get(`${urlBack}outboundRoutes/categoriesLink`);
    dispatch(getCategoriesLinkAction(categories.data));
    dispatch(getCategoriesGroupAction(categoriesGroup.data));
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
  }
};

export const createCategoriesGroupThunk = (categoriesGroup, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlBack}outboundRoutes/categoriesGroup/create`, {
      data: categoriesGroup,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteCategoriesGroupThunk = (categoriesGroup, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}outboundRoutes/categoriesGroup/delete`, {
      data: categoriesGroup,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateCategoriesGroupThunk = (categoriesGroup, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(`${urlBack}outboundRoutes/categoriesGroup/update`, {
      data: categoriesGroup,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

//categories thunks

export const getCategoriesThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const categories = await Axios.get(`${urlBack}outboundRoutes/categories`);
    dispatch(getCategoriesAction(categories.data));
    setLoading(false);
  } catch (error) {
    return error;
  }
};

export const createCategoriesThunk = (newCategory, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlBack}outboundRoutes/categories/create`, {
      data: newCategory,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateCategoryThunk = (category, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(`${urlBack}outboundRoutes/categories/update`, {
      data: category,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteCategoryThunk = (category, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}outboundRoutes/categories/delete`, {
      data: category,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const uploadFileCategoryThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}outboundRoutes/categories/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};
