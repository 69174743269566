import React from 'react';
import { ProductivityWrapper } from './ProductivityScreen.style';
import { dataProductivityGraphs } from '../../../data/dataCallRegister';
import LineCharts from '../../../components/Graphs/Charts';
import BarCharts from '../../../components/Graphs/BarCharts';
import RadialCharts from '../../../components/Graphs/RadialCharts';
import PieCharts from '../../../components/Graphs/PieCharts';

const ProductivityScreen = () => {
  const dataLine = [
    { name: '08:00', atendidas: 320, entrantes: 100 },
    { name: '10:00', atendidas: 100, entrantes: 100 },
    { name: '12:00', atendidas: 200, entrantes: 220 },
    { name: '14:00', atendidas: 250, entrantes: 190 },
    { name: '16:00', atendidas: 140, entrantes: 320 },
    { name: '18:00', atendidas: 430, entrantes: 132 },
    { name: '20:00', atendidas: 290, entrantes: 140 },
  ];

  const dataKeys = [
    { dataKey: 'atendidas', color: '#3B8EDB' },
    { dataKey: 'entrantes', color: '#8977F1' },
  ];

  const dataBar = [
    { name: 'IVR', num: 140, color: '#7357F6' },
    { name: 'No disp', num: 63, color: '#7F40A6' },
    { name: 'Sin respuesta', num: 120, color: '#97DFD8' },
    { name: 'Colgado', num: 90, color: '#fb9dda' },
  ];

  const dataBar2 = [
    { name: 'IVR', num: 90, color: '#7357F6' },
    { name: 'No disp', num: 163, color: '#7F40A6' },
    { name: 'Sin respuesta', num: 120, color: '#71A39E' },
    { name: 'Colgado', num: 290, color: '#fb9dda' },
  ];

  const dataRadial = [
    { name: 'llamadas totales', respondidas: 100, not: 21, color: '#fb9dda' },
    { name: 'respondidas', respondidas: 82, not: 100, color: '#7F40A6' },
    { name: 'sin responder', respondidas: 54, not: 21, color: '#4E99E0' },
  ];

  const dataPie = [
    { name: 'Muy Satisfecho', num: 100, color: '#7357F6' },
    { name: 'Satisfecho', num: 313, color: '#fb9dda' },
    { name: 'Indiferente', num: 300, color: '#669D98' },
    { name: 'Insatisfecho', num: 400, color: '#7F40A6' },
    { name: 'Muy Insatisfecho', num: 231, color: '#4E81B1' },
  ];

  return (
    <ProductivityWrapper className="productivity-screen">
      <div className="selector">Día</div>

      <div className="grid">
        <div className="main_chart border">
          <LineCharts data={dataLine} dataKeys={dataKeys} />
        </div>
        <div className="lateral_pie border">
          <RadialCharts data={dataRadial} />
        </div>
        <div className="lateral_bar border">
          <h3> {dataProductivityGraphs.lateralGraph.barChart.title} </h3>
          <div className="chart_contain bar">
            <BarCharts data={dataBar} />
          </div>
        </div>
        <div className="bottom_status border">
          <h3> {dataProductivityGraphs.bottomGraph.status.title} </h3>
          <div className="chart_contain bar">
            <BarCharts data={dataBar2} />
          </div>
        </div>
        <div className="bottom_pie border">
          <h3> {dataProductivityGraphs.bottomGraph.pieChart.title} </h3>
          <div className="chart_contain bar">
            <PieCharts data={dataPie} pieProps={{ innerRadius: '0%', layout: 'horizontal', verticalAlign: 'bottom', align: 'center', width: '300px' }} wrapperStyle={{ lineHeight: '24px' }} />
          </div>
        </div>
        <div className="first_lateral_stat shadow text_card">
          <h5> {dataProductivityGraphs.lateralGraph.bottomgraphs.left.title} </h5>
          <h2> {dataProductivityGraphs.lateralGraph.bottomgraphs.left.text} </h2>
        </div>
        <div className="second_lateral_stat shadow text_card">
          <h5> {dataProductivityGraphs.lateralGraph.bottomgraphs.right.title} </h5>
          <h2> {dataProductivityGraphs.lateralGraph.bottomgraphs.right.text} </h2>
        </div>
      </div>
    </ProductivityWrapper>
  );
};

export default ProductivityScreen;
