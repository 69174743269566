import Axios from 'axios';

import {
  createApplicationsLocutions,
  createConferenceAction,
  deleteAPPLocutions,
  deleteConferenceAction,
  getAudios,
  getConferencesAction,
  getDestinationAction,
  getIvrAction,
  getLocutionsAction,
  getMailboxesAction,
  getQueueAction,
  getShortMarkingAction,
  getSongWaitingAction,
  getSongWaitingLinkAction,
  updateConferenceAction,
} from '../actions/applications/actionCreators';

// const urlApi = import.meta.env.VITE_APP_URL;
const urlBack = import.meta.env.VITE_BACK_URL;
const token = import.meta.env.VITE_APP_TOKEN;
const isDebug = import.meta.env.VITE_DEBUG;

export const getApplicationsLocutionsThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const locutions = await Axios.get(`${urlBack}applications/locutions`, {
      headers: {
        Authorization: token,
      },
    });
    const response = await Axios.get(`${urlBack}applications/locutions/getAudios`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getLocutionsAction(locutions.data));
    dispatch(getAudios(response.data.files));
    setLoading(false);
  } catch (error) {
    setLoading(false);
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    return error;
  }
};

export const createApplicationsLocutionsThunk = (locution, setLoading) => async (dispatch) => {
  setLoading(true);
  const data = new FormData();
  data.append('file', locution.file[0]);
  data.append('locutionName', locution.locutionName);
  data.append('headquarter', +locution.headquarters.value);
  data.append('fileName', locution.file[0].path.replace(/\s+/g, '_'));
  try {
    const response = await Axios.post(`${urlBack}applications/locutions/create`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    if (response.status === 201) {
      dispatch(createApplicationsLocutions(locution));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateApplicationsLocutionsThunk = (locution, setLoading) => async (dispatch) => {
  setLoading(true);

  if (locution.file[0] !== undefined) {
    const data = new FormData();

    data.append('file', locution.file[0]);
    data.append('locutionName', locution.locutionName);
    data.append('headquarter', +locution.headquarters.value);
    data.append('fileName', locution.file[0].path.replace(/\s+/g, '_'));
    data.append('id', locution.id);
    try {
      const response = await Axios.patch(`${urlBack}applications/locutions/edit`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
      if (response.status === 201) {
        dispatch(createApplicationsLocutions(locution));
      }
      setLoading(false);
      return response;
    } catch (error) {
      isDebug === 'true' ? console.log('Error: ' + error.message) : '';
      setLoading(false);
      return error;
    }
  } else {
    try {
      const response = await Axios.patch(`${urlBack}applications/locutions/editNoFile`, locution, { headers: { Authorization: token } });
      if (response.status === 201) {
        dispatch(createApplicationsLocutions(locution));
      }
      setLoading(false);
      return response;
    } catch (error) {
      isDebug === 'true' ? console.log('Error: ' + error.message) : '';
      setLoading(false);
      return error;
    }
  }
};

export const getAudioLocutionsThunk = (setLoading) => async (dispatch) => {
  try {
    const response = await Axios.get(`${urlBack}applications/locutions/getAudios`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getAudios(response.data.files));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteAPPLocutionsThunk = (locution, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.delete(
      `${urlBack}applications/locutions/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: locution,
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteAPPLocutions(locution));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const uploadFileLocutionsThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}applications/locutions/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getConferencesThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/conferences`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getConferencesAction(response.data));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const createConferenceThunk = (conference, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}applications/conferences/create`,
      {
        data: conference,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createConferenceAction(conference));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateConferenceThunk = (conference, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}applications/conferences/edit`,
      {
        data: conference,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateConferenceAction(conference));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteConferenceThunk = (conference, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.delete(
      `${urlBack}applications/conferences/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: conference,
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteConferenceAction(conference));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const uploadFileConferenceThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}applications/conferences/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getMailboxesThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/mailboxes`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getMailboxesAction(response.data));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const createMailboxesThunk = (mailboxes, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}applications/mailboxes/create`,
      {
        data: mailboxes,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createConferenceAction(mailboxes));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateMailboxesThunk = (mailboxes, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}applications/mailboxes/edit`,
      {
        data: mailboxes,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateConferenceAction(mailboxes));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteMailboxesThunk = (mailboxes, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.delete(
      `${urlBack}applications/mailboxes/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: mailboxes,
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteConferenceAction(mailboxes));
    }
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const uploadFileMailboxesThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}applications/mailboxes/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getSongWaitingThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/songWaiting`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getSongWaitingAction(response.data));
  } catch (error) {
    if (isDebug === 'true') console.log('Error: ' + error.message);
    return error;
  } finally {
    setLoading(false);
  }
};

export const getSongWaitingLinkThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const songWaitingLink = await Axios.get(`${urlBack}applications/getSongWaitingLink`, {
      headers: {
        Authorization: token,
      },
    });
    const songWaiting = await Axios.get(`${urlBack}applications/songWaiting`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getSongWaitingAction(songWaiting.data));
    dispatch(getSongWaitingLinkAction(songWaitingLink.data));
  } catch (error) {
    if (isDebug === 'true') console.log('Error: ' + error.message);

    return error;
  } finally {
    setLoading(false);
  }
};

export const createSongWaitingThunk = (songWaiting, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}applications/songWaiting/create`,
      {
        data: songWaiting,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateSongWaitingThunk = (songWaiting, setLoading) => async () => {
  try {
    const response = await Axios.patch(
      `${urlBack}applications/songWaiting/edit`,
      {
        data: songWaiting,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteSongWaitingThunk = (songWaiting, setLoading) => async () => {
  setLoading(true);
  try {
    var response = await Axios.delete(
      `${urlBack}applications/songWaiting/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: songWaiting,
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const uploadFileSongWaitingThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}applications/songWaiting/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getShortMarkingThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/shortMarking`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getShortMarkingAction(response.data));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const createShortMarkingThunk = (shortMarking, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}applications/shortMarking/create`,
      {
        data: shortMarking,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateShortMarkingThunk = (shortMarking, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}applications/shortMarking/edit`,
      {
        data: shortMarking,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteShortMarkingThunk = (shortMarking, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(
      `${urlBack}applications/shortMarking/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: shortMarking,
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const uploadFileShortMarkingThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}applications/shortMarking/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getIvrsThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/ivr`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getIvrAction(response.data));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const createIvrThunk = (ivr, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}applications/ivr/create`,
      {
        data: ivr,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateIvrThunk = (ivr, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}applications/ivr/edit`,
      { ivr },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteIvrThunk = (ivr, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(
      `${urlBack}applications/ivr/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: ivr,
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getDestinationThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/destination`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getDestinationAction(response.data));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const getQueueThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlBack}applications/queues`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getQueueAction(response.data));
    setLoading(false);
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const createQueueThunk = (queue, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}applications/queues/create`,
      {
        data: queue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const updateQueueThunk = (queue, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}applications/queues/edit`,
      {
        data: queue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const deleteQueueThunk = (queue, setLoading) => async () => {
  setLoading(true);
  try {
    var response = await Axios.delete(
      `${urlBack}applications/queues/delete`,

      {
        headers: {
          Authorization: token,
        },
        data: queue,
      }
    );

    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};

export const uploadFileQueueThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}applications/queue/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });

    setLoading(false);
    return response;
  } catch (error) {
    isDebug === 'true' ? console.log('Error: ' + error.message) : '';
    setLoading(false);
    return error;
  }
};
