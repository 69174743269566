import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

import usePreferences from '../../hooks/usePreferences';
import { array, func, number, string } from 'prop-types';

const DayWrapper = styled.button`
  width: 25px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background: ${(props) => (props.selected ? props.color : 'transparent')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  //transition: all 0.1s ease;

  &:hover {
    background: ${(props) => (props.selected ? 'grey' : props.color)};
    color: ${(props) => (props.selected ? 'white' : 'black')};
  }
`;

const Day = ({ day, month, year, selectedDays, setSelectedDays, crudType }) => {
  const [selected, setSelected] = useState(false);
  const [colorSelected, setColorSelected] = useState();

  const { preferencesForm } = usePreferences();

  useEffect(() => {
    if (selectedDays.length === 1) {
      setColorSelected(selectedDays[0].color);

      if (selectedDays[0].days.includes(`${day}-${month}-${year}`)) setSelected(true);
      else setSelected(false);
    } else
      selectedDays.forEach((rangeDay) => {
        if (rangeDay.days.includes(`${day}-${month}-${year}`)) {
          setSelected(true);
          setColorSelected(rangeDay.color);
        }
      });
  }, [selectedDays]);

  const onClick = (event) => {
    event.preventDefault();

    if (crudType === 'create' || crudType === 'edit') {
      const dayId = `${day}-${month}-${year}`;

      const newSelectedDays = selectedDays.map((rangeDay) => {
        if (rangeDay.color === colorSelected) {
          if (rangeDay.days.includes(dayId))
            return {
              name: rangeDay.name,
              color: rangeDay.color,
              days: rangeDay.days.filter((day) => day !== dayId),
            };
          else
            return {
              name: rangeDay.name,
              color: rangeDay.color,
              days: [...rangeDay.days, dayId],
            };
        } else return rangeDay;
      });

      const sortedSelectedDays = newSelectedDays.map((rangeDay) => {
        if (rangeDay.color === colorSelected) {
          return {
            name: rangeDay.name,
            color: rangeDay.color,
            days: rangeDay.days.sort((a, b) => {
              const dayA = a.split('-')[0];
              const monthA = a.split('-')[1];
              const yearA = a.split('-')[2];

              const dayB = b.split('-')[0];
              const monthB = b.split('-')[1];
              const yearB = b.split('-')[2];

              if (yearA > yearB) return 1;
              else if (yearA < yearB) return -1;
              else {
                if (monthA > monthB) return 1;
                else if (monthA < monthB) return -1;
                else {
                  if (dayA > dayB) return 1;
                  else if (dayA < dayB) return -1;
                  else return 0;
                }
              }
            }),
          };
        } else return rangeDay;
      });

      setSelectedDays(sortedSelectedDays);
      preferencesForm({ action: 'input', value: sortedSelectedDays[0].days }, { form: 'calendar', element: 'days' });
      setSelected(!selected);
    }
  };

  return (
    <DayWrapper selected={selected} color={colorSelected} onClick={(event) => onClick(event)}>
      {day}
    </DayWrapper>
  );
};

Day.propTypes = { day: number, month: number, year: number, selectedDays: array, setSelectedDays: func, crudType: string };

export default Day;
