import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const EmergencyWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${colors.backgroundColor};

  .contain__inputCard-component {
    min-height: 362px;
  }

  .contain__statistics {
    table thead tr {
      width: auto;
      top: unset;
    }
  }
`;
