import styled from 'styled-components';

export const PlantillaScreenWrapper = styled.div`
  ${'' /* height: 100%; */}
  display: flex;
  flex-direction: column;
  left: 70px;
  ${'' /* padding: 10px; */}
  width: 100%;

  .navtable__container {
    top: 120px;
  }
`;
