import styled from 'styled-components';
// import { colors } from '../../theme/colors.js';

export const CallRegiserScreenWrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  ${'' /* padding-top: 50px; */}
  ${'' /* padding-left: 10px; */}
  ${'' /* background-color: ${colors.primaryColor}; */}
`;
