import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes, { bool, func, object } from 'prop-types';
import { useStore } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PermissionProvider from '../Permission/PermissionProvider';
import Restricted from '../Permission/PermissionConsumer';
import { useDispatch } from 'react-redux';
import { NavMenuWrapper } from './NavMenuLeft.style';
import { isMenuOpenActionCreator, isModalOpenActionCreator, isSubMenuOpenActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { menuApplications } from '../../data/dataApplications';
import { menuEM } from '../../data/dataEM';
import { menuNumbering } from '../../data/dataNumbering';
import { menuCallRegister } from '../../data/dataCallRegister';
import { menuOutbound } from '../../data/dataOutbound';
import { menuSettings } from '../../data/dataSettings';
import { useAppSelector } from '../../redux/hooks/hooks';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
// import { Modal } from '../Modal/Modal';

export const NavMenuLeft = ({ menu }) => {
  const { menuTop } = menu;
  const { getState } = useStore();

  const dispatch = useDispatch();
  const { isMenuOpen, isModalOpen, isSubMenuOpen } = useAppSelector((state) => state.system);
  const userData = useAppSelector((state) => state.login.user);

  const [, setMenu] = useState([]);
  const [itemClicked, setItemClicked] = useState(false);
  const [subMenu, setSubMenu] = useState([]);
  const [menuType, setMenuType] = useState('');
  const [menuTypeSP, setMenuTypeSP] = useState('');

  const urLocation = window.location.pathname;

  const [urlMainSectionSelected, setUrlMainSectionSelected] = useState(urLocation.split('/')[1]);
  const [, setUrlOptionSelected] = useState(urLocation.split('/')[2]) || '';

  const permissions = getState()['arrayUsersPermission'];
  const titlePermissionsUser = permissions.map((item) => item.title);

  const openMenuOptions = (item) => {
    const newMenuType = item.currentTarget.id;
    if (isSubMenuOpen) {
      dispatch(isSubMenuOpenActionCreator(false));
      setItemClicked(false);
    } else {
      setItemClicked(!itemClicked);
    }

    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : null;

    let newMenuTypeTranslated = '';
    let newMenuTypeTranslatedSP = '';

    switch (newMenuType) {
      case 'Aplicaciones':
        newMenuTypeTranslated = 'applications';
        newMenuTypeTranslatedSP = newMenuType;
        setMenu(menuApplications);
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'applications':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Aplicaciones';
        setMenu(menuApplications);
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Emergency manager':
        newMenuTypeTranslated = 'EM';
        newMenuTypeTranslatedSP = newMenuType;
        setMenu(menuEM);
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'EM':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Emergency manager';
        setMenu(menuEM);
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Dispositivos':
        newMenuTypeTranslated = 'numbering';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'numbering':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Dispositivos';
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Llamadas':
        newMenuTypeTranslated = 'cdr';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'cdr':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Llamadas';
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Configuración':
        newMenuTypeTranslated = 'settings';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'settings':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Configuración';
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Rutas de salida':
        newMenuTypeTranslated = 'outboundRoutes';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'outboundRoutes':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Rutas de salida';
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Usuarios':
        newMenuTypeTranslated = 'users';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'webphone':
        newMenuTypeTranslated = 'webphone';
        newMenuTypeTranslatedSP = newMenuType;
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'users':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Usuarios';
        dispatch(isSubMenuOpenActionCreator(true));
        break;
      case 'Dashboard':
        newMenuTypeTranslated = 'dashboard';
        newMenuTypeTranslatedSP = newMenuType;
        break;
      case 'dashboard':
        newMenuTypeTranslated = newMenuType;
        newMenuTypeTranslatedSP = 'Dashboard';
        break;
      default:
        newMenuTypeTranslated = newMenuType || menuType;
        break;
    }

    setMenuType(newMenuTypeTranslated);
    setMenuTypeSP(newMenuTypeTranslatedSP);

    getSubMenu(newMenuTypeTranslated, window.location.pathname.split('/')[2]);
  };

  const handleOpenMenu = () => {
    dispatch(isMenuOpenActionCreator(!isMenuOpen));
  };

  const openSubMenuOptions = (itemSubOption) => {
    setUrlOptionSelected(itemSubOption.currentTarget.innerText);

    !isMenuOpen ? dispatch(isMenuOpenActionCreator(false)) : null;
    dispatch(isSubMenuOpenActionCreator(false));
    // getSubMenu(urlMainSectionSelected);
    getSubMenu(urlMainSectionSelected, window.location.pathname.split('/')[2] ? window.location.pathname.split('/')[2] : window.location.pathname.split('/')[1]);

    setSubMenu([]);

    setUrlOptionSelected(itemSubOption.currentTarget.innerText || urLocation.split('/')[2]);
    getSubMenu(urlMainSectionSelected, urLocation.split('/')[2] ? urLocation.split('/')[2] : urLocation.split('/')[1]);
    setItemClicked(true);
  };

  const isMenuPref = menuTop === 'Preferencias';
  const classPreffix = isMenuPref ? 'menupref__' : '';

  const getSubMenu = (parentMenu, menuOption) => {
    let arraySubMenu = [];

    setUrlMainSectionSelected(parentMenu);
    setUrlOptionSelected(menuOption ? menuOption : '');
    setMenuType(parentMenu);

    switch (parentMenu) {
      case 'applications':
        arraySubMenu = menuApplications;
        break;
      case 'EM':
        arraySubMenu = menuEM;
        break;
      case 'numbering':
        arraySubMenu = menuNumbering;
        break;
      case 'cdr':
        arraySubMenu = menuCallRegister;
        break;
      case 'settings':
        arraySubMenu = menuSettings;
        break;
      case 'outboundRoutes':
        arraySubMenu = menuOutbound;
        break;
      case 'webphone':
        arraySubMenu = menuOutbound;
        break;
      default:
        arraySubMenu = [];
        break;
    }

    const result = arraySubMenu?.map((item, index) =>
      item.restricted === true ? (
        <Restricted key={index} to={parentMenu !== 'applications' && parentMenu !== 'numbering' ? parentMenu + '/' + item.restricted : null}>
          <div onClick={openSubMenuOptions} id={item.id} className={item.path === urLocation.split('/')[2] ? classPreffix + 'container__link-active' : classPreffix + 'container__link'}>
            <div className={item.restricted === (urLocation.split('/')[2] ? urLocation.split('/')[2] : urLocation.split('/')[1]) ? classPreffix + 'link-active' : classPreffix + 'item-link'}>
              <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={classPreffix + 'menuTop__icon'} />
              {item.text}
            </div>
          </div>
        </Restricted>
      ) : (
        <Link key={index} to={parentMenu + '/' + item.path} replace>
          <div onClick={openSubMenuOptions} id={item.id} className={item.path === urLocation.split('/')[2] ? 'container__link-active link-active' : 'container__link item-link'}>
            <div className={item.path === urLocation.split('/')[2] ? 'link-active' : 'item-link'}>
              <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={'menuTop__icon'} />
              {item.text}
            </div>
          </div>
        </Link>
      )
    );

    setSubMenu(
      <div className="item__options__container">
        {isMenuOpen ? <span className={'submenu__title'}>{menuTypeSP}</span> : null} {result}
      </div>
    );
  };
  // const { isLocationModified } = useSelector((state) => state.system);

  // const [isLostMode] = useState(false);
  // const [isFormDirty, setIsFormDirty] = useState(isLocationModified);

  useEffect(() => {
    getSubMenu(urlMainSectionSelected, urLocation.split('/')[1] ? urLocation.split('/')[1] : urLocation.split('/')[2]);
  }, [urlMainSectionSelected, urLocation]);

  return (
    <PermissionProvider permissionsUser={titlePermissionsUser}>
      <NavMenuWrapper isMenuOpen={isMenuOpen} className="contain__navLeft">
        {/* <Modal show={!isLostMode && isFormDirty} isDirty={true} type="isDirty" setIsFormDirty={setIsFormDirty} /> */}
        <div className="container__top">
          {menuTop.map((itemMenu, index) =>
            itemMenu.text === 'Divider' ? (
              <div key={index} className="item__divider" />
            ) : itemMenu.text === 'Dashboard' || itemMenu.text === 'Usuarios' ? (
              <Restricted key={index} to={itemMenu.restricted}>
                <Link to={'../' + (itemMenu.text === 'Usuarios' ? 'users' : itemMenu.text)} className={itemMenu.text === 'Configuración' ? 'navleft-link__settings' : 'navleft-link'}>
                  <div onClick={openMenuOptions} className={itemMenu.path === urlMainSectionSelected ? 'item__active' : 'item__contain'} id={itemMenu.path}>
                    <itemMenu.image className="item__image" height={'40px'} />
                    {isMenuOpen ? <p className="item__text">{itemMenu.text}</p> : null}
                  </div>
                </Link>
                {isSubMenuOpen && !itemClicked && menuType !== 'users' ? subMenu : null}
              </Restricted>
            ) : (
              <Restricted key={index} to={itemMenu.restricted}>
                <div onClick={openMenuOptions} className={itemMenu.path === urlMainSectionSelected ? 'item__active' : 'item__contain'} id={itemMenu.path}>
                  <itemMenu.image className="item__image" height={'40px'} />
                  {isMenuOpen ? <p className="item__text">{itemMenu.text}</p> : null}
                </div>

                {isSubMenuOpen && !itemClicked && menuType !== 'users' ? subMenu : null}
              </Restricted>
            )
          )}
        </div>
        <div onClick={handleOpenMenu} className={classPreffix + 'openMenu__container'}>
          {!isMenuOpen ? <IconArrowRight className={classPreffix + 'icon_openMenu'} /> : <IconArrowLeft className={classPreffix + 'icon_openMenu'} />}
        </div>

        {!subMenu ? (
          <>
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#menu" place="bottom" content="Ver / Ocultar menú lateral" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#pin" place="bottom" content="Anclar / Desanclar menú lateral" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#dashboard" place="right" content="Dashboard" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#users" place="right" content="Usuarios" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#numbering" place="right" content="Numeraciones" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#applications" place="right" content="Aplicaciones" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#cdr" place="right" content="Llamadas" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#outboundRoutes" place="right" content="Rutas de salida" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#preferences" place="right" content="Configuración de usuario" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#EM" place="right" content="Emergency Manager" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#settings" place="right" content="Configuración" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#logout-button" place="bottom-start" content="Cerrar sesión" delayShow={200} />
            <ReactTooltip className="tooltip" globalCloseEvents={['clickOutsideAnchor']} anchorSelect="#user-button" place="bottom-start" content={userData.email} delayShow={200} />
          </>
        ) : null}
      </NavMenuWrapper>
    </PermissionProvider>
  );
};

NavMenuLeft.propTypes = {
  menu: PropTypes.object.isRequired,
  isClosed: bool,
  setIsClosed: func,
  user: object,
  reducerForm: object,
};
