import styled from 'styled-components';
import { colors } from '../../../theme/colors.js';

export const ConfigurationAsteriskScreenWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  ${'' /* padding-bottom: 90px; */}
  overflow: auto;

  ${
    '' /* .pageScreen__form {
    width: 80%;
  } */
  }
  #isTeams__0, #isRainbow__3,#isRainbow__3 {
    width: 100px;
  }
  .pageScreen__tabs {
    position: relative;
    top: 95px;
    width: 85%;
    max-width: 1024px;
  }

  .form__body__prefs {
    width: 90%;
    display: grid;
    margin: auto;
    gap: 50px;
    padding-bottom: 20px;
    margin: auto;
    margin-bottom: 70px;
    max-width: 1024px;
    .stepForm__radio {
      gap: 10px;
      padding: 10px;
      grid-row-start: 4;
      grid-column-start: 1;
      grid-column-end: 5;

      .contain__items-radio div {
        background-color: unset;
        color: white;
        width: 100%;
      }
    }
    .form__title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-left: 55px;
      text-align: left;
      color: ${colors.primaryColorLight};
      font-weight: bold;
      position: fixed;
      top: 0px;
      z-index: 550;

      .title__Form_title {
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 15px;
      }
    }

    .container__cards {
      width: 100%;

      .contain__inputCard-component {
        ${'' /* padding-top: 80px; */}
        background-color: ${colors.primaryColorLight};
        border-radius: 0 15px 15px 15px;

        .stepForm__contain-typeInput {
          display: grid;
          margin: auto;
          column-gap: 20px;
          row-gap: 20px;
          padding-top: 40px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          border-radius: 0 15px 15px 15px;
          padding: 40px;
        }
      }
    }

    #isRainbow__3 {
      grid-row-start: 2;
    }

    #Rainbow_URL__5 {
      grid-row-start: 2;
      grid-column-start: 2;
    }

    #teamsTroncalId__1 {
      grid-row-start: 1;
      grid-column-start: 3;
      grid-column-end: 5;
    }
    #rainbowTroncalId__4 {
      grid-row-start: 2;
      grid-column-start: 3;
      grid-column-end: 5;
    }
    #ipLocal__0 {
      grid-row-start: 1;
      grid-column-start: 2;
      ${'' /* grid-column-end: 3; */}
    }

    #domain__1 {
      grid-row-start: 1;
      grid-column-start: 3;
      ${'' /* grid-column-end: 5; */}
    }

    #portWss__2 {
      grid-row-start: 1;
      grid-column-start: 4;
    }

    #pathWss__3 {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;

    &__drop {
      display: flex;
    }
  }
`;
