import Axios from 'axios';

import { getCompanyLinkAction, getHeadquarterAction } from '../actions/headquarter/actionCreator';

// const urlApi = import.meta.env.VITE_APP_URL;
const urlBack = import.meta.env.VITE_BACK_URL;

export const getHeadquarterThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const headquarter = await Axios.get(`${urlBack}headquarters/headquarters`);
    const companyLink = await Axios.get(`${urlBack}headquarters/companyLink`);
    dispatch(getCompanyLinkAction(companyLink.data));
    dispatch(getHeadquarterAction(headquarter.data));
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
  }
};

export const getCompanyLinkThunk = (setLoading) => async (dispatch) => {
  try {
    const companyLink = await Axios.get(`${urlBack}headquarters/companyLink`);
    dispatch(getCompanyLinkAction(companyLink.data));
    setLoading(false);
  } catch (err) {
    setLoading(false);
    return err;
  }
};

export const createCompanyThunk = (company, setLoading) => async () => {
  try {
    const response = await Axios.post(`${urlBack}headquarters/headquarters/create`, { data: company });

    setLoading(false);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCompanyThunk = (company, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(`${urlBack}headquarters/headquarters/update`, { data: company });

    setLoading(false);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCompanyThunk = (company, setLoading) => async () => {
  setLoading(true);
  company.refColumn = 'companyId';
  try {
    var response = await Axios.delete(
      `${urlBack}headquarters/headquarters/delete`,

      { data: company }
    );
    return response;
  } catch (error) {
    return error;
  }
};
