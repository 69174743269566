import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const PageScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  ${'' /* background-color: ${colors.primaryColor}; */}

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .pageScreen__title {
    position: fixed;
    background-color: white;
    left: 13%;
    box-sizing: border-box;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      background-color: white;
      margin-left: 10px;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.bold};
      letter-spacing: 0.25px;
    }
  }

  .icon__dispositivo {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 5px;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
  }

  .modal__form__container {
    width: 100%;
    height: 100%;
    position: fixed;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: ${colors.primaryColorLight};
    left: 0;
    top: 0;
    z-index: 9;
    opacity: 0.9;

    .modal__form__body {
      height: auto;
      width: 80%;
      max-width: 650px;
      position: absolute;
      top: 200px;
      display: flex;
      justify-content: start;
      align-items: center;
      opacity: 1;
      background-color: ${colors.backgroundColor};
      margin: auto;
      border-radius: 10px;

      .form__container {
        width: 100%;
      }

      .stepForm__contain-typeInput {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
      .container__cards {
        width: 100%;

        .contain__inputCard-component {
          width: 80%;
          margin: auto;
        }

        .form__title {
          padding-top: unset;
          background-color: ${colors.primaryColor};
          color: ${colors.white};
          font-weight: bold;

          .title__Form_title {
            width: 100%;
            justify-content: start;
            color: ${colors.white};
          }

          ${
            '' /* .icon__Form_title1 {
              width: 30px;
              height: 30px;
              stroke: ${colors.backgroundColor};
            } */
          }
        }
      }
    }

    #modalListNumber {
      background-color: green;
    }

    .contain__button-save {
      height: 35px;
      width: 100%;
      opacity: 1;
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      top: -15px;
      right: 30px;
      z-index: 718;

      button {
        height: 30px;
        width: 90px;
        border-radius: 10px;
        background-color: ${colors.primaryColor};
      }
    }
  }

  .modal__form__body {
    .form__body {
      width: 100%;
      height: auto;
      margin: auto;
      border-radius: 10px;
      background-color: ${colors.backgroundColor};
      position: relative;
      top: 20%;

      .form__title {
        width: 100%;
        padding-top: unset;
        text-align: left;
        align-items: center;
        gap: 10px;

        .icon__Form_title {
          color: ${colors.white};
        }
      }
    }

    .container__cards {
      ${'' /* width: 80%; */}
      padding-top: unset;
    }
  }
  .user__contain__table {
    height: 100%;
    width: 60%;
    max-width: 630px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 900;
    padding-bottom: 225px;
    background-color: ${colors.backgroundColor};
    position: fixed;
    top: 65%;
    right: 20%;
    overflow: visible;
    table {
      color: ${colors.white};
    }
  }

  .left {
    width: 100%;
    height: 30px;
    padding-left: 5px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    ${'' /* min-width: 800px; */}
  }

  .tooltip-column {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;

    tr th:last-child div:last-child {
      justify-content: left;
    }
  }

  thead {
    background-color: ${colors.primaryColor};
    border-radius: 10px;
    color: white;
    position: relative;
    margin-left: 10px;
    z-index: 2;
  }

  .nav__buttons__container {
    height: 50px;
    width: 100%;
  }
  .user__table {
    width: 100%;
  }

  .section__name {
    width: max-content;
    display: flex;
    align-items: center;
    color: #0c3fc4;
    height: 30px;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    z-index: 90;
    padding-left: 5px;
  }

  .navtable__container {
    width: 100%;
    height: 30px;
    position: relative;
    top: 0px;
    left: 0px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: flex-end;
    z-index: 9;
    text-align: end;
    flex-direction: row-reverse;

    table {
      position: relative;
      top: -40px;
      left: 40px;
    }
  }

  tr th:nth-child(2) {
    border-left: none;
    font-weight: 700;
    cursor: pointer;
    width: 40px;
    min-width: unset;
    width: auto;
  }

  .row {
    color: black;
    width: 100%;
    border-bottom: rgb(215, 215, 215) 1px solid;
  }

  .pageScreen {
    width: 100%;
    background-color: ${colors.backgroundColor};
    height: auto;
    border-radius: 10px;
    padding-bottom: 100px;

    .form__body__users {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
      gap: 30px;
      align-items: center;
      border-radius: 0 0 15px 15px;
      position: fixed;
      right: 20px;
      width: 55%;

      .form__title {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: 34px;
        text-align: left;
        color: ${colors.primaryColorLight};
        font-weight: bold;
        position: fixed;
      }

      .title__Form_title {
        display: flex;
        align-items: center;
        color: ${colors.primaryColor};
        font-size: 22px;
        padding-left: 130px;
      }

      .container__cards {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;

        .contain__inputCard-component {
          width: 90%;
          height: 100%;
          padding: 100px 40px 0 0;

          .section__name {
            padding-left: 0;
          }
        }
        .section_name {
          padding-left: 0;
        }
        .stepForm__contain-typeInput {
          gap: 10px;
        }

        .contain__select {
          width: unset;
        }
      }
    }

    &__tabs {
      display: flex;
      justify-content: flex-start;
      border: none;
      ${'' /* padding: 20px; */}
      z-index: 101;
      margin: auto;
      height: 40px;
      gap: 10px;
      margin: auto;
      width: max-content;
      padding-top: 25px;

      &__tab {
        min-width: 130px;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: ${colors.blue};

        &:hover {
          cursor: pointer;
        }

        &__item {
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
          // font-size: ${newFontSize.h3};

          border: none;
        }
      }

      .active {
        min-height: 35px;
        padding: 15px;
        color: ${colors.white};
        background-color: ${colors.primaryColor};
        border-radius: 10px;
        &:hover {
          background-color: ${colors.primaryColorLight};
          ${'' /* color: ${colors.white}; */}
          cursor: pointer;
          ${'' /* color: ${colors.primaryColorLight}; */}
        }
      }

      .inactive {
        min-height: 35px;
        padding: 15px;
        color: ${colors.primaryColor};
        border-radius: 10px;

        &:hover {
          background-color: ${colors.primaryColorLight};
          color: ${colors.white};
          cursor: pointer;
          ${'' /* color: ${colors.primaryColorLight}; */}
        }
      }
    }

    &__form {
      height: auto;
      width: 100%;
      ${'' /* padding-top: 20px; */}
      ${'' /* padding-left: ${(p) => (!p.isMenuOpen ? '30px' : '160px')}; */}
      ${'' /* padding-right: 30px; */}
      ${'' /* border: 1px solid ${colors.primaryColor}; */}
      ${
        '' /* background-color: ${colors.backgroundColor};
      border-radius: 15px 0; */
      }

      .form__body__ivr {
        margin: auto;
        padding-left: 120px;

        .container__cards {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-top: 20px;
          width: 80%;

          .contain__inputCard-component {
            width: 80%;
          }
        }

        .title__Form_title {
          font-size: 18px;
          padding-left: 120px;
          ${'' /* margin: auto; */}
        }
      }

      .contain__button-save {
        opacity: 1;
        height: 35px;
        position: fixed;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        top: 75px;
        right: 10px;
        ${'' /* z-index: 718; */}

        .cancel_button {
          color: lightgrey;
          background-color: ${colors.primaryColor};
          /* background-color: #c142269a; */
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: ${colors.primaryColorLight};
          }
        }

        .save_edit_button {
          color: black;
          background-color: ${colors.greenGYGAS};
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          margin-right: 20px;
          height: 35px;
          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }
      }
    }
  }
`;
