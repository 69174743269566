import React, { useEffect } from 'react';
import { IconArrowsRandom } from '@tabler/icons-react';

import { colors } from '../../theme/colors';
import { InputRandomWrapper } from './InputRandom.style';
import { array, func, object } from 'prop-types';

export const InputRandom = ({ item, form, setErrorInput, setInputValue, setValidationNormalInput, validationNormalInput }) => {
  const [randomInput, setRandomInput] = React.useState('');
  const [rotate, setRotate] = React.useState(false);

  const onChange = (e, item, requiredRegex) => {
    setInputValue((prevState) => ({
      ...prevState,
      [item.element]: e.target.value,
    }));

    if (e.target.value === '' || !requiredRegex.test(e.target.value)) {
      setErrorInput(true);
      setValidationNormalInput((prevState) => [...prevState, item.element]);
    } else {
      setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.element));
      if (validationNormalInput.length === 0) setErrorInput(false);
    }
    setRandomInput(e.target.value);
    let action = { action: 'input', value: e.target.value };
    form(action, item);
  };

  const generateRandomInput = (again) => {
    let randomInput;
    if (again === 'again') {
      randomInput = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      setRandomInput(randomInput);
      setRotate(!rotate);
    } else {
      randomInput = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      setRandomInput(randomInput);
    }
    let event = { target: { value: randomInput } };
    onChange(event, item.ref, item.required.regex);
  };

  useEffect(() => {
    generateRandomInput();
  }, []);

  return (
    <InputRandomWrapper>
      <div className="inputRandom">
        <input type={'text'} id={item.ref.element} placeholder={item.placeholder} value={randomInput} className={'inputRandom__input'} onChange={(e) => onChange(e, item.ref, item.required.regex)} />
        <div className={rotate ? 'inputRandom__icon-rotate' : 'inputRandom__icon'} onClick={() => generateRandomInput('again')}>
          <IconArrowsRandom className="inputRandom__icon-component" size={'18px'} color={colors.black} />
        </div>
      </div>
    </InputRandomWrapper>
  );
};

InputRandom.propTypes = { item: object, form: func, setErrorInput: func, setInputValue: func, setValidationNormalInput: func, validationNormalInput: array };
