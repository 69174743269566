import Axios from 'axios';

import { getDepartmentsAction } from '../actions/department/actionCreator';

// const urlApi = import.meta.env.VITE_APP_URL;
const urlBack = import.meta.env.VITE_BACK_URL;

export const getDepartmentsThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const department = await Axios.get(`${urlBack}departments/departments`);
    dispatch(getDepartmentsAction(department.data));
    setLoading(false);
  } catch (error) {
    return error;
  }
};

export const createDepartmentThunk = (department, setLoading) => async () => {
  try {
    const response = await Axios.post(`${urlBack}departments/departments/create`, { data: department });
    setLoading(false);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDepartmentThunk = (department, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.patch(`${urlBack}departments/departments/update`, { data: department });
    setLoading(false);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteDepartmentThunk = (department, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}departments/departments/delete`, { data: department });
    setLoading(false);
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadDepartmentThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);
  try {
    const response = await Axios.post(`${urlBack}departments/departments/uploadFile`, data);
    return response;
  } catch (error) {
    return error;
  }
};
