export const routesWide = {
  dashboard: 'dashboard',
  users: 'users',
  numbering: 'numbering',
  applications: 'applications',
  cdr: 'cdr',
  outboundRoutes: 'outboundRoutes',
  preferences: 'preferences',
  profile: 'profile',
  help: 'help',
  settings: 'settings',
  EM: 'EM',
};

export const routesUser = {
  usersList: 'usersList',
  userCreate: 'usersList/create',
  userUpdate: 'usersList/update',
};

export const routesNumbering = {
  numberingList: 'numberingList',
  marcasList: 'marcasList',
  createmarca: 'marcas/create',
  updatemarca: 'marcas/update',
  modelosList: 'modelosList',
  createmodelo: 'modelos/create',
  updatemodelo: 'modelos/update',
  plantillasList: 'plantillasList',
  createPlantilla: 'plantillas/create',
  updatePlantilla: 'plantillas/update',
  createNumberingList: 'numberingList/create',
  updateNumberingList: 'numberingList/update',
  extensions: 'extensions',
  createExtensions: 'extensions/create',
  updateExtensions: 'extensions/update',
};

export const routesEM = {
  diffusionLists: 'diffusionLists',
  createDiffusionList: 'diffusionLists/create',
  updateDiffusionList: 'diffusionLists/update',
  alerts: 'alerts',
  createAlerts: 'alerts/create',
  updateAlerts: 'alerts/update',
  history: 'history',
  historyItem: 'history/item',
};

export const routesApplications = {
  mailboxes: 'mailboxes',
  createMailboxes: 'mailboxes/create',
  updateMailboxes: 'mailboxes/update',
  queue: 'queue',
  createQueue: 'queue/create',
  updateQueue: 'queue/update',
  conference: 'conference',
  createConferences: 'conference/create',
  updateConferences: 'conference/update',
  ivr: 'ivr',
  createIvr: 'ivr/create',
  updateIvr: 'ivr/update',
  locutions: 'locutions',
  createLocutions: 'locutions/create',
  updateLocutions: 'locutions/update',
  shortMarking: 'shortMarking',
  createShortMarking: 'shortMarking/create',
  updateShortMarking: 'shortMarking/update',
  songWaiting: 'songWaiting',
  createSongWaiting: 'songWaiting/create',
  updateSongWaiting: 'songWaiting/update',
};

export const routesPreferences = {
  calendar: 'calendar',
  createCalendar: 'calendar/create',
  updateCalendar: 'calendar/update',
  schedule: 'schedule',
  createSchedule: 'schedule/create',
  updateSchedule: 'schedule/update',
  protocolSetup: 'protocolSetup/',
  department: 'departments',
  permissions: 'permissions',

  modules: 'modules',
  // users: 'users',
  // permissions: 'permissions',
  permissionsEdit: 'edit',
  companyInformation: 'companyInformation',
  // createUser: 'users/create',
  // updateUser: 'users/update/',
  configurationAsterisk: 'configurationAsterisk',
  api: 'apiKey',
  createApi: 'apiKey/create',
  domains: 'domains',
  createDomains: 'domains/create',
  updateDomains: 'domains/update',
  whitelist: 'whitelist',
  createWhitelist: 'whitelist/create',
  updateWhitelist: 'whitelist/update',
};

export const routesSettings = {
  // users: 'users',
  modules: 'modules',
  permissions: 'permissions',
  permissionsEdit: 'permissions/edit',
  permissionsCreate: 'permissions/create',
  companyInformation: 'companyInformation',

  company: 'company',
  updateCompany: 'company/update',
  department: 'department',
  createDepartment: 'department/create',
  updateDepartment: 'department/update',
  createCompany: 'company/create',

  configurationAsterisk: 'configurationAsterisk',
  api: 'apiKey',
  updateApi: 'apiKey/update',
  createApi: 'apiKey/create',
  domains: 'domains',
  createDomains: 'domains/create',
  updateDomains: 'domains/update',
  whitelist: 'whitelist',
  createWhitelist: 'whitelist/create',
  updateWhitelist: 'whitelist/update',

  calendar: 'calendar',
  createCalendar: 'calendar/create',
  updateCalendar: 'calendar/update',
  schedule: 'schedule',
  createSchedule: 'schedule/create',
  updateSchedule: 'schedule/update',
  protocolSetup: 'protocolSetup/',
  // company: 'company',
  // department: 'department',
  // createDepartment: 'department/create',
  // updateDepartment: 'department/update',
  // createCompany: 'company/create',
  // updateCompany: 'company/update',
};

export const routesCallRegister = {
  general: 'general',
  activity: 'activity',
  cdractivity: 'cdr/activity',
  productivity: 'productivity',
  customer: 'customer',
};

export const routesOutbound = {
  groupCategories: 'groupCategories',
  createGroupCategory: 'groupCategories/create',
  updateGroupCategory: 'groupCategories/update',
  categories: 'categories',
  createCategory: 'categories/create',
  updateCategory: 'categories/update',
  trunks: 'trunks',
  createTrunks: 'trunks/create',
  updateTrunks: 'trunks/update/',
};

export const routesHelp = {
  faqs: 'faqs',
  tutorials: 'tutorials',
  guide: 'guide',
  profile: 'profile/',
};
