import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

// import usersReducer from '../features/userSlice/userSlice';
// import penguinsReducer from "../features/penguinSlice/penguinSlice";
// import messagesReducer from "../features/messageSlice/messageSlice";
// import uiReducer from '../features/uiSlice/uiSlice';
// import systemReducer from '../features/systemSlice/systemSlice';

// const allReducers = { ...rootReducer, user: usersReducer, ui: uiReducer, system: systemReducer };

const configureStore = () => {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  // return createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
};

export default configureStore;
