import React, { useEffect, useState } from 'react';
// import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { dataConfiguracionAsterisk } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { ConfigurationAsteriskScreenWrapper } from './ConfigurationAsteriskScreen.style';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { string } from 'prop-types';
import { getSettingsThunk, updateSettingsThunk } from '../../../redux/thunks/settingsThunks';
import { useDispatch, useStore } from 'react-redux';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import useApplications from '../../../hooks/useApplications';
// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { PageScreen } from '../../../components/PageScreen/PageScreen';

export const ConfigurationAsteriskScreen = ({ crudType }) => {
  const { settingsForm, configDataForm, getSettingsData, configDataGet, getOutboundRoutes, getConfigData } = useSettings();
  const { getApplicationsLocutions, arrayLocutionsHook } = useApplications();
  const { arrayOutboundRoutesTrunksHook } = useOutbound();

  const { getState } = useStore();

  const [checkboxesActives, setCheckboxesActives] = useState();
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [render, setRender] = useState(false);

  const data = dataConfiguracionAsterisk;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettingsThunk(setLoading));
    getSettingsData();
    getConfigData();
    getOutboundRoutes();
    getApplicationsLocutions();
  }, []);

  const [docSettings, setDocSettings] = useState(getState()['getConfigReducer']);

  useEffect(() => {
    if (docSettings?.id === 1) {
      settingsForm(
        { action: 'clearForm' },
        {
          form: 'config',
        }
      );
      settingsForm(
        {
          action: 'id',
          value: 1,
        },
        { form: 'config', element: 'id' }
      );
      settingsForm(
        {
          action: 'toggleButton',
          value: docSettings.isRainbow === 1 ? 1 : 0,
        },
        {
          form: 'config',
          element: 'isRainbow',
        }
      );
      settingsForm(
        {
          action: 'toggleButton',
          value: docSettings.isTeams === 1 ? 1 : 0,
        },
        {
          form: 'config',
          element: 'isTeams',
        }
      );

      const rainbowTroncal = arrayOutboundRoutesTrunksHook.find((option) => option.id === docSettings.rainbowTroncalId);

      settingsForm(
        {
          action: 'select-option',
        },
        {
          form: 'config',
          element: 'rainbowTroncalId',
        },
        {
          value: docSettings.rainbowTroncalId,
          label: rainbowTroncal?.description,
        }
      );

      const teamsTroncal = arrayOutboundRoutesTrunksHook.find((option) => option.id === docSettings.teamsTroncalId);

      settingsForm(
        {
          action: 'select-option',
        },
        {
          form: 'config',
          element: 'teamsTroncalId',
        },
        {
          value: docSettings.teamsTroncalId,
          label: teamsTroncal?.description,
        }
      );

      const callAudio = arrayLocutionsHook.find((option) => option.id === docSettings.callback_audio_id);

      settingsForm(
        {
          action: 'select-option',
        },
        {
          form: 'config',
          element: 'callback_audio_id',
        },
        {
          value: Number(docSettings.callback_audio_id),
          label: callAudio?.description,
        }
      );

      settingsForm(
        {
          action: 'titleInputPair',
          value: docSettings?.extension_start,
          //|| configDataForm.extension_start
        },
        {
          form: 'config',
          element: 'extension_start',
        }
      );

      settingsForm(
        {
          action: 'titleInputPair',
          value: docSettings?.extension_end || configDataForm.extension_end || data.tabs[1].steps[0].input[8].inputs[1].defaultValues,
          //
        },
        {
          form: 'config',
          element: 'extension_end',
        }
      );

      Object.keys(docSettings).map(() =>
        data.tabs?.map((item2) =>
          item2.steps?.map((item3) => {
            item3.input.map((item4) => {
              if (Array.isArray(item4.inputs)) {
                item4.inputs.forEach((input) => {
                  settingsForm(
                    {
                      // action: item4.cardType === 'titleInputPair' ? 'input' : item4.cardType,
                      action: item4.cardType,
                      // action: 'input',
                      value: input.defaultValues,
                      label: input.placeholder,
                    },
                    {
                      form: input.ref.form,
                      element: input.ref.element,
                    }
                  );
                });
              } else {
                if (item4.ref?.element !== 'teamsTroncalId' && item4.ref?.element !== 'rainbowTroncalId' && item4.ref?.element !== 'callback_audio_id') {
                  if (item4?.cardType !== 'input' && item4?.cardType !== 'titleInputPair') {
                    return settingsForm(
                      {
                        action: item4?.cardType,
                        value: item4.defaultValues,
                        label: item4?.label,
                      },
                      {
                        form: item4.ref?.form,
                        element: item4.ref?.element,
                      }
                    );
                  }
                  // else if (item4?.cardType === 'titleInputPair') {
                  //   settingsForm(
                  //     {
                  //       action: 'titleInputPair',
                  //       value: item4.defaultValues,
                  //       //
                  //     },
                  //     {
                  //       form: 'config',
                  //       element: item4.ref?.element,
                  //     }
                  //   );
                  // }
                }
              }
              // }
            });
          })
        )
      );
    } else {
      if (Object.keys(configDataGet).length !== 0) Object.assign(docSettings, configDataGet);

      Object.keys(docSettings).map(() =>
        data.tabs?.map((item2) =>
          item2.steps?.map((item3) => {
            item3.input.map((item4) => {
              if (Array.isArray(item4.inputs)) {
                item4.inputs.forEach((input) => {
                  // if (item4.cardType !== 'titleInputPair') {
                  settingsForm(
                    {
                      // action: item4.cardType,
                      action: 'input',
                      value: docSettings[input.ref?.element],
                      label: input.placeholder,
                    },
                    {
                      form: input.ref.form,
                      element: input.ref.element,
                    }
                  );
                  // }
                });
              } else {
                if (item4.ref?.element !== 'teamsTroncalId' && item4.ref?.element !== 'rainbowTroncalId' && item4.ref?.element !== 'callback_audio_id') {
                  // if (item4?.cardType !== 'input') {
                  return settingsForm(
                    {
                      action: item4?.cardType,
                      value: docSettings[item4.ref?.element],
                      label: item4?.label,
                    },
                    {
                      form: item4.ref?.form,
                      element: item4.ref?.element,
                    }
                  );
                  // } else {
                  //   return null;
                }
              }
            });
          })
        )
      );
    }
  }, [docSettings]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof docSettings === 'object') {
        if (docSettings?.id === null || docSettings?.id === undefined) {
          setDocSettings(configDataForm);
          setLoadingCounter(loadingCounter + 1);
        } else {
          const { tabs } = data;
          tabs.forEach((tab) => {
            tab.steps.map((item) => {
              item.input.map((item2) => {
                if (item2.inputs) {
                  item2.inputs.forEach((input) => {
                    const type = input.ref.element.includes('start', 0) === true ? 'start' : input.ref.element.includes('end') ? 'end' : '';

                    switch (type) {
                      case 'start':
                        input.defaultValues = docSettings[input.ref.element];
                        // return item2.inputs[0];
                        // return item2;
                        break;
                      // input.defaultValues);
                      case 'end':
                        input.defaultValues = docSettings[input.ref.element];
                        // return item2.inputs[1];
                        // return item2;
                        break;
                      default:
                        // input.defaultValues = docSettings[input.ref.element];
                        break;
                    }

                    // settingsForm(
                    //   {
                    //     action: item2.cardType,
                    //     value: input.defaultValues,
                    //     label: input.placeholder,
                    //   },
                    //   {
                    //     form: input.ref.form,
                    //     element: input.ref.element,
                    //   }
                    // );

                    return item2;
                    // return (item2.input[0].defaultValues = input.defaultValues);
                  });
                  return item;
                } else {
                  if (item2.ref?.element !== undefined) {
                    let newLabel = '';

                    switch (item2.ref.element) {
                      case 'rainbowTroncalId':
                        newLabel = arrayOutboundRoutesTrunksHook.find((option) => option.id === docSettings.rainbowTroncalId)?.description;

                        item2.defaultValues = { value: docSettings.rainbowTroncalId.toString(), label: newLabel };
                        break;
                      case 'teamsTroncalId':
                        newLabel = arrayOutboundRoutesTrunksHook.find((option) => option.id === docSettings.teamsTroncalId)?.description;

                        item2.defaultValues = { value: docSettings.teamsTroncalId.toString(), label: newLabel };
                        break;
                      case 'callback_audio_id':
                        newLabel = arrayLocutionsHook.find((option) => option.id === docSettings.callback_audio_id)?.description;

                        item2.defaultValues = { value: docSettings.callback_audio_id, label: newLabel };
                        break;

                      // case 'titleInputPair':
                      //   item2.defaultValues = docSettings.isRainbow;
                      //   break;
                      // case 'Rainbow_URL':
                      //   item2.defaultValues = docSettings.Rainbow_URL;
                      //   break;
                      // case 'Teams_URL':
                      //   item2.defaultValues = docSettings.Teams_URL;
                      //   break;
                      default:
                        item2.defaultValues = docSettings[item2.ref.element];
                        // if (item2.defaultValues && item2.defaultValues.length > 0) {
                        //   item2.defaultValues = docSettings[item2.ref.element];
                        // } else {
                        //   item2.defaultValues = docSettings.id !== '' ? docSettings[item2.ref.element] : configDataForm[item2.ref.element];
                        // }
                        break;
                    }
                    return item2;
                  }
                }
              });
              return item;
            });
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter]);

  useEffect(() => {
    if (Object.keys(configDataGet).length !== 0) Object.assign(configDataForm, configDataGet);

    const { tabs } = data;
    tabs.forEach((tab) => {
      tab.steps.forEach((step) => {
        step.input.forEach((item) => {
          if (item.ref?.element) {
            let refElement = item.ref?.element;
            if (refElement === 'maquina') {
              if (configDataForm[refElement] === 'Monomáquina') {
                item.defaultValues = true;
              } else if (configDataForm[refElement] === 'Multimáquina') {
                item.defaultValues = false;
              } else {
                item.defaultValues = undefined;
              }
            }
            // if (refElement === 'preferences') {
            item.options?.forEach((option) => {
              if (option.selectData) {
                option.selectData.options.map((item) => {
                  let idAudio = +configDataForm[option.selectData.ref.element];

                  if (item.value === idAudio) {
                    return (option.selectData.defaultValues = item);
                  }
                  return item;
                });
              }
            });
            // item.defaultValues = configDataForm[refElement];
            // }
            if (refElement === 'teamsTroncalId') {
              arrayOutboundRoutesTrunksHook.forEach((Teams_Troncal) => {
                if (Teams_Troncal.description === null) {
                  Teams_Troncal.description = 'not defined';
                }
                const outboundTeams = {
                  label: Teams_Troncal.description,
                  value: Teams_Troncal.id?.toString(),
                };
                const foundOption = item.options ? item.options?.some((option) => option.value === outboundTeams.value) : true;
                if (!foundOption && outboundTeams.value !== undefined) {
                  item.options.push(outboundTeams);
                }
              });
            } else {
              item.defaultValues = configDataForm[refElement];
            }

            if (refElement === 'rainbowTroncalId') {
              arrayOutboundRoutesTrunksHook.forEach((Rainbow_Troncal) => {
                if (Rainbow_Troncal.description === null) {
                  Rainbow_Troncal.description = 'not defined';
                }
                let outboundRainbow = {
                  label: Rainbow_Troncal.description,
                  value: Rainbow_Troncal.id?.toString(),
                };
                const foundOption = item.options ? item.options?.some((option) => option.value === outboundRainbow.value) : true;
                if (!foundOption && outboundRainbow.value !== undefined) {
                  item.options.push(outboundRainbow);
                }
              });
            } else {
              item.defaultValues = configDataForm[refElement];
            }

            if (refElement === 'callback_audio_id') {
              item.options = [];
              arrayLocutionsHook.forEach((Audio) => {
                if (Audio.description === null) {
                  Audio.description = 'not defined';
                }
                const newAudio = {
                  label: Audio.description,
                  value: Audio.id?.toString(),
                };
                // const foundOption = arrayLocutionsHook.some((option) => option.id === Audio.id);
                // if (!foundOption && newAudio.value !== undefined) {
                item.options.push(newAudio);
                // }
              });
            } else {
              // item.defaultValues = configDataForm[refElement];
            }
            return item;
          }
        });
      });
    });
  }, [crudType, loadingCounter]);

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = () => {
    setDataSave(true);
    // console.log(state, crudType);

    dispatch(updateSettingsThunk(configDataForm));
  };

  return (
    <ConfigurationAsteriskScreenWrapper className="asterisk__screen">
      {/* {loading ? <LoaderSpinner /> : <PageScreen data={data} onSubmit={onSubmit} render={render} setRender={setRender} form={settingForm} crudType={'edit'} maxwidth={'1000px'} />} */}
      {loading ? (
        <LoaderSpinner />
      ) : (
        //{/* <FormScreen onCancel={handleOnCancel} arrScreen={['Preferencias']} data={data} onSubmit={onSubmit} form={configDataForm} dataSave={dataSave} crudType={crudType} /> */}
        <PageScreen
          arrScreen={['Preferencias']}
          dataSave={dataSave}
          data={data}
          onSubmit={onSubmit}
          render={render}
          setRender={setRender}
          form={settingsForm}
          crudType={'edit'}
          maxwidth={'1000px'}
          onCancel={handleOnCancel}
          reducerForm={configDataForm}
          setCheckboxesActives={setCheckboxesActives}
          checkboxesActives={checkboxesActives}
        />
      )}
    </ConfigurationAsteriskScreenWrapper>
  );
};
ConfigurationAsteriskScreen.propTypes = { crudType: string };
