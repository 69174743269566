import React from 'react';
import { Navigate } from 'react-router-dom';
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { menuApplications } from '../../../data/dataApplications';
import './OverviewApplications.css';

export const OverviewApplications = () => {
  return (
    <div className="overview-applications">
      <Navigate to="../mailboxes" />
      <LinkButtonBig menuTop={menuApplications} />
    </div>
  );
};
