import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

const getColor = (props) => {
  if (props.isDragAccept) {
    return colors.blue;
  }
  if (props.isDragReject) {
    return colors.red;
  }

  return colors.greyLine;
};

export const MyDropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .dropzone {
    .toggleButton {
      height: 100%;
      width: 400px;
      border: 1px solid ${colors.greyBorder};
      border-radius: 8px;
      display: flex;
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      background-color: ${colors.backgroundColor};

      svg {
        width: 40px;
        heigth: 40px;
      }
      &__left,
      &__right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        gap: 20px;
        height: 50px;
        width: 200px;
        font-size: ${newFontSize.h4};
        font-weight: ${weight.regular};
        color: ${colors.primaryColor};
      }

      &__left {
        border-right: 1px solid ${colors.greyBorder};
        background-color: ${(props) => (props.isActive && props.twoButtonActive === 'false' ? colors.greenGYGAS : colors.greenGYGAS)};
        border-radius: 6px;
        color: color: ${colors.primaryColor};
      }

      &__right {
        background-color: ${(props) => (props.isActive && props.twoButtonActive !== 'false' ? colors.greenGYGAS : colors.secondaryColorLight)};
        border-radius: 6px;
        color: color: ${colors.primaryColor};
      }
    }
  }

  .presentation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .speech__container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input__speech {
      width: 100%;
      height: 130px;
      ${'' /* position: relative; */}
      margin: auto;
    }

    select {
      height: 35px;
      width: 100%;
      border-radius: 10px;
    }

    .speech__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;
      ${
        '' /* position: relative;
      top: 20px; */
      }

      button {
        padding: 5px;
        width: 100px;
        height: 35px;
        border-radius: 10px;
        background-color: ${colors.primaryColor};
        color: ${colors.white};
      }

      .button_start {
        background-color: ${colors.greenGYGAS};
        color: ${colors.primaryColor};
      }

      ${
        '' /* .button_stop {
        background-color: ${colors.burlywood};
        color: ${colors.primaryColor};
      } */
      }

      .button_pause {
        background-color: ${colors.burlywood};
        color: ${colors.primaryColor};
      }
    }
  }

  .icon__microphone {
    color: ${colors.primaryColor};
    position: relative;
    top: -50px;
    left: 240px;
    width: 40px;
    height: 40px;
    padding: 5px;

    &:hover {
      background-color: ${colors.primaryColor};
      color: white;
      border-radius: 10px;
      ${'' /* padding: 5px; */}
    }
  }

  .contain__dropzone {
    width: 100%;
    ${'' /* height: 32px; */}
    border: 1px dashed ${(props) => getColor(props)};
    border-radius: 7px;
    outline: none;
    display: flex;
    flex-direction: row;
    //transition: border 0.24s ease-in-out;

    p {
      display: flex;
      width: 50%;
      justify-content: center;
      align-items: center;
      margin: 0;
      height: 34px;
      ${'' /* width: 50%; */}
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }
    .dropzone__item {
      font-size: ${newFontSize.span};
      margin: 0 0 5px 5px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      text-align: left;
      width: max-content;
      font-weight: ${weight.bold};
      color: ${colors.black};
      height: 10px;
      width: 50%;
    }
  }
`;
export const ContainData = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  height: 10px;

  .dropzone__file {
    display: flex;

    .dropzone__success,
    .dropzone__current {
      font-size: ${newFontSize.span};
      margin: 0;
      text-align: center;
      ${'' /* font-weight: ${weight.bold}; */}
      color: ${colors.primaryColors};
      margin-left: 5px;
      width: max-content;
    }

    .dropzone__error {
      font-size: ${newFontSize.span};
      margin: 0;
      text-align: center;
      font-weight: ${weight.bold};
      color: ${colors.red};
    }
  }
`;
