import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize } from '../../theme/fonts';

export const CardSliderWrapper = styled.div`
  height: 100%;
  .cardSlider {
    height: 100%;
    &__cards--grid {
      margin-top: 20px;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 200px));
    }
    &__cards--column {
      margin-top: 50px;
      display: grid;
      grid-template-rows: ${(props) => (props.isShowCard ? `repeat(auto-fill, minmax(50px, 170px))` : `repeat(auto-fill, minmax(50px, 50px))`)};
      border: 1px solid ${colors.primaryColor};
      gap: 20px;
      justify-content: center;
      padding-top: 10px;

      border-radius: 5px;

      max-height: 600px;
      // overflow: scroll;

      &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        height: 5px;
        border-radius: 5px;
        border-color: ${colors.primaryColor};
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        background: ${colors.primaryColor};
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 50%;
      }
    }
    &__cards--column-contain {
      display: grid;
      position: relative;
      grid-template-columns: repeat(3, 250px);
      gap: 10px;
      justify-content: center;
    }

    &__cards--column-contain-order {
      display: grid;
      position: relative;
      grid-template-columns: repeat(3, 280px);
      gap: 10px;
      justify-content: start;
    }
    &__cards--column-name {
      position: absolute;
      top: 30px;
      font-size: ${newFontSize.label};
      color: ${colors.primaryColor};
    }

    &__cards--column-number {
      position: relative;
    }
    &__cards--column-number-item {
      position: absolute;
      left: -22px;
      top: 50%;
      bottom: 50%;
      color: ${colors.greyBorder};
    }
  }
  .dropArea {
    position: relative;
  }
  .dropArea::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #ebebeb;
  }
`;
