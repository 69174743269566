// import { routesEM } from '../../navigation/routes';
// New Icons
import hierarchy from '../../assets/images/EM/hierarchy.svg';
import home from '../../assets/images/EM/home.svg';
import phone from '../../assets/images/EM/phone.svg';
import speakerphone from '../../assets/images/icons/speakerphone.svg';
import extensions from '../../assets/images/menuTop/extensions.svg';
import ambulance from '../../assets/images/navMenu/ambulance.svg';
import click from '../../assets/images/notFound/click.svg';

// import { IconAlertOctagon, IconSpeakerphone, IconBook } from '@tabler/icons-react';

// Form Icons
import extensionIcon from '../../assets/images/forms/affiliate.svg';
import list from '../../assets/images/forms/list.svg';
import code from '../../assets/images/forms/separator-vertical.svg';
import textInput from '../../assets/images/forms/textInput.svg';
import volume from '../../assets/images/forms/volume.svg';
import { typeRegex } from '../dataRegex';
import { routesEM } from '../../navigation/routes';
import { IconAlertOctagon, IconBook, IconSpeakerphone } from '@tabler/icons-react';
// import { IconMicrophone2 } from '@tabler/icons-react';
// import { IconPhone } from '@tabler/icons-react';
// import { IconMicrophone } from '@tabler/icons-react';
// import { IconTableShortcut } from '@tabler/icons-react';
// import { IconPlaylist } from '@tabler/icons-react';

export const titleEM = {
  title: 'Emergency Manager',
  subtitle: 'La extensión preparada para difundir y gestionar mensajes de emergencia. Llama a un código, graba un mensaje y automáticamente emitelo.',
  icon: ambulance,
};

export const menuEM = [
  // {
  //   id: 1,
  //   header: 'Aplicaciones',
  //   // icon: IconMail,
  //   active: true,
  //   name: 'Aplicaciones',
  //   buttons: [
  // {
  //   id: 1,
  //   name: 'Buzones corporativos',
  //   text: 'Buzones corporativos',
  //   icon: IconStack,
  //   path: routesApplications.mailboxes,
  //   restricted: 'mailboxes',
  //   content: 'Crear buzones para tus extensiones',
  // },
  // {
  //   id: 2,
  //   name: 'Colas',
  //   text: 'Colas',
  //   path: routesApplications.queue,
  //   icon: IconStack,
  //   restricted: 'queue',
  // },
  // {
  //   id: 3,
  //   name: 'Conferencias',
  //   text: 'Conferencias',
  //   path: routesApplications.conference,
  //   icon: IconMicrophone2,
  //   restricted: 'conference',
  //   content: 'Crear conferencias para tus extensiones',
  // },

  // {
  //   id: 4,
  //   name: 'Ivr',
  //   text: 'Ivr',
  //   path: routesApplications.ivr,
  //   icon: IconPhone,
  //   restricted: 'ivr',
  // },
  // {
  //   id: 5,
  //   name: 'Locuciones',
  //   text: 'Locuciones',
  //   path: routesApplications.locutions,
  //   icon: IconMicrophone,
  //   restricted: 'locutions',
  //   content: 'Crea locuciones para tus extensiones',
  // },

  // {
  //   id: 6,
  //   name: 'Marcación abreviada',
  //   text: 'Marcación abreviada',
  //   path: routesApplications.shortMarking,
  //   icon: IconTableShortcut,
  //   restricted: 'shortMarking',
  //   content: 'Crea marcaciones abreviadas para tus extensiones',
  // },
  // {
  //   id: 7,
  //   name: 'Música en espera',
  //   text: 'Música en espera',
  //   path: routesApplications.songWaiting,
  //   icon: IconPlaylist,
  //   restricted: 'songWaiting',
  //   content: 'Crea música en espera para tus extensiones',
  // },
  //   ],
  // },
  {
    text: 'Alertas',
    description: 'Crea y edita las alertas',
    path: routesEM.alerts,
    icon: IconAlertOctagon,
    restricted: 'alerts',
    id: 2,
    content: 'Crea y edita las alertas para tus listas de difusión.',
  },
  {
    text: 'Listas de difusión',
    description: 'Crea listas de difusión para tus alertas',
    path: routesEM.diffusionLists,
    icon: IconSpeakerphone,
    restricted: 'diffusionLists',
    id: 1,
    content: 'Crea y edita las listas de difusión para tus alertas.',
  },
  {
    text: 'Historial',
    description: 'Controla y analiza el historial de alertas lanzadas',
    path: routesEM.history,
    icon: IconBook,
    restricted: 'history',
    id: 3,
    content: 'Controla y analiza el historial de alertas lanzadas.',
  },
];

export const dataScreenDiffusionList = {
  title: [
    {
      text: 'Listas de difusión',
      icon: speakerphone,
    },
    {
      text: 'Extensiones',
      icon: extensions,
    },
  ],
  option: [],
  icon: {
    phone,
    home,
    hierarchy,
  },
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna lista de difusión creada',
    subtitle: 'Empieza por configurar una nueva lista de difusión para visualizar en tu escritorio',
    textButton: 'Crear lista de difusión',
  },
};

export const dataScreenAlerts = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna alerta creada',
    subtitle: 'Empieza por configurar una nueva alerta para visualizar en tu escritorio',
    textButton: 'Crear alerta',
  },
  icon: {
    code,
    list,
    volume,
  },
};

export const dataScreenHistory = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ningun historial',
    subtitle: 'Empieza para visualizar un historial en tu escritorio',
    textButton: false,
  },
};

export const formCreateDiffusionList = {
  title: 'Creación de una nueva lista de difusión',
  titleEdit: 'Edita la lista de difusión',
  successMessage: 'Lista de difusión creada correctamente',
  textButton: 'Crear Lista Difusión',
  steps: [
    {
      number: '1',
      title: 'Nombre',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'diffusionList', element: 'name', unique: true },
          defaultValues: null,
          errorContent: 'Nombre obligatorio',
          required: {
            text: 'Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Ya hay un lista de difusión con ese nombre',
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Extensiones',
      id: 2,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 2,
          cardType: 'multiselect',
          label: 'Extensiones',
          icon: extensionIcon,
          ref: { form: 'diffusionList', element: 'extensions' },
          options: [],
          defaultValues: [],
          placeholder: 'Seleccionar extensiones',
          errorContent: 'Selecciona una opción',
          required: {
            text: 'Extensiones',
            validation: true,
            errorMessage: '* Extension obligatoria',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formCreateEMAlerts = {
  title: 'Creación de una nueva alerta',
  titleEdit: 'Edita la alerta',
  successMessage: 'Alerta creada correctamente',
  textButton: 'Crear Alerta',
  steps: [
    {
      number: '1',
      title: 'Nombre, Código y Código confirmación',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'alerts', element: 'name', unique: true },
          defaultValues: null,
          required: {
            text: 'Nombre*',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Hay una alerta con ese nombre',
          },
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          label: 'Buzón de voz',
          placeholder: 'Buzón de voz',
          ref: { form: 'alerts', element: 'external' },
          defaultValues: false,
          icon: speakerphone,
          // radioType: 'mono',
          // options: [
          //     { label: 'Activa', value: 1, id: 4 },
          //     { label: 'Desactiva', value: 0, id: 5 },
          // ],
          required: {
            text: 'Interno | Externo',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Código',
          placeholder: 'Código',
          ref: { form: 'alerts', element: 'keyCall', unique: true },
          defaultValues: null,
          icon: code,
          required: {
            text: 'Código*',
            validation: true,
            errorMessage: '* Puede contener #, *, +, números',
            regex: /^[0-9]+$/,
            errorMessageUnique: '* Hay una alerta con ese codigo',
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Código de confirmación',
          placeholder: 'Código confirmación',
          ref: { form: 'alerts', element: 'confirmationNumber' },
          defaultValues: null,
          icon: code,
          required: {
            text: 'Código confirmación*',
            validation: true,
            errorMessage: '* Puede contener #, *, +, números',
            regex: /^[+0-9]+$/,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Locución',
      id: 2,
      nextStepText: 'Siguiente Paso',
      lastStep: false,
      input: [
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Locución',
          data: 'alertAction',
          icon: volume,
          placeholder: 'Selecciona una locución',
          ref: { form: 'alerts', element: 'locution' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Locución*',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: /^(?:\s*[a-zA-Z]+){1,10}/,
          },
        },
        {
          layoutId: 2,
          cardType: 'toggle',
          label: 'Buzón de voz',
          placeholder: 'Buzón de voz',
          ref: { form: 'alerts', element: 'callAndRecord' },
          defaultValues: false,
          icon: speakerphone,
          // radioType: 'mono',
          // options: [
          //     { label: 'Activa', value: 1, id: 4 },
          //     { label: 'Desactiva', value: 0, id: 5 },
          // ],
          required: {
            text: 'Llamar y grabar (activa o desactiva)',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
    {
      number: '3',
      title: 'Lista de difusión, Lista de difusión secundaria',
      id: 3,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 3,
          cardType: 'select-option',
          label: 'Lista de difusión',
          icon: list,
          placeholder: 'Lista de difusión',
          data: 'alertList',
          ref: { form: 'alerts', element: 'diffusionList' },
          options: [],
          originalOptions: [],
          defaultValues: {},
          required: {
            text: 'Lista de difusión*',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: /^(?:\s*[a-zA-Z]+){1,10}/,
          },
        },

        {
          layoutId: 3,
          cardType: 'select-option',
          label: 'Lista de difusión secundaria',
          icon: list,
          placeholder: 'Lista de difusión secundaria',
          data: 'alertList',
          ref: { form: 'alerts', element: 'secondaryDiffusionList' },
          options: [],
          defaultValues: {},
          required: {
            text: 'Lista de difusión secundaria',
            validation: false,
            errorMessage: 'Selecciona una opción',
            regex: /^/,
          },
        },
      ],
    },
  ],
};
