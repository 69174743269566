import { useCallback, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
  changeExtensionIdAction,
  changeExtensionNumberAction,
  changeExtensionNameAction,
  changeExtensionPasswordAction,
  addExtensionIdentificationAction,
  removeExtensionIdentificationAction,
  addTypeExtensionAction,
  removeTypeExtensionAction,
  clearExtensionFormAction,
  changeNumberingListIdAction,
  changeNumberingListNumberAction,
  changeNumberingListNameAction,
  removeNumberingListHqAction,
  clearNumberingListFormAction,
  addExtensionCompanyIdAction,
  removeExtensionCompanyIdAction,
  addExtensionRecordAction,
  addExtensionPikedGroupAction,
  removeExtensionPikedGroupAction,
  changeExtensionCallForwardAction,
  changeExtensionCallForwardNAAction,
  changeExtensionVoicemailActiveAction,
  addExtensionDepartmentAction,
  // addExtensionCategoriesAction,
  changeExtensionCallWaitingAction,
  clearExtensionsPikedGroupAction,
  changeExtensionSipPeerIdAction,
  addNumberingListCompanyIdAction,
  addNumberingListDepartmentAction,
  addExtensionAllowAction,
  clearExtensionAllowAction,
  addNumberingListCalendarAction,
  removeExtensionDepartmentAction,
  removeExtensionCategoriesAction,
  addNumberingListScheduleAction,
  addNumberingListSoundPromptAction,
  // addNumberingListDestinationAction,
  addNumberingListSoundPromptCloseAction,
  addNumberingListDestinationCloseAction,
  clearNumberingListScheduleAction,
  clearNumberingListCalendarAction,
  clearNumberingListSoundPromptAction,
  clearNumberingListDestinationAction,
  clearNumberingListSoundPromptCloseAction,
  clearNumberingListDestinationCloseAction,
  clearNumberingListCompanyIdAction,
  clearListNumberingDepartmentAction,
  addNumberingListDepartmentIdAction,
  changeNumberingListCompanyIdAction,
  addNumberingListSoundPromptCloseIdAction,
  addNumberingListDestinationCloseTypeIdAction,
  addNumberingListDestinationCloseIdAction,
  addNumberingListCalendarsAction,
  // addNumberingListTimesAction,
  addNumberingListDestinationsAction,
  // addNumberingListLocutionsAction,
  addNumberingListDestinationTypeAction,
  addNumberingListDestinationAction,
  addNumberingListDestinationsTypeAction,
  addNumberingListSchedulesAction,
  addNumberingListSoundPromptsAction,
  addNumberingListDestinationCloseTypeAction,
  addNumberingListIsDefaultsAction,
  addNumberingListIsDefaultAction,
  addExtensionUserAction,
  removeExtensionAllowAction,
  changeExtensionFilterTypeAction,
  changeExtensionPlantillaAction,
  changeExtensionModeloAction,
  changeExtensionMarcaAction,
  changePlantillaMarcaAction,
  changePlantillaModeloAction,
  changePlantillaIdAction,
  changePlantillaNombreAction,
  clearPlantillaFormAction,
  clearPlantillaModeloAction,
  changePlantillaBlfsAction,
  changePlantillaObservacionesAction,
  changeMarcaNombreAction,
  changeMarcaIdAction,
  changeModeloIdAction,
  clearModeloFormAction,
  changeModeloMarcaAction,
  changeModeloNombreAction,
  clearMarcaFormAction,
  // addNumberingListDispositivosAction,
} from '../redux/actions/numbering/actionCreator';

import {
  getNumberingListThunk,
  createNumberingListThunk,
  updateNumberingListThunk,
  deleteNumberingListThunk,
  getExtensionThunk,
  createExtensionThunk,
  updateExtensionThunk,
  deleteExtensionThunk,
  changeExtensionPasswordThunk,
  getSipPasswordThunk,
  updateFileExtensionThunk,
  uploadFileListNumberingThunk,
  getPlantillasThunk,
  getModelosThunk,
  getMarcasThunk,
  createMarcaThunk,
  createModeloThunk,
  createPlantillaThunk,
  updatePlantillaThunk,
  updateMarcaThunk,
  updateModeloThunk,
  deleteMarcaThunk,
  deleteModeloThunk,
  deletePlantillaThunk,
} from '../redux/thunks/numberingThunks';

const useNumbering = () => {
  const [loading, setLoading] = useState(true);
  const { getState } = useStore();
  const dispatch = useDispatch();

  const arrayNumberingHook = useSelector((numberingArray) => numberingArray.arrayNumbering);

  const arrayMarcasHook = useSelector((marcasArray) => marcasArray.arrayMarcas);

  const arrayModelosHook = useSelector((modelosArray) => modelosArray.arrayModelos);

  const arrayPlantillasHook = useSelector((plantillasArray) => plantillasArray.arrayPlantillas);

  const loadListNumbering = useSelector((numberingArray) => numberingArray.loadListNumberingReducer);

  const arrayNumberingExtensionsHook = useSelector((numberingArray) => numberingArray.arrayNumberingExtensions);

  const formExtensionsReducer = useSelector((dataExtension) => dataExtension.formExtensionsReducer);

  const formMarcaReducer = useSelector((dataExtension) => dataExtension.formMarcas);

  const formModeloReducer = useSelector((dataExtension) => dataExtension.formModelo);

  const formPlantillaReducer = useSelector((dataExtension) => dataExtension.formPlantilla);

  const formListNumberingReducer = useSelector((dataListNumbering) => dataListNumbering.formListNumberingReducer);

  const ddiLink = useSelector((selector) => selector.getDdiLinkReducer);

  const getNumberingList = useCallback(() => {
    let response = dispatch(getNumberingListThunk(setLoading));
    return response;
  }, [dispatch]);

  const createNumberingList = useCallback(() => {
    const listNumbering = getState()['formListNumberingReducer'];
    let response = dispatch(createNumberingListThunk(listNumbering, setLoading));
    return response;
  }, [dispatch]);

  const updateNumberingList = useCallback(() => {
    const payload = getState()['formListNumberingReducer'];
    let response = dispatch(updateNumberingListThunk(payload, setLoading));
    return response;
  }, [dispatch]);

  const deleteNumberingList = useCallback(
    (listNumbering) => {
      let response = dispatch(deleteNumberingListThunk(listNumbering, setLoading));
      return response;
    },
    [dispatch]
  );

  const getMarcas = useCallback(() => {
    dispatch(getMarcasThunk(setLoading));
  }, [dispatch]);

  const createMarca = useCallback(() => {
    const marca = getState()['formMarcas'];
    let response = dispatch(createMarcaThunk(marca, setLoading));
    return response;
  }, [dispatch]);

  const getModelos = useCallback(() => {
    dispatch(getModelosThunk(setLoading));
  }, [dispatch]);

  const createModelo = useCallback(() => {
    const modelo = getState()['formModelo'];
    let response = dispatch(createModeloThunk(modelo, setLoading));
    return response;
  }, [dispatch]);

  const getPlantillas = useCallback(() => {
    dispatch(getPlantillasThunk(setLoading));
  }, [dispatch]);

  const createPlantilla = useCallback(() => {
    const plantilla = getState()['formPlantilla'];
    let response = dispatch(createPlantillaThunk(plantilla, setLoading));
    return response;
  }, [dispatch]);

  const getExtensions = useCallback(() => {
    dispatch(getExtensionThunk(setLoading));
  }, [dispatch]);

  const createExtension = useCallback(() => {
    const extension = getState()['formExtensionsReducer'];
    let response = dispatch(createExtensionThunk(extension, setLoading));
    return response;
  }, [dispatch]);

  const updateExtensions = useCallback(() => {
    const extension = getState()['formExtensionsReducer'];
    let response = dispatch(updateExtensionThunk(extension, setLoading));
    return response;
  }, [dispatch]);

  const updatePlantilla = useCallback(() => {
    const plantilla = getState()['formPlantilla'];
    let response = dispatch(updatePlantillaThunk(plantilla, setLoading));
    return response;
  }, [dispatch]);

  const updateMarca = useCallback(() => {
    const marca = getState()['formMarcas'];
    const response = dispatch(updateMarcaThunk(marca, setLoading));
    return response;
  }, [dispatch]);

  const updateModelo = useCallback(() => {
    const modelo = getState()['formModelo'];
    let response = dispatch(updateModeloThunk(modelo, setLoading));
    return response;
  }, [dispatch]);

  const deleteExtensions = useCallback(
    (extension) => {
      let response = dispatch(deleteExtensionThunk(extension, setLoading));
      return response;
    },
    [dispatch]
  );

  const deleteMarcas = useCallback(
    (extension) => {
      let response = dispatch(deleteMarcaThunk(extension, setLoading));
      return response;
    },
    [dispatch]
  );

  const deleteModelos = useCallback(
    (extension) => {
      let response = dispatch(deleteModeloThunk(extension, setLoading));
      return response;
    },
    [dispatch]
  );

  const deletePlantillas = useCallback(
    (extension) => {
      let response = dispatch(deletePlantillaThunk(extension, setLoading));
      return response;
    },
    [dispatch]
  );
  const listNumberingForm = useCallback((arg, ref, inputValue) => {
    if (arg.action) {
      switch (ref.form) {
        case 'numberingList':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearNumberingListFormAction());
              break;
            case 'clear':
              if (ref.element === 'companyId') {
                dispatch(clearNumberingListCompanyIdAction(arg.removedValues[0]));
              }
              if (ref.element === 'Company') {
                dispatch(clearNumberingListCompanyIdAction(arg.removedValues[0]));
              }
              if (ref.element === 'departmentId') {
                dispatch(clearListNumberingDepartmentAction(arg.removedValues[0]));
              }
              if (ref.element === 'Department') {
                dispatch(clearListNumberingDepartmentAction(arg.removedValues[0]));
              }
              if (ref.element === 'calendar') {
                dispatch(clearNumberingListCalendarAction(arg.removedValues[0]));
              }
              if (ref.element === 'schedule') {
                dispatch(clearNumberingListScheduleAction(arg.removedValues[0]));
              }
              if (ref.element === 'soundPrompt') {
                dispatch(clearNumberingListSoundPromptAction(arg.removedValues[0]));
              }
              if (ref.element === 'destination') {
                dispatch(clearNumberingListDestinationAction(arg.removedValues[0]));
              }
              if (ref.element === 'soundPromptCloseId') {
                dispatch(clearNumberingListSoundPromptCloseAction(arg.removedValues[0]));
              }
              if (ref.element === 'destinationCloseId') {
                dispatch(clearNumberingListDestinationCloseAction(arg.removedValues[0]));
              }
              if (ref.element === 'destinationClose') {
                dispatch(clearNumberingListDestinationCloseAction(arg.removedValues[0]));
              }
              break;
            case 'multiselect':
              switch (ref.element) {
                case 'calendars':
                  // formListNumberingReducer.calendars ? formListNumberingReducer.calendars?.push(inputValue || arg.value) : null;
                  dispatch(addNumberingListCalendarsAction(inputValue || arg.value));
                  // ?.value ? inputValue.value : arg.value));
                  break;
                case 'schedules':
                  formListNumberingReducer.schedules ? formListNumberingReducer.schedules?.push(inputValue || arg.value) : null;
                  dispatch(addNumberingListSchedulesAction(formListNumberingReducer.schedules || inputValue || arg.value));
                  break;
                case 'soundPrompts':
                  formListNumberingReducer.soundPrompts ? formListNumberingReducer.soundPrompts?.push(inputValue || arg.value) : null;
                  dispatch(addNumberingListSoundPromptsAction(formListNumberingReducer.soundPrompts || inputValue || arg.value));
                  break;
                case 'destinationsType':
                  formListNumberingReducer.destinationsType ? formListNumberingReducer.destinationsType?.push(inputValue || arg.value) : null;
                  dispatch(addNumberingListDestinationsTypeAction(formListNumberingReducer.destinationsType || inputValue || arg.value));
                  break;
                case 'destinations':
                  formListNumberingReducer.destinations ? formListNumberingReducer.destinations?.push(inputValue || arg.value) : null;
                  dispatch(addNumberingListDestinationsAction(formListNumberingReducer.destinations || inputValue || arg.value));
                  break;
                case 'isDefaults':
                  formListNumberingReducer.isDefaults ? formListNumberingReducer.isDefaults?.push(inputValue || arg.value) : null;
                  dispatch(addNumberingListIsDefaultsAction(formListNumberingReducer.isDefaults || inputValue || arg.value));
                  break;
                // case 'calendars':
                //   formListNumberingReducer.calendars ? formListNumberingReducer.calendars?.push(inputValue || arg.value) : null;
                //   dispatch(addNumberingListDispositivosAction(formListNumberingReducer.calendars || inputValue || arg.value));
                //   break;
                default:
                  break;
              }
              break;
            case 'select-option':
              // dispatch(addNumberingListHqAction(inputValue.value))
              if (ref.element === 'Company') {
                dispatch(addNumberingListCompanyIdAction(inputValue));
              }
              if (ref.element === 'Department') {
                dispatch(addNumberingListDepartmentAction(inputValue));
              }
              if (ref.element === 'calendar') {
                dispatch(addNumberingListCalendarAction(inputValue));
              }
              if (ref.element === 'schedule') {
                dispatch(addNumberingListScheduleAction(inputValue));
              }
              if (ref.element === 'soundPrompt') {
                dispatch(addNumberingListSoundPromptAction(inputValue));
              }
              if (ref.element === 'destinationType') {
                dispatch(addNumberingListDestinationTypeAction(inputValue ? inputValue : arg.value));
              }
              if (ref.element === 'destination') {
                dispatch(addNumberingListDestinationAction(inputValue ? inputValue : arg.value));
              }
              if (ref.element === 'soundPromptClose') {
                dispatch(addNumberingListSoundPromptCloseAction(inputValue));
              }
              // if (ref.element === 'userId') {
              //   dispatch(addExtensionUserAction(inputValue));
              // }
              // if (ref.element === 'destinationCloseTypeId') {
              //   dispatch(addNumberingListDestinationCloseTypeIdAction(inputValue));
              // }
              if (ref.element === 'destinationCloseType') {
                dispatch(addNumberingListDestinationCloseTypeAction(inputValue));
              }
              if (ref.element === 'destinationClose') {
                dispatch(addNumberingListDestinationCloseAction(inputValue));
              }
              break;
            case 'toggle':
              dispatch(addNumberingListIsDefaultAction(arg.value));
              break;
            case 'remove-value':
              dispatch(removeNumberingListHqAction(arg.removedValue.value));
              break;
            case 'input':
              if (ref.element === 'id') {
                dispatch(changeNumberingListIdAction(arg.value));
              }
              if (ref.element === 'name') {
                dispatch(changeNumberingListNameAction(arg.value));
              }
              if (ref.element === 'ddi') {
                dispatch(changeNumberingListNumberAction(arg.value));
              }
              if (ref.element === 'companyId') {
                dispatch(changeNumberingListCompanyIdAction(arg.value));
              }
              if (ref.element === 'departmentId') {
                dispatch(addNumberingListDepartmentIdAction(arg.value));
              }
              if (ref.element === 'soundPromptCloseId') {
                dispatch(addNumberingListSoundPromptCloseIdAction(arg.value));
              }
              if (ref.element === 'destinationCloseId') {
                dispatch(addNumberingListDestinationCloseIdAction(arg.value));
              }
              if (ref.element === 'destinationCloseTypeId') {
                dispatch(addNumberingListDestinationCloseTypeIdAction(arg.value));
              }
              if (ref.element === 'calendar') {
                dispatch(addNumberingListCalendarAction(arg.value));
              }
              // if (ref.element === 'calendars') {

              // }
              // if (ref.element === 'schedules') {
              //   dispatch(addNumberingListTimesAction(arg.value));
              // }
              // if (ref.element === 'destinationsType') {
              //   dispatch(addNumberingListDestinationsTypeAction(arg.value));
              // }
              // if (ref.element === 'destinations') {
              //   dispatch(addNumberingListDestinationsAction(arg.value));
              // }
              // if (ref.element === 'soundPrompts') {
              //   dispatch(addNumberingListLocutionsAction(arg.value));
              // }
              break;
            case 'id':
              if (ref.element === 'id') {
                dispatch(changeNumberingListIdAction(arg.value));
              }
              break;

            default:
              break;
          }
          break;
        case 'marca':
          switch (arg.action) {
            case 'select-option':
              if (ref.element === 'marca') {
                dispatch(changeMarcaNombreAction(inputValue));
              }
              break;

            case 'input':
              if (ref.element === 'id') {
                dispatch(changeMarcaIdAction(arg.value));
              }
              if (ref.element === 'marca') {
                dispatch(changeMarcaNombreAction(arg.value));
              }
              break;
            case 'clearForm':
              dispatch(clearMarcaFormAction());
              break;
            case 'clear':
              break;
            default:
              break;
          }
          break;
        case 'modelo':
          switch (arg.action) {
            case 'select-option':
              if (ref.element === 'marca') {
                dispatch(changeModeloMarcaAction(inputValue));
              }
              break;
            case 'input':
              if (ref.element === 'id') {
                dispatch(changeModeloIdAction(arg.value));
              }
              if (ref.element === 'modelo') {
                dispatch(changeModeloNombreAction(arg.value));
              }
              break;
            case 'clearForm':
              dispatch(clearModeloFormAction());
              break;
            case 'clear':
              if (ref.element === 'modelo') {
                dispatch(clearPlantillaModeloAction());
              }
              break;
            default:
              break;
          }
          break;
        case 'plantilla':
          switch (arg.action) {
            case 'select-option':
              if (ref.element === 'marca') {
                dispatch(changePlantillaMarcaAction(inputValue));
              }
              if (ref.element === 'modelo') {
                dispatch(changePlantillaModeloAction(inputValue));
              }
              break;
            case 'input':
              if (ref.element === 'id') {
                dispatch(changePlantillaIdAction(arg.value));
              }
              if (ref.element === 'nombre') {
                dispatch(changePlantillaNombreAction(arg.value));
              }
              if (ref.element === 'blfs') {
                dispatch(changePlantillaBlfsAction(inputValue || arg.value));
              }
              if (ref.element === 'observaciones') {
                dispatch(changePlantillaObservacionesAction(inputValue || arg.value));
              }
              break;
            case 'clearForm':
              dispatch(clearPlantillaFormAction());
              break;
            case 'clear':
              if (ref.element === 'modelo') {
                dispatch(clearPlantillaModeloAction());
              }
              break;
            default:
              break;
          }
          break;
        case 'extension':
          switch (arg.action) {
            case 'select-option':
              if (ref.element === 'typeExtension') {
                dispatch(addTypeExtensionAction(inputValue));
              }
              if (ref.element === 'marca') {
                dispatch(changeExtensionMarcaAction(inputValue));
              }
              if (ref.element === 'modelo') {
                dispatch(changeExtensionModeloAction(inputValue));
              }
              if (ref.element === 'plantilla') {
                dispatch(changeExtensionPlantillaAction(inputValue));
              }
              if (ref.element === 'companyId') {
                dispatch(addExtensionCompanyIdAction(inputValue));
              }
              if (ref.element === 'record') {
                dispatch(addExtensionRecordAction(arg.value));
              }
              if (ref.element === 'pikedGroup') {
                dispatch(addExtensionPikedGroupAction(inputValue));
              }
              if (ref.element === 'userId') {
                dispatch(addExtensionUserAction(inputValue));
              }
              if (ref.element === 'allow') {
                dispatch(addExtensionAllowAction(inputValue));
              }
              if (ref.element === 'department') {
                dispatch(addExtensionDepartmentAction(inputValue));
              }
              if (ref.element === 'identification') {
                dispatch(addExtensionIdentificationAction(inputValue));
              }
              break;
            case 'multiselect':
              if (ref.element === 'identification') {
                dispatch(addExtensionIdentificationAction(inputValue));
              }
              if (ref.element === 'allow') {
                // arg.option ? inputValue.push(arg.option) : inputValue;
                // const newValue = inputValue;
                dispatch(addExtensionAllowAction(inputValue));
              }
              break;
            case 'toggle':
              if (ref.element === 'voicemailActive') {
                dispatch(changeExtensionVoicemailActiveAction(arg.value));
              }
              if (ref.element === 'filterType') {
                dispatch(changeExtensionFilterTypeAction(arg.value));
              }
              if (ref.element === 'callWaiting') {
                dispatch(changeExtensionCallWaitingAction(arg.value));
              }
              break;

            case 'remove-value':
              if (ref.element === 'identification') {
                dispatch(removeExtensionIdentificationAction(arg.removedValue.value));
              }
              if (ref.element === 'typeExtension') {
                dispatch(removeTypeExtensionAction(arg.removedValue.value));
              }
              if (ref.element === 'companyId') {
                dispatch(removeExtensionCompanyIdAction(arg.removedValue.value));
              }

              if (ref.element === 'pikedGroup') {
                dispatch(removeExtensionPikedGroupAction(arg.removedValue.value));
              }
              if (ref.element === 'allow') {
                dispatch(removeExtensionAllowAction(inputValue));
              }

              break;
            case 'input':
              if (ref.element === 'name') {
                dispatch(changeExtensionNameAction(arg.value));
              }
              if (ref.element === 'extension') {
                dispatch(changeExtensionNumberAction(arg.value));
              }
              if (ref.element === 'password') {
                dispatch(changeExtensionPasswordAction(arg.value));
              }

              if (ref.element === 'id') {
                dispatch(changeExtensionIdAction(arg.value));
              }
              if (ref.element === 'callForward') {
                dispatch(changeExtensionCallForwardAction(arg.value));
              }
              if (ref.element === 'callForwardNA') {
                dispatch(changeExtensionCallForwardNAAction(arg.value));
              }
              if (ref.element === 'sipPeerId') {
                dispatch(changeExtensionSipPeerIdAction(arg.value));
              }
              if (ref.element === 'AstSipPeer') {
                dispatch(changeExtensionSipPeerIdAction(arg.value));
              }
              if (ref.element === 'identification') {
                dispatch(addExtensionIdentificationAction(arg.value || inputValue));
              }
              break;
            case 'select':
              if (ref.element === 'typeExtension') {
                dispatch(addTypeExtensionAction(inputValue));
              }
              break;
            case 'clearForm':
              dispatch(clearExtensionFormAction());
              break;
            case 'clear':
              if (ref.element === 'pikedGroup') {
                dispatch(clearExtensionsPikedGroupAction());
              }
              if (ref.element === 'allow') {
                dispatch(clearExtensionAllowAction());
              }
              if (ref.element === 'identification') {
                dispatch(removeExtensionIdentificationAction());
              }
              if (ref.element === 'typeExtension') {
                dispatch(removeTypeExtensionAction());
              }
              if (ref.element === 'companyId') {
                dispatch(removeExtensionCompanyIdAction());
              }
              if (ref.element === 'department') {
                dispatch(removeExtensionDepartmentAction());
              }
              if (ref.element === 'categories') {
                dispatch(removeExtensionCategoriesAction());
              }

              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  });

  const changePassword = useCallback((newPassword, id) => {
    const newPasswordData = {
      id,
      newPassword,
    };

    const response = dispatch(changeExtensionPasswordThunk(newPasswordData));
    return response;
  });

  const getSipPassword = useCallback((extension) => {
    const response = getSipPasswordThunk(extension);
    return response;
  });

  const updateFileExtension = useCallback(
    (file) => {
      const response = dispatch(updateFileExtensionThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileListNumbering = useCallback(
    (file) => {
      const response = dispatch(uploadFileListNumberingThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );
  return {
    arrayNumberingHook,
    loadListNumbering,
    arrayNumberingExtensionsHook,
    deleteMarcas,
    deleteModelos,
    deletePlantillas,
    arrayMarcasHook,
    createMarca,
    arrayModelosHook,
    createModelo,
    arrayPlantillasHook,
    createPlantilla,
    formMarcaReducer,
    formModeloReducer,
    formPlantillaReducer,
    updatePlantilla,
    updateMarca,
    updateModelo,
    getNumberingList,
    createNumberingList,
    updateNumberingList,
    deleteNumberingList,
    getExtensions,
    getMarcas,
    getModelos,
    getPlantillas,
    createExtension,
    deleteExtensions,
    updateExtensions,
    formExtensionsReducer,
    listNumberingForm,
    formListNumberingReducer,
    getState,
    changePassword,
    getSipPassword,
    updateFileExtension,
    uploadFileListNumbering,
    loading,
    ddiLink,
  };
};

export default useNumbering;
