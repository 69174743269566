export const tooltipsQueue = {
  tooltips: [{
    'Cola': {
      extension: 'Configurar la extensión para la cola.',
      name: 'Indicar nombre para la cola.',
      strategyId:
        'Las opciones son: Ring all: llamar a todos los agentes disponibles simultáneamente hasta que un agente responda. Lineal: llamar a los agentes en el orden especificado. Least recent: llamar al agente que ha sido llamado menos recientemente. Fewest calls: llamar al agente con menos llamadas completadas. Random: llamar a un agente aleatorio. Round Robin: llamar a los agentes usando un horario de round robin con memoria.',
      mohId: 'Selecciona la música en esperapara esta cola. Las listas pueden gestionarse desde el menú "Música e espera".',
      timeHangup: 'Configura el tiempo de espera (en segundos) despues de finalizar la llamada antes para recibir la siguiente llamada. Si está a 0, no habrá espera.',
      time_retry: 'Configurar número de segundos antes del iniciar el siguiente agente. Mínimo 1.',
      time_ring: 'Configurar número de segundos antes de que salte el agente. Mínimo 5.',
      isCustomWelcome: '',
      customWelcomeId: 'Tono inicial cuando se llama a un agente.',
      isSurvey: '',
      survey_soundpromptId: 'Cuando el agente cuelga el sistema preguntará si se quiere puntuar el nivel de satisfacción con una escala de 1 a 5, siendo 5 el máximo.',
      survey_soundprompt: 'Cuando el agente cuelga el sistema preguntará si se quiere puntuar el nivel de satisfacción con una escala de 1 a 5, siendo 5 el máximo.',

      queueMaxWaitTime: 'Total tiempo estancia en la cola. Si no es contestada antes del intervalo indicado se dará por finalizada.',
      destinationId: 'La llamada será enrutada si nadie contesta a la cola.',
      queueisCycle: '',
      displayNameEdited: 'Introduce un nombre. Es posible utilizar variables. Ejemplo: ${QUEUENAME} | ${CALLERIDNUM} | MI TEXTO',
      customPromptId: 'Cuando se use un mensaje personalizado, presionar 1 para indicar el destino de fallover o seguir esperando.',
      promptDestinationId: 'Despues del intervalo indicado se preguntará de presionar 1 para ser redireccionado directamente al destino de fallover.',
      queueMaxLength: 'Configurar el máximo de llamadas para ser enviadas a la cola a la vez. Este número no incluye llamadas conectadas con agentes, sólo llamadas que están todavía en la cola.',

      maxWaitTime: 'Configurar el máximo de llamadas para ser enviadas a la cola a la vez. Este número no incluye llamadas conectadas con agentes, sólo llamadas que están todavía en la cola.',
      queueAnnounceInterval: 'El intervalo en el que se avisa la posicion de la llamada en la cola.',
      leaveWhenEmpty:
        'Configura si se desconecta o no basado en el estado del agente. Yes: La llamada sera desconectada si los agentes estan pausados o deshabilitados. No: nunca se desconecta. Strict (por defecto): Se descontan si los agentes estan pausado o deshabilitados o no hay.',
      dialEmpty:
        'Configura si se puede llamar a colas sin agentes. Yes: Siempre. No (Default): No se puede llamar a la cola si hay agentes aunque esten pausados o deshabilitados. Strict: No se puede si no hay agentes o estan pausados o deshabilitados.',
      falloverDestinationId: 'Serán redireccionadas si la cola está vacía o si los agentes no estan logueados',
      },
      // survey_ivrId: 'When the agent hangs up, the system will play the prompt to ask the caller to rate their satisfaction scale from 1 to 5, where 5 is the highest.',
      // queueMaxWaitTime: 'Amount of time to keep a caller in queue. If a call is not answered within the timeout period, it will be terminated.',
      // extension: 'Configure the extension for the call queue.',
      // name: 'Define the name for the call queue. For example, "Sales".',
      // selectStrategy:
      //   'The options are: Ring All: Ring all available agents simultaneously until one agent answers.Linear: Ring agents in the specified order.Least Recent: Ring the agent who has been called the least recently.Fewest Calls: Ring the agent with the fewest completed calls.Random: Ring a random agent.Round Robin: Ring agents using round robin schedule with memory.',
      // queueCustomPrompt: 'When playing a custom prompt, press 1 to enter the failover destination or continue waiting in queue.',
      // promptDestinationId: 'After the specified amount of time, the caller will be prompted to press 1 to immediately get redirected to the configured failover destination.',
      // queueMaxLength:
      //   'Configure the maximum number of calls to be queued at once. This number does not include calls that have been connected with agents, only calls that are still in queue. When this maximum value is exceeded, the caller will hear a busy tone and be forwarded to the configured failover destination. Default value is 0 (unlimited).',
      // maxWaitTime:
      // 'Configure the maximum number of calls to be queued at once. This number does not include calls that have been connected with agents, only calls that are still in queue. When this maximum value is exceeded, the caller will hear a busy tone and be forwarded to the configured failover destination. Default value is 0 (unlimited).',
      // leaveWhenEmpty:
      //   'Configures whether or not to disconnect callers in queue based on agent status. There are three options: Yes: Callers will be disconnected from queue if all agents are paused or unavailable. No: Callers will never be disconnected from queue. Strict (Default): Callers will be disconnected from the queue if there are no agents or if all agents are unavailable or paused.',
      // dialEmpty:
      //   'Configures whether or not callers can dial into a call queue with no agents. There are three options: Yes: Callers can always dial into a queue even if it is empty. No (Default): Callers cannot dial into a queue unless it has agents (regardless of their status). This also means that callers can dial into a queue even if all agents are unavailable or paused. Strict: Callers cannot dial into a queue if there are no agents or if all agents are unavailable or paused.',
      // destinationId: 'The call will be routed to this destination if no one in this queue answers the call.',
    
    // { musiconhold: 'Define the name for the call queue. For example, "Sales".' },
    // { maxqueue: 'Define the name for the call queue. For example, "Sales".' },

    // { wrapup: 'Configure the delay time (in seconds) after a completed call before a new call will ring. If set to 0, there will be no delay between call completion and a new call.' },
    // { retrytime: '' },
    // { timering: '' },
    // {
    //   autorecord:
    //     'All Calls: All incoming calls to this extension will be recorded.OFF: This extensions calls will not be recorded.Only External Calls: All external calls of the extension will be recorded.Only Internal Calls: All internal calls of the extension will be recorded.',
    // },
    // { isrecord: 'If enabled, call queue members can use recording feature code to pause or restore current queues recording.' },
    // { customprompt: ' },
    // { surveyprompt: 'When the agent hangs up, the system will play the prompt to ask the caller to rate their satisfaction scale from 1 to 5, where 5 is the highest.' },
    // { maxwaittime: 'Amount of time to keep a caller in queue. If a call is not answered within the timeout period, it will be terminated.' },
    // { destination: '' },
    // { enableannouce: 'If enabled, the system will inform callers waiting in the queue of their positions in line.' },
    // { },
    // {
    //   colavacia:
    //     'Configures whether or not to disconnect callers in queue based on agent status. There are three options:Yes: Callers will be disconnected from queue if all agents are paused or unavailable.No: Callers will never be disconnected from queue.Strict (Default): Callers will be disconnected from the queue if there are no agents or if all agents are unavailable or paused.',
    // },
    // { falloverdestination: 'Callers will be redirected to this destination if the queue is empty or if all agents are not logged in.' },
    // {
    //   noentrarsinagentes:
    //     'Configures whether or not callers can dial into a call queue with no agents. There are three options:Yes: Callers can always dial into a queue even if it is empty.No (Default): Callers cannot dial into a queue unless it has agents (regardless of their status). This also means that callers can dial into a queue even if all agents are unavailable or paused. Strict: Callers cannot dial into a queue if there are no agents or if all agents are unavailable or paused.',
    // },
    // {
    //   sivacio:
    //     'Configures whether or not to disconnect callers in queue based on agent status. There are three options: Yes: Callers will be disconnected from queue if all agents are paused or unavailable.No: Callers will never be disconnected from queue. Strict (Default): Callers will be disconnected from the queue if there are no agents or if all agents are unavailable or paused.',
    // },
  }],
};
