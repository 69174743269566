import { arraysAreEqual } from '../../../utils/AppUtils';
import { actionTypesApplications } from '../../actions/applications/actionTypes';

export const applicationsLocutionsGetReducer = (locutions = [], action) => {
  let newLocutions;
  switch (action.type) {
    case actionTypesApplications.getLocutions:
      newLocutions = [...action.locutions];
      break;
    default:
      newLocutions = locutions;
  }
  return newLocutions;
};

export const locutionsObjectFormReducer = (state = { locutionName: '', file: [], headquarters: '', id: '' }, action) => {
  switch (action.type) {
    case actionTypesApplications.changeApplicationsLocutionsFormName:
      state = {
        ...state,
        locutionName: action.payload,
      };
      break;

    case actionTypesApplications.addApplicationsLocutionsFormHeadquarters:
      state = {
        ...state,
        headquarters: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormSoundpromptid:
      state = {
        ...state,
        soundpromptId: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisvirtual:
      state = {
        ...state,
        isvirtual: action.payload,
      };
      break;
    case actionTypesApplications.removeApplicationsLocutionsFormHeadquarters:
      state = {
        ...state,
        headquarters: '',
      };
      break;
    case actionTypesApplications.addApplicationsLocutionsFormFile:
      state = {
        ...state,
        file: action.payload,
      };
      break;
    case actionTypesApplications.changeApplicationsLocutionsFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.clearLocutionsForm:
      state = { locutionName: '', file: [], headquarters: '', id: '' };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export const APPLocutionsDeleteReducer = (locution = {}, action) => {
  let mewLocutionDelete;
  switch (action.type) {
    case actionTypesApplications.deleteApplicationsLocutions:
      mewLocutionDelete = { ...action.locution };
      break;
    default:
      mewLocutionDelete = locution;
  }
  return mewLocutionDelete;
};

export const getConferencesReducer = (conferences = [], action) => {
  let newConferences;
  switch (action.type) {
    case actionTypesApplications.getConferences:
      newConferences = [...action.conferences];
      break;
    default:
      newConferences = conferences;
      break;
  }
  return newConferences;
};

export const conferenceFormReducer = (
  state = {
    name: '',
    number: '',
    pin: '',
    record: false,
    company: [],
    id: '',
  },
  action
) => {
  switch (action.type) {
    case actionTypesApplications.changeConferenceFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesApplications.changeConferenceFormNumber:
      state = {
        ...state,
        number: action.payload,
      };
      break;
    case actionTypesApplications.changeConferenceFormPin:
      state = {
        ...state,
        pin: action.payload,
      };
      break;
    case actionTypesApplications.changeConferenceFormRecord:
      state = {
        ...state,
        record: action.payload,
      };
      break;
    case actionTypesApplications.addConferenceFormCompany:
      state = {
        ...state,
        company: action.payload,
      };
      break;
    case actionTypesApplications.changeConferenceFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.removeConferencesCompany:
      state = {
        ...state,
        company: [],
      };
      break;
    case actionTypesApplications.clearConferenceForm:
      state = {
        name: '',
        number: '',
        pin: '',
        record: false,
        company: [],
        id: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getMailboxesReducer = (mailboxes = [], action) => {
  let newMailboxes;
  switch (action.type) {
    case actionTypesApplications.getMailboxes:
      newMailboxes = [...action.mailboxes];
      break;
    default:
      newMailboxes = mailboxes;
      break;
  }
  return newMailboxes;
};

export const mailboxesFormReducer = (
  state = {
    number: '',
    name: '',
    pin: '',
    company: [],
    email: '',
    soundPrompt: [],
    id: '',
    astVoicemailId: '',
  },
  action
) => {
  switch (action.type) {
    case actionTypesApplications.changeMailboxesFormNumber:
      state = {
        ...state,
        number: action.payload,
      };
      break;
    case actionTypesApplications.changeMailboxesFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesApplications.changeMailboxesFormPin:
      state = {
        ...state,
        pin: action.payload,
      };
      break;
    case actionTypesApplications.addMailboxesFormCompany:
      state = {
        ...state,
        company: action.payload,
      };
      break;
    case actionTypesApplications.changeMailboxesFormEmail:
      state = {
        ...state,
        email: action.payload,
      };
      break;
    case actionTypesApplications.addMailboxesFormSoundPrompt:
      state = {
        ...state,
        soundPrompt: action.payload,
      };
      break;
    case actionTypesApplications.changeMailboxesFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.changeMailboxesFormAstVoicemailId:
      state = {
        ...state,
        astVoicemailId: action.payload,
      };
      break;

    case actionTypesApplications.removeMailboxesCompany:
      state = {
        ...state,
        company: [],
      };
      break;
    case actionTypesApplications.removeMailboxesSoundPrompt:
      state = {
        ...state,
        soundPrompt: [],
      };
      break;

    case actionTypesApplications.clearMailboxesForm:
      state = {
        number: '',
        name: '',
        pin: '',
        company: [],
        email: '',
        soundPrompt: [],
        id: '',
        astVoicemailId: '',
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};

export const getSongWaitingReducer = (songWaiting = [], action) => {
  let newSongWaiting;
  switch (action.type) {
    case actionTypesApplications.getSongWaiting:
      newSongWaiting = [...action.songWaitings];
      break;

    default:
      newSongWaiting = songWaiting;
      break;
  }
  return newSongWaiting;
};

export const getSongWaitingLinkReducer = (songWaitingLink = [], action) => {
  let newSongWaitingLink;
  switch (action.type) {
    case actionTypesApplications.getSongWaitingLink:
      newSongWaitingLink = [...action.songWaitingLink];
      break;

    default:
      newSongWaitingLink = songWaitingLink;
      break;
  }
  return newSongWaitingLink;
};

export const songWaitingFormReducer = (
  state = {
    name: '',
    company: [],
    soundPrompt: [],
    id: '',
  },
  action
) => {
  // let indexSoundPrompt = -1;
  // let newArraySoundPrompt = [];

  const arraySoundPrompt = { ...state };
  // const index = arraySoundPrompt?.map((p) => p.value).indexOf(action.songWaitings?.length <= 1 ? Number(action.payload[0].value) : Number(action.payload?.value));

  switch (action.type) {
    case actionTypesApplications.changeSongWaitingFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;

    case actionTypesApplications.addSongWaitingFormCompany:
      state = {
        ...state,
        company: action.payload,
      };
      break;
    case actionTypesApplications.changeSongWaitingFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.addSongWaitingFormSoundPrompt:
      // arraySoundPrompt=arraySoundPrompt.length === 0 ? [] : arraySoundPrompt;
      // newArraySoundprompt = arraySoundPrompt?.length === 0 ? action.payload : arraySoundPrompt;
      // newArraySoundPrompt =
      //   action?.payload[0] !== undefined && action?.payload.length > 1 ? action.payload : action.payload[0] !== undefined && action.payload.length === 1 ? action.payload[0] : action.payload;
      // if (arraySoundPrompt.soundPrompt.length !== undefined && arraySoundPrompt.soundPrompt.length === 0) {
      //   arraySoundPrompt.soundPrompt = newArraySoundPrompt.length > 1 ? newArraySoundPrompt : [newArraySoundPrompt];
      // } else {
      //   if (arraysAreEqual(arraySoundPrompt.soundPrompt, Array.isArray(newArraySoundPrompt) ? newArraySoundPrompt : [newArraySoundPrompt]) !== true) {
      //     arraySoundPrompt.soundPrompt.push(newArraySoundPrompt);
      //   }
      // }
      // if (arraySoundPrompt.soundPrompt === '' || arraySoundPrompt.soundPrompt.length === 0) {
      //   arraySoundPrompt.soundPrompt = action.payload;
      // } else if (!arraysAreEqual(arraySoundPrompt.soundPrompt, action.payload)) {
      // if (action.payload.value !== undefined) {
      //   arraySoundPrompt.soundPrompt = action.payload;
      // } else {
      // arraySoundPrompt.soundPrompt.push(action.payload);
      // }(

      // if (action.payload?.length) {
      //   arraySoundPrompt.soundPrompt = action.payload;
      // } else {
      //   arraySoundPrompt.soundPrompt.push(action.payload);
      // }
      // if (arraySoundPrompt.soundPrompt === '') {
      //   arraySoundPrompt.soundPrompt = action.payload;
      // } else if (!arraysAreEqual(arraySoundPrompt.soundPrompt, action.payload)) {
      //   if (action.payload.length) {
      //     arraySoundPrompt.soundPrompt = action.payload;
      //   } else {
      //     arraySoundPrompt.soundPrompt.push(action.payload);
      //   }

      state = {
        ...state,
        soundPrompt: action.payload,
      };
      break;
    case actionTypesApplications.removeSongWaitingFormSoundPrompt:
      // indexSoundPrompt = arraySoundPrompt.soundPrompt.map((p) => p.value).indexOf(action.payload[0].value);
      // if (indexSoundPrompt > -1) {
      //   arraySoundPrompt.soundPrompt.splice(indexSoundPrompt, 1);
      // }
      arraySoundPrompt.soundPrompt = action.payload;
      // state.soundPrompt = action.payload;
      // state = { ...state, soundPrompt: arraySoundPrompt.soundPrompt };
      state = { ...state, soundPrompt: action.payload };

      break;

    case actionTypesApplications.removeSongWaitingFormCompany:
      state = {
        ...state,
        company: [],
      };
      break;

    case actionTypesApplications.clearSongWaitingFormSoundPrompt:
      state = {
        ...state,
        soundPrompt: [],
      };
      break;

    case actionTypesApplications.clearSongWaitingForm:
      state = {
        name: '',
        company: [],
        soundPrompt: [],
        id: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getShortMarkingReducer = (shortMarking = [], action) => {
  let newShortMarking;
  switch (action.type) {
    case actionTypesApplications.getShortMarking:
      newShortMarking = [...action.shortMarking];
      break;
    default:
      newShortMarking = shortMarking;
      break;
  }
  return newShortMarking;
};

export const shortMarkingFormReducer = (state = { nombre: '', numCorto: '', destino: '', company: [], id: '' }, action) => {
  switch (action.type) {
    case actionTypesApplications.changeShortMarkingFormNombre:
      state = {
        ...state,
        nombre: action.payload,
      };
      break;
    case actionTypesApplications.changeShortMarkingFormNumCorto:
      state = {
        ...state,
        numCorto: action.payload,
      };
      break;
    case actionTypesApplications.changeShortMarkingFormDestino:
      state = {
        ...state,
        destino: action.payload,
      };
      break;
    case actionTypesApplications.addShortMarkingFormCompany:
      state = {
        ...state,
        company: action.payload,
      };
      break;
    case actionTypesApplications.changeShortMarkingFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.removeShortMarkingCompany:
      state = {
        ...state,
        company: [],
      };
      break;
    case actionTypesApplications.clearShortMarkingForm:
      state = {
        nombre: '',
        numCorto: '',
        destino: '',
        company: [],
        id: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getAudiosReducer = (state = { audio: [] }, action) => {
  switch (action.type) {
    case actionTypesApplications.getAudios:
      state = {
        ...state,
        audio: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getIvrReducer = (ivr = [], action) => {
  let newIvr;
  switch (action.type) {
    case actionTypesApplications.getIvr:
      newIvr = [...action.ivrs];
      break;
    default:
      newIvr = ivr;
      break;
  }
  return newIvr;
};

export const ivrFormReducer = (state, action) => {
  const { item, value } = action?.payload || {};

  // const title = action.payload && action.payload.item?.name !== undefined ? action.payload.item.name : {};
  const destinationIndex = state?.destination?.findIndex((dest) => dest.key === item?.value);

  switch (action.type) {
    case actionTypesApplications.changeIvrFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesApplications.changeIvrTimeout:
      state = {
        ...state,
        timeout: action.payload,
      };
      break;
    case actionTypesApplications.addIvrFormCompany:
      state = {
        ...state,
        company: action.payload,
      };
      break;
    case actionTypesApplications.addIvrFormSoundPrompt:
      state = {
        ...state,
        soundPrompt: action.payload,
      };
      break;
    case actionTypesApplications.addIvrFormTimeOut:
      state = {
        ...state,
        timeout: action.payload,
      };
      break;
    case actionTypesApplications.changeIvrFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.removeIvrCompany:
      state = {
        ...state,
        company: [],
      };
      break;
    case actionTypesApplications.removeIvrSoundPrompt:
      state = {
        ...state,
        soundPrompt: [],
      };
      break;

    case actionTypesApplications.changeIvrFormDestinations:
      if (value === 'timeOut') {
        state.destination.forEach((item2) => {
          if (item2.key === 'Si no se pulsa') {
            item2.timeOut = item;
          }
        });
      } else {
        const newDestination = { ...item, key: value };
        const existingDestinationIndex = state.destination.findIndex((dest) => dest.key === value);
        if (existingDestinationIndex !== -1) {
          state = {
            ...state,
            destination: [...state.destination.slice(0, existingDestinationIndex), newDestination, ...state.destination.slice(existingDestinationIndex + 1)],
          };
        } else {
          state = {
            ...state,
            destination: [...state.destination, newDestination],
          };
        }
      }
      break;
    case actionTypesApplications.removeIvrFormDestinations:
      if (destinationIndex !== -1) {
        state = {
          ...state,
          // destination: [...state.destination.slice(0, destinationIndex), ...state.destination.slice(destinationIndex)],
          destination: state.destination.filter(function (itemDestination) {
            return itemDestination.key !== value;
          }),
        };
      }
      break;
    case actionTypesApplications.clearIvrForm:
      state = {
        name: '',
        company: [],
        soundPrompt: [],
        id: '',
        destination: [],
        timeout: 2,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getDestinationReducer = (destination = [], action) => {
  let newDestination;
  switch (action.type) {
    case actionTypesApplications.getDestination:
      newDestination = [...action.destination];
      break;
    default:
      newDestination = destination;
      break;
  }
  return newDestination;
};

export const getQueueReducer = (queues = [], action) => {
  let newQueues;
  switch (action.type) {
    case actionTypesApplications.getQueue:
      newQueues = [...action.queues];
      break;
    default:
      newQueues = queues;
      break;
  }
  return newQueues;
};

export const queueFormReducer = (
  state = {
    id: '',
    name: '',
    number: '',
    mohId: '',
    strategyId: '',
    maxWaitTime: '',
    extensionId: '',
    extensions: '',
    isvirtual: '',
    time_retry: '',
    time_between_calls: '',
    time_ring: '',
    isnotify_ivr: '',
    survey_soundpromptId: '',
    survey_soundprompt: '',
    time_wait: '',
    destinationId: '',
    createdAt: '',
    updatedAt: '',
    queueMaxLength: '',
    timeHangup: '',
    isRecord: '',
    alertinfo: '',
    promptDestinationId: '',
    promptDestinationTypeId: '',
    promptDestination: '',
    promptDestinationType: '',
    isHoldLog: '',
    falloverDestinationId: '',
    dialEmpty: '',
    leaveWhenEmpty: '',
    isCustomWelcome: '',
    customWelcomeId: '',
    isCustomPrompt: '',
    customPromptId: '',
  },
  action
) => {
  let arrayPreferences = { ...state }.preferences;
  let arrayAgents = { ...state }.agents;
  let arrayAgentsInactive = { ...state }.agentsInactive;
  let arrayAgentsExternal = { ...state }.agentsExternal;
  // let arrayExtensions = action.arrayNumbering;

  const index = arrayPreferences?.map((p) => p?.value).indexOf(action.payload?.value);
  const indexAgents = arrayAgentsInactive?.map((p) => p?.id).indexOf(action.payload?.id);
  const indexAgentsExternal = arrayAgentsExternal?.map((p) => p?.id).indexOf(action.payload?.id);
  const iExternal = arrayAgentsExternal?.map((p) => p.id).indexOf(action.payload?.id);
  const iAgent = arrayAgents?.map((p) => p.id).indexOf(action.payload?.id);
  // const location = useLocation();
  const arrayExtensions = { ...state };
  // let keys = [];
  // let arrayExist = '';
  // // let newArrayExtensions = [];
  // let newArray = [];

  switch (action.type) {
    case actionTypesApplications.changeQueueFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormNumber:
      state = {
        ...state,
        number: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormTimeRing:
      state = {
        ...state,
        time_ring: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormMaxWaitingUsers:
      state = {
        ...state,
        max_waiting_users: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormCallToUseExtensions:
      state = {
        ...state,
        call_to_use_extensions: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormMohId:
      state = {
        ...state,
        mohId: Number(action.payload.value),
        moh: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormStrategyId:
      state = {
        ...state,
        strategyId: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormleaveWhenEmpty:
      state = {
        ...state,
        leaveWhenEmpty: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormdialEmpty:
      state = {
        ...state,
        dialEmpty: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormFallOverIdType:
      state = {
        ...state,
        falloverDestinationTypeId: action.payload?.label,
      };
      break;
    case actionTypesApplications.changeQueueFormisHoldLog:
      state = {
        ...state,
        isHoldLog: action.payload,
      };
      break;

    case actionTypesApplications.changeQueueFormmaxWaitTime:
      state = {
        ...state,
        maxWaitTime: action.payload,
      };
      break;

    case actionTypesApplications.addQueueFormAlertinfo:
      state = {
        ...state,
        alertinfo: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormExtensionId:
      state = {
        ...state,
        extensionId: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormExtensions:
      if (arrayExtensions.extensions === '') {
        arrayExtensions.extensions = action.payload;
      } else if (!arraysAreEqual(arrayExtensions.extensions, action.payload)) {
        if (action.payload.length) {
          arrayExtensions.extensions = action.payload;
        } else {
          arrayExtensions.extensions.push(action.payload);
        }
      }

      state = {
        ...state,
        extensions: arrayExtensions.extensions,
      };
      break;
    case actionTypesApplications.changeQueueFormTimeoutQueue:
      state = {
        ...state,
        timeout_queue: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormRetry:
      state = {
        ...state,
        time_retry: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormTimeoutPriority:
      state = {
        ...state,
        timeout_priority: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisRecord:
      state = {
        ...state,
        isRecord: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisWelcome:
      state = {
        ...state,
        isCustomWelcome: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisPrompt:
      state = {
        ...state,
        isCustomPrompt: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormCompanyId:
      state = {
        ...state,
        companyId: {
          label: action.payload?.label,
          value: action.payload?.value,
        },
      };
      break;
    case actionTypesApplications.addQueueFormMusicClass:
      state = {
        ...state,
        musicclass: {
          value: action.payload?.value,
          label: action.payload?.label,
        },
      };
      break;

    case actionTypesApplications.addQueueFormDestination:
      state = {
        ...state,
        Destination: {
          value: action.payload?.value,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormDestinationId:
      state = {
        ...state,
        destinationId: action.payload?.value || action.payload,
        Destination: {
          value: action.payload?.value ? action.payload?.value : action?.payload,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
      };
      break;
    case actionTypesApplications.addQueueFormDestinationType:
      state = {
        ...state,
        DestinationType: {
          value: action.payload?.value,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
        // destinationTypeId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormDestinationTypeId:
      state = {
        ...state,
        destinationTypeId: action.payload?.value || action.payload,
        // DestinationType: action.payload,
      };
      break;

    case actionTypesApplications.addQueueFormPromptDestinationTypeId:
      state = {
        ...state,
        promptDestinationTypeId: action.payload,
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormPromptDestinationType:
      state = {
        ...state,
        promptDestinationType: {
          value: action.payload?.value,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormPromptDestinationId:
      state = {
        ...state,
        promptDestinationId: action.payload?.value || action.payload,
        promptDestination: {
          value: action.payload?.value ? action.payload?.value : action.payload,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
      };
      break;
    case actionTypesApplications.addQueueFormFallOverId:
      state = {
        ...state,
        falloverDestinationId: action.payload,
        // FalloverDestinationData: {
        //   value: action.payload?.value ? action.payload.value : action.payload,
        //   label: action.payload?.label,
        //   // ref: action.payload?.ref,
        // },
      };
      break;
    case actionTypesApplications.addQueueFormFallOverTypeId:
      state = {
        ...state,
        falloverDestinationTypeId: action.payload,
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormFallOver:
      state = {
        ...state,
        FalloverDestination: {
          value: action.payload?.value,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormFallOverType:
      state = {
        ...state,
        FalloverDestinationType: {
          value: action.payload?.value,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormPromptDestination:
      state = {
        ...state,
        promptDestination: {
          value: action.payload?.value,
          label: action.payload?.label,
          // ref: action.payload?.ref,
        },
        // destinationId: action.payload?.value,
      };
      break;
    case actionTypesApplications.addQueueFormPreferences:
      if (index > -1) {
        arrayPreferences.splice(index, 1);
      } else {
        arrayPreferences.push(action.payload);
      }
      state = {
        ...state,
        record: arrayPreferences,
      };
      break;

    case actionTypesApplications.addQueueFormSoundPrompt:
      state = {
        ...state,
        soundPrompt: action.payload,
        //  {
        //   label: action.payload?.label,
        //   value: action.payload?.value,
        // },
      };
      break;
    case actionTypesApplications.addQueueFormStrategy:
      state = {
        ...state,
        strategyId: {
          label: action.payload?.label,
          value: action.payload?.value,
        },
      };
      break;
    // case actionTypesApplications.addQueueFormFallOverId:
    //   state = {
    //     ...state,
    //     falloverDestinationId: action.payload?.label,
    //   };
    //   break;
    case actionTypesApplications.changeQueueFormStrategy:
      state = {
        ...state,
        Strategy: action.payload,
      };
      break;

    case actionTypesApplications.addQueueFormAgents:
      state = {
        ...state,
        agents: action.payload,
      };
      break;

    case actionTypesApplications.changeQueueFormMaxLength:
      state = {
        ...state,
        queueMaxLength: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormTimeoutHang:
      state = {
        ...state,
        timeHangup: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormAgentIsActive:
      if (indexAgents > -1) {
        arrayAgentsInactive.splice(indexAgents, 1);
      } else {
        arrayAgentsInactive.push(action.payload);
      }
      state = {
        ...state,
        agentsInactive: arrayAgentsInactive,
      };
      break;
    case actionTypesApplications.changeQueueFormAgentIsExternal:
      if (indexAgentsExternal > -1) {
        arrayAgentsExternal.splice(indexAgentsExternal, 1);
      } else {
        arrayAgentsExternal.push(action.payload);
      }
      state = {
        ...state,
        agentsExternal: arrayAgentsExternal,
      };
      break;

    case actionTypesApplications.removeQueueFormAgentDelete:
      if (iExternal > -1) {
        arrayAgentsExternal.splice(iExternal, 1);
      }
      if (iAgent > -1) {
        arrayAgents.splice(iAgent, 1);
      }

      state = {
        ...state,
        agentsExternal: arrayAgentsExternal,
        agents: arrayAgents,
      };
      break;

    case actionTypesApplications.removeQueueFormAgent:
      state = { ...state, extensions: action.payload };

      break;
    case actionTypesApplications.clearQueueFormCompanyId:
      state = {
        ...state,
        companyId: {
          label: '',
          value: '',
        },
      };
      break;

    case actionTypesApplications.clearQueueFormName:
      state = {
        ...state,
        name: '',
      };
      break;
    case actionTypesApplications.clearQueueFormNumber:
      state = {
        ...state,
        number: '',
      };
      break;
    case actionTypesApplications.clearQueueFormMusicClass:
      state = {
        ...state,
        musicclass: {
          label: '',
          value: '',
        },
      };
      break;

    case actionTypesApplications.clearQueueFormDestination:
      state = {
        ...state,
        destination: {
          label: '',
          value: '',
          ref: '',
        },
      };
      break;
    case actionTypesApplications.changeQueueFormisSurvey:
      state = {
        ...state,
        isSurvey: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormqueueMaxWaitTime:
      state = {
        ...state,
        queueMaxWaitTime: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisCallerAnnouce:
      state = {
        ...state,
        isCallerAnnouce: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormdisplayNameEdited:
      state = {
        ...state,
        displayNameEdited: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisDisplayNameEdited:
      state = {
        ...state,
        isDisplayNameEdited: action.payload,
      };
      break;

    case actionTypesApplications.addQueueFormSurveyId:
      state = {
        ...state,
        survey_soundpromptId: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormSurvey:
      state = {
        ...state,
        survey_soundprompt: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormCustomPromptId:
      state = {
        ...state,
        customPromptId: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormCustomWelcomeId:
      state = {
        ...state,
        customWelcomeId: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormannouceWaitTime:
      state = {
        ...state,
        annouceWaitTime: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormannounceInterval:
      state = {
        ...state,
        announceInterval: action.payload,
      };
      break;
    case actionTypesApplications.addQueueFormCustomPrompt:
      state = {
        ...state,
        isCustomPrompt: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormisAgentLog:
      state = {
        ...state,
        isAgentLog: action.payload,
      };
      break;
    case actionTypesApplications.changeQueueFormIdAstQueue:
      state = {
        ...state,
        idAstQueue: action.payload,
      };
      break;
    case actionTypesApplications.clearQueueForm:
      state = {
        ...state,
        id: '',
        name: '',
        number: '',
        mohId: '',
        strategyId: '',
        maxWaitTime: '',
        extensionId: '',
        extensions: '',
        time_retry: '',
        time_ring: '',
        isnotify_ivr: '',
        survey_soundpromptId: '',
        survey_soundprompt: '',
        time_wait: '',
        destinationId: '',
        createdAt: '',
        updatedAt: '',
        queueMaxLength: '',
        timeHangup: '',
        isRecord: '',
        promptDestinationId: '',
        isHoldLog: '',
        isAgentLog: '',
        falloverDestinationId: '',
        dialEmpty: '',
        leaveWhenEmpty: '',
        customWelcomeId: '',
        isCustomPrompt: '',
        customPromptId: '',
        isSurvey: '',
        queueMaxWaitTime: '',
        isDisplayNameEdited: '',
        displayNameEdited: '',
        isCallerAnnouce: '',
      };
      break;

    default:
      // state = { ...state };
      break;
  }
  return state;
};
