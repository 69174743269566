import { IconArrowUp } from '@tabler/icons-react';
import React from 'react';

const ScrollToTop = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="scroll-top-container" title="scroll-top-container">
      <IconArrowUp className="icon-position icon-style" onClick={goToTop} title="bt-totop" />
    </div>
  );
};
export default ScrollToTop;
