import React from 'react';
import { Navigate } from 'react-router-dom';
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { menuOutbound } from '../../../data/dataOutbound';
import './OverviewOutboundScreen.css';

export const OverviewOutboundScreen = () => {
  return (
    <div className="overview">
      <Navigate to="/outboundRoutes/groupCategories" />
      <LinkButtonBig menuTop={menuOutbound} />
    </div>
  );
};
