import React from 'react';

import { ButtonWrapper } from './Button.style';
import { func, number, object, string } from 'prop-types';

export const Button = ({
  text,
  backgroundColor,
  width,
  Icon,
  icon2,
  fontSize,
  height,
  colorText,
  border,
  onClick,
  hoverBackgroundColor,
  hoverColorText,
  hoverIcon,
  id,
  strokeWidth = 3,
  heightIcon = 12,
  borderRadius = '10px',
}) => {
  return (
    <ButtonWrapper
      id={id}
      backgroundColor={backgroundColor}
      width={width}
      fontSize={fontSize}
      height={height}
      colorText={colorText}
      border={border}
      onClick={onClick}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverColorText={hoverColorText}
      hoverIcon={hoverIcon}
      className={'iconActionMenu__close'}
      borderRadius={borderRadius}>
      {Icon && <Icon height={heightIcon} strokeWidth={strokeWidth} className="button__icon" />}
      {text && <p>{text}</p>}
      {icon2 && <img src={icon2} alt={text} />}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  text: string,
  backgroundColor: string,
  width: string,
  Icon: object,
  icon2: object,
  fontSize: string,
  height: string,
  colorText: string,
  border: string,
  onClick: func,
  hoverBackgroundColor: string,
  hoverColorText: string,
  hoverIcon: object,
  id: string,
  strokeWidth: number,
  heightIcon: number,
  borderRadius: string,
};
