import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateShortMarking } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';

export const ShortMarkingFormScreen = ({ crudType }) => {
  const { applicationsForm, shortMarkingForm, createShortMarking, updateShortMarking, getShortMarking } = useApplications();
  const { typeSwal } = useSwal();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();

  const location = useLocation();

  const [unSavedChanges, setUnSavedChanges] = useState(false);

  const [loadingCounter, setLoadingCounter] = useState();
  const [dataSave, setDataSave] = useState(false);
  const [loading, setLoading] = useState(true);

  const data = formCreateShortMarking;

  useEffect(() => {
    getHeadquarter();
    applicationsForm({ action: 'clearForm' }, { form: 'shortMarking' });

    if (crudType === 'edit') {
      applicationsForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        {
          form: 'shortMarking',
          element: 'id',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.nombre,
        },
        {
          form: 'shortMarking',
          element: 'nombre',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.num_corto,
        },
        {
          form: 'shortMarking',
          element: 'num_corto',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.destino,
        },
        {
          form: 'shortMarking',
          element: 'destino',
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'shortMarking',
          element: 'company',
        },
        {
          label: location.state?.company,
          value: String(location.state?.companyId),
        }
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'company') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }

            return item2;
          });
          return item;
        });
        if (crudType === 'edit' && (shortMarkingForm.nombre === '' || shortMarkingForm.nombre !== location.state?.nombre)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'nombre':
                  item2.defaultValues = shortMarkingForm.nombre;
                  break;
                case 'num_corto':
                  item2.defaultValues = shortMarkingForm.numCorto;
                  break;
                case 'destino':
                  item2.defaultValues = shortMarkingForm.destino;
                  break;

                case 'company':
                  item2.options.filter((item3) => {
                    if (item3.value === shortMarkingForm.company.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;

                default:
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    setUnSavedChanges(false);
    if (crudType === 'create') {
      typeSwal('createLoader', '', applicationsForm, getShortMarking, `/${routesWide.applications}/${routesApplications.shortMarking}`, 'shortMarking', createShortMarking);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', applicationsForm, getShortMarking, `/${routesWide.applications}/${routesApplications.shortMarking}`, 'shortMarking', updateShortMarking);
    }
  };

  return (
    <div className={'form__container'}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          arrScreen={['Marcación abreviada']}
          onCancel={handleOnCancel}
          data={data}
          onSubmit={onSubmit}
          form={applicationsForm}
          dataSave={dataSave}
          crudType={crudType}
        />
      )}
    </div>
  );
};

ShortMarkingFormScreen.propTypes = { crudType: string };
