import styled from 'styled-components';
import { colors } from './theme/colors.js';

export const AppScreenWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

.trunksoutboundscreen {
    box-sizing: border-box;
    height: 100%;

    .contain__table {
      box-sizing: border-box;
      height: 100%;
      position: relative;
      display: flex;
      width: 100%;
      padding-top: 70px;
      padding-left: 20px;
      overflow: scroll;
    }
  }

   .diffusion-list {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin-right: 70px;
    display: flex;
    flex-direction: column;
    ${'' /* padding-left: 70px; */}
    ${'' /* padding-top: 70px; */}
    border-radius: 10px;
    background-color: ${colors.backgroundColor};

    .contain__table {
      box-sizing: border-box;
      height: 100%;
      position: relative;
      display: flex;
      width: 100%;
      padding-top: 70px;
      padding-left: 20px;
      overflow: scroll;
    }
  }

 .dropzone {
    position: relative;
    top: 30px;
  }
  ${
    '' /* .contain__button-save {
    opacity: 1;
    height: 35px;
    position: fixed;
    box-sizing: border-box;
    display: flex;
    justify-content: end;
    top: 70px;
    right: 10px;

    .cancel_button {
      background-color: ${colors.primaryColor};
      color: lightgrey;
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        color: white;
        background-color: ${colors.primaryColorLight};
      }
    }

    .save_edit_button {
      color: black;
      width: 100px;
      background-color: ${colors.greenGYGAS};
      font-size: 14px;
      margin-right: 20px;
      border-radius: 10px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }

    .save_button,
    .next_step {
      color: black;
      background-color: ${colors.greenGYGAS};
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }
  } */
  }

  .customConfirmButtonSwal {
    border: none;
    color: white;
    stroke: white;
    background-color: ${colors.primaryColorLight};
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dispositivos {
    min-width: 120px;
  }

  .dispositivo-col-container {
    max-width: 40px;
    min-width: unset;
  }

  .icon-usersView {
    ${'' /* background-color: ${colors.greenGYGAS}; */}
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    stroke: ${colors.primaryColor};
  }

  .icon__view {
    opacity: 0.7;
    margin-left: 25px;
    color: ${colors.primaryColor};

    &:hover {
      opacity: 1;
    }
    &:focus {
      border: none;
    }
  }

  audio {
    width: 100px;
    height: 15px;
  }

  audio::-webkit-controls {
    display: none;
  }

  .form__user__logo {
    width: 100px;
    height: 100px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
    border-radius: 50%;
    position: fixed;
    
  }

  .numbering {
    .container__cards {
      ${'' /* width: 80%; */}
      margin: auto;
      .form__body {
        width: 80%;
      }

      .form__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: ${colors.primaryColor};
        font-size: 14px;
        font-weight: bold;
        top: 50px;
        margin: auto;
        width: 80%;
        background-color: ${colors.backgroundColor};
        border-radius: 10px 10px 0 0px;
        padding: 5px;
        
      }

      .form__user__logo {
        font-size: 34px;
        color: ${colors.primaryColor};
       
      }
    }
    .contain__inputCard-component {
      padding-top: 100px;
      width: 80%;
      margin: auto;
    }

    ${
      '' /* .icon__Form_title {
        color: ${colors.primaryColor};
        width:50px;
        height:50px;
      } */
    }
  }

  /

  .modal__container {
    width: 100%;
    height: 100%;
    top: 0;
    color: ${colors.primaryColor};
    max-width: unset;
    max-height: unset;
    background-color: ${colors.primaryColorLight};
    border-radius: 10px;

    .contain__button-save {
      height: 35px;
      width: 100%;
      position: unset;
      margin-bottom: 15px;
      margin-top: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      opacity: 1;
    }

    .form__body {
      width: 60%;
      height: auto;
      max-width: 600px;
      margin: auto;
      border-radius: 10px;
      background-color: ${colors.backgroundColor};
      position: relative;
      top: 20%;

      .container__cards {
        width: 80%;
      }
    }
  }

  .input-disabled {
    border: 1px solid grey;
    border-radius: 5px;
    width: 100%;
    max-width: 275px;
    height: 38px;
    padding: 0 0 0 10px;
    margin: 0;
    box-sizing: border-box;
    background-color: lightgrey;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &:focus {
      padding-left: 10px;
    }
  }

  audio::-webkit-slider-thumb {
    width: 100px;
    height: 10px;
    background-color: red;
  }

  audio::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  audio::-webkit-slider-thumb:hover {
    background-color: #fff;
  }

  .header__container {
    box-sizing: border-box;
    background-color: ${colors.primaryColor};
    width: -webkit-fill-available;
    height: 50px;
    position: absolute;
    top: 0;
    padding-left: 20px;
    ${'' /* left: ${(p) => (p.isMenuOpen ? '228px' : '70px')}; */}
    display: flex;
    align-items: center;
    justify-content: center;
       ${'' /* z-index: 9; */}

    img {
      height: 20px;
    }
  }

  .form__container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    border-bottom: rgb(215, 215, 215) 1px solid;
    color: ${colors.primaryColor};

    &:hover {
      background-color: white;

      ${
        '' /* svg {
        stroke: ${colors.primaryColor};
      } */
      }

      .icon__password,
      .passwordWatcher__copy,.hideColumn__copy {
        border: 1px solid ${colors.primaryColor};

        border-radius: 10px;
      }

      .icon__dispositivo svg,
      .icon-cloud svg {
        ${'' /* background-color: ${colors.greenGYGAS}; */}
        border-radius: 50%;
        color: ${colors.primaryColor};
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }

    td {
      cursor: auto;

      &:first-child {
        width: auto;
      }

      ${
        '' /* &:last-child {
        width: 100%;
      } */
      }
    }
  }

  .tooltip {
    background: ${colors.primaryColor};
    border-radius: 10px;
    border: none;
    height: auto;
   
  }

  .numb_contain__table {
    /* width: 60%; */
    height: 50%;
    position: relative;
    top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    ${'' /* height: 100%; */}
    width: 80%;
    ${'' /* margin: auto; */}

    .nav__buttons__container {
      ${'' /* width: auto; */}
      height: 50px;
    }

    .container__cards {
      top: 120px;
    }

    .left {
      width: 100%;
      padding-left: 0;
      display: flex;
      align-items: center;
      margin: auto;
      gap: 20px;
      color: white;
      border-radius: 10px 10px 0px 0;

      .icon-plus {
        width: 35px;
        height: 35px;
        padding: 5px;
        margin-left: 5px;
        background-color: #0c3fc4;
        color: white;

        &:hover {
          cursor: pointer;
          border-radius: 10px;
        }
      }
    }

    table thead {
      background-color: ${colors.primaryColor};
      color: white;
      position: relative;
      margin-left: 10px;
      ${'' /* z-index: 2; */}

      td {
        min-width: 40px;
      }

      ${
        '' /* tr th {
        min-width: 150px;
      } */
      }

      tr th:nth-child(2) {
        border-left: none;
        font-weight: 700;
        cursor: pointer;
        min-width: 40px;
      }
    }

    ${'' /* td:nth-child(3) { */}
    ${'' /* color: #0c3fc4; */}
      ${
        '' /* font-weight: bold;
    } */
      }

    .navtable__container {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      margin: auto;
      background-color: #22262e;
      color: white;
      display: flex;
      justify-content: flex-end;
      border-radius: 10px 10px 0px 0;
      border-bottom: 1px solid lightgrey;

      table {
        position: relative;
        top: -40px;
        left: 40px;
      }
    }

    .tooltip-column {
      color: white;
    }
    .numb__table {
      width: 60%;
    }

    .container .left {
      left: 285px;
      top: 60px;
    }

    .row {
      &:hover {
        background-color: ${colors.white};
        color: white;
      }
    }

    .section__name {
      display: flex;
      align-items: center;
      height: 30px;
      color: ${colors.primaryColor};
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      ${'' /* z-index: 9; */}
      width: max-content;
    }
  }

  .numb__table {
    width: 60%;
    position: relative;
    top: -50px;
  }

  .tooltip-column {
    min-width: 120px;
    color: black;
    width: max-content;
  }

  .tooltip-column_devices {
    width: 100%;
    ${'' /* min-width: 120px; */}
    color: black;
    display: flex;
    justify-content: center;
  }

  .extensions__format {
    width: 50px;
    height: 20px;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    color: white;
    background-color: ${colors.primaryColor};
    ${'' /* border: 1px solid rgb(12, 63, 196); */}
    border-radius: 25px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .settings-screen {
    border-radius: 10px;
    background-color: ${colors.backgroundColor};

    .container__cards {
      width: 80%;
      margin: auto;
    }
    .form__body {
      .form__title {
        position: fixed;
        top: 0px;
        ${'' /* left: 0px; */}
        display: flex;
        justify-content: flex-start;
        align-items: center;
        ${'' /* padding-left: 100px; */}
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        stroke: ${colors.white};

        .title__Form_title {
          display: flex;
          align-items: center;
          color: ${colors.white};
          font-size:14px;
          border-radius: 15px;
          ${'' /* padding-left: 120px; */}
        }
      }
    }
    .contain__inputCard-component {
      padding-top: 100px;
    }

    .pageScreen__tabs {
      position: relative;
      ${'' /* top: 5px; */}
      width: max-content;
    }
    ${'' /* .pageScreen { */}
    ${
      '' /* position: relative;
      top: 70px; */
    }
    ${'' /* } */}
  }

  .left {
    /* padding-left: 15px; */
    ${'' /* width: 100%; */}
    display:flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
  }

  .form__body__queue {
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: scroll;
    align-items: center;

    border-top: 0;
    border-radius: 0px 10px 10px 10px;
    overflow: visible;

    .container__cards {
      width: 80%;
      margin: auto;
      padding-top: 80px;

      .contain__inputCard-component {
        width: 80%;
        margin: auto;
      }
    }

    .form__title {
      width: 80%;
      margin: auto;
      position: absolute;
      top: 0px;
      ${'' /* left: 0px; */}
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      text-align: left;
      font-size: 18px;
      font-weight: bold;

      .title__Form_title {
        display: flex;
        align-items: center;
        color: ${colors.primaryColor};
        font-size: 18px;
        border-radius: 15px;
        padding-left: 120px;
        padding-top: 35px;
      }
    }

    .cancel_button {
      color: lightgrey;
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      font-weight: bold;
      margin-right: 20px;
      background-color: ${colors.primaryColor};

      &:hover {
        color: white;
        background-color: ${colors.primaryColorLight};
      }
    }
    .save_button {
      color: black;
      background-color: ${colors.greenGYGAS};
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }

    .save_button,
    .next_step {
      color: black;
      background-color: ${colors.greenGYGAS};
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }
  }

  .icon__Form_title {
    width: 60px;
    height: 60px;
  }

  .icon__Form_title1 {
    width: 50px;
    height: 50px;
  }
  /* .form__title {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    text-align: left;
    background-color: ${colors.primaryColor};
    font-size: 14px;
    font-weight: bold;
    top: 50px;
    margin: auto;
    padding-top: 50px;

    .icon__Form_title {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: ${colors.white};
    }

    .title__Form_title {
      display: flex;
      align-items: center;
      border-radius: 15px;
    }
  } */
  .form__body__calendar,
  .form__body__whitelist {
    border-radius: 10px;

    .calendar_container {
      width: 100%;
      grid-column: 1 / 4;

      .calendar_wrapper {
        margin-top: 15px;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        ${'' /* border: 1px solid #d2d3d4; */}
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .container__cards {
      margin: auto;

      .form__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: #334155;
        font-size: 14px;
        font-weight: bold;
        top: 50px;
        ${'' /* margin: auto; */}
        width: max-content;
        background-color: #f8fafc;
        border-radius: 10px 10px 0 0px;
        padding-left: 100px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.primaryColor};
          padding-left: 100px;
        }
      }
      .contain__inputCard-component {
        padding-top: 100px;
        width: 80%;
        margin: auto;

        .stepForm__contain-typeInput {
          display: unset;
          margin: auto;

          .color {
            justify-content: center;
            margin: auto;
          }
          .contain__input,
          button {
            max-width: 200px;
            ${'' /* margin: auto; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .form__body__difusion {
    border-radius: 10px;
    width: 80%;
    margin: auto;
    .contain__inputCard-component {
      padding-top: 100px;
      margin: auto;
      width: 80%;
    }
  }

  .form__body,
  .form__body__whitelist,
  .form__body__difusion {
    border-radius: 10px;

    .container__cards {
      margin: auto;

      .form__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;
        color: #334155;
        font-size: 14px;
        font-weight: bold;
        top: 50px;
        margin: auto;
        width: auto;
        background-color: #f8fafc;
        border-radius: 10px 10px 0 0px;
        padding: 5px;

        .title__Form_title {
          font-size: 18px;
          color: ${colors.primaryColor};
          padding-left: 140px;
        }
      }
      .contain__inputCard-component {
        ${'' /* padding-top: 100px; */}
        width: 80%;
        margin: auto;

        .stepForm__contain-typeInput {
          ${'' /* display: unset; */}
          margin: auto;

          .color {
            justify-content: center;
            margin: auto;
          }
          .contain__input,
          button {
            max-width: 200px;
            ${'' /* margin: auto; */}
          }

          .textRequired {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .form__body__mailbox,
  .form__body__conference,
  .form__body__ivr,
  .form__body__locution,
  .form__body__marcacion,
  .form__body__musica {
    width: 100%;

    .container__cards {
      padding-top: 20px;
      width: 100%;

      .contain__inputCard-component {
        width: 80%;
        margin: auto;
      }

      .title__Form_title {
        font-size: 18px;
        padding-left: 120px;
      }
    }
  }

 .form__body__locution {
    .stepForm__contain-typeInput {
     grid-template-columns: repeat(2, 1fr);
      ${'' /* grid-template-rows: 1fr 1fr; */}
    }
  }
  .form__body__trunk {
    .container__cards {
      ${'' /* padding-top: 20px; */}
      ${'' /* width: 80%; */}

    .contain__inputCard-component {
        width: 100%;
      }
      .form__title {
        width: auto;
        margin: auto;
      }

      .title__Form_title {
        font-size: 18px;
        padding-left: 120px;
      }
    }
  }

  .form__body__alert {
    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: 0 0 15px 15px;

    .container__cards {
      ${'' /* padding-top: 100px; */}
      ${'' /* width: 80%; */}
      margin: auto;

      .contain__inputCard-component {
        ${'' /* width: 80%; */}
        padding-top: 100px;
      }

      .form__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        text-align: left;

        font-size: 14px;
        font-weight: bold;
        ${'' /* top: 50px; */}
        margin: auto;
        width: auto;
        background-color: #f8fafc;
        border-radius: 10px 10px 0 0px;
        padding: 5px;

        .title__Form_title {
          font-size: 18px;
          color: #334155;
          padding-left: 100px;
        }
      }
    }
  }

  .form__body__cat {
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
    ${'' /* padding-top: 100px; */}

    .container__cards {
      ${'' /* padding-top: 60px; */}
      width: 100%;

      .stepForm__contain-typeInput{
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));}
    /* width: 100%;
      } 
      
      .form__title {
        width: 60%;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        ${'' /* padding-left: 80px; */}
        background-color: ${colors.backgroundColor};

        .title__Form_title {
          padding-left: 120px;
          display: fle;
        }
      }
    
  
  .form__body__prefs {
    ${'' /* width: 80%; */}
    /* height: 100%; */
    display: grid;
    ${
      '' /* flex-direction: column;
    align-items: center; */
    }
    margin: auto;
    gap: 50px;
    /* border: 2px solid #0c3fc4; */
    /* border-top: 0; */
    padding-bottom: 20px;
    ${'' /* padding-top: 100px; */}
    margin: auto;
    width: 75%;
    /* border-radius: 0 0 10px 10px; */

    .form__title {
      display: flex;
      /* min-width: 75%; */
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-left: 55px;
      text-align: left;
      color: ${colors.primaryColorLight};
      font-weight: bold;
      position: fixed;
      top: 0px;
      z-index: 550;

      ${
        '' /* .icon__Form_title {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: white;
      } */
      }

      .title__Form_title {
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 15px;
      }
    }
  }

  

  .form__body__users {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 30px;
    align-items: center;
    border-radius: 0 0 15px 15px;
    ${'' /* z-index: 550; */}

    .container__cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      padding-top: 100px;
      ${'' /* margin: auto; */}

      .form__title {
        width: 80%;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        background-color: ${colors.backgroundColor};
        z-index: 9;
      }

      .contain__inputCard-component {
        width: 100%;
        height: 100%;
        padding-top: 30px;
        ${'' /* padding: 25px 30px 40px 30px; */}

        .section__name {
          padding-left: 0;
        }
      }

      .section_name {
        padding-left: 0;
      }

      .stepForm__contain-typeInput {
        gap: 10px;
      }

      .contain__select {
        width: unset;
        ${'' /* grid-column-start: 1; */}
      }
    }
  }

  

  .error__container {
    width: 65%;
    margin: auto;
    margin-top: 100px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      width: 100%;
    }
    .error__body {
      text-align: left;
    }
    .error__title {
      color: red;
    }
  }

  .swal2-actions {
    .customDeleteButtonSwal {
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: #fff;
      opacity: 0.7;
      background-color: #cd3434;
      border-radius: 10px;
      font-weight: 600;
      cursor: pointer;
      overflow: hidden;
      padding: 6px 0;
    }

    .customDeleteButtonSwal:hover {
      background-color: #cd3434;
      opacity: 1;
    }
  }

  .customConfirmButtonSwal:hover {
    background-color: #ec1616;
    color: white;
  }

  .customCancelButtonSwal {
    margin-right: 10px;
    border: none;
    opacity: 0.7;
    background-color: green;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    width: 180px;
    height: 28px;
  }

  .customCancelButtonSwal:hover {
    background-color: #22262e;
    opacity: 1;
  }

  .customIconSwal {
    background-color: #d2d3d5;
    border: none;
  }

  .customConfirmButtonSwalPassword {
    border: none;
    color: black;
    background-color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customConfirmButtonSwalPassword:hover {
    background-color: lightgrey;
    color: black;
    width: 100%;
  }

  .copyPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
  }

  .password {
    ${'' /* width: 80%; */}
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .copyImage {
    width: 20%;
  }

  .copyImageIcon {
    height: 25px;
  }
`;
