import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'react-redux';

import PermissionProvider from '../Permission/PermissionProvider';
import Restricted from '../Permission/PermissionConsumer';

import { LinkButtonBigWrapper } from './LinkButtonBig.style';
import { array } from 'prop-types';

export const LinkButtonBig = ({ menuTop }) => {
  const { getState } = useStore();

  const permissions = getState()['arrayUsersPermission'];

  const subtitlePermissionsUser = permissions.map((item) => item.subtitle);

  return (
    <PermissionProvider permissionsUser={subtitlePermissionsUser}>
      <LinkButtonBigWrapper>
        {menuTop.map((item, index) => (
          <Restricted key={index} to={item.restricted}>
            <Link to={'../' + item.path}>
              <div>
                <p>
                  {' '}
                  <item.icon height={'20px'} />
                  {item.text}
                </p>
                <p>{item?.description}</p>
              </div>
            </Link>
          </Restricted>
        ))}
      </LinkButtonBigWrapper>
    </PermissionProvider>
  );
};

LinkButtonBig.propTypes = { menuTop: array };
