import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useNumbering from '../../../hooks/useNumbering';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { routesNumbering, routesWide } from '../../../navigation/routes';
// import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
// import { dataScreenListNumbering } from '../../../data/dataNumbering';
// import { CardGird } from '../../../components/CardGrid/CardGird';
import { useSwal } from '../../../hooks/useSwal';
import { nameTables } from '../../../data/dataTable';
// import useView from '../../../hooks/useView';

import { ListNumberWrapper } from './ListNumberScreen.style';
// import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
// import { NavTable } from '../../../components/NavTable/NavTable';
// import { optionsDestination } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import usePreferences from '../../../hooks/usePreferences';
import { selectedDocActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { useDispatch } from 'react-redux';

export const ListNumberScreen = () => {
  const { arrayNumberingHook, getNumberingListModal, getNumberingList, deleteNumberingList, uploadFileListNumbering, loading } = useNumbering();
  const { getCalendar, getScheduleDB } = usePreferences();
  const { getMailboxes, getIvr, getQueues, getConferences, getApplicationsLocutions } = useApplications();
  const { typeSwal } = useSwal();
  // const { isCardView } = useView();

  const dispatch = useDispatch();

  const [checkboxesActives, setCheckboxesActives] = useState([]);
  const { listNumberingForm } = useNumbering();
  const [isFilter, setIsFilter] = useState({ open: false, active: false });
  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  // const { notFound } = dataScreenListNumbering;

  const navigate = useNavigate();

  useEffect(() => {
    getApplicationsLocutions();
    getConferences();
    getScheduleDB();
    getMailboxes();
    getQueues();
    getNumberingList();
    getIvr();
    setSearch([]);
    getCalendar();
    getNumberingListModal();

    listNumberingForm(
      { action: 'clearForm' },
      {
        form: 'numberingList',
      }
    );
  }, [setSearch, getNumberingList]);

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Número',
      accessor: 'ddi',
    },
    {
      Header: 'Departamento',
      accessor: 'department',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
  ];

  // function searchDestinationCalendar(id) {
  //   let ddiLinkId = ddiLink.filter((obj) => obj.ddiId === id);

  //   return ddiLinkId;
  // }

  // const getDestinationInfo = (destinationId, destinationTypeId) => {
  //   destinationId = destinationId?.length ? destinationId : [destinationId];
  //   destinationTypeId = destinationTypeId?.length ? destinationTypeId : [destinationTypeId];

  //   // if (import.meta.env.VITE_DEBUG === 'true') console.log(destinationField);

  //   let newArray = [];
  //   destinationId.forEach((dest, index) => {
  //     const idType = optionsDestination[destinationTypeId[index]];

  //     let arrayToSearch = [];

  //     switch (idType?.ref || ivr) {
  //       case 'queue':
  //         arrayToSearch = queues;
  //         break;
  //       case 'conference':
  //         arrayToSearch = conferences;
  //         break;
  //       case 'voicemail':
  //         arrayToSearch = mailboxes;
  //         break;
  //       case 'ivr':
  //         arrayToSearch = ivr;
  //         // destinationId = destinationId ? destinationId : 178;
  //         break;
  //       default:
  //         arrayToSearch = mailboxes;
  //         break;
  //     }
  //     destinationId = destinationId ? destinationId : arrayToSearch[0].id;
  //     const newValue = arrayToSearch?.length ? arrayToSearch?.find((item) => item?.id === destinationId[index]) : '';
  //     // return newValue.map((newDestination) => {
  //     newArray.push({ value: newArray.length + 1, id: newValue?.id, label: newValue?.name ? newValue.name : newValue?.extension });
  //     // });
  //   });
  //   return newArray;
  // };

  // const getCalendarsInfo = (calendars) => {
  //   const newCalendars = [];
  //   calendars.forEach((itemCalendar) =>
  //     arrayModalExtNumbHook?.find((itemCal) => {
  //       if (itemCal.CalendarName?.id === itemCalendar) {
  //         newCalendars.push({ value: newCalendars.length + 1, id: itemCal.CalendarName.id, label: itemCal.CalendarName.name });
  //       }
  //     })
  //   );
  //   return newCalendars;
  // };

  // const getSchedulesInfo = (itemSchedule) => {
  //   const newSchedules = [];
  //   itemSchedule.forEach((itemSchedule2) =>
  //     scheduleArrayHook?.find((itemSched) => {
  //       if (itemSched[0].Schedule.id === itemSchedule2) {
  //         newSchedules.push({ value: newSchedules.length + 1, id: itemSched[0].Schedule.id, label: itemSched[0].Schedule.name });
  //       }
  //     })
  //   );
  //   return newSchedules;
  // };

  // const getSoundPromptsInfo = (itemSound) => {
  //   const newItems = [];
  //   itemSound.forEach((itemSound2) =>
  //     arrayLocutionsHook?.find((sched) => {
  //       if (sched.id === itemSound2) {
  //         newItems.push({ value: newItems.length + 1, id: sched.id, label: sched.description });
  //       }
  //     })
  //   );
  //   return newItems;
  // };

  // const getDestinationType = (item) => {
  //   const newItems = [];
  //   item = item?.length ? item : [item || 2];
  //   item.forEach((item2) => {
  //     optionsDestination[item2];
  //     // if (sched.id === item2) {
  //     newItems.push({ value: optionsDestination[item2].id, label: optionsDestination[item2].label });
  //   });
  //   return newItems;
  // };

  const DATA = arrayNumberingHook.map(
    ({
      id,
      description,
      ddi,
      Company,
      Department,
      // soundPromptCloseId,
      // calendar,
      // schedule,
      // destinationType,
      // destination,
      companyId,
      // locutionCloseId,
      // destinationCloseId,
      // destinationCloseTypeId,
      // schedules,
      // destinationsType,
      // destinations,
      // soundPrompts,
      // calendars,
      // soundPrompt,
      // isDefaults,
    }) => ({
      id: id,
      name: description,
      ddi: ddi,
      // ddiId: id,
      // isDefaults,
      // soundPrompt,
      company: Company?.name,
      department: Department?.name,
      departmentId: Department?.id,
      companyId,
      // destinationCalendar: searchDestinationCalendar(id),
      // locutionCloseId,
      // destinationCloseTypeId,
      // destinationCloseId,
      // destinationCloseType: getDestinationType(destinationCloseTypeId),
      // destinationClose: getDestinationInfo(destinationCloseId, destinationCloseTypeId, 'destinationClose'),
      // soundPromptCloseId,
      // schedules: getSchedulesInfo(schedules),
      // schedule,
      // destinationsType: getDestinationType(destinationsType),
      // destinations: getDestinationInfo(destinations, destinationsType, 'destinations'),
      // calendars: getCalendarsInfo(calendars),

      // destinationType: getDestinationType(destinationType),
      // destination: { value: 243, label: 'IVR 3' },
      // : getDestinationInfo(destinationCloseId, destinationCloseTypeId, 'destination'),
      // : getDestinationInfo(243, 2, 'destination'),
      // soundPrompts: getSoundPromptsInfo(soundPrompts),
    })
  );

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.numbering}/${routesNumbering.createNumberingList}`, {});
  };

  const handleOpenFormEdit = (item) => {
    dispatch(selectedDocActionCreator(item.original));
    navigate(`/${routesWide.numbering}/${routesNumbering.updateNumberingList}`, {
      state: item.original ? item.original : item,
    });
  };

  const dataExport = arrayNumberingHook.map(({ description, ddi }) => ({
    Descripción: description,
    Número: ddi,
  }));

  // const dataCard = () => {
  //   if (search.length > 0) {
  //     return search.map(({ name, ddi, ddiId }) => ({
  //       title: name,
  //       icon: icon.phoneCard,
  //       subtitle: ddi,
  //       ddiId,
  //       name,
  //       ddi,
  //     }));
  //   } else {
  //     return DATA.map(({ description, ddi, id }) => ({
  //       title: description,
  //       icon: icon.phoneCard,
  //       subtitle: ddi,
  //       id,
  //       name: description,
  //       ddi: ddi,
  //       ddiId: id,
  //     }));
  //   }
  // };

  // const dataToCards = dataCard();

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteNumberingList(item.original);
          } else {
            response = await deleteNumberingList(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getNumberingList();
            typeSwal('delete');
          }
          if (response.status === 203) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            typeSwal('error', table);
            getNumberingList();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileListNumbering(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getNumberingList();
      }
    } catch (err) {
      return err;
    }
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Número: '',
      Descripción: '',
    },
  ];

  return (
    <ListNumberWrapper className="listNumber__body">
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            // filter: {
            //   position: 'right',
            // },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          handleOpenFormCreate={handleOpenFormCreate}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          onClickCreate={handleOpenFormCreate}
          // onClickAssign={handleOpenFormAssign}
          // totalDocs={users.length}
          deleteFunction={handleDelete}
          checkboxesActives={checkboxesActives}
          setCheckboxesActives={setCheckboxesActives}
          isNavTable={true}
          DATA={DATA}
        />
        {/* {arrayNumberingHook[0]?.status ? null : (
          <div className="contain__menuFilter">
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterExtension} setSearch={setSearch} />
          </div>
        )} */}
        {/* <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteNumberingList} totalDocs={arrayNumberingHook.length} /> */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayNumberingHook[0]?.status ? (
        <div className="contain__table">
          {/* <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} /> */}
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
            setDataCard={() => {
              false;
            }}
          />
        </div>
      )}
    </ListNumberWrapper>
  );
};
