import React from 'react';
import { BoxStatisticWrapper } from './ BoxStatistic.style';
import { number, string } from 'prop-types';

export const BoxStatistic = ({ backgroundColor, title, value }) => {
  return (
    <BoxStatisticWrapper backgroundColor={backgroundColor}>
      <div className="boxStatistic_container">
        <h5> {title} </h5>
        <div className="stats">
          <p>{value}</p>
        </div>
      </div>
    </BoxStatisticWrapper>
  );
};

BoxStatistic.propTypes = { backgroundColor: string, title: string, value: number };
