import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NumberingScreen } from '../../screens/NumberingScreen/NumberingScreen';
// import { OverviewScreen } from '../../screens/OverviewScreen/OverviewScreen';
import { WideWrapper } from './WideNavigation.style';
import { routesWide } from '../routes';
// import { EMScreen } from '../../screens/EMScreen/EMScreen';
import ApplicationsScreen from '../../screens/ApplicationsScreen/ApplicationsScreen';
import PreferencesScreen from '../../screens/PreferencesScreen/PreferencesScreen';
import SettingsScreen from '../../screens/SettingsScreen/SettingsScreen';
import { CallRegisterScreen } from '../../screens/CallRegisterScreen/CallRegisterScreen';
import { OutboundScreen } from '../../screens/OutboundScreen/OutboundScreen';
import { ProfileScreen } from '../../screens/ProfileScreen/ProfileScreen';
import { HelpScreen } from '../../screens/HelpScreen/HelpScreen';
import { NotFoundScreen } from '../../screens/NotFoundScreen/NotFoundScreen';
import { dataScreenNotFound } from '../../data/dataSettings';
import { EMScreen } from '../../screens/EMScreen/EMScreen';
import UsersScreen from '../../screens/UsersScreen/UsersScreen';
// import { Modal } from '../../components/Modal/Modal';
// import { useSelector } from 'react-redux';
// import { OverviewEMScreen } from '../../screens/EMScreen/OverviewEMScreen/OverviewEMScreen';
// import { AlertsScreens } from '../../screens/EMScreen/AlertsScreen/AlertsScreens';

const WideNavigation = () => {
  const { notFound } = dataScreenNotFound;
  // const { isDataModified } = useSelector((state) => state.system);
  // const [isFormDirty, setIsFormDirty] = useState(isDataModified);

  return (
    <WideWrapper className={'widenav__container'}>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        {/* <Route path="/" element={<Navigate to="/users" />} /> */}
        <Route path={`${routesWide.dashboard}/*`} element={<NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} />} />
        {/* // <Navigate to={'/cdr/activity'} />} /> */}
        <Route path={`${routesWide.users}/*`} element={<UsersScreen />} />
        <Route path={`${routesWide.numbering}/*`} element={<NumberingScreen />} />
        <Route path={`${routesWide.applications}/*`} element={<ApplicationsScreen />} />
        <Route path={`${routesWide.cdr}/*`} element={<CallRegisterScreen />} />
        <Route path={`${routesWide.outboundRoutes}/*`} element={<OutboundScreen />} />
        <Route path={`${routesWide.preferences}/*`} element={<PreferencesScreen />} />
        <Route path={`${routesWide.EM}/*`} element={<EMScreen />} />
        <Route path={`${routesWide.profile}/*`} element={<ProfileScreen />} />
        <Route path={`${routesWide.help}/*`} element={<HelpScreen />} />
        <Route path={`${routesWide.settings}/*`} element={<SettingsScreen />} />
      </Routes>
    </WideWrapper>
  );
};

export default WideNavigation;
