import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';
import '../../theme/fonts/PF Isotext Pro Bold.ttf';
export const MenuTopWrapper = styled.div`

  .file-button{
    border-radius: 10px;
  }

.app__title{
  color: ${colors.white};
  font-family: 'PFIsotextPro';
  font-size: 41px;
  width: 90px;
}

.view__name{
  position: fixed;
  font-family: 'PFIsotextPro';
  width: 100%;
  color: ${colors.white};
  font-size: 24px;
  text-align: center;
  font-weight: ${weight.bold};
  display: flex;
  justify-content:center;
  align-items: center;
  gap: 10px;
  ${'' /* flex:1; */}

  svg{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
    padding: 3px;
  }
}
.user-prefix{
  width:25px;
  height:25px;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.greenGYGAS};
  border-radius: 50%;
  color:black;
  font-weight:bold;
      margin-right: 10px;
}

.user__item__contain{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 230px;
  color: rgb(225,224,224);
   
    &:hover {
      color: ${colors.white};
      background-color: ${colors.primaryColorLight};
      border-radius: 10px;

    ${'' /* color:${colors.backgroundColor}; */}
  }
}

.title__Form_title{
  color: ${colors.primaryColorLight};
  font-weight:bold;
  font-size: 16px;
}
  .menupref__container{
    position: relative;
    top: -10 px;
    color: black;
    display: flex;
    justify-content: center;
    gap:10px;

    &__link {
      box-sizing: border-box;
      height: 100%;
      width: max-content;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      align-items: start;
      border-radius: 10px;
      justify-content: center;

      .menuTop__icon {
        margin-right: 5px;
      }

      &:hover {
        cursor: pointer;
        color: ${colors.white};
        background-color: ${colors.greyLine};
      }
    }
  }
  
  

 .menupref__item-link {
    box-sizing: border-box;
    width: max-content;
    line-height: 16px;
    padding: 5px;
    letter-spacing: 0.25px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 10px;
    
    &:hover {
      cursor: pointer;
      color: ${colors.blue};
      // background-color: ${colors.blue};
      // border-radius: 10px;
      ${'' /* border-bottom: 1px solid ${colors.blue}; */}

      .menuTop__icon {
        color: ${colors.white};
        margin-right: 5px;
      }
    }
  }
  
  .header__buttons {
    height: 50px;
    color:darkgrey;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    z-index: 102;
    margin-right: 10px;

.item__contain {
    ${'' /* width: 100%; */}
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
  }}

  .item__image{
    height: 40px;
    width: 40px;
    padding: 5px;

    &:hover{
      // height: 40px;
      // width: 40px;
      background-color:${colors.primaryColorLight};
      color :${colors.white};
      border-radius: 10px;
    
    }
  }

  // a {
  //  width: max-content;
    // font-size: ${newFontSize.button};
    // font-weight: bold;

    // &:hover {
      // color: ${colors.black};
      // background-color:${colors.grey};
      // cursor:pointer;
      // border-radius: 10px;
      // border: 1px solid ${colors.white};
    // }
  // }


  .header_navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
  }

  .button__icon {
    color: darkgrey;
    height: 30px;
    width: 100%;
  }

  .buttons__container {
    box-sizing: border-box;
    top: 0;
    font-family: 'PFIsotextPro';   
    width: 91%;  
    display: flex;
    align-items: center;   
    justify-content: flex-start;
    z-index: 100;
    font-size: 34px;
    font-weight: 700;
    flex: 4;
    color: white;
    gap: 10px;
    padding-left: 70px;

  .item__contain {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    border-radius: 10px;    
    color: darkgrey;

    &:hover {
      cursor:pointer;     
      color: ${colors.white};   
      background-color: ${colors.blue};
      // border: 1px solid ${colors.white};
    }

    .item__image {
      height: 30px;
      width: 30px;
      box-sizing: border-box;
      border-radius: 10px;
    }
  }
  }
  .container {
    box-sizing: border-box;
    height: 30px;
    width: fit-content;
    position:fixed;
    right:15px;
    top:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      height: 100%;
      border-radius: 10px;
      // margin: auto;
      flex: 1;
      max-width: fit-content;

      &:hover {
        // height: 100%;
        cursor: pointer;

        .menuTop__icon {
          // color: ${colors.black};
          // //transition: all 0.3s;
        }

        .item-link {
          cursor: pointer;
          // color: ${colors.white};
          // //transition: all 0.3s;
        }
      }
    }

    &__link {
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: start;
      padding:5px;
      border-radius: 10px;
      justify-content: center;

      

      &:hover {
        cursor: pointer;
        // color: ${colors.black};
      }

      .item-link {
        box-sizing: border-box;
        height: 100%;
        color: darkgrey;
        line-height: 16px;
        padding: 5px;
        letter-spacing: 0.25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        &:hover {
          // cursor: pointer;
          color: ${colors.white};
          // background-color:  ${colors.grey};
          // border-bottom: 1px solid ${colors.primaryColor};
          .menuTop__icon {
            // color: ${colors.black};
          }
        }
      }

      // .menuTop__icon {
      //   color: ${colors.black};
      //   //transition: all 0.3s;
      //   margin-right: 5px;
      // }
    }

    &__link-active {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      max-width: 190px      
      display: flex;
      justify-items: center;
      align-items: center;
      background-color: ${colors.primaryColorLight};
      border-radius: 10px;
      // border: 2px solid ${colors.white};
      // //transition: all 0.3s;
      //  font-weight: bold;
      padding:5px;
      
      .menuTop__icon {
        color: ${colors.white};
      }
    }
  }


  .link-active {
    box-sizing: border-box;
    color: ${colors.white};
    // font-weight: ${weight.bold};
    line-height: 16px;
    letter-spacing: 0.25px;    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;    
   
    // border: 2px solid ${colors.white};
    
    &:hover{
      cursor:pointer;
    }

    .menuTop__icon {
      //  color: ${colors.white};
      
     }
  }

  .tooltip {
    background-color: ${colors.primaryColor};
    border-radius: 10px;
    font-size: 12px;
    z-index: 9999;
  }
`;
