import styled from 'styled-components';
import { colors } from './../../../theme/colors.js';

export const ExtensionsFormScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 15px;

  .contain__inputCard-component__modal + div {
    position: relative;
    top: -50px;
    right: 10%;
    height: 35px;
    width: auto;
    opacity: 1;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancel_button {
      background-color: ${colors.primaryColor};
      color: white;
      height: 35px;
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      margin-right: 20px;
      border-radius: 15px;

      &:hover {
        color: white;
        background-color: ${colors.red};
      }
    }

    .save_edit_button {
      color: black;
      width: 100px;
      height: 35px;
      background-color: ${colors.greenGYGAS};
      font-size: 14px;
      border-radius: 15px;
      ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}
      -webkit-border-radius: 15px 15px 15px 15px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }

    .save_button {
      height: 35px;
      color: black;
      border-radius: 15px;
      width: 100px;
      background-color: ${colors.greenGYGAS};
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }

    .save_button,
    .next_step {
      color: black;
      background-color: ${colors.greenGYGAS};
      border-radius: 15px;
      width: 100px;
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        background-color: ${colors.greenGYGASLight};
      }
    }
  }

  ${
    '' /* .form__body {
    width: 60%;
    height: auto;
    max-width: 600px;
    margin: auto;
    border-radius: 10px;
    background-color: ${colors.backgroundColor};
    position: relative;
    top: 20%;

    .container__cards {
      width: 80%;
    }
  } */
  }

  ${
    '' /* .container__cards {
    height: 298px;

    border-radius: 10px;
  } */
  }
  ${
    '' /* .form__body {
    .container__cards {
      .contain__button-save {
          height: 35px;
          opacity: 1;
          position: absolute;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          align-items: center;      
          height: 35px;
          opacity: 1;
          position: absolute;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          top: 320px;
          right: 80px;

          button {
           height: 35px;
            width: 100px;
            border-radius: 15px;
            background-color: ${colors.primaryColor};
          }

          .cancel_button {
            height: 35px;
            color: lightgrey;
            background-color: ${colors.primaryColor};          
            width: 100px;
            font-size: 14px;
            font-weight: bold;
            margin-right: 20px;

            &:hover {
              color: white;
              background-color: ${colors.primaryColorLight};
            }
          }

          .save_edit_button, .save_button  {
            color: black;
            background-color: ${colors.greenGYGAS};
            font-size: 14px;
            margin-right: 20px;
            height: 35px;
            width: 100px;

            &:hover {
              background-color: ${colors.greenGYGASLight};
            }
          }        
      }
    }
  } */
  }

  .input-disabled {
    height: 34px;
    border-radius: 10px;
    padding: 0 10px;
    background-color: hsl(0, 0%, 95%);
    border: 0;
    color: hsl(0, 0%, 60%);
  }

  ${
    '' /* .form__body {
    height: 100%;
    width: 100%;
  } */
  }

  ${
    '' /* .form__title {
    border-radius: 10px 10px 0 0;
    height: 40px;
    color: ${colors.backgroundColor};
    display: flex;
    align-items: center;
    padding: 5px 10px 0;
    font-weight: bold;
    gap: 10px;
    font-size: 16px;
  } */
  }

  .contain__inputCard-component {
    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: 15px;
    ${'' /* max-width: 1000px; */}

    .stepForm__contain-typeInput {
      border-radius: 15px;
      ${'' /* background-color: ${colors.primaryColorLight}; */}
      padding: 0 40px 0 40px;

      span {
        color: ${colors.white};
      }
      .contain__select {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 16px;
      }
    }

    #userId__0 {
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;
    }

    #typeExtension__1 {
      grid-column-start: 3;
      grid-column-end: 5;
      width: 100%;
    }

    #allow__2 {
      grid-row-start: 2;
      grid-column-start: 1;
      width: 100%;
    }

    #marca__3 {
      grid-row-start: 2;
      width: 100%;
      grid-column-start: 2;
    }

    #modelo__4 {
      grid-row-start: 2;
      grid-column-start: 3;
      width: 100%;
    }

    #plantilla__5 {
      grid-row-start: 2;
      grid-column-start: 4;
      width: 100%;
    }
  }

  ${
    '' /* .contain__button-save {
    position: relative;
    right: 0px;
    top: -15px;
    left: -60px;
    font-weight: bold;

    .save_button {
      height: 35px;
      width: 100px;
      -webkit-border-radius: 15px 15px 15px 15px;   
    }
    .cancel_button {
      height: 35px;
      width: 100px
      
      -webkit-border-radius: 15px 15px 15px 15px;
     
    }
  } */
  }
`;
