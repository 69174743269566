import styled from 'styled-components';
import { colors } from '../../theme/colors.js';
import { newFontSize, weight } from '../../theme/fonts.js';

export const CardWrapper = styled.div`
    height: 160px;
    width: 180px;
    border: 1px solid ${colors.greyLine};
    background-color: ${colors.white};
    cursor: pointer;
    //transition: all 0.3s;

    &:hover {
      background-color: ${colors.greyHover}; 
      //transition: all 0.3s;

    }
    &:active {
      
      transform: scale(0.96);
      //transition: all 0.1s;
    }
    
    border-radius: 5px;
    box-shadow: 26px 32px 17px rgba(73, 80, 87, 0.01),
    15px 18px 14px rgba(73, 80, 87, 0.04),7px 8px 10px rgba(73, 80, 87, 0.06),2px 2px 6px rgba(73, 80, 87, 0.07),2px 2px 6px rgba(73, 80, 87, 0.07),0px 0px 0px rgba(73, 80, 87, 0.07) ;
    border-radius:15px;

    .card{
      height: 100%;
      width: 90%; 
      display: grid;
      grid-template-rows: repeat(7, 1fr);
      overflow:hidden;
      margin: auto;
    }

    .card__title {
        color: ${colors.grey};
        font-weight: ${weight.medium};
        font-size: ${newFontSize.h4};
        margin: 0;
        justify-self: center;
        align-self: center;
        grid-row-start: 2;
        grid-row-end: 3;
        text-align: center;

     

    }
    .contain__iconSubtitle {
      align-self: center;
      justify-self: center;
      grid-row-start: 3;
      grid-row-end: 4;
      display: flex;
      align-items: center;
      color: ${colors.grey};
      
     


      .card__subtitle-icon {
        margin-right: 4px;
        height: 20px;

      }
      .card__subtitle {
        font-weight: ${weight.medium};
        // font-size: ${newFontSize.button};
        margin: 0;
        text-align: center;
       
      }
    }
    .contain__text {
      justify-self: center;
      align-self: center;
      grid-row-start: 5;
      grid-row-end: 6;
      display: flex;
      align-items: center;


      .card__text-icon {
        margin-right: 4px;
        height: 11px;



      }
      .card__text {
        color: ${colors.grey};
        font-weight: ${weight.regular};
        font-size: ${newFontSize.label};
        margin: 0;
       
      }
    }

    .contain__component {
      justify-self: center;
      align-self: center;
      grid-row-start: 6;
      grid-row-end: 7;
      display: flex;
      align-items: center;


      audio {
        width: 210px;
        height: 20px;
      }

      .card__component-icon {
        margin-right: 4px;
        height: 11px;
      }

      .card__text-component {
        color: ${colors.grey};
        margin: 0;
        font-weight: ${weight.regular};
        font-size: ${newFontSize.label};
      }
    }

    .

   
`;
