import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const NavMenuLeftWrapper = styled.div`
  box-sizing: border-box;
  width: ${(p) => (!p.isMenuOpen ? '70px' : '228px')};
  height: 100%;
  position: fixed;
  top: 50px;
  background-color: ${colors.primaryColor};
  z-index: 9;
  ${'' /* transition: all 1.5s; */}

  .contain__logo {
    box-sizing: border-box;
    height: 60px;
    padding-top: 25px;
    width: 232px;

    img {
      width: 183.2px;
    }
  }

  .nav__buttons__container {
    position: relative;
    bottom: 30%;
    color: white;
    width: 100%;
    gap: 5px;
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    border-radius: 10px;

    svg {
      cursor: pointer;
      border-radius: 50%;
      border: 0.5px solid ${colors.white};
      width: 40px;
      height: 40px;
      padding: 5px;
      &:hover {
        background-color: ${colors.greenGYGAS};
        color: ${colors.primaryColor};
      }
    }
    .nav__button {
      color: white;
    }
  }
  .button__icon {
    color: ${colors.white};
    height: 45px;
    width: ${(p) => (!p.isMenuOpen ? '65px' : '65px')};
    display: flex;
    justify-content: flex-start;

    &:hover {
      color: ${colors.black};
      background-color: ${colors.white};
      border-radius: 10px;
    }
  }

  .contain__logo-close {
    box-sizing: border-box;
    // //transition: all 1s;
    // transition-delay: ${(p) => (!p.isMenuOpen ? 0.5 : 0)};
    height: 60px;
    padding-top: 25px;
    width: 53px;

    img {
      width: 183.2px;
    }
  }

  // .navMenuLeft__container {
  //   box-sizing: border-box;
  //   //transition: all 1.5s;
  //   position: fixed;
  //   top: 70px;
  //   z-index: 1;
  //   margin-left: 10px;
  //   background-color: ${colors.blue};
  //   box-shadow: 7px 5px 13px -5px rgba(0, 0, 0, 0.68);
  //   border-radius: 15px;
  //   width: ${(p) => (!p.isMenuOpen ? '65px' : '228px;')};
  // }
`;
