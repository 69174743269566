import React from 'react';
import * as ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import configureStore from './redux/store';
import App from './App';
// import { AppWrapper } from './index.style';
// import FallbackRender from './components/FallbackRenderer/FallbackRenderer';
// import { ErrorBoundary } from 'react-error-boundary';

import './index.css';

const store = configureStore();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ErrorBoundary fallbackRender={FallbackRender}> */}
      {/* <AppWrapper className={'AppMain'}> */}
      <App />
      {/* </AppWrapper> */}
      {/* </ErrorBoundary> */}
    </Provider>
  </React.StrictMode>
);
