import Axios from 'axios';

import { getAccessLevelsAction } from '../actions/settingsScreen/user/actionCreators';

const urlBack = import.meta.env.VITE_BACK_URL;

export const getAccessLevelsThunk = (user) => async (dispatch) => {
  try {
    const response = await Axios.get(`${urlBack}users/accesslevel/${user}`);
    if (response.status === 200) {
      dispatch(getAccessLevelsAction(response.data));
    }
    return response;
  } catch (err) {
    if (import.meta.env.VITE_DEBUG === 'true') console.log(err);

    return err;
  }
};
