import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCompany } from '../../../data/dataPreferences';
import { useDepartments } from '../../../hooks/useDepartments';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesPreferences, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const CompanyForm = ({ crudType }) => {
  const { FormCompany, companyForm, createCompany, updateCompany, getHeadquarter } = useHeadquarter();
  const { departmentsHook } = useDepartments();
  const { typeSwal } = useSwal();

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);

  const data = formCompany;

  useEffect(() => {
    // getDepartments();
    FormCompany({ action: 'clear' });

    if (crudType === 'edit') {
      let actionId = {
        action: 'id',
        value: location.state?.id,
      };
      FormCompany(actionId);

      FormCompany(
        {
          action: 'input',
          value: location.state?.name,
        },
        { form: 'company', element: 'name' }
      );
      FormCompany(
        {
          action: 'input',
          value: location.state?.ip,
        },
        { form: 'company', element: 'ip' }
      );
      FormCompany(
        {
          action: 'input',
          value: location.state?.port,
        },
        { form: 'company', element: 'port' }
      );
      FormCompany(
        {
          action: 'input',
          value: location.state?.ipBackup,
        },
        { form: 'company', element: 'ipBackup' }
      );
      FormCompany(
        {
          action: 'input',
          value: location.state?.portBackup,
        },
        { form: 'company', element: 'portBackup' }
      );

      FormCompany(
        {
          action: 'select-option',
        },
        {
          form: 'company',
          element: 'departments',
        },
        location.state?.departments
      );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof departmentsHook === 'object' && departmentsHook.length > 0) {
        data.steps.map((step) => {
          const result = step.input.map((input) => {
            input.defaultValues = '';
            input.options = [];
            if (input.cardType === 'multiselect') {
              departmentsHook.forEach((department) => {
                if (department.name === null) {
                  department.name = 'not defined';
                }
                const departmentValue = {
                  value: department.id,
                  label: department.name,
                };
                const foundDepartment = input.options.some((option) => option.value === departmentValue.value);
                if (!foundDepartment && departmentValue.value !== undefined) {
                  input.options.push(departmentValue);
                }
              });
            }
            return step;
          });
          return result;
        });
        if (crudType === 'edit' && (companyForm.name === '' || companyForm.name !== location.state?.name)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((step) => {
            step.input.map((item) => {
              switch (item.ref.element) {
                case 'name':
                  item.defaultValues = companyForm.name;
                  break;
                case 'ip':
                  item.defaultValues = companyForm.ip;
                  break;
                case 'port':
                  item.defaultValues = companyForm.port;
                  break;
                case 'ipBackup':
                  item.defaultValues = companyForm.ipBackup;
                  break;
                case 'portBackup':
                  item.defaultValues = companyForm.portBackup;
                  break;
                case 'departments':
                  // if (item.options) {
                  // if (companyForm?.departments[0] && arraysAreEqual(location.state?.departments, companyForm?.departments) === false) {
                  //   location.state?.departments = companyForm?.departments;
                  //   FormCompany(
                  //     {
                  //       action: 'select-option',
                  //       value: location.state?.departments,
                  //     },
                  //     {
                  //       form: 'company',
                  //       element: 'departments',
                  //     }
                  //   );
                  // }
                  //   return (item.defaultValues = location.state?.departments);
                  // }
                  // item.defaultValues = location.state?.departments;
                  item.defaultValues = companyForm.departments;
                  // item.defaultValues = [];
                  // item.options.filter((option) => {
                  //   if (location.state?.departments.map((department) => department.Department.id.toString()).includes(option.value)) {
                  //     if (!item.defaultValues.map((item2) => item2.value).includes(option.value)) {
                  //       return item.defaultValues.push(option);
                  //     }
                  //   }
                  //   return false;
                  // });
                  break;
                default:
                  break;
              }
              return item;
            });
            return step;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 1000);
  });

  const handleOnCancel = () => {
    history.back();
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', FormCompany, getHeadquarter, `/${routesWide.preferences}/${routesPreferences.company}`, 'company', createCompany);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', FormCompany, getHeadquarter, `/${routesWide.preferences}/${routesPreferences.company}`, 'company', updateCompany);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          onCancel={handleOnCancel}
          data={data}
          onSubmit={onSubmit}
          form={FormCompany}
          dataSave={dataSave}
          dataTabs={data.tabs || data.steps}
          // initialData={EMFormListExtensions}
          reducerForm={companyForm}
          crudType={crudType}
          arrScreen={['Sede']}
        />
      )}
    </>
  );
};

CompanyForm.propTypes = { crudType: string };
