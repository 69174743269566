import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const DropDownWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${'' /* width: 100px; */}
  height: 100%;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const Wrapper = styled.div`
  height: 10px;
  ${'' /* ${(props) => props.height}; */}
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  border-radius: 10px;
  border: ${(props) => props.border};
  ${'' /* z-index: 99; */}
  ${'' /* width: 100%; */}
  color: rgb(225,224,224);
  display: flex;
  flex-direction: row-reverse;

  .title-dropDown {
    color: ${(props) => props.color};
    font-weight: ${weight.medium};
    margin: 0;
    letter-spacing: 0.25px;
    line-height: 16px;
    //transition: all 0.3s;
  }

  .subOptions-dropDown1 {
    width: 100px;
    position: absolute;
    left: 180px;
    background-color: ${colors.primaryColor};
    border-radius: 10px 10px 0px 0px;
    padding: 5px;

    &:hover {
      .menuTop__icon {
        border-radius: 10px 0 0px 10px;
        background-color: ${colors.primaryColorLight};
      }

      .menuTop__text {
        ${'' /* &:hover { */}
        border-radius: 0 0 10px 10px;
        background-color: ${colors.primaryColorLight};
        ${'' /* } */}
      }
    }
  }
  .subOptions-dropDown2 {
    width: 100px;
    padding: 5px;
    position: absolute;
    left: 180px;
    top: 168px;
    border-radius: 0px 0px 10px 10px;
    background-color: ${colors.primaryColor};
    &:hover {
      background-color: ${colors.primaryColorLight};
    }
  }

  .icon-dropDown-left {
    ${'' /* position: relative; */}
    top: ${(props) => (props.isMenuOpen ? '-35px' : '-30px')};
    left: ${(props) => (props.isMenuOpen ? '90px' : '25px')};
  }
  .icon-dropDown-left-item {
    width: -webkit-fill-available;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 10px;
  }

  .icon-dropDown {
    height: 25px;
    width: 25px;
    border-radius: 10px;
    color: ${colors.greySpanLabel};

    &:hover {
      color: ${colors.white};

      .title-dropDown {
        color: ${colors.white};
        //transition: all 0.3s;
      }

      .icon-dropDown {
        color: ${colors.white};
        //transition: all 0.3s;
      }
    }
  }
`;

export const OptionsWrapper = styled.div`
  background-color: ${colors.primaryColor};
  color: color: ${colors.greySpanLabel};
  position: absolute;
  top: 35px;
  left: -100px;
  z-index: 99;
  display: flex;
  border-radius: 10px;
  width: auto;
  height: auto;
  flex-direction: ${(props) => props.direction};
  padding: 5px;
  box-shadow:
    26px 32px 17px rgba(73, 80, 87, 0.01),
    15px 18px 14px rgba(73, 80, 87, 0.04),
    7px 8px 10px rgba(73, 80, 87, 0.06),
    2px 2px 6px rgba(73, 80, 87, 0.07),
    2px 2px 6px rgba(73, 80, 87, 0.07),
    0px 0px 0px rgba(73, 80, 87, 0.07);


.submenu__title {
    color: ${colors.backgroundColor};
    font-weight: bold;
    padding-left: 10px;
  }
  
  .contain__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding: 5px 5px;
    background-color: ${colors.primaryColor};

    &:hover {
      background-color: ${colors.primaryColorLight};
      border-radius: 10px;
      margin: auto;
    }
    &:first-child {
      border-radius: 10px;
      ${'' /* border-top: 0.5px solid ${colors.primaryColorLight}; */}
    }
  }
  .contain__option-left {
    position: absolute;
    top: 230px;
    left: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: max-content;
    padding: 5px 5px;
    background-color: ${colors.primaryColor};

    &:first-child {
      border-radius: 10px;
      border-top: 0.5px solid ${colors.primaryColorLight};
    }
  }

  .menuTop__icon {
    color: ${colors.backgroundColor};
  }

  .title {
    height: 35px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    ${'' /* color: ${colors.backgroundColor}; */}

    .title__icons {
      width: 25px;
      height: 25px;
      ${'' /* color: ${colors.primaryColorLight}; */}
    }

    img {
      color: ${colors.backgroundColor};
      padding-left: 10px;
    }
  }

  .contain__title {
    color: ${colors.backgroundColor};
    margin: 0;
    font-style: normal;
    font-weight: ${weight.medium};
    line-height: 16px;
    letter-spacing: 0.25px;
    padding: 10px;
    width: max-content;
  }

  .contain__name {
    margin: 0;
    font-style: normal;
    font-weight: ${weight.regular};
    font-size: ${newFontSize.body};
    line-height: 16px;
    letter-spacing: 0.25px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 10px;
    //transition: background-color 0.3s;
  }

  .contain__option-table {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    //transition: background-color 0.3s;

    &:hover {
      background-color: ${colors.greyBorder};
    }
  }
`;
