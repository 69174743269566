// import React from 'react';
import Axios from 'axios';
import jwtDecode from 'jwt-decode';

import { loginUserAction } from '../actions/login/actionCreators';
import { createUsersAction, getUsersAction, getUsersPermissionAction } from '../actions/settingsScreen/user/actionCreators';
// import { correctAction, wrongAction } from '../../components/Toasters/Toasters';
// import { toast } from 'react-toastify';

const urlApi = import.meta.env.VITE_APP_URL;
const env = import.meta.env.VITE_NODE_ENV || 'development';
const urlBack = env === 'development' ? import.meta.env.VITE_BACK_DEV_URL : import.meta.env.VITE_BACK_URL;

export const usersLoginThunk = (user) => async (dispatch) => {
  try {
    const response = await Axios.post(`${urlBack}users/login`, user);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const token = response.data.token;
      const userDecode = jwtDecode(token);

      localStorage.setItem('tokenStorage', JSON.stringify({ token }));

      dispatch(loginUserAction(userDecode));
    }
    if (response.status === 203) {
      return response;
    }
    // correctAction('Login finished.');
  } catch (err) {
    return err;
  }
};

export const usersCreateThunk = (user, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlBack}users/usersList/create`, user);
    if (response.status === 201) {
      dispatch(createUsersAction(user));
    }
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const usersDeleteThunk = (user, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}users/usersList/delete`, {
      data: user,
    });
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const usersUpdateThunk = (user, setLoading) => async () => {
  setLoading(true);
  try {
    const newUSer = {
      id: Number(user.id),
      firstname: user.firstname,
      lastname: user.lastname,
      secondname: user.secondname,
      password: user.password,
      email: user.email,
      companyId: Number(user.companyId.value),
      departmentId: Number(user.departmentId.value),
      roleId: Number(user.roleId.value),
      accesslevelId: Number(user.accesslevelId.value),
      isTeams: user.isTeams,
      isRainbow: user.isRainbow,
      isVoicemail: user.isVoicemail,
      isvoicemailEmail: user.isvoicemailEmail,
      voicemailPIN: user.voicemailPIN,
      // isSIPExtension: user.isSIPExtension,
      captureId: user.captureId.value,
      category: user.category.value,
      SIPExtension: user.SIPExtension.value,
      callForward: user.callForward,
      notAnswer: user.notAnswer,
    };

    const response = await Axios.post(`${urlBack}users/usersList/update`, {
      data: newUSer,
    });
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getUsersThunk = () => async (dispatch) => {
  // setLoading(true);
  try {
    const arrayUsers = await Axios.get(`${urlBack}users/usersList/`);
    if (arrayUsers.status === 200) {
      dispatch(getUsersAction(arrayUsers.data));
    }

    // correctAction('Get Users finished.');
    console.log('GET getUsers found: ', arrayUsers.length() + ', OK');
    // setLoading(false);
  } catch (err) {
    // wrongAction('Error getting users: ' + err.description);
    // setLoading(false);
    return err;
  }
};
export const uploadFileUsersThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlApi}users/usersList/uploadFile`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

//permissions according to user id
export const getUsersPermissionsThunk = (user) => async (dispatch) => {
  try {
    const response = await Axios.get(`${urlBack}users/permissions/${user}`);
    if (response.status === 200) {
      dispatch(getUsersPermissionAction(response.data));
    }
    return response;
  } catch (err) {
    return err;
  }
};

export const changeUserPassword = (newPasswordUser) => async () => {
  try {
    const response = await Axios.patch(`${urlBack}users/changeUserPassword`, {
      data: newPasswordUser,
    });
    return response;
  } catch (err) {
    if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
  }
};
