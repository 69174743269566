import React from 'react';
// import Play from './../../assets/images/audioplayer/play.svg';
// import Pause from './../../assets/images/audioplayer/pause.svg';
// import Next from './../../assets/images/audioplayer/next.svg';
// import Prev from './../../assets/images/audioplayer/prev.svg';
import { IconPlayerPlay, IconPlayerTrackNext, IconPlayerTrackPrev, IconPlayerPause } from '@tabler/icons-react';
import { bool, func } from 'prop-types';

const AudioControls = ({ isPlaying, onPlayPauseClick, onPrevClick, onNextClick }) => (
  <div className="audio-controls">
    <button type="button" className="prev" aria-label="Previous" onClick={onPrevClick}>
      <IconPlayerTrackPrev className="prev" />
    </button>
    {isPlaying ? (
      <button type="button" className="pause" onClick={() => onPlayPauseClick(false)} aria-label="Pause">
        <IconPlayerPause width={'35px'} className="pause" />
      </button>
    ) : (
      <button type="button" className="play" onClick={() => onPlayPauseClick(true)} aria-label="Play">
        <IconPlayerPlay width={'35px'} className="play" />
      </button>
    )}
    <button type="button" className="next" aria-label="Next" onClick={onNextClick}>
      <IconPlayerTrackNext className="next" />
    </button>
  </div>
);

export default AudioControls;

AudioControls.propTypes = { isPlaying: bool, onPlayPauseClick: func, onPrevClick: func, onNextClick: func };
