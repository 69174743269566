import React from 'react';
import { KeyWrapper } from './Key.style';
import { bool, func, string } from 'prop-types';

export const Key = ({ item, onClick, backgroundColor, isDisable }) => {
  return (
    <KeyWrapper onClick={onClick} backgroundColor={backgroundColor} title={item.title} isDisable={isDisable}>
      <div className="key">
        <div className="key__item">{item}</div>
      </div>
    </KeyWrapper>
  );
};

Key.propTypes = { item: string, onClick: func, backgroundColor: string, isDisable: bool };
